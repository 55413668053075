import { useCallback, useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { Article, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { Icon, ArticleNumber, Box, CardContent, Stack, Button } from "@tm/components"
import { TraderArticle } from "../business/helpers"
import { OfferCard, OfferCardPartActions } from "./StyledComponents"

type Props = {
    workTaskId: string
    part: TraderArticle
    advertisementCategoryId: string
    onRequestPartList(showAddToBasketMessage?: boolean): void
}

export function OfferItemWholesalerPartWide({ workTaskId, part, advertisementCategoryId, onRequestPartList }: Props) {
    const { userContext } = useUser() ?? {}
    const { renderMicro } = useMicro<IMicros>()

    const requestPartsList = useCallback(() => onRequestPartList(true), [onRequestPartList])

    const article = useMemo(
        () =>
            ({
                traderArticleNo: part.traderArticleNumber,
                supplier: {},
                productGroup: {},
                id: part.id,
                requestErpInfo: true,
            } as Article),
        [part.id, part.traderArticleNumber]
    )

    const wholesalerPart = useMemo(
        () => ({
            wholesalerArticleNumber: part.traderArticleNumber,
            quantityValue: 1,
            advertisementCategoryId,
        }),
        [part.traderArticleNumber, advertisementCategoryId]
    )

    return (
        <OfferCard key={part.id}>
            <CardContent sx={{ flex: 1 }}>
                <Box mt={0.5} height="6rem" display="flex" alignItems="center" justifyContent="center">
                    <Icon name="supplier" />
                </Box>
                <Stack pt={1}>
                    <Box height="3em" />
                    <Box height="2em" />
                </Stack>
                <Stack height="3em">
                    {!userContext?.parameter.hideDealerPartNumber && (
                        <Box alignItems="flex-start">
                            <ArticleNumber articleNumber={part.traderArticleNumber} articleNumberType="wholesaler" disabled />
                        </Box>
                    )}
                </Stack>
                <Box position="relative" display="flex" justifyContent="start" height="2em">
                    {renderMicro("erp", "erp-info-prices", {
                        data: article,
                    })}
                    {renderMicro("erp", "erp-info-availability", {
                        data: article,
                    })}
                </Box>
                <Box>
                    {renderMicro("erp", "erp-info-additional-information", {
                        data: article,
                        maxLines: 1,
                        variant: "body2",
                    })}
                </Box>
            </CardContent>
            <OfferCardPartActions>
                {renderMicro("basket", "add-to-basket-wholesaler-part", {
                    part: wholesalerPart,
                    generatedArticleId: part.id,
                    hideQuantityField: false,
                    generatedWorktaskId: workTaskId,
                    onAddWholesalerPartToBasketFinished: requestPartsList,
                })}
                <Button disabled startIcon={<Icon name="details" />} />
            </OfferCardPartActions>
        </OfferCard>
    )
}
