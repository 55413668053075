import { StateStorage } from "zustand/middleware"
import { getStorageKey } from "./helper"
import { fastServiceStore } from ".."

export const zustandSessionStorage: StateStorage = {
    getItem: async (name: string): Promise<string | null> => {
        const key = getStorageKey()
        if (!key) {
            return null
        }

        const storageKey = `${name}_${key}`

        return sessionStorage.getItem(storageKey) || null
    },
    setItem: async (name: string, value: string): Promise<void> => {
        const key = getStorageKey()
        if (!key || fastServiceStore.getState().worktaskId !== key || !fastServiceStore.persist.hasHydrated()) {
            return
        }

        const storageKey = `${name}_${key}`
        const savedValue = sessionStorage.getItem(storageKey) || null

        if (savedValue === value) {
            return
        }

        sessionStorage.setItem(storageKey, value)
    },
    removeItem: async (name: string): Promise<void> => {
        const key = getStorageKey()

        if (!key) {
            return
        }

        const storageKey = `${name}_${key}`

        sessionStorage.removeItem(storageKey)
    },
}
