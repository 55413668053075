import { Box, Collapse, Divider, Icon, IconButton, Stack, styled, Tooltip, Typography } from "@tm/components"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { FiltersSkeleton } from "../../../../../parts/src/components/ListV2/components/Filters/FiltersSkeleton"
import { batch, useDispatch, useSelector } from "react-redux"
import { MainState } from "../../main"
import { Dropdown, Scrollbar } from "@tm/controls"
import { EFilterNames, TyreFilter } from "@tm/models"
import { useAvailabilityStatus } from "@tm/context-distribution"
import { AvailabilityFilterType } from "../../../business"
import { useActions } from "@tm/morpheus"
import { Actions } from "../business"
import { IListFilters } from "../../../data/models"
import { EuTyreLabel } from "../../_shared/EuTyresLabel"
import { AvailabilityFilter, FilterItem, FiltersArea, SeasonComponent } from "../../../../../tyres/src/components/list/components"
import { isAvailabilityFilter } from "../../../../../tyres/src/components/list/business/helpers"
import { TyresDropDownItemView } from "./tiresFilters"

type AccordionStates = {
    availability?: boolean,
    tyreSize?: boolean,
    season?: boolean,
    manufacturer?: boolean,
    loadIndex?: boolean,
    speedIndex?: boolean,
    oeIdentifier?: boolean,
    extras?: boolean,
    wetGripClass?: boolean,
}

export const TyresFiltersV2 = () => {
    const { translateText } = useLocalization()
    const { filters, selectedFilters } = useSelector((s: MainState) => ({ filters: s.tyresList.filters, selectedFilters: s.tyresList.selectedFilters }))
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()
    const actions = useActions(Actions, "loadTiresList", "updateFilter", "resetFilter", "changeAvailabilityFilter")


    const [visibilityButtonMinimized, setVisibilityButtonMinimized] = useState(false)
    const [filtersVisible, setFiltersVisible] = useState(true)

    let availabilityArray: AvailabilityFilter[] = []

    if (availabilityStatusIdsToShow?.length) {
        availabilityArray.push({
            name: translateText(1623),
            value: AvailabilityFilterType.Primary,
        })
    }
    if (availabilityStatusIdsToShowSecondary?.length) {
        availabilityArray.push({
            name: translateText(12860),
            value: AvailabilityFilterType.Secondary,
        })
    }

    const [accordionsState, setAccordionsState] = useState<AccordionStates>({
        ...(availabilityArray.length > 0 && { availability: true }),
        ...(filters.tyreSize.length > 0 && { size: true }),
        ...(filters.season.length > 0 && { season: true }),
        ...(filters.manufacturer.length > 0 && { manufacturer: false }),
        ...(filters.loadIndex.length > 0 && { loadIndex: false }),
        ...(filters.speedIndex.length > 0 && { speedIndex: false }),
        ...(filters.oeIdentifier.length > 0 && { oeIdentifier: false }),
        ...(filters.extras.length > 0 && { extras: false }),
        ...((filters.wetGripClass.length > 0 || filters.fuelEfficiency.length > 0 || filters.externalRolling.length > 0) && { wetGripClass: false })
    })

    const isFilterCollapsed = useMemo(() => !Object.values(accordionsState).some(value => value === true), [accordionsState])

    useEffect(() => {
        setAccordionsState({
            ...(availabilityArray.length > 0 && { availability: true }),
            ...(filters.tyreSize.length > 0 && { tyreSize: true }),
            ...(filters.season.length > 0 && { season: true }),
            ...(filters.manufacturer.length > 0 && { manufacturer: false }),
            ...(filters.loadIndex.length > 0 && { loadIndex: false }),
            ...(filters.speedIndex.length > 0 && { speedIndex: false }),
            ...(filters.oeIdentifier.length > 0 && { oeIdentifier: false }),
            ...(filters.extras.length > 0 && { extras: false }),
            ...((filters.wetGripClass.length > 0 || filters.fuelEfficiency.length > 0 || filters.externalRolling.length > 0) && { wetGripClass: false })
        })
    }, [filters.tyreSize, filters.season, filters.manufacturer, filters.loadIndex, filters.speedIndex, filters.oeIdentifier, filters.extras, filters.wetGripClass, filters.fuelEfficiency, filters.externalRolling])

    const updateAccordionState = (key: string, value: boolean) => {
        setAccordionsState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    const toggleAccordionsState = () => {
        const isAnyFilterOpen = Object.values(accordionsState).some(value => value === true)

        setAccordionsState(prevState => {
            const newState = Object.keys(prevState).reduce((acc: AccordionStates, key) => {
                acc[key] = !isAnyFilterOpen
                return acc
            }, {})

            return newState
        })
    }

    const handleToggleFilterVisibility = () => {
        setFiltersVisible((prev) => !prev)
        setVisibilityButtonMinimized((prev) => !prev)
    }

    function getCollapseIcon(): string {
        return filtersVisible ? "arrow-left" : "arrow-right"
    }

    const handleCollapseExit = useCallback(() => {
        if (filtersVisible) {
            setTimeout(() => setVisibilityButtonMinimized(false), 500)
        }
    }, [filtersVisible])

    const handleFilterChange = (filter: TyreFilter | AvailabilityFilter | null, path?: IListFilters) => {
        if (filter === null) {
            return
        }

        batch(() => {
            if (isAvailabilityFilter(filter)) {
                actions.changeAvailabilityFilter(selectedFilters.availability === filter.value ? AvailabilityFilterType.None : filter.value)
                actions.loadTiresList(true, path)
                return
            }
            if (path) {
                actions.updateFilter(path, filter)
                actions.loadTiresList(true, path)
            }
        })
    }

    const handleSizeChange = (tireFilter: TyreFilter | undefined) => {
        if (!tireFilter?.query) {
            handleFilterReset(EFilterNames.tyreSize)
            return
        }

        if (tireFilter && selectedFilters.tyreSize?.query != tireFilter.query) {
            handleFilterChange(tireFilter, EFilterNames.tyreSize)
        }
    }

    const handleFilterReset = (path?: IListFilters) => {
        if (!path) {
            actions.changeAvailabilityFilter(AvailabilityFilterType.None)
            return
        }
        actions.resetFilter(path)
        actions.loadTiresList(true, path)
    }

    const handleEuFiltersReset = () => {
        batch(() => {
            actions.resetFilter("fuelEfficiency")
            actions.resetFilter("wetGripClass")
            actions.resetFilter("externalRolling")
            actions.loadTiresList(true)
        })
    }

    const handleResetAllFilters = () => {
        actions.loadTiresList()
    }

    return (
        <Stack marginX="1em" maxWidth="19em">
            <Stack flex="0 0 auto" direction="row" alignItems="center" alignSelf="flex-start" sx={{ width: "100%" }}>
                <Tooltip title={translateText(visibilityButtonMinimized ? 13483 : 13484)} enterDelay={1000}>
                    <IconButton onClick={handleToggleFilterVisibility}>
                        <Icon size="16px" name={getCollapseIcon()} />
                    </IconButton>
                </Tooltip>
                <Box display="flex" justifyContent="space-between" width="100%">
                    {!visibilityButtonMinimized && <Typography>{translateText(209)}</Typography>}
                    {!visibilityButtonMinimized && (
                        <Box>
                            <Tooltip title={isFilterCollapsed ? translateText(13866) : translateText(13865)} enterDelay={1000}>
                                <Icon
                                    sx={{ mr: "1.2em", cursor: "pointer" }}
                                    name={isFilterCollapsed ? "filter-expand" : "filter-collaps"}
                                    onClick={toggleAccordionsState}
                                />
                            </Tooltip>
                            <Tooltip title={translateText(13857)} enterDelay={1000}>
                                <Icon sx={{ mr: "1.2em", cursor: "pointer" }} name="remove-filter" onClick={handleResetAllFilters} />
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            </Stack>
            <Divider sx={{ borderColor: "#ccc" }} />

            <Collapse
                in={filtersVisible}
                orientation="horizontal"
                sx={{ overflow: "hidden", flex: 1 }}
                addEndListener={handleCollapseExit}
            >
                {filters.loading && filtersVisible ? (
                    <Box width={290} key="loading">
                        <FiltersSkeleton />
                    </Box>
                ) : (
                    <Box width={290} height="100%" maxWidth="19em" sx={{ overflowY: "auto" }}>
                        <Scrollbar>
                            <Box display="flex" flexDirection="column" className="panel__content">
                                {!!availabilityArray.length &&
                                    <FiltersArea
                                        headline={translateText(746)}
                                        onChange={handleFilterChange}
                                        hideFilterSearch
                                        onResetFilters={handleFilterReset}
                                        isExpanded={!!accordionsState.availability}
                                        onAccordionClick={updateAccordionState}
                                        dropdownPath="availability"
                                    >
                                        {availabilityArray?.map((filter, index) => (
                                            <FilterItem
                                                key={index}
                                                filter={filter}
                                                checked={filter.value === selectedFilters.availability}
                                                onChange={handleFilterChange}
                                            />
                                        ))}
                                    </FiltersArea>
                                }
                                {!!filters.tyreSize.length && <FiltersArea 
                                    headline={translateText(712)} 
                                    hideFilterSearch 
                                    isExpanded={!!accordionsState.tyreSize}
                                    onAccordionClick={updateAccordionState}
                                    dropdownPath="tyreSize">
                                    <Dropdown<TyreFilter>
                                        value={selectedFilters.tyreSize}
                                        items={filters.tyreSize}
                                        onChange={handleSizeChange}
                                        disabled={!filters.tyreSize.length}
                                        itemView={TyresDropDownItemView}
                                        amountItemsToShow={5}
                                    />
                                </FiltersArea>}
                                {!!filters.season.length && <FiltersArea
                                    path="season"
                                    headline={translateText(1235)}
                                    onResetFilters={handleFilterReset}
                                    hideFilterSearch
                                    isExpanded={!!accordionsState.season}
                                    onAccordionClick={updateAccordionState}
                                >
                                    <SeasonComponent handleFilterChange={handleFilterChange} seasons={filters.season} selectedFilters={selectedFilters.season ? [selectedFilters.season] : undefined} />
                                </FiltersArea>}
                                {!!filters.manufacturer.length && <FiltersArea
                                    path="manufacturer"
                                    data={filters.manufacturer}
                                    headline={translateText(71)}
                                    onChange={handleFilterChange}
                                    selectedFilters={selectedFilters.manufacturer}
                                    onResetFilters={handleFilterReset}
                                    isExpanded={!!accordionsState.manufacturer}
                                    onAccordionClick={updateAccordionState}
                                />}
                                {!!filters.loadIndex.length && <FiltersArea
                                    path="loadIndex"
                                    data={filters.loadIndex}
                                    headline={translateText(12403)}
                                    onChange={handleFilterChange}
                                    selectedFilters={selectedFilters.loadIndex}
                                    onResetFilters={handleFilterReset}
                                    isExpanded={!!accordionsState.loadIndex}
                                    onAccordionClick={updateAccordionState}
                                />}
                                {!!filters.speedIndex.length && <FiltersArea
                                    path="speedIndex"
                                    data={filters.speedIndex}
                                    headline={translateText(719)}
                                    onChange={handleFilterChange}
                                    selectedFilters={selectedFilters.speedIndex}
                                    onResetFilters={handleFilterReset}
                                    isExpanded={!!accordionsState.speedIndex}
                                    onAccordionClick={updateAccordionState}
                                />}
                                {!!filters.oeIdentifier.length &&
                                    <FiltersArea
                                        path="oeIdentifier"
                                        data={filters.oeIdentifier}
                                        headline={translateText(720)}
                                        onChange={handleFilterChange}
                                        selectedFilters={selectedFilters.oeIdentifier}
                                        onResetFilters={handleFilterReset}
                                        isExpanded={!!accordionsState.oeIdentifier}
                                        onAccordionClick={updateAccordionState}
                                    />}
                                {!!filters.extras.length &&
                                    <FiltersArea
                                        path="extras"
                                        data={filters.extras}
                                        headline={translateText(744)}
                                        onChange={handleFilterChange}
                                        selectedFilters={selectedFilters.extras}
                                        onResetFilters={handleFilterReset}
                                        hasInlineInfo
                                        isExpanded={!!accordionsState.extras}
                                        onAccordionClick={updateAccordionState}
                                    />}
                                {(!!filters.wetGripClass.length || !!filters.fuelEfficiency.length || !!filters.externalRolling.length) &&
                                    <FiltersArea
                                        path="wetGripClass"
                                        headline={translateText(12404)}
                                        onChange={handleFilterChange}
                                        onResetFilters={handleFilterReset}
                                        onResetEuFilters={handleEuFiltersReset}
                                        hideFilterSearch
                                        isExpanded={!!accordionsState.wetGripClass}
                                        onAccordionClick={updateAccordionState}
                                    >
                                        <EuTyreLabel onChange={handleFilterChange} onResetFilters={handleFilterReset} />
                                    </FiltersArea>}
                            </Box>
                        </Scrollbar>
                    </Box>
                )}
            </Collapse>
        </Stack>
    )
}