import { useCallback, useMemo } from "react"
import { Box, Checkbox } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { TyreFilter } from "@tm/models"
import { ClickableText, ListItemMaxWidth } from "../../../../../parts/src/components/ListV2/components/Filters/FiltersStyledComponents"
import IconWithTooltip from "../../../../../parts/src/components/_shared/icon-with-tooltip/IconWithTooltip"
import { isAvailabilityFilter } from "../business/helpers"
import { AvailabilityFilter } from "."

export function FilterItem({
    filter,
    checked,
    onChange,
    onInfoInlineClick,
}: {
    filter: TyreFilter | AvailabilityFilter
    checked: boolean
    onChange: (filter: TyreFilter | AvailabilityFilter) => void
    onInfoInlineClick?: (value?:string) => void
}) {
    const { translateText } = useLocalization()

    const handleChange = useCallback(() => {
        onChange(filter)
    }, [onChange, filter])

    const handleInfoInlineClick = useCallback(() => {
        if(!isAvailabilityFilter(filter)){
            onInfoInlineClick?.(filter.valueKey)
        }
    }, [onInfoInlineClick, filter])

    const text = useMemo(() => {
        if (isAvailabilityFilter(filter)) {
            return (<strong>{filter.name}</strong>)
        } else {
            const value = filter.value
            const info = filter.info ?? ""
            return (
                <>
                    <strong>{value}</strong> {info}
                </>
            )
        }
    }, [filter])

    const plainText = useMemo(() => {
        if (isAvailabilityFilter(filter)) {
            return filter.name
        } else {
            return `${filter.value} ${filter.info ?? ""}`
        }
    }, [filter])

    return (
        <ListItemMaxWidth>
            <Checkbox
                sx={{ p: "0 8px 0 4px" }}
                size="small"
                checked={checked}
                onChange={handleChange}
            />
            <ClickableText onClick={handleChange} title={`${plainText}\n(${translateText(936)})`}>
                {text}
            </ClickableText>
            <Box
                ml="auto"
                mr="1em"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                >
                {onInfoInlineClick && (
                    <IconWithTooltip
                        variant="info"
                        iconProps={{ size: "16px" }}
                        onClick={handleInfoInlineClick}
                    />
                )}
            </Box>
        </ListItemMaxWidth>
    );
}
