import { ActivatableModule } from "@tm/models"

export function getModuleTitleAndPackage(module: ActivatableModule, translateText: (key: string | number) => string) {
    if (module.externalId) {
        return module.name
    }

    // at carat, TecRMi is called AUTOCOMPACT, thats why we need the translation
    const moduleName = module.id === "tecrmi" ? translateText(12804) : module.name
    let packageName = ""

    if (module.moduleOptions?.full?.active && !!module.modulePackages) {
        const activeDemoModulePackage = Object.values(module.modulePackages)?.find((x) => x.moduleOptions?.demo?.active)

        const activatableDemoModulePackage = Object.values(module.modulePackages)?.find((x) => x.moduleOptions?.demo?.activatable)

        const activatableFullModulePackage = Object.values(module.modulePackages)?.find((x) => x.moduleOptions?.full?.activatable)

        if (activatableDemoModulePackage) {
            packageName = activatableDemoModulePackage?.name
        } else if (activatableFullModulePackage) {
            packageName = activatableFullModulePackage?.name
        } else if (activeDemoModulePackage) {
            packageName = activeDemoModulePackage?.name
        }
    }

    return packageName ? `${moduleName} ${packageName}` : moduleName
}
