import { Icon, Loader } from "@tm/components"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { WarningPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ETransferMode, ErpSystemConfig } from "@tm/models"
import { notUndefinedOrNull, useErpConfig } from "@tm/utils"
import { sortBy, throttle } from "lodash"
import { Suspense, useCallback, useEffect, useRef, useState } from "react"
import { useRecoilValue, useResetRecoilState } from "recoil"
import { useCentralOrderWorkTasks } from "../../../data/hooks/centralOrder/useCentralOrderWorkTasks"
import { BASKET_ERP_CACHING_TIME } from "../../../data/hooks/workTaskBasket/queries/useErpInfosBasketData"
import { useInvalidateWorkTaskBasketQueries } from "../../../data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"
import { basketErp } from "../../../hooks/basketState/useCentralOrderBasketStateByWorkTask"
import { useIsCentralOrderBasketsLoading } from "../../../hooks/basketState/useIsCentralOrderBasketsLoading"
import { BoxWithAlignContent, OrderButton } from "../../StyledComponents"
import { removeParts } from "../parts-list/actions"
import AdditionalCostsDialog from "./components/AdditionalCostsDialog"
import NotUpToDateDialog from "./components/NotUpToDateDialog"
import ConfirmationMessage from "./components/confirmation-message"
import { useSendCentralOrder } from "./hooks/useSendCentralOrder"

type Props = {
    textId: number
}

function CentralOrderButton({ textId }: Props) {
    const { erpSystemConfigs, useOrderByDistributor } = useErpConfig()
    const { translateText } = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { invalidatePartsRequests } = useInvalidateWorkTaskBasketQueries()
    const { includedWorkTaskIds, includedWorkTasks, orderBeingSent, workTasks } = useCentralOrderWorkTasks().state
    const { includedBasketOrderGroups, sendCentralOrder } = useSendCentralOrder(telesalesCustomerNo)
    const allBasketErp = useRecoilValue(basketErp)
    const resetBasketErp = useResetRecoilState(basketErp)
    const isLoading = useIsCentralOrderBasketsLoading(true)

    const [showAdditionalCostsDialog, setShowAdditionalCostsDialog] = useState(false)
    const [showStaleErpInfoDialog, setShowStaleErpInfoDialog] = useState(false)

    const deleteConfirmationDialogRef = useRef<WarningPrompt>(null)
    const [erpSystem, setErpSystem] = useState<ErpSystemConfig>()

    const showAdditionalCostsConfirmation = includedBasketOrderGroups.some((orderGroup) => orderGroup.showAdditionalCostsConfirmation)

    useEffect(() => {
        if (!workTasks?.length) {
            resetBasketErp()
        }
    }, [workTasks])

    function handleConfirmAdditionalCosts() {
        setShowAdditionalCostsDialog(false)
        submitOrder(erpSystem)
    }

    function handleConfirmStaleErpInfo() {
        setShowStaleErpInfoDialog(false)
        includedWorkTasks.forEach(({ workTask }) => {
            invalidatePartsRequests(workTask.workTaskId)
        })
    }

    function validateOrder(extSys: ErpSystemConfig | undefined) {
        if (showAdditionalCostsConfirmation) {
            setShowAdditionalCostsDialog(true)
            setErpSystem(extSys)
        } else {
            submitOrder(extSys)
        }
    }

    function submitOrder(extSys: ErpSystemConfig | undefined) {
        const now = Date.now()

        const erpDataIsStale = includedWorkTaskIds.some((workTaskId) => {
            return allBasketErp[workTaskId]?.basketErpInformation
                .map((x) => x.dataUpdatedAt)
                .filter(notUndefinedOrNull)
                .some((x) => now - x > BASKET_ERP_CACHING_TIME)
        })

        if (erpDataIsStale) {
            setShowStaleErpInfoDialog(true)
        } else {
            sendCentralOrder(extSys)
        }
    }

    const handleOrderButtonClick = useCallback((orderAction: () => void) => {
        throttle(orderAction, 500, { leading: true })()
    }, [])

    function renderOrderButton(key: string | number, highlight: boolean, orderAction: () => void, text: string) {
        return (
            <OrderButton
                color={highlight ? "highlight" : undefined}
                endIcon={isLoading || orderBeingSent ? <Loader size="small" /> : <Icon name="orders" />}
                onClick={(e) => {
                    e.stopPropagation()
                    handleOrderButtonClick(orderAction)
                }}
                disabled={isLoading || orderBeingSent}
                key={key}
                size="large"
            >
                {text}
            </OrderButton>
        )
    }

    function handleClearBasketConfirm() {
        includedWorkTasks.forEach(({ workTask, orderGroups }) => {
            const partIds: Array<string> = []
            orderGroups?.forEach((group) => {
                group.orderItems.forEach((item) => {
                    if (item.isIncludedInOrder) {
                        partIds.push(item.id)
                    }
                })
            })
            if (partIds.length) {
                removeParts(workTask.workTaskId, partIds).then(() => {
                    invalidatePartsRequests(workTask.workTaskId)
                })
            }
        })
    }

    function renderButtons() {
        const orderButtons = []

        if (erpSystemConfigs) {
            let showOthersAsPrioB = false
            let extSystems = erpSystemConfigs.filter((system) => system.transferMode !== ETransferMode.BasketTransferBoschOs3)

            const incadea = extSystems.find((x) => x.displayMode === "BoschIncadea")
            if (incadea?.useForWorkTaskOrder) {
                // Create a copy, so we can splice the incadea system.
                extSystems = [...extSystems]
                extSystems.splice(extSystems.indexOf(incadea), 1)

                orderButtons.push(
                    renderOrderButton(
                        incadea.id,
                        true,
                        () => {
                            validateOrder(incadea)
                        },
                        incadea.description
                    )
                )
            }

            // Will be displayed after the other systems
            const manual = extSystems.find((x) => x.displayMode === "81") // "Manuelle Bestellung"
            if (manual?.useForWorkTaskOrder) {
                // Create a copy, so we can splice the manual system.
                extSystems = [...extSystems]
                extSystems.splice(extSystems.indexOf(manual), 1)
                showOthersAsPrioB = true
            }

            sortBy(extSystems, ["sortNumber"])
                .reverse() // The buttons of the external systems are displayed from right to left
                .forEach((system) => {
                    if (!system.useForWorkTaskOrder) {
                        return
                    }

                    let text = translateText(textId || 50)
                    if (extSystems.length > 1 || useOrderByDistributor) {
                        text += ` (${system.description})`
                    }

                    orderButtons.push(
                        renderOrderButton(
                            system.id,
                            !showOthersAsPrioB,
                            () => {
                                validateOrder(system)
                            },
                            text
                        )
                    )
                })

            if (manual?.useForWorkTaskOrder) {
                orderButtons.push(
                    renderOrderButton(
                        manual.id,
                        true,
                        () => {
                            validateOrder(manual)
                        },
                        translateText(1792)
                    )
                )
            }

            if (useOrderByDistributor) {
                const text = translateText(textId || 50)
                orderButtons.push(
                    renderOrderButton(
                        "orderByDistributor",
                        !showOthersAsPrioB,
                        () => {
                            validateOrder(undefined)
                        },
                        text
                    )
                )
            }
        }
        return orderButtons
    }

    return (
        <BoxWithAlignContent>
            {orderBeingSent === false && <ConfirmationMessage textId={3001} skin="success" />}
            {renderButtons()}
            <WarningPrompt
                text={translateText(1217)}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                ref={deleteConfirmationDialogRef}
                onConfirm={handleClearBasketConfirm}
            />
            <AdditionalCostsDialog
                showAdditionalCostsDialog={showAdditionalCostsDialog}
                onCloseDialog={() => setShowAdditionalCostsDialog(false)}
                onConfirmDialog={handleConfirmAdditionalCosts}
            />
            <NotUpToDateDialog
                showNotUpToDateDialog={showStaleErpInfoDialog}
                dialogText={translateText(13578)}
                onConfirmDialog={handleConfirmStaleErpInfo}
            />
        </BoxWithAlignContent>
    )
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <CentralOrderButton {...props} />
        </Suspense>
    )
}
