import { useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useRecoilValue } from "recoil"
import { ListFilter } from "@tm/models"
import { CHECK_DELAY, FILTERS_QUERY } from "."
import { FiltersByQueryRequest, FiltersRequest, FiltersResponse } from "../../../../../../data/model/uni-parts"
import * as PartsRepository from "../../../../../../data/repositories/uni-parts"
import { mapDataSupplierFilterAndShowOnTop, mapProductGroupFilter } from "../../../../helpers"
import { UniversalPartsListParams } from "../../../../models"
import { ExtendedAssortmentAtom, SelectedAttributeFilterQueriesAtom, SelectedProductGroupIdsAtom, SelectedSupplierIdsAtom } from "../../../../states"
import { useFilterStorageKey } from "../../../../hooks/useFilterStorageKey"

export function useFiltersByQuery(params: UniversalPartsListParams, isEnabled: boolean) {
    const requestTimerRef = useRef<number>()
    const requestDelay = useRef(0) // First request should be without any delay

    const { startParams, setNoResult } = params
    const storageKey = useFilterStorageKey(startParams)

    const [filtersRequest, setFiltersRequest] = useState<FiltersRequest>()
    const extendedAssortmentEnabled = useRecoilValue(ExtendedAssortmentAtom(storageKey))
    const selectedProductGroupIds = useRecoilValue(SelectedProductGroupIdsAtom(storageKey))
    const selectedSupplierIds = useRecoilValue(SelectedSupplierIdsAtom(storageKey))
    const selectedAttributeFilterQueries = useRecoilValue(SelectedAttributeFilterQueriesAtom(storageKey))
    const [loadedFilters, setLoadedFilters] = useState<FiltersResponse>()

    useEffect(
        function createFiltersRequest() {
            window.clearTimeout(requestTimerRef.current)

            if (!isEnabled || startParams.type !== "unisearch") {
                setFiltersRequest(undefined)
                return
            }

            setNoResult(false)

            const newRequest: FiltersByQueryRequest = {
                query: startParams.query,
                selectedProductGroups:
                    loadedFilters?.productGroupFilters.filter((x) => selectedProductGroupIds.includes(x.id)).map(mapProductGroupFilter) ?? [],
                selectedSuppliers:
                    loadedFilters?.supplierFilters.filter((x) => selectedSupplierIds.includes(x.id)).map(mapDataSupplierFilterAndShowOnTop) ?? [],
                selectedCriteria: selectedAttributeFilterQueries.map((x) => ({ query: x } as ListFilter)),
                extendedAssortment: !!params.extendedAssortment,
            }

            // Zeitverzögerter Request
            requestTimerRef.current = window.setTimeout(() => {
                setFiltersRequest(newRequest)
            }, requestDelay.current)

            // Any further request will be delayed (to prevent multiple requests when state changes quickly)
            requestDelay.current = CHECK_DELAY
        },
        [
            isEnabled,
            startParams,
            params.extendedAssortment,
            extendedAssortmentEnabled,
            selectedSupplierIds,
            selectedProductGroupIds,
            selectedAttributeFilterQueries,
        ]
    )

    return useQuery({
        queryKey: [FILTERS_QUERY, filtersRequest] as [string, FiltersByQueryRequest | undefined],
        queryFn: async ({ queryKey: [, requestFromKey] }) => {
            if (requestFromKey) {
                const response = await PartsRepository.getFiltersByQuery(requestFromKey)
                setLoadedFilters(response)
                if (!response.criterionFilterGroups.length && !response.productGroupFilters.length && !response.supplierFilters.length) {
                    setNoResult(true)
                }
                return response
            }
        },
        enabled: isEnabled && !!filtersRequest,
        keepPreviousData: true, // after the user has changed filter a new request will be triggered, but we want to keep showing the previous data while loading
        notifyOnChangeProps: "tracked", // only update when properties of the useQuery return value changed which are really used - enabled by default in v4
    })
}
