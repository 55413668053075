import { Button } from "@tm/controls"
import { MouseEvent, useMemo } from "react"
import { NextTheme, makeStyles, createTheme } from "@tm/components"
import { ActiveButtons } from "../../../data/models"

type Props = {
    isActive: boolean
    onClick(e: MouseEvent<HTMLDivElement>, buttonType: ActiveButtons): void
    readOnly?: boolean
    buttonType: ActiveButtons
}

function DefectButton({ isActive, onClick, readOnly, buttonType }: Props) {
    const theme = createTheme()
    const styles = useStyle(theme)
    const icon = useMemo(() => getIconName(isActive, buttonType), [isActive, buttonType])

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        if (readOnly) {
            return
        }
        onClick(e, buttonType)
    }

    return <Button onClick={handleClick} isActive={isActive} className={styles.bigButton} size="xl" layout={["holo"]} icon={icon} />
}

export default DefectButton

const useStyle = makeStyles<NextTheme>((theme) => ({
    bigButton: {
        padding: ".2em",
        marginLeft: ".5em",
        "&& .icon.btn__icon": {
            width: "2.5rem",
            height: "2.5rem",
        },
        "&.is-active svg": {
            fill: `${theme.colors.primary}!important`,
        },
    },
}))

const getIconName = (isActive: boolean, iconType: ActiveButtons): string => {
    switch (iconType) {
        case "defectButton":
            return isActive ? "defect-filled" : "defect-bordered"
        case "repairedButton":
            return isActive ? "repaired_filled" : "repaired-bordered"
        case "checkButton":
            return isActive ? "checked-filled" : "check-bordered"
        default:
            return ""
    }
}
