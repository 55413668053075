import { ArticleItem, styled } from "@tm/components"
import { ArticleListSortingMode, WholesalerPart } from "@tm/models"
import { useCallback, useMemo } from "react"
import { ArticleGroup } from "../components/ArticleGroup"
import { ArticleListWrapper } from "../components/ArticleListWrapper"
import { ArticleSelection } from "../components/ArticleSelection"
import { NoSearchResult } from "../components/NoSearchResult"
import { WholesalerArticleNumbersItemStateProvider } from "../components/WholesalerArticleNumbers/WholesalerArticleNumbersItemStateProvider"
import { useListOptionsContext } from "../ContextProvider"
import { useProductGroupTopicIdsDataContext } from "../ContextProvider/ProductGroupTopicIds"
import { usePartsViewOptions } from "../hooks/usePartsViewOptions"
import { useFiltersAndResultsContext, useWholeSalerArticleNumberListParamsContext } from "./ContextProvider"

const ModalArticleListWrapper = styled(ArticleListWrapper)({ height: "100%", paddingLeft: 0, paddingRight: 1, margin: 1, overflow: "hidden" })

export function WholesalerArticleNumberList() {
    const {
        noResult,
        attributes,
        toggleAttribute,
        setAttributes,
        sorting: { productGroups: sortingProductGroups, setForProductGroups: activateSortingForProductGroups },
    } = useWholeSalerArticleNumberListParamsContext()

    const {
        isFiltered,
        wholesalerNosArticles,
        results: { articleGroups },
    } = useFiltersAndResultsContext()
    const options = useListOptionsContext()

    const productGroupTopicIds = useProductGroupTopicIdsDataContext()

    const {
        partsViewSettings: { showVehicleRecordsFilters },
    } = usePartsViewOptions()

    const usedAttributeFilters = useMemo(() => attributes.map((x) => x.query ?? `${x.id}|${x.key ?? ""}`), [attributes])

    const handleResetAttributeFilters = useCallback(() => {
        if (usedAttributeFilters.length) {
            setAttributes([])
        }
    }, [usedAttributeFilters, setAttributes])

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            activateSortingForProductGroups([productGroupId], value)
        },
        [activateSortingForProductGroups]
    )

    return (
        <ModalArticleListWrapper>
            <ArticleSelection showArticleComparision hideBasketButton={options.hideAddToBasketButton} />

            {noResult && <NoSearchResult searchType="direct" />}

            {articleGroups.map((group) => (
                <ArticleGroup
                    key={group.productGroup.id}
                    group={group}
                    currentSorting={sortingProductGroups[group.productGroup.id]}
                    onChangeSorting={handleChangeSorting}
                    showFilterOptions={showVehicleRecordsFilters}
                    usedAttributeFilters={usedAttributeFilters}
                    onToggleAttributeFilter={toggleAttribute}
                    onResetAttributeFilters={handleResetAttributeFilters}
                    productGroupTopicIds={productGroupTopicIds}
                />
            ))}

            {!!wholesalerNosArticles.length &&
                !isFiltered &&
                wholesalerNosArticles.map((article: WholesalerPart) => (
                    <WholesalerArticleNumbersItemStateProvider key={article.wholesalerArticleNumber} article={article}>
                        <ArticleItem variant="WholesalerArticleNumber" />
                    </WholesalerArticleNumbersItemStateProvider>
                ))}
        </ModalArticleListWrapper>
    )
}
