import { useEffect, useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Badge, Headline, Icon, Scrollbar, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { bem, classes } from "@tm/utils"
import { Box, Badge as MuiBadge, styled } from "@tm/components"
import { maintenanceReviewActions, stepNavigationActions, useFastServiceStore } from "../../data"
import { ActiveButtons, SelectedItems } from "../../data/models"
import { CustomPanel, NextStep, TitleHeader } from "../_shared"
import { getComponentStyles } from "../_shared/styles"

export default function MaintenanceReview() {
    const style = useMemo(() => getStyle(), [])
    const { translateText } = useLocalization()
    const classNames = getComponentStyles()

    const {
        maintenanceReview: { activeFilterButton, selectedWorks },
        activeStep,
        selectedItems,
    } = useFastServiceStore((state) => ({
        maintenanceReview: state.maintenanceReview,
        activeStep: state.navigation.active,
        selectedItems: state.worksState.selectedItems,
    }))

    const recordedDefects: number = useMemo(
        () =>
            Object.values(selectedItems)
                .flatMap((item) => Object.values(item))
                .filter((a) => a.selectedStatusButton === "defectButton")?.length,
        [selectedItems]
    )

    const handleFilterButtonsClick = (stateButton: ActiveButtons) => {
        maintenanceReviewActions.filterData(stateButton)
    }

    useEffect(() => {
        if (activeStep) {
            stepNavigationActions.updateNextStep(activeStep)
            stepNavigationActions.completeStep(activeStep)
        }
    }, [activeStep])

    const renderBadgeIcons = (value: SelectedItems) => {
        return (
            <div className={style.buttonsWrapper}>
                {value.selectedGenArts && (
                    <div className={style.readOnlyButton}>
                        <Icon name="article" />
                        <Badge skin="dark" value={value.selectedGenArts.length} />
                    </div>
                )}
                {value.selectedRepairTimes && (
                    <div className={style.readOnlyButton}>
                        <Icon name="repairtimes" />
                        <Badge skin="dark" value={value.selectedRepairTimes.length} />
                    </div>
                )}
                {/* {value.selectedConsumables && <Icon name={"repairtimes"}><Badge skin="dark" value={value.selectedRepairTimes?.length} /></Icon>} */}
            </div>
        )
    }

    const renderPanelTitle = (title: string, data: SelectedItems) => {
        return (
            <>
                <TitleHeader
                    title={title}
                    reandOnlyIcons={renderBadgeIcons(data)}
                    selectedStatusButton={data.selectedStatusButton}
                    className={style.header}
                    disableStatusButtonClick
                />
            </>
        )
    }

    const renderPanels = ([label, data]: [string, SelectedItems], idx: number) => {
        if (data.selectedStatusButton === "none") {
            return null
        }

        return <CustomPanel key={idx} title={renderPanelTitle(label, data)} initiallyClosed />
    }

    const renderFilterButtons = () => {
        return (
            <div className={classes(classNames.flexContainerWithGap, style.filterButtonsWrapper)}>
                <Text
                    onClick={handleFilterButtonsClick.bind(null, "none")}
                    className={bem(style.filtersButtons, activeFilterButton === "none" && "selected")}
                    size="l"
                >
                    {translateText(1205)}
                </Text>
                <Box>
                    <Text
                        onClick={handleFilterButtonsClick.bind(null, "defectButton")}
                        className={bem(style.filtersButtons, activeFilterButton === "defectButton" && "selected")}
                        size="l"
                    >
                        {translateText(13234)}
                    </Text>
                    {recordedDefects > 0 && <StyledBadge badgeContent={<Box style={{ color: "white" }}>{recordedDefects}</Box>} />}
                </Box>
                <Text
                    onClick={handleFilterButtonsClick.bind(null, "repairedButton")}
                    className={bem(style.filtersButtons, activeFilterButton === "repairedButton" && "selected")}
                    size="l"
                >
                    {translateText(12537)}
                </Text>
                <Text
                    onClick={handleFilterButtonsClick.bind(null, "checkButton")}
                    className={bem(style.filtersButtons, activeFilterButton === "checkButton" && "selected")}
                    size="l"
                >
                    {translateText(316)}
                </Text>
            </div>
        )
    }

    const renderContent = () => {
        if (!selectedWorks.size) {
            return (
                <div className="article-list__panel article-list__status">
                    <div className="article-list__no-result">
                        <Headline size="xs">{translateText(12775)}</Headline>
                        <Text size="s" modifiers={["block"]}>
                            {translateText(918)}
                        </Text>
                    </div>
                </div>
            )
        }

        return (
            <>
                {Array.from(selectedWorks, ([key, items], idx) => (
                    <div key={idx} className={style.wrapper}>
                        <Headline size="xs" className={style.headline}>
                            {translateText(key)}
                        </Headline>
                        {Object.entries(items).map(renderPanels)}
                    </div>
                ))}
            </>
        )
    }

    return (
        <>
            {renderFilterButtons()}
            <Scrollbar>
                <div className={classes(classNames.wrapperMargins, classNames.wrapperMinHeight)}>{renderContent()}</div>

                {activeStep === "maintenanceReview" && <NextStep currentStepName="maintenanceReview" buttonTextId={414} icon="arrows_down" active />}
                {activeStep === "inspection" && <NextStep currentStepName="inspection" buttonTextId={3113} icon="arrows_down" active />}
            </Scrollbar>
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            padding: "0 1em",
        },
        headline: {
            paddingTop: theme.margin.m,
            paddingBottom: theme.margin.m,
        },
        buttonsWrapper: {
            display: "flex",
        },
        readOnlyButton: {
            position: "relative",
            marginRight: theme.margin.s,
        },
        header: {
            justifyContent: "space-between",
        },
        filterButtonsWrapper: {
            justifyContent: "flex-end",
            marginTop: ".6em",
        },
        filtersButtons: {
            cursor: "pointer",
            borderBottom: "3px solid",
            borderColor: theme.colors.dark,
            padding: theme.margin.xs,
            borderRadius: theme.radius?.default,
            opacity: theme.opacity.secondary,
            $nest: {
                "&:hover": {
                    borderColor: theme.colors.primary,
                    color: theme.colors.primary,
                    opacity: theme.opacity.primary,
                },
                "&--selected": {
                    borderColor: theme.colors.primary,
                    color: theme.colors.primary,
                    opacity: theme.opacity.primary,
                },
            },
        },
    })(MaintenanceReview)
}
const StyledBadge = styled(MuiBadge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        padding: ".25rem",
        background: `${theme.colors?.dark}`,
        borderRadius: "1rem",
        border: "1px solid",
    },
    marginLeft: ".7em",
    bottom: "10px",
}))
