import { Box, Button, Icon, Modal, Tooltip, Typography } from "@tm/components"
import { AllowedFileSelectionTypes, FileSelectButton, FileSelectError } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useCallback, useState } from "react"
import { convertDataURIToDecodedString } from "@tm/utils"
import { useImportArticles, useTotals } from "../../../../../data/hooks/useCompilations"

const MAXFILESIZE = 1000
const FILETYPES: AllowedFileSelectionTypes = ["textTxt"]

type Props = {
    compilationId: string | undefined
}

export function ImportArticle({ compilationId }: Props) {
    const { translateText } = useLocalization()

    const [fileToUpload, setFileToUpload] = useState<string>("")
    const [openLayer, setOpenLayer] = useState<boolean>(false)

    const { totals } = useTotals(compilationId)
    const { importFile } = useImportArticles()

    const [error, setError] = useState<string>("")

    const uploadImportArticleStream = useCallback(
        (deleteExisting: boolean) => {
            setOpenLayer(false)
            if (!compilationId || !fileToUpload) {
                return
            }

            importFile({ stream: fileToUpload, deleteExisting, compilationId })
        },
        [compilationId, fileToUpload]
    )
    const openModal = useCallback(() => {
        setOpenLayer(true)
    }, [])

    const handleArticleImport = useCallback(
        (fileData: string) => {
            setFileToUpload(convertDataURIToDecodedString(fileData))
            if (totals?.positionCount) {
                openModal()
            }
        },
        [openModal, uploadImportArticleStream, totals?.positionCount]
    )

    const handleArticleImportError = useCallback((type: FileSelectError) => {
        if (type === "FILE_TOO_BIG") {
            setError(translateText(12797).replace("{0}", (MAXFILESIZE / 1000).toString()))
        }
        if (type === "WRONG_FILE_TYPE") {
            setError(translateText(12798).replace("{0}", FILETYPES.toString()))
        }
    }, [])

    return (
        <Box ml={2} display="flex">
            <FileSelectButton
                allowedFileTypes={FILETYPES}
                maxFileSize={MAXFILESIZE}
                onLoad={handleArticleImport}
                onError={handleArticleImportError}
                buttonProps={{
                    icon: "upload",
                    fakeButton: true,
                }}
                onOpenFileDialog={() => setError("")}
            >
                {translateText(46)}
            </FileSelectButton>
            <Modal maxWidth="sm" sx={{ minWidth: 5 }} onOutsideClick={() => setOpenLayer(false)} open={openLayer}>
                <Box p={3}>{translateText(13008)}</Box>
                <Box pb={3} px={3} display="flex" justifyContent="space-between" textAlign="center">
                    <Box flex={3} pr={2}>
                        <Button fullWidth onClick={() => uploadImportArticleStream(false)} variant="contained">
                            {translateText(584)}
                        </Button>
                    </Box>
                    <Box flex={3}>
                        <Button fullWidth onClick={() => uploadImportArticleStream(true)} variant="contained">
                            {translateText(585)}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            {error && (
                <Box my="auto" pl={1}>
                    <Tooltip title={<Typography>{error}</Typography>} variant="light">
                        <div>
                            <Icon name="alert" color="error" />
                        </div>
                    </Tooltip>
                </Box>
            )}
        </Box>
    )
}
