import { PrintImage } from "../../models"
import { fastServiceStore } from "../store"
import { getInitialState } from "."

export function resetImages() {
    fastServiceStore.setState(getInitialState(), false, "Reset images slice")
}

export function addImage(step: string, work: string, imageData: PrintImage) {
    const state = fastServiceStore.getState()
    const availableImages = { ...state.imagesState.images }

    if (!availableImages[step]) {
        availableImages[step] = {}
    }

    if (!availableImages[step][work]) {
        availableImages[step][work] = []
    }

    availableImages[step][work].push(imageData)

    fastServiceStore.setState(
        {
            imagesState: {
                images: availableImages,
            },
        },
        false,
        "Add image"
    )
}

export function replaceImage(step: string, work: string, imageData: PrintImage) {
    const state = fastServiceStore.getState()
    const availableImages = { ...state.imagesState.images }

    if (availableImages[step]?.[work]) {
        const newImages = availableImages[step][work].map((image) => {
            if (image.id === imageData.id) {
                return imageData
            }

            return image
        })

        availableImages[step][work] = newImages
    }

    fastServiceStore.setState(
        {
            imagesState: {
                images: availableImages,
            },
        },
        false,
        "Replace image"
    )
}

export function deleteImage(step: string, work: string, id: string) {
    const state = fastServiceStore.getState()
    const availableImages = { ...state.imagesState.images }

    if (availableImages[step]?.[work]) {
        const newImages = availableImages[step][work].filter((image) => image.id !== id)
        availableImages[step][work] = newImages
    }

    fastServiceStore.setState(
        {
            imagesState: {
                images: availableImages,
            },
        },
        false,
        "Delete image"
    )
}

export function getImage(step: string, work: string) {
    const state = fastServiceStore.getState()
    return state.imagesState.images[step]?.[work]
}
