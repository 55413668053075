import { EFilterNames, ETyresSeason } from "@tm/models"
import { PrintImage, TyreAxles } from "."

export type TabIdentifier = {
    tabType: string
    collapsibleName: string
}

export enum TireType {
    SpareTire,
    RearTire,
}

export type WheelsAndTyresTab = {
    index: number
    name: string
    translation: number
    isComplete: boolean
    collapsibleItems: CollapsibleInfo[]
    type: string
    selectedFilters: SelectedFilters
}

export type CollapsibleInfo = {
    name: string
    translateTextId: number
    completed: boolean
    selectedImages?: PrintImage[]
    infoData: CollapsibleData
    safetyStatus?: number
    description?: string
    tyreWear?: TyreWear
}

export type SelectedFilters = {
    width: string
    height: string
    inch: string
    loadIndex: string
    speedIndex: string
    size: string
    manufacturerId: string
    manufacturer: string
    tyreModel: string
    season: string
    rimSize: string
    offsetValue?: string
    isWinter?: boolean
    isSummer?: boolean
    vehicleLabel?: string
    sizeFromSpecs?: string
    axleSpecs?: TyreAxles
}

export type SizeSpecs = {
    width: string
    height: string
    inch: string
    loadIndex: string
    speedIndex: string
}

export type AdditionalSpecs = {
    manufacturer: string
    manufacturerId: string
    tyreModel: string
}

export type CollapsibleData = {
    rimCondition?: "aluminium" | "steel" | "none"
    profileDepth?: number
    dot?: number
    tyrePressure?: number
    brakeSystem?: Date
}

export type TyreWear = {
    left?: boolean
    center?: boolean
    right?: boolean
}

export type TyreModelsWithSeason = {
    seasonPrefix: string
    modelName: string
}

export const ExtendEFilterNames = {
    ...EFilterNames,
    size: "size",
    rimSize: "rimSize",
    manufacturerId: "manufacturerId",
    vehicleLabel: "vehicleLabel",
    sizeFromSpecs: "sizeFromSpecs",
}

export enum SeasonPrefixes {
    Summer = "SUM",
    Winter = "WIN",
    AllSeason = "ALL",
}

export enum WheelsAndTyresTabNames {
    FrontLeft = "frontLeft",
    FrontRight = "frontRight",
    RearLeft = "rearLeft",
    RearRight = "rearRight",
    SpareWheel = "spareWheel",
}

export const ExtendETyreSeason = {
    ...ETyresSeason,
    spareTyre: 3, // spare tyres are in season because HP seems to handle them like that
}
