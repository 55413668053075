import { QueryFunctionContext, useQuery } from "react-query"
import { DirectSearchListParams, OeReplacementData } from "../../../models"
import { OeReplacementManufacturer } from "../../../../../data/model"
import * as PartsRepository from "../../../../../data/repositories"

const QUERY_KEY = "OeReplacement"

type QueryKey = [string, string]

export function useOeReplacement(params: DirectSearchListParams, isEnabled: boolean): OeReplacementData {
    const { query } = params.startParams

    const findeOeReplacement = useQuery({
        enabled: isEnabled,
        queryKey: [QUERY_KEY, query],
        queryFn: getOeReplacements,
    })

    return { isLoading: findeOeReplacement.isLoading, replacementData: findeOeReplacement.data }
}

function getOeReplacements({ queryKey: [, query] }: QueryFunctionContext<QueryKey>): Promise<OeReplacementManufacturer[]> {
    return PartsRepository.getOeReplacements(query)
}
