import { createContext, useContextSelector } from "use-context-selector"
import { FiltersData, FilterActions } from "../../models"
import { ensureContextAvailability } from "../../helpers"

export type FiltersContextData = FiltersData & FilterActions
export const FiltersContext = createContext<FiltersContextData | undefined>(undefined)

export function useFiltersContext<TContext extends FiltersContextData>(): TContext
export function useFiltersContext<TContext extends FiltersContextData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useFiltersContext<TContext extends FiltersContextData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(FiltersContext, (context) => {
        const validatedContext = ensureContextAvailability("FiltersContext", context)
        if (!selector) {
            return validatedContext
        }
        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
