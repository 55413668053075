import { useState } from "react"
import { ArticlesContext, ArticlesProviderProps, useArticlesContext } from "../../../ContextProvider/Articles"
import { useAfterPaintEffect } from "../../../helpers"
import { useVehicle } from "../../../hooks"
import { OePartsArticleData } from "../../../models"
import { useOePartsListParamsContext } from "../ListParams"
import { useArticles } from "./useArticles"

export function OePartsArticlesProvider({ setHasRendered, erpInfos, children }: ArticlesProviderProps) {
    const params = useOePartsListParamsContext()
    const vehicle = useVehicle()
    const [startRender, setStartRender] = useState(false)
    const articlesData = useArticles(vehicle, params, erpInfos, startRender)

    useAfterPaintEffect(() => {
        setStartRender(true)
    }, [])

    useAfterPaintEffect(() => {
        if (articlesData.requestStatus === "success") {
            setHasRendered(true)
        }
    }, [articlesData.requestStatus])

    return <ArticlesContext.Provider value={articlesData}>{children}</ArticlesContext.Provider>
}

export function useOePartsArticlesContext<TContext extends OePartsArticleData>(): TContext
export function useOePartsArticlesContext<TContext extends OePartsArticleData, TReturnType>(selector: (val: TContext) => TReturnType): TReturnType
export function useOePartsArticlesContext<TContext extends OePartsArticleData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useArticlesContext(selector as never)
}
