import { SupplierArticleQuantitiesRequest } from "@tm/models"
import { ajaxAuth, notUndefinedOrNull } from "@tm/utils"
import { getServiceUrl } from "../../.."
import { ShowQuantitiesBySupplierArticleRequest, ShowQuantitiesBySupplierArticleResponse } from "../../../model"

// TODO: Remove when the artilce list V2 doesn't use it anymore
export function getShowQuantitiesBySupplierArticle(requests: SupplierArticleQuantitiesRequest[]) {
    const url = `${getServiceUrl()}/ShowQuantitiesBySupplierArticle`
    const body: ShowQuantitiesBySupplierArticleRequest = {
        workTaskId: requests[requests.length - 1].workTaskId,
        articles: requests.map((r) => r.article),
    }

    return ajaxAuth<ShowQuantitiesBySupplierArticleResponse>({ url, body, method: "POST" }).then(
        (data) => data?.supplierArticleQuantities?.filter(notUndefinedOrNull) || []
    )
}
