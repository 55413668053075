import { useState } from "react"
import { Button, Icon, Dialog, Typography, Stack } from "@tm/components"

import { useLocalization } from "@tm/localization"
import { useBasketModuleParameter, useCostEstimationModuleParameter } from "@tm/context-distribution"
import { useParams } from "react-router"
import { useVoucherButtonsActions } from "../../../business/hooks/useVoucherButtonsActions"
import { useOrderDetails } from "../../../business/hooks/useOrderDetails"
import { VoucherRouteParams } from "../../../../../business"

export default function OrderActionButtons() {
    const { translateText } = useLocalization()
    const matchParams = useParams<VoucherRouteParams>()
    const voucherId = matchParams.id && decodeURIComponent(matchParams.id)
    const { orderButtonAction } = useVoucherButtonsActions()
    const { hasWorkTaskOrder } = useBasketModuleParameter()
    const { hasCostEstimation } = useCostEstimationModuleParameter()
    const [showAddDialog, setShowAddDialog] = useState<boolean>(false)
    const { orderDetails, orderDetailsLoading } = useOrderDetails(voucherId)

    const buttonDisabled = !orderDetails?.totals.numberOfParts || orderDetailsLoading

    function handleOrderParts(newWorkTask: boolean) {
        orderButtonAction(orderDetails, newWorkTask, !hasWorkTaskOrder)
        setShowAddDialog(false)
    }
    if (!hasCostEstimation && !hasWorkTaskOrder) {
        return null
    }

    return (
        <>
            <Button
                color="highlight"
                startIcon={<Icon name="cart" />}
                disabled={buttonDisabled}
                onClick={() => setShowAddDialog(true)}
                title={translateText(937)}
            >
                {translateText(133)}
            </Button>
            <Dialog
                onOutsideClick={() => setShowAddDialog(false)}
                open={showAddDialog}
                position="top"
                skin="info"
                customSkinIcon={<Icon name="question" />}
            >
                <Stack px={3} gap={2}>
                    <Typography>{translateText(hasWorkTaskOrder ? 1226 : 13885)}</Typography>
                    <Stack direction="row" gap={1}>
                        <Button size="large" value={translateText(hasWorkTaskOrder ? 1227 : 13886)} onClick={() => handleOrderParts(false)} />
                        <Button size="large" color="success" value={translateText(1228)} onClick={() => handleOrderParts(true)} />
                    </Stack>
                </Stack>
            </Dialog>
        </>
    )
}
