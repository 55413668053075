import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { useArticlesContext } from "../Articles/ArticlesContext"
import { ensureContextAvailability } from "../../helpers"
import { useProductGroupRepairTimes } from "./useProductGroupRepairTimes"
import { ProductGroupRepairTimesData } from "../../models"

export const ProductGroupRepairTimesContext = createContext<ProductGroupRepairTimesData | undefined>(undefined)

export function ProductGroupRepairTimesProvider(props: PropsWithChildren<{ isEnabled: boolean }>) {
    const { productGroupIds } = useArticlesContext()
    const productGroupRepairTimesData = useProductGroupRepairTimes(productGroupIds, props.isEnabled)

    return <ProductGroupRepairTimesContext.Provider value={productGroupRepairTimesData}>{props.children}</ProductGroupRepairTimesContext.Provider>
}

export function useProductGroupRepairTimesDataContext(): ProductGroupRepairTimesData {
    const context = useContext(ProductGroupRepairTimesContext)
    return ensureContextAvailability("ProductGroupRepairTimesContext", context)
}
