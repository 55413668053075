import { useQuery } from "react-query"
import { useCallback, useState } from "react"
import { useHandlePostSendOrderAction } from "../../hooks/useHandlePostSendOrderAction"
import * as Data from ".."
import { useBasketUpdateWorkflow } from "./workTaskBasket/workflow/useBasketUpdateWorkflow"
import { ExportToExternalBasketResponse } from "../model/ThirdPartyBasket"

export function useThirdPartyBasket(workTaskId: string, handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { handlePostSendOrderAction, externalModalUrl, resetExternalModalUrl } = useHandlePostSendOrderAction()
    const {
        data: importExternalBasketResponse,
        isError: importError,
        refetch: importFromExternalBasket,
        isFetching: importFetching,
    } = useQuery({
        queryKey: ["basket_importFromExternalBasket", workTaskId],
        queryFn: () => {
            return Data.importFromExternalBasket(workTaskId)
        },
        enabled: false,
        onSuccess: (response) => {
            if (response?.basketUpdateWorkflow) {
                handleBasketUpdateWorkflow(workTaskId, response)
            }
        },
    })

    // TODO: At the moment,it is not possible to use react-query to accept an extra parameter in the refetch function.
    // If you know how, please useREqct-query
    const [exportError, setExportError] = useState(false)
    const [exportFetching, setExportFetching] = useState(false)
    const [exportExternalBasketResponse, setExportExternalBasketResponse] = useState<ExportToExternalBasketResponse | undefined>(undefined)
    const exportToExternalBasket = useCallback(
        (isWidget?: boolean, throwOnError?: boolean) => {
            setExportError(false)
            setExportFetching(true)
            Data.exportToExternalBasket({ workTaskId, isWidget })
                .then((response) => {
                    setExportExternalBasketResponse(response)
                    if (response?.basketUpdateWorkflow) {
                        handleBasketUpdateWorkflow(workTaskId, response)
                    }
                    if (response?.postSendOrderActions) {
                        handlePostSendOrderAction(response.postSendOrderActions)
                    }
                })
                .catch((error) => {
                    if (error) {
                        setExportError(true)
                    }
                    if (throwOnError) {
                        throw new Error(error)
                    }
                })
                .finally(() => {
                    setExportFetching(false)
                })
        },
        [handleBasketUpdateWorkflow, handlePostSendOrderAction, workTaskId]
    )

    return {
        importFromExternalBasket,
        exportToExternalBasket,
        externalBasketLoading: importFetching || exportFetching,
        externalBasketPostSendActions: exportExternalBasketResponse?.postSendOrderActions,
        externalBasketErrorDetails: importExternalBasketResponse?.errorDetails ?? exportExternalBasketResponse?.errorDetails,
        externalBasketHasTechnicalErrors: importError || exportError,
        externalModalUrl,
        resetExternalModalUrl,
    }
}
