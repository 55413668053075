import { useMemo } from "react"
import { ArticleErpInfos, OE } from "@tm/models"
import { useArticlesErpInfos } from "./useArticlesErpInfos"
import { mapOePartToArticle } from "../../../../data/mapper/article"
import { ErpInfosData } from "../../models"

export function useOePartErpInfos(oePart: OE.OePart | undefined, quantity = 1, erpInfosData: ErpInfosData): ArticleErpInfos | undefined {
    const articles = useMemo(() => {
        if (!oePart) {
            return []
        }
        const article = mapOePartToArticle(oePart, quantity)
        return [article]
    }, [oePart, quantity])

    return useArticlesErpInfos(articles, quantity, erpInfosData).first() ?? { default: undefined, alternative: undefined }
}
