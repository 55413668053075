import { useWorkTask } from "@tm/context-distribution"
import { EModuleId, ModuleId, VehicleType } from "@tm/models"

export const useAlternativeIcon = (moduleId?: ModuleId): string | undefined => {
    const { workTask } = useWorkTask() ?? {}

    if (!moduleId) {
        return undefined
    }

    const vehicle = workTask?.vehicle
    const isVehicleTab = moduleId === EModuleId.vehicle

    if (!vehicle || !isVehicleTab) {
        return undefined
    }

    switch (vehicle.vehicleType) {
        case VehicleType.PassengerCar:
            return "car"
        case VehicleType.Motorcycle:
            return "bike"
        case VehicleType.CommercialVehicle:
            return "truck"
        default:
            return undefined
    }
}
