import { KeyboardEvent, SyntheticEvent, useEffect, useMemo, useState } from "react"
import { Box, Button, Stack, Tab, TabPanel, Tabs, Typography, styled, LicensePlate } from "@tm/components"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { DateField, Loader, PanelSection, SuggestionTextField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import {
    CarModelDetails,
    LicensePlateComponentInitiator,
    MileType,
    RegistrationNoDetailsSimple,
    RegistrationNoType,
    TruckModelDetails,
    Vehicle,
} from "@tm/models"
import { clone, equals, getVehicleRegistrationNoLabelTextIds } from "@tm/utils"
import * as Data from "../../../data/repositories/vin-picker"
import { engineCode, kba, onlyDigits } from "../../../helpers"
import { constructionYearToDate } from "../../../helpers/construction-year"
import { VinInputField } from "../../_shared/VinInput"
import { VehicleDataProgress } from "../../_shared/vehicle-data-progress"

type Props = {
    vehicle: Vehicle
    regNoType: RegistrationNoType | undefined
    defaultItem: string
    onChange(vehicle: Vehicle): Promise<void>
    onAbort(): void
    onShowPartsIndicator?(): void
    modelDetails?: CarModelDetails | TruckModelDetails
    regNoDetails?: RegistrationNoDetailsSimple
}

const StyledBox = styled(Box)(() => ({
    minHeight: "3em",
    margin: "0.5em 0",

    "& > *": {
        maxWidth: "50%",
    },
}))

const items = ["plateId", "regNo", "initialRegistration", "mileAge", "vin", "motorcode"] // Defines the order of menu items
export default function EditorComponent(props: Props) {
    const { translateText, translate } = useLocalization()

    const [isSaving, setIsSaving] = useState<boolean>()
    const [validVin, setValidVin] = useState<boolean>()

    const { modelDetails, regNoType, defaultItem } = props
    const [activeNavIdentifier, setActiveNavIdentifier] = useState<string>(defaultItem)
    const [vehicle, setVehicle] = useState<Vehicle>(() => clone(props.vehicle))
    const [latestValidVin, setLatestValidVin] = useState(props.vehicle.vin)
    const { plateCode } = useCountryCodeToLicensePlate(props.vehicle?.countryCode)
    useEffect(() => {
        setVehicle(clone(props.vehicle))
        setActiveNavIdentifier(defaultItem)
    }, [props.vehicle, defaultItem])

    const isDirty = useMemo(() => !equals(vehicle, props.vehicle), [vehicle, props.vehicle])
    const saveEnabled = useMemo(
        () => isDirty && (activeNavIdentifier !== "vin" || !vehicle.vin?.length || (!!vehicle.vin?.length && validVin)),
        [isDirty, vehicle, validVin, activeNavIdentifier]
    )

    function getVinSuggestions(query: string): Promise<Array<string>> {
        return new Promise((resolve, reject) => {
            if (!props.vehicle?.tecDocTypeId) {
                resolve([])
                return
            }

            Data.getVinSuggestions(query, props.vehicle?.tecDocTypeId).then((data) => {
                if (data.length === 1 && data[0].toLowerCase() === query.toLowerCase()) {
                    resolve([])
                    return
                }

                resolve(data)
            }, reject)
        })
    }

    function handleGetRegistrationNoSuggestions(): string[] {
        return (modelDetails as CarModelDetails)?.registrationNos || (props.vehicle?.registrationNo ? [props.vehicle.registrationNo] : [])
    }

    function handleChange(vehicle: Vehicle, _path?: Array<string>) {
        const newVehicle: Vehicle = { ...vehicle }
        setVehicle(newVehicle)
        setValidVin(validVin)
    }

    function handleVinChange(vin: string, isValid?: boolean) {
        isValid && setLatestValidVin(vin)

        const newVehicle: Vehicle = { ...vehicle, vin }

        if (newVehicle.vin === null) {
            delete newVehicle.vin
        }

        setVehicle(newVehicle)
        setValidVin(isValid)
    }

    function handleChangeMileAge(mileAge: string) {
        const parsedMileAge = parseInt(mileAge)

        const tempVehicle = {
            ...vehicle,
            mileAge: !isNaN(parsedMileAge) ? parsedMileAge : undefined,
            mileType: vehicle.mileType || (!isNaN(parsedMileAge) ? MileType.Kilometer : undefined),
        }

        // Fix "isDirty" being true due to "mileAge" being "undefined" and not missing in vehicle
        if (tempVehicle.mileAge === undefined) {
            delete tempVehicle.mileAge
        }

        // Fix "isDirty" being true due to "mileType" being "undefined" and not missing in vehicle
        if (tempVehicle.mileType === undefined) {
            delete tempVehicle.mileType
        }

        handleChange(tempVehicle)
    }

    function handleChangeInitialRegistration(date: Date | null) {
        handleChange({
            ...vehicle,
            initialRegistration: date ?? undefined, // Fix "isDirty" being true due to "initialRegistration" being "null" and not "undefined"
        })
    }

    function handleChangeRegistrationNo(registrationNo: string) {
        handleChange({
            ...vehicle,
            registrationNo,
            registrationTypeId: registrationNo && !vehicle.registrationTypeId ? regNoType : vehicle.registrationTypeId,
        })
    }

    function handleTabChange(event: SyntheticEvent, newValue: any) {
        setActiveNavIdentifier(newValue)
    }

    function handleInitialRegistrationRef(ref: any) {
        ref?.focus?.()
    }

    function handleKeyPress(e: KeyboardEvent) {
        switch (e.key) {
            case "Enter":
                if (saveEnabled) {
                    handleSave()
                }
                break
            case "Escape": {
                props.onAbort()
                break
            }
            case "Tab": {
                e.preventDefault()
                const currentIndex = items.indexOf(activeNavIdentifier)
                const activeNavigationKey = !e.shiftKey ? items[currentIndex + 1] || items[0] : items[currentIndex - 1] || items[items.length - 1]
                setActiveNavIdentifier(activeNavigationKey)
                break
            }
            default:
        }
    }

    function handleSave() {
        if (isSaving) {
            return
        }

        setIsSaving(true)

        if (vehicle.vin) {
            props.onChange({ ...vehicle, vin: latestValidVin })
        } else {
            props.onChange(vehicle)
        }
    }

    function renderPlateIdField() {
        return (
            <TabPanel value="plateId" index={activeNavIdentifier}>
                <LicensePlate
                    style={{ height: "2.1em", width: "8.5em" }}
                    countryCode={plateCode}
                    size="medium"
                    value={vehicle.plateId ?? ""}
                    maxLength={17}
                    autoFocus
                    isReadOnly={false}
                    countryCodeFontSize="large"
                    licensePlateComponentInitiator={LicensePlateComponentInitiator.VehicleEditor}
                    onChangePlate={handleChange}
                    vehicle={vehicle}
                />
            </TabPanel>
        )
    }

    function renderInitialRegistrationField() {
        const constructionYearFrom = modelDetails?.constructionYearFrom ? constructionYearToDate(modelDetails.constructionYearFrom) : undefined
        return (
            <TabPanel value="initialRegistration" index={activeNavIdentifier}>
                <DateField
                    floatingLabel
                    label={translateText(124)}
                    value={vehicle.initialRegistration}
                    onChange={handleChangeInitialRegistration}
                    minDate={constructionYearFrom}
                    maxDate={new Date()}
                    openToDate={vehicle.initialRegistration ?? constructionYearFrom}
                    autoFocus
                    ref={handleInitialRegistrationRef}
                    useUtc
                />
            </TabPanel>
        )
    }

    function renderMileAgeField() {
        return (
            <TabPanel value="mileAge" index={activeNavIdentifier}>
                <TextField
                    floatingLabel
                    label={translateText(125)}
                    value={vehicle.mileAge?.toString()}
                    maxLength={7}
                    onChange={handleChangeMileAge}
                    formatter={onlyDigits} // ML 30.09.2020 - Removed dot formatting because it is shitty to edit the mileage then
                    autoFocus
                />
            </TabPanel>
        )
    }

    function renderVinField() {
        return (
            <TabPanel value="vin" index={activeNavIdentifier}>
                <VinInputField
                    showEdit
                    vehicleData={vehicle}
                    textFieldProps={{
                        floatingLabel: true,
                        label: translateText(101),
                    }}
                    getVinSuggestions={getVinSuggestions}
                    onVinChange={handleVinChange}
                    onVinChangeConfirm={handleVinChange}
                />
            </TabPanel>
        )
    }

    const handleGetEngineCodeSuggestions = (): Array<string> => {
        if (!modelDetails && !props.regNoDetails) {
            return []
        }

        return props.regNoDetails?.engineCodes?.length ? props.regNoDetails.engineCodes : modelDetails?.engineCodes || []
    }

    function renderMotorcode() {
        return (
            <TabPanel value="motorcode" index={activeNavIdentifier}>
                <SuggestionTextField
                    floatingLabel
                    label={translateText(105)}
                    model={vehicle}
                    path={["engineCode"]}
                    getSuggestionData={handleGetEngineCodeSuggestions}
                    onChange={handleChange}
                    onChangeConfirm={handleChange}
                    formatter={engineCode}
                />
            </TabPanel>
        )
    }

    function renderRegNoField() {
        const itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(regNoType)
        const regNoLabel = itemLabelTextIds?.labelShort ?? itemLabelTextIds?.label ?? 103

        return (
            <TabPanel value="regNo" index={activeNavIdentifier}>
                <SuggestionTextField
                    floatingLabel
                    label={translateText(regNoLabel)}
                    value={vehicle.registrationNo}
                    getSuggestionData={handleGetRegistrationNoSuggestions}
                    onChange={handleChangeRegistrationNo}
                    onChangeConfirm={handleChangeRegistrationNo}
                    formatter={kba}
                />
            </TabPanel>
        )
    }

    function renderActivePageContent() {
        switch (activeNavIdentifier) {
            default:
            case "plateId":
                return renderPlateIdField()
            case "regNo":
                return renderRegNoField()
            case "initialRegistration":
                return renderInitialRegistrationField()
            case "mileAge":
                return renderMileAgeField()
            case "vin":
                return renderVinField()
            case "motorcode":
                return renderMotorcode()
        }
    }

    const itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(regNoType)
    const regNoLabel = itemLabelTextIds?.labelShort ?? itemLabelTextIds?.label

    return (
        <PanelSection className="editor">
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h1">{translate(178)}</Typography>

                {isSaving && <Loader />}
                <Stack direction="row" spacing={0.5}>
                    <Button onClick={props.onAbort} disabled={isSaving}>
                        {translate(317)}
                    </Button>
                    <Button color="success" disabled={isSaving || !saveEnabled} onClick={handleSave}>
                        {translate(9)}
                    </Button>
                </Stack>
            </Stack>

            <Tabs value={activeNavIdentifier} variant="scrollable" onChange={handleTabChange}>
                <Tab value="plateId" label={translateText(21)} />
                {regNoLabel ? <Tab value="regNo" label={translateText(regNoLabel)} /> : undefined}
                <Tab value="initialRegistration" label={translateText(124)} />
                <Tab value="mileAge" label={translateText(125)} />
                <Tab value="vin" label={translateText(101)} />
                <Tab value="motorcode" label={translateText(105)} />
            </Tabs>

            <StyledBox onKeyDown={handleKeyPress}>{renderActivePageContent()}</StyledBox>

            <Typography variant="label" pl="6px">
                {translateText(1083)}
            </Typography>

            <VehicleDataProgress vehicle={vehicle} hasEngineCodes={!!modelDetails?.engineCodes?.length} onClick={props.onShowPartsIndicator} />
        </PanelSection>
    )
}
