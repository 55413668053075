import { getInitialState, TyresWheelsSlice } from "."
import { CollapsibleData, SelectedFilters, TabIdentifier, TireType, TyreWear, WheelsAndTyresTab, TyreAxles } from "../../models"
import { DriveRightTiresRespone, TyresCritsResponse } from "../../repositories"
import { TireBrands } from "../../repositories/tires-tiresBrands/model"
import { fastServiceStore } from "../store"
import { isSameTires } from "./helper"
import { defaultSelectedFilters } from "./staticData"

export function resetTyresWheels() {
    fastServiceStore.setState(getInitialState(), false, "Reset Tyres Wheels Slice")
}

export function updateDescription(description: string, path: TabIdentifier) {
    const state = fastServiceStore.getState()
    const tyresWheelsState = state.tyresWheels
    const { collapsibleName, tabType } = path

    const newTabs = getNewTabs<string>(state, tabType, collapsibleName, "description", description)
    const selectedTab = newTabs.find((x) => x.name === tabType)

    fastServiceStore.setState(
        {
            tyresWheels: {
                ...tyresWheelsState,
                tabs: newTabs,
                selectedTab: selectedTab ?? { ...tyresWheelsState.selectedTab },
            },
        },
        false,
        "Update description"
    )
}

export function updateSafetyStatus(safetyStatus: number, path: TabIdentifier) {
    const state = fastServiceStore.getState()
    const tyresWheelsState = state.tyresWheels

    const { collapsibleName, tabType } = path

    const newTabs = getNewTabs<number>(state, tabType, collapsibleName, "safetyStatus", safetyStatus)
    const selectedTab = newTabs.find((x) => x.name === tabType)

    fastServiceStore.setState(
        {
            tyresWheels: {
                ...tyresWheelsState,
                tabs: newTabs,
                selectedTab: selectedTab ?? { ...tyresWheelsState.selectedTab },
            },
        },
        false,
        "Update safety status"
    )
}

export function setTyreSpecification(tyreSpecification: TyreAxles) {
    fastServiceStore.setState(
        (state) => ({
            tyresWheels: {
                ...state.tyresWheels,
                selectedTyreSpecification: tyreSpecification,
            },
        }),
        false,
        "Set tyre specification"
    )
}

export function updateTyreWear(tyreWear: TyreWear, path: TabIdentifier) {
    const state = fastServiceStore.getState()
    const tyresWheelsState = state.tyresWheels
    const { collapsibleName, tabType } = path

    const newTabs = getNewTabs<TyreWear>(state, tabType, collapsibleName, "tyreWear", tyreWear)
    const selectedTab = newTabs.find((x) => x.name === tabType)

    fastServiceStore.setState(
        {
            tyresWheels: {
                ...tyresWheelsState,
                tabs: newTabs,
                selectedTab: selectedTab ?? { ...tyresWheelsState.selectedTab },
            },
        },
        false,
        "Update tyre wear"
    )
}

export function setSelectedTireType(selectedTireType: TireType) {
    fastServiceStore.setState(
        (state) => ({
            tyresWheels: {
                ...state.tyresWheels,
                selectedTire: selectedTireType,
            },
        }),
        false,
        "Set selected tire type"
    )
}

export function updateShowBonus(showBonus: boolean) {
    fastServiceStore.setState(
        (state) => ({
            tyresWheels: {
                ...state.tyresWheels,
                showBonus,
            },
        }),
        false,
        "Update show bonus"
    )
}

export function updateSpareWheel(hasSpareWheel: boolean) {
    fastServiceStore.setState(
        (state) => ({
            tyresWheels: {
                ...state.tyresWheels,
                spareWheel: hasSpareWheel,
            },
        }),
        false,
        "Update spare wheel"
    )
}

export function setTyresCritsError() {
    fastServiceStore.setState(
        (state) => ({
            tyresWheels: {
                ...state.tyresWheels,
                loadingCrits: false,
            },
        }),
        false,
        "Set tyres crits error"
    )
}

export function setTyresCritsLoaded(tyresCrits: TyresCritsResponse) {
    fastServiceStore.setState(
        (state) => ({
            tyresWheels: {
                ...state.tyresWheels,
                tyresCrits,
                loadingCrits: false,
            },
        }),
        false,
        "Set tyres crits loaded"
    )
}

export function setTyresCritsLoading() {
    fastServiceStore.setState(
        (state) => ({
            tyresWheels: {
                ...state.tyresWheels,
                loadingCrits: true,
            },
        }),
        false,
        "Set tyres crits loading"
    )
}

export function updateCollapsibleData(tabName: string, collapsibleName: string, data: CollapsibleData) {
    const state = fastServiceStore.getState()
    const tyresWheelsState = state.tyresWheels
    let newTabs: WheelsAndTyresTab[]

    if (collapsibleName === "rimCondition") {
        newTabs = tyresWheelsState.tabs.map((x) => ({
            ...x,
            collapsibleItems: x.collapsibleItems.map((y) => (y.name === collapsibleName ? { ...y, infoData: data } : { ...y })),
        }))
    } else {
        newTabs = tyresWheelsState.tabs.map((x) =>
            x.name === tabName
                ? { ...x, collapsibleItems: x.collapsibleItems.map((y) => (y.name === collapsibleName ? { ...y, infoData: data } : { ...y })) }
                : { ...x }
        )
    }

    fastServiceStore.setState(
        {
            tyresWheels: {
                ...tyresWheelsState,
                tabs: newTabs,
            },
        },
        false,
        "Update collapsible data"
    )
}

export function updateSelectedFilters(tabName: string, tabType: string, selectedFilters: SelectedFilters) {
    const state = fastServiceStore.getState()
    const tyresWheelsState = state.tyresWheels
    let newTabs: WheelsAndTyresTab[]

    if (tabType === "front" && tyresWheelsState.selectedTire === undefined) {
        newTabs = tyresWheelsState.tabs.map((x) => ({
            ...x,
            selectedFilters: {
                ...selectedFilters,
                size: `${selectedFilters.width}/${selectedFilters.height} R${selectedFilters.inch} ${selectedFilters.loadIndex}${selectedFilters.speedIndex}`,
            },
        }))
    } else {
        newTabs = tyresWheelsState.tabs.map((x) =>
            x.type === tabType
                ? {
                      ...x,
                      selectedFilters: {
                          ...selectedFilters,
                          size: `${selectedFilters.width}/${selectedFilters.height} R${selectedFilters.inch} ${selectedFilters.loadIndex}${selectedFilters.speedIndex}`,
                      },
                  }
                : { ...x }
        )
    }

    fastServiceStore.setState(
        {
            tyresWheels: {
                ...tyresWheelsState,
                tabs: newTabs,
                selectedTab: {
                    ...tyresWheelsState.selectedTab,
                    selectedFilters,
                },
            },
        },
        false,
        "Update selected filters"
    )
}

export function setTyresLoaded(data: DriveRightTiresRespone, tecDocUsed: number, tiresBrands: TireBrands) {
    fastServiceStore.setState(
        (state) => ({
            tyresWheels: {
                ...state.tyresWheels,
                tiresData: {
                    ...data,
                    loading: false,
                },
                tireBrands: tiresBrands,
                tecDocUsed,
            },
        }),
        false,
        "Set tyres loaded"
    )
}

export function setErrorTyres() {
    fastServiceStore.setState(
        (state) => ({
            tyresWheels: {
                ...state.tyresWheels,
                tiresData: {
                    frontTires: [],
                    rearTires: [],
                    loading: false,
                },
            },
        }),
        false,
        "Set error tyres"
    )
}

export function setTyresLoading() {
    fastServiceStore.setState(
        (state) => ({
            tyresWheels: {
                ...state.tyresWheels,
                tiresData: {
                    ...state.tyresWheels.tiresData,
                    loading: true,
                },
            },
        }),
        false,
        "Set tyres loading"
    )
}

export function selectTab(tab: WheelsAndTyresTab) {
    fastServiceStore.setState(
        (state) => ({
            tyresWheels: {
                ...state.tyresWheels,
                selectedTab: tab,
            },
        }),
        false,
        "Select tab"
    )
}

export function completeCollapsible(tabName: string, tabType: string, collapsibleName: string) {
    const state = fastServiceStore.getState()
    const tyresWheelsState = state.tyresWheels

    let newTabs: WheelsAndTyresTab[]

    // TODO hardcodes values on string type
    if (
        (collapsibleName === "general" && isSameTires(state, defaultSelectedFilters) && tabType !== "rear" && tabType !== "spareWheel") ||
        collapsibleName === "rimCondition"
    ) {
        newTabs = tyresWheelsState.tabs.map((x) => ({
            ...x,
            collapsibleItems: x.collapsibleItems.map((y) => (y.name === collapsibleName ? { ...y, completed: true } : { ...y })),
        }))
    } else {
        newTabs = tyresWheelsState.tabs.map((x) =>
            x.name === tabName
                ? {
                      ...x,
                      isComplete: true,
                      collapsibleItems: x.collapsibleItems.map((y) => (y.name === collapsibleName ? { ...y, completed: true } : { ...y })),
                  }
                : { ...x }
        )
    }

    // check if all collapsibles are complete
    newTabs = newTabs.map((tab) => ({
        ...tab,
        isComplete:
            tab.name === "spareWheel"
                ? !tyresWheelsState.spareWheel
                    ? tab.collapsibleItems.filter((x) => x.name === "brakeSystem").last()?.completed || false
                    : tab.collapsibleItems.filter((x) => x.name !== "brakeSystem").every((colaps) => colaps.completed)
                : tab.collapsibleItems.every((colaps) => colaps.completed),
    }))

    const { index, isComplete } = newTabs?.filter((tab) => tab.isComplete).last() ?? {}

    fastServiceStore.setState(
        {
            tyresWheels: {
                ...tyresWheelsState,
                tabs: newTabs,
                ...(!!newTabs &&
                    isComplete &&
                    index !== undefined && {
                        selectedTab: newTabs.find((tab) => tab.index === index + 1) || newTabs[newTabs.length - 1],
                    }),
            },
        },
        false,
        "Complete collapsible"
    )
}

function getNewTabs<T>(tyresWheelsState: TyresWheelsSlice, tabType: string, collapsibleName: string, key: string, value: T) {
    const newTabs: WheelsAndTyresTab[] = tyresWheelsState.tyresWheels.tabs.map((x) =>
        x.name === tabType
            ? {
                  ...x,
                  collapsibleItems: x.collapsibleItems.map((y) =>
                      y.name === collapsibleName
                          ? {
                                ...y,
                                [key]: value,
                            }
                          : { ...y }
                  ),
              }
            : { ...x }
    )

    return newTabs
}
