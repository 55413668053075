import { Box, Button, Dialog, Icon, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useState } from "react"
import { useInvalidateWorkTaskBasketQueries } from "../../../../data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"
import { ExternalBasketErrorDetails } from "../../../../data/model/ThirdPartyBasket"
import { StackRow } from "../../../StyledComponents"
import { ExternalBasketDialog } from "./ExternalBasketDialog"

type Props = {
    workTaskId: string
    showDeleteDialogDefault: boolean
    showErrorDetailsDialogDefault: boolean
    showExternalBasketDialogDefault: boolean
    externalDialogBasketUrl: string | undefined
    externalBasketErrorDetails?: ExternalBasketErrorDetails
    onResetCostEstimation(): void
    onCloseExternalBasketDialog(): void
}

export default function ActionButtonsDialogs(props: Props) {
    const {
        workTaskId,
        showDeleteDialogDefault,
        showErrorDetailsDialogDefault,
        showExternalBasketDialogDefault,
        externalBasketErrorDetails,
        externalDialogBasketUrl,
        onResetCostEstimation,
        onCloseExternalBasketDialog,
    } = props
    const { translateText } = useLocalization()
    const { invalidateAllWorkTaskBasketRequests } = useInvalidateWorkTaskBasketQueries()
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(showDeleteDialogDefault)
    const [showErrorDetailsDialog, setShowErrorDetailsDialog] = useState<boolean>(showErrorDetailsDialogDefault)
    const [showExternalBasketDialog, setShowExternalBasketDialog] = useState<boolean>(showExternalBasketDialogDefault)

    useEffect(() => {
        setShowDeleteDialog(showDeleteDialogDefault)
    }, [showDeleteDialogDefault])

    useEffect(() => {
        setShowErrorDetailsDialog(showErrorDetailsDialogDefault)
    }, [showErrorDetailsDialogDefault])

    useEffect(() => {
        setShowExternalBasketDialog(showExternalBasketDialogDefault)
    }, [showExternalBasketDialogDefault])

    function handleRemoveAllItems() {
        setShowDeleteDialog(false)
        onResetCostEstimation()
    }

    function handleCloseErrorDetailsDialog() {
        setShowErrorDetailsDialog(false)
        invalidateAllWorkTaskBasketRequests(workTaskId)
    }

    function handleCloseExternalBasketDialog() {
        setShowExternalBasketDialog(false)
        onCloseExternalBasketDialog()
    }

    return (
        <>
            <Dialog open={showDeleteDialog} skin="warning" position="top" fullWidth>
                <Stack spacing={2}>
                    <Typography>{translateText(989)}</Typography>
                    <Typography variant="body2">{translateText(990)}</Typography>
                </Stack>
                <Box pl={2} textAlign="center" display="initial">
                    <Button variant="contained" onClick={() => setShowDeleteDialog(false)}>
                        {translateText(584)}
                    </Button>
                    <Button variant="contained" sx={{ marginLeft: 1 }} color="success" onClick={handleRemoveAllItems}>
                        {translateText(585)}
                    </Button>
                </Box>
            </Dialog>
            <Dialog open={showErrorDetailsDialog} position="top" fullWidth skin="error">
                <StackRow justifyContent="space-between">
                    <Typography>{translateText(13869)}</Typography>
                    <Button startIcon={<Icon name="close" />} variant="text" onClick={handleCloseErrorDetailsDialog} />
                </StackRow>
                <Stack gap={0.5}>
                    {externalBasketErrorDetails?.messages.map((message) => (
                        <Typography key={message}>{message}</Typography>
                    ))}
                </Stack>
            </Dialog>
            <ExternalBasketDialog open={showExternalBasketDialog} url={externalDialogBasketUrl} onClose={handleCloseExternalBasketDialog} />
        </>
    )
}
