import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { RecordCustomWorks, RecordWorks, SelectedItems, Work } from "../../models"

export type WorksSlice = {
    worksState: {
        // Step, and second keys is the path. (The STEP is the current item for exampe Interior (right navigation). PATH is the current selected item inside the STEP)
        selectedItems: Record<string, Record<string, SelectedItems>>
        savedItemLubricants: Record<string, Record<string, SelectedItems>>
        works: RecordWorks
        customWorks: RecordCustomWorks
        nextPanel?: Work
    }
}

export function getInitialState(): WorksSlice {
    return {
        worksState: {
            selectedItems: {},
            savedItemLubricants: {},
            works: {},
            customWorks: {},
        },
    }
}

export const createWorksSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], WorksSlice> = () => ({
    ...getInitialState(),
})
