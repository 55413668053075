import { useEffect, useMemo } from "react"
import { ArticlesRequest } from "../../../../../../data/model/uni-parts"
import * as PartsRepository from "../../../../../../data/repositories/uni-parts"
import { useArticleListConfiguration } from "../../../../ArticleListConfiguration"
import { fetchUniversalPartsArticles } from "../../../../data/fetchUniversalPartsArticles"
import { useArticlesBase } from "../../../../hooks/useArticlesBase"
import { BaseArticleData, UniversalPartsListParams } from "../../../../models"

const QUERY_KEY = "UniversalParts.useArticlesByNode"

export function useArticlesByNodeOrProductGroups(params: UniversalPartsListParams, isEnabled: boolean): BaseArticleData {
    const { productGroups, suppliers, attributes, startParams, extendedAssortment, isFiltersLoading } = params

    const { pageSize } = useArticleListConfiguration()

    const request = useMemo<ArticlesRequest | null>(() => {
        if (!isEnabled || isFiltersLoading || startParams.type === "unisearch" || !productGroups.length) {
            return null
        }

        return {
            pageSize,
            selectedProductGroups: productGroups,
            selectedSuppliers: suppliers,
            selectedCriteria: attributes,
            extendedAssortment,
        }
    }, [isEnabled, isFiltersLoading, pageSize, productGroups, suppliers, startParams, attributes, extendedAssortment])

    useEffect(
        function resetNoResult() {
            params.setNoResult(false)
        },
        [request]
    )

    return useArticlesBase(request, (context) => fetchUniversalPartsArticles(PartsRepository.getArticlesBySearchTree, context), QUERY_KEY)
}
