import { Stack } from "@tm/components"
import { FC, useState } from "react"
import { Article, ArticleErpInfo, GetArticleListByMatchCodeRequest } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { WheelsTyresArticleItem } from "./WheelsTyresArticleItem"
import { ArticleListConfigurationProvider } from "../ListV2/ArticleListConfiguration"
import { ListOptionsProvider } from "../ListV2/ContextProvider/ListOptions"
import { EmptyListParamsProvider } from "../ListV2/ContextProvider/ListParams"
import { EmptyFiltersProvider } from "../ListV2/ContextProvider/Filters"
import { TradeReferenceProvider } from "../ListV2/ContextProvider/TradeReferences"
import { ErpInfosProvider } from "../ListV2/ContextProvider/ErpInfos"
import { BasketQuantitiesProvider } from "../ListV2/ContextProvider/BasketQuantities"
import { ActiveVehicleDataProvidersProvider } from "../ListV2/ContextProvider/ActiveVehicleDataProviders"
import { ProductGroupRepairTimesProvider } from "../ListV2/ContextProvider/ProductGroupRepairTimes"
import { PreviouslyOrderedArticlesProvider } from "../ListV2/ContextProvider/PreviouslyOrderedArticles"
import { NotesProvider } from "../ListV2/ContextProvider/Notes"
import { ArticleListActionsProvider } from "../ListV2/ContextProvider/ArticleListActions"
import { ComposerComponent, ContextComposer } from "../ListV2/ContextProvider"
import { TyresListProvider, useTyresListContext } from "../TyresList/ContextProvider/TyresList"
import { NextPageLoader } from "../ListV2/components/NextPageLoader"
import { TyresError } from "../../../../tyres/src/components/list/components"
import { ArticleGroupHeader } from "../list/components/ArticleGroupHeader/ArticleGroupHeader"
import { AvailabilityFilterType } from "../../business"

type WheelsTyresListProps = {
    request: GetArticleListByMatchCodeRequest
    selectedArticles: Article[]
    onAddToBasket: (article: Article, quantity: number) => void
    onChangeQuantity: (article: Article, quantity: number) => void
    toggleSelectedArticle: (article: Article) => void
    selectedAvailability: AvailabilityFilterType
    handleAutoNextPage(): void
}

export const WheelsTyresList: FC<WheelsTyresListProps> = (props) => {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [ArticleListConfigurationProvider],
        [ListOptionsProvider, { isEnabled: true }],
        [EmptyListParamsProvider],
        [EmptyFiltersProvider],
        [
            TyresListProvider,
            {
                setHasRendered: setHasListRendered,
                request: props.request,
                selectedAvailability: props.selectedAvailability,
                handleAutoNextPage: props.handleAutoNextPage,
                erpInfos,
            },
        ],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [ErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [PreviouslyOrderedArticlesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: false }],
        [ArticleListActionsProvider],
    ]

    return (
        <ContextComposer components={components}>
            <WheelsTyresListComponent
                requestAvailable={!!props.request}
                selectedArticles={props.selectedArticles}
                onAddToBasket={props.onAddToBasket}
                onChangeQuantity={props.onChangeQuantity}
                toggleSelectedArticle={props.toggleSelectedArticle}
            />
        </ContextComposer>
    )
}

type WheelsTyresListComponentProps = {
    requestAvailable: boolean
    selectedArticles: Article[]
    onAddToBasket: (article: Article, quantity: number) => void
    onChangeQuantity: (article: Article, quantity: number) => void
    toggleSelectedArticle: (article: Article) => void
}

function WheelsTyresListComponent({
    requestAvailable,
    selectedArticles,
    onAddToBasket,
    onChangeQuantity,
    toggleSelectedArticle,
}: WheelsTyresListComponentProps) {
    const { translateText } = useLocalization()

    const wheelsTyreArticles = useTyresListContext((context) => context.displayArticles)
    const showInitialLoading = useTyresListContext((tyresListContext) => tyresListContext.isLoading)
    const isFailed = useTyresListContext((tyresListContext) => tyresListContext.isFailed)
    const isLoaded = useTyresListContext((tyresListContext) => tyresListContext.isLoaded)

    if (!requestAvailable) {
        return null
    }

    if (showInitialLoading || (!wheelsTyreArticles?.length && !isLoaded)) {
        return <NextPageLoader skeletonArticleCount={5} />
    }

    if (isFailed) {
        return <TyresError />
    }

    return (
        <Stack flex={1} position="relative">
            <ArticleGroupHeader title={translateText(240)} groupArticlesCount={wheelsTyreArticles?.length} />
            <Stack padding={0.5}>
                {wheelsTyreArticles?.map((article) => (
                    <WheelsTyresArticleItem
                        key={article.internalId}
                        article={article}
                        selectedArticles={selectedArticles}
                        onAddToBasket={onAddToBasket}
                        onChangeQuantity={onChangeQuantity}
                        toggleSelectedArticle={toggleSelectedArticle}
                    />
                ))}
            </Stack>
        </Stack>
    )
}
