import { PropsWithChildren } from "react"
import { createContext, useContextSelector } from "use-context-selector"
import { useDirectSearchListParamsContext } from ".."
import { ensureContextAvailability } from "../../../helpers"
import { OeReplacementData } from "../../../models"
import { useOeReplacement } from "./useOeReplacement"

export const OeReplacementContext = createContext<OeReplacementData | undefined>(undefined)

export function OeReplacementProvider(props: PropsWithChildren<{ isEnabled: boolean }>) {
    const params = useDirectSearchListParamsContext()
    const oeReplacement = useOeReplacement(params, props.isEnabled)

    return <OeReplacementContext.Provider value={oeReplacement}>{props.children}</OeReplacementContext.Provider>
}

export function useOeReplacementContext<TContext extends OeReplacementData>(): TContext
export function useOeReplacementContext<TContext extends OeReplacementData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useOeReplacementContext<TContext extends OeReplacementData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(OeReplacementContext, (context) => {
        const validatedContext = ensureContextAvailability("OeReplacementContext", context)
        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
