import { Channel, MessageBus } from "@tm/hermes"
import {
    BikeModel,
    CarLookupError,
    CarModel,
    CarModelDetails,
    channel,
    KeyValueStateContainer,
    RegisteredModels,
    RegistrationNoType,
    ShowCarModelDetailsRequest,
    ShowCarModelDetailsResponse,
    ShowConfigResponse,
    TruckModel,
    Vehicle,
    VehicleImportData,
    VehicleType,
} from "@tm/models"
import { Container } from "@tm/nexus"
import { isLicensePlateType, isVinType } from "@tm/utils"

import { ModelsResponse } from "./data/hooks"
import { mapModelDetailsToVehicle } from "./data/mapper"
import { CheckVehicleRequest } from "./data/model/gsi"
import { checkGsiVehicle, mergeRegistrationNoDetails } from "./data/repositories/gsi"
import { showVehiclesByRegistrationNo, showVehiclesByVin } from "./data/repositories/vrm-lookup"
import { getAutoIDatErrorMessageTextId, getDATErrorMessageTextId, VrmLookupErrorTextIds } from "./helpers"

type AttachModelRequest = {
    model: CarModel | BikeModel | TruckModel
    vehicleType: VehicleType
    searchQuery?: string

    referenceId?: string

    plateId?: string
    vin?: string
    initialRegistration?: Date
    engineCode?: string

    mileAge?: number
    mileageType?: number

    lastGeneralInspection?: Date
    nextGeneralInspection?: Date
    nextServiceDate?: Date

    longlife?: boolean

    registrationNo?: string
    registrationNoTypeId?: RegistrationNoType

    checkAndMergeRegNoDetails?: boolean

    requestOnlyRegNo?: string
    countryCode?: string
    selectedLookupType?: RegistrationNoType
}

export function setTabInfo(workTaskId: string, manufacturer: string) {
    channel("WORKTASK", workTaskId).publish("MODULE/CHANGED", { id: "vehicle", view: "{{1601}}", info: manufacturer } as any)
}

export const SkippedRegNoSearches = [
    RegistrationNoType.None,
    RegistrationNoType.KeywordSearch,
    RegistrationNoType.RückwFahrzeugVkn,
    RegistrationNoType.KTypNr,
    RegistrationNoType.Motorcode,
    RegistrationNoType.Fahrzeugbaum,
    RegistrationNoType.NkwId,
    RegistrationNoType.TopmotiveTypeId,
]

export function createVehicleFromModel(data: AttachModelRequest): Promise<Vehicle | string> {
    return new Promise((resolve, reject) => {
        const request: ShowCarModelDetailsRequest = {
            modelId: data.model.id,
            modelIdentifier: {
                plateId: data.model.registrationNoDetails?.plateId,
                vin: data.model.registrationNoDetails?.vin,
                initialRegistration: data.model.registrationNoDetails?.initialRegistration,
            },
            selectedLookupType: data.selectedLookupType,
        }

        if (data.registrationNoTypeId !== undefined && !SkippedRegNoSearches.includes(data.registrationNoTypeId)) {
            request.registrationNo = data.requestOnlyRegNo ?? data.model.registrationNoDetails?.registrationNo ?? data.searchQuery
            request.registrationNoTypeId = data.registrationNoTypeId
            request.forceUpdateRegistrationNoDetails = false
        }

        Container.getInstance<ShowCarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)
            .subscribe(request, data.vehicleType)
            .load()
            .then((response) => {
                if (!response?.modelDetails) {
                    reject()
                    return
                }

                const { modelDetails } = response

                const { registrationNo, registrationNoTypeId, selectedLookupType } = request

                const isPlate = isLicensePlateType(registrationNoTypeId, registrationNo)
                const isVin = isVinType(registrationNoTypeId, registrationNo)

                // https://jira.dvse.de/browse/NEXT-22881 if a DatVin search is performed the registrationNoType has priority
                const requestRegistrationNoTypeId =
                    (registrationNoTypeId !== RegistrationNoType.DatVin ? selectedLookupType : undefined) ?? registrationNoTypeId
                const vehicle = createVehicleFromModelDetails(data, response, modelDetails, requestRegistrationNoTypeId)

                // Only execute "check and merge" logic if all conditions are met
                if (!data.checkAndMergeRegNoDetails || !registrationNo || !registrationNoTypeId || (!isPlate && !isVin)) {
                    resolve(vehicle)
                    return
                }

                const { initialRegistration, plateId = isPlate ? registrationNo : undefined, vin = isVin ? registrationNo : undefined } = vehicle

                checkAndMergeRegNoDetails({
                    modelId: modelDetails.id.toString(),
                    registrationNo,
                    registrationNoTypeId,
                    initialRegistration,
                    plateId,
                    vin,
                })
                    .then((vehicleId) => {
                        if (vehicleId) {
                            // Existing vehicle found and registration number data already merged
                            resolve(vehicleId)
                        } else {
                            // No existing vehicle found, create a new one
                            resolve(vehicle)
                        }
                    })
                    .catch(() => {
                        resolve(vehicle)
                    })
            }, reject)
    })
}

/**
 * This function maps a ModelDetailsResponse and some user input data (like plate id) to a GSI Vehicle.
 * @todo This should be done in the GSI/Vehicle service
 */
function createVehicleFromModelDetails(
    data: AttachModelRequest,
    response: ShowCarModelDetailsResponse,
    modelDetails: CarModelDetails,
    requestRegistrationNoTypeId?: RegistrationNoType
) {
    const vehicle: Vehicle = mapModelDetailsToVehicle(response, modelDetails, data.vehicleType, requestRegistrationNoTypeId)

    // Map user input data - input data is always superior
    if (data.referenceId) {
        vehicle.refId = data.referenceId
    }

    if (data.plateId) {
        vehicle.plateId = data.plateId
    }
    if (data.vin) {
        vehicle.vin = data.vin
    }
    if (data.initialRegistration) {
        vehicle.initialRegistration = data.initialRegistration
    }
    if (data.engineCode) {
        vehicle.engineCode = data.engineCode
    }

    if (data.mileAge) {
        vehicle.mileAge = data.mileAge
    }
    if (data.mileageType) {
        vehicle.mileType = data.mileageType
    }

    if (data.nextGeneralInspection) {
        vehicle.nextGeneralInspection = data.nextGeneralInspection
    }
    if (data.lastGeneralInspection) {
        vehicle.lastGeneralInspection = data.lastGeneralInspection
    }
    if (data.nextServiceDate) {
        vehicle.nextServiceDate = data.nextServiceDate
    }

    if (data.registrationNo) {
        vehicle.registrationNo = data.registrationNo
    }

    if (data.longlife !== undefined) {
        vehicle.longlife = data.longlife
    }

    // Fix that vehicle doesn't have the search query as vin in case of vin search
    /** @todo Should be done by service */
    if (!vehicle.vin && isVinType(data.registrationNoTypeId, data.searchQuery)) {
        vehicle.vin = data.searchQuery
    }

    // Fix that vehicle doesn't have the search query as plate id in case of license plate search
    /** @todo Should be done by service */
    if (!vehicle.plateId && isLicensePlateType(data.registrationNoTypeId, data.searchQuery)) {
        vehicle.plateId = data.searchQuery
    }

    if (data.model.countryCode || data.countryCode) {
        vehicle.countryCode = data.model.countryCode ?? data.countryCode
    }

    return vehicle
}

export async function checkAndMergeRegNoDetails({
    modelId,
    registrationNo,
    registrationNoTypeId,
    initialRegistration,
    plateId,
    vin,
}: CheckVehicleRequest): Promise<string | undefined> {
    return checkGsiVehicle({
        modelId,
        initialRegistration,
        plateId,
        vin,
        registrationNo,
        registrationNoTypeId,
    }).then(async ({ vehicleId } = {}) => {
        if (!vehicleId) {
            return
        }

        mergeRegistrationNoDetails({
            vehicleId,
            initialRegistration,
            plateId,
            vin,
            registrationNo,
            registrationNoTypeId,
        })

        return vehicleId
    })
}

export function searchVehiclesByVin(vin: string, lookupTypeId: RegistrationNoType, forceUpdate?: boolean): Promise<ModelsResponse> {
    return new Promise((resolve, reject) => {
        showVehiclesByVin({ query: vin, lookupTypeId, forceUpdateRegistrationNoDetails: forceUpdate }).then(
            ({ carModels, customerVehicles }) => {
                // Remove vehicles without tecdoc model id
                const vehicles = carModels.filter((x) => x.id)

                // no vehicles found for vin
                if (!vehicles.length) {
                    reject(VrmLookupErrorTextIds.NoResult)
                    return
                }

                resolve({
                    models: vehicles,
                    customerVehicles,
                    filters: {
                        bodyTypes: [],
                        modelYears: [],
                        fuelTypes: [],
                        engineCodes: [],
                        engineCapacities: [],
                    },
                })
            },
            (error: CarLookupError) => reject(getDATErrorMessageTextId(error))
        )
    })
}

export function searchVehiclesByRegNo(
    registrationNo: string,
    lookupTypeId: RegistrationNoType,
    forceUpdateRegistrationNoDetails?: boolean,
    vehicleType?: VehicleType
): Promise<ModelsResponse> {
    return new Promise((resolve, reject) => {
        showVehiclesByRegistrationNo({ query: registrationNo, lookupTypeId, forceUpdateRegistrationNoDetails, vehicleType }).then(
            ({ carModels, truckModels, customerVehicles }) => {
                let vehicles = carModels.length ? carModels : truckModels

                // Remove vehicles without tecdoc model id
                vehicles = vehicles.filter((x) => x.id)

                // no vehicles found for registrationNo
                if (!vehicles.length && !customerVehicles) {
                    reject(VrmLookupErrorTextIds.NoResult)
                    return
                }

                resolve({
                    models: lookupTypeId === RegistrationNoType.VrmLicensePlateNetherlandRDC ? carModels : vehicles,
                    customerVehicles,
                    filters: {
                        bodyTypes: [],
                        modelYears: [],
                        fuelTypes: [],
                        engineCodes: [],
                        engineCapacities: [],
                    },
                })
            },
            (error: CarLookupError) => reject(getAutoIDatErrorMessageTextId(error))
        )
    })
}

export function getAvailableVehicleSearchesRegNoTypes(): Promise<Array<RegistrationNoType>> {
    return new Promise((resolve) => {
        getAvailableVehicleSearches().then((response) => {
            if (!response) {
                return
            }

            const registrationNumberTypeIds: Array<RegistrationNoType> = []
            const { configuredVehicleLookups } = response

            configuredVehicleLookups?.forEach((x) => {
                if (!x.isUsedInDefaultSearch) {
                    return
                }
                registrationNumberTypeIds.push(x.lookupTypeId || RegistrationNoType.Kba)
            })

            resolve(registrationNumberTypeIds)
        })
    })
}

export function getAvailableVehicleSearches(): Promise<ShowConfigResponse> {
    return new Promise((resolve, reject) => {
        Container.getInstance<ShowConfigResponse>(RegisteredModels.Vehicle_ShowOptions).subscribe().load().then(resolve, reject)
    })
}

export function getImportedVehicleDetails(workTaskId: string): Promise<VehicleImportData | undefined> {
    return new Promise<VehicleImportData | undefined>((resolve) => {
        const unsub = channel("WORKTASK", workTaskId).subscribe(
            "VEHICLE/DETAILS_IMPORTED",
            (data) => {
                unsub()
                resolve(data)
            },
            true
        )
    })
}

export function loadImportedVehicleDetails(workTaskId: string) {
    const keyValueStore = Container.getInstance(RegisteredModels.KeyValueStore) as KeyValueStateContainer
    keyValueStore
        .action("loadKeyValue")(`${workTaskId}_DMS-SEARCH-VEHICLE`)
        .then((response) => {
            if (!response) {
                channel("WORKTASK", workTaskId).publish("VEHICLE/DETAILS_IMPORTED", undefined)
                return
            }
            const json = JSON.parse(response)
            const data: VehicleImportData = {
                ...json,
                initialRegistration: json.initialRegistration ? new Date(json.initialRegistration) : undefined,
                nextGeneralInspection: json.nextGeneralInspection ? new Date(json.nextGeneralInspection) : undefined,
                nextServiceDate: json.nextServiceDate ? new Date(json.nextServiceDate) : undefined,
            }
            channel("WORKTASK", workTaskId).publish("VEHICLE/DETAILS_IMPORTED", data)
        })
}

export function deleteImportedVehicleDetails() {
    const worktaskId = channel("GLOBAL").last(1, "WORKTASK/ID_CHANGED")[0]?.content?.id
    const container = Container.getInstance(RegisteredModels.KeyValueStore) as KeyValueStateContainer
    container.action("deleteKeyValue")(`${worktaskId}_DMS-SEARCH-VEHICLE`)
}

export function mergeImportedVehicleData(vehicle: Vehicle, importedData: VehicleImportData | undefined, details: CarModelDetails): Vehicle {
    if (!importedData) {
        return vehicle
    }

    if (
        details.registrationNos?.some(
            (x) =>
                x.replace(" ", "").toUpperCase() === importedData.registrationNo?.toUpperCase() &&
                details.engineCodes?.some((x) => x.toUpperCase() === importedData.engineCode?.toUpperCase())
        )
    ) {
        return {
            ...vehicle,
            refId: vehicle.refId || importedData.referenceId,
            vin: vehicle.vin || importedData.vin,
            mileAge: vehicle.mileAge || importedData.mileAge,
            mileType: vehicle.mileType || importedData.mileageType,
            plateId: vehicle.plateId || importedData.plateId,
            initialRegistration: vehicle.initialRegistration || importedData.initialRegistration,
            registrationNo: vehicle.registrationNo || importedData.registrationNo,
            engineCode: vehicle.engineCode || importedData.engineCode,
            nextGeneralInspection: vehicle.nextGeneralInspection || importedData.nextGeneralInspection,
            nextServiceDate: vehicle.nextServiceDate || importedData.nextServiceDate,
        }
    }

    return vehicle
}

export function getImageTypeByVehicleType(vehicleType: VehicleType): "car" | "bike" | "manufacturer" | undefined {
    switch (vehicleType) {
        case VehicleType.PassengerCar:
            return "car"
        case VehicleType.Motorcycle:
            return "bike"
        case VehicleType.CommercialVehicle:
            return "manufacturer"
    }
}

let mb: MessageBus<BundleChannels>

export function bundleChannel(): Channel<BundleChannels, "BUNDLE"> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }
    return mb.channel("BUNDLE")
}

export interface BundleChannels {
    BUNDLE: BundleChannelType
}

export type BundleChannelType = { SET_APPLY_BUTTON_STATUS: { disabled: boolean } } & { APPLY_BUTTON_CLICKED: true } & {
    DETAILS_VEHICLE_CUSTOMERID_CHANGED: { customerId?: string }
} & { VRC_SCAN_SHUTTER_TIMER_START: {} } & { VRC_SCAN_SHUTTER_TIMER_STOP: {} }
