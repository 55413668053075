import { Box, Button } from "@tm/components"
import { ArticleListSortingMode } from "@tm/models"
import { useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { ArticleGroup } from "../components/ArticleGroup"
import { NextPageLoader } from "../components/NextPageLoader"
import { NoSearchResult } from "../components/NoSearchResult"
import { ArticleSelection } from "../components/ArticleSelection"
import { useArticleSelection } from "../hooks/useArticleSelection"
import { useUniversalPartsListParamsContext } from "./ContextProvider/ListParams"
import { useListOptionsContext } from "../ContextProvider"
import { useUniversalPartsArticlesContext } from "./ContextProvider/Articles"

export function UniversalPartsList() {
    const { translateText } = useLocalization()

    const {
        sorting: { productGroups, setForProductGroups },
        noResult,
        isFiltersLoading,
    } = useUniversalPartsListParamsContext()

    const { articleGroups, isLoading, isLoaded, isFetchingNextPage, hasNextPage, loadNextPage, isStalling, isFrontendFiltered } =
        useUniversalPartsArticlesContext()

    const options = useListOptionsContext()
    const { selectedArticles } = useArticleSelection()

    let distance = ""
    if (selectedArticles.length > 0) {
        distance = "-50px"
    }

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            setForProductGroups([productGroupId], value)
        },
        [setForProductGroups]
    )

    const showPageLoader = ((hasNextPage || isLoading || isFiltersLoading) && !isFrontendFiltered) || isStalling || isFetchingNextPage

    if (noResult) {
        return <NoSearchResult searchType="universal" />
    }

    return (
        <Box position="relative" top={distance}>
            <ArticleSelection showArticleComparision hideBasketButton={options.hideAddToBasketButton} />

            {isLoaded &&
                !isStalling &&
                articleGroups.map((group) => (
                    <ArticleGroup
                        key={group.productGroup.id}
                        group={group}
                        currentSorting={productGroups[group.productGroup.id]}
                        onChangeSorting={handleChangeSorting}
                    />
                ))}

            {showPageLoader && (
                <NextPageLoader
                    loadNextPage={loadNextPage}
                    canLoadNextArticles={hasNextPage}
                    skeletonArticleCount={articleGroups.length ? 1 : 5}
                    skeletonCategory
                />
            )}

            {hasNextPage && isFrontendFiltered && (
                <Box display="flex" justifyContent="center">
                    <Button color="highlight" onClick={loadNextPage}>
                        {translateText(12430)}
                    </Button>
                </Box>
            )}
        </Box>
    )
}
