import { renderRoute } from "@tm/utils"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { useUser } from "@tm/context-distribution"
import { Divider, Stack } from "@tm/components"
import { useCompilations, useShowCompilationPdf } from "../../data/hooks/useCompilations"
import { getBundleParams } from "../../utils"
import CompilationDetailsComponent from "./components/details"
import CompilationsListComponent from "./components/list"
import { Navigation } from "./components/navigation"
import SingleCompilationsComponent from "./components/singleWatchlist/component"

type RouteProps = {
    compilationId?: string
    workTaskId: string
}

export default function CompilationsComponent() {
    const { userContext } = useUser()
    const [query, setQuery] = useState("")
    const { compilations, isLoading, error } = useCompilations(query || undefined)
    const [showCreator, setShowCreator] = useState(false)
    const matchParams = useParams<RouteProps>()
    const history = useHistory()
    const { compilationDetailsRoute, compilationsRoute } = getBundleParams()

    useEffect(() => {
        if (compilations && compilations.length === 0 && matchParams.compilationId) {
            openCompilations()
        }
    }, [compilations, matchParams.compilationId])

    const openCompilationDetails = (compilationId: string) => {
        window.document.querySelector(".module.worktask .worktask__content")?.scroll({ top: 0 })
        history.push(renderRoute(compilationDetailsRoute, { ...matchParams, compilationId }))
    }

    const openCompilations = () => {
        history.push(renderRoute(compilationsRoute, matchParams))
    }

    const { compilationId } = matchParams
    const { showCompilationPdf, loadingCompilationPdf } = useShowCompilationPdf(compilationId)

    // Use for anonym login (Industy Catalog) a fullscreen Compilationlist
    if (userContext.isAnonym) {
        return <SingleCompilationsComponent compilationId={compilations?.first()?.id} />
    }

    return (
        <Stack gap={2} flex={1}>
            <Navigation
                compilationId={compilationId}
                showCreator={showCreator}
                onShowCreator={() => setShowCreator(true)}
                searchCompilation={setQuery}
                openCompilations={openCompilations}
                loadingCompilationPdf={loadingCompilationPdf}
                onPrintButtonClick={() => showCompilationPdf()}
            />
            <Stack direction="row" gap={1} p={1} divider={<Divider orientation="vertical" />}>
                <CompilationsListComponent
                    compilations={compilations}
                    loading={isLoading}
                    error={error as any}
                    showCreator={showCreator}
                    compact={!!compilationId}
                    onHideCreator={() => setShowCreator(false)}
                    openCompilationDetails={openCompilationDetails}
                    openCompilationList={openCompilations}
                />
                {compilationId && <CompilationDetailsComponent compilationId={compilationId} encodedWorkTaskId={matchParams.workTaskId} />}
            </Stack>
        </Stack>
    )
}
