import { StateCreator } from "zustand"
import { FastServiceStore } from "../store"

export type EndPageSlice = {
    articlesLoading: boolean
    printError: boolean
    printLoading: boolean
    pdfUrl?: string
    pdfExpiresAt?: number
    calculationDone: boolean
    pdfId: string
}

export function getInitialState(): EndPageSlice {
    return {
        articlesLoading: false,
        printError: false,
        printLoading: false,
        calculationDone: false,
        pdfId: "",
    }
}

export const createEndPageSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], EndPageSlice> = () => ({
    ...getInitialState(),
})
