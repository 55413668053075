export * from "./fileUpload/useCompleteFileUpload"
export * from "./useFileDelete"
export * from "./useFileDownload"
export * from "./useFileList"
export * from "./fileUpload/useGetUploadKey"
export * from "./fileUpload/useUploadFile"
export * from "./useVefilesSearch"

export const QueryKeys = {
    GetFileList: "VEFILES_GET_FILE_LIST",
    GetUploadKey: "VEFILES_GET_UPLOAD_KEY",
    FastUpStorageUri: "VEFILES_FASTUP_STORAGE_URI",
    FastUpTransferInfo: "VEFILES_FASTUP_TRANSFER_INFO",
}
