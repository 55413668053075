import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."

export type TechnicianSlice = {
    technician: {
        signature?: string
        firstName: string
        lastName: string
        comment: string
    }
}

export function getInitialState(): TechnicianSlice {
    return {
        technician: {
            firstName: "",
            lastName: "",
            comment: "",
        },
    }
}

export const createTechnicianSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], TechnicianSlice> = (set) => ({
    ...getInitialState(),
})
