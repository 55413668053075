import { Article, ArticleSearchHitInfo, DefaultArticleItemState } from "@tm/models"
import { useCallback, useMemo } from "react"
import { atom, useRecoilState } from "recoil"
import { isEqual } from "lodash"
import { useLocalization } from "@tm/localization"
import * as PartsRepository from "../../../../data/repositories/parts"
import { GetArticleSearchHitInfoRequest } from "../../../../data/model"
import { getSearchFilterText } from "../../../../helper"
import { getBundleParams } from "../../../../utils"
import { useListParamsContext } from "../../ContextProvider"

const ArticleFoundListAtom = atom<[GetArticleSearchHitInfoRequest, ArticleSearchHitInfo[], boolean][]>({
    key: "ArticleFoundList",
    default: [],
})

export function useArticleFoundBy(article: Article): DefaultArticleItemState["foundBy"] {
    const { translateText } = useLocalization()
    const [list, setList] = useRecoilState(ArticleFoundListAtom)
    const {startParams} = useListParamsContext()
    const { hideFoundBy } = getBundleParams()

    const request = useMemo<GetArticleSearchHitInfoRequest | undefined>(() => {
        if (startParams.type !== "direct") {
            return
        }
        const { searchFilter, query } = startParams
        const {
            supplierArticleNo,
            supplier: { id: supplierId },
        } = article

        return { query, searchFilter, supplierArticleNo, supplierId }
    }, [article, startParams])

    const load = useCallback(async () => {
        if (!request) {
            return
        }
        const entryRequest = list.find((x) => isEqual(request, x[0]))?.[0] ?? request
        setList([...list.filter((x) => x[0] !== entryRequest), [entryRequest, [], true]])
        const result = await PartsRepository.getArticleSearchHitInfo(request)
        setList([...list.filter((x) => x[0] !== entryRequest), [entryRequest, result, false]])
    }, [list, request, setList])

    const result = useMemo<DefaultArticleItemState["foundBy"]>(() => {
        const [, searchHitInfos, isLoading] = list.find((x) => isEqual(request, x[0])) ?? []
        const hitInfos = searchHitInfos?.map((x) => ({ ...x, searchFilter: getSearchFilterText(x.searchFilter, translateText) }))
        return { hitInfos, isLoading, load }
    }, [list, load, request, translateText])

    if (startParams.type !== "direct" || hideFoundBy) {
        return
    }

    return result
}
