import { Button, Icon, Stack, Typography } from "@tm/components"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { isWM } from "../../../../utils"
import { Options } from "./components/options"

type Props = {
    compilationId: string | undefined
    showCreator: boolean
    loadingCompilationPdf?: boolean
    onShowCreator(): void
    searchCompilation(query: string): void
    openCompilations(): void
    onPrintButtonClick(): void
}

export function Navigation(props: Props) {
    const { compilationId, openCompilations } = props
    const { translateText } = useLocalization()

    const detailsIsActive = !!compilationId

    return (
        <Stack direction="row" mt={(theme) => theme.spacing(1)}>
            <Typography variant="h2" mt={3} pr={2} pl={1}>
                {translateText(1240)}
            </Typography>
            {isWM() && (
                <Toolbar title={translateText(176)}>
                    <Button
                        onClick={openCompilations}
                        color={!detailsIsActive ? "primary" : undefined}
                        startIcon={<Icon name="menu" />}
                        variant="contained"
                    >
                        {translateText(6)}
                    </Button>
                    <Button disabled={!detailsIsActive} color={detailsIsActive ? "primary" : undefined} startIcon={<Icon name="details" />}>
                        {translateText(43)}
                    </Button>
                </Toolbar>
            )}
            <Options {...props} />
        </Stack>
    )
}
