import { PropsWithChildren } from "react"
import { ListParamsContext, useListParamsContext } from "../../../ContextProvider/ListParams"
import { WholesalerArticleNumberListParams } from "../../../models"
import { useListParams } from "./useListParams"

export function WholeSalerArticleNumberListParamsProvider(props: PropsWithChildren<unknown>) {
    const params = useListParams()
    return <ListParamsContext.Provider value={params}>{props.children}</ListParamsContext.Provider>
}

export function useWholeSalerArticleNumberListParamsContext<TContext extends WholesalerArticleNumberListParams>(): TContext
export function useWholeSalerArticleNumberListParamsContext<TContext extends WholesalerArticleNumberListParams, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function useWholeSalerArticleNumberListParamsContext<TContext extends WholesalerArticleNumberListParams, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useListParamsContext(selector as never)
}
