import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { useArticlesContext } from "../Articles/ArticlesContext"
import { NotesParams, useNotes } from "./useNotes"
import { ensureContextAvailability } from "../../helpers"

export const NotesContext = createContext<NotesParams | undefined>(undefined)

export function NotesProvider(props: PropsWithChildren<{ isEnabled: boolean; isVehicleDepending: boolean }>) {
    const { articles } = useArticlesContext()
    const notes = useNotes(articles, props.isVehicleDepending, props.isEnabled)

    return <NotesContext.Provider value={notes}>{props.children}</NotesContext.Provider>
}

export function useNotesContext(): NotesParams {
    const context = useContext(NotesContext)
    return ensureContextAvailability("NotesContext", context)
}
