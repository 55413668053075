import { PropsWithChildren } from "react"
import { ArticleListActionsContext, useArticleListActionsContext } from "../../../ContextProvider/ArticleListActions"
import { useArticleListActions } from "./useArticleListActions"

export function VehiclePartsArticleListActionsProvider(props: PropsWithChildren<unknown>) {
    const articleListActions = useArticleListActions()
    return <ArticleListActionsContext.Provider value={articleListActions}>{props.children}</ArticleListActionsContext.Provider>
}

export const useVehiclePartsArticleListActionsContext = useArticleListActionsContext
