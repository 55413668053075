import { GetArticleListByMatchCodeRequest } from "@tm/models"
import { getSelectedCriteria } from "."
import { getBundleParams } from "../../../../utils"
import { ListState } from "../model"


export function createNextArticlesListRequest(state: ListState): GetArticleListByMatchCodeRequest | undefined {
    const { searchValue, articles: { nextArticlesError, pageIndex, count }, lastSearch: { carType } } = state
    const selectedCriteria = getSelectedCriteria(state)

    const { pageSize, traderReferenceId } = getBundleParams()
    if (pageIndex >= count / pageSize || nextArticlesError || !searchValue) {
        return
    }

    return {
        pageIndex: pageIndex + 1,
        pageSize,
        query: searchValue,
        searchLevel: 0,
        selectedCriteria: selectedCriteria,
        fromFilter: true,
        traderReferenceId,
        carTypes: carType
    }
}