import { Box, Icon } from "@tm/components"
import { Widget, WidgetProps } from "@tm/controls"
import { ExternalModule } from "@tm/models"
import { ReactElement } from "react"
import { ExternalCatalogComponentProps } from "../component"
import ItemCover from "./ItemCover"

export type WidgetItemComponentProps = Pick<ExternalCatalogComponentProps, "size" | "itemClassName" | "hideCover" | "showArrow"> & {
    item: ExternalModule
    onClick: (item: ExternalModule) => void
    Overlay?: ReactElement
}

function WidgetItemComponent(props: WidgetItemComponentProps) {
    const { item, size, itemClassName, hideCover, onClick, showArrow, Overlay } = props
    const description = item.catalogDescription || item.description
    const logoParam = item.parameter.find((param) => param.key === "URL_Logo")
    const isUrl = logoParam?.value.includes("/") // logoParam?.value could be either a URL (absolute or relative) or an icon name (which cannot contain a forward slash)
    const className = `item external-catalog ${isUrl ? "image" : "text"} ${!hideCover ? "cover" : ""} ${itemClassName || ""}`

    function renderItemCover() {
        return (
            <>
                <ItemCover {...props} title={description} logo={isUrl ? logoParam?.value : undefined} icon={isUrl ? undefined : logoParam?.value} />
                {Overlay}
            </>
        )
    }

    function renderWidgetCover() {
        return (
            <>
                <Widget.Cover>
                    {isUrl ? (
                        <Box className="widget__cover-image">
                            <img src={logoParam?.value} alt={description} />
                        </Box>
                    ) : (
                        <Icon name={logoParam?.value} height="38px" width="38px" />
                    )}
                    <div className="widget__cover-text">{description}</div>
                    {showArrow && <Icon className="target-icon" name="arrow-right" />}
                </Widget.Cover>
                {Overlay}
            </>
        )
    }

    const widgetProps: WidgetProps = {
        size: size || "1x1",
        active: false,
        className,
        onClick: () => onClick(item),
        title: !hideCover ? renderItemCover() : Overlay,
        cover: hideCover ? renderWidgetCover() : Overlay,
    }

    return <Widget key={`${item.description}-${item.type}`} {...widgetProps} />
}

export default WidgetItemComponent
