import { CellContentPosition, NotesButton, Table, TableCellData, TableColumnData, Theme } from "@tm/components"
import { useMemo, useState } from "react"
import { useUser } from "@tm/context-distribution"
import { NoteTypes, VoucherType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { ExtendedOrderVoucherItem } from "../../../../../../../../data/model"
import ArticleThumbnail from "../../../_shared/ArticleThumbnail"
import ArticleManufacturerComponent from "./components/ArticleManufacturer"
import ArticleNumbers from "../../../_shared/ArticleNumbers"
import ArticleDescription from "../../../_shared/ArticleDescription"
import ArticleType from "./components/ArticleType"
import { ArticleQuantities } from "../../../_shared/ArticleQuantities"
import PartActions from "../../../_shared/PartActions"
import PartFooter from "./components/PartFooter"
import PartPrices from "../../../_shared/PartPrices"

type Props = {
    workTaskId: string
    voucherId: string | undefined
    selectedIds: string[]
    parts: ExtendedOrderVoucherItem[]
    hideVehicleLogos: boolean | undefined
    isBigScreen: boolean
    theme: Theme
    onSelectPart(id: string): void
    onOpenArticleSearch(query: string): void
    onShowReturn(returnNumber: string): void
}

export default function OrderPartsTable(props: Props) {
    const { workTaskId, parts, hideVehicleLogos, selectedIds, voucherId, isBigScreen, theme, onOpenArticleSearch, onSelectPart, onShowReturn } = props
    const { translateText } = useLocalization()
    const { userSettings, userContext } = useUser()
    const { hasMailRetoure } = userContext

    const partNotesExpandedMap = new Map<string, boolean>()
    parts.forEach((part) => {
        partNotesExpandedMap.set(part.id, false)
    })

    const [isNoteExpandedMap, setIsNoteExpandedMap] = useState(partNotesExpandedMap)
    const hasNotes = useMemo(() => {
        return parts.some((part) => !!part.memo)
    }, [parts])

    function toggleIsNoteExpanded(partId: string) {
        if (isNoteExpandedMap.has(partId)) {
            const currentValue = isNoteExpandedMap.get(partId)
            setIsNoteExpandedMap(new Map(isNoteExpandedMap.set(partId, !currentValue)))
        }
    }

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: "" }, // Article thumbnail
            { header: "" }, // Article manufacturer
            { header: "" }, // Numbers
            { header: "" }, // Article description
            { header: "" }, // Article type
        ]

        if (hasNotes) {
            columns.push({ header: "" }) // Memo / note
        }
        columns.push(
            { header: "" }, // Quantity
            { header: "", alignContent: CellContentPosition.right }, // Part prices
            { header: "", alignContent: CellContentPosition.right } // actions
        )

        return columns
    }

    function getDisplayData() {
        return parts?.map((part, index) => ({
            cells: getCellData(part),
            id: `${index}`,
            customRow: false,
            active: false,
            extendedContent: (
                <PartFooter
                    workTaskId={workTaskId}
                    part={part}
                    isNoteExpanded={isNoteExpandedMap.get(part.id) || false}
                    hasMailRetoure={hasMailRetoure}
                    voucherId={voucherId}
                    hideVehicleLogos={hideVehicleLogos}
                    isBigScreen={isBigScreen}
                    theme={theme}
                    onSelectPart={onSelectPart}
                    onOpenArticleSearch={onOpenArticleSearch}
                    onShowReturn={onShowReturn}
                />
            ),
        }))
    }

    function getCellData(part: ExtendedOrderVoucherItem): TableCellData[] {
        const {
            articleInfo,
            articleInfoType,
            orderPrices,
            cummulatedOrderPrices,
            confirmedQuantity,
            memo,
            foundBySearchTerm,
            vehicle,
            mainPartItemId,
            returnedQuantity,
            isLinkedItem,
        } = part

        const cellData: TableCellData[] = [
            {
                displayValue: (
                    <ArticleThumbnail articleInfoType={articleInfoType} mainPartItemId={mainPartItemId} thumbnailUrl={articleInfo.thumbnailUrl} />
                ),
                id: "part_1",
            },
            {
                displayValue: (
                    <ArticleManufacturerComponent
                        dataSupplier={articleInfo.dataSupplier}
                        vehicleManufacturer={articleInfo.vehicleManufacturer}
                        manufacturerName={articleInfo.manufacturerName}
                        isBigScreen={isBigScreen}
                    />
                ),
                id: "part_2",
            },
            {
                displayValue: (
                    <ArticleNumbers
                        articleType={articleInfoType}
                        articleNumber={articleInfo.dataSupplierArticleNumber}
                        productGroupId={articleInfo.productGroup?.id}
                        supplierId={articleInfo.dataSupplier?.id}
                        wholesalerArticleNumber={articleInfo.wholesalerArticleNumber}
                        isLinkedItem={!!mainPartItemId}
                    />
                ),
                id: "part_3",
            },
            {
                displayValue: (
                    <ArticleDescription
                        description={articleInfo.description}
                        additionalDescription={articleInfo.additionalDescription}
                        productGroupName={articleInfo.productGroup?.name}
                        isBigScreen={isBigScreen}
                        isLinkedItem={isLinkedItem}
                    />
                ),
                id: "part_4",
            },
            {
                displayValue: (
                    <ArticleType
                        articleInfoType={articleInfoType}
                        foundBySearchTerm={foundBySearchTerm}
                        voucherVehicleInfo={vehicle}
                        hideVehicleLogos={hideVehicleLogos}
                        onOpenArticleSearch={onOpenArticleSearch}
                    />
                ),
                id: "part_5",
            },
        ]
        if (hasNotes) {
            cellData.push({
                displayValue: memo && (
                    <NotesButton
                        onClick={() => toggleIsNoteExpanded(part.id)}
                        hasNote
                        iconOnly
                        articleNotes={[
                            {
                                type: NoteTypes.ARTICLE,
                                message: memo,
                                title: translateText(12874),
                            },
                        ]}
                    />
                ),
                id: "part_6",
            })
        }

        cellData.push(
            {
                displayValue: (
                    <ArticleQuantities
                        confirmedQuantityValue={confirmedQuantity.value}
                        returnedQuantityValue={returnedQuantity?.value}
                        isBigScreen={isBigScreen}
                    />
                ),
                id: "part_7",
            },
            {
                displayValue: (
                    <PartPrices
                        prices={orderPrices}
                        cumulatedPrices={cummulatedOrderPrices}
                        showPurchasePrice={userSettings?.showPurchasePrice}
                        showTotalPrices
                        isBigScreen={isBigScreen}
                        theme={theme}
                    />
                ),
                id: "part_8",
            },
            {
                displayValue: (
                    <PartActions
                        workTaskId={workTaskId}
                        voucherId={voucherId}
                        voucherType={VoucherType.Order}
                        orderedItem={part}
                        isSelected={selectedIds?.includes(part.id) || false}
                        isBigScreen={isBigScreen}
                        onSelectPart={onSelectPart}
                    />
                ),
                id: "part_9",
            }
        )

        return cellData
    }

    return <Table headerBackground="transparent" columns={getColumns()} rows={getDisplayData()} overflowY="unset" />
}
