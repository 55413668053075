import { createContext, useContext } from "react"
import { Work } from "../models"
import { useStepContext } from "./stepContext"

export type WorkContextType = {
    work: Work | undefined
}

export type UseWorkContextType = WorkContextType & {
    path: string
    step: string
}

export const WorkContext = createContext<WorkContextType | null>(null)

// This hook also returns the step in which the work is
export const useWorkContext = (): UseWorkContextType => {
    const { work } = useContext(WorkContext) ?? {}
    const { step } = useStepContext()

    if (!work) {
        return { work: undefined, path: "", step }
    }

    return {
        work,
        path: work?.label ?? "",
        step,
    }
}
