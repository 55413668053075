import { Button, Typography, Box, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ETyresSeason } from "@tm/models"
import { TyresCritsResponse } from "../../../../../data/repositories"
import { getSeasonTitle } from "../../../../../helpers/getSeasonTitle"
import { ExtendEFilterNames, SelectedFilters } from "../../../../../data/models"

type Props = {
    selectedFilters: SelectedFilters
    setSelectedFiltersTemp: (filters?: SelectedFilters) => void
    tyresCrits: Partial<TyresCritsResponse>
    freeInputMode: boolean
    previousFieldsCompleted: boolean
}

export function SeasonSpecifications(props: Props) {
    const { selectedFilters, setSelectedFiltersTemp, tyresCrits, freeInputMode, previousFieldsCompleted } = props
    const { season } = selectedFilters
    const { translateText } = useLocalization()

    const handleFilterSelect = (path: string, filter: string) => {
        if (path === ExtendEFilterNames.season) {
            setSelectedFiltersTemp({
                ...selectedFilters,
                season: filter,
            })
        }
    }

    if (!previousFieldsCompleted) {
        return null
    }

    return (
        <StyledBox>
            <Typography marginRight=".5rem">{translateText(13078)}</Typography>
            {[ETyresSeason.summer, ETyresSeason.winter, ETyresSeason.allSeason].map((seasonKey) => {
                const defaultVariant: "text" | "contained" | "outlined" = seasonKey === season ? "outlined" : "contained"
                if (tyresCrits.season?.length === 1 && tyresCrits.season[0].value === seasonKey && !season) {
                    handleFilterSelect(ExtendEFilterNames.season, seasonKey)
                }
                return (
                    <Button
                        key={seasonKey}
                        disabled={freeInputMode ? false : !tyresCrits.season?.some((x) => x.value === seasonKey)}
                        onClick={() => handleFilterSelect(ExtendEFilterNames.season, seasonKey)}
                        variant={tyresCrits.season?.length === 1 && tyresCrits.season[0].value === seasonKey ? "outlined" : defaultVariant}
                        size="large"
                    >
                        {translateText(getSeasonTitle(seasonKey))}
                    </Button>
                )
            })}
        </StyledBox>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    gap: ".5em",
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(1),
}))
