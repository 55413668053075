import { Article, DefaultArticleItemState, ListOptions, OE } from "@tm/models"
import { useMemo } from "react"
import { useDisableReason } from "./useDisableReason"
import { usePartsViewOptions } from "../usePartsViewOptions"
import { useListOptionsContext } from "../../ContextProvider"

export function useArticleOptions(article: Article): DefaultArticleItemState["options"] {
    const options = useListOptionsContext()
    const {
        partsViewSettings: { compactView, quantitySuggestionEnabled },
    } = usePartsViewOptions()
    const hasSuggestedQuantity = quantitySuggestionEnabled && !!article.suggestedQuantity

    const disableReason = useDisableReason(article)

    return useMemo(
        () => ({
            ...options,
            compactView,
            hasSuggestedQuantity,
            disableReason,
        }),
        [options, disableReason, compactView, hasSuggestedQuantity]
    )
}

export function useOeArticleOptions(article: OE.OePart): DefaultArticleItemState["options"] {
    const options = useListOptionsContext()
    const {
        partsViewSettings: { compactView },
    } = usePartsViewOptions()

    return useMemo(
        () => ({
            ...options,
            compactView,
        }),
        [options, compactView]
    )
}
