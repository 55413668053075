import { SortDirection } from "@tm/components"
import { VeFiles } from "@tm/data"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useLocation } from "react-router"

const SEARCH_QUERY_PARAM = "query"

export function useVefilesSearch() {
    const history = useHistory()
    const location = useLocation()
    const queryStart = useMemo(() => new URLSearchParams(location.search).get(SEARCH_QUERY_PARAM), [location.search])

    const [tempSearchQuery, setTempSearchQuery] = useState("")
    const [sortOrder, setSortOrder] = useState<VeFiles.FileSortOrder>()

    useEffect(() => {
        if (!tempSearchQuery && queryStart) {
            setTempSearchQuery(queryStart)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryStart])

    const handleSearch = useCallback(() => {
        if (!tempSearchQuery) {
            const queryParams = new URLSearchParams(location.search)
            if (queryParams.get(SEARCH_QUERY_PARAM)) {
                queryParams.delete(SEARCH_QUERY_PARAM)
                history.replace({
                    search: queryParams.toString(),
                })
            }

            return
        }

        history.push({
            search: `${SEARCH_QUERY_PARAM}=${tempSearchQuery}`,
        })
    }, [tempSearchQuery, location.search, history])

    const handleSortOrderClick = useCallback((property: VeFiles.FileSortingField, sortDirection: SortDirection) => {
        setSortOrder({ field: property, descending: sortDirection === "desc" })
    }, [])

    return {
        search: {
            handleSearch,
            tempSearchQuery,
            setTempSearchQuery,
        },
        searchQuery: queryStart ?? undefined,
        sortOrder,
        onSort: handleSortOrderClick,
    }
}
