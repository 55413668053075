import { useAvailabilityStatus, useStyle, useUser } from '@tm/context-distribution'
import { Loader, Scrollbar, Text } from '@tm/controls'
import { ConfigParams, SystemType, TyreArticle } from '@tm/models'
import Morpheus, { useActions } from '@tm/morpheus'
import { classes, createQueryString, parseQueryString, renderRoute, RouteComponentProps, withRouter } from '@tm/utils'
import { em } from 'csx'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { TyresError } from '.'
import { getBundleParams } from '../../../utils'
import { addOrRemoveItem } from '../../../data/helpers'
import { IAttributesItem } from '../../../data/models'
import { MainState } from '../../main'
import { MainActions } from '../../main/business'
import { ArticleItem } from '../../_shared'
import { getAttributeString } from '../business/helpers'
import { filterList } from '../business/helpers/filterList'
import { useMemo } from 'react'

type Props = RouteComponentProps<any> & {
	repairTimesRoute: string
	className?: string
	detailsModalRoute?: string
}

const TyresListComponent: React.FC<Props> = ({ repairTimesRoute, className, detailsModalRoute, match }) => {
	let scrollTop = 0
	let blockScroll = false

	const { articles, vehicle, availability } = useSelector((s: MainState) => ({
		articles: s.list.articles,
		vehicle: s.manager.vehicle,
		availability: s.list.selectedFilters.availability
	}))

	const user = useUser()

	const [selectedAttributes, setSelectedAttr] = React.useState<string[]>([])

	const { error, loading, loadingNextItems, loadingAutoItems, data, nextArticlesError, count, pageIndex } = articles
	const actions = useActions(MainActions, "loadNextTyresList", "changeQuantity", "changeStep")

	const handleScroll = (e: React.UIEvent<HTMLElement> | UIEvent) => {
		const el = e.target as HTMLElement
		if ((el.scrollHeight - el.scrollTop) <= (el.clientHeight + 100) && el.scrollTop > scrollTop) {
			actions.loadNextTyresList()
			blockScroll = true
			scrollTop = el.scrollTop
		}

	}

	const handleSelectedAttribute = (_: TyreArticle, item: IAttributesItem) => {
		setSelectedAttr(addOrRemoveItem(selectedAttributes, getAttributeString(item)))
	}

	const handleArticleDetails = (article: TyreArticle, path?: string) => {
		const params = {
			productGroupId: article.productGroup.id,
			supplierId: article.supplier.id,
			supplierArticleNo: article.supplierArticleNo,
			quantity: article.quantity,
			partsDetailsSubPage: path
		}

		const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()

		let queryParams = parseQueryString(location.search)
		queryParams["initialQuantity"] = params?.quantity

		if (articleDetailsInModal && user?.userContext?.system.systemType == SystemType.Next && detailsModalRoute)
			Morpheus.showView("1", renderRoute(detailsModalRoute, { ...match.params, ...params }) + createQueryString(queryParams))
		else
			actions.changeStep("details", params)
	}

    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()
    const items = filterList(data, selectedAttributes, availability, availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary)

	const { maxAutoRequest, minAvailableItems, pageSize, isFiltersOnRightSide } = getBundleParams()
	const style = useMemo(() => getStyle(isFiltersOnRightSide), [])


	React.useEffect(() => {
		if (availability &&
			items.length < minAvailableItems
			&& pageIndex < count / pageSize
			&& !nextArticlesError
			&& articles.autoNextCount < maxAutoRequest) {

			actions.loadNextTyresList(true)
		}

	}, [availability, items])

	if (error)
		return (
			<div className={className}>
				<TyresError />
			</div>
		)

	const renderRow = (article: TyreArticle) => {
		return (<ArticleItem
			key={article.id}
			onArticleAttributeSelect={handleSelectedAttribute}
			selectedArticleAttributes={selectedAttributes}
			repairTimesRoute={repairTimesRoute}
			hidden={loadingAutoItems && !article.erpInformation}
			vehicle={vehicle}
			onArticleDetailsClick={handleArticleDetails}
			onChangeStandaloneQuantity={actions.changeQuantity}
			item={article}
		/>)
	}

	return (
		<div className={className}>
			<Scrollbar onScroll={handleScroll} >

				<div className="tk-parts">
					{(!loading && !loadingAutoItems && !items.length) && <TyresError />}
					{loading && <div className="article-list__panel article-list__status">
						<Loader />
					</div>}
					<div className={classes(style.articleList, "list article-list")}>

						{items.map(renderRow)}
					</div>
				</div>
				{loadingAutoItems && articles.autoNextCount != 0 &&
					<div className={classes("article-list__panel", "article-list__status", style.loadWrapper)}>
						<Text>Try to load more data</Text>
						<Loader />
					</div>}

				{loadingNextItems && < div className="article-list__panel article-list__status">	<Loader /></div>}
			</Scrollbar>
		</ div >
	)
}

function getStyle(isFiltersOnRightSide?: boolean){
	return useStyle({
		articleList: {
			padding: isFiltersOnRightSide ? "7px 10px 0 0 !important" : undefined,
			$nest: {
				".article__cell--description": {
					marginRight: "1em",
					minWidth: em(20),
					flex: "1"
				},
				".article__cell--icon": {
					flex: "1"
				},
				".article__cell--erp-information .erp-info__prices .price__group .price": {
					display: "block",
					textAlign: "right"
				},
				".article__cell--erp-information .erp-info__prices .price__group .price .price__icon": {
					marginBottom: em(-.25)
				}
			}
		},
		loadWrapper: {
			display: "flex",
			flexDirection: "column",
			height: em(5)
		}
	})(TyresListComponent)
}

export default React.memo(withRouter(TyresListComponent))
