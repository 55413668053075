import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { PrintOptions } from "../../models"

export type PrintOptionsSlice = {
    printOptions: PrintOptions
}

export const getInitialState = (): PrintOptionsSlice => {
    return {
        printOptions: {
            inspectionTimes: false,
            remarks: false,
            images: false,
            tyres: false,
            nextMaintenance: false,
            showDefects: false,
        },
    }
}

export const createPrintOptionsSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], PrintOptionsSlice> = () => ({
    ...getInitialState(),
})
