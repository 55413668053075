import { ArticleItem, Box, Button, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleListSortingMode } from "@tm/models"
import { useCallback, useMemo } from "react"
import { getBundleParams } from "../../../utils"
import { ArticleGroup } from "../components/ArticleGroup"
import { ArticleSelection } from "../components/ArticleSelection"
import { NextPageLoader } from "../components/NextPageLoader"
import { NoSearchResult } from "../components/NoSearchResult"
import { OePositions } from "../components/OePositions"
import { useArticleSelection } from "../hooks/useArticleSelection"
import { usePartsViewOptions } from "../hooks/usePartsViewOptions"
import { ArticleGroupHeader } from "../../list/components/ArticleGroupHeader/ArticleGroupHeader"
import { ArticleListWrapper } from "../components/ArticleListWrapper"
import { OeArticleItemStateProvider } from "../components/OeArticle/OeArticleItemStateProvider"
import { useVehicle } from "../hooks/useVehicle"
import { useLoadVehicleRecords } from "../hooks/useVehicleRecords"
import { useOePartsListParamsContext } from "./ContextProvider/ListParams"
import { useListOptionsContext } from "../ContextProvider"
import { useArticles } from "./ContextProvider/Articles/useArticles"
import { useOePartsArticlesContext } from "./ContextProvider/Articles"
import { useProductGroupTopicIdsDataContext } from "../ContextProvider/ProductGroupTopicIds"

const ModalArticleListWrapper = styled(ArticleListWrapper)({
    height: "100%",
    paddingLeft: 0,
})

export function OePartsList() {
    const { translateText } = useLocalization()
    const params = useOePartsListParamsContext()
    const {
        noResult,
        oePositions,
        selectedOePart,
        changeOePosition,
        attributes,
        toggleAttribute,
        setAttributes,
        sorting: { productGroups: sortingProductGroups, setForProductGroups: activateSortingForProductGroups },
        startParams: { replaceButtonBundle },
    } = params

    const options = useListOptionsContext()
    const vehicle = useVehicle()
    const articlesData = useArticles(vehicle, params, [], true)
    const productGroupIds = [
        selectedOePart?.productGroupId || selectedOePart?.attributes?.[0]?.topAttributes?.find((x) => !!x.productGroupId)?.productGroupId || 0,
    ]
    useLoadVehicleRecords(vehicle, productGroupIds, articlesData.articles, true)

    const { articleGroups, isLoading, isLoaded, isFailed, isFetchingNextPage, hasNextPage, loadNextPage, isStalling, isFrontendFiltered } =
        useOePartsArticlesContext()
    const productGroupTopicIds = useProductGroupTopicIdsDataContext()

    const {
        partsViewSettings: { showVehicleRecordsFilters },
    } = usePartsViewOptions()

    const calculatorRoute = getBundleParams().fastCalculatorModalRoute

    const usedAttributeFilters = useMemo(() => attributes.map((x) => x.query ?? `${x.id}|${x.key ?? ""}`), [attributes])
    const prefilledAttributeFilters = useMemo(() => {
        return selectedOePart?.attributes?.flatMap((x) => [
            ...x.topAttributes.map((attr) => `${attr.id}|${attr.key ?? ""}`),
            ...x.vehicleAttributes.map((attr) => `${attr.id}|${attr.key ?? ""}`),
        ])
    }, [selectedOePart])

    const handleResetAttributeFilters = useCallback(() => {
        if (usedAttributeFilters.length) {
            setAttributes([])
        }
    }, [usedAttributeFilters, setAttributes])

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            activateSortingForProductGroups([productGroupId], value)
        },
        [activateSortingForProductGroups]
    )

    const { selectedArticles } = useArticleSelection()
    let distance = ""
    if (selectedArticles.length > 0) {
        distance = "-50px"
    }

    return (
        <>
            <ArticleSelection
                maxArticleNumberToCompareReached
                showArticleComparision
                hideBasketButton={!!replaceButtonBundle || options.hideAddToBasketButton}
            />
            <Box position="relative" display="grid" gridTemplateRows="auto 1fr" overflow="hidden" mt={distance} height="100%">
                <Box p={1} className="oe-article-section">
                    <Box marginBottom={1}>
                        <OePositions onPositionSelect={changeOePosition} positions={oePositions} />
                    </Box>
                    {selectedOePart && (
                        <>
                            <ArticleGroupHeader title={translateText(803)} key="oeSelectedPartHeader" />
                            <OeArticleItemStateProvider article={selectedOePart} articleTypeKey="OePositionsSelectedOePart">
                                <ArticleItem variant="OE" />
                            </OeArticleItemStateProvider>
                        </>
                    )}
                </Box>

                <Box pr={1} overflow="hidden">
                    {(!isFetchingNextPage && !isLoading && isLoaded && !articleGroups.length) || isFailed || noResult ? (
                        <NoSearchResult searchType="oe" />
                    ) : (
                        <ModalArticleListWrapper>
                            {isLoaded &&
                                !isStalling &&
                                articleGroups.map((group) => (
                                    <ArticleGroup
                                        key={group.productGroup.id}
                                        group={group}
                                        currentSorting={sortingProductGroups[group.productGroup.id]}
                                        onChangeSorting={handleChangeSorting}
                                        calculatorRoute={calculatorRoute}
                                        showFilterOptions={showVehicleRecordsFilters}
                                        usedAttributeFilters={usedAttributeFilters}
                                        prefilledAttributeFilters={prefilledAttributeFilters}
                                        onToggleAttributeFilter={toggleAttribute}
                                        onResetAttributeFilters={handleResetAttributeFilters}
                                        productGroupTopicIds={productGroupTopicIds}
                                    />
                                ))}

                            {(((hasNextPage || isLoading) && !isFrontendFiltered) || isStalling) && (
                                <NextPageLoader
                                    loadNextPage={loadNextPage}
                                    canLoadNextArticles={hasNextPage}
                                    skeletonArticleCount={(!isLoading || isFetchingNextPage) && !isStalling ? 1 : 5}
                                    skeletonCategory
                                />
                            )}

                            {hasNextPage && isFrontendFiltered && (
                                <Box display="flex" justifyContent="center">
                                    <Button color="highlight" onClick={loadNextPage}>
                                        {translateText(12430)}
                                    </Button>
                                </Box>
                            )}
                        </ModalArticleListWrapper>
                    )}
                </Box>
            </Box>
        </>
    )
}
