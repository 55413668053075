import { useMemo } from "react"
import { useLocation } from "react-router"
import Morpheus from "@tm/morpheus"
import { PartsBundleParams } from "@tm/models"
import { ArticleListConfigurationProvider } from "../ArticleListConfiguration"
import { DirectSearch } from "../DirectSearch"
import { SearchType } from "../../../business"
import { ProductGroups } from "../ProductGroups"
import WholesalerArticleNumbers from "../WholesalerArticleNumbers"
import { UniversalParts } from "../UniversalParts"
import { ExternalUniversalParts } from "../UniversalParts/ExternalUniversalParts"

export function PartsAlternatives() {
    const location = useLocation()

    const searchParams = new URLSearchParams(location.search)
    const searchTypeString = searchParams.get("searchType") ?? undefined

    const { uniPartsIsExternalSystem } = Morpheus.getParams<PartsBundleParams>("parts")

    const content = useMemo(() => {
        if (searchTypeString) {
            const searchType = SearchType[searchTypeString.toUpperCase() as keyof typeof SearchType]
            switch (searchType) {
                case SearchType.DIRECT:
                    return <DirectSearch />
                case SearchType.PRODUCTGROUPS:
                    return <ProductGroups />
                case SearchType.TRADERARTICLENOS:
                    return <WholesalerArticleNumbers />
                case SearchType.UNISEARCH:
                    return uniPartsIsExternalSystem ? <ExternalUniversalParts /> : <UniversalParts inModal />
                default:
                    break
            }
        }

        return null
    }, [searchTypeString])

    return <ArticleListConfigurationProvider>{content}</ArticleListConfigurationProvider>
}
