import { useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Card, Button, TextField, Loader, Stack, Icon, Switch } from "@tm/components"
import { ButtonKeyDefinition } from "@tm/utils"
import { useCreateCompilation } from "../../../../../data/hooks/useCompilations"
import { isWM } from "../../../../../utils"

type Props = {
    short?: boolean
    onClose(): void
}

export default function CompilationCreator({ short, onClose }: Props) {
    const { translateText } = useLocalization()
    const descriptionRef = useRef<HTMLInputElement>(null)
    const confirmationRef = useRef<HTMLButtonElement>(null)

    const { createCompilation, creatingCompilation } = useCreateCompilation()

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [isPublic, setIsPublic] = useState(true)

    const handleNameChange = (value: string | null) => {
        setName(value || "")
    }

    const handleNameKeyDown = (e: any) => {
        if (e.key == ButtonKeyDefinition.Enter) {
            descriptionRef.current?.focus()
        }
    }

    const handleDescriptionChange = (value: string | null) => {
        setDescription(value || "")
    }

    const handleDescriptionKeyDown = (e: any) => {
        if (e.key == ButtonKeyDefinition.Enter) {
            confirmationRef.current?.focus()
        }
    }

    const handleConfirmButtonClick = () => {
        createCompilation({ name, description, isPublic })
    }

    return (
        <Card sx={{ p: ".5rem" }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Stack direction="row" gap={2} alignItems="center" flex={0.7}>
                    <TextField
                        sx={{ flex: "1 1 25rem" }}
                        inputProps={{ maxLength: 50 }}
                        onKeyDown={handleNameKeyDown}
                        label={translateText(155)}
                        variant="filled"
                        inputCounter
                        onChange={(e) => handleNameChange(e.target.value)}
                        size="large"
                        value={name}
                    />
                    {!short && (
                        <TextField
                            sx={{ flex: "1 1 40rem" }}
                            label={translateText(617)}
                            variant="filled"
                            size="large"
                            inputCounter
                            inputProps={{ maxLength: 200 }}
                            ref={descriptionRef}
                            value={description}
                            onChange={(e) => handleDescriptionChange(e.target.value)}
                            onKeyDown={handleDescriptionKeyDown}
                        />
                    )}
                    {isWM() && <Switch labelPlacement="end" label={translateText(1254)} onChange={() => setIsPublic(!isPublic)} checked={isPublic} />}
                </Stack>
                {creatingCompilation ? (
                    <Loader size="extrasmall" />
                ) : (
                    <Stack direction="row" gap={0.5}>
                        <Button title={translateText(70)} onClick={onClose} startIcon={<Icon name="close" />} />
                        <Button
                            title={translateText(9)}
                            onClick={handleConfirmButtonClick}
                            startIcon={<Icon name="check" />}
                            color="success"
                            ref={confirmationRef}
                            disabled={!name}
                        />
                    </Stack>
                )}
            </Stack>
        </Card>
    )
}
