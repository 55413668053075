import { useLocalization } from "@tm/localization"
import { VoucherType } from "@tm/models"
import { Box, Tooltip, Button, Icon, Badge } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { ExtendedOrderVoucherItem } from "../../../../../../../data/model"
import { useOrderDetails } from "../../../../../business/hooks/useOrderDetails"
import { useAddReturnItem } from "../../../../../business/hooks/useReturnItems"
import { getAddReturnItemRequest } from "../../../../../business"

type Props = {
    voucherId?: string
    voucherType: VoucherType
    orderedItem?: ExtendedOrderVoucherItem
}

export default function ReturnButton({ orderedItem, voucherType, voucherId }: Props) {
    const { translateText, date } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { orderDetails } = useOrderDetails(voucherType === VoucherType.Order ? voucherId : undefined)
    const { addReturnItem } = useAddReturnItem(voucherType === VoucherType.Order ? voucherId : undefined)

    function handleReturnItemClick() {
        if (orderedItem) {
            const request = getAddReturnItemRequest(
                orderedItem,
                VoucherType.Order,
                voucherId,
                orderDetails?.orderDate,
                orderDetails?.wholesalerOrderNumber
            )
            if (request) {
                addReturnItem(request)
            }
        }
    }

    if (!userContext.hasMailRetoure || voucherType !== VoucherType.Order) {
        return null
    }

    let returnText = translateText(13369)
    const returnButton = (
        <Button
            disabled={!orderedItem?.canReturn}
            startIcon={<Icon name="return" />}
            title={translateText(13167)}
            variant="bordered"
            onClick={handleReturnItemClick}
        />
    )

    // If returnInfo is set, the item is allready returned
    if (orderedItem?.returnInfo?.returnNumber && !orderedItem?.canReturn) {
        returnText = translateText(13436)
    }
    return (
        <Box>
            {orderedItem?.pendingReturnQuantity && (
                <Badge
                    size="small"
                    badgeContent={orderedItem.pendingReturnQuantity.value}
                    sx={{ position: "absolute", marginLeft: "30px", marginTop: "3px" }}
                />
            )}
            {orderedItem?.returnableUntil && !orderedItem?.pendingReturnQuantity ? (
                <Tooltip title={orderedItem?.canReturn ? translateText(13370).replace("{0}", date(orderedItem.returnableUntil, "d")) : returnText}>
                    <Box>{returnButton}</Box>
                </Tooltip>
            ) : (
                returnButton
            )}
        </Box>
    )
}
