import { ArticleQuantities, WholesalerArticleDto, WholesalerPart } from "@tm/models"
import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { useWholesalerPartBasketQuantities } from "../../../ContextProvider/BasketQuantities"
import { ensureContextAvailability } from "../../../helpers"
import { useFiltersAndResultsContext } from "../FiltersAndResults"

export const WholesalerPartBasketQuantitiesContext = createContext<WholesalerPartBasketQuantities | undefined>(undefined)

type WholesalerPartBasketQuantities = {
    basketQuantities: ArticleQuantities<WholesalerArticleDto>[]
    updateBasketQuantities: (articles: WholesalerPart[]) => Promise<void>
}

export function WholesalerPartBasketQuantitiesProvider(props: PropsWithChildren<{ isEnabled: boolean }>) {
    const { wholesalerNosArticles } = useFiltersAndResultsContext()
    const wholesalerPartBasketQuantities = useWholesalerPartBasketQuantities(wholesalerNosArticles, props.isEnabled)
    return (
        <WholesalerPartBasketQuantitiesContext.Provider value={wholesalerPartBasketQuantities}>
            {props.children}
        </WholesalerPartBasketQuantitiesContext.Provider>
    )
}

export function useWholesalerPartBasketQuantitiesContext(): WholesalerPartBasketQuantities {
    const context = useContext(WholesalerPartBasketQuantitiesContext)
    return ensureContextAvailability("WholesalerPartBasketQuantitiesContext", context)
}
