import { OeArticleQuantitiesRequest } from "@tm/models"
import { ajaxAuth, notUndefinedOrNull } from "@tm/utils"
import { getServiceUrl } from "../../.."
import { ShowQuantitiesByOeArticleRequest, ShowQuantitiesByOeArticleResponse } from "../../../model"

// TODO: Remove when the artilce list V2 doesn't use it anymore
export function getShowQuantitiesByOeArticle(requests: OeArticleQuantitiesRequest[]) {
    const url = `${getServiceUrl()}/ShowQuantitiesByOeArticle`
    const body: ShowQuantitiesByOeArticleRequest = {
        workTaskId: requests[requests.length - 1].workTaskId,
        articles: requests.map((r) => r.article),
    }

    return ajaxAuth<ShowQuantitiesByOeArticleResponse>({ url, body, method: "POST" }).then(
        (data) => data?.oeArticleQuantities?.filter(notUndefinedOrNull) || []
    )
}
