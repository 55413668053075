import { Box, styled } from "@tm/components"
import { FC, memo, useEffect, useMemo, useState } from "react"
import { Mousewheel } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import SlideComponent from "./slideComponent"
import { changeStepWithHistory, useFastServiceStore } from "../../data"

type StepperNavigationProps = {
    stepsWrapperHeight: number
}

const StepperNavigation: FC<StepperNavigationProps> = memo(({ stepsWrapperHeight }) => {
    const { navigationSteps, activeStep } = useFastServiceStore((state) => ({
        navigationSteps: state.stepNavigationState.steps,
        activeStep: state.navigation.active,
    }))

    const [insideActiveIndex, setInsideActiveIndex] = useState(0)
    const [controlledSwiper, setControlledSwiper] = useState<any>(null)
    const realActiveIndex = useMemo<number>(() => getStep(activeStep || "start"), [activeStep])
    const isNotDev = localStorage.getItem("ENV") !== "development"

    function getStep(step: string): number
    function getStep(step: number): string
    function getStep(step: string | number): number | string {
        if (typeof step === "string") {
            return navigationSteps.findIndex((x) => x.stepName === step)
        }

        return navigationSteps[step]?.stepName
    }

    useEffect(() => {
        if (realActiveIndex !== insideActiveIndex && controlledSwiper) {
            setInsideActiveIndex(realActiveIndex)
            controlledSwiper?.slideTo(realActiveIndex)
        }
    }, [realActiveIndex, controlledSwiper])

    const handleInsideChange = (e: any) => {
        if (e.activeIndex !== insideActiveIndex) {
            setInsideActiveIndex(e.activeIndex)
        }
    }

    const handleChange = (e: any) => {
        // if (!e.initialized || e.isBeginning) return

        if (realActiveIndex !== e.activeIndex && navigationSteps.find((x) => x.indexNumber === realActiveIndex)?.available && isNotDev) {
            controlledSwiper?.slideTo(realActiveIndex)
            changeStepWithHistory(getStep(realActiveIndex) ?? "start")

            return null
        }

        handleInsideChange(e)
    }

    const onClick = (e: any, idx: number) => {
        // got some issues there because, when you click, sometimes the onTouch event will be fired (this is because the cursor has slightly moved 1 pixel or more, but that is not observable by eye)
        e.preventDefault()
        e.stopPropagation()
        if (!navigationSteps.find((x) => x.indexNumber === idx)?.available) {
            controlledSwiper?.slideTo(realActiveIndex)
            return null
        }
        controlledSwiper.slideTo(idx)
        changeStepWithHistory(getStep(idx) ?? "start")
    }

    const renderArray = () => {
        return navigationSteps.map((slide, idx) => {
            return (
                <SwiperSlide
                    style={{
                        width: "100%",
                        paddingLeft: "0.5em",
                        display: "flex",
                    }}
                    onClick={(e) => onClick(e, idx)}
                    key={slide.stepName + idx}
                >
                    <SlideComponent idx={idx} slideObject={slide} activeIndex={insideActiveIndex} />
                </SwiperSlide>
            )
        })
    }
    return (
        <StepsWrapper>
            {stepsWrapperHeight > 0 && (
                <Swiper
                    style={{ height: stepsWrapperHeight }}
                    className="swiperjs"
                    direction="vertical"
                    slidesPerView={9}
                    spaceBetween={20}
                    breakpoints={{
                        850: {
                            slidesPerView: 7,
                            spaceBetween: 20,
                        },
                        1500: {
                            slidesPerView: 9,
                            spaceBetween: 20,
                        },
                    }}
                    modules={[Mousewheel]}
                    watchSlidesProgress
                    speed={200}
                    preventInteractionOnTransition
                    loopPreventsSlide
                    centeredSlides
                    mousewheel
                    onSwiper={(s) => setControlledSwiper(s)}
                    onTransitionEnd={handleChange}
                    onTouchMove={handleInsideChange}
                >
                    {renderArray()}
                </Swiper>
            )}
        </StepsWrapper>
    )
})

export default StepperNavigation

const StepsWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flexWrap: "wrap",
    touchAction: "none",
    ".swiperjs": {
        width: "12.5em",
    },
    ">*": {
        userSelect: "none",
    },
})
