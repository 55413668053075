import { JSXElementConstructor, PropsWithChildren } from "react"

export type ComposerComponent =
    | [JSXElementConstructor<PropsWithChildren<any>>, Record<string, unknown>]
    | [JSXElementConstructor<PropsWithChildren<any>>]

type ComposerProps = PropsWithChildren<{
    components: ComposerComponent[]
}>

export function ContextComposer(props: ComposerProps) {
    const { components = [], children } = props

    return (
        <>
            {components.reduceRight((acc, [Comp, compProps]) => {
                return <Comp {...compProps}>{acc}</Comp>
            }, children)}
        </>
    )
}
