import { Icon, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CisVoucherType } from "@tm/models"
import { PartItem } from "../../../../../data/model"
import VoucherButton from "../../../../_shared/VoucherButton"

type Props = {
    externalSystemId?: number
    cisVoucherUrl?: string
    voucherTypeId?: CisVoucherType
    partItem: PartItem
    workTaskId?: string
}

export default function PartOrderInfo({ cisVoucherUrl, externalSystemId, voucherTypeId, partItem, workTaskId }: Props) {
    const { translateText, date } = useLocalization()
    const { costEstimationItem, articleInformation } = partItem

    if (!costEstimationItem?.isOrdered || !costEstimationItem?.orderInformation) {
        return <></>
    }

    const orderDate = new Date(costEstimationItem.orderInformation.orderDate)
    const distributor = costEstimationItem.orderInformation.distributorName

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="row" spacing={1} alignItems="center">
                <Icon name="check-filled" color="success" height="1.5em" width="1.5em" />
                <Typography variant="body2">
                    {translateText(698)} {date(orderDate, "d")} {translateText(699)} {orderDate.toLocaleTimeString()}{" "}
                    {distributor && `${translateText(13884)}: ${distributor}`} / {translateText(782)}:{" "}
                    {costEstimationItem.orderInformation.orderedQuantity}
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="label">{translateText(700)} </Typography>
                <VoucherButton
                    workTaskId={workTaskId}
                    voucherTypeId={voucherTypeId}
                    externalSystemId={externalSystemId}
                    cisVoucherUrl={cisVoucherUrl}
                    orderNumber={costEstimationItem.orderInformation.orderNumber}
                    orderVoucherId={costEstimationItem.orderInformation.orderVoucherId}
                    itemId={articleInformation.wholesalerArticleNumber || articleInformation.articleNumber}
                    variant="orderNumber"
                />
            </Stack>
        </Stack>
    )
}
