import { useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute, useExternalCatalogUrl, mapVoucherTypeForUrl, mapVoucherTypeFromUrl } from "@tm/utils"
import { Dialog } from "@tm/controls"
import { VoucherType } from "@tm/models"
import { useTelesalesCustomerNumber, useUser } from "@tm/context-distribution"
import { Typography, Switch, ModuleHeaderSlot } from "@tm/components"
import { useHistory, useRouteMatch, useParams, useLocation } from "react-router"
import ReturnsButtonComponent from "./components/ReturnsButton"
import { VoucherTypeSelectItem } from "../../../_shared/VoucherTypeSelector"
import { RowStack } from "../../../_shared/StyledComponents"
import { VoucherRouteParams } from "../../../../business"
import { useShowOnlyUserVouchers } from "../../../../data/hooks/useShowOnlyUserVouchers"
import { useDefaultVoucherType } from "../../../../hooks/useDefaultVoucherType"
import ArticleSearchFields from "../../../_shared/ArticleSearchFields"
import VoucherDetailsOptions from "./components/VoucherDetailsOptions"
import Areas from "./components/Areas"
import DateFilters from "./components/DateFilters"
import ActionButtons from "./components/ActionButtons"
import VehicleSelector from "../../../_shared/VehicleSelector"

type Props = {
    externalSystemId?: number
}

export default function Navigation({ externalSystemId }: Props) {
    const { translateText } = useLocalization()
    const location = useLocation()
    const params = useParams<VoucherRouteParams>()
    const history = useHistory()
    const { path } = useRouteMatch()
    const { hasMailRetoure } = useUser().userContext
    const externalOrdersDialogRef = useRef<Dialog>(null)
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const defaultVoucherType = useDefaultVoucherType()
    const queryParams = new URLSearchParams(location.search)
    const [searchQuery, setSearchQuery] = useState(queryParams.get("searchQuery") || undefined)

    const { externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })
    const { showOnlyUserVouchers, setShowOnlyUserVouchers, showOnlyUserVouchersLoaded } = useShowOnlyUserVouchers()

    const voucherType = mapVoucherTypeFromUrl(params.type)
    const url = renderRoute(path, { ...params, id: undefined })

    function redirectToVoucherList() {
        history.push(url)
    }

    function handleChangeVoucherType(entry: VoucherTypeSelectItem) {
        const newUrl = renderRoute(path, { ...params, type: mapVoucherTypeForUrl(entry.voucherType), id: undefined })
        setSearchQuery(undefined)
        history.push(newUrl)
        if (externalSystemId && entry.voucherType === VoucherType.Order) {
            externalOrdersDialogRef.current?.show()
        }
    }

    if (!defaultVoucherType) {
        return null
    }

    const listActive = !params.type || !params.id
    return (
        <RowStack justifyContent="space-between" flex={1} mb={2}>
            <RowStack spacing={1} alignItems="flex-end" pl={2}>
                <Typography variant="h1">{translateText(31)}</Typography>
                <ModuleHeaderSlot title={translateText(176)}>
                    <Areas voucherType={voucherType} onVoucherTypeChanged={handleChangeVoucherType} />
                </ModuleHeaderSlot>
                {!!listActive && hasMailRetoure && voucherType === VoucherType.Order && (
                    <ModuleHeaderSlot title={translateText(177)}>
                        {voucherType === VoucherType.Order && hasMailRetoure && <ReturnsButtonComponent />}
                    </ModuleHeaderSlot>
                )}
                <ModuleHeaderSlot title={translateText(209)}>
                    <RowStack gap={1}>
                        <DateFilters onRedirectToVoucherList={redirectToVoucherList} />
                        {voucherType !== VoucherType.Return && <VehicleSelector active autoWidth size="medium" variant="outlined" />}
                    </RowStack>
                </ModuleHeaderSlot>
                <ModuleHeaderSlot title={translateText(135)}>
                    <ArticleSearchFields query={searchQuery} url={url} variant="header" />
                </ModuleHeaderSlot>
                <ModuleHeaderSlot title={translateText(1131)}>
                    <Switch
                        checked={showOnlyUserVouchers}
                        size="small"
                        onChange={() => setShowOnlyUserVouchers?.(!showOnlyUserVouchers)}
                        disabled={!showOnlyUserVouchersLoaded}
                    />
                </ModuleHeaderSlot>
                {!listActive && voucherType !== VoucherType.Return && (
                    <VoucherDetailsOptions
                        hasMailRetoure={hasMailRetoure}
                        onRedirectToVoucherList={redirectToVoucherList}
                        voucherType={voucherType}
                        voucherId={params.id}
                    />
                )}
                <Dialog className="form-confirmation" ref={externalOrdersDialogRef} text={translateText(31)} iconName="voucher" layout="stretch">
                    <iframe className="PDF-IFrame" src={externalCatalogUrl} title="External vouchers" />
                </Dialog>
            </RowStack>
            {params.id && <ActionButtons voucherType={voucherType} />}
        </RowStack>
    )
}
