import { OE } from "@tm/models"
import { useCallback } from "react"
import { useLoadErpInfos } from "."
import { mapOePartToArticle } from "../../../../data/mapper/article"
import { ErpInfosData } from "../../models"
import { useDefaultErpSystem } from "../useDefaultErpSystem"

export type OePartErpRequest = { oePart: OE.OePart; quantity: number }

export function useLoadOePartErpInfos(erpInfosData: ErpInfosData) {
    const defaultErpSystem = useDefaultErpSystem()
    const loadErpInfos = useLoadErpInfos(erpInfosData)

    return useCallback(
        (oePartErpRequests: OePartErpRequest[]) => {
            if (!oePartErpRequests) {
                return
            }

            const erpRequestArticles = oePartErpRequests.map((oePart) => ({
                article: mapOePartToArticle(oePart.oePart, oePart.quantity),
                quantity: oePart.quantity,
            }))

            if (defaultErpSystem?.hasOeArticleSupport) {
                loadErpInfos(erpRequestArticles)
            }
        },
        [defaultErpSystem?.hasOeArticleSupport, loadErpInfos]
    )
}

export function useLoadOePositionsErpInfos(erpInfosData: ErpInfosData) {
    const loadOeParts = useLoadOePartErpInfos(erpInfosData)

    return (oePositions: OE.OePosition[]) => {
        const allOeParts: OE.OePart[] = []
        oePositions.map((op) => op.parts && allOeParts.push(...op.parts))
        oePositions.map((op) => op.replacements?.forEach((replacement) => replacement.parts && allOeParts.push(...replacement.parts)))
        loadOeParts(allOeParts.map((p) => ({ oePart: p, quantity: p.quantity || 1 })))
    }
}
