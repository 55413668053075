import { useState } from "react"
import { ArticlesContext, ArticlesProviderProps, useArticlesContext } from "../../../ContextProvider/Articles"
import { DirectSearchArticleData } from "../../../models"
import { useArticles } from "./useArticles"
import { useAfterPaintEffect } from "../../../helpers"

export function ProductGroupsArticlesProvider({ erpInfos, setHasRendered, children }: ArticlesProviderProps) {
    const [startRender, setStartRender] = useState(false)
    const articlesData = useArticles(erpInfos, startRender)

    useAfterPaintEffect(() => {
        setStartRender(true)
    }, [])

    useAfterPaintEffect(() => {
        if (articlesData.requestStatus === "success") {
            setHasRendered(true)
        }
    }, [articlesData.requestStatus])

    return <ArticlesContext.Provider value={articlesData}>{children}</ArticlesContext.Provider>
}

export function useProductGroupsArticlesContext<TContext extends DirectSearchArticleData>(): TContext
export function useProductGroupsArticlesContext<TContext extends DirectSearchArticleData, TReturnType>(
    selector: (val: TContext) => TReturnType
): TReturnType
export function useProductGroupsArticlesContext<TContext extends DirectSearchArticleData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useArticlesContext(selector as never)
}
