import { useCallback, useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { MoreMenu, MoreMenuItem } from "@tm/controls"
import { Article, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { Icon, ArticleNumber, Box, Card, CardActions, CardContent } from "@tm/components"
import { TraderArticle } from "../business/helpers"

type Props = {
    workTaskId: string
    part: TraderArticle
    advertisementCategoryId: string
    onRequestPartList(showAddToBasketMessage?: boolean): void
}

const emptyArray: Array<MoreMenuItem> = []
// eslint-disable-next-line @typescript-eslint/no-empty-function
function doNothing() {}

export function OfferItemWholesalerPart({ workTaskId, part, advertisementCategoryId, onRequestPartList }: Props) {
    const { userContext } = useUser() ?? {}
    const { renderMicro } = useMicro<IMicros>()

    const requestPartsList = useCallback(() => onRequestPartList(), [onRequestPartList])

    const article = useMemo(
        () =>
            ({
                traderArticleNo: part.traderArticleNumber,
                supplier: {},
                productGroup: {},
                id: part.id,
                requestErpInfo: true,
            } as Article),
        [part.id, part.traderArticleNumber]
    )

    const wholesalerPart = useMemo(
        () => ({
            wholesalerArticleNumber: part.traderArticleNumber,
            quantityValue: 1,
            advertisementCategoryId,
        }),
        [part.traderArticleNumber, advertisementCategoryId]
    )

    return (
        <Card key={part.id} sx={{ display: "flex", flexDirection: "column", height: "100%", width: "33.3%" }}>
            <CardContent sx={{ flex: 1 }}>
                <Box mt={0.5} height="4rem" display="flex" alignItems="center" justifyContent="center">
                    <Icon name="supplier" />
                </Box>
                <Box pt={1.5} position="relative" display="flex" justifyContent="space-between" height="5.5em">
                    {renderMicro("erp", "erp-info-prices", {
                        data: article,
                        hideRetailPrice: true,
                    })}
                    {renderMicro("erp", "erp-info-availability", {
                        data: article,
                    })}
                </Box>
                {!userContext?.parameter.hideDealerPartNumber && (
                    <Box alignItems="flex-start" height="3em">
                        <ArticleNumber articleNumber={part.traderArticleNumber} articleNumberType="wholesaler" disabled />
                    </Box>
                )}
                <Box height="3.5em" />
                <Box height="2em" />
                <Box />
                <Box>
                    {renderMicro("erp", "erp-info-additional-information", {
                        data: article,
                        maxLines: 1,
                        variant: "body2",
                    })}
                </Box>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
                {renderMicro("basket", "add-to-basket-wholesaler-part", {
                    part: wholesalerPart,
                    generatedArticleId: part.id,
                    hideQuantityField: true,
                    generatedWorktaskId: workTaskId,
                    onAddWholesalerPartToBasketFinished: requestPartsList,
                })}
                <MoreMenu items={emptyArray} onMenuItemSelect={doNothing} disabled />
            </CardActions>
        </Card>
    )
}
