import { useUser, useWorkTask } from "@tm/context-distribution"
import { ArticleNumber, Stack, styled } from "@tm/components"
import { useOpenBasketArticleDetails } from "../../hooks/useOpenBasketArticleDetails"

const ArticleNumbersStack = styled(Stack)({
    alignItems: "flex-start",
})

type Props = {
    articleNumber?: string
    isLinkedItem?: boolean
    oeArticleNumber?: string
    productGroupId?: number
    supplierArticleNumber?: string
    supplierId?: number
    wholesalerArticleNumber?: string
    workTaskId?: string // Required for the central ordering
    externalBasketItemId?: string
}

export default function ArticleNumbersComponent(props: Props) {
    const {
        supplierId,
        supplierArticleNumber,
        wholesalerArticleNumber,
        productGroupId,
        articleNumber,
        oeArticleNumber,
        isLinkedItem,
        workTaskId,
        externalBasketItemId,
    } = props
    const userContext = useUser()?.userContext
    const workTask = useWorkTask()
    const handleOpenArticleDetails = useOpenBasketArticleDetails(workTaskId ?? workTask?.workTaskId)

    if (articleNumber) {
        return (
            <ArticleNumbersStack>
                <ArticleNumber articleNumber={articleNumber} articleNumberType="custom" />
            </ArticleNumbersStack>
        )
    }

    if (oeArticleNumber) {
        return (
            <ArticleNumbersStack direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}>
                {wholesalerArticleNumber && !userContext?.parameter.hideDealerPartNumber && (
                    <ArticleNumber
                        articleNumber={wholesalerArticleNumber}
                        articleNumberType="wholesaler"
                        omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    />
                )}
                <ArticleNumber
                    articleNumber={oeArticleNumber}
                    articleNumberType="oe"
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                />
            </ArticleNumbersStack>
        )
    }

    return (
        <ArticleNumbersStack direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}>
            {wholesalerArticleNumber && !userContext?.parameter.hideDealerPartNumber && (
                <ArticleNumber
                    articleNumber={wholesalerArticleNumber}
                    articleNumberType={isLinkedItem ? "linked" : "wholesaler"}
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    onClick={() =>
                        handleOpenArticleDetails(productGroupId, supplierArticleNumber, supplierId, undefined, undefined, externalBasketItemId)
                    }
                />
            )}
            {supplierArticleNumber && (
                <ArticleNumber
                    articleNumber={supplierArticleNumber}
                    articleNumberType={isLinkedItem ? "linked" : "supplier"}
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    onClick={() =>
                        handleOpenArticleDetails(productGroupId, supplierArticleNumber, supplierId, undefined, undefined, externalBasketItemId)
                    }
                />
            )}
        </ArticleNumbersStack>
    )
}
