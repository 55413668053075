import { PropsWithChildren } from "react"
import { ListParamsContext, useListParamsContext } from "../../../ContextProvider/ListParams"
import { UniversalPartsListParams } from "../../../models"
import { useListParams } from "./useListParams"

export function UniversalPartsListParamsProvider(props: PropsWithChildren<unknown>) {
    const params = useListParams()
    return <ListParamsContext.Provider value={params}>{props.children}</ListParamsContext.Provider>
}

export function useUniversalPartsListParamsContext<TContext extends UniversalPartsListParams>(): TContext
export function useUniversalPartsListParamsContext<TContext extends UniversalPartsListParams, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function useUniversalPartsListParamsContext<TContext extends UniversalPartsListParams, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useListParamsContext(selector as never)
}
