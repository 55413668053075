import { useStyle, useUser } from '@tm/context-distribution'
import { Toolbar } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import { useActions } from '@tm/morpheus'
import { useSelector } from 'react-redux'
import { MainState } from '../../main'
import { MainActions } from '../../main/business'
import { PurchasePriceSwitch } from '../../_shared'
import MatchCodeSearch from '../../_shared/matchCodeSearch'
import { FC, useMemo } from 'react'
import { Box, Switch } from '@tm/components'


export const TopFiltersV2: FC = () => {
    const { translateText } = useLocalization()
    const user = useUser()
    const style = useMemo(() => getStyle(), [])

    const actions = useActions(MainActions, "updateSearchValue", "loadTiresList", "changeStep")
    const { searchValue } = useSelector((s: MainState) => ({ searchValue: s.list.searchValue }))

    const handleSearch = (searchValue?: string) => {
        searchValue && actions.updateSearchValue(searchValue)
        actions.loadTiresList(true)
        actions.changeStep("list", { size: searchValue, season: undefined, carType: undefined })
    }

    const handleChangeCompactView = (compactView: boolean) => {

        if (user?.userSettings) {
            user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...user.userSettings.articleListSettings,
                viewOptions: {
                    ...user.userSettings.articleListSettings?.viewOptions,
                    compactView
                }
            })
        }
    }

    const handleChangeShowArticleImages = (showArticleImages: boolean) => {
        if (user?.userSettings) {
            user.setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...user.userSettings.articleListSettings,
                viewOptions: {
                    ...user.userSettings.articleListSettings?.viewOptions,
                    showArticleImages
                }
            })
        }
    }


    return (
        <Box className={style.wrapper}>
            <Toolbar title={translateText(135)} className={style.matchcodeToolbar}>
                <MatchCodeSearch
                    value={searchValue}
                    onButtonClick={handleSearch}
                    isV2
                />
            </Toolbar>
            <Toolbar title={translateText(795)}>
                <Switch
                    checked={user?.userSettings?.articleListSettings?.viewOptions?.showArticleImages}
                    onChange={(event, checked) => handleChangeShowArticleImages(checked)}
                />
            </Toolbar>
            <PurchasePriceSwitch isV2 />
            <Toolbar className={style.compactViewToolbar} title={translateText(3009)}>
                <Switch
                    checked={user?.userSettings?.articleListSettings?.viewOptions?.compactView}
                    onChange={(event, checked) => handleChangeCompactView(checked)}
                />
            </Toolbar>
        </Box>
    )
}

function getStyle() {
    return useStyle({
        wrapper: {
            flex: 1,
            justifyContent: "unset",
            display: "flex",
        },
        compactViewToolbar: {
            $nest: {
                "&:before": {
                    borderLeft: "transparent !important"
                }
            }
        },
        matchcodeToolbar: {
            $nest: {
                ".toolbar__inner": {
                    paddingLeft: 0
                }
            }
        }
    })(TopFiltersV2)
}