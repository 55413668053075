import { LinearProgress, Stack } from "@tm/components"
import { Button, Card, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ArticleErpInfo } from "@tm/models"
import { useState } from "react"
import { CompilationShowViewModelItem } from "../../../../compilations/src/data"
import { ArticleGroupHeader } from "../list/components/ArticleGroupHeader/ArticleGroupHeader"
import { ArticleListConfigurationProvider } from "../ListV2/ArticleListConfiguration"
import { ComposerComponent, ContextComposer } from "../ListV2/ContextProvider"
import { ActiveVehicleDataProvidersProvider } from "../ListV2/ContextProvider/ActiveVehicleDataProviders"
import { ArticleListActionsProvider } from "../ListV2/ContextProvider/ArticleListActions"
import { EmptyFiltersProvider } from "../ListV2/ContextProvider/Filters"
import { EmptyListParamsProvider } from "../ListV2/ContextProvider/ListParams"
import { NotesProvider } from "../ListV2/ContextProvider/Notes"
import { PreviouslyOrderedArticlesProvider } from "../ListV2/ContextProvider/PreviouslyOrderedArticles"
import { ProductGroupRepairTimesProvider } from "../ListV2/ContextProvider/ProductGroupRepairTimes"
import { SupplierLogosProvider } from "../ListV2/ContextProvider/SupplierLogos"
import { TradeReferenceProvider } from "../ListV2/ContextProvider/TradeReferences"
import { ArticleListOptions } from "./components/ArticleListOptions"
import { CompilationArticleItem } from "./components/CompilationArticleItem"
import { CompilationArticlesProvider, useCompilationArticlesContext } from "./ContextProviders/Articles"
import { BasketQuantitiesProvider } from "./ContextProviders/BasketQuantities/BasketQuantitiesContext"
import { ListOptionsProvider } from "./ContextProviders/ListOptions/ListOptionsContext"
import { ErpInfosProvider } from "../ListV2/ContextProvider/ErpInfos"

type CompilationArticleListProps = {
    encodedWorkTaskId?: string
    compilationId: string
    parts: Array<CompilationShowViewModelItem>
    elementId?: string
}

export function CompilationArticleList(props: CompilationArticleListProps) {
    const { elementId, ...rest } = props
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [ArticleListConfigurationProvider],
        [EmptyListParamsProvider],
        [EmptyFiltersProvider],
        [CompilationArticlesProvider, { setHasRendered: setHasListRendered, erpInfos, parts: props.parts }],
        [SupplierLogosProvider, { isEnabled: hasListRendered }],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [ErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered, compilationId: props.compilationId }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: false }],
        [PreviouslyOrderedArticlesProvider, { isEnabled: hasListRendered }],
        [ListOptionsProvider],
        [ArticleListActionsProvider],
    ]
    return (
        <ContextComposer components={components}>
            <>
                <ArticleListOptions elementId={elementId} />
                <CompilationArticleListComponent {...rest} />
            </>
        </ContextComposer>
    )
}

function CompilationArticleListComponent({ compilationId, encodedWorkTaskId }: CompilationArticleListProps) {
    const { translateText } = useLocalization()

    const { isLoading, articleGroups } = useCompilationArticlesContext()

    if (isLoading) {
        return <LinearProgress />
    }

    if (!articleGroups?.length) {
        return (
            <Card className="empty-list">
                <Text>{translateText(217)}</Text>
                <div className="empty-list__dashboard-button">
                    <Button icon="dashboard" linkTo={`/${encodedWorkTaskId}`}>
                        {translateText(760)}
                    </Button>
                </div>
            </Card>
        )
    }

    return (
        <ArticleListActionsProvider>
            <Stack flex={1} padding={0.5} position="relative" className="part-list">
                {articleGroups.map((group) => {
                    const { productGroup, articles } = group
                    const title = productGroup.id === -1 || !productGroup.name ? translateText(13859) : productGroup.name
                    return (
                        <Stack key={productGroup.id}>
                            <ArticleGroupHeader title={title} productGroupId={productGroup.id} groupArticlesCount={articles.length} />
                            <Stack padding={0.5}>
                                {articles.map((article) => (
                                    <CompilationArticleItem key={article.id} article={article} compilationId={compilationId} />
                                ))}
                            </Stack>
                        </Stack>
                    )
                })}
            </Stack>
        </ArticleListActionsProvider>
    )
}
