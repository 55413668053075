import { getStoredAuthorization, ajax } from "@tm/utils"
import { ERIKContentService } from "@tm/data"
import { getBundleParams } from "../../../utils"
import { PrintModel, PrintMaintenanceResponse } from "../../models"

export function printMaintenance(data: PrintModel, fileInfo?: ERIKContentService.UploadFileInfo): Promise<PrintMaintenanceResponse> {
    const url = `${getServiceUrl()}/fastservice/PrintMaintenancePlan`
    const authorization = getStoredAuthorization()

    return ajax({ url, body: { pdfModel: data, fileInfo }, authorization, method: "POST" }).then((res) => {
        return res
    })
}

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
