import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { ArticleInfoType, VoucherType } from "@tm/models"
import { Button, Icon, Stack, Checkbox } from "@tm/components"
import { useOpenArticleDetails } from "@tm/utils"
import { VoucherPart } from "@tm/data/vouchers/costEstimations"
import { ExtendedOrderVoucherItem } from "../../../../../../data/model"
import { getBundleParams } from "../../../../../../utils"
import ReturnButton from "./Buttons/ReturnButton"
import BasketButton from "./Buttons/BasketButton"

type Props = {
    workTaskId: string
    voucherId?: string
    voucherType: VoucherType
    voucherPart?: VoucherPart
    orderedItem?: ExtendedOrderVoucherItem
    isSelected: boolean
    costEstimationVehicleId?: string
    isBigScreen: boolean
    currencyCode?: string
    onSelectPart(id: string): void
}

export default function PartActions(props: Props) {
    const { orderedItem, voucherPart, voucherType, costEstimationVehicleId, voucherId, isSelected, isBigScreen, currencyCode, workTaskId } = props
    const { partsDetailRoute } = getBundleParams()

    const { translateText } = useLocalization()

    const handleOpenArticleDetails = useOpenArticleDetails({
        productGroupId:
            voucherType === VoucherType.CostEstimation ? voucherPart?.articleInformation.productGroupId : orderedItem?.articleInfo.productGroup?.id,
        supplierId:
            voucherType === VoucherType.CostEstimation ? voucherPart?.articleInformation.supplierId : orderedItem?.articleInfo.dataSupplier?.id,
        supplierArticleNumber:
            voucherType === VoucherType.CostEstimation
                ? voucherPart?.articleInformation.articleNumber
                : orderedItem?.articleInfo.dataSupplierArticleNumber,
        partsDetailsUrl: partsDetailRoute,
        openModal: Morpheus.showView,
    })

    function getIsArticleOrderable(): boolean {
        if (
            voucherType === VoucherType.CostEstimation &&
            voucherPart &&
            voucherPart.articleInformation.articleInfoType !== ArticleInfoType.CustomArticle &&
            !voucherPart.mainPartItemId
        ) {
            return true
        }
        if (
            voucherType === VoucherType.Order &&
            orderedItem &&
            orderedItem.articleInfoType !== ArticleInfoType.CustomArticle &&
            !orderedItem.isLinkedItem
        ) {
            return true
        }
        return false
    }

    function getArticleInfoType(): ArticleInfoType {
        if (voucherType === VoucherType.CostEstimation && voucherPart) {
            return voucherPart.articleInformation.articleInfoType
        }
        if (voucherType === VoucherType.Order && orderedItem) {
            return orderedItem.articleInfoType
        }
        return ArticleInfoType.Undefined
    }

    const isArticleOrderable = getIsArticleOrderable()
    const articleInfoType = getArticleInfoType()

    function hasArticleDetails(): boolean {
        if (voucherType === VoucherType.CostEstimation && voucherPart && !voucherPart.mainPartItemId) {
            return !(
                voucherPart.articleInformation.articleInfoType === ArticleInfoType.OeArticle ||
                voucherPart.articleInformation.articleInfoType === ArticleInfoType.CustomArticle
            )
        }

        if (orderedItem) {
            return !(orderedItem.articleInfoType === ArticleInfoType.CustomArticle || orderedItem.articleInfoType === ArticleInfoType.OeArticle)
        }

        return false
    }

    function handlePartCheck() {
        if (voucherType === VoucherType.CostEstimation && voucherPart) {
            props.onSelectPart(voucherPart.partItemId)
        }

        if (orderedItem) {
            props.onSelectPart(orderedItem.id)
        }
    }

    return (
        <Stack direction="row" gap={0.5}>
            <BasketButton
                workTaskId={workTaskId}
                disabled={!isArticleOrderable}
                voucherType={voucherType}
                costEstimationVehicleId={costEstimationVehicleId}
                currencyCode={currencyCode}
                orderedItems={orderedItem}
                voucherParts={voucherPart}
            />
            <ReturnButton voucherType={voucherType} orderedItem={orderedItem} voucherId={voucherId} />
            <Button
                onClick={() => handleOpenArticleDetails()}
                disabled={!hasArticleDetails() || articleInfoType !== ArticleInfoType.TecdocArticle}
                startIcon={!isBigScreen ? <Icon name="details" /> : undefined}
            >
                {isBigScreen && translateText(43)}
            </Button>
            <Checkbox
                // Currently the add-to-basket micro only supports a list of a single artcileInfoType
                disabled={articleInfoType !== ArticleInfoType.TecdocArticle}
                checked={isSelected}
                onChange={handlePartCheck}
            />
        </Stack>
    )
}
