import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."

export type NavigationSlice = {
    navigation: {
        active?: string
        initialized?: boolean
    }
}

export function getInitialState(): NavigationSlice {
    return {
        navigation: {
            active: "start",
        },
    }
}

export const createNavigationSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], NavigationSlice> = (set) => ({
    ...getInitialState(),
})
