import { fastServiceStore } from "../.."
import { getInitialState } from "."

export function resetStepsNavigation() {
    fastServiceStore.setState(getInitialState(), false, "Reset Step Navigation Slice")
}

export function updateNextStep(currentStepName: string) {
    const state = fastServiceStore.getState().stepNavigationState
    const activeSteps = state.steps.findIndex((x) => x.stepName === currentStepName) + 1
    return updateSteps(activeSteps)
}

export function updateSteps(activeSteps: number) {
    fastServiceStore.setState(
        (state) => ({
            stepNavigationState: {
                steps: state.stepNavigationState.steps.map((x, i) => (i <= activeSteps ? { ...x, available: true } : { ...x })),
            },
        }),
        false,
        "Update step"
    )
}

export function completeStep(stepName: string) {
    fastServiceStore.setState(
        (state) => ({
            stepNavigationState: {
                steps: state.stepNavigationState.steps.map((x) => (x.stepName === stepName ? { ...x, completed: true } : { ...x })),
            },
        }),
        false,
        "Complete step"
    )
}

export function resetCompleteStep() {
    fastServiceStore.setState(
        (state) => ({
            stepNavigationState: {
                steps: state.stepNavigationState.steps.map((x, i) => (i < 1 ? { ...x } : { ...x, completed: false })),
            },
        }),
        false,
        "Reset complete step"
    )
}

export function updateWorksSteps(steps: string[]) {
    fastServiceStore.setState(
        (state) => ({
            stepNavigationState: {
                steps: state.stepNavigationState.steps.filter((step) => (step.specialStep ? steps?.includes(step.stepName) : true)),
            },
        }),
        false,
        "Update works steps"
    )
}
