import { FC } from "react"
import Morpheus from "@tm/morpheus"
import { Button } from "@tm/controls"
import { withRouter, RouteComponentProps } from "@tm/utils"
import { useUser, useWorkTask, useCostEstimationModuleParameter } from "@tm/context-distribution"
import { channel } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { FastCalculator } from "@tm/data"
import {
    getButtonIcon,
    getButtonSkin,
    getButtonLayout,
    getLabourRate,
    saveInputsToWorkTask,
    isInitialRegistrationChanged,
    getButtonLabel,
} from "../../data/helpers"
import { createMemo } from "../main/business/helper"
import { useBasketImports } from "../../../../basket/src/hooks/basketState/useBasketImports"

import { useFastCalculatorStore, useStoreContext } from "../../state"
import { handleButtonClick } from "../../business"

type Props = RouteComponentProps<{}> & {
    item: FastCalculator.CalcStateButton
    isActive?: boolean
    isDisabled?: boolean
    isOverlayCalcState?: boolean
    isTab?: boolean
    inModal?: boolean
    isFromWidget?: boolean
    changeFCRoute?: () => void
    onClick?: () => void
}

const CalcStateButtonComponent: FC<Props> = ({
    isActive,
    isDisabled,
    item,
    isTab,
    history,
    inModal,
    isFromWidget,
    changeFCRoute,
    isOverlayCalcState,
    onClick,
}) => {
    const instance = useStoreContext()
    const userSettings = useUser()?.userSettings
    const { workTask, attachToWorkTask } = useWorkTask() || {}
    const { languageId } = useLocalization()

    const { widgetContext, costEstimationUrl, selectedServices } = useFastCalculatorStore((state) => ({
        widgetContext: state.selectedCalcState?.context,
        costEstimationUrl: state.costEstimationUrl,
        selectedServices: state.selectedServices,
    }))

    const { importFastCalculation } = useBasketImports()

    const { hasCostEstimation } = useCostEstimationModuleParameter()

    const handleShowCostEstimation = () => {
        if (inModal) {
            Morpheus.closeView("1")

            channel("APP").publish("TOAST_MESSAGE/SHOW", {
                skin: "success",
                message: "Es wurde ein Kostenvoranschlag anhand der Kalkulation erstellt.", // TODO: localize
            })
        } else if (costEstimationUrl) {
            history.push(costEstimationUrl)
        }
    }

    const handleClick = () => {
        onClick?.()
        let memo

        if (item.type === FastCalculator.ECalcButtonState.CreateQuote && workTask) {
            memo = userSettings?.orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo ? createMemo(workTask) : undefined
        }

        handleButtonClick(
            instance,
            item,
            languageId,
            importFastCalculation,
            getLabourRate(userSettings),
            workTask,
            handleShowCostEstimation,
            isOverlayCalcState,
            changeFCRoute,
            memo
        )

        const initialRegistration = widgetContext?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.RegDate)?.value as Date
        const engineCode = widgetContext?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.EngineCode)?.value as string
        const mileage = widgetContext?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.Mileage)?.value as number
        const longLife = widgetContext?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.Longlife)?.value

        const initialRegistrationChanged =
            workTask?.vehicle?.initialRegistration && isInitialRegistrationChanged(workTask.vehicle.initialRegistration, initialRegistration)
        const isVehicleUpdated =
            workTask?.vehicle?.engineCode != engineCode ||
            initialRegistrationChanged ||
            workTask?.vehicle?.mileAge != mileage ||
            workTask?.vehicle?.longlife != longLife

        if (item.type === FastCalculator.ECalcButtonState.StartCalculation && isVehicleUpdated) {
            saveInputsToWorkTask(initialRegistration, engineCode, mileage, longLife, workTask?.vehicle, attachToWorkTask)
        }

        if (item.type === FastCalculator.ECalcButtonState.CreateQuote || item.type === FastCalculator.ECalcButtonState.SaveCalculation) {
            channel("WORKTASK", workTask?.id).publish("FAST-CALCULATOR/CALCULATION_DONE", { isFastCalcDone: true })
        }
    }

    if (!item) {
        return null
    }

    if (item.type === FastCalculator.ECalcButtonState.CreateQuote && !hasCostEstimation) {
        return null
    }

    return (
        <Button
            onClick={handleClick}
            layout={getButtonLayout(item.style, isTab)}
            skin={isActive ? "primary" : getButtonSkin(item.style, item)}
            size="m"
            disabled={
                isDisabled ||
                (!isFromWidget && item.type === FastCalculator.ECalcButtonState.StartCalculation ? !selectedServices?.length : item.isDisabled)
            }
            icon={getButtonIcon(item)}
            isActive={isActive}
        >
            {getButtonLabel(item)}
        </Button>
    )
}

export default withRouter(CalcStateButtonComponent)
