import { useMemo } from "react"
import { useListParamsContext } from "../../ContextProvider"

export function useListType() {
    const listSubType = useListParamsContext((context) => context.startParams.type)
    return useMemo(() => {
        switch (listSubType) {
            case "node":
            case "productgroups":
            case "synonym":
                return "vehicle"
            case "uninode":
            case "unisearch":
                return "uni"
            default:
                return listSubType
        }
    }, [listSubType])
}
