import Morpheus from "@tm/morpheus"
import { getVehicleLookupType } from "@tm/context-distribution"
import { CarLookupError, channel, RegistrationNoType, UserContext, VehicleType } from "@tm/models"
import { getRegistrationNumberTypeTextIds } from "@tm/utils"
import { useCallback, useEffect, useRef, useState } from "react"
import { getBundleParams } from "./utils"

export enum LanguageFlag {
    "German" = 1,
    "English" = 4,
    "France" = 6,
}

const getRegistrationTypeId = (values: any[]) => {
    const registrationNoType = values.find((item) => item.key === "IDSearch" || item.key === "VehicleLookupType")

    if (registrationNoType) {
        return parseFloat(registrationNoType.value)
    }
}
// TODO: Check if UserContext is fixed, otherwise research and fix it
// model UserContext has an externalmodultype which has huge differences to the ekeys from authority service (ekey is used to define the externalmoduletypes)
const getVehicleLookupTypeFromExternalModules = (userContext: any) => {
    if (!userContext.externalModules || userContext.externalModules.length === 0) {
        return undefined
    }

    const eModules = userContext.externalModules.find((item: any) => item.type === 3 && item.parameter && item.parameter.length > 0)
    const lookupTypeId = eModules && eModules.parameter ? getRegistrationTypeId(eModules.parameter) : undefined
    return lookupTypeId
}

export const getLookupType = (userContext: UserContext | undefined) => {
    if (!userContext) {
        return
    }

    let vehicleLookupType: number | undefined
    vehicleLookupType = getVehicleLookupTypeFromExternalModules(userContext)

    if (!vehicleLookupType) {
        vehicleLookupType = getVehicleLookupType(userContext)
    }

    return vehicleLookupType
}

export enum VrmLookupErrorTextIds {
    UnknownError = 1261,
    UserNotRegistered = 1354,
    NoResultForVin = 1262,
    NoResult = 1405,
    ManufacturerNotSupported = 1263,
    ContingentLimitReached = 1524,
}

export const UnknownVinTextId = 1881

export function getDATErrorMessageTextId(error: CarLookupError): VrmLookupErrorTextIds {
    // parse exception from V4 or external service
    if (error.errorMessage) {
        if (error.errorMessage.includes("DAT User not registered")) {
            return VrmLookupErrorTextIds.UserNotRegistered
        }
        if (
            error.errorMessage.includes("manufacturer not supported") ||
            /VIN requests for manufacturers.*are not permitted/.test(error.errorMessage)
        ) {
            return VrmLookupErrorTextIds.ManufacturerNotSupported
        }
        if (
            error.errorMessage.includes("Fahrgestellnummer ist unbekannt") ||
            error.errorMessage.includes("no result") ||
            error.errorMessage.includes("unknown vin")
        ) {
            return VrmLookupErrorTextIds.NoResultForVin
        }
        if (error.errorMessage.includes("No module calls left")) {
            return VrmLookupErrorTextIds.ContingentLimitReached
        }
    }

    return VrmLookupErrorTextIds.UnknownError
}

export function getAutoIDatErrorMessageTextId(error: CarLookupError): VrmLookupErrorTextIds {
    // parse exception from V4 or external service
    if (error.errorMessage?.includes("Keine Daten")) {
        return VrmLookupErrorTextIds.NoResult
    }

    return VrmLookupErrorTextIds.UnknownError
}

export function handleDATRegistration(registrationRoute: string, rejectOnModalClose?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
        // Show registration page in modal
        Morpheus.showView("1", registrationRoute)

        const handlePostMessage = (e: { data?: { setDATUserCredentials?: { error?: boolean } } }) => {
            // Skip unrelevant incoming post messages
            if (!e.data || !e.data.setDATUserCredentials) {
                window.addEventListener("message", handlePostMessage, { once: true })
                return
            }

            // Hide the registration page modal
            Morpheus.closeView("1")

            if (e.data.setDATUserCredentials.error) {
                // If an error occured show info text
                reject()
            } else {
                // Otherwise retry the search
                resolve()
            }
        }

        // Add event listener and wait for any post message sent by the registration page
        window.addEventListener("message", handlePostMessage, { once: true })

        // Remove event listener when modal was closed by user
        channel("GLOBAL").subscribeOnce("MODAL/CLOSED", () => {
            window.removeEventListener("message", handlePostMessage)

            if (rejectOnModalClose) {
                reject()
            }
        })
    })
}

export function publishModuleInfo(workTaskId: string, view: string, info: string) {
    channel("WORKTASK", workTaskId).publish("MODULE/CHANGED", { id: "vehicle", name: "{{99}}", view, info })
}

type VehicleSearchTexts = {
    placeholder?: string
    tooltip?: string
}

function getVehicleSearchTexts(
    regNoTypes: Array<RegistrationNoType>,
    vehicleType: VehicleType,
    translate: (id: number) => string
): Array<VehicleSearchTexts> {
    return regNoTypes // for each vehicle search
        .map((type) => getRegistrationNumberTypeTextIds(type, vehicleType)) // get the according text ids
        .filter((x) => x.placeholder || x.tooltip) // filter out entries without placeholder or label
        .map((x) => {
            const mapped: VehicleSearchTexts = {}

            if (x.placeholder !== undefined) {
                mapped.placeholder = translate(x.placeholder)

                if (x.exampleQuery !== undefined) {
                    mapped.placeholder = mapped.placeholder.replace("{0}", x.exampleQuery)
                }
            }

            if (x.tooltip !== undefined) {
                mapped.tooltip = translate(x.tooltip)
            }

            return mapped
        })
}

export const enum searchType {
    CONTROL_PLATE = "control_plate",
    VEHICLE_TYPE = "vehicle_type",
}

export function getVehicleSearchTooltip(regNoTypes: Array<RegistrationNoType>, translateText: (id: number) => string, searchBy: searchType): string {
    if (getBundleParams().showAdaptedVehicleSearchTooltip) {
        switch (searchBy) {
            case searchType.CONTROL_PLATE:
                return translateText(1918)
            case searchType.VEHICLE_TYPE:
                return translateText(1919)
            default:
                break
        }
    }

    let tooltip = translateText(1220)

    const texts = getVehicleSearchTexts(regNoTypes, VehicleType.PassengerCar, translateText)

    texts.forEach((x, idx) => {
        if (x.tooltip && texts.findIndex((y) => x.tooltip === y.tooltip) === idx) {
            tooltip += `, ${x.tooltip}`
        }
    })

    return tooltip
}
export function getBikeSearchTooltip(regNoTypes: Array<RegistrationNoType>, translateText: (id: number) => string): string {
    let tooltip = translateText(1896)
    const texts = getVehicleSearchTexts(
        regNoTypes.filter((type) => type === RegistrationNoType.Kba),
        VehicleType.Motorcycle,
        translateText
    )
    const mapped: Array<string> = []

    texts.forEach((x, idx) => {
        if (x.tooltip && texts.findIndex((y) => x.tooltip === y.tooltip) === idx) {
            mapped.push(x.tooltip)
        }
    })

    if (mapped.length) {
        tooltip += ` ${translateText(1916)} ${mapped.join(", ")}`
    }

    return tooltip
}
export function getCommercialSearchTooltip(regNoTypes: Array<RegistrationNoType>, translateText: (id: number) => string): string {
    const tooltip = translateText(1902)

    // no KBA Number for Commercial Vehicle?

    // const texts = getVehicleSearchTexts(regNoTypes, translateText)

    // texts.forEach((x, idx) => {
    //     if (x.tooltip && texts.findIndex(y => x.tooltip === y.tooltip) === idx)
    //         tooltip += ", " + x.tooltip
    // })

    return tooltip
}
export function getVehicleSearchPlaceholder(regNoTypes: Array<RegistrationNoType>, translateText: (id: number) => string): string {
    let placeholder = translateText(129)
    const texts = getVehicleSearchTexts(regNoTypes, VehicleType.PassengerCar, translateText)

    texts.forEach((x, idx) => {
        if (x.placeholder && texts.findIndex((y) => x.placeholder === y.placeholder) === idx) {
            placeholder += ` / ${x.placeholder}`
        }
    })

    return placeholder
}

export function getBikeSearchPlaceholder(regNoTypes: Array<RegistrationNoType>, translateText: (id: number) => string): string {
    let placeholder = translateText(1892)
    const texts = getVehicleSearchTexts(
        regNoTypes.filter((type) => type === RegistrationNoType.Kba),
        VehicleType.Motorcycle,
        translateText
    )

    texts.forEach((x, idx) => {
        if (x.placeholder && texts.findIndex((y) => x.placeholder === y.placeholder) === idx) {
            placeholder += ` / ${x.placeholder}`
        }
    })

    return placeholder
}

export function getCommercialSearchPlaceholder(regNoTypes: Array<RegistrationNoType>, translateText: (id: number) => string): string {
    const placeholder = translateText(1893)

    // Not needed for NKW?

    // const texts = getVehicleSearchTexts(regNoTypes, translateText)

    // texts.forEach((x, idx) => {
    //     if (x.placeholder && texts.findIndex(y => x.placeholder === y.placeholder) === idx)
    //         placeholder += " / " + x.placeholder
    // })

    return placeholder
}

export function onlyDigits(value: string) {
    return value.replace(/[\D]/g, "")
}

export function uppercase(value: string) {
    return value.toUpperCase()
}

export function kba(value: string) {
    return value.toUpperCase().replace(/[^A-Z0-9 ]/g, "")
}

export function engineCode(value: string): string {
    return value.toUpperCase().replace(/[^A-Z0-9 -]/g, "")
}

/** @todo MLE 09.04.21 - Fix RegNo info missing if vehicle regNoType is some special one */
// THIS IS HACKY AND SHOULD BE REMOVED AS SOON AS THE VEHICLE IS EXTENDED TO STORE DATSOURCEID AND REGISTRATIONNOTYPE SIMOULTANEOUSLY.
export function isSpecialRegNoType(regNoType: RegistrationNoType | undefined): boolean {
    return (
        regNoType === RegistrationNoType.VehicleBase ||
        regNoType === RegistrationNoType.KeywordSearch ||
        regNoType === RegistrationNoType.RückwFahrzeugVkn ||
        regNoType === RegistrationNoType.KTypNr ||
        regNoType === RegistrationNoType.Motorcode ||
        regNoType === RegistrationNoType.Fahrzeugbaum ||
        regNoType === RegistrationNoType.DatVin ||
        regNoType === RegistrationNoType.TopmotiveVin ||
        regNoType === RegistrationNoType.VrmAAA ||
        regNoType === RegistrationNoType.TopmotiveTypeId
    )
}

// https://gist.github.com/DominicTobias/c8579667e8a8bd7817c1b4d5b274eb4c
export default function useResizeObserver<T extends HTMLElement>() {
    const [size, setSize] = useState({ width: 0, height: 0 })
    const [element, setElement] = useState<T>()
    const resizeObserver = useRef<ResizeObserver>(null)

    const onResize = useCallback((entries) => {
        const { width, height } = entries[0].contentRect
        setElement(entries[0])
        setSize({ width, height })
    }, [])

    const ref = useCallback(
        (node) => {
            if (node !== null) {
                if (resizeObserver.current) {
                    resizeObserver.current.disconnect()
                }
                // @ts-ignore
                resizeObserver.current = new ResizeObserver(onResize)
                resizeObserver.current.observe(node)
            }
        },
        [onResize]
    )

    useEffect(
        () => () => {
            resizeObserver.current?.disconnect()
        },
        []
    )

    return { ref, element, width: size.width, height: size.height }
}
