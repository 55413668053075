import { Checkbox, Radio, Text, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { getCurrencyFromUserContext, getValue, initSelector } from "@tm/utils"
import * as React from "react"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { getBundleParams } from "../../../../utils"
import { Actions } from "../../bussiness"
import { hasFieldError } from "../../bussiness/helpers"
import { DocumentsState } from "../../bussiness/model"
import { documentsIdsSelector, fieldsSelector, modelStateSelector } from "../../bussiness/selectors"
import { Field } from "../common"

type Props = {}

const selector = initSelector(fieldsSelector, documentsIdsSelector)

const DocumentCompliantTab: React.FC<Props> = () => {
    const actions = useActions(Actions, "updateField")
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const currencyCode = useMemo(() => getCurrencyFromUserContext(userContext), [userContext])

    const [model] = useSelector(selector)
    if (!model) {
        return null
    }
    const modelState = useSelector((s: DocumentsState) => modelStateSelector(s, translateText))
    const { complainInfo } = model

    const handleCheckboxChange = (path: Array<string>, value: any, defaultValue?: any) => {
        if (getValue(model, path) == value && defaultValue != undefined) {
            value = defaultValue
        }

        actions.updateField(path, value)
    }

    const handlePriceChange = (path: Array<string>, newValue: string) => {
        const stringValue = newValue || ""
        const value = stringValue.replace(",", ".")
        actions.updateField(path, value)
    }
    return (
        <>
            <Field.Container>
                <Field.Item m={12} mandatory={modelState["complainInfo.claimDescription"]} title={translateText(1371)}>
                    <TextField
                        multiline
                        showClear
                        value={model.complainInfo.claimDescription}
                        className={hasFieldError(modelState["complainInfo.claimDescription"])}
                        onChange={actions.updateField.bind(this, ["complainInfo", "claimDescription"])}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={4} mandatory title={translateText(1372)}>
                    <Radio
                        size="s"
                        label={translateText(1373)}
                        checked={model.complainInfo.errorOccurance == 1}
                        onCheck={handleCheckboxChange.bind(this, ["complainInfo", "errorOccurance"], 1, 0)}
                    />
                    <Radio
                        size="s"
                        label={translateText(1374)}
                        checked={model.complainInfo.errorOccurance == 2}
                        onCheck={handleCheckboxChange.bind(this, ["complainInfo", "errorOccurance"], 2, 0)}
                    />
                    <Radio
                        size="s"
                        label={translateText(1375)}
                        checked={model.complainInfo.errorOccurance == 3}
                        onCheck={handleCheckboxChange.bind(this, ["complainInfo", "errorOccurance"], 3, 0)}
                    />

                    <Radio
                        size="s"
                        label={translateText(1441)}
                        checked={model.complainInfo.errorOccurance == 4}
                        onCheck={handleCheckboxChange.bind(this, ["complainInfo", "errorOccurance"], 4, 0)}
                    />
                    <Radio
                        size="s"
                        label={translateText(1376)}
                        checked={model.complainInfo.errorOccurance == 5}
                        onCheck={handleCheckboxChange.bind(this, ["complainInfo", "errorOccurance"], 5, 0)}
                    />

                    {complainInfo.errorOccurance == 5 && (
                        <TextField
                            multiline
                            showClear
                            value={model.complainInfo.errorOccuranceDescription}
                            onChange={actions.updateField.bind(this, ["complainInfo", "errorOccuranceDescription"])}
                        />
                    )}
                </Field.Item>
                <Field.Item m={4} title={translateText(1414)}>
                    {getBundleParams().formulars?.gvaOnlyCreditHandling ? (
                        <>
                            <Checkbox
                                size="s"
                                label={translateText(1378)}
                                checked={model.complainInfo.completionAtAcknowledgement == 1}
                                onToggle={handleCheckboxChange.bind(this, ["complainInfo", "completionAtAcknowledgement"], 1, 0)}
                            />
                        </>
                    ) : (
                        <>
                            <Radio
                                size="s"
                                label={translateText(1378)}
                                checked={model.complainInfo.completionAtAcknowledgement == 1}
                                onCheck={handleCheckboxChange.bind(this, ["complainInfo", "completionAtAcknowledgement"], 1, 0)}
                            />
                            <Radio
                                size="s"
                                label={translateText(1725)}
                                checked={model.complainInfo.completionAtAcknowledgement == 2}
                                onCheck={handleCheckboxChange.bind(this, ["complainInfo", "completionAtAcknowledgement"], 2, 0)}
                            />
                            <Radio
                                size="s"
                                label={translateText(688)}
                                checked={model.complainInfo.completionAtAcknowledgement == 3}
                                onCheck={handleCheckboxChange.bind(this, ["complainInfo", "completionAtAcknowledgement"], 3, 0)}
                            />
                        </>
                    )}
                </Field.Item>

                <Field.Item m={4} title={translateText(1377)}>
                    <Radio
                        size="s"
                        label={translateText(1382)}
                        checked={model.complainInfo.completionAtRejection == 4}
                        onCheck={handleCheckboxChange.bind(this, ["complainInfo", "completionAtRejection"], 4, 0)}
                    />
                    <Radio
                        size="s"
                        label={translateText(1380)}
                        checked={model.complainInfo.completionAtRejection == 5}
                        onCheck={handleCheckboxChange.bind(this, ["complainInfo", "completionAtRejection"], 5, 0)}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={12} title={translateText(1391)}>
                    <Checkbox
                        label={translateText(1383)}
                        checked={complainInfo.isAdditionalCosts == 1}
                        onToggle={handleCheckboxChange.bind(this, ["complainInfo", "isAdditionalCosts"], 1, 0)}
                    />
                </Field.Item>
            </Field.Container>

            {complainInfo.isAdditionalCosts == 1 && (
                <>
                    <Field.Container>
                        <Field.Item m={4}>
                            <TextField
                                placeholder={currencyCode || "EUR"}
                                formatter={(value) => value || ""}
                                value={model.complainInfo.additionalCosts}
                                pattern={/\d{0,5}(?:[\.,]\d{0,2})?/}
                                onChange={handlePriceChange.bind(this, ["complainInfo", "additionalCosts"])}
                            />
                        </Field.Item>
                        <Field.Item m={8}>
                            <Text>{translateText(1384)} </Text>
                        </Field.Item>
                    </Field.Container>

                    <Field.Container>
                        <Field.Item m={12} title={translateText(25)}>
                            <TextField
                                multiline
                                showClear
                                value={model.complainInfo.additionalCostsDecription}
                                onChange={actions.updateField.bind(this, ["complainInfo", "additionalCostsDecription"])}
                            />
                        </Field.Item>
                    </Field.Container>
                </>
            )}
        </>
    )
}

export default DocumentCompliantTab
