import { QueryFunctionContext, useQuery } from "react-query"
import { uniqueId } from "@tm/utils"
import { OE } from "@tm/models"
import { useMemo } from "react"
import { DirectSearchListParams, OensArticleData } from "../../../models"
import { OensArticle } from "../../../../../data/model"
import * as PartsRepository from "../../../../../data/repositories"

const QUERY_KEY = "OensArticles"

type QueryKey = [string, string]

export function useOensArticles(params: DirectSearchListParams, isEnabled: boolean): OensArticleData {
    const oensArticleQuery = useQuery({
        enabled: isEnabled && !!params.startParams.query,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryKey: [QUERY_KEY, params.startParams.query!],
        queryFn: getOensArticles,
    })

    const oeParts: OE.OePart[] | undefined = useMemo(
        () =>
            oensArticleQuery.data?.map<OE.OePart>((article) => ({
                id: uniqueId(),
                number: article.number,
                traderNumber: article.wholesalerArticleNumber,
                description: article.description ?? article.descriptionShort ?? "",
                manufacturerId: article.manufacturer.id,
                manufacturerName: article.manufacturer.name,
                thumbnailUrl: article.manufacturer.thumbnail,
                prices: article.price ? [{ currency: article.currencyCode, value: article.price }] : undefined,
                oeArticleOrigin: {
                    module: OE.OeArticleModule.Undefined,
                    provider: OE.OeArticleProvider.Topmotive,
                },
            })),
        [oensArticleQuery.data]
    )

    return { isLoading: oensArticleQuery.isLoading, oeParts }
}

function getOensArticles({ queryKey: [, request] }: QueryFunctionContext<QueryKey>): Promise<OensArticle[]> {
    return PartsRepository.getOensArticles(request)
}
