import { Article } from "@tm/models"
import { Part } from "../../../../data/models"

export const mapPart = (part: Article): Part => {
    return {
        dataSupplierId: part.supplier.id,
        dataSupplierName: part.supplier.name,
        dataSupplierArticleNumber: part.supplierArticleNo,
        wholesalerArticleNumber: part.traderArticleNo,
        productGroupId: part.productGroup.id,
        productGroupName: part.productGroup.name,
        quantityValue: part.quantity,
        article: {
            id: part.internalId,
            supplierArticleNo: part.supplierArticleNo,
            traderArticleNo: part.traderArticleNo,
            traderDescription: part.traderDescription,
            information: part.information,
            attributes: part.attributes,
            references: part.references,
            supplier: part.supplier,
            productGroup: part.productGroup,
            status: part.status,
            packaging: part.packaging,
            isAccessory: part.isAccessory,
            isPartsListAvailable: part.isPartsListAvailable,
            isAccessoryListAvailable: part.isAccessoryListAvailable,
            thumbnail: part.thumbnail,
            existImage: part.existImage,
        },
    }
}
