import { Stack } from "@tm/components"
import { FC, useState } from "react"
import { ArticleErpInfo, GetArticleListByMatchCodeRequest } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { ArticleListConfigurationProvider } from "../ListV2/ArticleListConfiguration"
import { ArticleListActionsProvider } from "../ListV2/ContextProvider/ArticleListActions"
import { PureArticleSelection } from "../PureList/PureArticleSelection"
import { TyresArticleItem } from "./TyresArticleItem"
import { TyresError } from "../../../../tyres/src/components/list/components"
import { ArticleGroupHeader } from "../list/components/ArticleGroupHeader/ArticleGroupHeader"
import { NextPageLoader } from "../ListV2/components/NextPageLoader"
import { ComposerComponent, ContextComposer } from "../ListV2/ContextProvider"
import { BasketQuantitiesProvider } from "../ListV2/ContextProvider/BasketQuantities"
import { ListOptionsProvider } from "../ListV2/ContextProvider/ListOptions"
import { ErpInfosProvider } from "../ListV2/ContextProvider/ErpInfos"
import { NotesProvider } from "../ListV2/ContextProvider/Notes"
import { TyresListProvider, useTyresListContext } from "./ContextProvider/TyresList"
import { EmptyListParamsProvider } from "../ListV2/ContextProvider/ListParams"
import { EmptyFiltersProvider } from "../ListV2/ContextProvider/Filters"
import { TradeReferenceProvider } from "../ListV2/ContextProvider/TradeReferences"
import { PreviouslyOrderedArticlesProvider } from "../ListV2/ContextProvider/PreviouslyOrderedArticles"
import { ActiveVehicleDataProvidersProvider } from "../ListV2/ContextProvider/ActiveVehicleDataProviders"
import { ProductGroupRepairTimesProvider } from "../ListV2/ContextProvider/ProductGroupRepairTimes"
import { AvailabilityFilterType } from "../../business"

export type TyreListProps = {
    request: GetArticleListByMatchCodeRequest
    selectedAvailability: AvailabilityFilterType
    handleAutoNextPage(): void
}

export const TyresList: FC<TyreListProps> = ({ request, selectedAvailability, handleAutoNextPage }: TyreListProps) => {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [ArticleListConfigurationProvider],
        [ListOptionsProvider, { isEnabled: true }],
        [EmptyListParamsProvider],
        [EmptyFiltersProvider],
        [
            TyresListProvider,
            {
                setHasRendered: setHasListRendered,
                request,
                selectedAvailability,
                handleAutoNextPage,
                erpInfos,
            },
        ],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [ErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [PreviouslyOrderedArticlesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: false }],
        [ArticleListActionsProvider],
    ]

    return (
        <ContextComposer components={components}>
            <TyresListComponent requestAvailable={!!request} />
        </ContextComposer>
    )
}

type TyresListComponentProps = {
    requestAvailable: boolean
}

function TyresListComponent({ requestAvailable }: TyresListComponentProps) {
    const { translateText } = useLocalization()

    const tyreArticles = useTyresListContext((context) => context.displayArticles)
    const showInitialLoading = useTyresListContext((tyresListContext) => tyresListContext.isLoading)

    if (!requestAvailable) {
        return null
    }

    if (showInitialLoading) {
        return <NextPageLoader skeletonArticleCount={5} />
    }

    if (!tyreArticles?.length) {
        return <TyresError />
    }

    return (
        <Stack flex={1} position="relative">
            <ArticleGroupHeader title={translateText(240)} groupArticlesCount={tyreArticles?.length}>
                <PureArticleSelection showArticleComparision sx={{ mt: "2em", mr: "2em" }} />
            </ArticleGroupHeader>
            <Stack padding={0.5}>
                {tyreArticles?.map((article) => (
                    <TyresArticleItem key={article.internalId} article={article} />
                ))}
            </Stack>
        </Stack>
    )
}
