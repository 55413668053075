import { StepNames } from "../data/models"

export const getTranslationByLocation = (stepName: string): string => {
    switch (stepName) {
        case StepNames.EngineCompartment:
            return "12665"
        case StepNames.UnderVehicle:
            return "12668"
        case StepNames.Interior:
            return "12666"
        case StepNames.Exterior:
            return "12667"
        case StepNames.General:
            return "380"
        case StepNames.CheckWork:
            return "12674"
        case StepNames.TestDrive:
            return "12673"
        case StepNames.TiresWheels:
            return "13062"
        default:
            return ""
    }
}

export const getTranslationByKey = (stepName: string): string => {
    switch (stepName) {
        case "12665":
            return StepNames.EngineCompartment
        case "12668":
            return StepNames.UnderVehicle
        case "12666":
            return StepNames.Interior
        case "12667":
            return StepNames.Exterior
        case "380":
            return StepNames.General
        case "12674":
            return StepNames.CheckWork
        case "12673":
            return StepNames.TestDrive
        case "13062":
            return StepNames.TiresWheels
        default:
            return ""
    }
}
