import { useCallback, useMemo, useState } from "react"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { fastServiceStore, imagesActions, useFastServiceStore } from "../data"
import { useUploadFile } from "./useUploadFile"
import { dataURLtoFile } from "../helpers"
import { useFileDelete } from "./useFileDelete"

export function useWorkImages(step: string, workLabel: string) {
    const { translateText } = useLocalization()
    const [errorMessage, setErrorMessage] = useState("")
    const imageList = useFastServiceStore((state) => state.imagesState.images)
    const images = useMemo(() => imageList[step || ""]?.[workLabel] || [], [step, workLabel, imageList])
    const { uploadFile, isLoading } = useUploadFile()
    const { deleteFile, isLoading: fileDeleteLoading } = useFileDelete()
    const { userContext } = useUser()

    const savePicture = useCallback(
        async (imageData: string) => {
            const { stateVehicle } = fastServiceStore.getState().maintenancePlan

            const fileName = `${"FastService"}_${step}_${stateVehicle?.vin}`
            const imageBuffer = dataURLtoFile(imageData, `${fileName}.jpeg`)

            if (imageBuffer) {
                try {
                    const uploadedFile = await uploadFile({
                        file: imageBuffer,
                        category: "maintenanceprotocol",
                        stepName: step,
                        vehicleId: stateVehicle?.id,
                        vin: stateVehicle?.vin,
                        mileage: stateVehicle?.mileAge?.toString(),
                        workshopUser: userContext.account?.username ?? "",
                    })

                    if (uploadedFile) {
                        const newImg = {
                            id: uploadedFile?.parts?.[0]?.files?.[0]?.meta?.thumbnail?.id ?? "",
                            imageUrl: uploadedFile?.parts?.[0]?.files?.[0]?.meta?.thumbnail?.url ?? "",
                        }

                        imagesActions.addImage(step, workLabel, newImg)
                    }
                } catch (error) {
                    setErrorMessage(translateText(13841))
                }
            }
        },
        [step, workLabel]
    )

    const replacePicture = useCallback(
        async (id: string, imageData: string) => {
            const result = await deleteFile({ fileId: id })
            if (result?.isDeleted) {
                imagesActions.deleteImage(step, workLabel, id)
                savePicture(imageData)
            }
        },
        [step, workLabel]
    )

    const removePicture = useCallback(
        async (id: string) => {
            try {
                const result = await deleteFile({ fileId: id })
                if (result?.isDeleted) {
                    imagesActions.deleteImage(step, workLabel, id)
                }
            } catch (error) {
                setErrorMessage(translateText(13841))
            }
        },
        [step, workLabel]
    )

    return {
        images,
        savePicture,
        replacePicture,
        removePicture,
        isLoading: isLoading || fileDeleteLoading,
        error: !!errorMessage,
        errorMessage,
    }
}
