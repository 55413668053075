import { useCallback } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { ImportOrderItemDto } from "@tm/models"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { ShowDetailsResponse as ShowCostEstimationDetailsResponse } from "@tm/data/vouchers/costEstimations"
import { useHistory } from "react-router"
import { ShowOrderDetailsResponse } from "../../../../data/model"
import { getBundleParams } from "../../../../utils"
import { useBasketImports } from "../../../../../../basket/src/hooks/basketState/useBasketImports"
import { mapVoucherPartInPartItem, mapVoucherWorkInWorkItem, mapToImportOrderItemDto } from "../../../../data/mapper"

export function useVoucherButtonsActions() {
    const history = useHistory()
    const { workTaskId, createWorkTask } = useWorkTask() || {}
    const { importVoucherItems, importVoucher } = useBasketImports()
    const { costEstimationRoute, costEstimationRouteWithoutWorkTaskId, basketRoute, basketRouteWithoutWorkTaskId } = getBundleParams()

    const orderButtonAction = useCallback(
        async (orderDetails: ShowOrderDetailsResponse | undefined, newWorkTask: boolean, redirectToCostEstimation: boolean) => {
            if (orderDetails?.orderItemsByWorkTask && createWorkTask) {
                let newWorkTaskId = workTaskId

                if (newWorkTask) {
                    newWorkTaskId = uniqueId()
                    await createWorkTask({
                        workTaskId: newWorkTaskId,
                        subPage: redirectToCostEstimation ? costEstimationRouteWithoutWorkTaskId : basketRouteWithoutWorkTaskId,
                    })
                } else if (newWorkTaskId) {
                    history.push(
                        renderRoute(redirectToCostEstimation ? costEstimationRoute : basketRoute, {
                            workTaskId: encodeUniqueId(newWorkTaskId),
                        })
                    )
                } else {
                    return
                }

                const orderItems: Array<ImportOrderItemDto> = []

                orderDetails.orderItemsByWorkTask.forEach((x) => {
                    x.orderVoucherItems.forEach((item) => {
                        orderItems.push(mapToImportOrderItemDto(item))
                    })
                })

                if (orderItems.length) {
                    importVoucherItems({ workTaskId: newWorkTaskId, orderItems })
                }
            }
        },
        [
            basketRoute,
            basketRouteWithoutWorkTaskId,
            costEstimationRoute,
            costEstimationRouteWithoutWorkTaskId,
            createWorkTask,
            history,
            importVoucherItems,
            workTaskId,
        ]
    )

    const costEstimationButtonAction = useCallback(
        async (costEstimationDetails: ShowCostEstimationDetailsResponse | undefined, redirectToCostEstimation: boolean) => {
            if (costEstimationDetails && createWorkTask) {
                const newWorkTaskId = uniqueId()
                const vehicleId = costEstimationDetails.vehicle?.id
                const customerId = costEstimationDetails.customer?.id
                await createWorkTask({
                    workTaskId: newWorkTaskId,
                    vehicle: vehicleId,
                    customer: customerId,
                    subPage: redirectToCostEstimation ? costEstimationRouteWithoutWorkTaskId : basketRouteWithoutWorkTaskId,
                })
                if (costEstimationDetails.costEstimation?.parts?.length || costEstimationDetails.costEstimation?.works?.length) {
                    importVoucher({
                        workTaskId: newWorkTaskId,
                        vehicleId,
                        customerId,
                        voucherParts: costEstimationDetails.costEstimation?.parts?.map(mapVoucherPartInPartItem),
                        voucherWorks: redirectToCostEstimation
                            ? costEstimationDetails.costEstimation?.works?.map(mapVoucherWorkInWorkItem)
                            : undefined,
                        currencyCode: costEstimationDetails.costEstimation.currencyCode,
                        currencySymbol: costEstimationDetails.costEstimation.currencySymbol,
                        vehicleImage: costEstimationDetails.vehicle?.vehicleImage,
                    })
                }
            }
        },
        [basketRouteWithoutWorkTaskId, costEstimationRouteWithoutWorkTaskId, createWorkTask, importVoucher]
    )

    return { orderButtonAction, costEstimationButtonAction }
}
