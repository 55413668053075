import { Article } from "@tm/models"
import { useMemo } from "react"
import { CompilationShowViewModelItem } from "../../../../../../compilations/src/data"
import { useArticles as useCompilationsArticles } from "../../../../../../compilations/src/data/hooks/useArticles"
import { mapDistinctValues } from "../../../ListV2/helpers"
import { ArticleData, ArticleGroupParams } from "../../../ListV2/models"

export function useArticles(parts: CompilationShowViewModelItem[]): ArticleData {
    const { articles, isLoading, requestStatus } = useCompilationsArticles(parts)
    const hasArticlesLoaded = !!articles && !isLoading
    const validArticles = useMemo(
        () => (hasArticlesLoaded ? articles?.filter((article) => article.productGroup.id !== -1 && article.internalId !== -1) ?? [] : []),
        [articles, hasArticlesLoaded]
    )
    const supplierIds = useMemo(() => mapDistinctValues(validArticles, (x) => x.supplier.id), [validArticles])
    const productGroupIds = useMemo(() => mapDistinctValues(validArticles, (x) => x.productGroup.id), [validArticles])
    const articleGroups = useMemo(() => groupArticles(articles), [articles])

    return {
        requestStatus,
        isLoading,
        isLoaded: requestStatus === "success" || requestStatus === "error",
        isSuccess: requestStatus === "success",
        isFailed: requestStatus === "error",
        articles: validArticles,
        articleCount: validArticles.length,
        articleGroups,
        supplierIds,
        productGroupIds,
        pageCount: 0,
        hasNextPage: false,
        isFetchingNextPage: false,
        loadNextPage: () => {},
    }
}

function groupArticles(articles: Article[] | undefined) {
    if (!articles?.length) {
        return []
    }

    const groupMap: Record<string, ArticleGroupParams> = {}

    articles.forEach((article) => {
        const group = groupMap[article.productGroup.id]
        if (group) {
            group.articles.push(article)
        } else {
            groupMap[article.productGroup.id] = {
                productGroup: article.productGroup,
                articles: [article],
                incomplete: false,
            }
        }
    })

    return Object.values(groupMap)
}
