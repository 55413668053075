import { Image, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"

type TabIconProps = { icon?: string }

export function TabIcon({ icon }: TabIconProps) {
    const { languageId } = useLocalization()

    if (!icon) {
        return null
    }

    if (icon.includes(".")) {
        // No icons with a dot in name supported
        return <Image src={icon} className="tab__icon icon icon--m" />
    }

    if (icon === "voucher-kva" && languageId !== "1") {
        return <Icon name={`${icon}-international`} className="icon tab__icon" size="1em" />
    }

    return <Icon name={icon} className="icon tab__icon" size="16px" />
}
