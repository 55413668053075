import { ArticleItemStateProvider } from "@tm/components"
import { WholesalerArticleNumbersItemState, WholesalerPart } from "@tm/models"
import { PropsWithChildren, useCallback, useMemo, useState } from "react"
import AvailabilityComponent from "../../../../../../erp/src/_shared/availability-wrapper"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import { useListOptionsContext } from "../../ContextProvider"
import { isAddButtonDisabled, isSameWholesalerArticle, mapWholesaleDtoFromWholesalerPart, mapWholesalerPartToArticle } from "../../helpers"
import { useArticleExtendedDeliveryInfo } from "../../hooks/useArticleItem/useArticleExtendedDeliveryInfo"
import { useArticleErpInfos } from "../../hooks/useArticleItem/useArticlesErpInfos"
import { useHandleAddWholesalerArticleNumberToBasket } from "../../hooks/useArticleItem/useHandleAddWholesalerArticleNumberToBasket"
import { useHandleRemoveFromBasket } from "../../hooks/useArticleItem/useHandleRemoveFromBasket"
import { useLoadErpInfos } from "../../hooks/useErpInfos"
import {
    useWholesalerArticleNumbersArticleListActionsContext,
    useWholesalerErpInfosContext,
    useWholesalerPartBasketQuantitiesContext,
} from "../../WholesalerArticleNumbers/ContextProvider"

type OeArticleItemStateProviderProps = PropsWithChildren<{
    article: WholesalerPart
}>

export function WholesalerArticleNumbersItemStateProvider({ article, children }: OeArticleItemStateProviderProps) {
    const options = useListOptionsContext()
    const erpInfosData = useWholesalerErpInfosContext()

    const loadErpInfos = useLoadErpInfos(erpInfosData)
    const [quantity, setQuantity] = useState<number>(article?.quantityValue || 1)
    const { basketQuantities: wholesalerBasketQuantities } = useWholesalerPartBasketQuantitiesContext()
    const { updateWholesalerBasketQuantities } = useWholesalerArticleNumbersArticleListActionsContext()

    const handleAddToBasketInternal = useHandleAddWholesalerArticleNumberToBasket(article)
    const handleRemoveFromBasketInternal = useHandleRemoveFromBasket()

    const basketQuantity = useMemo(() => {
        const wholesalerArticleDto = mapWholesaleDtoFromWholesalerPart(article)
        return wholesalerBasketQuantities?.find((q) => isSameWholesalerArticle(q.article, wholesalerArticleDto))
    }, [wholesalerBasketQuantities, article])

    const handleAddToBasket = useCallback(
        async (internalQuantity?: number) => {
            await handleAddToBasketInternal(internalQuantity ?? quantity)
            updateWholesalerBasketQuantities?.([article])
        },
        [handleAddToBasketInternal, quantity, article, updateWholesalerBasketQuantities]
    )

    const handleRemoveFromBasket = useCallback(async () => {
        if (basketQuantity?.articleQuantities?.allPartItemIds) {
            await handleRemoveFromBasketInternal(basketQuantity?.articleQuantities?.allPartItemIds)
            updateWholesalerBasketQuantities?.([article])
        }
    }, [basketQuantity, handleRemoveFromBasketInternal, updateWholesalerBasketQuantities, article])

    const wholesalerToArticle = useMemo(() => mapWholesalerPartToArticle(article), [article])
    const articleErpInfos = useArticleErpInfos(wholesalerToArticle, quantity, erpInfosData)

    const addButtonsDisabled = useMemo(() => isAddButtonDisabled(articleErpInfos), [articleErpInfos])

    const { hideExtendedDeliveryComponent } = useArticleListConfiguration()
    const extendedDeliveryInfo = useArticleExtendedDeliveryInfo(!hideExtendedDeliveryComponent, articleErpInfos.default)

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setQuantity(value)

            if (loadErpInfo) {
                loadErpInfos({ article: wholesalerToArticle, quantity: value, erpSystem: articleErpInfos.default?.erpSystem })
                if (articleErpInfos.alternative) {
                    loadErpInfos({ article: wholesalerToArticle, quantity: value, erpSystem: articleErpInfos.alternative.erpSystem })
                }
            }
        },
        [wholesalerToArticle, loadErpInfos, articleErpInfos]
    )

    const state: WholesalerArticleNumbersItemState = {
        type: "wholesalerArticleNos",
        handleAddToBasket,
        article: wholesalerToArticle,
        basketQuantity,
        handleChangeQuantity,
        handleRemoveFromBasket,
        quantity: article.quantityValue || 1,
        isVehicleDependent: false,
        originalQuantity: article.quantityValue || 1,
        AvailabilityComponent,
        thumbnailUrl: "",
        wholesalerArticleNumber: article.wholesalerArticleNumber,
        options,
        articleErpInfos,
        extendedDeliveryInfo,
        addToBasketButtonDisabled: addButtonsDisabled,
        addToCostEstimationButtonDisabled: addButtonsDisabled,
    }

    return <ArticleItemStateProvider value={state}>{children}</ArticleItemStateProvider>
}
