import { useEffect, useState } from "react"
import { ERIKContentService } from "@tm/data"
import { useLocalization } from "@tm/localization"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { AccessKeyExpireIn, ShareEmailTemplateProps, TemplateTitle } from "../data/models"
import { useFastServiceStore } from "../data"

export function useEmailTemplate() {
    const { translateText } = useLocalization()
    const customer = useWorkTask()?.workTask?.customer
    const { userSettings } = useUser()
    const { getSharedAccessKey } = ERIKContentService.useGetSharedAccessKey()
    const { getEmailTemplate } = ERIKContentService.useGetEmailTemplate()
    const pdfId = useFastServiceStore((state) => state.pdfId)
    const [emailTemplate, setEmailTemplate] = useState("")

    useEffect(() => {
        const fetchTemplate = async () => {
            const accessKeyResponse = await getSharedAccessKey([pdfId], AccessKeyExpireIn.ThirtyDays)
            if (accessKeyResponse?.directUrl) {
                const validTo = accessKeyResponse.validTo?.toLocaleDateString() ?? ""

                const templateContent: ShareEmailTemplateProps = {
                    textIntroduction: translateText(13818).replace("{{0}}", `${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`),
                    textFileDescription: translateText(13820).replace("{{1}}", validTo),
                    documentLinkText: translateText(13819),
                    textEnding: translateText(13821).replace("{{3}}", userSettings?.repairShop?.companyName || ""),
                    textDisclaimer: translateText(13822),
                    documentUrl: accessKeyResponse.directUrl,
                }

                const template = await getEmailTemplate(templateContent, TemplateTitle.DocumentSharingEmail)
                if (template?.url) {
                    const response = await fetch(template.url)
                    const htmlTemplate = await response.text()
                    setEmailTemplate(htmlTemplate)
                }
            }
        }

        fetchTemplate()
    }, [pdfId])

    return emailTemplate
}
