import { Tooltip, Typography, Loader, Icon, Button, Card, styled } from "@tm/components"
import { WorkTaskInfo, useUser, useWorkTask } from "@tm/context-distribution"
import { Table } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import {
    channel,
    ConfigParams,
    GetMainServicesRequest,
    IMicros,
    MainService,
    RepairTimeProvider,
    ServiceBase,
    ServiceDirection,
    SystemType,
} from "@tm/models"
import Morpheus, { connectComponent, useMicro } from "@tm/morpheus"
import { decodeUniqueId, getCategoryOfWorkDescriptionTextId, mapMainServicesToAddRepairTimeListRequest, renderRoute, uniqueId } from "@tm/utils"
import { useState, useEffect, Suspense } from "react"
import { useHistory, useParams, useRouteMatch } from "react-router"
import { ModuleOpenedPayload, RouteParams } from "../../business"
import { getProductGroups } from "../../data"
import getVehicle from "../../data/helpers/getVehicle"
import { useMainServices } from "../../data/hooks/useMainServices"

import AddToCostEstimation from "../_shared/AddToCostEstimation"
import { Actions, IActions } from "./business"
import IncludedWorksList from "./components/included-works-list"
import { useWorkTaskBasketState } from "../../../../basket/src/hooks/basketState/useWorkTaskBasketState"

const StyledCard = styled(Card)(() => ({
    position: "fixed",
    right: "2em",
    bottom: "2.5rem",
    zIndex: 6,
    padding: "0.5rem",
    borderTopLeftRadius: "0.25rem",
    borderTopRightRadius: "0.25rem",
}))

const ButtonWithPadding = styled(Button)(() => ({
    padding: "8px !important",
    "& svg": {
        margin: 0,
    },
}))

type Props = {
    actions: IActions
    scrollable?: boolean
    provider: RepairTimeProvider
    moduleOpenedPayload?: ModuleOpenedPayload
}

function RepairTimesSelectionComponent(props: Props & { workTask: WorkTaskInfo }) {
    const { workTask, provider, actions, moduleOpenedPayload, scrollable } = props
    const { renderMicro } = useMicro<IMicros>()
    const { number, translateText, translate } = useLocalization()
    const matchParams = useParams<RouteParams>()
    const history = useHistory()
    const { path } = useRouteMatch()
    const { subCategoryId, searchQuery, productGroupId, position, repairTimeId } = matchParams

    const { userContext, userSettings } = useUser() || {}
    const vehicle = getVehicle(workTask?.vehicle, userContext?.system.systemType)
    const { costEstimation } = useWorkTaskBasketState(workTask.id)

    const [marked, setMarked] = useState<MainService>()
    const [expanded, setExpanded] = useState<string[]>([])

    useEffect(() => {
        actions.clearMainService()
    }, [searchQuery, subCategoryId, provider, productGroupId, vehicle?.tecDocTypeId])

    let request: GetMainServicesRequest | undefined
    if (vehicle?.tecDocTypeId && userSettings?.repairTimeOptions) {
        request = {
            repairTimeProvider: provider,
            modelId: vehicle.tecDocTypeId,
            vehicleType: vehicle.vehicleType,
            fittingSideFilter: position ? parseInt(position) : undefined,
            manufacturerId: vehicle.tecDocManufacturerId,
            defaultRepairTimeDivision: userSettings.repairTimeOptions.division,
            useManufacturerRepairTimeDivision: userSettings.repairTimeOptions.useManufacturerRepairTimeDivision,
        }

        if (subCategoryId) {
            request = { ...request, nodeId: parseInt(subCategoryId) }
        } else if (searchQuery) {
            request = { ...request, query: searchQuery }
        } else if (productGroupId) {
            request = { ...request, productGroupId }
        }
    }

    const { mainServices, repairTimeDivision, isLoading, isIdle } = useMainServices(request)

    useEffect(() => {
        if (userSettings?.repairTimeOptions && repairTimeId && !marked && mainServices?.length) {
            const selectedRepairTime = mainServices.find((service: MainService) => service.repairTimeNo === repairTimeId)
            if (selectedRepairTime) {
                actions.selectMainService(selectedRepairTime, matchParams.workTaskId, provider, userSettings.repairTimeOptions.division)
                setMarked(selectedRepairTime)
            }
        }
    }, [userSettings, repairTimeId, mainServices, matchParams.workTaskId])

    if (isIdle || isLoading) {
        return <Loader />
    }

    if (!mainServices?.length) {
        return <>{translate(218)}</>
    }

    const getRowClassName = (mainService: MainService): string => {
        let className = "is-clickable"
        if (marked && marked.repairTimeNo === mainService.repairTimeNo && marked.sortId === mainService.sortId) {
            className += " is-selected"
        }

        if (expanded.indexOf(mainService.repairTimeNo) !== -1) {
            className += " fancy-list__item--expanded"
        }

        return className
    }

    const handleClick = (item: MainService) => {
        setMarked(marked !== item ? item : undefined)

        if (repairTimeDivision) {
            actions.selectMainService(item, matchParams.workTaskId, provider, repairTimeDivision)
        }

        history.push(renderRoute(path, { ...matchParams, repairTimeId: item.repairTimeNo }))
        if (moduleOpenedPayload) {
            actions.updateModuleURL(moduleOpenedPayload)
        }
    }

    const handleAddItemsToBasket = (service: MainService) => {
        if (!vehicle || !repairTimeDivision) {
            return
        }

        const fittingSide = position ? parseInt(position) : undefined
        const proms: Promise<void>[] = []
        if (!service.productGroupIds || !service.productGroupIds.length) {
            proms.push(
                getProductGroups({
                    repairTimeProvider: provider,
                    serviceId: service.repairTimeNo,
                    modelId: vehicle.tecDocTypeId,
                    vehicleType: vehicle.vehicleType,
                }).then((productGroups) => {
                    service.productGroupIds = productGroups.map((x) => x.id)
                })
            )
        }

        Promise.all(proms).then(() => {
            const workTaskId = decodeUniqueId(matchParams.workTaskId)
            if (workTaskId) {
                const work: MainService = {
                    ...service,
                    description: service.description || translateText(getCategoryOfWorkDescriptionTextId(service.serviceType)),
                    calculatedServices: service.calculatedServices?.map((calculatedService) => {
                        return {
                            ...calculatedService,
                            description:
                                calculatedService.description || translateText(getCategoryOfWorkDescriptionTextId(calculatedService.serviceType)),
                        }
                    }),
                    preparationWorks: service.preparationWorks?.map((calculatedService) => {
                        return {
                            ...calculatedService,
                            description:
                                calculatedService.description || translateText(getCategoryOfWorkDescriptionTextId(calculatedService.serviceType)),
                        }
                    }),
                }
                const addRepairTimesRequest = mapMainServicesToAddRepairTimeListRequest(
                    workTaskId,
                    provider,
                    [work],
                    vehicle.id,
                    repairTimeDivision,
                    fittingSide
                )
                costEstimation.actions.addRepairTimes(addRepairTimesRequest)
            }
        })
    }

    const handleRemoveRepairTime = (workId: string, service: MainService) => {
        if (!vehicle) {
            return
        }

        const proms: Promise<void>[] = []
        if (!service.productGroupIds || !service.productGroupIds.length) {
            proms.push(
                getProductGroups({
                    repairTimeProvider: provider,
                    serviceId: service.repairTimeNo,
                    modelId: vehicle.tecDocTypeId,
                    vehicleType: vehicle.vehicleType,
                }).then((productGroups) => {
                    service.productGroupIds = productGroups.map((x) => x.id)
                })
            )
        }

        Promise.all(proms).then(() => {
            costEstimation.actions.removeWorks([workId], true)
        })
    }

    const handleExpandToggle = (mainService: MainService) => {
        setExpanded(
            expanded.indexOf(mainService.repairTimeNo) === -1
                ? [...expanded, mainService.repairTimeNo]
                : expanded.filter((id) => id !== mainService.repairTimeNo)
        )
    }

    const handleShowPartsByProductGroupsClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, productGroupIds: number[]) => {
        e.stopPropagation()
        const { showPartsyProductGroupsInModal } = Morpheus.getParams<ConfigParams>()
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            productGroups: { ids: productGroupIds },
            inModal: showPartsyProductGroupsInModal,
            useNewModal: true,
        })
    }

    const renderNumbers = (mainService: MainService) => {
        return (
            <Table.Cell>
                <Typography variant="body2">{mainService.repairTimeDisplayNo || mainService.repairTimeNo}</Typography>
            </Table.Cell>
        )
    }

    const renderDescription = (mainService: MainService) => {
        let { description } = mainService
        if (mainService.oeReferenceNumber) {
            description += ` (OE ${mainService.oeReferenceNumber})`
        }

        return (
            <Table.Cell>
                <Typography variant="body2">{description}</Typography>
            </Table.Cell>
        )
    }

    const renderTime = (mainService: MainService) => {
        if (!mainService.repairTime) {
            return <Table.Cell />
        }

        return (
            <Table.Cell>
                <Typography variant="body2">{number(mainService.repairTime, 2)}</Typography>
            </Table.Cell>
        )
    }

    const renderNotes = (mainService: MainService) => {
        if (!mainService.note) {
            return <Table.Cell />
        }

        return (
            <Table.Cell>
                <Tooltip title={mainService.note.description}>
                    <Icon name="message" />
                </Tooltip>
            </Table.Cell>
        )
    }

    const renderActions = (mainService: MainService) => {
        return (
            <Table.Cell>
                {!productGroupId && mainService.productGroupIds && !!mainService.productGroupIds.length && (
                    <ButtonWithPadding
                        title={translateText(359)}
                        onClick={(e) => handleShowPartsByProductGroupsClick(e, mainService.productGroupIds)}
                        size="large"
                    >
                        <Icon size="18px" name="box" />
                    </ButtonWithPadding>
                )}
                {userContext?.system.systemType === SystemType.Next && (
                    <AddToCostEstimation<MainService>
                        service={mainService}
                        provider={provider}
                        workTaskId={matchParams.workTaskId}
                        onAddToBasket={handleAddItemsToBasket}
                        onRemoveRepairTime={handleRemoveRepairTime}
                        size="large"
                    />
                )}
                {userContext?.system.systemType === SystemType.Redesign &&
                    renderMicro("standalone", "rd-add-rt-to-basket", {
                        provider,
                        repairTimeDivision,
                        position,
                        vehicle,
                        service: mainService,
                        isPreparationWork: true,
                        buttonSize: "large",
                    })}
            </Table.Cell>
        )
    }

    const renderAttributes = (mainService: MainService) => {
        // Example: KTyp 4618 -> Autodata -> N -> N3 -> N3.0400
        return (
            <Table.Cell>
                {mainService.attributes?.map((element, idx) => {
                    if (element.isBlockSeparator) {
                        return <br />
                    }

                    return (
                        <Typography key={element.id} variant="label">
                            {`${element.value}${idx < mainService.attributes.length - 1 ? ", " : ""}`}
                        </Typography>
                    )
                })}
            </Table.Cell>
        )
    }

    const renderExpandButton = (mainService: MainService) => {
        const { calculatedServices, preparationWorks } = mainService

        const enabled =
            (!!calculatedServices && calculatedServices.some((work) => work && work.isVisible === true)) ||
            (!!preparationWorks && preparationWorks.some((work) => work && work.direction === ServiceDirection.Include))

        return (
            <Table.Cell>
                <Button
                    variant={enabled ? "contained" : "outlined"}
                    title={translateText(359)}
                    onClick={() => handleExpandToggle(mainService)}
                    disabled={!enabled}
                    size="small"
                >
                    <Icon size="12px" name={expanded.indexOf(mainService.repairTimeNo) === -1 ? "down" : "up"} />
                </Button>
            </Table.Cell>
        )
    }

    const renderExpandableCell = (mainService: MainService) => {
        const { calculatedServices, preparationWorks } = mainService

        let includedRepairTimes: ServiceBase[] = []
        if (calculatedServices?.length) {
            includedRepairTimes = calculatedServices.filter((calculatedService) => calculatedService.isVisible === true)
        } else if (preparationWorks?.length) {
            includedRepairTimes = preparationWorks.filter((preparationWork) => preparationWork.direction === ServiceDirection.Include)
        }

        if (!includedRepairTimes.length) {
            return <Table.Cell />
        }

        return (
            <Table.Cell>
                <IncludedWorksList includedRepairTimes={includedRepairTimes} repairTimeProvider={provider} />
            </Table.Cell>
        )
    }

    const renderTableColumns = () => {
        const repairTimesTextId = repairTimeDivision === 1 ? 84 : 1550

        return [
            <Table.Column className="fancy-list__block--expand-button" renderItemContent={renderExpandButton} />,
            <Table.Column className="fancy-list__block--number" renderItemContent={renderNumbers}>
                {translate(130)}
            </Table.Column>,
            <Table.Column className="fancy-list__block--description" renderItemContent={renderDescription}>
                {translate(131)}
            </Table.Column>,
            <Table.Column className="fancy-list__block--time" renderItemContent={renderTime}>
                {translate(repairTimesTextId)}
            </Table.Column>,
            <Table.Column className="fancy-list__block--notes" renderItemContent={renderNotes} />,
            <Table.Column className="fancy-list__block--actions" renderItemContent={renderActions} />,
            <Table.Column className="fancy-list__block--attributes" renderItemContent={renderAttributes} />,
            <Table.Column className="fancy-list__block--expendable" renderItemContent={renderExpandableCell} />,
        ]
    }

    return (
        <div className="repair-times__selection node-list">
            <Table
                scrollable={scrollable}
                data={mainServices}
                onClickRow={handleClick}
                columns={renderTableColumns()}
                getRowClassName={getRowClassName}
            />
            {(provider === RepairTimeProvider.AwDocCar || provider === RepairTimeProvider.AwDocTruck) && (
                <StyledCard>
                    <Typography variant="label">{translateText(793)}</Typography>
                </StyledCard>
            )}
        </div>
    )
}

function Wrapper(props: Props) {
    const workTask = useWorkTask()?.workTask
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <RepairTimesSelectionComponent {...props} workTask={workTask} />
        </Suspense>
    )
}

export default connectComponent(Actions, Wrapper)
