import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."

export type TestDriveSlice = {
    testDriveState: {
        testDriveStatus: boolean
        date?: Date
        mileageStart?: string
        mileageEnd?: string
        timeStart?: string
        timeEnd?: string
    }
}

export function getInitialState(): TestDriveSlice {
    return {
        testDriveState: {
            testDriveStatus: false,
            date: new Date(),
            mileageStart: undefined,
            mileageEnd: undefined,
            timeStart: undefined,
            timeEnd: undefined,
        },
    }
}

export const createTestDriveSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], TestDriveSlice> = (set) => ({
    ...getInitialState(),
})
