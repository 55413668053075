import { ArticleErpInfo } from "@tm/models"
import { Dispatch, PropsWithChildren, SetStateAction } from "react"
import { createContext, useContextSelector } from "use-context-selector"

import { ensureContextAvailability } from "../../helpers"
import { ArticleData, BaseArticleData } from "../../models"

export type ArticlesProviderProps = PropsWithChildren<{
    setHasRendered: Dispatch<SetStateAction<boolean>>
    erpInfos: ArticleErpInfo[]
}>

export const ArticlesContext = createContext<BaseArticleData | undefined>(undefined)

export function useArticlesContext<TContext extends ArticleData>(): TContext
export function useArticlesContext<TContext extends ArticleData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useArticlesContext<TContext extends ArticleData, TReturnType>(selector?: (value: TContext) => TReturnType): TContext | TReturnType {
    return useContextSelector(ArticlesContext, (context) => {
        const validatedContext = ensureContextAvailability("ArticlesContext", context)

        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
