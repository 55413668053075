import { useAddCatalogPartList } from "../../data/hooks/workTaskBasket/parts/useAddCatalogPartList "
import { useAddCustomPartList } from "../../data/hooks/workTaskBasket/parts/useAddCustomPartList"
import { useAddOePartList } from "../../data/hooks/workTaskBasket/parts/useAddOePartList"
import { useAddWholesalerPartList } from "../../data/hooks/workTaskBasket/parts/useAddWholesalerPartList"
import { useBasketUpdateWorkflow } from "../../data/hooks/workTaskBasket/workflow/useBasketUpdateWorkflow"
import { useRemovePartList } from "../../data/hooks/workTaskBasket/parts/useRemovePartList"
import { useAddExternalPartList } from "../../data/hooks/workTaskBasket/parts/useAddExternalPartList"

async function resolve() {}

export function useBasketParts() {
    const handleBasketUpdateWorkflow = useBasketUpdateWorkflow(resolve)

    const addCustomPartList = useAddCustomPartList(handleBasketUpdateWorkflow)
    const addCatalogPartList = useAddCatalogPartList(handleBasketUpdateWorkflow)
    const addExternalPartList = useAddExternalPartList(handleBasketUpdateWorkflow)
    const addOePartList = useAddOePartList(handleBasketUpdateWorkflow)
    const addWholesalerPartList = useAddWholesalerPartList(handleBasketUpdateWorkflow)
    const removePartList = useRemovePartList(handleBasketUpdateWorkflow)

    return { addCatalogPartList, addCustomPartList, addExternalPartList, addOePartList, addWholesalerPartList, removePartList }
}
