import { useStyle, useUser } from "@tm/context-distribution";
import { Toolbar, Switch } from "@tm/controls";
import { useLocalization } from "@tm/localization";
import { classes } from "@tm/utils";
import { useMemo } from "react";
import { Switch as SwitchMui } from "@tm/components"

type Props = {
    isV2?: boolean
}

const PurchasePriceSwitch = ({ isV2 }: Props) => {
    const user = useUser()
    const { userSettingsUpdating } = user
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    if (user?.userContext?.parameter.purchasePricesDisabled) {
        return null
    }

    const handleChange = (showPurchasePrice: boolean) => {
        if (user?.userSettings?.articleListSettings)
            user.setUserSetting("SHOW_PURCHASE_PRICE", showPurchasePrice)
    }

    const canShowSwitch = (): boolean => {
        const externalModules = user?.userContext?.externalModules

        let isInfoEnabled = false;
        if (externalModules) {
            externalModules.map(externalModule => {
                if (externalModule.isInfoEnabled) {
                    isInfoEnabled = true
                    return
                }
            })
        }
        return isInfoEnabled
    }

    return (
        <>
            {canShowSwitch() && <Toolbar className={classes("tk-parts purchase-price-switch", style.showPurchasePriceToolbar)} title={translateText(1132)}>
                {isV2
                    ?
                    <SwitchMui
                        checked={user?.userSettings?.showPurchasePrice}
                        onChange={(event, checked) => handleChange(checked)}
                        disabled={userSettingsUpdating} />
                    :
                    <Switch
                        status={user?.userSettings?.showPurchasePrice}
                        onChange={handleChange}
                        disabled={userSettingsUpdating}
                    />}
            </Toolbar>}
        </>
    )
}

function getStyle() {
    return useStyle({
        showPurchasePriceToolbar: {
            $nest: {
                "&:before": {
                    borderLeft: "transparent"
                }
            }
        }
    })(PurchasePriceSwitch)
}

export default PurchasePriceSwitch
