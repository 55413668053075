import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { useErpInfosContext } from "../../../ContextProvider"
import { ensureContextAvailability } from "../../../helpers"
import { useVehicle } from "../../../hooks"
import { VehicleRecordsActions } from "../../../models"
import { useVehiclePartsArticlesContext } from "../Articles/ArticlesContext"
import { useLoadVehicleRecords } from "./useLoadVehicleRecords"

export const VehicleRecordsContext = createContext<VehicleRecordsActions | undefined>(undefined)

export function VehicleRecordsProvider(props: PropsWithChildren<{ isEnabled: boolean }>) {
    const vehicle = useVehicle()
    const erpInfosData = useErpInfosContext()
    const articlesData = useVehiclePartsArticlesContext()
    const vehicleRecords = useLoadVehicleRecords(
        vehicle,
        articlesData.productGroupIds,
        articlesData.articles,
        props.isEnabled && erpInfosData.isReady
    )

    return <VehicleRecordsContext.Provider value={vehicleRecords}>{props.children}</VehicleRecordsContext.Provider>
}

export function useVehicleRecordsContext(hasEnsureContext = true) {
    const context = useContext(VehicleRecordsContext)
    if (!hasEnsureContext) {
        return context
    }
    return ensureContextAvailability("VehicleRecordsContext", context)
}
