import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."

export type InspectionWorkSlice = {
    inspectionDate: Date | undefined
    inspectionKmValue: string
}

export function getInitialState(): InspectionWorkSlice {
    return { inspectionDate: new Date(), inspectionKmValue: "" }
}

export const createInspectionWorkSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], InspectionWorkSlice> = (set) => ({
    ...getInitialState(),
})
