import { Alert, Headline, Tag, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { renderRoute, usePrevious, useWindowSize } from "@tm/utils"
import { Box, Button, Icon, Tooltip, styled } from "@tm/components"
import { useActions } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { useCallback, useEffect, useRef } from "react"
import { useCostEstimationModuleParameter } from "@tm/context-distribution"
import { EreSelectionSteps } from "../../data/models"
import { MainActions, navigationSelector } from "../main/business"
import AddToKVAButton from "./components/AddToKVAButton"
import RenderButton from "./components/renderButton"
import TotalsCalculation from "../calculation/components/totals"
import { MatchParams } from "../summary/components/models"

type Props = {
    defaultRoute: string
    detailsTabRoute: string
    showPrice: boolean
    hasAdditional: boolean
}

export default function NavigationComponent({ defaultRoute, detailsTabRoute, showPrice, hasAdditional }: Props) {
    const { translateText } = useLocalization()
    const { active, params } = useSelector(navigationSelector)
    const [width] = useWindowSize()
    const history = useHistory()
    const matchParams = useParams<MatchParams>()

    const titleRef = useRef<HTMLDivElement>(null)
    const firstToolbarRef = useRef<HTMLDivElement>(null)
    const secondToolbarRef = useRef<HTMLDivElement>(null)

    const previousActive = usePrevious(active)

    const actions = useActions(MainActions, "changeStep", "removeIframe", "reset")

    const resetButtonEnabled =
        params[EreSelectionSteps.EXTERN] ||
        params[EreSelectionSteps.CALCULATION] ||
        params[EreSelectionSteps.DETAILS] ||
        active === EreSelectionSteps.DETAILS ||
        active === EreSelectionSteps.CALCULATION ||
        active === EreSelectionSteps.EXTERN

    const { hasCostEstimation } = useCostEstimationModuleParameter()

    useEffect(() => {
        if (!matchParams.view) {
            // onWidgetClick
            handleChangeStep(EreSelectionSteps.SUMMARY)
        }

        if (!!active && previousActive !== active) {
            handleChangeStep(active)
        }

        if (history.action === "POP" && matchParams.view) {
            actions.changeStep(matchParams.view)
        }
    }, [active, history.location.pathname])

    const handleChangeStep = (newStep: EreSelectionSteps) => {
        const routeParams = {
            workTaskId: matchParams.workTaskId,
            view: newStep,
            ...params[newStep],
        }

        const route = getRouteByActiveStep(newStep)
        const url = renderRoute(route, routeParams)

        if (active === EreSelectionSteps.NONE || matchParams.view === newStep) {
            history.replace(url)
        } else {
            history.push(url)
        }
    }

    const getRouteByActiveStep = (step: EreSelectionSteps) => {
        switch (step) {
            case EreSelectionSteps.DETAILS:
                return detailsTabRoute
            default:
                return defaultRoute
        }
    }

    const handleGeneralReset = () => {
        actions.removeIframe(true)
        actions.changeStep(EreSelectionSteps.SUMMARY)
        actions.reset()
    }

    const renderModuleTabs = useCallback(
        () => (
            <Toolbar title={translateText(176)}>
                <Box ref={firstToolbarRef}>
                    <Button
                        startIcon={<Icon name="summary" />}
                        variant="text"
                        color={active === EreSelectionSteps.SUMMARY ? "primary" : undefined}
                        onClick={() => actions.changeStep(EreSelectionSteps.SUMMARY)}
                    >
                        {translateText(409)}
                    </Button>
                    <Button
                        startIcon={<Icon name="damage-calculation" />}
                        variant="text"
                        color={active === EreSelectionSteps.EXTERN ? "primary" : undefined}
                        disabled={!params[EreSelectionSteps.EXTERN]}
                        onClick={() => actions.changeStep(EreSelectionSteps.EXTERN)}
                    >
                        {translateText(1613)}
                    </Button>
                    <Button
                        startIcon={<Icon name="calculation" />}
                        variant="text"
                        color={active === EreSelectionSteps.CALCULATION ? "primary" : undefined}
                        disabled={!params[EreSelectionSteps.CALCULATION]}
                        onClick={() => actions.changeStep(EreSelectionSteps.CALCULATION)}
                    >
                        {translateText(613)}
                    </Button>
                    <Button
                        startIcon={<Icon name="details" />}
                        variant="text"
                        color={active === EreSelectionSteps.DETAILS ? "primary" : undefined}
                        disabled={!params[EreSelectionSteps.DETAILS]}
                        onClick={() => actions.changeStep(EreSelectionSteps.DETAILS)}
                    >
                        {translateText(43)}
                    </Button>
                </Box>
            </Toolbar>
        ),
        [active]
    )

    const renderOptionsTabs = () => {
        return (
            <Toolbar title={translateText(177)}>
                <Box ref={secondToolbarRef}>
                    <Button
                        className="reset"
                        variant="text"
                        color="error"
                        startIcon={<Icon name="synchronize" />}
                        disabled={!resetButtonEnabled}
                        onClick={handleGeneralReset}
                    >
                        {translateText(48)}
                    </Button>
                </Box>
            </Toolbar>
        )
    }

    const renderPriceAndKVAButton = () => {
        if (!showPrice) {
            if (hasCostEstimation) {
                return <AddToKVAButton />
            }

            return null
        }

        const diffWidth =
            (titleRef.current?.offsetWidth ?? 0) + (firstToolbarRef.current?.offsetWidth ?? 0) + (secondToolbarRef.current?.offsetWidth ?? 0)
        const showTooltip = width - diffWidth < (hasAdditional ? 1250 : 900)

        if (showTooltip) {
            return (
                <Toolbar className="tooltip-total">
                    <Tooltip
                        // eslint-disable-next-line react/style-prop-object
                        color="primary"
                        title={
                            <StyledBoxTooltip>
                                <TotalsCalculation />
                            </StyledBoxTooltip>
                        }
                        placement="right"
                    >
                        <Tag label={translateText(13004)} size="m" layout="bordered" />
                    </Tooltip>
                    {hasCostEstimation && <AddToKVAButton />}
                </Toolbar>
            )
        }

        return (
            <Toolbar className="total-sum-take-damage">
                <StyledBox>
                    <TotalsCalculation />
                </StyledBox>
                {hasCostEstimation && <AddToKVAButton />}
            </Toolbar>
        )
    }

    return (
        <StyledNavigation>
            <Box className="ere-title" ref={titleRef}>
                <Headline size="s">{translateText(1464)}</Headline>
            </Box>
            {renderModuleTabs()}
            {renderOptionsTabs()}
            {matchParams.view === EreSelectionSteps.EXTERN && (
                <Box className="info-message">
                    <Tooltip showOnlyOnOverflow title={translateText(1539)}>
                        <Alert buttonText="info" icon="info" message={translateText(1539)} />
                    </Tooltip>
                </Box>
            )}
            {matchParams.view === EreSelectionSteps.SUMMARY && <RenderButton />}
            {matchParams.view === EreSelectionSteps.CALCULATION && renderPriceAndKVAButton()}
        </StyledNavigation>
    )
}

const StyledNavigation = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(0.5, 1.5),
    paddingBottom: ".5em",
    justifyContent: "flex-start",
    borderBottom: "2px solid #e2e2e2",
    position: "relative",
    top: 0,
    backgroundColor: "#efefef",
    zIndex: 2,
    ".tooltip-total": {
        marginLeft: "auto",
        marginRight: "-.5em",
        "&::before": {
            content: "unset",
        },
    },
    ".tag": {
        alignSelf: "flex-end",
        marginLeft: "auto",
        marginBottom: ".5em",
        marginRight: "1em",
        cursor: "default",
    },
    ".ere-title": {
        marginRight: "1rem",
        alignSelf: "center",
        display: "flex",
    },
    ".take-damage": {
        marginLeft: "auto",
        marginRight: ".25em",
    },
    ".total-sum-take-damage": {
        marginLeft: "auto",
        marginRight: "-.5em",
    },
    ".tab": {
        margin: ".1em",
    },
    ".info-message": {
        display: "flex",
        marginLeft: "auto",
        svg: {
            fill: "black",
        },

        ".alert": {
            height: "2.5em",
            marginBottom: 0,
            marginTop: ".2em",
            border: "1px solid transparent",
            backgroundColor: "#ffeb3b",

            ".alert__icon": {
                alignSelf: "initial",
            },

            ".alert__text-wrapper": {
                marginLeft: ".5em",
            },
        },

        ".text": {
            color: "black !important",
            fill: "black",
        },
    },
    ".toolbar": {
        display: "inline-flex",
        position: "relative",
        "&::before": {
            position: "absolute",
            top: 0,
            left: 0,
        },
        "&__content": {
            marginRight: 0,
            padding: 0,
            svg: {
                marginRight: ".3em",
            },
        },
    },
    ".articles-summary": {
        alignContent: "left",
        display: "inline-flex",
        justifyContent: "space-between",
        whiteSpace: "nowrap",
        ".panel": {
            "&.total": {
                ".panel": {
                    "&__content": {
                        ".price": {
                            ".value": {
                                color: "#f3ae21",
                            },
                        },
                    },
                },
            },

            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
            marginBottom: "-.5em",
            borderBottom: "0.35em #c3c3c3 solid",
            marginRight: "1em",
            fontWeight: "bold",

            ".panel": {
                "&__title": {
                    border: "none",
                    fontSize: "medium",
                    padding: 0,
                },
                "&__content": {
                    ".price": {
                        display: "flex",
                        position: "relative",
                        flexWrap: "wrap",

                        ".value": {
                            marginBottom: ".35em",

                            "&.initial-price": {
                                flex: "0 0 100%",
                            },
                        },
                    },
                },
            },
        },
    },
}))

const StyledBox = styled(Box)({
    alignContent: "center",
    height: "3.5em",
    display: "inline-flex",
    justifyContent: "space-between",
    whiteSpace: "nowrap",

    ".panel": {
        marginRight: "1em",
        "&.total": {
            ".panel": {
                "&__content": {
                    ".price": {
                        marginLeft: "-0.5em",
                        ".value": {
                            color: "#f3ae21",
                            fontSize: "1rem",
                            lineHeight: "1.2",
                            fontWeight: "700",
                        },
                    },
                },
            },
        },

        backgroundColor: "transparent",
        boxShadow: "none",
        ".panel": {
            "&__title": {
                border: "none",
                fontSize: "medium",
                padding: "0",
                lineHeight: "1.2",
                fontWeight: "400",
            },
            "&__content": {
                height: "auto",
                ".price": {
                    display: "flex",
                    position: "relative",
                    flexWrap: "wrap",
                    paddingBottom: 0,
                    marginLeft: "-0.5em",
                    marginBottom: ".35em",

                    ".value": {
                        fontSize: "1rem",
                        textIndent: "0.5em",
                        lineHeight: "1.2",
                        fontWeight: "700",
                        "&.initial-price": {
                            flex: "0 0 100%",
                        },
                    },
                },
            },
        },
    },
    ".panel.parts": {
        "&__title": {
            padding: "0",
        },
        "&__content": {
            height: "auto",
        },
        padding: "0 .2rem !important",
        height: "auto",
    },

    ".panel.repair-times": {
        padding: "0 .2rem !important",
        height: ".4em",
    },

    ".panel.total": {
        padding: "0 .2rem !important",
        height: ".4em",
    },

    ".panel.total.vat": {
        padding: "0 .2rem !important",
        height: ".4em",
    },
})

const StyledBoxTooltip = styled(Box)({
    alignContent: "center",
    height: "4em",
    display: "inline-flex",
    justifyContent: "space-between",
    whiteSpace: "nowrap",

    ".panel": {
        padding: "0 1rem",
        width: "auto",
        "&.total": {
            ".panel": {
                "&__content": {
                    ".price": {
                        marginLeft: "-0.5em",
                        ".value": {
                            color: "#f3ae21",
                            fontSize: "1rem",
                        },
                    },
                },
            },
        },
        backgroundColor: "transparent",
        boxShadow: "none",
        ".panel": {
            "&__title": {
                border: "none",
                fontSize: "small",
                padding: "0",
            },

            "&__content": {
                borderBottom: "none",
                height: "auto",

                ".price": {
                    display: "flex",
                    position: "relative",
                    flexWrap: "wrap",
                    paddingBottom: 0,
                    marginLeft: "-0.5em",

                    ".value": {
                        fontSize: "1rem",
                        textIndent: "0.5em",

                        "&.initial-price": {
                            flex: "0 0 100%",
                        },
                    },
                },
            },
        },
    },
    ".panel.parts": {
        "&__title": {
            padding: "0",
        },
        padding: "0",
        height: "auto",
        width: "min-content",
    },
})
