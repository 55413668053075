import { TmaEModule, channel, OE } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenArticleList(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { openArticleList, sourceId } = data

    if (!openArticleList) {
        return
    }

    switch (sourceId) {
        case "TM_MVC_DAT":
            TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SetSearchContextV2(TmaEModule.DAT_FAST_CALCULATION, "")
            openArticleList.skipTma = true

            const vehicle = this.props.workTask?.vehicle
            const manufacturerId = vehicle?.tecDocManufacturerId ?? 0
            const manufacturerName = vehicle?.manufacturer

            openArticleList.oePositions?.forEach((oePosition) => {
                // eslint-disable-next-line no-param-reassign
                oePosition.number = oePosition.number.trim()

                oePosition.parts?.forEach((part) => mapPart(part, manufacturerId, manufacturerName))

                const firstPart = oePosition.parts?.find((x) => x.productGroupId)
                const productGroupId = firstPart?.productGroupId ? +firstPart.productGroupId : undefined

                oePosition.replacements?.forEach((replacement) => {
                    // eslint-disable-next-line no-param-reassign
                    replacement.number = replacement.number.trim()

                    replacement.parts?.forEach((part) => mapPart(part, manufacturerId, manufacturerName, productGroupId))
                })
            })

            openArticleList.tecDocTypeId = openArticleList.tecDocTypeId ? +openArticleList.tecDocTypeId : vehicle?.tecDocTypeId

            console.log("openArticleList", openArticleList)

            break

        default:
            break
    }

    channel("WORKTASK").publish("PARTS/REQUEST_LIST", openArticleList)
}

function mapPart(part: OE.OePart, manufacturerId?: number, manufacturerName?: string, productGroupId?: number | undefined) {
    // eslint-disable-next-line no-param-reassign
    part.number = part.number.trim()

    // eslint-disable-next-line no-param-reassign
    part.oeArticleOrigin = {
        module: OE.OeArticleModule.GraphicalPartsIdentification,
        provider: OE.OeArticleProvider.DAT,
    }

    if (!part.manufacturerId && manufacturerId) {
        // eslint-disable-next-line no-param-reassign
        part.manufacturerId = manufacturerId
    }

    if (!part.manufacturerName && manufacturerName) {
        // eslint-disable-next-line no-param-reassign
        part.manufacturerName = manufacturerName
    }

    if (!part.productGroupId && productGroupId) {
        part.productGroupId = productGroupId
    }
    // eslint-disable-next-line no-param-reassign
    else if (typeof part.productGroupId === "string") {
        part.productGroupId = part.productGroupId ? +part.productGroupId : undefined
    }
}
