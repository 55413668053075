import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useParams } from "react-router"
import { ECounterType, ListFilter } from "@tm/models"
import { equals, TmaHelper } from "@tm/utils"
import { useRecoilState } from "recoil"
import { useQueryClient } from "react-query"
import { FILTERS_QUERY } from "../Filters/useFilters"
import { SelectedCriteriasState } from "../../../../_shared/uni-search-top-products/state/SelectedCriteriasState"
import { useArticleListSorting, useArticleSelectionActions } from "../../../hooks"
import { UniversalPartsListParams, UniversalPartsStartParams } from "../../../models"

function getStartParamsFromUrl(url: string, searchType: UniversalPartsStartParams["type"], initialCriterias: string[]): UniversalPartsStartParams {
    const searchParams = new URLSearchParams(url)

    switch (searchType) {
        case "uninode": {
            const treeId = searchParams.get("treeId")
            const nodeId = searchParams.get("nodeId")

            return {
                type: searchType,
                treeId: (treeId && parseInt(treeId)) || NaN,
                nodeId: (nodeId && parseInt(nodeId)) || NaN,
                criterias: initialCriterias,
            }
        }
        case "uniproductgroups": {
            const productGroupIds = searchParams.get("productGroupIds")

            return {
                type: searchType,
                productGroupIds: productGroupIds
                    ? productGroupIds
                          .split(",")
                          .map((x) => parseInt(x))
                          .filter((x) => !Number.isNaN(x))
                    : [],
            }
        }
        case "unisearch":
        default: {
            const query = searchParams.get("query")
            return {
                type: "unisearch",
                query: query || "",
            }
        }
    }
}
/**
 * Get information about the current article list parameters, status and filter selection and some helper methods to change them.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useListParams(): UniversalPartsListParams {
    const location = useLocation()
    const { searchType } = useParams<{ searchType: UniversalPartsStartParams["type"] }>()

    const sorting = useArticleListSorting()
    const { clear: clearSorting } = sorting
    const queryClient = useQueryClient()

    const [productGroups, setProductGroups] = useState<ListFilter[]>([])
    const [suppliers, setSuppliers] = useState<ListFilter[]>([])
    const [attributes, setAttributes] = useState<ListFilter[]>([])
    const [extendedAssortment, setExtendedAssortment] = useState(false)
    const [showAvailable, setShowAvailable] = useState(false)
    const [showAvailableSecondary, setShowAvailableSecondary] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)
    const [noResult, setNoResult] = useState(false)
    const [isFiltersLoading, setIsFiltersLoading] = useState(false)
    const [selectedCriteria, setSelectedCriterias] = useRecoilState(SelectedCriteriasState)

    // Relevant for Multiselect, resets on reload, list change or new search
    const { deselectAll } = useArticleSelectionActions()

    const initialCriterias = useMemo(() => {
        return selectedCriteria?.map((v) => v.queryStr) || []
    }, [selectedCriteria])

    const [startParams, setStartParams] = useState<UniversalPartsStartParams>(() =>
        getStartParamsFromUrl(location.search, searchType, initialCriterias)
    )

    useEffect(
        function resetOnStartParamsChanged() {
            const newStartParams = getStartParamsFromUrl(location.search, searchType, initialCriterias)
            deselectAll()

            if (!equals(newStartParams, startParams)) {
                setStartParams(newStartParams)
                setSuppliers((prev) => (prev.length ? [] : prev))
                setProductGroups((prev) => (prev.length ? [] : prev))
                setAttributes((prev) => (prev.length ? [] : prev))
                setExtendedAssortment(false)
                setSelectedCriterias(undefined)
                clearSorting()
                queryClient.resetQueries([FILTERS_QUERY])
            }
        },
        [deselectAll, location, searchType, startParams, clearSorting, initialCriterias]
    )

    const toggleProductGroup = useCallback((filter: ListFilter) => {
        setProductGroups((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)
            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }
            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleSupplier = useCallback((filter: ListFilter) => {
        setSuppliers((state) => {
            const existingIndex = state.findIndex((x) => x.id === filter.id)
            if (existingIndex === -1) {
                return [...state, { ...filter, isSelected: true }]
            }
            return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
        })
    }, [])

    const toggleAttribute = useCallback(
        (attribute: ListFilter) => {
            setAttributes((state) => {
                const existingIndex = state.findIndex((attr) => attr.id === attribute.id && attr.value === attribute.value)
                TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListInlineFilter, !!existingIndex)
                if (existingIndex === -1) {
                    return [...state, attribute]
                }

                // when deselecting an attribute filter this should also clear the selectedCriteria when same attribute was used there
                if (selectedCriteria?.length) {
                    const indexInSelectedCriteria = selectedCriteria.findIndex((attr) => attr.id === attribute.id && attr.value === attribute.value)

                    if (indexInSelectedCriteria !== -1) {
                        setSelectedCriterias([
                            ...selectedCriteria.slice(0, indexInSelectedCriteria),
                            ...selectedCriteria.slice(indexInSelectedCriteria + 1),
                        ])
                    }
                }
                return [...state.slice(0, existingIndex), ...state.slice(existingIndex + 1)]
            })
        },
        [selectedCriteria]
    )

    const handleChangeExtendedAssortment = useCallback((checked: boolean) => setExtendedAssortment(checked), [])
    const setAvailability = useCallback((checked: boolean) => setShowAvailable(checked), [])
    const setAvailabilitySecondary = useCallback((checked: boolean) => setShowAvailableSecondary(checked), [])

    const nextPage = useCallback(() => {
        setPageIndex((index) => index + 1)
    }, [])

    return {
        listType: "universal",
        startParams,
        pageIndex,
        productGroups,
        suppliers,
        attributes,
        showAvailable,
        showAvailableSecondary,
        extendedAssortment,
        noResult,
        isFiltersLoading,
        setProductGroups,
        setSuppliers,
        setAttributes,
        toggleProductGroup,
        toggleSupplier,
        toggleAttribute,
        setExtendedAssortment: handleChangeExtendedAssortment,
        setAvailability,
        setAvailabilitySecondary,
        setNoResult,
        setIsFiltersLoading,
        nextPage,
        sorting,
    }
}
