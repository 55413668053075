import { AddWholesalerPartListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import { useBasketModuleParameter } from "@tm/context-distribution"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { useHandlePostSendOrderAction } from "../../../../hooks/useHandlePostSendOrderAction"
import { useCreateBasketPartPostMessage } from "../../../../hooks/useCreatePartPostMessage"

export function useAddWholesalerPartList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { addToNextBasket, sendArticlesPostMessage } = useBasketModuleParameter()
    const { getMessageByWholsalerPart } = useCreateBasketPartPostMessage()
    const { handlePostSendOrderAction } = useHandlePostSendOrderAction()
    const { mutateAsync: addWholesalerPartList } = useMutation(
        ({ request }: { request: AddWholesalerPartListRequest; skipResponseHandling?: boolean }) => Data.addWholesalerPartList(request),
        {
            onSuccess: (response, { request, skipResponseHandling }) => {
                if (!skipResponseHandling) {
                    if (response) {
                        handleBasketUpdateWorkflow(request.workTaskId, response)
                    }

                    // Save work task in the DB
                    Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
                }
            },
        }
    )
    const addWholesalerParts = useCallback(
        (request: AddWholesalerPartListRequest, skipResponseHandling?: boolean) => {
            if (addToNextBasket !== false) {
                return addWholesalerPartList({ request, skipResponseHandling })
            }
            if (sendArticlesPostMessage) {
                const message = getMessageByWholsalerPart(request.wholesalerParts, request.workTaskId)
                handlePostSendOrderAction(message)
            }
            return Promise.reject(new Error("no function"))
        },
        [addToNextBasket, sendArticlesPostMessage, getMessageByWholsalerPart]
    )

    return addWholesalerParts
}
