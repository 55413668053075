import { ActivatableModule, ModuleId, ModuleOption, ModulePackage, VehicleType } from "@tm/models"
import { clone } from "@tm/utils"

type TranslateText = (key: string | number) => string

export const getModuleTitle = (
    mainModule: ActivatableModule,
    moduleId: ModuleId | undefined,
    moduleName: string,
    moduleGroupId: string,
    vehicleType: VehicleType,
    translateText: TranslateText
) => {
    if (mainModule.externalId) {
        return mainModule.name
    }

    if (!moduleId) {
        return moduleName
    }

    let moduleTitle = clone(moduleName)

    if (moduleGroupId === "technicalData" && vehicleType === VehicleType.CommercialVehicle) {
        moduleTitle = moduleTitle.replace("{{12987}}", "{{12986}}")
    }

    return moduleTitle.replace(/\{\{(.*?)\}\}/, (s, num) => translateText(num))
}

export function getMessage(
    moduleTitle: string,
    moduleName: string | undefined,
    defaultFullOptionModule: ModuleOption | undefined,
    defaultDemoOptionModule: ModuleOption | undefined,
    activeFullModulePackage: ModulePackage | undefined,
    activeDemoModulePackage: ModulePackage | undefined,
    activatableFullModulePackage: ModulePackage | undefined,
    activatableDemoModulePackage: ModulePackage | undefined,
    translateText: TranslateText
) {
    if (!moduleName) {
        return ""
    }

    // demo v2
    if (defaultFullOptionModule?.active) {
        let message = ""
        if (activeFullModulePackage) {
            message = `${translateText(13260).replace("{0}", `"${`${moduleName} ${activeFullModulePackage.name}`}"`)} `
        }

        if (activeDemoModulePackage) {
            const amountOfDays = activeDemoModulePackage.moduleOptions.demo?.active ? activeDemoModulePackage.moduleOptions.demo.amountOfDays ?? 0 : 0

            return `${translateText(12799)
                .replace("{0}", `"${`${moduleName} ${activeDemoModulePackage.name}`}"`)
                .replace("{1}", amountOfDays.toString())}`
        }

        if (activatableDemoModulePackage && activatableFullModulePackage) {
            return `${message} ${translateText(12596).replace("{0}", `"${`${moduleName} ${activatableFullModulePackage.name}`}"`)}`
        }

        if (activatableDemoModulePackage) {
            return `${message} ${translateText(13250).replace("{0}", `"${`${moduleName} ${activatableDemoModulePackage.name}`}"`)}`
        }

        if (activatableFullModulePackage) {
            return `${message} ${translateText(13251).replace("{0}", `"${`${moduleName} ${activatableFullModulePackage.name}`}"`)}`
        }
    }

    // demo v1
    if (defaultDemoOptionModule?.activatable && defaultFullOptionModule?.activatable) {
        return translateText(12596).replace("{0}", `"${moduleTitle}"`)
    }

    let message = ""

    if (defaultDemoOptionModule?.active) {
        message += `${translateText(12799)
            .replace("{0}", `"${moduleTitle}"`)
            .replace("{1}", (defaultDemoOptionModule.amountOfDays ?? 0).toString())} `
    } else if (defaultDemoOptionModule?.activatable) {
        message += `${translateText(13250).replace("{0}", `"${moduleTitle}"`)} `
    }

    if (defaultFullOptionModule?.active) {
        message += `${translateText(13260).replace("{0}", `"${moduleTitle}"`)} `
    } else if (defaultFullOptionModule?.activatable) {
        message += `${translateText(13251).replace("{0}", `"${moduleTitle}"`)} `
    }

    return message
}

export function getModuleOptionsAndPackages(mainModule: ActivatableModule) {
    const defaultFullOptionModule = mainModule.moduleOptions?.full
    const defaultDemoOptionModule = mainModule.moduleOptions?.demo

    let activeFullModulePackage: ModulePackage | undefined
    let activeDemoModulePackage: ModulePackage | undefined

    let activatableFullModulePackage: ModulePackage | undefined
    let activatableDemoModulePackage: ModulePackage | undefined

    let fullOptionModule: ModuleOption | undefined
    let demoOptionModule: ModuleOption | undefined

    if (defaultFullOptionModule?.active && mainModule.modulePackages) {
        if (mainModule.modulePackages.higherPackage?.moduleOptions?.full?.activatable) {
            activatableFullModulePackage = { ...mainModule.modulePackages.higherPackage }
            activeFullModulePackage = { ...mainModule.modulePackages?.currentPackage }
            fullOptionModule = activatableFullModulePackage.moduleOptions.full
        } else {
            activeFullModulePackage = mainModule.modulePackages.higherPackage?.moduleOptions?.full?.active
                ? mainModule.modulePackages.higherPackage
                : mainModule.modulePackages.currentPackage
            fullOptionModule = activeFullModulePackage?.moduleOptions.full?.active ? activeFullModulePackage?.moduleOptions.full : undefined
        }

        if (mainModule.modulePackages.higherPackage?.moduleOptions?.demo?.active) {
            activeDemoModulePackage = { ...mainModule.modulePackages.higherPackage }
            demoOptionModule = activeDemoModulePackage.moduleOptions.demo
        }

        if (mainModule.modulePackages.higherPackage?.moduleOptions?.demo?.activatable) {
            activatableDemoModulePackage = { ...mainModule.modulePackages.higherPackage }
            demoOptionModule = activatableDemoModulePackage.moduleOptions.demo
        }
    }

    fullOptionModule = fullOptionModule ?? defaultFullOptionModule
    demoOptionModule = demoOptionModule ?? defaultDemoOptionModule

    const isFullPackageActive = defaultFullOptionModule?.active && !activatableFullModulePackage

    return {
        defaultFullOptionModule,
        defaultDemoOptionModule,
        activeFullModulePackage,
        activeDemoModulePackage,
        activatableFullModulePackage,
        activatableDemoModulePackage,
        fullOptionModule,
        demoOptionModule,
        isFullPackageActive,
    }
}
