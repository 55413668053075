import { ArticleItem, Box, Button, Image, Stack, Switch, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { ManufacturerBase, OE, SearchFilters, channel, getCurrentWorkTaskId } from "@tm/models"
import { useCallback, useMemo, useState } from "react"
import Morpheus from "@tm/morpheus"
import { OeInformationData } from "../../models"
import { useOeInfoSelection } from "./hooks/useOeInfoSelection"
import { OeInformation as OeInformationType } from "../../../../data/model"
import { usePartsRoutes } from "../../hooks/usePartsRoutes"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import { createManufacturerDependentSearchRequest, createOePositions, createVehicleDependentSearchRequest } from "./helpers"
import { useVehicle } from "../../hooks/useVehicle"
import { OeInformationItemStateProvider } from "./OeInformationItemStateProvider"
import { ReplacementChain } from "../ReplacementChain"

type SectionStackProps = {
    noLeftPadding?: boolean
}

export const SectionStack = styled(Stack, {
    shouldForwardProp: (prop) => prop !== "noLeftPadding",
})<SectionStackProps>(({ noLeftPadding }) => ({
    paddingTop: "8px",
    marginLeft: "16px",
    paddingLeft: noLeftPadding ? "0px" : "10px",
    ":before": {
        content: '""',
        borderLeft: "1px solid #727272",
        height: "25px",
        position: "absolute",
        marginLeft: "-6px",
        marginTop: "-8px",
    },
}))

const OeWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.highlight.light,
    padding: "0 8px 8px 8px",
    display: "flex",
}))

type StyledProps = {
    isSelected: boolean
}

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "isSelected",
})<StyledProps>(({ isSelected, theme }) => ({
    "&&": {
        borderColor: isSelected ? theme.colors?.primary : "#b4b4b4",
    },
}))

type PropsInner = {
    oeInformationList: OeInformationType[]
    query?: string
    oeManufacturerIds?: number[]
}

export type OeInformationArticle = {
    manufacturer: ManufacturerBase
    parts: OE.OePart[]
}

function OeInformationComponent({ oeInformationList, query, oeManufacturerIds }: PropsInner) {
    const { translateText, translate } = useLocalization()
    const routes = usePartsRoutes()
    const history = Morpheus.getHistory()
    const {
        oeInformation: { showReplacementChain, hideManufacturerLogo, showOePrice, isVehicleDependentSearch },
    } = useArticleListConfiguration()

    const vehicle = useVehicle()

    const { selectedManufacturer, selectedOeArticle, onSelectManufacturer } = useOeInfoSelection(oeInformationList, query, oeManufacturerIds)

    const [switches, setSwitches] = useState({ manufacturer: false, vehicle: false })

    const onSelectReplacement = useCallback(
        (oeNumber: string) => {
            if (!oeNumber) {
                return
            }

            const baseUrl = renderRoute(routes.directSearch?.list || "", { workTaskId: encodeUniqueId(getCurrentWorkTaskId() ?? "") })
            const searchUrl = `${baseUrl}?query=${encodeURIComponent(oeNumber)}&searchFilter=${SearchFilters.OeReference}&isOeReplacement=1`

            history.push(searchUrl)
        },
        [history, routes.directSearch?.list]
    )

    const article: OeInformationArticle | undefined = useMemo(
        () =>
            selectedManufacturer?.manufacturer && selectedOeArticle?.parts?.length
                ? {
                      manufacturer: selectedManufacturer.manufacturer,
                      parts: selectedOeArticle.parts,
                  }
                : undefined,
        [selectedManufacturer, selectedOeArticle?.parts]
    )

    const handleManufacturerDependentSearch = useCallback(() => {
        if (!selectedManufacturer || !selectedOeArticle) {
            return
        }

        setSwitches({ manufacturer: true, vehicle: true })

        const positions = createOePositions(selectedOeArticle?.number, selectedManufacturer, selectedOeArticle)

        const request = createManufacturerDependentSearchRequest(
            selectedOeArticle.number,
            positions,
            selectedManufacturer.manufacturer.id,
            !showOePrice,
            selectedManufacturer?.oeArticles
        )

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }, [selectedManufacturer, selectedOeArticle, showOePrice])

    const handleVehicleDependentSearch = useCallback(() => {
        if (!selectedManufacturer || !selectedOeArticle || !vehicle) {
            return
        }

        setSwitches({ manufacturer: false, vehicle: true })

        const positions = createOePositions(selectedOeArticle?.number, selectedManufacturer, selectedOeArticle)

        const request = createVehicleDependentSearchRequest(positions, !showOePrice)

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)

        setTimeout(() => setSwitches({ manufacturer: false, vehicle: false }), 2000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedManufacturer, selectedOeArticle, showOePrice, vehicle?.id, setSwitches])

    if (!article) {
        return null
    }

    const displayVehicleDependentSearch = () => {
        if (!isVehicleDependentSearch) {
            return null
        }

        return (
            <Box display="flex">
                <SectionStack spacing="4px" noLeftPadding />
                <Box display="flex" flexDirection="column">
                    <Typography variant="h2" m={1}>
                        {translateText(389)}
                    </Typography>
                    <Box display="flex">
                        <Switch
                            checked={switches.vehicle}
                            disabled={!vehicle}
                            label={translate(13228)}
                            size="small"
                            sx={{ marginLeft: 0, marginRight: 0 }}
                            onChange={handleVehicleDependentSearch}
                        />
                    </Box>
                    {/* This toggle is hidden for now, until is requested by Dierk as a feature
                    <Box display="flex">
                        <Switch
                            checked={checkboxes.manufacturer}
                            label={translate(13229)}
                            size="small"
                            sx={{ marginLeft: 0, marginRight: 0 }}
                            onChange={handleManufacturerDependentSearch}
                        />
                    </Box> */}
                </Box>
            </Box>
        )
    }

    return (
        <OeWrapper>
            <Stack width="100%">
                <Stack direction="row" marginBottom="0.75em">
                    <Typography variant="h2" marginTop="8px">
                        {translateText(13168)}
                    </Typography>
                    <SectionStack spacing="4px">
                        <Typography textTransform="uppercase" variant="label">
                            {translateText(71)}
                        </Typography>
                        <Stack direction="row" spacing="6px">
                            {oeInformationList.map((info) => (
                                <StyledButton
                                    key={info.manufacturer.id}
                                    isSelected={info.manufacturer.id === article.manufacturer?.id}
                                    startIcon={
                                        hideManufacturerLogo ? undefined : (
                                            <Image type="manufacturer" src={info.manufacturer.thumbnail} height="20px" />
                                        )
                                    }
                                    onClick={onSelectManufacturer(info.manufacturer.id)}
                                    variant="outlined"
                                >
                                    {hideManufacturerLogo ? info.manufacturer.description || "OE" : ""}
                                </StyledButton>
                            ))}
                        </Stack>
                    </SectionStack>
                    {showReplacementChain && (
                        <ReplacementChain
                            selectedOeNumber={selectedOeArticle?.number}
                            oeNumbers={selectedManufacturer?.oeArticles?.map((x) => x.number)}
                            onSelectReplacement={onSelectReplacement}
                        />
                    )}
                </Stack>
                <OeInformationItemStateProvider article={article}>
                    <ArticleItem variant="OeInformation" />
                </OeInformationItemStateProvider>
            </Stack>
            {displayVehicleDependentSearch()}
        </OeWrapper>
    )
}

type Props = OeInformationData & {
    query?: string
    oeManufacturerIds?: number[]
}

export function OeInformation({ isLoading, oeInformation, query, oeManufacturerIds }: Props) {
    const { enableOeInformation } = useArticleListConfiguration().oeInformation

    if (!enableOeInformation) {
        return null
    }

    if (isLoading || !oeInformation?.length) {
        return null
    }

    return <OeInformationComponent oeInformationList={oeInformation} query={query} oeManufacturerIds={oeManufacturerIds} />
}
