import { useState, useRef, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { MessageToast } from "@tm/controls"
import { channel } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"
import { getBundleParams } from "../../utils"

type Props = {
    closeMessageDelay?: number
}

function ArticleAddedMessageComponent(props: Props) {
    const { translateText } = useLocalization()
    const { hideAddArticlesToBasketOrCostEstimationToastMessage } = getBundleParams()
    const workTaskId = useWorkTask()?.workTaskId
    const [showMessageId, setShowMessageId] = useState<number>()
    const closeTimeOutRef = useRef<number>()

    function hide() {
        setShowMessageId(undefined)
    }

    useEffect(() => {
        if (showMessageId) {
            closeTimeOutRef.current = window.setTimeout(hide, (props.closeMessageDelay || 3) * 1000)
        } else if (closeTimeOutRef.current) {
            window.clearTimeout(closeTimeOutRef.current)
        }
        return () => {
            if (closeTimeOutRef.current) {
                window.clearTimeout(closeTimeOutRef.current)
            }
        }
    }, [showMessageId])

    useEffect(() => {
        if (hideAddArticlesToBasketOrCostEstimationToastMessage) {
            return
        }

        const unsubscriptions: Array<() => void> = []

        const worktaskChannel = channel("WORKTASK", workTaskId)
        unsubscriptions.push(
            worktaskChannel.subscribe("BASKET/ARTICLE_ADDED", () => {
                setShowMessageId(1728)
            })
        )
        unsubscriptions.push(
            worktaskChannel.subscribe("BASKET/ARTICLE_QUANTITY_CHANGED", () => {
                setShowMessageId(1729)
            })
        )

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [hideAddArticlesToBasketOrCostEstimationToastMessage, workTaskId])

    if (!showMessageId) {
        return null
    }

    return <MessageToast skin="success" message={translateText(showMessageId)} icon="success" onButtonClick={hide} />
}

export default ArticleAddedMessageComponent
