import { Stack, Theme } from "@tm/components"
import { ExtendedOrderVoucherItem } from "../../../../../../../../../data/model"
import OrderPartsTable from ".."
import ReturnInformation from "./ReturnInformation"
import PartNotes from "../../../../_shared/PartNotes"

type Props = {
    workTaskId: string
    part: ExtendedOrderVoucherItem
    voucherId: string | undefined
    isNoteExpanded: boolean
    hasMailRetoure: boolean
    hideVehicleLogos: boolean | undefined
    isBigScreen: boolean
    theme: Theme
    onSelectPart(id: string): void
    onOpenArticleSearch(query: string): void
    onShowReturn(returnNumber: string): void
}

export default function PartFooter(props: Props) {
    const { workTaskId, part, isNoteExpanded, hasMailRetoure, voucherId, hideVehicleLogos, isBigScreen, theme } = props
    const { returnInfo, linkedItems } = part

    if (!hasMailRetoure && !isNoteExpanded) {
        return null
    }

    return (
        <Stack p={0.5} spacing={1.5} flex={1}>
            {isNoteExpanded && part?.memo && <PartNotes note={part?.memo} />}
            {hasMailRetoure && (
                <ReturnInformation returnInfo={returnInfo} handleOpenReturnVoucherClick={() => props.onShowReturn(returnInfo.returnVoucherId)} />
            )}
            {!!linkedItems?.length && (
                <OrderPartsTable
                    workTaskId={workTaskId}
                    parts={linkedItems || []}
                    hideVehicleLogos={hideVehicleLogos}
                    selectedIds={[]}
                    voucherId={voucherId}
                    isBigScreen={isBigScreen}
                    theme={theme}
                    onOpenArticleSearch={props.onOpenArticleSearch}
                    onSelectPart={props.onSelectPart}
                    onShowReturn={props.onShowReturn}
                />
            )}
        </Stack>
    )
}
