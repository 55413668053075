import { useState } from "react"
import { useContextSelector } from "use-context-selector"
import { PureArticleListType } from "@tm/models"
import { ensureContextAvailability, useAfterPaintEffect } from "../../../ListV2/helpers"
import { ArticlesContext, ArticlesProviderProps } from "../../../ListV2/ContextProvider/Articles"
import { useArticles } from "./useArticles"
import { ArticleData, PureListArticleData } from "../../../ListV2/models"

type PureListArticlesProviderProps = ArticlesProviderProps & {
    listId: string
    listType: PureArticleListType
    disableArticleGrouping: boolean
}

export function PureListArticlesProvider({ setHasRendered, erpInfos, children, listId, listType }: PureListArticlesProviderProps) {
    const [startRender, setStartRender] = useState(false)
    const articlesData = useArticles(listId, listType)

    useAfterPaintEffect(() => {
        setStartRender(true)
    }, [])

    useAfterPaintEffect(() => {
        if (articlesData.requestStatus === "success") {
            setHasRendered(true)
        }
    }, [articlesData.requestStatus])

    return <ArticlesContext.Provider value={articlesData as ArticleData}>{children}</ArticlesContext.Provider>
}

export function usePureListArticlesContext<TContext extends PureListArticleData>(): TContext
export function usePureListArticlesContext<TContext extends PureListArticleData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function usePureListArticlesContext<TContext extends PureListArticleData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(ArticlesContext, (context) => {
        const validatedContext = ensureContextAvailability("PureListArticlesContext", context)

        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
