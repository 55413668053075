import { useUser } from "@tm/context-distribution"
import { UserModuleType } from "@tm/models"
import { getBundleParams } from "../../../utils"

const ARTICLE_FEEDBACK_PATH = "/:workTaskId/modal/^article-feedback/:productGroupId/:supplierId/:supplierName/:supplierArticleNo/:traderArticleNo?"

export enum ShowFeedbackForArticleType {
    AllArticles = 0,
    OnlyTraderArticles = 1,
    ArticlesWithoutTraderReference = 2,
}

export type ArticleFeedbackConfiguration = {
    feedbackPath: string
    isEnabled: boolean
    showFeedbackAsButton: boolean
    showFeedbackForArticleType: ShowFeedbackForArticleType
    openInArticleDetails?: boolean
}

export function useArticleFeedbackConfiguration(): ArticleFeedbackConfiguration {
    const { userContext } = useUser()
    const { showArticleFeedbackAsButton } = getBundleParams()

    const feedbackModule = userContext.modules?.find((x) => x.type === UserModuleType.Feedback)

    const parameterValue = feedbackModule?.parameters?.find((x) => x.key === "DisplayBehavior")?.value
    const target = feedbackModule?.parameters?.find((x) => x.key === "Target")?.value
    const showFeedbackForArticleType = getShowFeedbackForArticleType(parameterValue)

    return {
        isEnabled: !!feedbackModule,
        showFeedbackAsButton: showArticleFeedbackAsButton ?? false,
        showFeedbackForArticleType,
        feedbackPath: ARTICLE_FEEDBACK_PATH,
        openInArticleDetails: target === "4",
    }
}

function getShowFeedbackForArticleType(parameterValue: string | undefined): ShowFeedbackForArticleType {
    switch (parameterValue) {
        case "500":
            return ShowFeedbackForArticleType.AllArticles
        case "501":
            return ShowFeedbackForArticleType.OnlyTraderArticles
        case "502":
            return ShowFeedbackForArticleType.ArticlesWithoutTraderReference
        default:
            return ShowFeedbackForArticleType.AllArticles
    }
}
