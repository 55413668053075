export type ShareEmailTemplateProps = {
    logoUrl?: string
    textIntroduction?: string
    textFileDescription?: string
    documentLinkText?: string
    textEnding?: string
    commercialImageUrl?: string
    textDisclaimer?: string
    documentUrl?: string
}

export enum TemplateTitle {
    DocumentSharingEmail = "DocumentSharingEmail",
}

export enum AccessKeyExpireIn {
    ThirtyDays = 30,
}
