import { ModelState, FieldValidationState, getValue, setValue, validateField, TmaHelper } from "@tm/utils"
import { TextField, DropdownField, NumberField, DateField } from "@tm/controls"
import { Paper, Typography, Grid, Button, Icon } from "@tm/components"

import { Customer, ECounterType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { getBundleParams } from "../../../utils"
import { GetStreetsRequest, Street } from "../../../data/models/address-picker"
import { AddressPickerRepository } from "../../../data"
import AddressSuggestBox from "./address-suggest-box"
import { onlyDigits } from "../../../helpers"

type Props = {
    model: Customer
    onChange(model: Customer, valid: boolean): void
    hideDiscount?: boolean
}

const maxDate = new Date()
maxDate.setFullYear(maxDate.getFullYear() - 16)
const minDate = new Date()
minDate.setFullYear(maxDate.getFullYear() - 100)

const CustomerDetailsInformation: React.FunctionComponent<Props> = (props: Props) => {
    const { model, hideDiscount, onChange } = props
    const { translateText, translate } = useLocalization()

    const firstNameRef = React.useRef<TextField>(null)
    const [actPath, setActPath] = React.useState<Array<string>>()
    const [showAdditionalInfo, setShowAdditionalInfo] = React.useState<boolean>(false)
    const [formularState, setFormularState] = React.useState<ModelState>()
    const [adressSugg, setAdressSugg] = React.useState<Array<Street>>([])
    const [adressSuggLoading, setAdressSuggLoading] = React.useState<boolean>(false)
    const [requierdFields, setRequierdFields] = React.useState<{
        lastnameClass: string
        companyClass: string
    }>({ lastnameClass: "is-required", companyClass: "" })

    const streetFieldNode = document.querySelectorAll(".address-field input")[0] as HTMLInputElement
    const zipFieldNode = document.querySelectorAll(".address-field input")[2] as HTMLInputElement
    const cityFieldNode = document.querySelectorAll(".address-field input")[3] as HTMLInputElement

    // to determine the count of the messages over all properties, I use this map reduce combination
    // const isValid = !modelState ? true : ![0, ...Object.keys(modelState).map(key => modelState[key].length)].reduce((pre, cur) => pre + cur)

    const titleItems = {
        0: " ",
        1: translateText(145),
        2: translateText(146),
        3: translateText(108),
    }
    const customerNo = model.customerNo || translateText(115)

    React.useEffect(() => {
        setTimeout(() => {
            firstNameRef.current?.focus()
        }, 25)
    }, [])

    if (!model) {
        return null
    }

    const handleValidationStateChange = (field: string, state: FieldValidationState) => {
        const modelState = formularState || {}
        if (state.messages.length) {
            modelState[field] = state.messages
        } else {
            delete modelState[field]
        }

        setFormularState(modelState)
    }

    const validateCompany = (value: string | undefined, required: boolean): FieldValidationState => {
        let state = validateField(value)
        if (required) {
            state = state.required(translateText(37)).min(2, translateText(289))
        }
        return state
    }

    const validateLastName = (value?: string, required?: boolean): FieldValidationState => {
        let state = validateField(value)
        if (required) {
            state = state.required(translateText(37)).min(2, translateText(289))
        }
        return state
    }

    const validateEmail = (value?: string): FieldValidationState => {
        const state = validateField(value).email(translateText(290))
        handleValidationStateChange("email", state)
        return state
    }

    const validate = (model: Customer): boolean => {
        let success = true
        success = success && validateEmail(model.email).isValid
        success = success && validateCompany(model.companyName, model.titleType === 3).isValid
        success = success && validateLastName(model.lastName, model.titleType !== 3).isValid
        return success
    }

    const handleChangeConfirm = (customer: Customer) => {
        onChange(customer, validate(customer))
    }

    const handleAddressChange = (customer: Customer) => {
        if (!getBundleParams().disableAddressSuggestions) {
            setAdressSuggLoading(true)
            const request: GetStreetsRequest = {
                countryCode: "DE",
                latitude: 53.720903,
                longitude: 10.281732,
                postcode: customer.zip,
                region: customer.city,
                street: customer.street,
            }
            AddressPickerRepository.getStreets(request).then((list) => {
                setAdressSugg(list)
                setAdressSuggLoading(false)
            })
        }
        handleChangeConfirm(customer)
    }

    const handleChange = (customer: Customer, path: Array<string>) => {
        if (path) {
            switch (path.first()) {
                case "titleType":
                    if (customer.titleType === 3) {
                        setRequierdFields({
                            lastnameClass: "",
                            companyClass: "is-required",
                        })
                    } else if (customer.titleType === 1) {
                        setRequierdFields({
                            lastnameClass: "is-required",
                            companyClass: "",
                        })
                    }
                    break
                case "companyName":
                    validateCompany(customer.companyName, customer.titleType === 3)
                    break
                case "lastName":
                    validateLastName(customer.lastName, customer.titleType !== 3)
                    break
                case "email":
                    validateEmail(customer.email)
                    break
                case "city":
                case "street":
                case "zip":
                    handleAddressChange(customer)
                    break
                case "sparePartsSurcharge":
                case "repairTimesSurcharge":
                case "sparePartsDiscount":
                case "repairTimesDiscount": {
                    // Get the changed value from the changed model
                    let value = getValue(customer, path) || ""
                    // Parse string to float (replace , with .)
                    value = parseFloat(value.replace ? value.replace(",", ".") : value)

                    // If the value is invalid, restore the old value
                    if (Number.isNaN(value)) {
                        value = 0 // getValue(rates, path)
                    }

                    // Update the parsed float value
                    setValue(customer, path, value)
                    break
                }
                default:
                    break
            }
        }
        setActPath(path)
        handleChangeConfirm(customer)
    }

    const handleNumberfieldChangeConfirm = (customer: Customer, path: Array<string>) => {
        if (path) {
            switch (path.first()) {
                case "sparePartsSurcharge":
                case "repairTimesSurcharge":
                case "sparePartsDiscount":
                case "repairTimesDiscount": {
                    const value = getValue(customer, path) || 0
                    setValue(customer, path, value)
                    break
                }
                default:
                    break
            }
        }
        onChange(customer, validate(customer))
    }

    const handleToggleAdditionalInfo = () => {
        !showAdditionalInfo && TmaHelper.GeneralCountEvent.Call(ECounterType.CustomerDetailsexpandcollapse)
        setShowAdditionalInfo(!showAdditionalInfo)
    }

    const handleBirthDateChange = (oldCustomer: Customer, birthDate: Date) => {
        const customer = {
            ...oldCustomer,
            birthDate,
        }
        handleChangeConfirm(customer)
    }

    const resetAddressSuggestions = () => {
        setAdressSugg([])
    }

    const handleAddressSuggestionSelect = (street: Street) => {
        if (!model || !actPath) {
            return
        }
        const customer = {
            ...model,
            street: street.name || model.street,
            zip: street.postcode || model.zip,
            city: street.region || model.city,
        }
        onChange(customer, validate(customer))
        resetAddressSuggestions()

        switch (actPath[0]) {
            case "street":
                streetFieldNode.focus()
                break
            case "zip":
                zipFieldNode.focus()
                break
            case "city":
                cityFieldNode.focus()
                break
            default:
                break
        }
    }

    const handleAddressInputKeyPress = (e: React.KeyboardEvent) => {
        if (e.which === 27) {
            resetAddressSuggestions()
        }
    }

    const renderAdditionalInformation = () => {
        if (!showAdditionalInfo) {
            return null
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <AddressSuggestBox
                        onSelect={handleAddressSuggestionSelect}
                        addressSuggest={adressSugg}
                        adressSuggLoading={adressSuggLoading}
                        onClickOutside={resetAddressSuggestions}
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        className="address-field"
                        label={translateText(112)}
                        model={model}
                        path={["zip"]}
                        floatingLabel
                        modelState={formularState}
                        onChange={handleChange}
                        onChangeConfirm={handleChangeConfirm}
                        onKeyPress={handleAddressInputKeyPress}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        className="address-field"
                        label={translateText(113)}
                        model={model}
                        path={["city"]}
                        modelState={formularState}
                        floatingLabel
                        onChange={handleChange}
                        onChangeConfirm={handleChangeConfirm}
                        onKeyPress={handleAddressInputKeyPress}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        className="address-field"
                        label={translateText(111)}
                        model={model}
                        path={["street"]}
                        modelState={formularState}
                        floatingLabel
                        onChange={handleChange}
                        onChangeConfirm={handleChangeConfirm}
                        onKeyPress={handleAddressInputKeyPress}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        className="address-field"
                        label={translateText(75)}
                        model={model}
                        floatingLabel
                        path={["addressAddition"]}
                        modelState={formularState}
                        onChange={handleChange}
                        onChangeConfirm={handleChangeConfirm}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        label={translateText(114)}
                        model={model}
                        path={["country"]}
                        modelState={formularState}
                        floatingLabel
                        onChange={handleChange}
                        onChangeConfirm={handleChangeConfirm}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label={translateText(121)}
                        model={model}
                        path={["fax"]}
                        modelState={formularState}
                        floatingLabel
                        onChange={handleChange}
                        onChangeConfirm={handleChangeConfirm}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label={translateText(78)}
                        model={model}
                        path={["taxId"]}
                        modelState={formularState}
                        onChange={handleChange}
                        floatingLabel
                        onChangeConfirm={handleChangeConfirm}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DateField
                        label={translateText(77)}
                        value={model.birthDate}
                        modelState={formularState}
                        maxDate={maxDate}
                        minDate={minDate}
                        floatingLabel
                        useUtc
                        onChange={handleBirthDateChange.bind(this, model)}
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Paper className="information" sx={{ position: "relative", pb: 3, mb: 3.5 }}>
                <Typography variant="h3" mb={1}>
                    {translate(569)}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6} md={2}>
                        <TextField value={customerNo} floatingLabel label={translateText(156)} readonly />
                    </Grid>
                    <Grid item xs={3} md={1}>
                        <DropdownField
                            label={translateText(118)}
                            model={model}
                            path={["titleType"]}
                            floatingLabel
                            items={titleItems}
                            modelState={formularState}
                            onChange={handleChange}
                            noCover
                        />
                    </Grid>
                    <Grid item xs={3} md={1}>
                        <TextField
                            label={translateText(76)}
                            model={model}
                            path={["academicTitle"]}
                            floatingLabel
                            modelState={formularState}
                            onChange={handleChange}
                            onChangeConfirm={handleChangeConfirm}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            label={translateText(119)}
                            model={model}
                            path={["firstName"]}
                            modelState={formularState}
                            floatingLabel
                            onChange={handleChange}
                            onChangeConfirm={handleChangeConfirm}
                            ref={firstNameRef}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            // className="is-required"
                            className={requierdFields.lastnameClass}
                            label={translateText(104)}
                            model={model}
                            path={["lastName"]}
                            modelState={formularState}
                            floatingLabel
                            onChange={handleChange}
                            onChangeConfirm={handleChangeConfirm}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            className={requierdFields.companyClass}
                            label={translateText(108)}
                            model={model}
                            path={["companyName"]}
                            modelState={formularState}
                            floatingLabel
                            onChange={handleChange}
                            onChangeConfirm={handleChangeConfirm}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            label={translateText(109)}
                            model={model}
                            path={["email"]}
                            floatingLabel
                            modelState={formularState}
                            onChange={handleChange}
                            onChangeConfirm={handleChangeConfirm}
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <TextField
                            label={translateText(110)}
                            model={model}
                            floatingLabel
                            path={["phone"]}
                            modelState={formularState}
                            onChange={handleChange}
                            onChangeConfirm={handleChangeConfirm}
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <TextField
                            label={translateText(1308)}
                            model={model}
                            floatingLabel
                            path={["mobile"]}
                            modelState={formularState}
                            onChange={handleChange}
                            onChangeConfirm={handleChangeConfirm}
                        />
                    </Grid>
                </Grid>
                {renderAdditionalInformation()}

                <Button
                    className="btn--center btn-expand"
                    startIcon={<Icon name={showAdditionalInfo ? "minus" : "plus"} />}
                    onClick={handleToggleAdditionalInfo}
                />
            </Paper>
            <Grid container spacing={1}>
                <Grid item xs={12} md={!hideDiscount ? 6 : 12}>
                    <Paper sx={{ height: "100%" }}>
                        <Typography variant="h3" mb={1}>
                            {translate(39)}
                        </Typography>
                        <TextField model={model} path={["memo"]} onChange={handleChange} onChangeConfirm={handleChangeConfirm} multiline />
                    </Paper>
                </Grid>
                {!hideDiscount && (
                    <Grid item xs={12} md={3}>
                        <Paper className="price-calculation" sx={{ height: "100%" }}>
                            <Typography variant="h3">{translate(285)}</Typography>
                            <Typography variant="body3" mb={1}>
                                {translate(231)}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={6} position="relative">
                                    <NumberField
                                        label={`${translateText(90)} %`}
                                        model={model}
                                        path={["sparePartsDiscount"]}
                                        modelState={formularState}
                                        onChange={handleChange}
                                        onChangeConfirm={handleNumberfieldChangeConfirm}
                                        floatingLabel
                                        nullable
                                        minimum={0}
                                        maximum={100}
                                        stepSize={0.01}
                                    />
                                </Grid>
                                <Grid item xs={6} position="relative">
                                    <NumberField
                                        label={`${translateText(83)} %`}
                                        model={model}
                                        path={["repairTimesDiscount"]}
                                        modelState={formularState}
                                        onChange={handleChange}
                                        onChangeConfirm={handleNumberfieldChangeConfirm}
                                        floatingLabel
                                        nullable
                                        minimum={0}
                                        maximum={100}
                                        stepSize={0.01}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                )}
                {!hideDiscount && ( // TODO: it must be defeined if the surcharges are going to be shown independently of the discounts
                    <Grid item xs={12} md={3}>
                        <Paper sx={{ height: "100%" }}>
                            <Typography variant="h3">{translate(12468)}</Typography>
                            <Typography variant="body3" mb={1}>
                                {translate(12469)}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={6} position="relative">
                                    <NumberField
                                        label={`${translateText(90)} %`}
                                        model={model}
                                        path={["sparePartsSurcharge"]}
                                        modelState={formularState}
                                        onChange={handleChange}
                                        onChangeConfirm={handleNumberfieldChangeConfirm}
                                        floatingLabel
                                        nullable
                                        minimum={0}
                                        maximum={9999}
                                        stepSize={0.01}
                                    />
                                </Grid>
                                <Grid item xs={6} position="relative">
                                    <NumberField
                                        label={`${translateText(83)} %`}
                                        model={model}
                                        path={["repairTimesSurcharge"]}
                                        modelState={formularState}
                                        onChange={handleChange}
                                        onChangeConfirm={handleNumberfieldChangeConfirm}
                                        floatingLabel
                                        nullable
                                        minimum={0}
                                        maximum={9999}
                                        stepSize={0.01}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </>
    )
}

export default CustomerDetailsInformation
