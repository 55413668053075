import { Box, ClickAwayListener, Icon, Switch, Typography } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { SearchFilters } from "@tm/models"
import { encodeUniqueId, renderRoute, TmaHelper } from "@tm/utils"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useHistory, useLocation } from "react-router"
import { Repositories } from "../../../../../data"
import { GetSearchFiltersRequest } from "../../../../../data/model"
import { getBundleParams } from "../../../../../utils"
import Hint from "../../../../_shared/direct-search-field/components/hint"
import QueryHistory from "../../../../_shared/direct-search-field/components/query-history"
import { SearchFiltersComponent } from "../../../../_shared/direct-search-field/components/search-filter-component"
import { usePartsRoutes } from "../../../hooks/usePartsRoutes"
import { SearchField } from "./SearchField"
import { Suggestions } from "./Suggestions"
import { useListParamsContext } from "../../../ContextProvider"

export function DirectSearch() {
    const extendedAssortment = useListParamsContext((x) => x.extendedAssortment)
    const searchFieldRef = useRef<HTMLDivElement | null>(null)
    const { translateText } = useLocalization()
    const { workTaskId } = useWorkTask() ?? {}
    const { search } = useLocation()
    const { urlQuery, urlSearchFilter } = useMemo(() => {
        const searchParams = new URLSearchParams(search)
        return {
            urlQuery: searchParams.get("query") ?? "",
            urlSearchFilter: parseInt(searchParams.get("searchFilter") ?? SearchFilters.All.toString()),
        }
    }, [search])
    const [query, setQuery] = useState(urlQuery)
    const routes = usePartsRoutes()
    const history = useHistory()
    const localization = useLocalization()
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [allowStartSearch, setAllowStartSearch] = useState(false)
    const [isExtendedSearch, setIsExtendedSearch] = useState(query.includes("*"))
    const [searchFilter, setSearchFilter] = useState<SearchFilters>(
        (() => {
            const urlSearchFilter = new URLSearchParams(window.location.search).get("searchFilter")
            if (!urlSearchFilter) {
                return SearchFilters.All
            }
            return parseInt(urlSearchFilter) || SearchFilters.All
        })()
    )
    const [hint, setHint] = useState("")
    const minQueryLength = useMemo(() => getBundleParams().minimumSearchLength.directSearch ?? 2, [])

    useEffect(() => setSearchFilter(urlSearchFilter), [urlSearchFilter])
    useEffect(() => {
        setQuery(urlQuery)
        setSearchFilter(searchFilter)
    }, [searchFilter, urlQuery])

    const resultCountsRequest = useMemo(() => {
        if (!query || query.length < minQueryLength) {
            return
        }
        return {
            query,
            searchFilter: SearchFilters.All,
            extendedAssortment,
        } as GetSearchFiltersRequest
    }, [query, minQueryLength, extendedAssortment])

    const resultCountsQuery = useQuery({
        queryKey: ["parts.directSearch.searchFilter.resultCounts", resultCountsRequest],
        queryFn: () => {
            if (resultCountsRequest) {
                return Repositories.getSearchFilters(resultCountsRequest)
            }
        },
        enabled: !!resultCountsRequest,
    })

    const resultCounts = useMemo(() => {
        return resultCountsQuery.data ?? []
    }, [resultCountsQuery.data])

    const handleChange = useCallback((value: string) => {
        setQuery(value)
    }, [])

    const startSearch = useCallback(
        (preventHideSuggestions?: boolean) => {
            const route = routes.directSearch?.list
            if (!route || !workTaskId) {
                return
            }
            if (query.length < minQueryLength) {
                return
            }
            const baseUrl = renderRoute(route, { workTaskId: encodeUniqueId(workTaskId) })
            const searchUrl = `${baseUrl}?query=${encodeURIComponent(query)}&searchFilter=${searchFilter}`

            TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.Direct(query, false)

            history.push(searchUrl)
            if (!preventHideSuggestions) {
                setShowSuggestions(false)
            }
        },
        [history, minQueryLength, query, routes.directSearch?.list, searchFilter, workTaskId]
    )

    const toggleExtendedSearch = (extendedSearch: boolean) => {
        setIsExtendedSearch(extendedSearch)

        if (extendedSearch) {
            setQuery((prev) => `${prev}*`)
            setSearchFilter(SearchFilters.All)
        } else {
            setQuery((prev) => prev.replace("*", ""))
        }
    }

    const handleClickHistoryItem = useCallback((historyQuery: string) => {
        setQuery(historyQuery)
        setAllowStartSearch(true)
    }, [])

    useEffect(() => {
        if (allowStartSearch) {
            setAllowStartSearch(false)
            startSearch()
        }
    }, [allowStartSearch, startSearch])

    useEffect(() => {
        startSearch(true)
    }, [searchFilter])

    return (
        <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
            <Box>
                <SearchField
                    id="directSearchQuery"
                    value={query}
                    ref={searchFieldRef}
                    onChange={handleChange}
                    onClick={() => setShowSuggestions(true)}
                    onStartSearch={startSearch}
                    placeholder={translateText(138)}
                />

                {searchFieldRef.current && (
                    <Suggestions anchor={searchFieldRef.current} isOpen={showSuggestions}>
                        {hint && <Hint hint={hint} />}
                        <Box p={1}>
                            <Switch
                                checked={isExtendedSearch}
                                onChange={(_state, checked) => {
                                    toggleExtendedSearch(checked)
                                }}
                                label={translateText(12466)}
                            />
                        </Box>
                        <Box display="flex">
                            <Box p={1} flex={1}>
                                <QueryHistory handleSearch={handleClickHistoryItem} />
                            </Box>
                            <Box className="search-filters-wrapper" p={1}>
                                <SearchFiltersComponent
                                    isExtendedSearch={isExtendedSearch}
                                    showApplyButton
                                    selectedSearchFilters={searchFilter}
                                    searchfilterResultCounts={resultCounts}
                                    onSelection={setSearchFilter}
                                    localization={localization}
                                    toggleExtendedSearch={toggleExtendedSearch}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" whiteSpace="initial" paddingLeft="11px" paddingBottom="0.75em">
                            <Box pr={0.625}>
                                <Icon name="info" size="20px" />
                            </Box>
                            <Typography variant="body3">{translateText(12893)}</Typography>
                        </Box>
                    </Suggestions>
                )}
            </Box>
        </ClickAwayListener>
    )
}
