import { AsyncAction, ActionDispatch } from "@tm/morpheus"
import { RequestArticleListPayload, SearchFilters, channel, CategoryType } from "@tm/models"
import { isEqual } from "lodash"
import { BundleActionType, SearchType, BundleActions, TabInfo } from "../../../business"
import { Models, Repositories } from "../../../data"
import { SearchState } from "./model"
import { getQueryHistory, removeQueryFromHistory } from "../../_shared/helper/queryhistory"

export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "SET_SEARCH_QUERY"; payload: string }
    | { type: "RESULT_COUNTS_LOADED"; payload: Array<Models.SearchFilter> }

const DEFAULT_STATE: SearchState = {
    query: "",
    searchType: SearchType.NONE,
    resultCounts: [],
    queryHistory: [],
    searchFilters: undefined,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): SearchState {
    switch (action.type) {
        case "VEHICLE_SET": {
            return {
                ...state,
                vehicle: action.payload,
            }
        }
        case "SET_SEARCHTYPE": {
            return {
                ...state,
                searchType: action.payload,
            }
        }
        case "SET_SEARCH_QUERY": {
            return {
                ...state,
                query: action.payload,
                hint: undefined,
            }
        }
        case "RESULT_COUNTS_LOADED": {
            return isEqual(action.payload, state.resultCounts)
                ? state
                : {
                      ...state,
                      resultCounts: action.payload,
                  }
        }
        case "SHOW_SEARCH_HINT": {
            return {
                ...state,
                hint: action.payload,
            }
        }
        case "QUERY_HISTORY_LOADED": {
            return {
                ...state,
                queryHistory: action.payload,
            }
        }
        default:
            break
    }

    return state
}

export function transmit(action: ComponentActionType): BundleActionType | undefined {
    switch (action.type) {
        case "SET_TAB_INFO":
            return action
        default:
            break
    }
}

export function receive(action: BundleActionType, dispatch: ActionDispatch<ComponentActionType, SearchState>, getState: () => SearchState) {
    switch (action.type) {
        case "SHOW_SEARCH_HINT":
        case "SET_SEARCHTYPE":
            dispatch(action)
            break
        case "FILTERS_CHANGED":
            if (getState().searchType != SearchType.DIRECT) {
                return
            }

            dispatch(getResultCounts(!!action.payload.extendedAssortment))
            break
        default:
            break
    }
}

function setSearchQuery(query: string): ComponentActionType {
    return { type: "SET_SEARCH_QUERY", payload: query }
}

function searchArticles(query: string, categoryType: CategoryType, searchFilter?: SearchFilters): AsyncAction<ComponentActionType, SearchState> {
    return (dispatch, getState) => {
        if (!query) {
            return
        }
        const state = getState()

        if (query != state.query) {
            dispatch(setSearchQuery(query))
        }

        const tabInfo: TabInfo = {
            moduleFilter: `"${query}"`,
        }

        const payload: RequestArticleListPayload = { forceReload: true }

        switch (categoryType) {
            case "vehicleParts":
                payload.synonym = { query }
                tabInfo.moduleView = "{{389}}"
                break
            case "universalParts":
                payload.uniSearch = { query }
                tabInfo.moduleView = "{{1009}}"
                break
            case "directSearch":
                payload.direct = { query, searchFilter }
                tabInfo.moduleView = "{{389}}"
                break
            default:
                return
        }

        dispatch({ type: "SET_TAB_INFO", payload: tabInfo }) // Can be removed, when publishModuleInfo is tested and in production
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", payload)
    }
}

function getResultCounts(extendedAssortment: boolean): AsyncAction<ComponentActionType, SearchState> {
    return (dispatch, getState) => {
        const state = getState()

        if (!state.query) {
            return
        }

        const request: Models.GetSearchFiltersRequest = {
            query: state.query,
            searchFilter: SearchFilters.All,
            extendedAssortment,
            oeManufacturerIds: undefined, // when search input is shown in /modal/part-alternatives it should be also passed.(NEXT-14427)
        }

        Repositories.getSearchFilters(request).then((response) => dispatch({ type: "RESULT_COUNTS_LOADED", payload: response }))
    }
}

function clearResultCounts(): ComponentActionType {
    return { type: "RESULT_COUNTS_LOADED", payload: [] }
}

function showSearchHint(hint: string): ComponentActionType {
    return {
        type: "SHOW_SEARCH_HINT",
        payload: hint,
    }
}

function loadQueryHistory(): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        getQueryHistory().then((history) => dispatch({ type: "QUERY_HISTORY_LOADED", payload: history }))
    }
}

function removeFromQueryHistory(query: string, index: number): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        removeQueryFromHistory(query, index).then((history) => dispatch({ type: "QUERY_HISTORY_LOADED", payload: history }))
    }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    setSearchQuery,
    searchArticles,
    getResultCounts,
    clearResultCounts,
    showSearchHint,
    loadQueryHistory,
    removeFromQueryHistory,
}
