import { em, important, rem } from "csx"
import { MouseEvent, useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, Text } from "@tm/controls"
import { PanelState } from "../../../data/models"

type Props = {
    title: string
    onStatusButtonClick?: (id: number, state: PanelState) => void
    id: number
    panelState: PanelState
}

export default function InspectionTitleHeader({ title, onStatusButtonClick, id, panelState }: Props) {
    const style = useMemo(() => getStyle(), [])

    const handleStatusButtonClick = (e: MouseEvent<HTMLDivElement>, buttonType: PanelState) => {
        e.preventDefault()
        e.stopPropagation()

        const selectedButtonType = buttonType === panelState ? PanelState.None : buttonType
        onStatusButtonClick?.(id, selectedButtonType)
    }

    return (
        <div className={style.panelheader}>
            <Text className={style.title} size="xl" modifiers="strong">
                {title}
            </Text>
            <div>
                <Button
                    onClick={(e) => {
                        handleStatusButtonClick(e, PanelState.Decline)
                    }}
                    isActive={panelState === PanelState.Decline}
                    className={style.bigButton}
                    size="xl"
                    layout={["holo"]}
                    icon="fast-check-cancel-bordered"
                />
                <Button
                    onClick={(e) => {
                        handleStatusButtonClick(e, PanelState.Check)
                    }}
                    isActive={panelState === PanelState.Check}
                    className={style.bigButton}
                    size="xl"
                    layout={["holo"]}
                    icon={panelState === PanelState.Check ? "checked-filled" : "check-bordered"}
                />
            </div>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        panelheader: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "space-between",
        },
        title: {
            flex: 0.6,
            marginLeft: em(2),
        },
        bigButton: {
            padding: em(0.2),
            marginLeft: em(0.5),
            $nest: {
                "svg.icon.btn__icon": {
                    width: rem(2.5),
                    height: rem(2.5),
                },
                "&.is-active svg": {
                    fill: important(theme.colors.primary),
                },
            },
        },
    })(InspectionTitleHeader)
}
