import { PropsWithChildren } from "react"
import { createContext, useContextSelector } from "use-context-selector"
import { ensureContextAvailability } from "../../helpers"
import { ArticleListActions } from "../../models"
import { useArticleListActions } from "./useArticleListActions"

export const ArticleListActionsContext = createContext<ArticleListActions | undefined>(undefined)

export function ArticleListActionsProvider(props: PropsWithChildren<unknown>) {
    const articleListActions = useArticleListActions()
    return <ArticleListActionsContext.Provider value={articleListActions}>{props.children}</ArticleListActionsContext.Provider>
}

export function useArticleListActionsContext<TContext extends ArticleListActions>(): TContext
export function useArticleListActionsContext<TContext extends ArticleListActions, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function useArticleListActionsContext<TContext extends ArticleListActions, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(ArticleListActionsContext, (context) => {
        const validatedContext = ensureContextAvailability("ArticleListActionsContext", context)

        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
