import { ajaxAuth } from "@tm/utils"
import { ChangeItemsResponse, ImportOrderVoucherItemsRequest, Item, OrderItem, OrderResponse, UserInteractionLog, AvailabilityLog } from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { mapShowCentralOrder } from "../../mapper"
import {
    SendWorkTaskOrderRequest,
    ShowWorkTasksResponse,
    SendCentralOrderRequest,
    ShowWorkTasksRequest,
    ShowCentralOrderRequest,
    ShowCentralOrderResponse,
    ChangeItemDistributorRequest,
    ChangeQuantityResponse,
} from "../../model"
import { getBasketServiceUrl } from "../.."
import { notifyBasketChanges } from "../../../helpers"

// TODO: remove export when the article-quantites endpoints are no longer used from the article V2
export function getServiceUrl() {
    return `${getBasketServiceUrl()}/Order`
}

// TODO: Delete when no longer used from the registered models
export function showItem(id: string): Promise<OrderItem> {
    const url = `${getServiceUrl()}/ShowItem`
    const body = { id }

    return new Promise<OrderItem>((resolve, reject) => ajaxAuth({ url, body }).then((response) => resolve(response && response.item), reject))
}

export function showWorkTasks(body: ShowWorkTasksRequest) {
    const url = `${getServiceUrl()}/ShowWorkTasks`
    return ajaxAuth<ShowWorkTasksResponse>({ url, body }, undefined, undefined, true)
}

export function showCentralOrder(body: ShowCentralOrderRequest): Promise<ShowCentralOrderResponse> {
    const url = `${getServiceUrl()}/ShowCentralOrder`
    return new Promise<ShowCentralOrderResponse>((resolve, reject) =>
        ajaxAuth({ url, body, method: "POST" }).then((response) => {
            response = mapShowCentralOrder(response)
            response ? resolve(response) : reject()
        }, reject)
    )
}

// TODO: Check all endpoints with workTask?: WorkTaskInfo as parameter. A workaround for notifyBasketChanges should be possible from hook
export function removeItemList(workTaskId: string, idList: Array<string>, workTask?: WorkTaskInfo, log?: UserInteractionLog) {
    const url = `${getServiceUrl()}/RemoveItemList`
    const body = { workTaskId, idList, log }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function removeAllWorkTaskItems(workTaskId: string, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/RemoveAllWorkTaskItems`
    const body = { workTaskId }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function changeItemQuantity(item: Item, quantityValue: number, workTask?: WorkTaskInfo, availability?: AvailabilityLog) {
    const url = `${getServiceUrl()}/ChangeItemQuantity`
    const body = { item, quantityValue, availability }

    return ajaxAuth<ChangeQuantityResponse>({ url, body, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function excludeItemList(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/ExcludeItemList`
    const body = { workTaskId, itemList }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}

export function includeItemList(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/IncludeItemList`
    const body = { workTaskId, itemList }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}

export function attachItemMemo(item: Item, memo: string, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/AttachItemMemo`
    const body = { item, memo }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function editItemMemo(item: Item, memo: string, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/EditItemMemo`
    const body = { item, memo }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function removeItemMemo(item: Item, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/RemoveItemMemo`
    const body = { item }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function sendWorkTaskOrder(body: SendWorkTaskOrderRequest) {
    const url = `${getServiceUrl()}/SendWorkTaskOrder`
    return ajaxAuth<OrderResponse>({ url, body, method: "POST" }, undefined, undefined, true)
}

export function sendCentralOrder(body: SendCentralOrderRequest) {
    const url = `${getServiceUrl()}/SendCentralOrder`
    return ajaxAuth<Array<OrderResponse>>({ url, body, method: "POST" }, undefined, undefined, true)
}

export function changeItemDistributor(body: ChangeItemDistributorRequest) {
    const url = `${getServiceUrl()}/ChangeItemDistributor`
    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}

export function importOrderVoucherItems(body: ImportOrderVoucherItemsRequest) {
    const url = `${getServiceUrl()}/ImportVoucherItems`
    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}
