import { useCallback, useMemo, useRef, useState } from "react"
import { Button, Paper, Icon, IconButton, Stack, Typography, Loader, CostEstimationButton } from "@tm/components"
import { WarningPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useShowNewArticleList } from "@tm/context-distribution"

type Props = {
    selectedIds: string[]
    allSelected: boolean
    selectAll: () => void
    unselectAll: () => void
    deleteSelected(selectedIds: string[]): Promise<void>
    onAddToBasket(selectedIds: string[]): Promise<void>
    itemsType: "article" | "repair-time"
}

export function CustomItemSelection({ selectedIds, allSelected, selectAll, unselectAll, deleteSelected, onAddToBasket, itemsType }: Props) {
    const { translateText, languageId } = useLocalization()
    const showNewArticleList = useShowNewArticleList()

    const warningRef = useRef<WarningPrompt>(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isBeingAddedToBasket, setIsBeingAddedToBasket] = useState(false)

    const handleDelete = useCallback(() => {
        setIsDeleting(true)
        warningRef.current?.show()
    }, [])

    const handleDialogPromptConfirm = useCallback(async () => {
        await deleteSelected(selectedIds)
        setIsDeleting(false)
    }, [selectedIds, deleteSelected])

    const handleDialogPromptClose = useCallback(() => {
        setIsDeleting(false)
    }, [])

    const handleAddToBasket = useCallback(async () => {
        setIsBeingAddedToBasket(true)
        await onAddToBasket(selectedIds)
        setIsBeingAddedToBasket(false)
    }, [selectedIds, onAddToBasket])

    const icon = useMemo(() => {
        if (!showNewArticleList || itemsType === "article") {
            return languageId === "1" ? "voucher-kva" : "voucher-kva-international"
        }
        return "add-repair-times"
    }, [showNewArticleList, itemsType, languageId])

    if (!selectedIds.length) {
        return null
    }

    return (
        <Paper sx={(theme) => ({ p: theme.spacing(0.5, 2) })}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2">
                    {translateText(701)}: {selectedIds.length} {translateText(479)}
                </Typography>

                <Button size="small" onClick={allSelected ? unselectAll : selectAll}>
                    {translateText(allSelected ? 703 : 702)}
                </Button>

                <IconButton title={translateText(624)} onClick={handleDelete} disabled={isDeleting}>
                    {isDeleting ? <Loader size="small" /> : <Icon name="delete" />}
                </IconButton>
                <WarningPrompt
                    text={translateText(834)}
                    confirmationButtonText={translateText(585)}
                    cancelButtonText={translateText(584)}
                    ref={warningRef}
                    onConfirm={handleDialogPromptConfirm}
                    onClose={handleDialogPromptClose}
                    doNotCloseOnConfirm
                />
                <CostEstimationButton
                    title={itemsType === "article" ? translateText(937) : translateText(916)}
                    variant={!isBeingAddedToBasket ? "bordered" : undefined}
                    onAddToCostEstimation={handleAddToBasket}
                    disabled={isBeingAddedToBasket}
                    color={!isBeingAddedToBasket ? "highlight" : undefined}
                    startIcon={<Icon name={icon} />}
                    sx={{ minWidth: "6em" }}
                    size="medium"
                />
                <IconButton onClick={unselectAll} title={translateText(317)}>
                    <Icon name="cancel-x" />
                </IconButton>
            </Stack>
        </Paper>
    )
}
