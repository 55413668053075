import { PropsWithChildren } from "react"
import { createContext, useContextSelector } from "use-context-selector"
import { useOensArticles } from "./useOensArticles"
import { ensureContextAvailability } from "../../../helpers"
import { getBundleParams } from "../../../../../utils"
import { useDirectSearchListParamsContext } from ".."
import { OensArticleData } from "../../../models"

export const OensArticlesContext = createContext<OensArticleData | undefined>(undefined)

export function OensArticlesProvider(props: PropsWithChildren<{ isEnabled: boolean }>) {
    const enableOENS = getBundleParams().enableOENS ?? false
    const params = useDirectSearchListParamsContext()
    const oensArticles = useOensArticles(params, props.isEnabled && enableOENS)

    return <OensArticlesContext.Provider value={oensArticles}>{props.children}</OensArticlesContext.Provider>
}
export function useOensArticlesContext<TContext extends OensArticleData>(): TContext
export function useOensArticlesContext<TContext extends OensArticleData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useOensArticlesContext<TContext extends OensArticleData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useContextSelector(OensArticlesContext, (context) => {
        const validatedContext = ensureContextAvailability("OensArticlesContext", context)
        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
