import { EReplaceButtonBundle, OE, RequestArticleListPayload, UserContext } from "@tm/models"
import { getCurrencyFromUserContext } from "@tm/utils"
import { ExtendedOePart, ICalculationItem } from "../../../../data/models"

const getReplacements = (replacements?: string[]) => {
    return replacements?.map((number) => ({ number })) ?? []
}

function mapOePart(part: ExtendedOePart, userContext: UserContext): OE.OePart {
    return {
        id: part.datProcessId?.toString(),
        description: part.description,
        number: part.oeArticleNumber,
        additionalDescription: part.additionalDescription,
        quantity: part.quantityValue,
        manufacturerId: part.vehicleManufacturerId,
        manufacturerName: part.vehicleManufacturerName,
        thumbnailUrl: part.thumbnailUrl,
        productGroupId: part.productGroupId,
        ...(part.oePriceValue && {
            prices: [
                {
                    currency: getCurrencyFromUserContext(userContext),
                    value: part.oePriceValue,
                },
            ],
        }),
    }
}

export const createRequestArticleListPayload = (item: ICalculationItem, userContex: UserContext): RequestArticleListPayload => {
    return {
        oePositions: [
            {
                number: item.oeArticle.oeArticleNumber,
                description: item.oeArticle.description,
                additionalDescription: item.oeArticle.additionalDescription,
                isSelected: item.isSelected,
                replacements: getReplacements(item.oeArticle.replacements),
                ...(!!item.selectedPart && {
                    parts: [mapOePart(item.oeArticle, userContex)],
                }),
            },
        ],
        replaceButtonMicro: EReplaceButtonBundle.DMGDatGlass,
        isOeReplacement: true,
        forceReload: true,
        inModal: true,
    }
}
