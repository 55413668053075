import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { ListOptions } from "@tm/models"
import { ensureContextAvailability } from "../../helpers"
import { useListOptions } from "./useListOptions"

export const ListOptionsContext = createContext<ListOptions | undefined>(undefined)

export function ListOptionsProvider(props: PropsWithChildren<unknown>) {
    const listOptions = useListOptions()

    return <ListOptionsContext.Provider value={listOptions}>{props.children}</ListOptionsContext.Provider>
}

export function useListOptionsContext(): ListOptions {
    const context = useContext(ListOptionsContext)
    return ensureContextAvailability("ListOptionsContext", context)
}
