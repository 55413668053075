import { create } from "zustand"
import { devtools, persist, createJSONStorage } from "zustand/middleware"
import { useShallow } from "zustand/react/shallow"
import { isDevtoolEnabled } from "@tm/utils"
import { zustandSessionStorage } from "../sessionStorage"
import { replacer, reviver } from "../sessionStorage/helper"
import { createEndPageSlice, EndPageSlice } from "./end-page"
import { createInspectionWorkSlice, InspectionWorkSlice } from "./inspection-work"
import { createMaintenancePlanSlice, MaintenancePlanSlice } from "./maintenance-plan"
import { createNavigationSlice, NavigationSlice } from "./navigation"
import { createTechnicianSlice, TechnicianSlice } from "./technician"
import { createTestDriveSlice, TestDriveSlice } from "./test-drive"
import { createTesterSlice, TesterSlice } from "./tester"
import { createWorksSlice, WorksSlice } from "./works"
import { createMaintenanceReviewSlice, MaintenanceReviewSlice } from "./maintenance-review"
import { createTyresWheelsSlice, TyresWheelsSlice } from "./tyres-wheels"
import { createImagesSlice, ImagesSlice } from "./images"
import { createPrintOptionsSlice, PrintOptionsSlice } from "./print-options"
import { createStepNavigationSlice, StepNavigationSlice } from "./steps-navigation"
import { createManagerSlice, ManagerSlice } from "./manager"

export type FastServiceStore = MaintenancePlanSlice &
    StepNavigationSlice &
    ManagerSlice &
    EndPageSlice &
    InspectionWorkSlice &
    TestDriveSlice &
    TesterSlice &
    NavigationSlice &
    TechnicianSlice &
    WorksSlice &
    MaintenanceReviewSlice &
    ImagesSlice &
    PrintOptionsSlice &
    TyresWheelsSlice

export const fastServiceStore = create<FastServiceStore>()(
    devtools(
        persist(
            (set, get, ...a) => ({
                ...createStepNavigationSlice(set, get, ...a),
                ...createMaintenancePlanSlice(set, get, ...a),
                ...createManagerSlice(set, get, ...a),
                ...createEndPageSlice(set, get, ...a),
                ...createInspectionWorkSlice(set, get, ...a),
                ...createTestDriveSlice(set, get, ...a),
                ...createTesterSlice(set, get, ...a),
                ...createNavigationSlice(set, get, ...a),
                ...createTechnicianSlice(set, get, ...a),
                ...createWorksSlice(set, get, ...a),
                ...createMaintenanceReviewSlice(set, get, ...a),
                ...createTyresWheelsSlice(set, get, ...a),
                ...createImagesSlice(set, get, ...a),
                ...createPrintOptionsSlice(set, get, ...a),
            }),
            {
                name: "FastServiceStore",
                skipHydration: true,
                storage: createJSONStorage(() => zustandSessionStorage, { replacer, reviver }),
            }
        ),
        { name: "Fast Service Store", enabled: isDevtoolEnabled() }
    )
)

export const useFastServiceStore = <T>(selector: (state: FastServiceStore) => T): T => {
    return fastServiceStore(useShallow(selector))
}
