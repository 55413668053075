import {
    Box,
    Icon,
    Typography,
    Button,
    AccordionDetails,
    styled,
    Accordion,
    AccordionSummary,
    Table,
    TableRowData,
    TableCellData,
    CellContentPosition,
    TableColumnData,
} from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ModuleGroup, ActivatableModule, ModuleGroupId } from "@tm/models"
import { getModuleCompareRoute, getModuleGroupIconName, getModuleGroupTextId, renderRoute } from "@tm/utils"
import { SyntheticEvent, useState, FC } from "react"
import Morpheus from "@tm/morpheus"
import { getModuleTitleAndPackage } from "../helpers"

type ModuleAccordionProps = {
    moduleGroup: ModuleGroup
    activatingModule: boolean
    moduleCompareRoute?: string | undefined
    renderDemoButtons: (moduleGroupId: ModuleGroupId, module: ActivatableModule) => JSX.Element
}

const headerColumns: TableColumnData[] = [
    { header: "", gridSize: "minmax(15rem, auto)", minWidth: "15rem", alignContent: CellContentPosition.left, overflowCell: true },
    { header: "", gridSize: "1fr", alignContent: CellContentPosition.left, overflowCell: true },
    { header: "", gridSize: "auto", alignContent: CellContentPosition.right, overflowCell: true },
    { header: "", gridSize: "auto", alignContent: CellContentPosition.right, overflowCell: true },
]

const ModuleAccordion: FC<ModuleAccordionProps> = ({ moduleGroup, activatingModule, moduleCompareRoute, renderDemoButtons }) => {
    const { translateText, translate, languageId } = useLocalization()

    const [expanded, setExpanded] = useState(true)

    const moduleRoute = getModuleCompareRoute(moduleGroup.id)

    const handleCompareOpen = (e: SyntheticEvent<HTMLButtonElement> | undefined) => {
        e?.stopPropagation?.()

        const url = renderRoute(moduleCompareRoute!, { module: moduleRoute })
        Morpheus.showView("1", url)
    }

    function renderCollapsibleHeader() {
        const modulesQuantity: number = moduleGroup.modules.length
        const moduleGroupTextId = getModuleGroupTextId(moduleGroup.id, translateText)
        const moduleGroupIconName = getModuleGroupIconName(moduleGroup.id)

        return (
            <Box display="flex" justifyContent="space-between" alignItems="center" flex="1" marginLeft="0.5em">
                <Box display="flex" gap="0.5em">
                    <Icon height="1em" width="1em" name={moduleGroupIconName} />
                    <Typography variant="body2">{moduleGroupTextId}</Typography>
                </Box>
                {modulesQuantity > 1 && moduleCompareRoute && moduleRoute && <Button onClick={handleCompareOpen}>{translateText(1817)}</Button>}
            </Box>
        )
    }

    function renderButtonsCell(module: ActivatableModule) {
        return (
            <>
                <Box display="flex" justifyContent="center">
                    {module.explanationVideoUrl && (
                        <Button
                            href={module.explanationVideoUrl ?? ""}
                            target="_blank"
                            startIcon={<Icon name="video" />}
                            sx={{ marginLeft: "0.5em" }}
                        >
                            {translate(12841)}
                        </Button>
                    )}
                    <Button
                        disabled={!module.longDescriptionUrl || activatingModule}
                        href={module.longDescriptionUrl?.replace("{0}", languageId)}
                        target="_blank"
                        sx={{ marginLeft: "0.5em" }}
                    >
                        {translate(465)}
                    </Button>
                </Box>
            </>
        )
    }

    function renderIdCell(module: ActivatableModule) {
        const moduleTitle = getModuleTitleAndPackage(module, translateText)

        return <Typography>{moduleTitle}</Typography>
    }

    function renderIconCell(module: ActivatableModule) {
        const versionActive = module.moduleOptions?.demo?.active || module.moduleOptions?.full?.active

        return <Icon height="1.75em" width="1.75em" name={versionActive ? "unlock" : "lock"} />
    }

    function cellData(module: ActivatableModule): TableCellData[] {
        return [
            { displayValue: renderIdCell(module), id: "0", alignContent: CellContentPosition.left },
            { displayValue: renderIconCell(module), id: "1", alignContent: CellContentPosition.left },
            { displayValue: renderButtonsCell(module), id: "2", alignContent: CellContentPosition.right },
            {
                displayValue: renderDemoButtons(moduleGroup.id, module),
                id: "3",
                alignContent: CellContentPosition.center,
            },
        ]
    }

    const handleToggleExpand = () => setExpanded((prev) => !prev)

    const rows: TableRowData[] = moduleGroup.modules
        .filter((x) => x.display)
        .map((module, index) => ({
            cells: cellData(module),
            id: `${module.id}_${index}`,
        }))

    return (
        <StyledAccordion expanded={expanded} onChange={handleToggleExpand}>
            <StyledAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                {renderCollapsibleHeader()}
            </StyledAccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
                <Table rows={rows} columns={headerColumns} variant="normal" headerStyle="hidden" rowStyle="uniform" />
            </AccordionDetails>
        </StyledAccordion>
    )
}

const StyledAccordion = styled(Accordion)({
    backgroundColor: "transparent",
    boxShadow: "none",
    borderTop: "0",
    margin: 0,
    padding: 0,
    "& :before": {
        position: "unset",
    },

    "&:before": {
        display: "none",
    },
    "&.Mui-expanded": {
        margin: 0,
        minHeight: "unset",
    },
})

const StyledAccordionSummary = styled(AccordionSummary)({
    margin: 0,
    minHeight: "unset",
    padding: "0.5em 0",
    backgroundColor: "transparent",
    "&.Mui-expanded": {
        minHeight: "unset",
        margin: 0,
    },
    "& .MuiAccordionSummary-content": {
        margin: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: 0,
    },
})

export default ModuleAccordion
