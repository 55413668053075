import { useCallback, useMemo } from "react"
import { Article, ArticleInfoType, ErpInformationRequestItem, ErpSystemConfig, VoucherType, OE } from "@tm/models"
import { chunk, isArray } from "lodash"
import {
    mapArticleToAddCatalogPartListRequest,
    mapArticleToAddExternalPartListRequest,
    mapArticleToAddOePartListRequest,
    mapArticleToAddWholesalerPartListRequest,
    useDefaultOrderWarehouse,
} from "@tm/utils"
import { VoucherPart } from "@tm/data/vouchers/costEstimations"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { getErpInfos } from "../../../../../../erp/src/data"
import { useBasketParts } from "../../../../../../basket/src/hooks/basketState/useBasketParts"
import { useBasketQuantities, BasketInfos } from "../../../../../../basket/src/hooks/basketState/useBasketQuantities"
import { useDefaultErpSystem } from "../../../../../../parts/src/components/ListV2/hooks/useDefaultErpSystem"
import { ExtendedOrderVoucherItem } from "../../../../data/model"
import { mapOrderedItemToArticle, mapOrderedItemToPartToOePart, mapVoucherPartToArticle, mapVoucherPartToOePart } from "../../../../data/mapper"

async function getErpInformation(
    articles: Article[],
    erpSystemConfig?: ErpSystemConfig,
    telesalesCustomerNo?: string,
    vehicleId?: string,
    warehouseId?: string
) {
    // Call erp info
    const promises = chunk(articles, erpSystemConfig?.erpRequestArticleCount ?? Number.POSITIVE_INFINITY).map((articleList) => {
        return getErpInfos({
            items: articleList.map<ErpInformationRequestItem>((article) => ({
                itemId: article.id,
                wholesalerArticleNumber: article.traderArticleNo,
                dataSupplierArticleNumber: article.supplierArticleNo,
                dataSupplierId: article.supplier.id > 0 ? article.supplier.id : undefined,
                dataSupplierName: article.supplier.name !== "" ? article.supplier.name : undefined,
                productGroupId: article.productGroup.id > 0 ? article.productGroup.id : undefined,
                productGroupName: article.productGroup.name !== "" ? article.productGroup.name : undefined,
                articleDescription: article.description,
                quantityValue: article.quantity,
                vehicleId,
                warehouseId,
                selectedShipmentModeId: undefined,
                foundBySearchTerm: undefined,
                oeArticleOrigin: article.oeArticleOrigin,
            })),
            distributorId: erpSystemConfig?.id,
            telesalesCustomerNo,
        })
    })
    const responses = await Promise.all(promises)
    return responses.flatMap((response) => response.items ?? [])
}

export function useBasketButtonActions(
    workTaskId: string,
    voucherType: VoucherType,
    voucherParts?: VoucherPart[] | VoucherPart,
    orderedItems?: ExtendedOrderVoucherItem[] | ExtendedOrderVoucherItem,
    currencyCode?: string,
    costEstimationVehicleId?: string
) {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const erpSystemConfig = useDefaultErpSystem()
    const { getCatalogPartQuantity, getWholesalerPartQuantity, getOePartQuantity, getExternalPartQuantity } = useBasketQuantities(workTaskId)
    const { addCatalogPartList, addExternalPartList, addOePartList, addWholesalerPartList, removePartList } = useBasketParts()
    const { warehouseData } = useDefaultOrderWarehouse({ telesalesCustomerNo, distributorId: erpSystemConfig?.id }, enableServiceCalls)

    const [catalogArticlesToAdd, wholesalerArticlesToAdd, oeArticlesToAdd, externalArticlesToAdd, basketInfos] = useMemo(() => {
        let basketInfo: BasketInfos = {} // Only for one article
        const articlesFromCatalogParts: Partial<Article>[] = []
        const articlesFromWholesalerParts: Partial<Article>[] = []
        const articlesFromOeParts: Partial<OE.OePart>[] = []
        const articlesFromExternalParts: Partial<Article>[] = []

        if (voucherType === VoucherType.CostEstimation) {
            const checkVoucherParts = (voucherPart: VoucherPart) => {
                const { articleNumber, supplierId, productGroupId, productGroupName, description, wholesalerArticleNumber, articleInfoType } =
                    voucherPart.articleInformation
                if (articleInfoType === ArticleInfoType.TecdocArticle && articleNumber && supplierId && productGroupId) {
                    articlesFromCatalogParts.push(mapVoucherPartToArticle(voucherPart))
                    basketInfo = getCatalogPartQuantity(articleNumber, supplierId, productGroupId)
                }
                if (articleInfoType === ArticleInfoType.WholesalerArticle && wholesalerArticleNumber) {
                    articlesFromWholesalerParts.push(mapVoucherPartToArticle(voucherPart))
                    basketInfo = getWholesalerPartQuantity(wholesalerArticleNumber)
                }
                if (articleInfoType === ArticleInfoType.OeArticle && articleNumber) {
                    articlesFromOeParts.push(mapVoucherPartToOePart(voucherPart, currencyCode))
                    basketInfo = getOePartQuantity(articleNumber, supplierId, productGroupId, wholesalerArticleNumber)
                }
                if (articleInfoType === ArticleInfoType.ExternalArticle && articleNumber) {
                    articlesFromExternalParts.push(mapVoucherPartToArticle(voucherPart))
                    basketInfo = getExternalPartQuantity(articleNumber, productGroupName, description)
                }
            }
            if (voucherParts && isArray(voucherParts)) {
                voucherParts?.forEach(checkVoucherParts)
            } else if (voucherParts) {
                checkVoucherParts(voucherParts)
            }
        } else if (voucherType === VoucherType.Order) {
            const checkOrderedItems = (orderItem: ExtendedOrderVoucherItem) => {
                const { articleInfoType, articleInfo } = orderItem
                const {
                    dataSupplierArticleNumber,
                    dataSupplier,
                    productGroup,
                    productGroupId,
                    productGroupName,
                    description,
                    oeArticleNumber,
                    vehicleManufacturer,
                    wholesalerArticleNumber,
                    articleNumber,
                } = articleInfo || {}
                if (articleInfoType === ArticleInfoType.TecdocArticle && dataSupplierArticleNumber && dataSupplier && productGroup) {
                    articlesFromCatalogParts.push(mapOrderedItemToArticle(orderItem))
                    basketInfo = getCatalogPartQuantity(dataSupplierArticleNumber, dataSupplier.id, productGroup.id)
                }
                if (articleInfoType === ArticleInfoType.WholesalerArticle && wholesalerArticleNumber) {
                    articlesFromWholesalerParts.push(mapOrderedItemToArticle(orderItem))
                    basketInfo = getWholesalerPartQuantity(wholesalerArticleNumber)
                }
                if (articleInfoType === ArticleInfoType.OeArticle && oeArticleNumber) {
                    articlesFromOeParts.push(mapOrderedItemToPartToOePart(orderItem))
                    basketInfo = getOePartQuantity(oeArticleNumber, vehicleManufacturer?.id, productGroupId, wholesalerArticleNumber)
                }
                if (articleInfoType === ArticleInfoType.ExternalArticle && articleNumber) {
                    articlesFromExternalParts.push(mapOrderedItemToArticle(orderItem))
                    basketInfo = getExternalPartQuantity(articleNumber, productGroupName, description)
                }
            }
            if (orderedItems && isArray(orderedItems)) {
                orderedItems?.forEach(checkOrderedItems)
            } else if (orderedItems) {
                checkOrderedItems(orderedItems)
            }
        }
        return [
            articlesFromCatalogParts as Article[],
            articlesFromWholesalerParts as Article[],
            articlesFromOeParts as OE.OePart[],
            articlesFromExternalParts as Article[],
            basketInfo,
        ]
    }, [
        orderedItems,
        voucherParts,
        voucherType,
        currencyCode,
        getCatalogPartQuantity,
        getExternalPartQuantity,
        getOePartQuantity,
        getWholesalerPartQuantity,
    ])

    const vehicleId = useMemo(() => {
        if (orderedItems && !isArray(orderedItems)) {
            return orderedItems.vehicle.id
        }

        if (voucherParts && costEstimationVehicleId) {
            return costEstimationVehicleId
        }
    }, [costEstimationVehicleId, orderedItems, voucherParts])

    const removeFromBasket = useCallback(() => {
        if (basketInfos?.partIds) {
            removePartList({ workTaskId, partIds: basketInfos?.partIds, isExternalCall: true })
        }
    }, [basketInfos?.partIds, removePartList, workTaskId])

    const addToBasket = useCallback(async () => {
        if (catalogArticlesToAdd.length) {
            const erpInfos = await getErpInformation(
                catalogArticlesToAdd,
                erpSystemConfig,
                telesalesCustomerNo,
                vehicleId,
                warehouseData?.defaultWarehouse?.id
            )
            await addCatalogPartList(
                mapArticleToAddCatalogPartListRequest(
                    catalogArticlesToAdd,
                    workTaskId,
                    vehicleId,
                    undefined,
                    undefined,
                    undefined,
                    erpInfos,
                    warehouseData?.defaultWarehouse,
                    undefined,
                    erpSystemConfig?.id,
                    erpSystemConfig?.description
                )
            )
        }

        if (wholesalerArticlesToAdd.length) {
            const erpInfos = await getErpInformation(
                wholesalerArticlesToAdd,
                erpSystemConfig,
                telesalesCustomerNo,
                vehicleId,
                warehouseData?.defaultWarehouse?.id
            )
            await addWholesalerPartList(
                mapArticleToAddWholesalerPartListRequest(
                    wholesalerArticlesToAdd,
                    workTaskId,
                    vehicleId,
                    undefined,
                    undefined,
                    warehouseData?.defaultWarehouse,
                    undefined,
                    erpSystemConfig?.id,
                    erpSystemConfig?.description,
                    erpInfos
                )
            )
        }

        if (oeArticlesToAdd.length) {
            await addOePartList(mapArticleToAddOePartListRequest(oeArticlesToAdd, workTaskId, undefined, undefined))
        }

        if (externalArticlesToAdd.length) {
            await addExternalPartList(mapArticleToAddExternalPartListRequest(externalArticlesToAdd, workTaskId, undefined, undefined, undefined))
        }
    }, [
        addCatalogPartList,
        addExternalPartList,
        addOePartList,
        addWholesalerPartList,
        catalogArticlesToAdd,
        erpSystemConfig,
        externalArticlesToAdd,
        oeArticlesToAdd,
        telesalesCustomerNo,
        vehicleId,
        warehouseData?.defaultWarehouse,
        wholesalerArticlesToAdd,
        workTaskId,
    ])

    return { basketInfos, addToBasket, removeFromBasket }
}
