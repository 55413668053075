import { PropsWithChildren } from "react"
import { FiltersContext, useFiltersContext } from "../../../ContextProvider/Filters"
import { useUniversalPartsListParamsContext } from "../ListParams"
import { useFilters } from "./useFilters"

export function UniversalPartsFiltersProvider(props: PropsWithChildren<unknown>) {
    const params = useUniversalPartsListParamsContext()
    const filters = useFilters(params, true)
    return <FiltersContext.Provider value={filters}>{props.children}</FiltersContext.Provider>
}

export const useUniversalPartsFiltersContext = useFiltersContext
