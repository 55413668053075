import { useEffect, useMemo, useRef, useState } from "react"
import { useQuery } from "react-query"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { sortBy, uniq } from "lodash"
import { ListFilter } from "@tm/models"
import { CHECK_DELAY, FILTERS_QUERY } from "."
import { FiltersRequest, FiltersResponse, ProductGroupsBySearchTreeRequest } from "../../../../../../data/model/uni-parts"
import * as PartsRepository from "../../../../../../data/repositories/uni-parts"
import { mapDataSupplierFilterAndShowOnTop, mapProductGroupFilter } from "../../../../helpers"
import { UniversalPartsListParams } from "../../../../models"
import {
    ExtendedAssortmentAtom,
    FixedAttributeFilterQueriesAtom,
    SelectedAttributeFilterQueriesAtom,
    SelectedProductGroupIdsAtom,
    SelectedSupplierIdsAtom,
} from "../../../../states"
import { useFilterStorageKey } from "../../../../hooks/useFilterStorageKey"

export function useFiltersByNode(params: UniversalPartsListParams, isEnabled: boolean) {
    const requestTimerRef = useRef<number>()
    const requestDelay = useRef(0) // First request should be without any delay

    const { startParams, setNoResult } = params
    const storageKey = useFilterStorageKey(startParams)

    const [filtersRequest, setFiltersRequest] = useState<FiltersRequest>()
    const extendedAssortmentEnabled = useRecoilValue(ExtendedAssortmentAtom(storageKey))
    const selectedProductGroupIds = useRecoilValue(SelectedProductGroupIdsAtom(storageKey))
    const selectedSupplierIds = useRecoilValue(SelectedSupplierIdsAtom(storageKey))
    const selectedAttributeFilterQueries = useRecoilValue(SelectedAttributeFilterQueriesAtom(storageKey))
    const setFixedAttributeFilterQueries = useSetRecoilState(FixedAttributeFilterQueriesAtom(storageKey))
    const [loadedFilters, setLoadedFilters] = useState<FiltersResponse>()

    // First load only the product groups and the criterions given by the searchtree node
    const productGroupsRequest = useMemo<ProductGroupsBySearchTreeRequest | null>(() => {
        if (!isEnabled || startParams.type !== "uninode") {
            return null
        }

        return {
            treeId: startParams.treeId,
            nodeId: startParams.nodeId,
        }
    }, [isEnabled, params.startParams])

    const productGroupsQuery = useQuery({
        queryKey: [FILTERS_QUERY, productGroupsRequest],
        queryFn: async () => {
            const result = await PartsRepository.getProductGroupsBySearchTree(productGroupsRequest!)
            if (result.selectedCriteria) {
                setFixedAttributeFilterQueries(result.selectedCriteria.map((x) => x.query))
            }
            return {
                ...result,
                productGroupFilters: result.productGroupFilters.map((x) => ({ ...x, showOnTop: x.isTopPriority })),
            }
        },
        enabled: isEnabled && !!productGroupsRequest,
        keepPreviousData: true, // after the user has changed filter a new request will be triggered, but we want to keep showing the previous data while loading
        notifyOnChangeProps: "tracked", // only update when properties of the useQuery return value changed which are really used - enabled by default in v4
    })

    useEffect(
        function transferNoResultAfterProductGoups() {
            if (
                isEnabled &&
                !!productGroupsRequest &&
                !productGroupsQuery.isLoading &&
                !productGroupsQuery.isRefetching &&
                !productGroupsQuery.data?.productGroupFilters.length
            ) {
                setNoResult(true)
            }
        },
        [isEnabled, productGroupsRequest, productGroupsQuery.isLoading, productGroupsQuery.isRefetching, productGroupsQuery.data]
    )

    useEffect(
        function createFiltersRequest() {
            window.clearTimeout(requestTimerRef.current)

            if (!isEnabled || startParams.type !== "uninode" || !productGroupsQuery.data?.productGroupFilters.length) {
                setFiltersRequest(undefined)
                return
            }

            setNoResult(false)
            const previousData = productGroupsQuery.isPreviousData ? [] : productGroupsQuery.data.selectedCriteria.map((x) => x.query)
            const selectedCriteria = sortBy(
                uniq([...selectedAttributeFilterQueries, ...previousData])
            ).map((x) => ({ query: x } as ListFilter))

            // Set all product groups in request as selected, if no product group is selected
            const newRequest: FiltersRequest = {
                selectedProductGroups: productGroupsQuery.data.productGroupFilters.map((x) => ({
                    ...mapProductGroupFilter(x),
                    isSelected: selectedProductGroupIds.length === 0 ? true : selectedProductGroupIds.includes(x.id),
                })),
                selectedSuppliers:
                    loadedFilters?.supplierFilters.filter((x) => selectedSupplierIds.includes(x.id)).map(mapDataSupplierFilterAndShowOnTop) ?? [],
                selectedCriteria,
                extendedAssortment: !!params.extendedAssortment,
            }

            // Zeitverzögerter Request
            requestTimerRef.current = window.setTimeout(() => {
                setFiltersRequest(newRequest)
            }, requestDelay.current)

            // Any further request will be delayed (to prevent multiple requests when state changes quickly)
            requestDelay.current = CHECK_DELAY
        },
        [
            isEnabled,
            startParams.type,
            params.extendedAssortment,
            extendedAssortmentEnabled,
            loadedFilters,
            productGroupsQuery.data,
            selectedProductGroupIds,
            selectedSupplierIds,
            selectedAttributeFilterQueries,
        ]
    )

    return useQuery({
        queryKey: [FILTERS_QUERY, filtersRequest] as [string, FiltersRequest | undefined],
        queryFn: async ({ queryKey: [, requestFromKey] }) => {
            if (requestFromKey) {
                const response = await PartsRepository.getFiltersBySearchTree(requestFromKey)
                setLoadedFilters(response)
                if (!response.criterionFilterGroups.length && !response.productGroupFilters.length && !response.supplierFilters.length) {
                    setNoResult(true)
                }
                return response
            }
        },
        enabled: isEnabled && !!filtersRequest,
        keepPreviousData: true, // after the user has changed filter a new request will be triggered, but we want to keep showing the previous data while loading
        notifyOnChangeProps: "tracked", // only update when properties of the useQuery return value changed which are really used - enabled by default in v4
    })
}
