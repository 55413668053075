import { DefaultShareOptions, Share, ShareOption, ShareType } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { useEffect, useMemo, useState } from "react"
import { ERIKContentService } from "@tm/data"
import { useFastServiceStore } from "../../../data"
import { usePdfDownload } from "../../../helpers/hooks/usePdfDownload"
import { useCarteligenceLinkShare } from "../../../../../notifications/src/data/hooks"
import { PrintOptionsPreview } from "./PrintOptionsPreview"
import { printMaintenance } from "../../../helpers"
import { useEmailTemplate } from "../../../hooks/useEmailTemplate"

export function FastServiceFileShare() {
    const { translateText } = useLocalization()

    const {
        vehicleFields: { plateNumber, vin },
        pdfUrlLoading,
        pdfExpiresAt,
        pdfUrlError,
        printOptions,
        pdfUrlFromState,
    } = useFastServiceStore((state) => ({
        vehicleFields: state.maintenancePlan.vehicleFields,
        pdfUrlLoading: state.printLoading,
        pdfExpiresAt: state.pdfExpiresAt,
        printOptions: state.printOptions,
        pdfUrlError: state.printError,
        pdfUrlFromState: state.pdfUrl,
    }))

    const { userSettings, userContext } = useUser()
    const customer = useWorkTask()?.workTask?.customer

    const { sendToCarteligence, disabled: carteligenceDisabled } = useCarteligenceLinkShare()
    const { sendEmail } = ERIKContentService.useEmailShare()
    const { downloadPdf } = usePdfDownload()
    const emailTemplate = useEmailTemplate()

    const [pdfUrl, setPdfUrl] = useState("")

    useEffect(() => {
        if (!pdfUrlFromState || !pdfExpiresAt || Date.now() >= pdfExpiresAt) {
            setPdfUrl("")
        } else {
            setPdfUrl(pdfUrlFromState)
        }
    }, [pdfUrlFromState])

    const handlePrint = () => {
        if (!pdfExpiresAt || Date.now() >= pdfExpiresAt) {
            printMaintenance(translateText, printOptions, userContext.account?.username ?? "", userSettings, customer)
        }
    }

    const shareOptionList: ShareOption[] = useMemo(
        () => [
            {
                ...DefaultShareOptions.WhatsApp,
                contentOverride: (content: string) => {
                    return `${translateText(13782)}: ${content}`
                },
            },
            {
                ...DefaultShareOptions.Email,
                type: ShareType.Email,
                handler: sendEmail,
                subject: translateText(13782) ?? "",
                initialEmails: [customer?.email ?? ""],
                bccEmails: [userSettings?.repairShop?.contactInfo?.email ?? ""],
                contentOverride: () => emailTemplate,
            },
            {
                ...DefaultShareOptions.Carteligence,
                disabled: carteligenceDisabled,
                handler: async (content: string) => {
                    return sendToCarteligence(translateText(13782), content)
                },
            },
            {
                ...DefaultShareOptions.Download,
                handler: (content: string) => {
                    return downloadPdf(content, `${translateText(12790)} - ${plateNumber || vin || ""}`)
                },
            },
        ],
        [downloadPdf, sendToCarteligence, carteligenceDisabled, userSettings, customer, translateText, sendEmail, emailTemplate]
    )

    const additionalContent = useMemo(() => {
        return <PrintOptionsPreview printOptions={printOptions} />
    }, [printOptions])

    return (
        <Share
            shareOptions={shareOptionList}
            url={pdfUrl}
            errorMessage={pdfUrlError ? translateText(13301) : ""}
            onShareCompleted={() => {}}
            generateLink={handlePrint}
            isLoading={pdfUrlLoading}
            additionalContent={additionalContent}
        />
    )
}
