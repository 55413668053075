import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { useArticlesContext } from "../Articles/ArticlesContext"
import { ensureContextAvailability } from "../../helpers"
import { useWatchListData } from "./useWatchListData"
import { useListOptions } from "../../hooks"
import { WatchListData } from "../../models"

export const WatchListContext = createContext<WatchListData | undefined>(undefined)

export function WatchListProvider(props: PropsWithChildren<{ isEnabled: boolean }>) {
    const options = useListOptions()

    const { articles } = useArticlesContext()
    const watchListData = useWatchListData(articles, !!options.hasWatchList && props.isEnabled)

    return <WatchListContext.Provider value={watchListData}>{props.children}</WatchListContext.Provider>
}

export function useWatchListContext(): WatchListData {
    const context = useContext(WatchListContext)
    return ensureContextAvailability("WatchListContext", context)
}
