import { useEffect, useCallback, useMemo, useRef } from "react"
import { Article, CustomArticle } from "@tm/models"
import { Box, Tip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ActiveButtons, Genart, RepairTime, Work, WorkId } from "../../../data/models"
import { Panels, TitleHeader } from "../../_shared"
import { maintenanceReviewActions, worksActions, useFastServiceStore, WorkContext } from "../../../data"
import { getTranslationByLocation, useIsFirstRender } from "../../../helpers"
import WorkCard from "../../_shared/workCard"
import { isTouchDevice } from "../../../helpers/isTouchDevice"

type Props = {
    step: string
    setCompleted: (isCompleted: boolean) => void
    workWhiteList?: string[]
    disableAutomaticTicking?: boolean
}

export default function WorksList({ step, setCompleted, workWhiteList, disableAutomaticTicking }: Props) {
    const isFirstMount = useIsFirstRender()
    const { translateText } = useLocalization()

    const { nextPanel, workData, selectedItems } = useFastServiceStore((state) => ({
        nextPanel: state.worksState.nextPanel,
        workData: state.worksState.works[step],
        selectedItems: state.worksState.selectedItems,
    }))

    const filteredWorkData = useMemo(
        () => (workWhiteList ? workData?.filter((w) => workWhiteList.includes(w.providerId)) : workData),
        [workData, workWhiteList]
    )
    const oeArticles = useRef<Genart[]>([])
    const touchDevice = isTouchDevice()
    const selectedItemForStep = selectedItems[step] ?? []

    useEffect(() => {
        const isComplete =
            Object.values(selectedItemForStep).length === workData?.length &&
            Object.values(selectedItemForStep).every((x) => x.selectedStatusButton !== "none")
        setCompleted?.(isComplete)
    }, [selectedItems])

    useEffect(() => {
        Object.entries(selectedItemForStep).forEach(([key, value]) => {
            const oeArticle = filteredWorkData.find((x) => x.label === key)
            const oeArticleGenart = oeArticle?.genArts.find(
                (genArt) =>
                    genArt.isMandatory &&
                    genArt.fsArticle &&
                    genArt.fsArticle.oeId &&
                    !genArt.fsArticle.supplierId &&
                    !genArt.fsArticle.supplierArtNr &&
                    !oeArticles.current.some((y) => y.label === genArt.label)
            )
            if (oeArticleGenart && oeArticle) {
                handleGenArtSelection(oeArticleGenart, oeArticle.label)
                oeArticles.current = [...oeArticles.current, oeArticleGenart]
            }
        })

        if (isFirstMount) {
            return
        }

        maintenanceReviewActions.sendItemsToMaintenanceReview(getTranslationByLocation(step), selectedItemForStep)
    }, [selectedItemForStep])

    const getActivePanel = useCallback((work: Work) => work.label === nextPanel?.label, [selectedItemForStep])

    function handleGenArtSelection(item: Genart, path: string) {
        worksActions.updateSelectedGenarts(item, path, step)
    }

    function handleRepairTimeSelection(item: RepairTime, path: string) {
        worksActions.updateSelectedRepairtimes(item, path, step)
    }

    function handleLubricantSelection(item: string, path: string) {
        worksActions.updateSelectedLubricant(item, path, step)
    }

    function handleLubricantSalvation(item: string, path: string) {
        worksActions.updateSavedLubricant(item, path, step)
    }

    function handleStatusButtonSelection(buttonType: ActiveButtons, path: string, isLastWork?: boolean) {
        worksActions.updateWorkField({ key: "selectedStatusButton", value: buttonType, isLastWork }, path, step)
    }

    function handleComment(comment: string, path: string) {
        worksActions.updateWorkField({ key: "comment", value: comment }, path, step)
    }

    function handleSafetyStatus(buttonType: number, path: string) {
        worksActions.updateWorkField({ key: "safetyStatus", value: buttonType }, path, step)
    }

    function handleQuantityChange(articleId: string, quantity: number, path: string) {
        worksActions.updateArticleQuantity(path, step, quantity, articleId)
    }

    function handleOeArticleChange(fsArticleId: string, quantity: number, price: number, designation: string, path: string, oeNumber: string) {
        worksActions.updateOeArticle(path, step, quantity, price, designation, fsArticleId, oeNumber)
    }

    function handleArticleReplace(articleId: string, newArticle: Article, path: string, oeReplacement?: boolean) {
        worksActions.replaceArticle(path, step, articleId, newArticle, oeReplacement)
    }

    function handleOnConsumableReplace(consumableId: string, newConsumableArticle: CustomArticle) {
        worksActions.replaceConsumable(step, consumableId, newConsumableArticle)
    }

    function handleGeneralStatusSet(buttonId: ActiveButtons) {
        filteredWorkData?.forEach((work, index) => {
            const mandatoryRepair = work.repairTimes.filter((r) => r.isMandatory)?.length
            const mandatoryConsumable = work.consumables.filter((r) => r.isMandatory)?.length
            const mandatoryGenArts = work.genArts.filter((r) => r.isMandatory)?.length
            const mandatoryFirstAidKit = work.providerId === WorkId.FirstAidKit

            const isLastWork = index === filteredWorkData.length - 1

            if (buttonId === "checkButton" && (mandatoryRepair || mandatoryConsumable || mandatoryGenArts || mandatoryFirstAidKit)) {
                if (isLastWork) {
                    handleStatusButtonSelection(buttonId, work.label, isLastWork)
                }
                return
            }

            handleStatusButtonSelection(buttonId, work.label)
        })
    }

    const renderPanels = (work: Work, idx: number) => {
        return (
            <WorkContext.Provider value={{ work }}>
                <Panels
                    key={idx}
                    item={work}
                    onSelectGenArt={handleGenArtSelection}
                    onSelectRepairTime={handleRepairTimeSelection}
                    onSelectLubricant={handleLubricantSelection}
                    onSaveLubricant={handleLubricantSalvation}
                    selectedItems={selectedItemForStep}
                    onStatusButtonClick={handleStatusButtonSelection}
                    onSaveComment={handleComment}
                    onSafetyStatus={handleSafetyStatus}
                    activePanel={getActivePanel(work)}
                    onQuantityChange={handleQuantityChange}
                    onOeArticleChange={handleOeArticleChange}
                    onArticleReplace={handleArticleReplace}
                    onConsumableReplace={handleOnConsumableReplace}
                />
            </WorkContext.Provider>
        )
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {!disableAutomaticTicking && (
                <WorkCard sx={{ marginBottom: "2em" }}>
                    <TitleHeader title={translateText(1205)} onStatusButtonClick={handleGeneralStatusSet} selectedStatusButton="none">
                        <Tip text={translateText(13690)} enableTipOnTouchDevice={touchDevice} />
                    </TitleHeader>
                </WorkCard>
            )}
            <Box sx={{ paddingX: disableAutomaticTicking ? "0" : "0.5em", width: "100%", flex: "1" }}>{filteredWorkData?.map(renderPanels)}</Box>
        </Box>
    )
}
