import { useLocalization } from "@tm/localization"
import { Omit } from "@tm/utils"
import { Text } from "@tm/controls"
import { ArticleAttributes, ArticleAttribute, ModificationState, AttributeComparisonModel } from "@tm/models"
import { Box, Icon } from "@tm/components"
import ArticleAttributesComponent from "./attributes"

export type MultipleValueAttribute = Omit<ArticleAttribute, keyof AttributeValue> & {
    key: string
    values: Array<ArticleAttribute>
    unit?: string
}

type AttributeValue = {
    key: string
    value: string
    unit: string
    modificationState: ModificationState
    highlight: boolean
    alternatives: Array<ArticleAttribute>
    isOptimized: boolean
}

type Props = {
    isCompact?: boolean
    isOeInformationItem?: boolean
    attributes: Array<ArticleAttributes>
    selected: Array<string>
    vehicleEngineCode?: string
    ignoreAttributeKey?: boolean
    vehicleRecordsComparisons: Array<AttributeComparisonModel>
    productGroupId: number
    canFilterArticleAttributes: boolean
    onSelect?(attribute: ArticleAttribute): void
    toggleMoreAttributes?(): void
}

export default function ArticleAttributeGroupComponent(props: Props) {
    const { translateText } = useLocalization()
    const {
        selected,
        ignoreAttributeKey,
        vehicleEngineCode,
        vehicleRecordsComparisons,
        productGroupId,
        canFilterArticleAttributes,
        isCompact,
        isOeInformationItem,
        attributes,
    } = props

    function renderAttributes(attributes: Array<ArticleAttribute>, className: string, showMore?: boolean) {
        if (!attributes.length) {
            return
        }

        return (
            <ArticleAttributesComponent
                attributes={attributes}
                canFilterArticleAttributes={canFilterArticleAttributes}
                className={className}
                productGroupId={productGroupId}
                selected={selected}
                vehicleRecordsComparisons={vehicleRecordsComparisons}
                ignoreAttributeKey={ignoreAttributeKey}
                isCompact={isCompact}
                showMore={showMore}
                vehicleEngineCode={vehicleEngineCode}
                onSelect={props.onSelect}
                toggleMoreAttributes={props.toggleMoreAttributes}
            />
        )
    }

    function renderAttributeGroup(attributes: ArticleAttributes, idx: number) {
        const minorAttributes = attributes.vehicleAttributes.concat(attributes.articleAttributes)

        return (
            <div className="article__attribute-group" key={idx}>
                <div className="article__attribute-group__description">
                    <Text size="s" title={`${idx + 1}. ${translateText(610)}`}>
                        {idx + 1}. <Icon name="car" />
                    </Text>
                </div>
                <div className="article__attribute-group__attributes">
                    {renderAttributes(attributes.topAttributes, "article__attributes--major", !!minorAttributes.length)}
                    {renderAttributes(minorAttributes, "article__attributes--minor")}
                </div>
            </div>
        )
    }

    if (!attributes?.length) {
        return null
    }

    if (attributes.length === 1) {
        const minorAttributes = attributes[0].vehicleAttributes.concat(attributes[0].articleAttributes).concat(attributes[0].partsListAttributes)
        if (isOeInformationItem) {
            return (
                <Box display="flex" flexDirection="column" flex="1" mr=".5em">
                    <Box display="flex" mb=".25em" alignItems="center">
                        {!!attributes[0].topAttributes?.length && <Icon name="article" sx={{ width: "3em" }} />}
                        {renderAttributes(attributes[0].topAttributes, "article__attributes--major", !!minorAttributes.length)}
                    </Box>
                    <Box display="flex" alignItems="center">
                        {!!minorAttributes?.length && <Icon name="car" sx={{ width: "3em" }} />}
                        {renderAttributes(minorAttributes, "article__attributes--major")}
                    </Box>
                </Box>
            )
        }

        return (
            <>
                {renderAttributes(attributes[0].topAttributes, "article__attributes--major", !!minorAttributes.length)}
                {renderAttributes(minorAttributes, "article__attributes--minor")}
            </>
        )
    }

    return <>{attributes.map(renderAttributeGroup)}</>
}
