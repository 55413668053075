import { useEffect } from "react"
import { atom, useSetRecoilState } from "recoil"
import { useWorkTaskBasketState } from "./useWorkTaskBasketState"
import { BasketErp, BasketOrderGroup, WorkTaskBasketCalculationInfo } from "../../models"
import { useCentralOrderWorkTasks } from "../../data/hooks/centralOrder/useCentralOrderWorkTasks"

export const basketPartsLoadingIds = atom<string[]>({ key: "basket_central_order_loading_ids", default: [] })
export const basketOrderGroups = atom<Record<string, BasketOrderGroup[]>>({ key: "basket_central_order_orderGroups", default: {} })
export const basketErp = atom<Record<string, BasketErp>>({ key: "basket_central_order_erp", default: {} })
export const basketCalculation = atom<Record<string, WorkTaskBasketCalculationInfo>>({
    key: "basket_central_order_calculation",
    default: {},
})

export function useCentralOrderBasketStateByWorkTask(workTaskId: string) {
    const workTaskBasketState = useWorkTaskBasketState(workTaskId)
    const { workTaskBasketLoading, erp, basket, workTaskBasketCalculation, workTaskBasketCalculationLoading, workTaskBasketCalculationWithError } =
        workTaskBasketState
    const { workTasks } = useCentralOrderWorkTasks().state
    const setBasketLoadingIds = useSetRecoilState(basketPartsLoadingIds)
    const setBasketOrderGroups = useSetRecoilState(basketOrderGroups)
    const setBasketErp = useSetRecoilState(basketErp)
    const setBasketCalculation = useSetRecoilState(basketCalculation)

    useEffect(() => {
        setBasketLoadingIds((prev) => {
            if (workTaskBasketLoading) {
                if (!prev.includes(workTaskId)) {
                    return [...prev, workTaskId]
                }
                return prev
            }
            return prev.filter((id) => id !== workTaskId)
        })
    }, [workTaskId, setBasketLoadingIds, workTaskBasketLoading])

    useEffect(() => {
        setBasketErp((prev) => {
            if (!erp) {
                return prev
            }
            // removes already ordered basketErps to prevent accumulation of basketErps when not reloading
            const filteredBasketErps = Object.entries(prev)
                .filter((p) => workTasks.some((w) => w.workTask.workTaskId === p[0]))
                .reduce<Record<string, BasketErp>>((record, [key, value]) => {
                    record[key] = value
                    return record
                }, {})
            return {
                ...filteredBasketErps,
                [workTaskId]: erp,
            }
        })
    }, [workTaskId, setBasketErp, erp, workTasks])

    useEffect(() => {
        setBasketOrderGroups((prev) => {
            if (!basket.state.basketOrderGroups) {
                return prev
            }
            return { ...prev, [workTaskId]: basket.state.basketOrderGroups }
        })
    }, [setBasketOrderGroups, basket.state.basketOrderGroups, workTaskId])

    useEffect(() => {
        setBasketCalculation((prev) => {
            if (!workTaskBasketCalculation) {
                return prev
            }
            return { ...prev, [workTaskId]: { workTaskBasketCalculation, workTaskBasketCalculationLoading, workTaskBasketCalculationWithError } }
        })
    }, [setBasketCalculation, workTaskBasketCalculation, workTaskBasketCalculationLoading, workTaskBasketCalculationWithError, workTaskId])

    return workTaskBasketState
}
