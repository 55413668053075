import { CustomWork, RecordCustomWorks } from "../data/models"

export function convertCustomWorks(panels: Array<CustomWork>) {
    let customWorkPanels: RecordCustomWorks = {}
    customWorkPanels = panels.reduce((record, panel) => {
        if (record[panel.location]) {
            record[panel.location].push({
                id: panel.id,
                providerId: panel.providerId,
                title: panel.title,
                initiallyClosed: panel.initiallyClosed,
                isCompleted: panel.isCompleted,
                panelState: panel.panelState,
                location: panel.location,
            })
        } else {
            // eslint-disable-next-line no-param-reassign
            record[panel.location] = [
                {
                    id: panel.id,
                    providerId: panel.providerId,
                    title: panel.title,
                    initiallyClosed: panel.initiallyClosed,
                    isCompleted: panel.isCompleted,
                    panelState: panel.panelState,
                    location: panel.location,
                },
            ]
        }
        return record
    }, customWorkPanels)

    return customWorkPanels
}
