import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { ArticleErpInfo, GetArticleListByMatchCodeRequest, TyreArticle } from "@tm/models"
import { useAvailabilityStatus, useWorkTaskId } from "@tm/context-distribution"
import { getArticleListByMatchCode } from "@tm/data/tyres"
import { BaseArticleData } from "../../../ListV2/models"
import { useDefaultErpSystem } from "../../../ListV2/hooks"
import { AvailabilityFilterType } from "../../../../business"
import { filterArticlesByAvailability } from "../../helpers/filterArticlesByAvailability"

const InitialLoading = 1
export function useTyresList(
    request: GetArticleListByMatchCodeRequest,
    selectedAvailability: AvailabilityFilterType,
    handleAutoNextPage: () => void,
    erpInfos: ArticleErpInfo[]
): BaseArticleData {
    const workTaskId = useWorkTaskId()
    const [articles, setArticles] = useState<TyreArticle[]>([])
    const [filteredArticles, setFilteredArticles] = useState<TyreArticle[]>([])
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()
    const defaultErpSystemId = useDefaultErpSystem()?.id
    const availabilityFilterChecked = selectedAvailability === AvailabilityFilterType.Primary
    const secondAvailabilityFilterChecked = selectedAvailability === AvailabilityFilterType.Secondary

    const fetchTyresArticles = () => {
        return getArticleListByMatchCode(request)
            .then((response) => {
                setArticles((prevArticles) => [...prevArticles, ...response.uniArticles])
                return response.uniArticles
            })
            .catch((error) => {
                if (error.message.includes("404") || error.response.status === 404) {
                    setFilteredArticles([])
                }
            })
    }

    const tyresArticlesQuery = useQuery({
        queryKey: ["tyresArticlesList", workTaskId, request, selectedAvailability],
        queryFn: () => fetchTyresArticles(),
        enabled: !!request,
    })

    const initialize = () => {
        setArticles([])
        setFilteredArticles([])
        const scrollableElement = document.querySelector(".scrollbar__container__tyres") as HTMLElement
        if (scrollableElement) {
            scrollableElement.scrollTop = 0
        }
    }

    const applyFilter = () => {
        if (articles.length) {
            if (selectedAvailability && defaultErpSystemId && availabilityStatusIdsToShow) {
                const filteredData = filterArticlesByAvailability(
                    articles,
                    erpInfos,
                    defaultErpSystemId,
                    availabilityFilterChecked,
                    secondAvailabilityFilterChecked,
                    availabilityStatusIdsToShow,
                    availabilityStatusIdsToShowSecondary
                )
                setFilteredArticles(filteredData)
                if (filteredData.length < request.pageSize) {
                    handleAutoNextPage()
                }
            } else {
                setFilteredArticles(articles)
            }
        }
    }

    useEffect(() => {
        if (request.pageIndex === 1) {
            initialize()
        }
    }, [request])

    useEffect(() => {
        applyFilter()
    }, [articles, selectedAvailability, request])

    const isLoaded = tyresArticlesQuery.isSuccess || tyresArticlesQuery.isError

    return {
        requestStatus: tyresArticlesQuery.status,
        isLoading:
            request.pageIndex === InitialLoading &&
            ((!!request && tyresArticlesQuery.isIdle && tyresArticlesQuery.isSuccess) || tyresArticlesQuery.isLoading),
        isLoaded,
        isSuccess: tyresArticlesQuery.isSuccess,
        isFailed: isLoaded && !filteredArticles.length,
        articles,
        displayArticles: filteredArticles,
        articleCount: filteredArticles.length,
        pageCount: request.pageIndex,
        hasNextPage: false,
        isFetchingNextPage: false,
        productGroupIds: [],
        supplierIds: [],
        loadNextPage: () => {},
    }
}
