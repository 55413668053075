import { Stack } from "@tm/components"
import { ArticleErpInfo } from "@tm/models"
import { useState } from "react"
import { ModalModuleHeader } from "../components/ModuleHeader/ModalModuleHeader"
import { ComposerComponent, ContextComposer } from "../ContextProvider"
import { ActiveVehicleDataProvidersProvider } from "../ContextProvider/ActiveVehicleDataProviders"
import { ArticleListActionsProvider } from "../ContextProvider/ArticleListActions"
import { BasketQuantitiesProvider } from "../ContextProvider/BasketQuantities"
import { ErpInfosProvider } from "../ContextProvider/ErpInfos"
import { ListOptionsProvider } from "../ContextProvider/ListOptions"
import { NotesProvider } from "../ContextProvider/Notes"
import { PreviouslyOrderedArticlesProvider } from "../ContextProvider/PreviouslyOrderedArticles"
import { ProductGroupRepairTimesProvider } from "../ContextProvider/ProductGroupRepairTimes"
import { ProductGroupTopicIdsProvider } from "../ContextProvider/ProductGroupTopicIds"
import { SupplierLogosProvider } from "../ContextProvider/SupplierLogos"
import { TradeReferenceProvider } from "../ContextProvider/TradeReferences"
import { WatchListProvider } from "../ContextProvider/WatchList"
import { ProductGroupsArticlesProvider } from "./ContextProvider/Articles"
import { ProductGroupsFiltersProvider } from "./ContextProvider/Filters"
import { ProductGroupsListParamsProvider } from "./ContextProvider/ListParams"
import ProductGroupsList from "./ProductGroupsList"

function ProductGroupsComponent() {
    return (
        <Stack overflow="hidden">
            <ModalModuleHeader />
            <ProductGroupsList />
        </Stack>
    )
}

export function ProductGroups() {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [ProductGroupsListParamsProvider],
        [ProductGroupsFiltersProvider],
        [ProductGroupsArticlesProvider, { setHasRendered: setHasListRendered, erpInfos }],
        [ProductGroupTopicIdsProvider, { isEnabled: hasListRendered }],
        [SupplierLogosProvider, { isEnabled: hasListRendered }],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [ErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: false }],
        [WatchListProvider, { isEnabled: hasListRendered }],
        [PreviouslyOrderedArticlesProvider, { isEnabled: hasListRendered }],
        [ListOptionsProvider],
        [ArticleListActionsProvider],
    ]
    return (
        <ContextComposer components={components}>
            <ProductGroupsComponent />
        </ContextComposer>
    )
}
