import { Container } from "@tm/nexus"
import { ModuleGroupId, ModuleId, RegisteredModels, VehicleType, ViewStateContainer, channel } from "@tm/models"
import { useQuery, useQueryClient } from "react-query"
import { clone } from "@tm/utils"

type HideDemoBanner = Partial<Record<ModuleGroupId, Partial<Record<ModuleId, Partial<Record<VehicleType, boolean>>>>>> | undefined
const KEY = "hide_demo_banner"

export function useHideDemoBanner(selectedModuleGroupId: ModuleGroupId, selectedModuleId?: ModuleId, vehicleType?: VehicleType) {
    const queryClient = useQueryClient()

    const {
        data: hideModulesBanner,
        isLoading,
        error,
    } = useQuery<HideDemoBanner>(
        KEY,
        async () => {
            const container: ViewStateContainer = Container.getInstance(RegisteredModels.ViewState)
            const data = await container.action("loadViewState")("HIDE_DEMO_BANNER")
            return data.value
        },
        { staleTime: Infinity, cacheTime: Infinity, enabled: !!selectedModuleGroupId && !!selectedModuleId && !!vehicleType }
    )

    const handleHideBanner = () => {
        if (!selectedModuleGroupId || !selectedModuleId || !vehicleType) {
            return
        }

        const clonedHideModulesBanner = hideModulesBanner ? clone(hideModulesBanner) : {}
        if (!clonedHideModulesBanner[selectedModuleGroupId]) {
            clonedHideModulesBanner[selectedModuleGroupId] = {}
        }

        if (!clonedHideModulesBanner[selectedModuleGroupId]![selectedModuleId]) {
            clonedHideModulesBanner[selectedModuleGroupId]![selectedModuleId] = {}
        }

        clonedHideModulesBanner[selectedModuleGroupId]![selectedModuleId]![vehicleType] = true

        queryClient.setQueryData(KEY, clonedHideModulesBanner)

        const container: ViewStateContainer = Container.getInstance(RegisteredModels.ViewState)

        container
            .action("saveViewState")({ key: "HIDE_DEMO_BANNER", value: clonedHideModulesBanner })
            .catch(() => {})

        setTimeout(() => {
            channel("GLOBAL").publish("MVC/RESIZE", { moduleMode: selectedModuleId })
        }, 20)
    }

    const hideBanner = (!!selectedModuleId && !!vehicleType && hideModulesBanner?.[selectedModuleGroupId]?.[selectedModuleId]?.[vehicleType]) || false

    return {
        handleHideBanner,
        hideBanner,
        hideModulesBanner,
    }
}
