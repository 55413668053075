import { EFilterNames } from "@tm/models"
import { IListFilters } from "../../components/list/business"
import { FilterType } from "../enums"

export const getEnumByPath = (path?: IListFilters) => {
    switch (path) {
        case EFilterNames.season: 
            return FilterType.Season
        case EFilterNames.loadIndex: 
            return FilterType.LoadIndex
        case EFilterNames.manufacturer: 
            return FilterType.Manufacturer
        case EFilterNames.oeIdentifier: 
            return FilterType.OeIdentifier
        case EFilterNames.extras: 
            return FilterType.Extras
        case EFilterNames.speedIndex: 
            return FilterType.SpeedIndex
        default: 
            return FilterType.None
    }
}