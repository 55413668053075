import { useLocalization } from "@tm/localization"
import { useState } from "react"
import { Box, Button, Icon, styled } from "@tm/components"
import CheckToken from "./CheckToken"
import { IncludedAdditionalWorks, MultiAppendix } from "../../_shared"
import { FastServiceSelection, SelectionKeys } from "../../../data/models"
import { maintenancePlanActions, useFastServiceStore } from "../../../data"
import WorkItem from "./WorkItem"
import { submitIncludeAdditionalWorks, submitSelection } from "../../../helpers/maintenancePlan"

type Props = {
    title: string
    icon: string
    selectionKey: SelectionKeys
    works: FastServiceSelection[]
    onCheckOpened?: (checkOffsetTop: number) => void
}

export default function WorksCheck(props: Props) {
    const { works, selectionKey, title, icon, onCheckOpened } = props

    const { userSelections, selectionsToSend, calculateIncludedAdditionalWorks } = useFastServiceStore((state) => ({
        userSelections: state.maintenancePlan.userSelections,
        selectionsToSend: state.maintenancePlan.selectionsToSend,
        calculateIncludedAdditionalWorks: state.maintenancePlan.vehicleFields.calculateIncludedAdditionalWorks,
    }))

    const { translateText } = useLocalization()
    const [isCompleted, setIsCompleted] = useState(false)

    const handleSelection = (element: string[], selectionId: string, selectionKey: SelectionKeys) => {
        submitSelection({ items: element, selectionId }, undefined, selectionKey)
    }

    const storeSelectionsBySelection = (id: string, selectionId: string) => {
        maintenancePlanActions.setUpdateSelections(selectionKey, id, selectionId)
    }

    const renderMultiHeader = (items: FastServiceSelection[]) => {
        return <MultiAppendix items={items} />
    }

    const handleAdditionalWorkInclude = (fieldType: string, value: any) => {
        if (typeof value === "boolean") {
            submitIncludeAdditionalWorks(value)
            maintenancePlanActions.updateVehicleField(fieldType, value)
        }
    }

    const renderHeaderAdditionalWorksContent = () => {
        if (selectionKey === SelectionKeys.AdditionalWorks) {
            return (
                <div onClick={(e: any) => e.stopPropagation()}>
                    <IncludedAdditionalWorks
                        name="calculateIncludedAdditionalWorks"
                        label={translateText(13371)}
                        icon="partsindicator"
                        value={calculateIncludedAdditionalWorks}
                        handleChange={handleAdditionalWorkInclude}
                    />
                </div>
            )
        }

        return null
    }

    const renderWork = (workSelectionItem: FastServiceSelection, idx: number) => {
        return (
            <WorkItem
                key={idx}
                workSelectionItem={workSelectionItem}
                selectionsToSend={userSelections?.[selectionKey]}
                storeSelections={(id: string, selectionId: string) => storeSelectionsBySelection(id, selectionId)}
                handleSelection={handleSelection}
                selectionKey={selectionKey}
            />
        )
    }

    const handleMultiSelection = () => {
        if (selectionsToSend) {
            submitSelection(undefined, selectionsToSend[selectionKey], selectionKey)
        }

        setIsCompleted((prev) => !prev)
    }

    return (
        <CheckToken
            onCheckOpened={onCheckOpened}
            icon={icon}
            name={title}
            isCompleted={isCompleted}
            headerData={renderMultiHeader(works.filter((x) => x.items.some((x) => x.isSelected)))}
            headerExtraData={renderHeaderAdditionalWorksContent()}
            selectionKey
        >
            <StyleMultiselectWrapper>
                {works.map((workSelectionItem: FastServiceSelection, idx: number) => renderWork(workSelectionItem, idx))}
            </StyleMultiselectWrapper>
            <StyledSubmitButton onClick={handleMultiSelection} color="highlight">
                <Icon name="arrows_down" />
            </StyledSubmitButton>
        </CheckToken>
    )
}

const StyleMultiselectWrapper = styled(Box)({
    width: "100%",
    ".fancy-list__item": {
        justifyContent: "space-between",
    },
})

const StyledSubmitButton = styled(Button)({
    padding: "0.7em",
    height: "3em",
    position: "sticky",
    top: "calc(50% - 1.5em)",
})
