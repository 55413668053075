import { Skeleton, Stack } from "@tm/components"
import { useEffect, useRef } from "react"
import { useOnScreen } from "@tm/utils"
import { SkeletonArticleItem } from "./SkeletonArticleItem"

type Props = {
    canLoadNextArticles?: boolean
    loadNextPage?: () => void
    skeletonArticleCount?: number
    skeletonCategory?: boolean
}

export function NextPageLoader(props: Props) {
    const { canLoadNextArticles, loadNextPage } = props

    const ref = useRef<HTMLDivElement>(null)
    const endOfPage = useOnScreen(ref)

    useEffect(() => {
        if (canLoadNextArticles && endOfPage) {
            loadNextPage?.()
        }
    }, [endOfPage])

    return (
        <Stack ref={ref} spacing={1}>
            {props.skeletonCategory && <Skeleton variant="rectangular" height="40px" />}
            {Array.from(Array(props.skeletonArticleCount || 2).keys()).map((_, index) => {
                return <SkeletonArticleItem key={index} />
            })}
        </Stack>
    )
}
