import { Button, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useBasketModuleParameter, useCostEstimationModuleParameter } from "@tm/context-distribution"
import { useParams } from "react-router"
import { RowStack } from "../../../../_shared/StyledComponents"
import { useCostEstimationDetails } from "../../../business/hooks/useCostEstimationDetails"
import { useVoucherButtonsActions } from "../../../business/hooks/useVoucherButtonsActions"
import { VoucherRouteParams } from "../../../../../business"

export default function CostEstimationActionButtons() {
    const { translateText } = useLocalization()
    const matchParams = useParams<VoucherRouteParams>()
    const voucherId = matchParams.id && decodeURIComponent(matchParams.id)
    const { hasWorkTaskOrder } = useBasketModuleParameter()
    const { hasCostEstimation } = useCostEstimationModuleParameter()
    const { costEstimationDetails, costEstimationDetailsLoading } = useCostEstimationDetails(voucherId)
    const { costEstimationButtonAction } = useVoucherButtonsActions()

    const buttonDisabled = !costEstimationDetails?.costEstimation?.parts?.length || costEstimationDetailsLoading

    function handleOrderParts(redirectToCostEstimation: boolean) {
        costEstimationButtonAction(costEstimationDetails, redirectToCostEstimation)
    }

    return (
        <RowStack spacing={0.5}>
            {hasCostEstimation && (
                <Button
                    startIcon={<Icon name="pencil" />}
                    disabled={buttonDisabled}
                    onClick={() => handleOrderParts(true)}
                    title={translateText(917)}
                >
                    {translateText(122)}
                </Button>
            )}
            {hasWorkTaskOrder && (
                <Button
                    color="highlight"
                    startIcon={<Icon name="cart" />}
                    disabled={buttonDisabled}
                    onClick={() => handleOrderParts(false)}
                    title={translateText(937)}
                >
                    {translateText(50)}
                </Button>
            )}
        </RowStack>
    )
}
