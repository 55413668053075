import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { SupplierLogosData } from "../../models"
import { useArticlesContext } from "../Articles/ArticlesContext"
import { useSupplierLogos } from "./useSupplierLogos"
import { ensureContextAvailability } from "../../helpers"
import { useListOptions } from "../ListOptions/useListOptions"

export const SupplierLogosContext = createContext<SupplierLogosData | undefined>(undefined)

export function SupplierLogosProvider(props: PropsWithChildren<{ isEnabled: boolean }>) {
    const { supplierIds } = useArticlesContext()
    const options = useListOptions()

    const SupplierLogos = useSupplierLogos(supplierIds, props.isEnabled && options.showDataSupplierLogos)
    return <SupplierLogosContext.Provider value={SupplierLogos}>{props.children}</SupplierLogosContext.Provider>
}

export function useSupplierLogosContext(): SupplierLogosData {
    const context = useContext(SupplierLogosContext)
    return ensureContextAvailability("SupplierLogosContext", context)
}
