import { Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { PartsBundleParams } from "@tm/models"
import { StyledModuleHeader } from "../StyledComponents"

export function ModalExternalListModuleHeader() {
    const { translateText } = useLocalization()

    return (
        <StyledModuleHeader>
            <Typography variant="h1" pr={2} pb={1}>
                {translateText(993)}
            </Typography>
        </StyledModuleHeader>
    )
}
