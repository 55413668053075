import { PropsWithChildren } from "react"
import { ArticlesContext, useArticlesContext } from "../../../ContextProvider/Articles"
import { ArticleData, WholesalerArticleData } from "../../../models"
import { useFiltersAndResultsContext } from "../FiltersAndResults"

export function WholesalerArticlesProvider(props: PropsWithChildren<unknown>) {
    const { results } = useFiltersAndResultsContext()

    return <ArticlesContext.Provider value={results as ArticleData}>{props.children}</ArticlesContext.Provider>
}

export function useWholesalerArticlesContext<TContext extends WholesalerArticleData>(): TContext
export function useWholesalerArticlesContext<TContext extends WholesalerArticleData, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function useWholesalerArticlesContext<TContext extends WholesalerArticleData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useArticlesContext(selector as never)
}
