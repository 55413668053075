import { StateCreator } from "zustand"
import _ from "lodash"
import { FastServiceStore } from "../.."
import { navigationData } from "./staticData"
import { StepsNavigation } from "../../models"

export type StepNavigationSlice = {
    stepNavigationState: {
        steps: StepsNavigation[]
    }
}

export function getInitialState(): StepNavigationSlice {
    return {
        stepNavigationState: {
            steps: _.cloneDeep(navigationData),
        },
    }
}

export const createStepNavigationSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], StepNavigationSlice> = (set) => ({
    ...getInitialState(),
})
