import { ArticleItemStateProvider } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { OE, OeArticleItemState, channel } from "@tm/models"
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react"
import AvailabilityComponent from "../../../../../../erp/src/_shared/availability-wrapper"
import { useErpInfosContext } from "../../ContextProvider"
import { useOeBasketQuantities } from "../../ContextProvider/BasketQuantities/useBasketQuantities"
import { isAddButtonDisabled, isSameOeArticle, mapOeArticleDtoFromOePart } from "../../helpers"
import { mapArticleAttributes } from "../../hooks/useArticleItem/mapper"
import { useOeArticleOptions } from "../../hooks/useArticleItem/useArticleOptions"
import { useHandleAddOeToBasket } from "../../hooks/useArticleItem/useHandleAddOeToBasket"
import { useHandleRemoveFromBasket } from "../../hooks/useArticleItem/useHandleRemoveFromBasket"
import { useOePartErpInfos } from "../../hooks/useArticleItem/useOeArticleErpInfos"
import { useLoadOePartErpInfos } from "../../hooks/useErpInfos/useLoadOePartErpInfos"
import { useOePartsListParamsContext } from "../../OeParts/ContextProvider/ListParams"

type OeArticleItemStateProviderProps = PropsWithChildren<{
    article: OE.OePart
    articleTypeKey?: string
}>

export function OeArticleItemStateProvider({ article, children, articleTypeKey }: OeArticleItemStateProviderProps) {
    const { translateText } = useLocalization()
    const [quantity, setQuantity] = useState<number>(article?.quantity || 1)
    const articleList = useMemo(() => [article], [article])
    const options = useOeArticleOptions(article)
    const {
        startParams: { replaceButtonBundle },
    } = useOePartsListParamsContext()
    const erpInfosData = useErpInfosContext()
    const loadOePartErpInfos = useLoadOePartErpInfos(erpInfosData)

    const handleReplaceArticle = useCallback(() => {
        if (!replaceButtonBundle) {
            return
        }
        channel("GLOBAL").publish("PARTS/REPLACE_PART", { part: article, isNewList: true, bundle: replaceButtonBundle })
    }, [article, replaceButtonBundle])

    const { basketQuantities, updateBasketQuantities } = useOeBasketQuantities(articleList, true, articleTypeKey)

    const handleAddToBasketInternal = useHandleAddOeToBasket(article)
    const handleRemoveFromBasketInternal = useHandleRemoveFromBasket()

    useEffect(() => {
        // Initially load erp infos for OE part
        loadOePartErpInfos([{ oePart: article, quantity: article?.quantity || 1 }])
    }, [article, loadOePartErpInfos])

    const basketQuantity = useMemo(() => {
        const oeArticleDto = mapOeArticleDtoFromOePart(article)
        return basketQuantities.find((q) => isSameOeArticle(q.article, oeArticleDto))
    }, [basketQuantities, article])

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setQuantity(value)
            if (loadErpInfo) {
                loadOePartErpInfos([{ oePart: article, quantity: value }])
            }
        },
        [article, loadOePartErpInfos]
    )
    const handleAddToBasket = useCallback(
        async (internalQuantity?: number) => {
            await handleAddToBasketInternal(internalQuantity ?? quantity)
            updateBasketQuantities(articleList)
        },
        [handleAddToBasketInternal, quantity, articleList, updateBasketQuantities]
    )

    const handleRemoveFromBasket = useCallback(async () => {
        if (basketQuantity?.articleQuantities?.allPartItemIds) {
            await handleRemoveFromBasketInternal(basketQuantity?.articleQuantities?.allPartItemIds)
            updateBasketQuantities(articleList)
        }
    }, [basketQuantity, handleRemoveFromBasketInternal, updateBasketQuantities, articleList])

    const articleErpInfos = useOePartErpInfos(article, quantity || 1, erpInfosData)
    const addButtonsDisabled = useMemo(() => isAddButtonDisabled(articleErpInfos), [articleErpInfos])
    const attributes = useMemo(() => mapArticleAttributes(translateText, article, true), [article])

    const state: OeArticleItemState = {
        type: "OE",
        article,
        attributes,
        isVehicleDependent: false,
        options,
        quantity,
        originalQuantity: quantity,
        basketQuantity,
        oeArticleNo: article.number,
        wholesalerArticleNumber: article.traderNumber ?? "",
        thumbnailUrl: "/styles/base/images/oe-part-logo.svg",
        manufacturerThumbnail: article.thumbnailUrl,
        showReplaceButton: !!replaceButtonBundle,
        articleErpInfos,
        addToCostEstimationButtonDisabled: addButtonsDisabled,
        addToBasketButtonDisabled: addButtonsDisabled,
        handleChangeQuantity,
        handleAddToBasket,
        handleRemoveFromBasket,
        AvailabilityComponent,
        handleReplaceArticle,
    }

    return <ArticleItemStateProvider value={state}>{children}</ArticleItemStateProvider>
}
