import { useLocalization } from "@tm/localization"
import { ErpPriceDictionary } from "@tm/models"
import { Typography } from "@tm/components"
import { LoaderSmall, StackRow } from "../../StyledComponents"
import ErrorButton from "../../_shared/ErrorButton"
import BasketErpTotalInOrder from "../../_shared/BasketErpTotalInOrder"
import { useBasketErpTotalInOrder } from "../../_shared/BasketErpTotalInOrder/useBasketErpTotalInOrder"

type Props = {
    loading: boolean
    numberOfPositionsInOrder?: number
    numberOfItemsInOrder?: number
    erpTotalsInOrder?: ErpPriceDictionary
    workTaskIds: string[]
    hasErrors: boolean
}

export default function Totals({ loading, numberOfItemsInOrder, numberOfPositionsInOrder, erpTotalsInOrder, workTaskIds, hasErrors }: Props) {
    const { translate } = useLocalization()
    const { taxText } = useBasketErpTotalInOrder(erpTotalsInOrder)
    return (
        <StackRow spacing={4}>
            <Typography variant="body2">
                {numberOfPositionsInOrder ?? ""} {translate(479)}, {numberOfItemsInOrder ?? ""} {translate(90)}
            </Typography>
            <StackRow gap={1}>
                {(loading || loading) && <LoaderSmall />}
                {!loading && (
                    <>
                        <BasketErpTotalInOrder erpTotalsInOrder={erpTotalsInOrder} />
                        <Typography variant="label">{taxText}</Typography>
                        {hasErrors && <ErrorButton basketWithErrors workTaskIds={workTaskIds} additionalErrorTextId={13663} />}
                    </>
                )}
            </StackRow>
        </StackRow>
    )
}
