import { Article, ErpSystemConfig, TradeReferenceNumber } from "@tm/models"
import { useCallback } from "react"
import { ErpInfosData } from "../../models"
import { useDefaultErpSystem } from "../useDefaultErpSystem"
import { ArticleErpInfoFactory } from "./factories"

export type LoadErpInfoItem = {
    article: Article
    quantity?: number
    erpSystem?: ErpSystemConfig
}

export function useLoadErpInfos({ setErpInfos }: ErpInfosData, tradeReferences?: TradeReferenceNumber[]) {
    const defaultErpSystem = useDefaultErpSystem()

    return useCallback(
        (data: LoadErpInfoItem | LoadErpInfoItem[]) => {
            if (!defaultErpSystem) {
                return
            }

            const requestItems = Array.isArray(data) ? data : [data]

            if (!requestItems.length) {
                return
            }

            setErpInfos((prev) => {
                let mapped = prev
                requestItems.forEach(({ article, erpSystem, quantity }) => {
                    const erpSystemOrDefault = erpSystem ?? defaultErpSystem
                    const articleWithQuantity: Article = { ...article, quantity: quantity ?? article.quantity ?? 1 }
                    const queuedErpInfo = ArticleErpInfoFactory.createQueued(
                        articleWithQuantity,
                        erpSystemOrDefault,
                        tradeReferences?.find((referenceNumber) => referenceNumber.erpSystemId === erpSystemOrDefault.id)?.traderArticleNumber
                    )
                    const { key } = queuedErpInfo
                    const index = prev.findIndex((prevItem) => prevItem.key.id === key.id)

                    if (index === -1) {
                        mapped = [...mapped, queuedErpInfo]
                        return
                    }

                    if (prev[index].state === "loading") {
                        return prev
                    }

                    mapped = mapped.with(index, queuedErpInfo)
                })

                return mapped
            })
        },
        [defaultErpSystem, setErpInfos, tradeReferences]
    )
}
