import { batch, useSelector } from "react-redux"
import { MainState } from "../../main"
import { Box } from "@tm/components"
import { Scrollbar } from "@tm/controls"
import { NextPageLoader } from "../../../../../parts/src/components/ListV2/components/NextPageLoader"
import { useActions } from "@tm/morpheus"
import { Actions } from "../business"
import { useMemo } from "react"
import { Article } from "@tm/models"
import { WheelSelectionSteps } from "../../../data/enums"
import { WheelsTyresList } from "../../../../../parts/src"

export const TyresArticleListV2 = () => {
    const request = useSelector((s: MainState) => s.tyresList?.request)
    const loadingNextItems = useSelector((s: MainState) => s.tyresList.articles.loadingNextItems)
    const selectedItem = useSelector((s: MainState) => s.tyresList.selectedItem)
    const selectedAvailability = useSelector((s: MainState) => s.tyresList?.selectedFilters.availability)

    const actions = useActions(Actions, "saveTyresListTab", "changeStep", "loadNextTiresList", "changeQuantity", "selectItem", "sendTireToOverview", "loadNextTiresList")

    const selectedArticles = useMemo(() => (selectedItem ? [selectedItem] : []), [selectedItem])

    const onAddToBasket = (article: Article, quantity: number) => {
        batch(() => {
            const newArticle = !quantity ? article : { ...article, quantity }
            actions.sendTireToOverview(newArticle)
            actions.changeStep({ step: WheelSelectionSteps.OVERVIEW }, true)
            actions.saveTyresListTab(newArticle)
        })
    }

    const toggleSelectedArticle = (article: Article) => {
        actions.selectItem(article)
    }

    const onChangeQuantity = (article: Article, quantity: number) => {
        if (selectedItem?.internalId !== article.internalId) {
            return
        }

        const newArticle = !quantity ? article : { ...article, quantity }
        actions.selectItem(newArticle)
    }

    if (!request) {
        return null
    }

    const handleScroll = (e: React.UIEvent<HTMLElement> | UIEvent) => {
        const el = e.target as HTMLElement
        if ((el.scrollHeight - el.scrollTop) <= (el.clientHeight + 175)) {
            actions.loadNextTiresList()
        }
    }

    const handleAutoNextPage = () => {
        actions.loadNextTiresList()
    }

    return (
        <Scrollbar onScroll={handleScroll} className="scrollbar__container__tyres" >
            <Box display="flex" flexDirection="column">
                <WheelsTyresList
                    request={request}
                    selectedArticles={selectedArticles}
                    onAddToBasket={onAddToBasket}
                    onChangeQuantity={onChangeQuantity}
                    toggleSelectedArticle={toggleSelectedArticle}
                    selectedAvailability={selectedAvailability}
                    handleAutoNextPage={handleAutoNextPage}
                />

                {loadingNextItems && (
                    <NextPageLoader
                        canLoadNextArticles={true}
                        skeletonArticleCount={1}
                    />
                )}
            </Box>
        </Scrollbar>
    )
}

