import { Article, WholesalerArticle } from "@tm/models"
import { useQuery } from "react-query"
import { usePureArticleList, useWorkTaskId } from "@tm/context-distribution"
import { useMemo } from "react"
import { loadArticles } from "../../data"
import { useVehicle } from "../../../ListV2/hooks"
import { PartsListImage, PartsListImageCoordinate } from "../../../../data/model"
import { ArticleGroupParams, PureListArticleData, WholesalerArticleGroup } from "../../../ListV2/models"
import { getArticleGroups, mapDistinctValues } from "../../../ListV2/helpers"

export function useArticles(listId: string, pureListType: string, disableArticleGrouping?: boolean): PureListArticleData {
    const workTaskId = useWorkTaskId()
    const vehicle = useVehicle()
    const { state } = usePureArticleList(listId)

    const articlesQuery = useQuery({
        queryKey: ["pureList", workTaskId, pureListType, state?.request],
        queryFn: () => state.request && loadArticles(pureListType, state.request, vehicle),
        enabled: !!state?.request,
    })

    const isLoaded = articlesQuery.isSuccess || articlesQuery.isError
    const articles = useMemo(() => articlesQuery.data?.articles ?? [], [articlesQuery.data])
    const supplierIds = useMemo(() => mapDistinctValues(articles, (x) => x.supplier.id), [articles])
    const productGroupIds = useMemo(() => mapDistinctValues(articles, (x) => x.productGroup.id), [articles])

    const articleGroups = useMemo(() => {
        if (disableArticleGrouping) {
            return []
        }

        const groups: (ArticleGroupParams | WholesalerArticleGroup)[] = []
        if (articlesQuery.data?.articles) {
            groups.push(...getArticleGroups(articlesQuery.data.articles, false))
        }
        if (articlesQuery.data?.type === "alternative" && articlesQuery.data.wholesalerParts?.length) {
            groups.unshift({ type: "WholesalerArticle", articles: articlesQuery.data.wholesalerParts })
        }
        return groups
    }, [articlesQuery.data?.articles, disableArticleGrouping])

    return {
        requestStatus: articlesQuery.status,
        isLoading: articlesQuery.isFetching,
        isLoaded,
        isSuccess: articlesQuery.isSuccess,
        isFailed: articlesQuery.isError,
        articles,
        articleCount: articles.length,
        articleGroups,
        supplierIds,
        productGroupIds,
        pageCount: 0,
        hasNextPage: false,
        isFetchingNextPage: false,
        loadNextPage: () => {},
        response: articlesQuery.data,
    }
}

export type LoadArticlesResponse = {
    articles: Article[]
} & (
    | { type: null }
    | {
          type: "alternative"
          wholesalerParts?: WholesalerArticle[]
      }
    | {
          type: "partslist"
          imageCoordinates?: PartsListImageCoordinate[]
          partsListImage?: PartsListImage
      }
    | {
          type: "accessoryList"
      }
    | {
          type: "articlesAlreadyOrderedList"
      }
    | {
          type: "mainAccessoryList"
      }
    | {
          type: "recommended-articles"
      }
)
