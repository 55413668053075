import { useHistory } from "react-router"
import { useCallback, useState } from "react"
import { PostSendOrderAction, PostSendOrderActionTargetType, PostSendOrderActionType } from "@tm/models"

export function useHandlePostSendOrderAction() {
    const history = useHistory()
    const [externalModalUrl, setExternalModalUrl] = useState<string | undefined>()

    const resetExternalModalUrl = useCallback(() => {
        setExternalModalUrl(undefined)
    }, [])

    const handlePostSendOrderAction = useCallback(
        (postSendOrderActions?: PostSendOrderAction[]) => {
            postSendOrderActions?.forEach((postAction) => {
                switch (postAction.action) {
                    case PostSendOrderActionType.OpenExternalUrl: {
                        if (postAction.target === PostSendOrderActionTargetType.NewWindow) {
                            window.open(postAction.value, "_blank")
                        } else if (postAction.target === PostSendOrderActionTargetType.Modal) {
                            setExternalModalUrl(postAction.value)
                        }
                        break
                    }
                    case PostSendOrderActionType.PostMessage: {
                        if (postAction.target === PostSendOrderActionTargetType.ParentFrame) {
                            window.parent.postMessage(JSON.parse(postAction.value), "*")
                        }
                        break
                    }
                    default:
                        break
                }
            })
        },
        [history]
    )

    return { handlePostSendOrderAction, externalModalUrl, resetExternalModalUrl }
}
