import { Tooltip, Icon, IconProps, TooltipProps } from "@tm/components"
import { useLocalization } from "@tm/localization"

type IconWithTooltipProps = {
    variant: "search" | "remove-filter" | "info"
    onClick?: React.MouseEventHandler<SVGSVGElement>
    iconProps?: IconProps
    tooltipProps?: TooltipProps
}

const TooltipText: Record<IconWithTooltipProps["variant"], string> = {
    search: "13858",
    "remove-filter": "13857",
    info: "12569",
}

export default function IconWithTooltip(props: IconWithTooltipProps) {
    const { variant, onClick, iconProps, tooltipProps } = props
    const { translateText } = useLocalization()

    return (
        <Tooltip sx={{ cursor: "pointer" }} title={translateText(TooltipText[variant])} {...tooltipProps}>
            <Icon name={variant} onClick={onClick} {...iconProps} />
        </Tooltip>
    )
}
