import { Article } from "@tm/models"
import { useMemo } from "react"
import { useCompilations, useCompilationsContains } from "../../../../../../compilations/src/data/hooks/useCompilations"
import { mapArticleToCompilationArticle } from "../../../../../../compilations/src/data/mapper"
import { Compilation } from "../../../../../../compilations/src/data/model"
import { WatchListData } from "../../models"

/**
 * Get the info if any of the given articles is in any watch list or `undefined` if watch list is disabled.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useWatchListData(articles: Article[], isEnabled: boolean): WatchListData | undefined {
    const compilationArticles = useMemo(() => mapArticleToCompilationArticle({ articles }), [articles])
    const compilationsData = useCompilations(undefined, isEnabled)
    const compilationsContains = useCompilationsContains(compilationArticles, isEnabled)

    const compilations = useMemo(() => {
        if (compilationsData.isLoading || compilationsContains.loading || !compilationsData.compilations?.length) {
            return []
        }

        return compilationsData.compilations.map((compilation) => {
            const result: [Compilation, Article[]] = [compilation, []]

            compilationsContains.containedArticles
                .filter((containedArticle) => containedArticle.compilationId === compilation.id)
                .forEach(({ id, productGroupId, supplierArticleNumber, supplierId }) => {
                    const foundArticle = articles.find(
                        (article) =>
                            article.id === id &&
                            article.productGroup.id === productGroupId &&
                            article.supplierArticleNo === supplierArticleNumber &&
                            article.supplier.id === supplierId
                    )

                    if (foundArticle) {
                        result[1].push(foundArticle)
                    }
                })

            return result
        })
    }, [compilationsData.isLoading, compilationsData.compilations, compilationsContains.loading, compilationsContains.containedArticles, articles])

    if (!isEnabled) {
        return undefined
    }

    return {
        isLoading: compilationsData.isLoading,
        error: compilationsData.error,
        compilations,
    }
}
