import { useHistory } from "react-router"
import { Advertisement, AdvertisementCategory, Article, channel, SupplierArticle } from "@tm/models"
import { encodeUniqueId, uniqueId } from "@tm/utils"

export type TraderArticle = {
    traderArticleNumber: string
    id: string
    isTraderArticle: true
}

export function mapAdvertisementToSupplierArticle(ads: Array<Advertisement>): Array<SupplierArticle> {
    const parts: Array<SupplierArticle> = []

    ads.forEach((ad) => {
        if (ad.dataSupplierId && ad.dataSupplierArticleNumber) {
            parts.push({
                supplierId: ad.dataSupplierId,
                supplierArticleNo: ad.dataSupplierArticleNumber,
            })
        }
    })

    return parts
}

export function getArticlesFromResponse(response: Record<string, Array<Article>>) {
    if (!response) {
        return
    }

    const parts: Array<Article | TraderArticle> = []

    Object.keys(response).forEach((traderArticleNo) => {
        if (response[traderArticleNo].length) {
            // only the first item should be displayed
            parts.push(response[traderArticleNo][0])
        } else {
            parts.push({
                traderArticleNumber: traderArticleNo,
                id: uniqueId(),
                isTraderArticle: true,
            })
        }
    })

    return parts
}

export function openPartsList(
    history: ReturnType<typeof useHistory>,
    workTaskId: string,
    advertisementCategory: AdvertisementCategory,
    tabTitle?: string
) {
    history.push(`/${encodeUniqueId(workTaskId)}`)

    channel("WORKTASK", workTaskId).subscribeOnce("WORKTASK/LOADED", () => {
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            offers: {
                advertisementCategory,
                tabTitle,
            },
        })

        channel("WORKTASK", workTaskId).publish("MODULE/CHANGED", {
            id: "parts",
            name: advertisementCategory.id,
            view: tabTitle ? `${tabTitle} - ${advertisementCategory.description}` : "{{1276}}",
            info: tabTitle ?? advertisementCategory.description,
        })
    })
}
