import { Icon, IconButton, Stack, TextField, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { concat } from "@tm/utils"
import { ReactNode, useState } from "react"

type Props = {
    currentPageIndex: number
    onChangePageIndex(page: number): void
    totalPages: number
    disabled: boolean
    maxPages?: number
}

export function OffersWidgetPagination(props: Props) {
    const { totalPages, onChangePageIndex, disabled, maxPages } = props
    const { translateText } = useLocalization()
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(props.currentPageIndex)

    function handlePageNavigationClick(value: number) {
        setCurrentPageIndex(value)
        onChangePageIndex(value)
    }

    function previousPage() {
        let page = currentPageIndex - 1

        if (page < 0) {
            page = totalPages - 1
        }

        setCurrentPageIndex(page)
        onChangePageIndex(page)
    }

    function nextPage() {
        let page = currentPageIndex + 1

        if (page >= totalPages) {
            page = 0
        }

        setCurrentPageIndex(page)
        onChangePageIndex(page)
    }

    function handleChangeCurrentPageIndex(value: number) {
        let newValue = value

        if (newValue < 0) {
            newValue = 0
        }

        if (newValue >= totalPages) {
            newValue = totalPages - 1
        }

        setCurrentPageIndex(newValue)
    }

    function handleBlur(value: number) {
        onChangePageIndex(value)
    }

    function renderPageNavigation() {
        const elements: Array<ReactNode> = []

        for (let pageIndex = 0; pageIndex < Math.min(totalPages, maxPages ?? Infinity); pageIndex++) {
            elements.push(
                <div
                    key={pageIndex}
                    className={concat(" ", "navigation-page", pageIndex === currentPageIndex && "selected")}
                    onClick={() => handlePageNavigationClick(pageIndex)}
                />
            )
        }

        return elements
    }

    // Used style for sx instead of styled(TextField) due problems with onBlur and focus does not work
    const textFieldStyle = {
        color: "black",
        margin: "0px 0px 0px 0px",
        width: "2.75em",
        marginRight: "0.5rem",
        ".MuiInputBase-root": {
            opacity: 1,
            input: {
                padding: "5px 4px",
            },
        },
    }

    return (
        <>
            <div className="navigation">{renderPageNavigation()}</div>
            <Stack direction="row" alignItems="center" spacing={1}>
                <TextField
                    sx={textFieldStyle}
                    value={currentPageIndex + 1}
                    disabled={disabled}
                    hideNumericCounter
                    onChange={(e) => handleChangeCurrentPageIndex(Number(e.target.value) - 1)}
                    onBlur={(e) => handleBlur(Number(e.target.value) - 1)}
                    inputProps={{
                        step: 1,
                        min: 1,
                        max: { totalPages },
                        type: "number",
                    }}
                />
                <Typography>{`${translateText(344)} ${totalPages}`}</Typography>
            </Stack>
            <div>
                <IconButton sx={{ p: ".2em", mr: ".2em" }} disabled={disabled} color="primary" onClick={previousPage}>
                    <Icon name="arrow-left" sx={{ minWidth: "16px", minHeight: "16px", width: "16px", height: "16px" }} />
                </IconButton>
                <IconButton sx={{ p: ".2em", mr: ".2em" }} disabled={disabled} color="primary" onClick={nextPage}>
                    <Icon name="arrow-right" sx={{ minWidth: "16px", minHeight: "16px", width: "16px", height: "16px" }} />
                </IconButton>
            </div>
        </>
    )
}
