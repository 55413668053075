import { useState, useEffect, ReactNode, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { getModuleFromUserContext, useUser } from "@tm/context-distribution"
import { UserModuleType } from "@tm/models"
import { ajax, notUndefinedOrNull } from "@tm/utils"
import { useQuery } from "react-query"
import { getBundleParams } from "../../../utils"

type ServiceBookEntry = {
    title: ReactNode
    url: string
}

type ServiceBook = {
    kher: number
    her: string
    url: string
}

export function useServiceBooks(tecDocManufacturerId?: number): ServiceBookEntry[] {
    const url = getBundleParams().serviceBooksUrl
    const { translate } = useLocalization()
    const [serviceBook, setServiceBook] = useState<ServiceBookEntry>()
    const { userContext } = useUser()

    const macsDia = useMemo(() => {
        const mod = getModuleFromUserContext(userContext, UserModuleType.ServiceBookletMacsDia)
        if (mod) {
            const url = mod.parameters?.find((x) => x.key === "URL_Homepage")?.value
            if (url) {
                return {
                    title: translate(13783),
                    url,
                }
            }
        }
    }, [userContext])

    const { data } = useQuery(
        "SERVICEBOOK",
        async () => {
            if (url) {
                const response = await ajax<Array<ServiceBook>>({ url })
                if (response) {
                    return Promise.resolve(response)
                }
            }
        },
        { staleTime: Infinity }
    )

    useEffect(() => {
        if (!tecDocManufacturerId) {
            setServiceBook(undefined)
        } else if (data) {
            try {
                // if we do not get a valid or false json, the following errors will be caught here
                const sb = data.find((x) => x.kher === tecDocManufacturerId)
                if (sb?.url) {
                    setServiceBook({ url: sb.url, title: translate(1857) })
                }
            } catch (e) {
                console.error("invalide servicebook.json")
                setServiceBook(undefined)
            }
        }
    }, [tecDocManufacturerId, data, translate])

    return useMemo(() => [serviceBook, macsDia].filter(notUndefinedOrNull), [serviceBook, macsDia])
}
