import {
    SystemType,
    MVCPlatformType,
    Vehicle,
    RegisteredModels,
    ShowCarModelDetailsResponse,
    UserContext,
    UserModuleType,
    VehicleType,
    Customer,
    TitleType,
    SessionStorageKeys,
    ModuleMode,
} from "@tm/models"
import { Container } from "@tm/nexus"
import { createQueryString, encodeUniqueId, equals, getMvcComponentModuleMode } from "@tm/utils"
import { CustomVehicle } from "../../components/truck/business"
import { LoginRequest } from ".."
import { getBundleParams } from "../../utils"

export const loadCarModelDetails = async (vehicle: Vehicle): Promise<ShowCarModelDetailsResponse | undefined> => {
    if (vehicle && vehicle.vin) {
        return Container.getInstance<ShowCarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)
            .subscribe({
                modelId: vehicle.tecDocTypeId,
                registrationNo: vehicle.vin,
                registrationNoTypeId: vehicle.registrationTypeId,
                forceUpdateRegistrationNoDetails: false,
            })
            .load()
    }
    return undefined
}

export function GetMvcPlatformId(systype: SystemType) {
    switch (systype) {
        case SystemType.Redesign:
            return MVCPlatformType.Redesign
        case SystemType.Next:
            return MVCPlatformType.Next
        default:
            return MVCPlatformType.Next
    }
}

export const isVehicleChanged = (newVeh: Vehicle, oldVeh?: Vehicle) => {
    if (!oldVeh) {
        return true
    }

    const { dataAvailabilities: _, ...newVehWithoutAvailabilites } = newVeh
    const { dataAvailabilities: __, ...oldVehWithoutAvailabilites } = oldVeh
    return !equals(newVehWithoutAvailabilites, oldVehWithoutAvailabilites)
}

export const getMvcSessionId = (workTaskId?: string, moduleMode?: ModuleMode | string | undefined) => {
    return sessionStorage.getItem(`${workTaskId}${getMvcComponentModuleMode(moduleMode)}${SessionStorageKeys.mvcSessionId}`) || ""
}

export const getCatalogNumber = (userContext: UserContext | undefined): number | undefined => {
    const catalogModule = userContext?.modules?.find((x) => x && x.type === UserModuleType.Catalog)

    return catalogModule?.id
}

export const buildBaseRequestParametersWithoutVehicle = (
    loginRequest: LoginRequest,
    languageId: string,
    workTaskId?: string,
    userContext?: UserContext
) => {
    const catNr = getCatalogNumber(userContext)
    const encodedWorkTaskId = !workTaskId ? undefined : encodeUniqueId(workTaskId)

    const request: LoginRequest = {
        ...loginRequest,
        platformId: MVCPlatformType.Next,
        languageId,
        mdmSessionId: userContext?.id,
        h_session: getMvcSessionId(workTaskId, loginRequest?.moduleMode),
        catNr,
        clientId: catNr,
        workTaskId: encodedWorkTaskId,
    }

    return request
}

export const buildBaseRequestParameters = (
    loginRequest: LoginRequest,
    languageId: string,
    workTaskId?: string,
    userContext?: UserContext,
    vehicle?: Vehicle
) => {
    const request = buildBaseRequestParametersWithoutVehicle(loginRequest, languageId, workTaskId, userContext)

    if (vehicle) {
        request.kTypeId =
            vehicle.vehicleType === VehicleType.CommercialVehicle
                ? vehicle.tecDocTypeId - getBundleParams().commercialVehicleOffset
                : vehicle.tecDocTypeId
        request.engineCode = vehicle.engineCode
        request.typeArt = vehicle.vehicleType
        request.longLife = vehicle.longlife
    }

    return request
}

export const buildIframeUrl = (loginUrl: string, request: LoginRequest) => {
    return new URL(`${loginUrl}${createQueryString(request)}`, document.baseURI).href
}

export const isEqualParameters = (oldParams: any, newParams: any) => {
    if (!oldParams || !newParams) {
        return false
    }

    const { h_session: _, ...obj1 } = oldParams
    const { h_session: __, ...obj2 } = newParams

    return equals(obj1, obj2)
}

function checkIsValidMvcSession(currentMvcSessionId: string, invalidMvcSessionId?: string) {
    let isInvalidMvcSession = !!invalidMvcSessionId?.length

    if (currentMvcSessionId.indexOf(SessionStorageKeys.invalidSessionMvc) > -1) {
        currentMvcSessionId = currentMvcSessionId.replace(SessionStorageKeys.invalidSessionMvc, "")
        isInvalidMvcSession = true
    }

    return {
        isInvalidMvcSession,
        // this flag makes sure to not invalidate the current MVC tab if the mvc session differs
        isInvalidUpdate: !!invalidMvcSessionId?.length && invalidMvcSessionId !== currentMvcSessionId,
    }
}

export const compareAndSetRequestUrl = (
    buildRequestParameters: () => LoginRequest,
    loginUrl: string,
    setLoading: (v: boolean) => void,
    setUrl: (v: string) => void,
    updateParameters: (p: LoginRequest) => void,
    parameters?: LoginRequest,
    url?: string,
    invalidMvcSessionId?: string
) => {
    const newParameters = buildRequestParameters()

    const { isInvalidMvcSession, isInvalidUpdate } = checkIsValidMvcSession(newParameters.h_session as string, invalidMvcSessionId)

    if (isInvalidUpdate) {
        return
    }

    if (isInvalidMvcSession || !isEqualParameters(newParameters, parameters)) {
        setLoading(true)

        const newUrl = buildIframeUrl(loginUrl, newParameters)

        setUrl(newUrl)
        updateParameters(newParameters)
        return
    }

    if (!url && parameters) {
        setUrl(buildIframeUrl(loginUrl, parameters))
    }
}

export const isCustomVehicleChanged = (oldObj: CustomVehicle | undefined, newObj: CustomVehicle | undefined) => {
    return !equals(oldObj, newObj)
}

export function getTruckVehicle(vehicle?: CustomVehicle) {
    return vehicle
        ? {
              licensePlate: vehicle.plateId,
              vin: vehicle.vin,
              initialRegistration: vehicle.initialRegistration,
              color: vehicle.color,
              weight: vehicle.weight,
              vehicleNr: vehicle.vehicleNr,
          }
        : undefined
}

function getCustomerName(customer: Customer, hasTelesales?: boolean): string {
    let name = ""

    if (customer.titleType === TitleType.Company || hasTelesales) {
        name = customer.companyName ?? ""
    }

    if (!name) {
        name = [customer.firstName, customer.lastName].filter((x) => !!x).join(" ")
    }

    return name
}

export function getCustomer(customer?: Customer, hasTelesales?: boolean) {
    return {
        name: customer ? getCustomerName(customer, hasTelesales) : "",
        customerId: hasTelesales ? customer?.refCustomerNo : undefined,
    }
}
