import { TextField, Button, Icon } from "@tm/components"
import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { createQueryString } from "@tm/utils"
import { useHistory } from "react-router"
import { RowStack } from "./StyledComponents"

export type Props = {
    query?: string
    hideClearButton?: boolean
    url: string
    variant?: "widget" | "header"
}

export default function ArticleSearchFields({ query, hideClearButton, url, variant }: Props) {
    const { translateText } = useLocalization()
    const history = useHistory()

    const [searchQuery, setSearchQuery] = useState(query)

    function handleSearchInputChange(newQuery: string) {
        setSearchQuery(newQuery)
    }

    function handleSearch(reset = false) {
        let newUrl = url
        if (reset) {
            setSearchQuery(undefined)
        } else {
            newUrl += createQueryString({ searchQuery })
        }

        history.push(newUrl)
    }

    const handleKeyDown = (e: { key: string }) => {
        if (e.key === "Enter") {
            handleSearch()
        }
    }

    return (
        <RowStack alignItems="center">
            <TextField
                sx={{ mt: 0, minWidth: variant === "widget" ? "240px" : undefined }}
                size={variant === "widget" ? "medium" : "large"}
                value={searchQuery}
                placeholder={translateText(87)}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                onKeyDown={handleKeyDown}
                fullWidth
                hiddenLabel
            />
            <RowStack spacing={0.5}>
                <Button variant="contained" onClick={() => handleSearch()} disabled={!searchQuery || searchQuery.length < 2} size="small">
                    <Icon name="search" size={variant === "header" ? "20px" : undefined} />
                </Button>
                {!hideClearButton && (
                    <Button variant="contained" onClick={() => handleSearch(true)} disabled={!searchQuery} size="small">
                        <Icon name="cancel-x" size={variant === "header" ? "20px" : undefined} />
                    </Button>
                )}
            </RowStack>
        </RowStack>
    )
}
