import { PropsWithChildren } from "react"
import { useFilters } from "./useFilters"
import { useProductGroupsListParamsContext } from "../ListParams"
import { FiltersContext, useFiltersContext } from "../../../ContextProvider/Filters"

export function ProductGroupsFiltersProvider(props: PropsWithChildren<unknown>) {
    const params = useProductGroupsListParamsContext()
    const filters = useFilters(params, true, true)
    return <FiltersContext.Provider value={filters}>{props.children}</FiltersContext.Provider>
}

export const useProductGroupsFiltersContext = useFiltersContext
