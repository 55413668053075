import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."

export type TesterSlice = {
    tester: {
        signature?: string
        firstName: string
        lastName: string
        comment: string
    }
}

export function getInitialState(): TesterSlice {
    return {
        tester: {
            firstName: "",
            lastName: "",
            comment: "",
        },
    }
}

export const createTesterSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], TesterSlice> = (set) => ({
    ...getInitialState(),
})
