import { PropsWithChildren } from "react"
import { FiltersContext, FiltersContextData, useFiltersContext } from "../../../ContextProvider/Filters"
import { useDirectSearchListParamsContext } from "../ListParams"
import { useFilters } from "./useFilters"

export function DirectSearchFiltersProvider(props: PropsWithChildren<unknown>) {
    const params = useDirectSearchListParamsContext()
    const filters = useFilters(params, true)
    return <FiltersContext.Provider value={filters}>{props.children}</FiltersContext.Provider>
}

export function useDirectSearchFiltersContext<TContext extends FiltersContextData>(): TContext
export function useDirectSearchFiltersContext<TContext extends FiltersContextData, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function useDirectSearchFiltersContext<TContext extends FiltersContextData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useFiltersContext(selector as never)
}
