import { useCallback, useMemo } from "react"
import { Article, ArticleAttribute, AttributeFilterModel } from "@tm/models"
import { useShowArticleDetails } from "../../../hooks"
import { useBasketQuantitiesContext, useListParamsContext } from "../../../ContextProvider"
import { useFiltersContext } from "../../../ContextProvider/Filters"
import { ArticleListActions } from "../../../models"
import { useVehicleRecordsContext } from "../VehicleRecords"

export function useArticleListActions(): ArticleListActions {
    const showArticleDetails = useShowArticleDetails()
    const params = useListParamsContext()
    const { toggleSupplier } = useFiltersContext()
    const { updateBasketQuantities } = useBasketQuantitiesContext()
    const vehicleRecords = useVehicleRecordsContext()

    const toggleInlineFilter = useCallback(
        (attribute: ArticleAttribute, article: Article) => {
            params.toggleAttribute(attribute)
            if (article && !params.attributes.find((x) => x.id === attribute.id && x.value === attribute.value)) {
                vehicleRecords?.handleSelectInlineFilter(attribute, article, params.fittingPosition)
            }
        },
        [params.attributes]
    )

    const toggleQuickFilter = useCallback(
        (attribute: AttributeFilterModel) => {
            params.toggleAttribute(attribute)
            if (!params.attributes.find((x) => x.id === attribute.id && x.value === attribute.value)) {
                vehicleRecords?.handleSelectQuickFilter(attribute)
            }
        },
        [params.attributes]
    )

    return useMemo<ArticleListActions>(
        () => ({
            showArticleDetails,
            toggleSupplier,
            toggleInlineFilter,
            toggleQuickFilter,
            updateBasketQuantities,
        }),
        [showArticleDetails, toggleSupplier, toggleInlineFilter, toggleQuickFilter, updateBasketQuantities]
    )
}
