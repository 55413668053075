import { ArticleErpInfo, GetArticlesRequest, Vehicle } from "@tm/models"
import { useEffect, useMemo } from "react"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"
import { fetchDirectSearchOrVehiclePartsArticles } from "../../../data/fetchDirectSearchOrVehiclePartsArticles"
import { getArticleGroups } from "../../../helpers"
import { useArticlesBase } from "../../../hooks/useArticlesBase"
import { useDisplayFilter } from "../../../hooks/useDisplayFilter"
import { OePartsArticleData, OePartsListParams } from "../../../models"

const QUERY_KEY = "Alternatives.useArticles"

/**
 * Get information about the current article data of the vehicle parts module.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useArticles(
    vehicle: Vehicle | undefined,
    params: OePartsListParams,
    erpInfos: ArticleErpInfo[],
    isEnabled: boolean
): OePartsArticleData {
    const {
        productGroups,
        suppliers,
        attributes,
        startParams,
        extendedAssortment,
        fittingPosition,
        sorting: { activateDefault: activateDefaultSorting },
        selectedOePart,
        selectedOeNumber,
    } = params

    const { pageSize } = useArticleListConfiguration()

    const request = useMemo<GetArticlesRequest | null>(() => {
        if (!isEnabled || !startParams || !productGroups.length || !suppliers.length || (!vehicle?.vehicleType && !vehicle?.tecDocTypeId)) {
            return null
        }

        const partialRequest: Partial<GetArticlesRequest> = {
            pageSize,
            vehicleType: vehicle.vehicleType,
            engineCode: vehicle?.engineCode,
            modelId: vehicle.tecDocTypeId,
            productGroupIds: productGroups.map((x) => x.id),
            supplierIds: suppliers.map((x) => x.id),
            attributeFilter: attributes?.map((attr) => attr.query).join("|"),
            extendedAssortment,
            fittingSideFilter: fittingPosition,
        }

        switch (startParams.type) {
            case "oe":
                if (!(selectedOePart?.number || selectedOeNumber?.number)) {
                    return null
                }
                return {
                    ...partialRequest,
                    oeReferences: selectedOePart?.number ? [selectedOePart.number] : selectedOeNumber?.number ? [selectedOeNumber.number] : [],
                }
            default:
                return null
        }
    }, [
        isEnabled,
        pageSize,
        vehicle?.vehicleType,
        vehicle?.tecDocTypeId,
        vehicle?.engineCode,
        productGroups,
        suppliers,
        attributes,
        startParams,
        extendedAssortment,
        fittingPosition,
        selectedOePart,
    ])

    const articlesBase = useArticlesBase(request, fetchDirectSearchOrVehiclePartsArticles, QUERY_KEY)
    const { isSuccess, articles, articleCount, pageCount, hasNextPage } = articlesBase

    const articleGroups = useMemo(() => getArticleGroups(articles, hasNextPage), [articles, hasNextPage])

    useEffect(() => {
        if (isSuccess) {
            activateDefaultSorting(articleCount, pageCount, articleGroups, true)
        }
    }, [activateDefaultSorting, articleCount, articleGroups, isSuccess, pageCount])

    const displayFilter = useDisplayFilter(articleGroups, params, erpInfos)

    return {
        ...articlesBase,
        isStalling: displayFilter.stalling && pageCount === 1,
        isFrontendFiltered: displayFilter.isFrontendFiltered,
        articleGroups: displayFilter.articleGroups,
    }
}
