import { getInitialState } from "."
import { fastServiceStore } from "../store"

export function resetInspectionWork() {
    fastServiceStore.setState(getInitialState(), false, "Reset inspection work slice")
}

export function saveInspectionDate(date: Date) {
    fastServiceStore.setState(
        {
            inspectionDate: date,
        },
        false,
        "Save inspection date"
    )
}

export function saveInspectionKm(value: string) {
    fastServiceStore.setState(
        {
            inspectionKmValue: value,
        },
        false,
        "Save inspection km"
    )
}
