import { UrlTagsProps, useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ConfigParams } from "@tm/models"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { RouteComponentProps, useExternalCatalogUrl, useHeighAdjustment, withRouter } from "@tm/utils"
import { useCallback, useEffect, useMemo } from "react"
import { useReplaceExternalCatalogUrl } from "../../hooks/useReplaceExternalCatalogUrl"
import { Actions, IActions, State } from "./business"

type Props = RouteComponentProps<void> & {
    state: State
    actions: IActions
    size?: string
    disableScrollbar?: boolean
    createUrl?: string
    externalSystemId?: number
    token?: string
    className?: string
    prodUrl?: string
    devUrl?: string
    heightObservationName?: string
}

// TODO: Refactoring (split into two components?)
function iFrameWidgetComponent(props: Props) {
    const {
        size,
        disableScrollbar,
        token,
        state,
        externalSystemId,
        location,
        className,
        createUrl,
        actions,
        prodUrl,
        devUrl,
        heightObservationName,
    } = props
    const { userContext } = useUser() ?? {}
    const { workTask, workTaskLoading } = useWorkTask() ?? {}
    const localization = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo }, undefined, () => !workTaskLoading)

    const createdUrl = useMemo(() => {
        if (!externalSystemId) {
            const createUrlFunc = eval(`[${createUrl}][0]`)
            if (!userContext || typeof createUrlFunc != "function") {
                return
            }

            if (token && !state.token) {
                return
            }

            const url = createUrlFunc(userContext, workTask?.vehicle, workTask?.customer, location, undefined, state.token, localization)
            return url
        }
    }, [externalSystemId, token, state.token, createUrl, workTask?.vehicle, workTask?.customer, localization, userContext])

    const urlTagsProps: UrlTagsProps = { location, kbaNumbers: state.kbaNumbers, languageId: localization.languageId }
    let { url } = useReplaceExternalCatalogUrl(urlTagsProps, externalCatalogUrl ?? createdUrl, true)

    if (prodUrl || devUrl) {
        const { env } = Morpheus.getParams<ConfigParams>()
        url = env === "dev" || env === "test" ? devUrl : prodUrl
        if (url && url?.indexOf("{0}") >= 1) {
            url = url?.replace("{0}", userContext?.account?.customerNo || "")
        }
    }

    useEffect(() => {
        if (token) {
            const [tokenType, tokenKey] = token.split(":")
            actions.getToken(tokenType, tokenKey)
        }
    }, [token])

    const handleRef = useCallback((ref: HTMLDivElement) => {
        if (ref && props.heightObservationName) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useHeighAdjustment().allowHeightObservation(ref, props.heightObservationName)
        }
    }, [])

    const newClassName = `${(size || "4x4").replace(/(\d{1,2})x(\d{1,2})/, "bundle-misc iframe-widget widget widget--w$1 widget--h$2")} ${
        className || ""
    }`

    if ((token && !state.token) || !url || workTaskLoading) {
        return <div ref={handleRef} className={newClassName} />
    }

    return (
        <div ref={handleRef} className={newClassName}>
            {url && <iframe width="100%" height="100%" src={url} allowFullScreen scrolling={disableScrollbar ? "no" : ""} />}
        </div>
    )
}

export default connectComponent(Actions, withRouter(iFrameWidgetComponent))
