import { em, percent } from "csx"
import { useCallback, useMemo } from "react"
import { classes, getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FilterNames, TyreFilter } from "@tm/models"
import { Tooltip } from "@tm/components"
import { getIconByGroup, getTitleByGroup } from "@tm/utils"

type CarTypeSeasonFilterName = Extract<FilterNames, "carType" | "season">

type Props = {
	filterName: CarTypeSeasonFilterName
	items: TyreFilter[]
	title?: string
	selectedItemValue?: TyreFilter | TyreFilter[]
	className?: string
	disabled?: boolean
	onChange(filterName: CarTypeSeasonFilterName, value: TyreFilter): void
}

export default function CarTypeSeasonFilters({ filterName, items, onChange, disabled, className, selectedItemValue }: Props) {
	const { translateText } = useLocalization()
	const style = useMemo(() => getStyle(), [])

	function isValueSelected(item: TyreFilter) {
		if (disabled) {
			return false
		}

		if (Array.isArray(selectedItemValue)) {
			return selectedItemValue.map(x => x.query).includes(item.query)
		}

		return selectedItemValue?.query == item?.query
	}

	const handleOnChange = useCallback((item: TyreFilter) => () => onChange(filterName, item), [filterName])

	return (
		<div className={classes(className, style.wrapper)} >
			{items.map((item, index) => (
				<Tooltip title={translateText(getTitleByGroup(item.query))} key={index}>
					<Button
						icon={getIconByGroup(item.query)}
						size="l"
						disabled={disabled}
						isActive={isValueSelected(item)}
						fakeButton
						onClick={handleOnChange(item)}
						className={classes("filter", style.marginBottom)} />
				</Tooltip>
			))}
		</div >
	)
}

function getStyle() {
	const theme = getStyleTheme()

	return useStyle({
		wrapper: {
			display: "flex",
			width: percent(100),
			flexWrap: "wrap",
			marginLeft: em(-.2),
		},
		marginBottom: {
			marginBottom: theme.margin.m,
			marginLeft: em(.2),
		}
	})(CarTypeSeasonFilters)
}
