import { Box, styled } from "@tm/components"
import { Badge, Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useSwiperSlide } from "swiper/react"
import { StepsNavigation, viewTranslate } from "../../data/models"

type Props = {
    slideObject: StepsNavigation
    activeIndex: number
    idx: number
}

const SlideComponent: React.FC<Props> = ({ slideObject, activeIndex, idx }) => {
    const slide = useSwiperSlide()
    const { translateText } = useLocalization()

    if (!slide.isVisible) {
        return null
    }

    const dist = (2 - Math.abs(idx - activeIndex) / 4) / 2

    const renderContent = () => {
        // to be changed with collors from theme
        let colors = { bgcolor: slideObject.indexNumber < activeIndex ? "#efefef" : "#d7d7d7", fill: "black", borderColor: "" }
        if (slide.isActive) {
            colors = { bgcolor: "#0288d1", fill: "white", borderColor: "#0288d1" }
        }
        if (!slideObject.available) {
            colors = { bgcolor: "#d7d7d7", fill: "#a8a8a8", borderColor: "" }
        }

        const renderBadgeIcon = () => {
            switch (slideObject.badge) {
                case "technician":
                    return <Icon name="defect-bordered" size="xl" />
                case "inspector":
                    return <Icon name="search" size="xl" />
                default:
                    return null
            }
        }

        const renderCompletedBadgeIcon = () => {
            if (slideObject.completed) {
                return <Icon name="success-dark" size="xl" />
            }

            return <Icon name="error-filled" size="xl" />
        }

        return (
            <>
                <Circle sx={colors}>
                    <StyledIcon name={slideObject.icon} size="xl" />
                    <StyledStatusBadge skin="decent" value={renderCompletedBadgeIcon()} />
                    <StyledBadge skin="decent" value={renderBadgeIcon()} />
                </Circle>
                <TextContent style={{ borderColor: colors.borderColor }}>
                    {" "}
                    {translateText(viewTranslate.get(slideObject.stepName) || "not found")}{" "}
                </TextContent>
            </>
        )
    }

    return (
        <div
            className="slideContainer"
            style={{
                position: "relative",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                cursor: "pointer",
                transition: "all 200ms ease-out",
                transformOrigin: "right",
                width: "100%",
                transform: `scale(${dist})`,
            }}
        >
            {renderContent()}
        </div>
    )
}

export default SlideComponent

const Circle = styled(Box)({
    position: "absolute",
    left: "0",
    height: "4.5em",
    width: "4.5em",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%)",
    zIndex: "10",
})

const TextContent = styled(Text)({
    border: "2px solid  transparent",
    paddingLeft: "3.5em",
    width: "10em",
    position: "absolute",
    left: "25px",
    height: "2.65em",
    display: "flex",
    alignItems: "center",
})

const StyledIcon = styled(Icon)({
    width: "2.4em",
    height: "2.4em",
})

const StyledBadge = styled((props: any) => <Badge {...props} />)(() => ({
    top: "-1em",
    right: "unset",
    width: "2.2em",
    height: "2.2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".icon": {
        height: "100%",
        width: "100%",
        padding: "0.1em",
    },
}))

const StyledStatusBadge = styled((props: any) => <Badge {...props} />)(() => ({
    right: "0em",
    top: "3.8em",
    width: "2.2em",
    height: "2.2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    ".icon": {
        height: "100%",
        width: "100%",
    },
}))
