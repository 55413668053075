import { useLocalization } from "@tm/localization"
import { CostEstimationOptions, VatRate, VoucherType } from "@tm/models"
import { useVatRateText } from "@tm/utils"
import { CellContentPosition, Stack, Table, TableCellData, TableColumnData, Typography } from "@tm/components"
import { VoucherPart } from "@tm/data/vouchers/costEstimations"
import ArticleThumbnail from "../../../../../_shared/ArticleThumbnail"
import ArticleNumbers from "../../../../../_shared/ArticleNumbers"
import ArticleDescription from "../../../../../_shared/ArticleDescription"
import PartActions from "../../../../../_shared/PartActions"
import PartType from "./components/PartType"
import { ArticleQuantities } from "../../../../../_shared/ArticleQuantities"
import { ValueLabelStack } from "../../../../../_shared/ValueLabelStack"
import PartFooter from "./components/PartFooter"

type Props = {
    workTaskId: string
    voucherId?: string
    parts?: VoucherPart[]
    currencyCode: string
    currencySymbol: string
    costEstimationOptions?: CostEstimationOptions
    selectedIds: string[]
    isBigScreen: boolean
    costEstimationVehicleId?: string
    onSelectPart(id: string): void
}

export default function PartsTable(props: Props) {
    const { workTaskId, selectedIds, isBigScreen, voucherId, costEstimationVehicleId, costEstimationOptions, parts, currencyCode, currencySymbol } =
        props
    const { translateText, currency } = useLocalization()
    const { vatRateTypeDisplayShortText } = useVatRateText(translateText)

    function renderRebate(rebate?: number) {
        if (!rebate) {
            return null
        }

        return <ValueLabelStack label={translateText(54)} value={`${rebate.round(0.01)}%`} />
    }

    function renderVatRate(vatRate?: VatRate) {
        if (!vatRate) {
            return "-"
        }

        return <ValueLabelStack label={translateText(706)} value={vatRateTypeDisplayShortText(vatRate)} />
    }

    function renderPrice(priceLabelId: string, value?: number) {
        if (!value || !currencyCode) {
            return undefined
        }

        return (
            <Stack justifyContent="flex-end">
                <Typography variant="label" alignSelf="flex-end">
                    {priceLabelId}
                </Typography>
                <Typography fontWeight="bold">{currency(value, currencySymbol || currencyCode)}</Typography>
            </Stack>
        )
    }

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: "" }, // Thumbnail
            { header: "" }, // Manufacturer
            { header: "" }, // ArticleNumbers
            { header: "" }, // Description
            { header: "" }, // PartType
            { header: "", alignContent: CellContentPosition.left }, // RetailPrice
            { header: "" }, // Quantity
            { header: "" }, // Rebate
            { header: "" }, // VatRate
            { header: "", alignContent: CellContentPosition.right }, // TotalPrice
            { header: "", alignContent: CellContentPosition.right }, // Actions
        ]

        return columns
    }

    function getCellData(voucherPart: VoucherPart): TableCellData[] {
        const { articleInformation, mainPartItemId, calculation, quantityValue, retailNetPrice, purchaseNetPrice } = voucherPart
        const { rebate, vatRate, offerPriceTotal, regularPriceWithSurcharge } = calculation
        const {
            thumbnailUrl,
            articleInfoType,
            articleNumber,
            supplierId,
            supplierName,
            wholesalerArticleNumber,
            productGroupId,
            productGroupName,
            description,
            additionalDescription,
        } = articleInformation

        const isLinkedItem = !!mainPartItemId
        const cellData: TableCellData[] = [
            {
                displayValue: <ArticleThumbnail articleInfoType={articleInfoType} mainPartItemId={mainPartItemId} thumbnailUrl={thumbnailUrl} />,
                id: "part_1",
            },
            { displayValue: supplierName, id: "part_2" },
            {
                displayValue: (
                    <ArticleNumbers
                        articleType={articleInfoType}
                        articleNumber={articleNumber}
                        productGroupId={productGroupId}
                        supplierId={supplierId}
                        wholesalerArticleNumber={wholesalerArticleNumber}
                        isLinkedItem={isLinkedItem}
                    />
                ),
                id: "part_3",
            },
            {
                displayValue: (
                    <ArticleDescription
                        description={description}
                        additionalDescription={additionalDescription}
                        productGroupName={productGroupName}
                        isBigScreen={isBigScreen}
                        isLinkedItem={isLinkedItem}
                    />
                ),
                id: "part_4",
            },
            { displayValue: <PartType articleInfoType={articleInformation.articleInfoType} />, id: "part_5" },
            {
                displayValue: renderPrice(
                    translateText(costEstimationOptions?.priceTypeMode ? 55 : 1620),
                    regularPriceWithSurcharge ?? purchaseNetPrice
                ),
                id: "part_6",
            },
            {
                displayValue: <ArticleQuantities confirmedQuantityValue={quantityValue} isBigScreen={isBigScreen} hideReturnQuantity />,
                id: "part_7",
            },
            { displayValue: renderRebate(rebate), id: "part_8" },
            { displayValue: renderVatRate(vatRate), id: "part_9" },
            {
                displayValue: renderPrice(translateText(57), offerPriceTotal ?? retailNetPrice),
                id: "part_10",
            },
            {
                displayValue: (
                    <PartActions
                        workTaskId={workTaskId}
                        voucherId={voucherId}
                        voucherType={VoucherType.CostEstimation}
                        costEstimationVehicleId={costEstimationVehicleId}
                        voucherPart={voucherPart}
                        isSelected={selectedIds?.includes(voucherPart.partItemId) || false}
                        isBigScreen={isBigScreen}
                        currencyCode={currencyCode}
                        onSelectPart={props.onSelectPart}
                    />
                ),
                id: "part_11",
            },
        ]

        return cellData
    }
    function getDisplayData() {
        return parts?.map((part, index) => ({
            cells: getCellData(part),
            id: `${index}`,
            customRow: false,
            active: false,
            extendedContent: (
                <PartFooter
                    workTaskId={workTaskId}
                    part={part}
                    costEstimationOptions={costEstimationOptions}
                    isBigScreen={isBigScreen}
                    onSelectPart={props.onSelectPart}
                    currencySymbol={currencySymbol ?? ""}
                    currencyCode={currencyCode ?? ""}
                />
            ),
        }))
    }

    return <Table headerBackground="transparent" columns={getColumns()} rows={getDisplayData()} overflowY="unset" />
}
