import { uniqueId } from "@tm/utils"
import { FSArticle } from "../data/repositories/fastcalculator-calculation/mapper"
import { ArticleIdentifierExt } from "../data/models"

export function mapCalcArticleToArticleIdentifier(part: FSArticle, productGroupId: number, tecdocTypeNo?: number): ArticleIdentifierExt {
    return {
        id: part.id ?? uniqueId(),
        internalId: part.iArtNr ?? 0,
        supplierId: part.supplierId,
        supplierArticleNo: part.supplierArtNr,
        productGroupId,
        tecdocTypeNo,
        fittingPosition: part.binKrit100 ?? 0,
    }
}
