import { getStoredAuthorization, ajax } from "@tm/utils"
import { channel } from "@tm/models"
import { getLocalization } from "@tm/localization"
import { getBundleParams } from "../../../utils"
import { FSCalculation, mapCalculationData } from "./mapper"
import { GetCalculation } from "./model"
import { endPageActions, maintenancePlanActions } from "../.."

export function getCalculation(data: GetCalculation): Promise<FSCalculation | undefined> {
    const url = `${getServiceUrl()}/fastcalculator/FastServiceCalculate`
    const authorization = getStoredAuthorization()
    const body = data
    const { translateText } = getLocalization()

    return ajax({ url, body, authorization, method: "POST" })
        .then((res) => {
            return mapCalculationData(res)
        })
        .catch(() => {
            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(13691), skin: "danger", closeDelay: 3000 })
            endPageActions.resetEndPage()
            maintenancePlanActions.resetSelections()
            return undefined
        })
}

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
