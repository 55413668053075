import { getInitialState } from "."
import { fastServiceStore } from "../store"

export function resetNavigation() {
    fastServiceStore.setState(getInitialState(), false, "Reset Navigation Slice")
}

export function changeStep(selectedStep: string) {
    fastServiceStore.setState(
        (state) => ({
            navigation: {
                ...state.navigation,
                active: selectedStep,
            },
        }),
        false,
        "Navigation change step"
    )
}
