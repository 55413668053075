import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { Icon, Tooltip } from "@tm/components"
import { useHistory, useParams } from "react-router"
import { getBundleParams } from "../../utils"
import { useVrcLookupModulesLoadable } from "../../data/hooks/vrc-lookup"

export default function VrcScanButton() {
    const { translateText } = useLocalization()
    const matchParams = useParams<{ workTaskId?: string }>()
    const history = useHistory()
    const modules = useVrcLookupModulesLoadable()

    if (!modules.length) {
        return null
    }

    const handleButtonClick = () => {
        history.push(
            renderRoute(getBundleParams().vrcLookupRoute, { ...matchParams, workTaskId: matchParams.workTaskId || encodeUniqueId(uniqueId()) })
        )
    }

    return (
        <Tooltip title={translateText(12617)} enterDelay={500}>
            <Icon sx={{ cursor: "pointer" }} name="scan" onClick={handleButtonClick} />
        </Tooltip>
    )
}
