import { Suspense } from "react"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { BonusPoints, Box, Icon, Stack, Typography } from "@tm/components"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { LoaderSmall, StackRow } from "../StyledComponents"
import ErrorButton from "../_shared/ErrorButton"
import BasketErpTotalInOrder from "../_shared/BasketErpTotalInOrder"
import { useBasketErpTotalInOrder } from "../_shared/BasketErpTotalInOrder/useBasketErpTotalInOrder"

type Props = {
    /** @default "default" */
    displayMode?: "default" | "default-with-bonus" | "flat"
}

export function Totals(props: Props & { workTaskId: string }) {
    const { displayMode, workTaskId } = props
    const { translate, translateText } = useLocalization()
    const { userSettings } = useUser() ?? {}

    const { workTaskBasketCalculationLoading, workTaskBasketCalculation, basket } = useWorkTaskBasketState(workTaskId)
    const { bonusPointsCalculation, bonusPointsLoading, basketWithErrors, basketErpInfosIndicator } = basket.state
    const { taxText } = useBasketErpTotalInOrder(workTaskBasketCalculation?.calculatedOrder?.erpTotalsInOrder)
    const showPurchasePrice = userSettings?.showPurchasePrice ?? true

    function renderTotalPrice() {
        return (
            <StackRow gap={1}>
                <BasketErpTotalInOrder erpTotalsInOrder={workTaskBasketCalculation?.calculatedOrder?.erpTotalsInOrder} />
                {basketWithErrors && (
                    <ErrorButton
                        basketWithErrors
                        workTaskIds={[workTaskId]}
                        additionalErrorTextId={basketErpInfosIndicator.hasErrors ? 13663 : undefined}
                    />
                )}
            </StackRow>
        )
    }

    function renderAdditionalInfo() {
        return <Typography variant="label">*{translate(802)}</Typography>
    }

    function renderNumbers() {
        const numberOfPositions = workTaskBasketCalculation?.calculatedOrder?.numberOfPositionsInOrder || 0
        const numberOfParts = workTaskBasketCalculation?.calculatedOrder?.numberOfItemsInOrder || 0

        return (
            <Typography variant="label">
                {numberOfPositions} {translate(479)}, {numberOfParts} {translate(90)}
            </Typography>
        )
    }

    // Used by promofficina
    function renderBonusPoints() {
        const bonusPoints = bonusPointsCalculation?.bonusPointTotalsInOrder
        if (!bonusPoints) {
            return
        }

        return (
            <Stack spacing={0.5} marginBottom="4px">
                {Object.entries(bonusPoints).map(([key, value], idx) => {
                    return (
                        <Box alignSelf="flex-start" key={idx}>
                            <BonusPoints
                                bonusSystem={{
                                    id: parseInt(key),
                                    imageUrl: "",
                                    value: value ?? 0,
                                    name: translateText(13114),
                                }}
                                variant="single-line-label"
                            />
                        </Box>
                    )
                })}
            </Stack>
        )
    }

    // Used bei STG, Neimcke, PV, Rhiagch
    function renderFlatContent() {
        return (
            <Stack direction="row" alignItems="center">
                {renderTotalPrice()}
                {renderAdditionalInfo()}
            </Stack>
        )
    }

    function renderDefaultContent() {
        return (
            <>
                {renderTotalPrice()}
                {displayMode === "default-with-bonus" && renderBonusPoints()}
                {renderNumbers()}
                {renderAdditionalInfo()}
            </>
        )
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Icon name="cart" />
            <Stack>
                <Typography variant="label">
                    {translate(57)} {translate(!showPurchasePrice ? 1620 : 55)}* {taxText}
                </Typography>
                {(workTaskBasketCalculationLoading || bonusPointsLoading) && <LoaderSmall />}
                {displayMode === "flat" ? renderFlatContent() : renderDefaultContent()}
            </Stack>
        </Stack>
    )
}

export default function Wrapper(props: Props) {
    const workTaskId = useWorkTask()?.workTaskId
    if (!workTaskId) {
        return null
    }
    return (
        <Suspense fallback={<LoaderSmall />}>
            <Totals {...props} workTaskId={workTaskId} />
        </Suspense>
    )
}
