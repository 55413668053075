import { useCallback, MouseEvent as ReactMouseEvent } from "react"
import { useLocalization } from "@tm/localization"
import { Box } from "@tm/components"
import { TyreFilter } from "@tm/models"
import { useSelector } from "react-redux"
import { IListFilters } from "../../data/models"
import { MainState } from "../main"
import { CustomRangeSlider } from "../../../../tyres/src/components/_shared"
import { AvailabilityFilter } from "../../../../tyres/src/components/list/components"

type Props = {
    onChange: (filter: TyreFilter | AvailabilityFilter, path: IListFilters) => void
    onResetFilters?: (path: IListFilters, event?: ReactMouseEvent) => void
}

export const EuTyreLabel = ({ onChange, onResetFilters }: Props) => {
    const { translateText } = useLocalization()
    const { filters, selectedFilters } = useSelector((s: MainState) => ({
        filters: s.tyresList.filters,
        selectedFilters: s.tyresList.selectedFilters,
    }))

    const handleFilterChange = useCallback(
        (filter: TyreFilter | AvailabilityFilter, path: string) => {
            onChange(filter, path as IListFilters)
        },
        [onChange]
    )

    return (
        <Box display="flex" flexWrap="wrap" width="95%" padding="0">
            <CustomRangeSlider
                title={translateText(722)}
                items={filters.fuelEfficiency}
                icon={"gas-station"}
                onChange={(filter) => handleFilterChange(filter, "fuelEfficiency")}
                onReset={() => onResetFilters?.("fuelEfficiency")}
                selectedItem={selectedFilters.fuelEfficiency}
                isV2
            />
            <CustomRangeSlider
                title={translateText(13279)}
                items={filters.wetGripClass}
                icon={"rain"}
                onChange={(filter) => handleFilterChange(filter, "wetGripClass")}
                onReset={() => onResetFilters?.("wetGripClass")}
                selectedItem={selectedFilters.wetGripClass}
                isV2
            />
            <CustomRangeSlider
                title={translateText(13280)}
                items={filters.externalRolling}
                icon={"sound"}
                onChange={(filter) => handleFilterChange(filter, "externalRolling")}
                onReset={() => onResetFilters?.("externalRolling")}
                selectedItem={selectedFilters.externalRolling}
                minimized
                isV2
            />
        </Box>
    )
}
