import { ErpIcon, IMicros, TeccomErpInformation, WarehouseDispatchType } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { Loader } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { BasketPart } from "../../models"

type Props = {
    erpInfoLoading?: boolean
    part: BasketPart
    warehouseDispatchType?: WarehouseDispatchType
    showAvailabilityOnly?: boolean
    onOpenArticleDetails?(
        productGroupId?: number,
        supplierArticleNumber?: string,
        supplierId?: number,
        subPage?: string,
        scrollTo?: string,
        externalBasketItemId?: string
    ): void
    onTeccomInfoLoaded?(partId: string, teccom: TeccomErpInformation): void
}

export default function PartErpInfoComponent(props: Props) {
    const { translateText } = useLocalization()
    const { part, erpInfoLoading, warehouseDispatchType, showAvailabilityOnly, onOpenArticleDetails, onTeccomInfoLoaded } = props
    const { partItem, erpInfoRequest, erpInfoResponse } = part
    const { supplierId, productGroupId, articleNumber } = partItem.articleInformation

    const { renderMicro } = useMicro<IMicros>()

    if (erpInfoLoading) {
        return <Loader size="small" />
    }
    if (erpInfoResponse && erpInfoRequest && partItem.orderItem) {
        const { tour, warehouses, isTeccomRequestAvailable, availability, specialIcons, memos, useSpecialProcurement } = erpInfoResponse
        if (!availability) {
            return null
        }

        const selectedShipmentMode = erpInfoResponse.orderOptions?.shipmentModes?.shipmentModes.find((e) => e.isSelected)
        const partDispatchType = selectedShipmentMode?.dispatchType
        let dispatchType = warehouseDispatchType
        if (partDispatchType && partDispatchType !== WarehouseDispatchType.Delivery) {
            dispatchType = partDispatchType
        }

        const basketSpecialIcons: ErpIcon[] = []

        if (part?.erpInfoResponse?.isSellOffArticle) {
            basketSpecialIcons.push({
                topmotiveId: "sale-off",
                useIconFromUrl: false,
                url: "",
                description: translateText(13786),
            })
        }

        if (part?.erpInfoResponse?.isNonReturnable) {
            basketSpecialIcons.push({
                topmotiveId: "no-return",
                useIconFromUrl: false,
                url: "",
                description: translateText(13790),
            })
        }

        if (specialIcons) {
            basketSpecialIcons.push(...specialIcons)
        }

        return (
            <>
                {showAvailabilityOnly
                    ? renderMicro("erp", "display-only-availability", {
                          availability,
                          tour,
                          warehouses,
                          onClick: () => {
                              onOpenArticleDetails?.(productGroupId, articleNumber, supplierId, "stocks", undefined, partItem.externalId)
                          },
                      })
                    : renderMicro("erp", "erp-info-basket", {
                          requestItem: erpInfoRequest,
                          availability,
                          tour,
                          warehouses,
                          isTeccomRequestAvailable,
                          warehouseDispatchType: dispatchType,
                          distributorId: partItem.orderItem.distributorId,
                          specialIcons: basketSpecialIcons,
                          memos,
                          useSpecialProcurement,
                          selectedShipmentMode, // NEXT-24130
                          onClick: () => {
                              onOpenArticleDetails?.(
                                  productGroupId,
                                  articleNumber,
                                  supplierId,
                                  useSpecialProcurement ? "special-procurement" : "stocks"
                              )
                          },
                          onTeccomInfoLoaded,
                      })}
            </>
        )
    }

    return null
}
