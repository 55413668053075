import { Box, Card, Theme, Typography } from "@tm/components"
import { Collapsible } from "@tm/controls"
import { ArticleInfoType, VoucherTotals, VoucherType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { ExtendedOrderVoucherItem, OrderVoucherItemsByWorkTask } from "../../../../../../../data/model"
import PartsTable from "./PartsTable"
import TotalNumbers from "../../_shared/TotalNumbers"
import { TotalNumberType } from "../../../../../business"
import PartsSelection from "../../_shared/PartsSelection"
import { mapOrderedItemToArticle } from "../../../../../../../data/mapper"

type Props = {
    workTaskId: string
    voucherId?: string
    orderItemsByWorkTask: OrderVoucherItemsByWorkTask
    hideVehicleLogos?: boolean
    isBigScreen: boolean
    theme: Theme
    selectedIds: string[]
    totals: VoucherTotals
    onShowReturn(returnNumber: string): void
    onSelectPart(id: string): void
    onSelectAll(): void
    onUnselectAll(): void
    onOpenArticleSearch(query: string): void
}

export default function OrderWorkTasks(props: Props) {
    const { orderItemsByWorkTask, hideVehicleLogos, isBigScreen, theme, selectedIds, totals, voucherId, workTaskId } = props
    const { translateText } = useLocalization()

    const { customerInfo, workTaskNumber } = orderItemsByWorkTask.workTaskInfo

    function renderOrderTotalNumbers() {
        return <TotalNumbers type={TotalNumberType.parts} totalNumber={totals.numberOfPositions} voucherType={VoucherType.Order} />
    }

    function renderPartsSelection(orderVoucherItems: Array<ExtendedOrderVoucherItem>) {
        if (orderVoucherItems.length) {
            // Currently the add-to-basket micro only supports a list of a single artcileInfoType
            const tecDocParts = orderVoucherItems.filter((part) => part.articleInfoType === ArticleInfoType.TecdocArticle)

            const selectedParts = tecDocParts.filter((part) => selectedIds.includes(part.id))
            if (selectedParts.length) {
                return (
                    <PartsSelection
                        allSelected={tecDocParts.length === selectedParts.length}
                        selectedArticles={selectedParts.map(mapOrderedItemToArticle)}
                        onSelectAll={props.onSelectAll}
                        onUnselectAll={props.onUnselectAll}
                    />
                )
            }
        }
    }

    return (
        <Card key={orderItemsByWorkTask.workTaskInfo.workTaskId}>
            <Collapsible
                name={workTaskNumber + (customerInfo ? `, ${customerInfo.displayName}` : "")}
                initiallyOpened
                renderHeaderAppendix={renderOrderTotalNumbers}
            >
                <Box position="relative" px={2} pb={2}>
                    <Typography variant="h2">{translateText(90)}</Typography>
                    {renderPartsSelection(orderItemsByWorkTask.orderVoucherItems)}
                    <PartsTable
                        workTaskId={workTaskId}
                        voucherId={voucherId}
                        selectedIds={selectedIds}
                        parts={orderItemsByWorkTask.orderVoucherItems}
                        hideVehicleLogos={hideVehicleLogos}
                        isBigScreen={isBigScreen}
                        theme={theme}
                        onOpenArticleSearch={props.onOpenArticleSearch}
                        onSelectPart={props.onSelectPart}
                        onShowReturn={props.onShowReturn}
                    />
                </Box>
            </Collapsible>
        </Card>
    )
}
