import { PropsWithChildren } from "react"
import { FiltersContext, FiltersContextData, useFiltersContext } from "../../../ContextProvider/Filters"
import { useVehicle } from "../../../hooks"
import { useFilters } from "./useFilters"
import { useOePartsListParamsContext } from "../ListParams"

export function OePartsFiltersProvider(props: PropsWithChildren<unknown>) {
    const params = useOePartsListParamsContext()
    const vehicle = useVehicle()
    const filters = useFilters(vehicle, params, true)
    return <FiltersContext.Provider value={filters}>{props.children}</FiltersContext.Provider>
}

export const useOePartsFiltersContext = useFiltersContext
