import { ComponentType } from "react"
import Start from "../components/start/component"
import MaintenancePlan from "../components/maintenance-plan/component"
import Technician from "../components/technician/component"
import MaintenanceReview from "../components/maintenance-review/component"
import MaintenanceCompletition from "../components/maintenance-completion/component"
import Tester from "../components/tester/component"
import TestDrive from "../components/test-drive/component"
import TestComplete from "../components/test-complete/component"
import End from "../components/end/component"
import { Works } from "../components/works/component"
import InspectionWork from "../components/inspection-work/component"
import TyresWheels from "../components/tyres-wheels/component"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const navigationStepComponent: { [key: string]: ComponentType<any> } = {
    start: Start,
    maintenancePlan: MaintenancePlan,
    technician: Technician,
    engineCompartment: Works,
    underVehicle: Works,
    tiresWheels: TyresWheels,
    exterior: Works,
    interior: Works,
    general: Works,
    maintenanceReview: MaintenanceReview,
    maintenanceComplete: MaintenanceCompletition,
    tester: Tester,
    inspection: MaintenanceReview,
    testDrive: TestDrive,
    checkWork: InspectionWork,
    testComplete: TestComplete,
    end: End,
}
