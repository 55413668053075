import { SelectedFilters } from "../data/models"

export function compareTyreFilters(previousFilters: SelectedFilters, newFilters: SelectedFilters) {
    if (
        previousFilters.width === newFilters.width &&
        previousFilters.height === newFilters.height &&
        previousFilters.inch === newFilters.inch &&
        previousFilters.loadIndex === newFilters.loadIndex &&
        previousFilters.speedIndex === newFilters.speedIndex &&
        previousFilters.manufacturerId === newFilters.manufacturerId
    ) {
        return false
    }

    return true
}
