import { useCallback, useEffect, useMemo, useState } from "react"
import { Article, ArticleListType, channel, TyreArticleItemState, TyreArticle, SupplierArticleDto, ErpInformation } from "@tm/models"
import { ArticleItem, ArticleItemStateProvider } from "@tm/components"
import { copyToClipboard } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { isEqual } from "lodash"
import AvailabilityComponent from "../../../../erp/src/_shared/availability-wrapper"
import {
    useBasketQuantitiesContext,
    useErpInfosContext,
    useListOptionsContext,
    usePreviouslyOrderedArticlesContext,
    useProductGroupRepairTimesDataContext,
} from "../ListV2/ContextProvider"
import {
    useArticleActions,
    useArticleErpInfos,
    useArticleExtendedDeliveryInfo,
    useArticleImages,
    useArticleNotes,
    useArticleNotifications,
    useArticleTradeReferences,
    useDisplayArticle,
    useGetRepairTimesUrl,
    useHandleClickAvailability,
    useHandleClickDetails,
    useHandleClickImage,
    useHandleClickReference,
    useHandleRemoveFromBasket,
    useLoadErpInfos,
    usePartsViewOptions,
    useShowArticleDetails,
    useWatchlist,
} from "../ListV2/hooks"
import { useArticleListConfiguration } from "../ListV2/ArticleListConfiguration"
import { useDmsArticleInfo } from "../../../../dms/src/hooks/useDmsArticleInfo"
import { emptyFunc, useTyreArticleSelection } from "./hooks"

type WheelsTyresArticleItemProps = {
    article: TyreArticle
    selectedArticles: Article[]
    onAddToBasket: (article: Article, quantity: number) => void
    onChangeQuantity: (article: Article, quantity: number) => void
    toggleSelectedArticle: (article: Article) => void
}

export function WheelsTyresArticleItem({
    article,
    selectedArticles,
    onAddToBasket,
    onChangeQuantity,
    toggleSelectedArticle,
}: WheelsTyresArticleItemProps) {
    const isCompact = usePartsViewOptions().partsViewSettings.compactView
    const listType: ArticleListType = "pure"
    const { translateText } = useLocalization()

    const [expanded, setExpanded] = useState(isCompact)

    const erpInfosData = useErpInfosContext()
    const displayArticle = useDisplayArticle(article, [], undefined, false)
    const { productGroupRepairTimes } = useProductGroupRepairTimesDataContext()
    const articleImages = useArticleImages(article)
    const notes = useArticleNotes(article)
    const getRepairTimesUrl = useGetRepairTimesUrl()

    const previouslyOrderedArticles = usePreviouslyOrderedArticlesContext()
    const showArticleNotes = useArticleListConfiguration((config) => config.enableArticleNotes)
    const feedbackConfiguration = useArticleListConfiguration((config) => config.feedbackConfiguration)
    const showExtendedDelivery = useArticleListConfiguration((config) => !config.hideExtendedDeliveryComponent)
    const options = useListOptionsContext()

    const showArticleDetails = useShowArticleDetails()
    const { basketQuantities, updateBasketQuantities } = useBasketQuantitiesContext()
    const [articleQuantity, setArticleQuantity] = useState(article.quantity)
    const [originalQuantity, setOriginalQuantity] = useState(article.quantity)
    const handleClickDetails = useHandleClickDetails(article, articleQuantity)
    const handleClickAvailability = useHandleClickAvailability(article, articleQuantity, erpInfosData)
    const { handleClickImage, handleCloseImage, showImageViewer } = useHandleClickImage(article)
    const handleClickReference = useHandleClickReference(article, articleQuantity, showArticleDetails)
    const tradeReferences = useArticleTradeReferences(article)
    const loadErpInfos = useLoadErpInfos(erpInfosData, tradeReferences)
    const articleErpInfos = useArticleErpInfos(article, articleQuantity, erpInfosData)

    useEffect(() => {
        setExpanded(!isCompact)
    }, [isCompact])

    const toggleExpand = useCallback(() => {
        setExpanded((prev) => !prev)
    }, [])

    const alreadyOrdered = useMemo(() => {
        return (
            previouslyOrderedArticles?.some((x) => x.supplierArticleNumber === article.supplierArticleNo && x.supplierId === article.supplier.id) ??
            false
        )
    }, [previouslyOrderedArticles, article.supplierArticleNo, article.supplier.id])

    const repairTimeProviders = productGroupRepairTimes?.productGroups[article.productGroup.id]
    const repairTimesUrl = useMemo(() => {
        if (!!repairTimeProviders?.length && repairTimeProviders) {
            return getRepairTimesUrl(article, repairTimeProviders)
        }
    }, [repairTimeProviders, getRepairTimesUrl, article])

    const basketQuantity = useMemo(
        () =>
            basketQuantities.find((q) =>
                isEqual(q.article, {
                    dataSupplierArticleNumber: article.supplierArticleNo,
                    dataSupplierId: article.supplier.id,
                    productGroupId: article.productGroup.id,
                } as SupplierArticleDto)
            ),
        [basketQuantities, article]
    )

    const articleActions = useArticleActions({
        article,
        articleErpInfo: articleErpInfos.default,
        feedbackConfiguration,
        isNotesEnabled: showArticleNotes,
        listType,
        notes: notes.notes,
        repairTimesUrl,
        onClickDetails: handleClickDetails,
        toggleExpand,
        toggleNotes: notes.toggle,
        hideAlternatives: true,
    })

    const removeFromBasket = useHandleRemoveFromBasket()

    const handleRemoveFromBasket = useCallback(
        async (itemIds?: string[]) => {
            const ids = itemIds ?? basketQuantity?.articleQuantities?.allPartItemIds
            if (ids?.length) {
                await removeFromBasket(ids)
                updateBasketQuantities([article])
            }
        },
        [basketQuantity, removeFromBasket, updateBasketQuantities, article]
    )

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setArticleQuantity(value)

            if (loadErpInfo) {
                setOriginalQuantity(value)

                if (articleErpInfos.default) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.default.erpSystem })
                }
                if (articleErpInfos.alternative) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.alternative.erpSystem })
                }
            }

            onChangeQuantity(article, value)
        },
        [onChangeQuantity, article, articleErpInfos.default, articleErpInfos.alternative, loadErpInfos]
    )

    const handleAddToBasket = useCallback(
        async (quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number) => {
            onAddToBasket(article, quantity ?? articleQuantity)
        },
        [article, articleQuantity, onAddToBasket]
    )

    const notifications = useArticleNotifications(articleErpInfos.default)
    const watchList = useWatchlist(article)
    const selection = useTyreArticleSelection(selectedArticles, toggleSelectedArticle)
    const extendedDeliveryInfo = useArticleExtendedDeliveryInfo(showExtendedDelivery, articleErpInfos.default)
    const dmsArticleInfo = useDmsArticleInfo(article)

    const handleCopyArticleNumber = useCallback(
        (number: string) => {
            copyToClipboard(number)
            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1920) })
        },
        [translateText]
    )

    const showExtendedAvailabilityContainer =
        !!erpInfosData.erpConfig?.erpSystemConfigs && erpInfosData.erpConfig?.erpSystemConfigs.filter((x) => x.useForGetErpInformation).length > 1

    const articleState = useMemo<TyreArticleItemState>(
        () => ({
            article,
            articleActions,
            articleErpInfos,
            articleImages,
            displayArticle,
            dmsArticleInfo,
            expanded,
            extendedDeliveryInfo,
            isVehicleDependent: false,
            alreadyOrdered,
            notes,
            notifications,
            options,
            originalQuantity,
            quantity: articleQuantity,
            selection,
            showImageViewer,
            type: "Tyres",
            watchList,
            basketQuantity,
            showExtendedAvailabilityContainer,
            addToBasketButtonDisabled: false,
            addToCostEstimationButtonDisabled: true,
            handleAddToBasket,
            handleAddToCostEstimation: emptyFunc,
            handleChangeQuantity,
            handleClickDetails,
            handleClickAvailability,
            handleClickImage,
            handleClickReference,
            handleCloseImage,
            handleRemoveFromBasket,
            handleCopyArticleNumber,
            toggleExpand,
            AvailabilityComponent,
        }),
        [
            article,
            articleActions,
            articleErpInfos,
            articleImages,
            displayArticle,
            dmsArticleInfo,
            expanded,
            extendedDeliveryInfo,
            alreadyOrdered,
            notes,
            notifications,
            options,
            originalQuantity,
            articleQuantity,
            selection,
            showImageViewer,
            watchList,
            basketQuantity,
            showExtendedAvailabilityContainer,
            handleAddToBasket,
            handleChangeQuantity,
            handleClickDetails,
            handleClickAvailability,
            handleClickImage,
            handleClickReference,
            handleCloseImage,
            handleRemoveFromBasket,
            handleCopyArticleNumber,
            toggleExpand,
        ]
    )
    return (
        <ArticleItemStateProvider value={articleState}>
            <ArticleItem variant="Tyres" />
        </ArticleItemStateProvider>
    )
}
