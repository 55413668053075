import { PropsWithChildren } from "react"
import { createContext } from "use-context-selector"
import { ArticleListActions } from "../../../models"
import { useArticleListActions } from "./useArticleListActions"
import { useArticleListActionsContext } from "../../../ContextProvider/ArticleListActions"

export const ArticleListActionsContext = createContext<ArticleListActions | undefined>(undefined)

export function WholesalerArticleNumbersArticleListActionsProvider(props: PropsWithChildren<unknown>) {
    const articleListActions = useArticleListActions()
    return <ArticleListActionsContext.Provider value={articleListActions}>{props.children}</ArticleListActionsContext.Provider>
}

export const useWholesalerArticleNumbersArticleListActionsContext = useArticleListActionsContext
