import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function demoActivationFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const demoActivationModule = getModuleFromUserContext(userContext, UserModuleType.DemoActivation)

    if (!demoActivationModule) {
        return undefined
    }

    return {
        inspection: {
            moduleRoute: "/modal/^td/compare",
            items: {
                haynespro: {
                    sort: 0,
                    name: "HaynesPro",
                    activatable: true,
                    setAsActiveVehicleDataProviderOnSelect: {
                        inspectionData: "haynespro",
                    },
                },
                autodata: {
                    sort: 1,
                    name: "Autodata",
                    activatable: true,
                    setAsActiveVehicleDataProviderOnSelect: {
                        inspectionData: "autodata",
                    },
                },
                tecrmi: {
                    sort: 2,
                    name: "TecRMI",
                    activatable: true,
                    setAsActiveVehicleDataProviderOnSelect: {
                        inspectionData: "tecrmi",
                    },
                },
            },
        },
        tech: {
            moduleRoute: "/modal/^td/compare",
            items: {
                haynespro: {
                    sort: 0,
                    name: "HaynesPro",
                    activatable: true,
                    setAsActiveVehicleDataProviderOnSelect: {
                        technicalData: "haynespro",
                    },
                },
                autodata: {
                    sort: 1,
                    name: "Autodata",
                    activatable: true,
                    setAsActiveVehicleDataProviderOnSelect: {
                        technicalData: "autodata",
                    },
                },
                tecrmi: {
                    sort: 2,
                    name: "TecRMI",
                    activatable: true,
                    setAsActiveVehicleDataProviderOnSelect: {
                        technicalData: "tecrmi",
                    },
                },
            },
        },
        gti: {
            moduleRoute: "/modal/^gpi/compare",
            items: {
                dat: {
                    sort: 0,
                    name: "DAT",
                    activatable: true,
                    setAsActiveVehicleDataProviderOnSelect: {
                        gpi: "dat",
                    },
                },
                eurotax: {
                    sort: 1,
                    name: "Eurotax",
                    activatable: true,
                    setAsActiveVehicleDataProviderOnSelect: {
                        gpi: "eurotax",
                    },
                },
            },
        },
        "fast-calculator-next": {
            moduleRoute: "/modal/^fast-calc-next/compare",
            items: {
                "fast-calculator-next": {
                    sort: 0,
                    activatable: true,
                    name: "{{598}}",
                    disabledFunction: "FASTCALC-ANY-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        fastCalculator: "topmotive",
                    },
                },
            },
        },
    }
}
