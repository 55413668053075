import { useCallback, useEffect, useMemo, useState } from "react"
import { ArticleListType, channel, TyreArticleItemState, TyreArticle } from "@tm/models"
import { ArticleItem, ArticleItemStateProvider } from "@tm/components"
import { copyToClipboard } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import {
    useArticleActions,
    useDisplayArticle,
    useHandleClickDetails,
    useHandleClickImage,
    useHandleClickReference,
    useArticleImages,
    useArticleNotes,
    useArticleNotifications,
    useWatchlist,
    useArticleExtendedDeliveryInfo,
    useShowArticleDetails,
    useGetRepairTimesUrl,
    useHandleClickAvailability,
    usePartsViewOptions,
} from "../ListV2/hooks"
import AvailabilityComponent from "../../../../erp/src/_shared/availability-wrapper"
import { useArticleSelection } from "../ListV2/hooks/useArticleSelection"
import { useDmsArticleInfo } from "../../../../dms/src/hooks/useDmsArticleInfo"
import { useArticleBasketActions } from "../PureList/hooks"
import {
    useErpInfosContext,
    useListOptionsContext,
    usePreviouslyOrderedArticlesContext,
    useProductGroupRepairTimesDataContext,
} from "../ListV2/ContextProvider"
import { useArticleListConfiguration } from "../ListV2/ArticleListConfiguration"

type TyresArticleItemProps = {
    article: TyreArticle
}

export function TyresArticleItem({ article }: TyresArticleItemProps) {
    const isCompact = usePartsViewOptions().partsViewSettings.compactView
    const listType: ArticleListType = "pure"
    const { translateText } = useLocalization()

    const [expanded, setExpanded] = useState(isCompact)

    const {
        originalQuantity,
        articleQuantity,
        basketQuantity,
        articleErpInfos,
        addButtonsDisabled,
        handleAddToBasket,
        handleAddToCostEstimation,
        handleRemoveFromBasket,
        handleChangeQuantity,
    } = useArticleBasketActions(article)
    const erpInfosData = useErpInfosContext()
    const displayArticle = useDisplayArticle(article, [], undefined, false)
    const { productGroupRepairTimes } = useProductGroupRepairTimesDataContext()
    const articleImages = useArticleImages(article)
    const notes = useArticleNotes(article)
    const getRepairTimesUrl = useGetRepairTimesUrl()

    const previouslyOrderedArticles = usePreviouslyOrderedArticlesContext()
    const showArticleNotes = useArticleListConfiguration((config) => config.enableArticleNotes)
    const feedbackConfiguration = useArticleListConfiguration((config) => config.feedbackConfiguration)
    const showExtendedDelivery = useArticleListConfiguration((config) => !config.hideExtendedDeliveryComponent)
    const options = useListOptionsContext()

    const showArticleDetails = useShowArticleDetails()
    const handleClickDetails = useHandleClickDetails(article, articleQuantity)
    const handleClickAvailability = useHandleClickAvailability(article, articleQuantity, erpInfosData)
    const { handleClickImage, handleCloseImage, showImageViewer } = useHandleClickImage(article)
    const handleClickReference = useHandleClickReference(article, articleQuantity, showArticleDetails)

    useEffect(() => {
        setExpanded(!isCompact)
    }, [isCompact])

    const toggleExpand = useCallback(() => {
        setExpanded((prev) => !prev)
    }, [])

    const alreadyOrdered = useMemo(() => {
        return (
            previouslyOrderedArticles?.some((x) => x.supplierArticleNumber === article.supplierArticleNo && x.supplierId === article.supplier.id) ??
            false
        )
    }, [previouslyOrderedArticles, article.supplierArticleNo, article.supplier.id])

    const repairTimeProviders = productGroupRepairTimes?.productGroups[article.productGroup.id]
    const repairTimesUrl = useMemo(() => {
        if (!!repairTimeProviders?.length && repairTimeProviders) {
            return getRepairTimesUrl(article, repairTimeProviders)
        }
    }, [repairTimeProviders, getRepairTimesUrl, article])

    const articleActions = useArticleActions({
        article,
        articleErpInfo: articleErpInfos.default,
        feedbackConfiguration,
        isNotesEnabled: showArticleNotes,
        listType,
        notes: notes.notes,
        repairTimesUrl,
        onClickDetails: handleClickDetails,
        toggleExpand,
        toggleNotes: notes.toggle,
        hideAlternatives: true,
    })

    const notifications = useArticleNotifications(articleErpInfos.default)
    const watchList = useWatchlist(article)
    const selection = useArticleSelection()
    const extendedDeliveryInfo = useArticleExtendedDeliveryInfo(showExtendedDelivery, articleErpInfos.default)
    const dmsArticleInfo = useDmsArticleInfo(article)

    const handleCopyArticleNumber = useCallback(
        (number: string) => {
            copyToClipboard(number)
            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1920) })
        },
        [translateText]
    )

    const showExtendedAvailabilityContainer =
        !!erpInfosData.erpConfig?.erpSystemConfigs && erpInfosData.erpConfig?.erpSystemConfigs.filter((x) => x.useForGetErpInformation).length > 1

    const articleState = useMemo<TyreArticleItemState>(
        () => ({
            article,
            articleActions,
            articleErpInfos,
            articleImages,
            displayArticle,
            dmsArticleInfo,
            expanded,
            extendedDeliveryInfo,
            isVehicleDependent: false,
            alreadyOrdered,
            notes,
            notifications,
            options,
            originalQuantity,
            quantity: articleQuantity,
            selection,
            showImageViewer,
            type: "Tyres",
            watchList,
            basketQuantity,
            showExtendedAvailabilityContainer,
            addToBasketButtonDisabled: addButtonsDisabled,
            addToCostEstimationButtonDisabled: addButtonsDisabled,
            handleAddToBasket,
            handleAddToCostEstimation,
            handleChangeQuantity,
            handleClickDetails,
            handleClickAvailability,
            handleClickImage,
            handleClickReference,
            handleCloseImage,
            handleRemoveFromBasket,
            handleCopyArticleNumber,
            toggleExpand,
            AvailabilityComponent,
        }),
        [
            article,
            articleActions,
            articleErpInfos,
            articleImages,
            displayArticle,
            dmsArticleInfo,
            expanded,
            extendedDeliveryInfo,
            alreadyOrdered,
            notes,
            notifications,
            options,
            originalQuantity,
            articleQuantity,
            selection,
            showImageViewer,
            watchList,
            basketQuantity,
            showExtendedAvailabilityContainer,
            addButtonsDisabled,
            handleAddToBasket,
            handleAddToCostEstimation,
            handleChangeQuantity,
            handleClickDetails,
            handleClickAvailability,
            handleClickImage,
            handleClickReference,
            handleCloseImage,
            handleRemoveFromBasket,
            handleCopyArticleNumber,
            toggleExpand,
        ]
    )
    return (
        <ArticleItemStateProvider value={articleState}>
            <ArticleItem variant="Tyres" />
        </ArticleItemStateProvider>
    )
}
