import { ArticleErpInfo } from "@tm/models"
import { Dispatch, PropsWithChildren, SetStateAction } from "react"
import { createContext, useContext } from "use-context-selector"
import { ensureContextAvailability } from "../../helpers"
import { ErpInfosData } from "../../models"
import { useArticlesContext } from "../Articles"
import { useListParamsContext } from "../ListParams"
import { useTradeReferenceContext } from "../TradeReferences"
import { useErpInfos } from "./useErpInfos"

export const ErpInfosContext = createContext<ErpInfosData | undefined>(undefined)

export type ErpInfosProviderProps = PropsWithChildren<{
    isEnabled: boolean
    erpInfos: ArticleErpInfo[]
    setErpInfos: Dispatch<SetStateAction<ArticleErpInfo[]>>
}>

export function ErpInfosProvider({ isEnabled, erpInfos, setErpInfos, children }: ErpInfosProviderProps) {
    const { articles } = useArticlesContext()
    const { startParams } = useListParamsContext()
    const { tradeReferences, tradeReferenceNumbersLoaded } = useTradeReferenceContext()

    const erpInfosData = useErpInfos({
        articles,
        isEnabled: isEnabled && tradeReferenceNumbersLoaded,
        startParams,
        tradeReferences,
        erpInfos,
        setErpInfos,
    })
    return <ErpInfosContext.Provider value={erpInfosData}>{children}</ErpInfosContext.Provider>
}

export function useErpInfosContext(): ErpInfosData {
    const context = useContext(ErpInfosContext)
    return ensureContextAvailability("ErpInfosContext", context)
}
