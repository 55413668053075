import { PriceType, VoucherType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Box, Divider, Typography, Loader, Theme, Stack } from "@tm/components"
import VoucherHeader from "../_shared/VoucherDetailsHeader"
import { useOrderDetails } from "../../../../business/hooks/useOrderDetails"
import OrderTotal from "./components/PartsTable/components/OrderTotal"
import OrderWorkTasks from "./components/OrderWorkTasks"

type Props = {
    workTaskId: string
    voucherId: string | undefined
    hideVehicleLogos?: boolean
    isBigScreen: boolean
    theme: Theme
    openArticleDirectSearchUrl(query: string): void
    onShowReturn(voucherId: string): void
}

export default function OrderDetails(props: Props) {
    const { workTaskId, hideVehicleLogos, voucherId, isBigScreen, theme, openArticleDirectSearchUrl, onShowReturn } = props
    const { translateText } = useLocalization()
    const { orderDetails, orderDetailsLoading, toggleSelected, selectAll, unselectAll, selectedIds } = useOrderDetails(voucherId)

    if (orderDetailsLoading) {
        return <Loader />
    }

    if (!orderDetails) {
        return <Typography>{translateText(323)}</Typography>
    }

    const { totals, voucherNumber, orderDate, wholesalerOrderNumber, customOrderNumber, orderOptions, catalogOrderNumber } = orderDetails
    const retailTotal = totals.totalPrices[PriceType.Retail]
    const purchaseTotal = totals.totalPrices[PriceType.Purchase]

    return (
        <Stack spacing={1}>
            <VoucherHeader
                type={VoucherType.Order}
                orderDate={orderDate}
                orderOptions={orderOptions}
                orderRecipient={orderDetails.orderRecipient}
                orderNumber={wholesalerOrderNumber}
                voucherNumber={voucherNumber}
                customOrderNumber={customOrderNumber}
                catalogOrderNumber={catalogOrderNumber}
                wholesalerOrderNumber={wholesalerOrderNumber}
                totalPurchasePrice={purchaseTotal?.value}
                totalRetailPrice={retailTotal?.value}
                currencyCode={retailTotal?.currencyCode || purchaseTotal?.currencyCode || "EUR"}
                currencySymbol={retailTotal?.currencySymbol || retailTotal?.currencySymbol || "€"}
            />
            {orderDetails.orderItemsByWorkTask.map((orderWorkTask) => (
                <OrderWorkTasks
                    key={orderWorkTask.workTaskInfo.workTaskId}
                    workTaskId={workTaskId}
                    orderItemsByWorkTask={orderWorkTask}
                    isBigScreen={isBigScreen}
                    selectedIds={selectedIds}
                    theme={theme}
                    totals={totals}
                    hideVehicleLogos={hideVehicleLogos}
                    voucherId={voucherId}
                    onShowReturn={onShowReturn}
                    onSelectPart={toggleSelected}
                    onSelectAll={selectAll}
                    onUnselectAll={unselectAll}
                    onOpenArticleSearch={openArticleDirectSearchUrl}
                />
            ))}
            <Divider />
            <Box display="flex" alignSelf="flex-end" justifyContent="flex-end">
                <OrderTotal retailTotal={retailTotal} />
            </Box>
        </Stack>
    )
}
