import { useEffect, useState } from "react"
import { styled } from "@tm/components"
import { ArticleSelectionComponent } from "../ListV2/components/ArticleSelection"
import { useArticleSelection } from "../ListV2/hooks/useArticleSelection"
import { useErpInfosContext } from "../ListV2/ContextProvider"

export const PureArticleSelection = styled(PureArticleSelectionComponent)({
    position: "absolute",
    transform: "transLateY(-50%)",
    margin: 0,
})

function PureArticleSelectionComponent(props: { showArticleComparision?: boolean }) {
    const erpInfos = useErpInfosContext()
    const { showArticleComparision } = props
    const { selectedArticles } = useArticleSelection()
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(!!selectedArticles.length)
    }, [selectedArticles])

    if (!show) {
        return null
    }

    return <ArticleSelectionComponent showArticleComparision={showArticleComparision} erpInfosData={erpInfos} />
}
