import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { Article, ArticleQuantities, SupplierArticleDto } from "@tm/models"
import { useArticlesContext } from "../Articles/ArticlesContext"
import { ensureContextAvailability } from "../../helpers"
import { useBasketQuantities } from "./useBasketQuantities"

export const BasketQuantitiesContext = createContext<ArticleBasketQuantities | undefined>(undefined)

type ArticleBasketQuantities = {
    basketQuantities: ArticleQuantities<SupplierArticleDto>[]
    updateBasketQuantities: (articles: Article[]) => Promise<void>
}

export function BasketQuantitiesProvider(props: PropsWithChildren<{ isEnabled: boolean }>) {
    const { articles } = useArticlesContext()
    const articleBasketQuantities = useBasketQuantities(articles, props.isEnabled)

    return <BasketQuantitiesContext.Provider value={articleBasketQuantities}>{props.children}</BasketQuantitiesContext.Provider>
}

export function useBasketQuantitiesContext(): ArticleBasketQuantities {
    const context = useContext(BasketQuantitiesContext)
    return ensureContextAvailability("BasketQuantitiesContext", context)
}
