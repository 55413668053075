import { CalculationHistoryStatus } from "."

export enum StepNavigationCategory {
    Inspection = "Inspection",
    Test = "Test",
}

export type StepsNavigation = {
    indexNumber: number
    stepName: string
    available: boolean
    badge: string | undefined
    completed: boolean | undefined
    category?: StepNavigationCategory
    specialStep?: boolean
    icon: string
    calculationStatus?: CalculationHistoryStatus
}
