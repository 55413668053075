import { ArticleItem, Box, Button, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleListSortingMode } from "@tm/models"
import { Fragment, useCallback } from "react"
import { ArticleGroup } from "../components/ArticleGroup"
import { NextPageLoader } from "../components/NextPageLoader"
import { NoSearchResult } from "../components/NoSearchResult"
import { ArticleSelection } from "../components/ArticleSelection"
import { useArticleSelection } from "../hooks/useArticleSelection"
import { OeInformation } from "../components/OeInformation"
import { OeArticle } from "../components/OeArticle"
import {
    useDirectSearchArticlesContext,
    useDirectSearchListParamsContext,
    useOeInformationContext,
    useOensArticlesContext,
    useOeReplacementContext,
} from "./ContextProvider"
import { useListOptionsContext } from "../ContextProvider"
import { OePositions } from "../components/OePositions"
import { ArticleGroupHeader } from "../../list/components/ArticleGroupHeader/ArticleGroupHeader"
import { OeArticleItemStateProvider } from "../components/OeArticle/OeArticleItemStateProvider"

export function DirectSearchList() {
    const {
        articleGroups,
        exactMatchArticleGroups,
        isLoading,
        isLoaded,
        isFailed,
        isFetchingNextPage,
        hasNextPage,
        loadNextPage,
        isStalling,
        isFrontendFiltered,
    } = useDirectSearchArticlesContext()

    const { translateText } = useLocalization()
    const params = useDirectSearchListParamsContext()
    const options = useListOptionsContext()
    const oeInformation = useOeInformationContext()
    const oensArticles = useOensArticlesContext()
    const oeReplacement = useOeReplacementContext()

    const {
        startParams: { query, oeManufacturerIds },
        sorting: { productGroups, setForProductGroups },
        oePositions,
        selectedOePart,
        changeOePosition,
    } = params

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            setForProductGroups([productGroupId], value)
        },
        [setForProductGroups]
    )

    const { selectedArticles } = useArticleSelection()
    const distance = selectedArticles.length > 0 ? "-50px" : ""

    const showPageLoader = ((hasNextPage || isLoading) && !isFrontendFiltered) || isStalling

    // needs to be refactord - always display OE Articles if exist!
    if (
        (!isFetchingNextPage &&
            isLoaded &&
            !isLoading &&
            !exactMatchArticleGroups.length &&
            !articleGroups.length &&
            !oensArticles.oeParts?.length) ||
        (isFailed && !showPageLoader && !oensArticles.oeParts?.length)
    ) {
        return (
            <>
                <OeInformation
                    oeInformation={oeInformation.oeInformation}
                    isLoading={oeInformation.isLoading}
                    query={query}
                    oeManufacturerIds={oeManufacturerIds}
                />
                <NoSearchResult searchType="direct" />
            </>
        )
    }

    return (
        <Box position="relative" top={distance}>
            <ArticleSelection showArticleComparision hideBasketButton={options.hideAddToBasketButton} />

            {params.startParams.forcePartsAlternatives && oePositions ? (
                <Box p={1} className="oe-article-section">
                    <Box marginBottom={1}>
                        <OePositions onPositionSelect={changeOePosition} positions={oePositions} />
                    </Box>
                    {selectedOePart && (
                        <>
                            <ArticleGroupHeader title={translateText(803)} key="oeSelectedPartHeader" />
                            <OeArticleItemStateProvider article={selectedOePart} articleTypeKey="OePositionsSelectedOePart">
                                <ArticleItem variant="OE" />
                            </OeArticleItemStateProvider>
                        </>
                    )}
                </Box>
            ) : (
                <OeInformation
                    oeInformation={oeInformation.oeInformation}
                    isLoading={oeInformation.isLoading}
                    query={query}
                    oeManufacturerIds={oeManufacturerIds}
                />
            )}

            <OeArticle oensArticlesData={oensArticles} oeReplacement={oeReplacement} />

            {isLoaded &&
                !isStalling &&
                exactMatchArticleGroups.map((group, index) => (
                    // Disable ESLint rule here, because there can be multiple groups with the same product group id
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={`${group.productGroup.id}-${index}`}>
                        <Typography variant="body1">{translateText(806).replace("%0", `${exactMatchArticleGroups.length}`)} </Typography>
                        <ArticleGroup group={group} currentSorting={productGroups[group.productGroup.id]} onChangeSorting={handleChangeSorting} />
                    </Fragment>
                ))}

            {isLoaded && !isStalling && articleGroups.length > 0 && (
                <>
                    <Typography variant="body1">{exactMatchArticleGroups.length > 0 ? translateText(808) : translateText(13188)}</Typography>
                    {articleGroups.map((group, index) => (
                        <ArticleGroup
                            // Disable ESLint rule here, because there can be multiple groups with the same product group id
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${group.productGroup.id}-${index}`}
                            group={group}
                            currentSorting={productGroups[group.productGroup.id]}
                            onChangeSorting={handleChangeSorting}
                        />
                    ))}
                </>
            )}

            {showPageLoader && (
                <NextPageLoader
                    loadNextPage={loadNextPage}
                    canLoadNextArticles={hasNextPage}
                    skeletonArticleCount={articleGroups.length ? 1 : 5}
                    skeletonCategory
                />
            )}

            {hasNextPage && isFrontendFiltered && (
                <Box display="flex" justifyContent="center">
                    <Button color="highlight" onClick={loadNextPage}>
                        {translateText(12430)}
                    </Button>
                </Box>
            )}
        </Box>
    )
}
