import { getInitialState } from "."
import { fastServiceStore } from "../.."

export function resetTechnician() {
    fastServiceStore.setState(getInitialState(), false, "Reset Technician Slice")
}

export function setSignature(signature: string) {
    fastServiceStore.setState(
        (state) => ({
            technician: {
                ...state.technician,
                signature,
            },
        }),
        false,
        "Technician set signature"
    )
}

export function setFirstName(firstName: string) {
    fastServiceStore.setState(
        (state) => ({
            technician: { ...state.technician, firstName },
        }),
        false,
        "Technician set first name"
    )
}

export function setLastName(lastName: string) {
    fastServiceStore.setState((state) => ({ technician: { ...state.technician, lastName } }), false, "Technician set last name")
}

export function setComment(comment: string) {
    fastServiceStore.setState((state) => ({ technician: { ...state.technician, comment } }), false, "Technician set comment")
}
