import { Article, ArticleSelectionParams } from "@tm/models"
import { useCallback, useMemo } from "react"

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFunc = () => {}

export function useTyreArticleSelection(selectedArticles: Article[], toggleSelectedArticle: (article: Article) => void): ArticleSelectionParams {
    const isArticleSelected = useCallback(
        (article: Article) => selectedArticles.findIndex((x) => x.internalId === article.internalId) > -1,
        [selectedArticles]
    )

    return useMemo(
        () => ({
            selectedArticles,
            toggleSelectedArticle,
            deselectArticle: emptyFunc,
            deselectAll: emptyFunc,
            isArticleSelected,
        }),
        [isArticleSelected, selectedArticles, toggleSelectedArticle]
    )
}
