import { Dispatch, PropsWithChildren, SetStateAction, useState } from "react"
import { createContext, useContext } from "use-context-selector"
import { ensureContextAvailability, useAfterPaintEffect } from "../../../helpers"
import { useVehicle } from "../../../hooks"
import { useWholeSalerArticleNumberListParamsContext } from "../ListParams"
import { FiltersAndResultsT, useFiltersAndResults } from "./useFiltersAndResults"

export const FiltersAndResultsContext = createContext<FiltersAndResultsT | undefined>(undefined)

export function FiltersAndResultsProvider(props: PropsWithChildren<{ setHasRendered: Dispatch<SetStateAction<boolean>> }>) {
    const vehicle = useVehicle()
    const params = useWholeSalerArticleNumberListParamsContext()
    const [startRender, setStartRender] = useState(false)
    const filtersAndResults = useFiltersAndResults(vehicle, params, startRender, true)

    useAfterPaintEffect(() => {
        setStartRender(true)
    }, [])

    useAfterPaintEffect(() => {
        if (filtersAndResults.results.requestStatus === "success") {
            props.setHasRendered(true)
        }
    }, [filtersAndResults.results.requestStatus])

    return <FiltersAndResultsContext.Provider value={filtersAndResults}>{props.children}</FiltersAndResultsContext.Provider>
}

export function useFiltersAndResultsContext(): FiltersAndResultsT {
    const context = useContext(FiltersAndResultsContext)
    return ensureContextAvailability("FiltersAndResultsContext", context)
}
