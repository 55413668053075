import { getInitialState } from "."
import { fastServiceStore } from "../.."

export function resetTestDrive() {
    fastServiceStore.setState(getInitialState(), false, "Reset Test Drive Slice")
}

export function setDate(date: Date) {
    fastServiceStore.setState(
        (state) => ({
            testDriveState: { ...state.testDriveState, date },
        }),
        false,
        "Test drive set date"
    )
}

export function setMileageStart(mileage: string) {
    fastServiceStore.setState(
        (state) => ({
            testDriveState: { ...state.testDriveState, mileageStart: mileage },
        }),
        false,
        "Test drive set mileage start"
    )
}

export function setMileageEnd(mileage: string) {
    fastServiceStore.setState(
        (state) => ({
            testDriveState: { ...state.testDriveState, mileageEnd: mileage },
        }),
        false,
        "Test drive set mileage end"
    )
}

export function setTimeStart(time: string) {
    fastServiceStore.setState(
        (state) => ({
            testDriveState: { ...state.testDriveState, timeStart: time },
        }),
        false,
        "Test drive set time start"
    )
}

export function setTimeEnd(time: string) {
    fastServiceStore.setState(
        (state) => ({
            testDriveState: { ...state.testDriveState, timeEnd: time },
        }),
        false,
        "Test drive set time end"
    )
}

export function setTestDriveStatus(status: boolean) {
    fastServiceStore.setState(
        (state) => ({
            testDriveState: { ...state.testDriveState, testDriveStatus: status },
        }),
        false,
        "Test drive set status"
    )
}
