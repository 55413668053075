import { Box, Button, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleListSortingMode } from "@tm/models"
import { useCallback, useMemo } from "react"
import { ArticleGroup } from "../components/ArticleGroup"
import { ArticleListWrapper } from "../components/ArticleListWrapper"
import { ArticleSelection } from "../components/ArticleSelection"
import { NextPageLoader } from "../components/NextPageLoader"
import { NoSearchResult } from "../components/NoSearchResult"
import { useListOptionsContext } from "../ContextProvider"
import { useProductGroupTopicIdsDataContext } from "../ContextProvider/ProductGroupTopicIds"
import { usePartsViewOptions } from "../hooks/usePartsViewOptions"
import { useProductGroupsArticlesContext } from "./ContextProvider/Articles"
import { useProductGroupsListParamsContext } from "./ContextProvider/ListParams"

const ModalArticleListWrapper = styled(ArticleListWrapper)({ height: "100%", paddingLeft: 0, paddingRight: 1, margin: 1, overflow: "hidden" })

export default function ProductGroupsList() {
    const { translateText } = useLocalization()

    const {
        sorting: { productGroups: sortingProductGroups, setForProductGroups: activateSortingForProductGroups },
        noResult,
        attributes,
        toggleAttribute,
        setAttributes,
    } = useProductGroupsListParamsContext()

    const { articleGroups, isLoading, isLoaded, isFailed, hasNextPage, loadNextPage, isStalling, isFrontendFiltered } =
        useProductGroupsArticlesContext()

    const options = useListOptionsContext()
    const productGroupTopicIds = useProductGroupTopicIdsDataContext()

    const {
        partsViewSettings: { showVehicleRecordsFilters },
    } = usePartsViewOptions()

    const usedAttributeFilters = useMemo(() => attributes.map((x) => x.query ?? `${x.id}|${x.key ?? ""}`), [attributes])

    const handleResetAttributeFilters = useCallback(() => {
        if (usedAttributeFilters.length) {
            setAttributes([])
        }
    }, [usedAttributeFilters, setAttributes])

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            activateSortingForProductGroups([productGroupId], value)
        },
        [activateSortingForProductGroups]
    )

    const showPageLoader = ((hasNextPage || isLoading) && !isFrontendFiltered) || isStalling

    return (
        <ModalArticleListWrapper>
            <ArticleSelection showArticleComparision hideBasketButton={options.hideAddToBasketButton} />

            {(isLoaded || !isLoading) && (noResult || (isFailed && !showPageLoader) || !articleGroups.length) && (
                <NoSearchResult searchType="direct" />
            )}

            {isLoaded &&
                !isStalling &&
                articleGroups.map((group) => (
                    <ArticleGroup
                        key={group.productGroup.id}
                        group={group}
                        currentSorting={sortingProductGroups[group.productGroup.id]}
                        onChangeSorting={handleChangeSorting}
                        showFilterOptions={showVehicleRecordsFilters}
                        usedAttributeFilters={usedAttributeFilters}
                        onToggleAttributeFilter={toggleAttribute}
                        onResetAttributeFilters={handleResetAttributeFilters}
                        productGroupTopicIds={productGroupTopicIds}
                    />
                ))}

            {showPageLoader && (
                <NextPageLoader
                    loadNextPage={loadNextPage}
                    canLoadNextArticles={hasNextPage}
                    skeletonArticleCount={articleGroups.length ? 1 : 5}
                    skeletonCategory
                />
            )}

            {hasNextPage && isFrontendFiltered && (
                <Box display="flex" justifyContent="center">
                    <Button color="highlight" onClick={loadNextPage}>
                        {translateText(12430)}
                    </Button>
                </Box>
            )}
        </ModalArticleListWrapper>
    )
}
