import { getInitialState } from "."
import { fastServiceStore } from "../.."
import { PrintOptions } from "../../models"

export function resetPrintOptions() {
    fastServiceStore.setState(getInitialState(), false, "Reset Print Options Slice")
}

export const replacePrintOptions = (printOptions: PrintOptions) => {
    fastServiceStore.setState(
        {
            printOptions,
        },
        false,
        "Replace print options"
    )
}
