import { Box, Loader, Stack } from "@tm/components"
import { ArticleErpInfo } from "@tm/models"
import { Suspense, useMemo, useState } from "react"
import { ArticleListConfigurationProvider } from "../ArticleListConfiguration"
import { ModalModuleHeader } from "../components/ModuleHeader/ModalModuleHeader"
import { ComposerComponent, ContextComposer } from "../ContextProvider"
import { ActiveVehicleDataProvidersProvider } from "../ContextProvider/ActiveVehicleDataProviders"
import { ArticleListActionsProvider } from "../ContextProvider/ArticleListActions"
import { BasketQuantitiesProvider } from "../ContextProvider/BasketQuantities"
import { ErpInfosProvider } from "../ContextProvider/ErpInfos"
import { ListOptionsProvider } from "../ContextProvider/ListOptions"
import { NotesProvider } from "../ContextProvider/Notes"
import { ProductGroupRepairTimesProvider } from "../ContextProvider/ProductGroupRepairTimes"
import { ProductGroupTopicIdsProvider } from "../ContextProvider/ProductGroupTopicIds"
import { SupplierLogosProvider } from "../ContextProvider/SupplierLogos"
import { TradeReferenceProvider } from "../ContextProvider/TradeReferences"
import { WatchListProvider } from "../ContextProvider/WatchList"
import { OePartsArticlesProvider } from "./ContextProvider/Articles"
import { OePartsFiltersProvider } from "./ContextProvider/Filters"
import { OePartsListParamsProvider, useOePartsListParamsContext } from "./ContextProvider/ListParams"
import { OePartsList } from "./OePartsList"

function OePartsComponent() {
    const { startParams, oePositions } = useOePartsListParamsContext()

    const hasMissingParams = useMemo(() => {
        if (startParams.replaceButtonBundle) {
            return false
        }

        switch (startParams.type) {
            case "oe":
                return !oePositions.length
            default:
                return true
        }
    }, [startParams, oePositions])

    return (
        <Box flex={1} display="grid" overflow="hidden" margin={1}>
            {!hasMissingParams && <OePartsList />}
        </Box>
    )
}

export function OeParts() {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [OePartsListParamsProvider],
        [OePartsFiltersProvider],
        [OePartsArticlesProvider, { setHasRendered: setHasListRendered, erpInfos }],
        [ProductGroupTopicIdsProvider, { isEnabled: hasListRendered }],
        [SupplierLogosProvider, { isEnabled: hasListRendered }],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [ErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: true }],
        [WatchListProvider, { isEnabled: hasListRendered }],
        [ListOptionsProvider],
        [ArticleListActionsProvider],
    ]
    return (
        <Suspense
            fallback={
                <Box alignContent="center">
                    <Loader />
                </Box>
            }
        >
            <ArticleListConfigurationProvider>
                <ContextComposer components={components}>
                    <Stack overflow="hidden">
                        <ModalModuleHeader />
                        <OePartsComponent />
                    </Stack>
                </ContextComposer>
            </ArticleListConfigurationProvider>
        </Suspense>
    )
}
