import { GetArticleListByMatchCodeRequest, VehicleType } from "@tm/models"
import { getSelectedCriteria } from "."
import { getBundleParams } from "../../../../utils"
import { TyresListState } from "../model"
import { mapVehicleTypeToTyresCarType } from "../../../../data/helpers"

export function createNextArticlesListRequest(state: TyresListState, vehicleType: VehicleType | undefined): GetArticleListByMatchCodeRequest | undefined {
    const { selectedFilters, articles: { nextArticlesError, pageIndex, count } } = state
    const { tyresPageSize, traderReferenceId } = getBundleParams()
    if (pageIndex >= count / tyresPageSize || nextArticlesError || !selectedFilters.tyreSize) {
        return
    }

    const selectedCriteria = getSelectedCriteria(state)

    const carType = mapVehicleTypeToTyresCarType(vehicleType)

    return {
        pageIndex: pageIndex + 1,
        pageSize: tyresPageSize,
        query: selectedFilters.tyreSize.value.replace(/ /g, ""),
        searchLevel: 0,
        selectedCriteria,
        traderReferenceId,
        carTypes: [carType]
    }
}