import { Stack, styled } from "@tm/components"
import { Route } from "react-router"
import { useUser } from "@tm/context-distribution"
import IFrameUniversalTagBased from "../../../../../misc/src/components/iframe-universal-tagbased/component"
import { ExternalListModuleHeader } from "../components/ModuleHeader/ExternalListModuleHeader"
import { ModalExternalListModuleHeader } from "../components/ModuleHeader/ModalExternalListModuleHeader"

const StyledIFrameUniversal = styled(IFrameUniversalTagBased)({
    height: "100%",
})

export function ExternalUniversalParts() {
    const { userContext } = useUser()

    const externalUniPartsModule = userContext.externalModules?.find(
        (extModule) =>
            extModule.parameter &&
            !!extModule.url &&
            extModule.parameter.some((param) => param.key === "DocumentTypeID" && param.value === "UniversalParts")
    )

    return (
        <Stack overflow="hidden" flex={1}>
            <Route path="/:workTaskId/parts/:section/:view/:searchType?" component={ExternalListModuleHeader} />
            {/* ModalModuleHeader is only shown for this specific path */}
            <Route path="/parts/alternatives" component={ModalExternalListModuleHeader} />
            {externalUniPartsModule?.id && <StyledIFrameUniversal externalSystemId={externalUniPartsModule.id} shouldUseHtmlIFrame />}
        </Stack>
    )
}
