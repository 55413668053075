import { Typography, ModuleHeaderSlot } from "@tm/components"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { PartsBundleParams } from "@tm/models"
import { Navigation } from "./Navigation"
import { Options } from "./Options"
import { Search } from "./Search"
import { StyledModuleHeader } from "../StyledComponents"

export function ModuleHeader() {
    const { translateText } = useLocalization()
    const { listV2 } = Morpheus.getParams<PartsBundleParams>("parts")

    return (
        <StyledModuleHeader>
            <Typography variant="h1" pr={2} pb={listV2?.areasAsTabs ? 2 : 1}>
                {translateText(993)}
            </Typography>
            <ModuleHeaderSlot title={listV2?.areasAsTabs ? "" : translateText(176)}>
                <Navigation areasAsTabs={listV2?.areasAsTabs} />
            </ModuleHeaderSlot>
            <ModuleHeaderSlot title={translateText(135)} isTabsView={listV2?.areasAsTabs}>
                <Search />
            </ModuleHeaderSlot>
            <ModuleHeaderSlot isTabsView={listV2?.areasAsTabs}>
                <Options />
            </ModuleHeaderSlot>
        </StyledModuleHeader>
    )
}
