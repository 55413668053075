import { Grid, Stack } from "@tm/components"
import { ArticleErpInfo } from "@tm/models"
import { useEffect, useMemo, useRef, useState } from "react"
import { Route } from "react-router"
import { ArticleListWrapper, ArticleListWrapperRefType } from "../components/ArticleListWrapper"
import { ModuleHeader } from "../components/ModuleHeader"
import { ModalModuleHeader } from "../components/ModuleHeader/ModalModuleHeader"
import { ComposerComponent, ContextComposer } from "../ContextProvider"
import { ActiveVehicleDataProvidersProvider } from "../ContextProvider/ActiveVehicleDataProviders"
import { ArticleListActionsProvider } from "../ContextProvider/ArticleListActions"
import { BasketQuantitiesProvider } from "../ContextProvider/BasketQuantities"
import { ErpInfosProvider } from "../ContextProvider/ErpInfos"
import { ListOptionsProvider } from "../ContextProvider/ListOptions"
import { NotesProvider } from "../ContextProvider/Notes"
import { PreviouslyOrderedArticlesProvider } from "../ContextProvider/PreviouslyOrderedArticles"
import { ProductGroupRepairTimesProvider } from "../ContextProvider/ProductGroupRepairTimes"
import { SupplierLogosProvider } from "../ContextProvider/SupplierLogos"
import { TradeReferenceProvider } from "../ContextProvider/TradeReferences"
import { WatchListProvider } from "../ContextProvider/WatchList"
import { DirectSearchArticlesProvider } from "./ContextProvider/Articles/ArticlesContext"

import { DirectSearchListParamsProvider, useDirectSearchListParamsContext } from "./ContextProvider/ListParams/ListParamsContext"
import { OeInformationProvider } from "./ContextProvider/OeInformation"
import { OensArticlesProvider } from "./ContextProvider/OensArticles"
import { OeReplacementProvider } from "./ContextProvider/OeReplacement"
import { DirectSearchList } from "./DirectSearchList"
import { DirectSearchFiltersProvider } from "./ContextProvider/Filters"

function DirectSearchComponent() {
    const { startParams, showAvailable, productGroups, suppliers } = useDirectSearchListParamsContext()

    const articleListWrapperRef = useRef<ArticleListWrapperRefType>(null)

    const hasMissingParams = useMemo(() => {
        switch (startParams.type) {
            case "direct":
                return !startParams.query
            default:
                return true
        }
    }, [startParams])

    useEffect(
        function scrollToTopOnListChanged() {
            articleListWrapperRef.current?.scrollToTop()
        },
        [startParams, showAvailable, productGroups, suppliers]
    )

    return (
        <Stack overflow="hidden" flex={1}>
            <Route path="/:workTaskId/parts/:section/:view/:searchType?" component={ModuleHeader} />
            {/* ModalModuleHeader is only shown for this specific path */}
            <Route path="/parts/alternatives" component={ModalModuleHeader} />
            <Grid flex={1} display="grid" gridTemplateRows="1fr" overflow="hidden">
                {!hasMissingParams && (
                    <ArticleListWrapper ref={articleListWrapperRef}>
                        <DirectSearchList />
                    </ArticleListWrapper>
                )}
            </Grid>
        </Stack>
    )
}

export function DirectSearch() {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [DirectSearchListParamsProvider],
        [DirectSearchFiltersProvider],
        [DirectSearchArticlesProvider, { setHasRendered: setHasListRendered, erpInfos }],
        [OeInformationProvider, { isEnabled: hasListRendered }],
        [OensArticlesProvider, { isEnabled: hasListRendered }],
        [OeReplacementProvider, { isEnabled: hasListRendered }],
        [SupplierLogosProvider, { isEnabled: hasListRendered }],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [ErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: false }],
        [WatchListProvider, { isEnabled: hasListRendered }],
        [PreviouslyOrderedArticlesProvider, { isEnabled: hasListRendered }],
        [ListOptionsProvider],
        [ArticleListActionsProvider],
    ]
    return (
        <ContextComposer components={components}>
            <DirectSearchComponent />
        </ContextComposer>
    )
}
