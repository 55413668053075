import { PropsWithChildren, useMemo } from "react"
import { FiltersContext, FiltersContextData } from "."

export function EmptyFiltersProvider(props: PropsWithChildren<unknown>) {
    const filters = useMemo<FiltersContextData>(
        () => ({
            attributeFilters: [],
            dataSupplierFilters: [],
            extendedAssortment: { enabled: false, forced: false },
            groupedAttributeFilters: [],
            isLoading: false,
            isRefetching: false,
            productGroupFilters: [],
            resetAll: () => {},
            resetProductGroups: () => {},
            resetSuppliers: () => {},
            showExtendedAssortmentFilter: false,
            showOnlyAvailable: false,
            showOnlyAvailableSecondary: false,
            toggleAvailability: () => {},
            toggleAvailabilitySecondary: () => {},
            toggleExtendedAssortment: () => {},
            toggleProductGroup: () => {},
            toggleSupplier: () => {},
        }),
        []
    )
    return <FiltersContext.Provider value={filters}>{props.children}</FiltersContext.Provider>
}
