import { PropsWithChildren } from "react"
import { DirectSearchListParams } from "../../../models"
import { useListParams } from "./useListParams"
import { useListParamsContext, ListParamsContext } from "../../../ContextProvider/ListParams"

export function DirectSearchListParamsProvider(props: PropsWithChildren<unknown>) {
    const params = useListParams()
    return <ListParamsContext.Provider value={params}>{props.children}</ListParamsContext.Provider>
}

export function useDirectSearchListParamsContext<TContext extends DirectSearchListParams>(): TContext
export function useDirectSearchListParamsContext<TContext extends DirectSearchListParams, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function useDirectSearchListParamsContext<TContext extends DirectSearchListParams, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useListParamsContext(selector as never)
}
