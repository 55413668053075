import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { SuggestionFieldButtonGroup } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VehicleSearchType, VehicleShortInfo } from "@tm/models"
import { concat, plateCountryCodeMapper } from "@tm/utils"
import { useEffect, useCallback, useRef, useState } from "react"

import { Alert, Stack, styled } from "@tm/components"
import { findGsiVehicles } from "../../data/repositories/gsi"
import { renderVehicleShortInfoAutosuggestColumns } from "./VehicleShortInfoAutosuggest"

const SearchWrapper = styled("div")<{ hasError?: boolean }>(({ hasError, theme }) => {
    return {
        border: hasError ? `solid ${theme.colors?.warning ?? "yellow"} 1px` : "",
    }
})

type Props = {
    initialValue?: string
    onSearch(query: string): Promise<void>
    onAttachVehicleId?(vehicleId: string, query: string): void

    size: "xs" | "s" | "m" | "l" | "xl"
    className?: string
    shortCountryCode: string
    showCountryCode?: boolean
    placeholder?: string
    searchType?: VehicleSearchType
    autoFocus?: boolean
    onlyNumbersAndDigits?: boolean
    hidePlateFx?: boolean
}

export default function VehicleSearchField(props: Props) {
    const localization = useLocalization()
    const { translateText } = localization

    const [query, setQuery] = useState(props.initialValue || "")
    const [suggestions, setSuggestions] = useState<{ loading: boolean; vehicles: Array<VehicleShortInfo> }>({ loading: false, vehicles: [] })
    const searchFieldRef = useRef<SuggestionFieldButtonGroup<any>>(null)
    const { plateCode } = useCountryCodeToLicensePlate()
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    useEffect(() => {
        if (props.autoFocus) {
            setTimeout(() => {
                searchFieldRef.current?.focus(false)
            }, 25)
        }
    }, [props.autoFocus])

    const handleRequestSuggestions = useCallback(
        (queryString: string) => {
            if (!queryString) {
                setSuggestions({ loading: false, vehicles: [] })
                return
            }

            setSuggestions((state) => ({
                loading: true,
                vehicles: state.vehicles,
            }))

            findGsiVehicles({
                query: queryString,
                searchType: props.searchType ?? VehicleSearchType.PlateIdOrVin,
                pageSize: 10,
            })
                .then((response) =>
                    setSuggestions({
                        loading: false,
                        vehicles: response?.vehicles ?? [],
                    })
                )
                .catch(() => {
                    setSuggestions({
                        loading: false,
                        vehicles: [],
                    })
                })
        },
        [props.searchType]
    )

    const changeQuery = (queryString: string) => {
        if (queryString && props.onlyNumbersAndDigits) {
            queryString = queryString.replace(/[^A-Za-z0-9 -äöüÄÖÜ]/g, "")
        }

        setErrorMessage("")
        setQuery(queryString)
    }

    const resetQuery = () => {
        setQuery("")
        setErrorMessage("")
        searchFieldRef.current?.focus(false)
    }

    const handleVehicleSuggestionSelect = (vehicleSuggestion: VehicleShortInfo) => {
        if (props.onAttachVehicleId && vehicleSuggestion.id) {
            setLoading(true)
            setSuggestions({
                loading: false,
                vehicles: [],
            })
            props.onAttachVehicleId(vehicleSuggestion.id, query)
        }
    }

    const handleSearch = () => {
        if (!query) {
            searchFieldRef.current?.focus(false)
        } else {
            setErrorMessage("")
            setLoading(true)
            props.onSearch(query).catch(() => {
                setErrorMessage(translateText(1266))
                setLoading(false)
                searchFieldRef.current?.focus(false)
            })
        }
    }

    const { size } = props
    const mappedCountryCode = plateCountryCodeMapper(props.shortCountryCode)

    return (
        <Stack direction="column">
            <SearchWrapper
                hasError={!!errorMessage}
                className={concat(" ", "plate-id", props.className, `plate-id--${mappedCountryCode.toLocaleLowerCase()}`, `plate-id--${size}`)}
            >
                {!props.hidePlateFx && <div className="plate-id__fx">{props.showCountryCode && mappedCountryCode?.toUpperCase()}</div>}
                <SuggestionFieldButtonGroup
                    value={query}
                    onChange={changeQuery}
                    onChangeConfirm={handleSearch}
                    onSuggestionSelect={suggestions.vehicles.length > 0 ? handleVehicleSuggestionSelect : undefined}
                    onChangeReset={resetQuery}
                    suggestions={suggestions.vehicles}
                    requestSuggestions={handleRequestSuggestions}
                    renderTableColumns={
                        suggestions.vehicles.length > 0
                            ? () => renderVehicleShortInfoAutosuggestColumns(suggestions.vehicles, localization, plateCode)
                            : undefined
                    }
                    showClearTooltip={translateText(48)}
                    maxHeight={500}
                    maxLength={17}
                    size={size}
                    placeholder={props.placeholder}
                    ref={searchFieldRef}
                    handleSearchButtonClick={handleSearch}
                    suggestDelay={500}
                    loading={loading || suggestions.loading}
                    minCharactersToSuggest={3}
                />
            </SearchWrapper>
            {!!errorMessage && <Alert size="extraSmall" severity="warning" title={errorMessage} variant="filled" />}
        </Stack>
    )
}
