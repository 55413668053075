import { ArticleIdentifierExt } from "../data/models"
import { FSArticle } from "../data/repositories/fastcalculator-calculation/mapper"

export function mapCalcArticleToOEArticleIdentifier(part: FSArticle, productGroupId: number, tecdocTypeNo?: number): ArticleIdentifierExt {
    return {
        id: part.oeId,
        internalId: part.iArtNr,
        supplierId: part.supplierId,
        supplierArticleNo: part.supplierArtNr,
        productGroupId,
        tecdocTypeNo,
        fittingPosition: part.binKrit100,
    }
}
