import { getModuleFromUserContext, useUser } from "@tm/context-distribution"
import { UserModuleType } from "@tm/models"
import { PropsWithChildren, useMemo } from "react"
import { ListOptionsContext } from "../../../ListV2/ContextProvider/ListOptions"
import { useListOptions } from "../../../ListV2/hooks"
import { CompilationListOptions } from "../../models"

export function ListOptionsProvider(props: PropsWithChildren<unknown>) {
    const { userContext } = useUser()

    const options = useListOptions()
    const showNotes = useMemo<boolean>(() => !!getModuleFromUserContext(userContext, UserModuleType.ArticleNotes), [userContext.modules])

    const listOptions: CompilationListOptions = useMemo(
        () => ({
            ...options,
            hasWatchList: false,
            showNotes,
        }),
        [options, showNotes]
    )

    return <ListOptionsContext.Provider value={listOptions}>{props.children}</ListOptionsContext.Provider>
}
