import { useMemo } from "react"
import { generateHash } from "@tm/utils"
import { Box, styled, Button } from "@tm/components"
import { Loader, IFrame, Icon } from "@tm/controls"
import { useHistory, useParams } from "react-router"
import { channel } from "@tm/models"
import { useIFrameUniversalTagbasedUrl } from "./useIFrameUniversalTagbasedUrl"

type RouteParams = {
    workTaskId?: string
    externalSystemId?: string
}

type Props = {
    className?: string
    externalSystemId: number
    showCloseButton?: boolean
    shouldUseHtmlIFrame?: boolean
}

const Wrapper = styled(Box)<{ showCloseButton: boolean }>((showCloseButton) => ({
    width: "100%",
    marginTop: showCloseButton ? "0.5em" : undefined,
    position: showCloseButton ? "relative" : undefined,
}))

const StyledIFrame = styled(IFrame)({
    height: "100%",
})

const StyledHtmlIFrame = styled("iframe")({
    height: "100%",
    width: "100%",
})

const CloseButton = styled(Button)(({ theme }) => ({
    position: "absolute",
    right: "0.5em",
    zIndex: "1",
    width: 24,
    height: 24,
    "&.MuiButton-contained": { border: "1px solid white", borderRadius: theme.radius?.default ?? "3px" },
}))

export default function IFrameUniversalTagBased(props: Props) {
    const { className, showCloseButton, shouldUseHtmlIFrame } = props

    const matchParams = useParams<RouteParams>()
    const history = useHistory()
    const { workTaskId } = matchParams

    const externalSystemId = useMemo(() => {
        if (props.externalSystemId) {
            return props.externalSystemId
        }

        const id = matchParams.externalSystemId

        if (id) {
            return parseInt(id)
        }
    }, [props.externalSystemId, matchParams.externalSystemId])

    const { url, isLoading } = useIFrameUniversalTagbasedUrl(externalSystemId)

    function handleClose() {
        const fullUrl = `${url}${location.search}`
        workTaskId && channel("WORKTASK", workTaskId).publish("MODULE/CLOSED", fullUrl)
        if (history.length) {
            history.goBack()
        } else {
            history.push("/")
        }
    }

    if (isLoading) {
        return <Loader />
    }

    if (!url) {
        return null
    }

    return (
        <Wrapper showCloseButton={showCloseButton ?? false} className={`bundle-misc iframe-universal-tagbased ${className || ""}`}>
            {showCloseButton && <CloseButton color="primary" startIcon={<Icon name="close" />} onClick={handleClose} />}
            {shouldUseHtmlIFrame ? (
                <StyledHtmlIFrame src={url} />
            ) : (
                <StyledIFrame
                    id={`iframe-${generateHash(`${workTaskId}#${externalSystemId || url}`)}`}
                    url={url}
                    allowFullscreen
                    refreshOnUrlChanged
                />
            )}
        </Wrapper>
    )
}
