import { useLocalization } from "@tm/localization"
import { MoreMenu, MoreMenuItem } from "@tm/controls"
import { ArticleInfoType, ConfirmationDialogKeys } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"
import { Stack, Button, Checkbox, Icon, Switch, useTheme, useMediaQuery } from "@tm/components"
import { PartListItemActions } from "../../../business"
import { getBundleParams } from "../../../../../utils"
import DeleteButton from "../../../../_shared/DeleteButton"
import { BasketPart } from "../../../../../models"

type Props = {
    creatingCostEstimation: boolean
    disableControls?: boolean
    isLinkedItem?: boolean
    part: BasketPart
    onIncludeExcludeBasketPart?(part: BasketPart): void
    onIncludeExcludePart(part: BasketPart): void
    onOpenArticleDetails(
        productGroupId?: number,
        supplierArticleNumber?: string,
        supplierId?: number,
        subPage?: string,
        scrollTo?: string,
        externalBasketItemId?: string
    ): void
    onOpenPartEditor?(id: string): void
    onOpenPartRepairTimes?(part: BasketPart): void
    onRemoveParts?(partIds: string[]): void
    onSelectPart(id: string): void
}

export default function PartActionsComponent(props: Props) {
    const { part, disableControls, creatingCostEstimation, isLinkedItem } = props
    const { partItem, states, repairTimeProviders } = part
    const { id, costEstimationItem, articleInformation, orderItem } = partItem
    const { supplierId, productGroupId, articleNumber, articleInfoType } = articleInformation

    const { translateText } = useLocalization()
    const { workTask } = useWorkTask() ?? {}
    const theme = useTheme()
    const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"))

    function handleOpenPartEditor() {
        if (states.isSelected) {
            props.onSelectPart(id)
        }
        props.onOpenPartEditor?.(id)
    }

    function handleMenuItemSelect(menuItem: MoreMenuItem) {
        if (menuItem.id === PartListItemActions.RepairTimes) {
            props.onOpenPartRepairTimes?.(part)
        } else if (menuItem.id === PartListItemActions.Details) {
            props.onOpenArticleDetails(productGroupId, articleNumber, supplierId, undefined, undefined, part.partItem.externalId)
        }
    }

    function renderMoreMenu() {
        const menuItems: Array<MoreMenuItem> = []
        if (workTask && workTask.vehicle) {
            menuItems.push({
                icon: "repairtimes",
                id: PartListItemActions.RepairTimes,
                text: translateText(83),
                disabled: !repairTimeProviders?.length,
            })
        }
        menuItems.push({
            icon: "search",
            id: PartListItemActions.Details,
            text: translateText(43),
        })

        return (
            <MoreMenu
                items={menuItems}
                onMenuItemSelect={handleMenuItemSelect}
                disabled={disableControls || isLinkedItem || articleInfoType !== ArticleInfoType.TecdocArticle}
            />
        )
    }

    return (
        <Stack direction="row" spacing={isBigScreen ? 1 : 0} alignItems="center">
            <Switch
                label={translateText(49)}
                checked={!!costEstimationItem?.isIncluded}
                labelPlacement="top"
                size="small"
                onChange={() => props.onIncludeExcludePart(part)}
                disabled={creatingCostEstimation}
                typographySx={{ color: "black", textTransform: "none" }}
            />
            <Button
                variant="text"
                title={translateText(122)}
                onClick={handleOpenPartEditor}
                startIcon={<Icon name="edit_change" />}
                disabled={!costEstimationItem?.isIncluded || disableControls || isLinkedItem || creatingCostEstimation}
            />
            <DeleteButton
                ids={[id]}
                buttonTitleId={624}
                disableButton={disableControls || isLinkedItem || creatingCostEstimation}
                dialogTextId={833}
                onConfirmRemove={(ids) => ids && props.onRemoveParts?.(ids)}
                confirmationKey={ConfirmationDialogKeys.DeleteBasketItem}
            />
            {renderMoreMenu()}
            {getBundleParams().showIsInOrderSwitch ? (
                <div className="is-in-order-checkbox">
                    {/* The checkbox was intended to be a switch, but after many discussions with the customer, it remained as checkbox. */}
                    <Checkbox
                        checked={orderItem?.isIncluded}
                        onChange={() => props.onIncludeExcludeBasketPart?.(part)}
                        disabled={disableControls || isLinkedItem || creatingCostEstimation || !!costEstimationItem?.orderInformation}
                    />
                </div>
            ) : (
                <Checkbox
                    checked={states.isSelected || false}
                    onChange={() => props.onSelectPart(id)}
                    disabled={disableControls || creatingCostEstimation}
                />
            )}
        </Stack>
    )
}
