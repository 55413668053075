import { getInitialState } from "."
import { ActiveButtons, SelectedItems } from "../../models"
import { fastServiceStore } from "../store"

export function resetMaintenanceReview() {
    fastServiceStore.setState(getInitialState(), false, "Reset maintenance review slice")
}

export function sendItemsToMaintenanceReview(path: string, data: Record<string, SelectedItems>) {
    const state = fastServiceStore.getState()
    const maintenanceReviewState = state.maintenanceReview
    const selWorks = maintenanceReviewState.selectedWorks.set(path, data)

    fastServiceStore.setState(
        {
            maintenanceReview: {
                items: {
                    ...maintenanceReviewState.items,
                    [path]: data,
                },
                itemsForPrint: {
                    ...maintenanceReviewState.items,
                    [path]: data,
                },
                activeFilterButton: "none",
                selectedWorks: selWorks,
            },
        },
        false,
        "Send items to maintenance review"
    )
}

export function filterData(buttonType: ActiveButtons) {
    const state = fastServiceStore.getState()
    const maintenanceReviewState = state.maintenanceReview

    let filteredWorks: typeof maintenanceReviewState.selectedWorks = new Map([])

    if (buttonType === "none") {
        filteredWorks = new Map(Object.entries(maintenanceReviewState.items))
    } else {
        Object.entries(maintenanceReviewState.items).forEach(([key, items]) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const foundItems = Object.entries(items).filter(([_, item]) => item.selectedStatusButton === buttonType)
            if (foundItems.length) {
                filteredWorks.set(key, Object.fromEntries(foundItems))
            }
        })
    }

    fastServiceStore.setState(
        {
            maintenanceReview: {
                items: {
                    ...maintenanceReviewState.items,
                },
                activeFilterButton: buttonType,
                selectedWorks: filteredWorks,
                itemsForPrint: {
                    ...maintenanceReviewState.itemsForPrint,
                },
            },
        },
        false,
        "Filter data"
    )
}
