import { RimDetailsArticle } from "./model"

export function mapRimDetailsArticleWithArticleToGenerailItem(rimDetailsArticle: RimDetailsArticle): any {
    return {
        ...rimDetailsArticle.articleDetails,
        quantity: rimDetailsArticle.quantity,
        id: rimDetailsArticle.articleDetails?.id && rimDetailsArticle.articleDetails.id.toString() || "",
        internalId: rimDetailsArticle.articleDetails && rimDetailsArticle.articleDetails.id,
        applictionType: rimDetailsArticle.appType,
        productGroup: {
            id: rimDetailsArticle.productGroupId,
            name: rimDetailsArticle.productGroupName
        },
        supplier: {
            id: rimDetailsArticle.supplierId, 
            name: rimDetailsArticle.supplierName
        },
        supplierArticleNo: rimDetailsArticle?.artNr ?? "",
    }
}

export function mapRimDetailsArticleWithoutArticleToGenerailItem(rimDetailsArticle: RimDetailsArticle): any {
    return {
        attributes: [],
        description: rimDetailsArticle.artDescription,
        productGroup: {
            id: rimDetailsArticle.productGroupId,
            name: rimDetailsArticle.productGroupName
        },
        supplier: {
            id: rimDetailsArticle.supplierId,
            name: rimDetailsArticle.supplierName
        },
        quantity: rimDetailsArticle.quantity,
        supplierArticleNo: rimDetailsArticle.artNr,
        thumbnail: rimDetailsArticle.logoUrl,
        applictionType: rimDetailsArticle.appType,
        applictionTypeID: rimDetailsArticle.appTypeID
    }
}

export function mapArticleImage(image: any) {
    return {
        url: image.imageUrl,
        thumbnailUrl: image.thumbnailUrl,
        description: image.description
    }
}