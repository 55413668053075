import { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Button, Typography, styled, useTheme } from "@tm/components"
import { classes, useUser } from "@tm/context-distribution"
import { Image, Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { stepNavigationActions, worksActions, useFastServiceStore } from "../../data"
import { getHaynesProServiceReset, useServiceBookUrl } from "../../helpers"
import { CustomPanel, HaynesProServiceModal, NextStep } from "../_shared"
import { getComponentStyles } from "../_shared/styles"
import WorksList from "../works/components/WorksList"
import InspectionTitleHeader from "./components/inspectionTitle"
import IntervalDate from "./components/intervalDate"
import { PanelState, StepNames } from "../../data/models"

const currentStepLocation = StepNames.CheckWork
const worksWhiteList = ["1242", "105000036"]

export default function InspectionWork() {
    const muiTheme = useTheme()
    const { translateText, languageId } = useLocalization()
    const classNames = getComponentStyles()
    const { stateVehicle, customWorkData, workData } = useFastServiceStore((state) => ({
        stateVehicle: state.maintenancePlan.stateVehicle,
        customWorkData: state.worksState.customWorks[currentStepLocation],
        workData: state.worksState.works[currentStepLocation],
    }))

    const serviceBookUrl = useServiceBookUrl(stateVehicle?.tecDocManufacturerId || undefined)
    const { userContext } = useUser() ?? {}

    const [url, setUrl] = useState("")
    const [open, setOpen] = useState(false)
    const [nextPanelId, setNextPanelId] = useState(0)
    const [worksCompleted, setWorksCompleted] = useState(false)

    const inspectionWorksCompleted = useMemo(() => customWorkData?.every((inspection) => inspection.isCompleted), [customWorkData])
    const filteredWorkData = useMemo(() => workData?.filter((w) => worksWhiteList.includes(w.providerId)), [workData])

    const completed = useMemo(
        () => (filteredWorkData ? !!inspectionWorksCompleted && !!worksCompleted : !!inspectionWorksCompleted),
        [filteredWorkData, inspectionWorksCompleted, worksCompleted]
    )

    const handleServiceReset = useCallback(() => {
        const serviceResetUrl = getHaynesProServiceReset(userContext, languageId)
        if (!serviceResetUrl) {
            return
        }

        setUrl(serviceResetUrl)
        setOpen(true)
    }, [stateVehicle, userContext, languageId])

    useEffect(() => {
        if (completed) {
            stepNavigationActions.updateNextStep(currentStepLocation)
            stepNavigationActions.completeStep(currentStepLocation)
        }
    }, [completed])

    const onPanelStatusChanged = (panelId: number, state: PanelState) => {
        if (customWorkData) {
            worksActions.updateCustomWorkField(customWorkData, panelId, state)
            setNextPanelId(panelId + 1)

            const selectedPanel = customWorkData[panelId]
            if (selectedPanel?.providerId) {
                const selectedWorkData = workData?.find((w) => w.providerId === selectedPanel.providerId)
                const path = selectedWorkData?.label
                if (path) {
                    worksActions.updateWorkField({ key: "selectedStatusButton", value: "checkButton" }, path, currentStepLocation)
                }
            }
        }
    }

    const handlePanelTitle = (titleIds: number[]) => {
        let panelTitle = ""
        titleIds.forEach((titleId) => {
            panelTitle += `${translateText(titleId)} `
        })
        return panelTitle.trim()
    }

    const renderCustomWorkContent = (customWorkId: number) => {
        switch (customWorkId) {
            case 0:
                return (
                    <Box sx={{ marginTop: "0.5em" }}>
                        <Button size="large" onClick={handleServiceReset}>
                            {translateText(12954)}
                        </Button>
                    </Box>
                )
            case 1:
                return (
                    <Box sx={{ marginTop: "0.5em" }}>
                        <Typography flex="0.6">{translateText(12955)}</Typography>
                        <IntervalDate />
                    </Box>
                )
            case 2:
                return (
                    <Box sx={{ marginTop: "0.5em" }}>
                        <Typography flex="0.6">{translateText(12951)}</Typography>
                        <Box display="flex" marginBottom={muiTheme.margin?.xl ?? "1em"}>
                            <StyledImage url="/styles/base/images/warning-triangle.svg" />
                            <Typography flex="0.6">{translateText(12952)}</Typography>
                        </Box>
                        <Button disabled={!serviceBookUrl} size="large" href={serviceBookUrl} target="_blank">
                            {translateText(12953)}
                        </Button>
                    </Box>
                )
            default:
                return <></>
        }
    }

    const renderPanelTitle = (title: string, panelId: number, panelState: PanelState) => {
        return <InspectionTitleHeader title={title} id={panelId} onStatusButtonClick={onPanelStatusChanged} panelState={panelState} />
    }

    return (
        <>
            <Scrollbar>
                <div className={classes(classNames.wrapperMargins, classNames.wrapperMinHeight)}>
                    {customWorkData?.map((panel) => (
                        <CustomPanel
                            key={panel.id}
                            title={renderPanelTitle(handlePanelTitle(panel.title), panel.id, panel.panelState)}
                            initiallyClosed={nextPanelId !== panel.id}
                        >
                            {panel.location === currentStepLocation && renderCustomWorkContent(panel.id)}
                        </CustomPanel>
                    ))}

                    {filteredWorkData && (
                        <WorksList
                            disableAutomaticTicking
                            workWhiteList={worksWhiteList}
                            step={currentStepLocation}
                            setCompleted={setWorksCompleted}
                        />
                    )}
                </div>
            </Scrollbar>
            {open && <HaynesProServiceModal open={open} url={url} onClose={setOpen} />}
            <NextStep
                currentStepName={currentStepLocation}
                buttonTextId={12941}
                icon="arrows_down"
                active={filteredWorkData ? !!inspectionWorksCompleted && !!worksCompleted : !!inspectionWorksCompleted}
            />
        </>
    )
}

const StyledImage = styled(Image)(({ theme }) => ({
    marginRight: theme.margin?.l ?? "0.75em",
    width: "1.25em",
    height: "1.25em",
}))
