import { useCallback, useMemo } from "react"
import { ArticleAttribute } from "@tm/models"
import { useShowArticleDetails } from "../../hooks"
import { useFiltersContext } from "../Filters"
import { ArticleListActions } from "../../models"
import { useListParamsContext } from "../ListParams"
import { useBasketQuantitiesContext } from "../BasketQuantities"

export function useArticleListActions(): ArticleListActions {
    const showArticleDetails = useShowArticleDetails()
    const { toggleAttribute } = useListParamsContext()
    const { toggleSupplier } = useFiltersContext()
    const { updateBasketQuantities } = useBasketQuantitiesContext()

    const toggleInlineFilter = useCallback((attribute: ArticleAttribute) => {
        toggleAttribute(attribute)
    }, [])

    return useMemo<ArticleListActions>(
        () => ({
            showArticleDetails,
            toggleSupplier,
            toggleInlineFilter,
            updateBasketQuantities,
        }),
        [showArticleDetails, toggleSupplier, toggleInlineFilter, updateBasketQuantities]
    )
}
