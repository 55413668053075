import { memo, useState } from "react"
import { batch, useSelector } from "react-redux"
import { Tooltip, styled } from "@tm/components"
import { Button, Dropdown, PanelSection, Scrollbar, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { EFilterNames, TyreFilter } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { AvailabilityFilterType } from "../../../business"
import { FilterType, getEnumByPath, getIconByGroup, getTitleByGroup, selectedTyresFiltersSelector, tyresFiltersSelector } from "../../../data/helpers"
import { IListFilters, IListMultiFilters } from "../../../data/models"
import { CustomRangeSlider, FilterComponent, ResetButtonFromState, SelectionCheckbox, SelectionItems } from "../../_shared"
import { MainState } from "../../main"
import { Actions } from "../business"
import { useAvailabilityStatus } from "@tm/context-distribution"

type Props = {
	className?: string
}

type CollapsibleFilters = IListFilters | "availability"

type State = {
	[key in CollapsibleFilters]?: {
		searchValue?: string
		opened?: boolean
		searchEnabled?: boolean
	}
}

const FilterPanel = styled(PanelSection)(({ theme }) => ({
	margin: theme.spacing(2, 1),
	display: "flex",
	flexDirection: "column",
	".panel__content": {
		flex: 1,
		height: "100%",
	},
}))

const SizeDropdown = styled("div")({
	width: "100%",
})

export const TyresDropDownItemView = memo<TyreFilter>(({ value }) => <div key={value} style={{ textAlign: "center" }}>{value}</div>)

export function TiresFilters({ className }: Props) {
	const { translateText } = useLocalization()

	const actions = useActions(Actions, "loadTiresList", "updateFilter", "resetFilter", "changeAvailabilityFilter")
	const selectedFilters = useSelector(selectedTyresFiltersSelector)
	const filters = useSelector(tyresFiltersSelector)
	const displayStateReset = useSelector((s: MainState) => s.tyresList.displayStateReset)
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()
	const [filterState, setFilterState] = useState<State>({ tyreSize: { opened: true }, season: { opened: true }, availability: { opened: true }, loadIndex: { opened: true }, speedIndex: { opened: true } })

	const handleFilterChange = (path: IListFilters, value: TyreFilter, fromFilter?: boolean) => {
		batch(() => {
			actions.updateFilter(path, value)
			actions.loadTiresList(fromFilter, path)
		})
	}

	const handleSearchTextChange = (path: CollapsibleFilters, value: string) => {
		setFilterState(state => ({
			...state,
			[path]: {
				...state[path],
				searchValue: value
			}
		}))
	}

	const handleSearchVisibility = (path: CollapsibleFilters) => {
		setFilterState(state => ({
			...state,
			[path]: {
				...state[path],
				searchEnabled: !state[path]?.searchEnabled,
				opened: true
			}
		}))
	}

	const handleCollapsibleChange = (path: CollapsibleFilters) => {
		setFilterState(state => ({
			...state,
			[path]: {
				...state[path],
				opened: !state[path]?.opened
			}
		}))
	}

	const handleFilterReset = (path: IListFilters) => {
		handleSearchTextChange(path, "")
		batch(() => {
			actions.resetFilter(path)
			actions.loadTiresList(true)
		})
	}

	const handleAllFiltersReset = () => {
		actions.loadTiresList()
	}

	const renderSelectionFilter = (path: IListMultiFilters, title: string) => {
		return (
			<FilterComponent
				title={title}
				loading={filters.loading}
				filterId={getEnumByPath(path)}
				onReset={handleFilterReset.bind(undefined, path)}
				disabled={!filters[path].length}
				onSearchChange={handleSearchTextChange.bind(undefined, path)}
				onSearchVisibility={handleSearchVisibility.bind(undefined, path)}
				searchValue={filterState[path]?.searchValue}
				active={filterState[path]?.opened && !!selectedFilters[path]}
				searchEnabled={filterState[path]?.searchEnabled}
				resetBtnDisabled={!selectedFilters[path]?.length}
				onCollapsibleChange={handleCollapsibleChange.bind(undefined, path)} >

				<SelectionItems
					searchValue={filterState[path]?.searchValue}
					items={filters[path]}
					selectedValues={selectedFilters[path]}
					onChange={x => handleFilterChange(path, x, true)}
					onReset={handleFilterReset.bind(undefined, path)} />

			</FilterComponent>
		)
	}

	const handleSizeChange = (tireFilter: TyreFilter | undefined) => {
		if (!tireFilter?.query) {
			handleFilterReset(EFilterNames.tyreSize)
			return
		}

		if (tireFilter && selectedFilters.tyreSize?.query != tireFilter.query) {
			handleFilterChange(EFilterNames.tyreSize, tireFilter)
		}
	}

	const renderTireSize = () => {

		return (
			<FilterComponent
				filterId={FilterType.TireSizes}
				title={translateText(712)}
				onCollapsibleChange={handleCollapsibleChange.bind(undefined, EFilterNames.tyreSize)}
				active={filterState.tyreSize?.opened}
				loading={filters.loading}
				disabled={!filters.tyreSize.length}>

				<SizeDropdown>
					<Dropdown<TyreFilter>
						value={selectedFilters.tyreSize}
						items={filters.tyreSize}
						onChange={handleSizeChange}
						disabled={!filters.tyreSize.length}
						itemView={TyresDropDownItemView}
						amountItemsToShow={5}
					/>
				</SizeDropdown>
			</FilterComponent>
		)
	}

	const renderSeason = () => {
		return (
			<FilterComponent
				filterId={FilterType.Season}
				title={translateText(1235)}
				onCollapsibleChange={handleCollapsibleChange.bind(undefined, EFilterNames.season)}
				active={filterState.season?.opened}
				onReset={handleFilterReset.bind(undefined, EFilterNames.season)}
				resetBtnDisabled={!selectedFilters.season}
				loading={filters.loading}
				disabled={!filters.season.length}>
				<Toolbar>
					{filters.season?.map((season) => (
						<Tooltip title={translateText(getTitleByGroup(season.query))} key={season.query}>
							<Button
								icon={getIconByGroup(season.query)}
								key={season.query}
								fakeButton
								isActive={selectedFilters.season?.query == season?.query}
								onClick={() => handleFilterChange(EFilterNames.season, season, false)}
							/>
						</Tooltip>
					))}
				</Toolbar>
			</FilterComponent>
		)
	}

	const renderAvailability = () => {
        if (!availabilityStatusIdsToShow?.length && !availabilityStatusIdsToShowSecondary?.length) {
			return null
		}

		const primarySelected = selectedFilters.availability === AvailabilityFilterType.Primary
		const secondarySelected = selectedFilters.availability === AvailabilityFilterType.Secondary

		return (
			<FilterComponent
				filterId={FilterType.Availability}
				title={translateText(412)}
				onCollapsibleChange={handleCollapsibleChange.bind(undefined, "availability")}
				active={filterState.availability?.opened}
				onReset={() => actions.changeAvailabilityFilter(AvailabilityFilterType.None)}
				resetBtnDisabled={!selectedFilters.availability}
				loading={filters.loading}
				disabled={!filters.manufacturer.length && !filters.loadIndex.length || filters.loading}
			>
				{
					!!availabilityStatusIdsToShow?.length &&
					<SelectionCheckbox
						label={translateText(1623)}
						selected={primarySelected}
						onChange={() => actions.changeAvailabilityFilter(primarySelected ? AvailabilityFilterType.None : AvailabilityFilterType.Primary)}
					/>
				}
				{
					!!availabilityStatusIdsToShowSecondary?.length &&
					<SelectionCheckbox
						label={translateText(12860)}
						selected={secondarySelected}
						onChange={() => actions.changeAvailabilityFilter(secondarySelected ? AvailabilityFilterType.None : AvailabilityFilterType.Secondary)}
					/>
				}
			</FilterComponent>
		)
	}

	const renderRangeSliders = () => {
		return (
			<FilterComponent
				filterId={FilterType.RangeSliders}
				title={translateText(12404)}
				onReset={() => {
					batch(() => {
						actions.resetFilter(EFilterNames.fuelEfficiency)
						actions.resetFilter(EFilterNames.wetGripClass)
						actions.resetFilter(EFilterNames.externalRolling)
						actions.loadTiresList(true)
					})
				}}
				resetBtnDisabled={!selectedFilters.fuelEfficiency && !selectedFilters.wetGripClass && !selectedFilters.externalRolling}
				disabled={filters.loading || !filters.fuelEfficiency.length && !filters.wetGripClass.length && !filters.externalRolling.length}
				loading={filters.loading}
				onCollapsibleChange={handleCollapsibleChange.bind(undefined, EFilterNames.fuelEfficiency)}>

				<CustomRangeSlider
					title={translateText(722)}
					items={filters.fuelEfficiency}
					icon="gas-station"
					onChange={(x) => handleFilterChange(EFilterNames.fuelEfficiency, x, true)}
					onReset={handleFilterReset.bind(undefined, EFilterNames.fuelEfficiency)}
					selectedItem={selectedFilters.fuelEfficiency}
				/>
				<CustomRangeSlider
					title={translateText(13279)}
					items={filters.wetGripClass}
					icon="rain"
					onChange={(x) => handleFilterChange(EFilterNames.wetGripClass, x, true)}
					onReset={handleFilterReset.bind(undefined, EFilterNames.wetGripClass)}
					selectedItem={selectedFilters.wetGripClass}
				/>
				<CustomRangeSlider
					title={translateText(13280)}
					items={filters.externalRolling}
					icon="sound"
					minimized
					onChange={(x) => handleFilterChange(EFilterNames.externalRolling, x, true)}
					onReset={handleFilterReset.bind(undefined, EFilterNames.externalRolling)}
					selectedItem={selectedFilters.externalRolling}
				/>
			</FilterComponent>
		)
	}

	const { tyreSize, ...atributeFilters } = selectedFilters
	const isResetBtnEnabled = Object.values(atributeFilters).some(x => Array.isArray(x) ? x.length : x)

	return (
		<FilterPanel className={className}>
			<Scrollbar>
				{displayStateReset && <ResetButtonFromState onReset={handleAllFiltersReset} />}
				<Button icon="remove-filter" disabled={!isResetBtnEnabled} onClick={handleAllFiltersReset} />
				{renderAvailability()}
				{renderTireSize()}
				{renderSeason()}
				{renderSelectionFilter(EFilterNames.manufacturer, translateText(71))}
				{renderSelectionFilter(EFilterNames.loadIndex, translateText(12403))}
				{renderSelectionFilter(EFilterNames.speedIndex, translateText(719))}
				{renderSelectionFilter(EFilterNames.oeIdentifier, translateText(720))}
				{renderSelectionFilter(EFilterNames.extras, translateText(744))}
				{renderRangeSliders()}
			</Scrollbar>
		</FilterPanel>
	)
}
