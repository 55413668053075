import { Typography } from "@tm/components"
import { ErpPriceDictionary } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { useBasketErpTotalInOrder } from "./useBasketErpTotalInOrder"

type Props = {
    erpTotalsInOrder?: ErpPriceDictionary
}

export default function BasketErpTotalInOrder({ erpTotalsInOrder }: Props) {
    const { userSettings } = useUser() || {}
    const showPurchasePrice = userSettings?.showPurchasePrice ?? true
    const { totalPriceAsCurrency } = useBasketErpTotalInOrder(erpTotalsInOrder)

    if (!totalPriceAsCurrency) {
        return null
    }

    return (
        <Typography color={showPurchasePrice ? "primary" : undefined} fontWeight="bold">
            {totalPriceAsCurrency}
        </Typography>
    )
}
