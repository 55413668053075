import { Box, Button, Icon, SearchField, styled, Tooltip } from "@tm/components"
import { useEffect, useState } from "react"
import { Toolbar } from "@tm/controls"
import { usePrevious } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { getBundleParams, isWM } from "../../../../../utils"
import CompilationWMNavigation from "./details_wm"
import { CompilationAddNavigation } from "../add/add"
import { ImportArticle } from "./importArticle"

const SmallCloseIcon = styled(Box)(({ theme }) => ({
    opacity: 0.54,
    cursor: "pointer",
    marginRight: theme.spacing(0.2),
    paddingTop: theme.spacing(0.5),

    ">svg": {
        width: "1em",
        height: "1em",
    },
}))

type Props = {
    compilationId: string | undefined
    showCreator: boolean
    loadingCompilationPdf?: boolean
    onShowCreator(): void
    searchCompilation(query: string): void
    onPrintButtonClick(): void
    openCompilations(): void
}

export function Options(props: Props) {
    const { compilationId, showCreator, loadingCompilationPdf, onShowCreator, searchCompilation, onPrintButtonClick, openCompilations } = props
    const { translateText } = useLocalization()
    const { enableListImport, showPrintButton } = getBundleParams()
    const detailsIsActive = !!compilationId

    const [searchText, setSearchText] = useState<string>("")

    const prevSearchText = usePrevious(searchText)

    const handleButtonClick = () => {
        searchCompilation(searchText)
    }

    useEffect(() => {
        if (prevSearchText && !searchText) {
            handleButtonClick()
        }
    }, [prevSearchText, handleButtonClick])

    if (isWM()) {
        return (
            <>
                <SmallCloseIcon />
                <Toolbar title={translateText(177)}>
                    <CompilationWMNavigation
                        onShowCreator={onShowCreator}
                        showCreator={showCreator}
                        compilationId={compilationId}
                        openCompilations={openCompilations}
                    />
                    {enableListImport && detailsIsActive && <ImportArticle compilationId={compilationId} />}
                </Toolbar>
                {!detailsIsActive && (
                    <Toolbar title={translateText(135)}>
                        <SearchField
                            id="compilationsSearch"
                            value={searchText}
                            onChange={setSearchText}
                            onStartSearch={handleButtonClick}
                            placeholder={translateText(1770)}
                            inputProps={{ maxLength: 100 }}
                        />
                    </Toolbar>
                )}
            </>
        )
    }

    return (
        <>
            <Toolbar title={translateText(135)}>
                <SearchField
                    id="compilationsSearch"
                    value={searchText}
                    onChange={setSearchText}
                    onStartSearch={handleButtonClick}
                    placeholder={translateText(1770)}
                    inputProps={{ maxLength: 100 }}
                />
                {enableListImport && detailsIsActive && <ImportArticle compilationId={compilationId} />}
            </Toolbar>
            <Toolbar title={translateText(177)}>
                <CompilationAddNavigation onShowCreator={onShowCreator} showCreator={showCreator} />
                {showPrintButton && (
                    <Tooltip title={translateText(49)}>
                        <Box>
                            <Button
                                disabled={!compilationId || loadingCompilationPdf}
                                onClick={onPrintButtonClick}
                                startIcon={<Icon name="print" />}
                                variant="outlined"
                            />
                        </Box>
                    </Tooltip>
                )}
            </Toolbar>
        </>
    )
}
