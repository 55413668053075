import { useCallback, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { useSelection } from "@tm/utils"
import { Alert, Box, Stack, Typography, Skeleton } from "@tm/components"
import CompilationCreator from "./components/creator"
import CompilationsList from "./components/list"
import CompilationsSelection from "./components/selection"
import { Compilation } from "../../../../data"
import { isWM } from "../../../../utils"
import { sortByCreatedAtDesc } from "../../../../utils/time"

type Props = {
    compilations: Compilation[] | undefined
    loading: boolean
    error: string | undefined
    showCreator: boolean
    compact: boolean
    onHideCreator(): void
    openCompilationDetails(id: string): void
    openCompilationList(): void
}

export default function CompilationsListComponent(props: Props) {
    const { compact, compilations, loading, error, showCreator, onHideCreator, openCompilationDetails, openCompilationList } = props
    const { translateText } = useLocalization()

    const getAllIds = useCallback(() => compilations?.filter((x) => x.isOwn).map((x) => x.id) || [], [compilations])
    const { selectedIds, toggleSelected, selectAll, unselectAll } = useSelection(getAllIds)

    useEffect(() => {
        unselectAll()
    }, [compilations])

    function renderCompilationsSelection() {
        if (!compact && compilations?.length) {
            if (selectedIds.length > 0) {
                const ownCompilations = compilations.filter((compilation) => compilation.isOwn)
                const allSelected = ownCompilations.length === selectedIds.length

                return (
                    <CompilationsSelection
                        allSelected={allSelected}
                        selectedCompilationIds={selectedIds}
                        onSelectAll={selectAll}
                        onUnselectAll={unselectAll}
                    />
                )
            }
        }
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>
    }

    if (isWM() && compact) {
        return null
    }

    if (loading) {
        return (
            <Stack flex={compact ? undefined : 1} minWidth="25rem" style={{ paddingTop: "1rem" }}>
                <Skeleton variant="text" height="30px" />
                <Skeleton variant="text" height="63px" />
                <Skeleton variant="text" height="63px" />
            </Stack>
        )
    }

    return (
        <Stack flex={compact ? undefined : 1} minWidth="25rem">
            <Box position="relative">{renderCompilationsSelection()}</Box>
            {showCreator && !compact && <CompilationCreator onClose={onHideCreator} />}
            {!compilations || compilations.length === 0 ? (
                <Box alignSelf="center" alignItems="center" display="flex">
                    <Typography>{translateText(1284)}</Typography>
                </Box>
            ) : (
                <CompilationsList
                    compilations={compilations.sort(sortByCreatedAtDesc)}
                    onOpenCompilationDetails={openCompilationDetails}
                    onSelectCompilation={toggleSelected}
                    selectedCompilationIds={selectedIds}
                    compact={compact}
                    openCompilationList={openCompilationList}
                    showCreator={showCreator}
                    onShowCreator={onHideCreator}
                />
            )}
        </Stack>
    )
}
