import { ArticleErpInfo, GetArticlesRequest } from "@tm/models"
import { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { QueryStatus } from "react-query"
import { addQueryToHistory } from "../../../../_shared/helper/queryhistory"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"
import { fetchDirectSearchOrVehiclePartsArticles } from "../../../data/fetchDirectSearchOrVehiclePartsArticles"
import { getArticleGroupsWithExactMatches, publishModuleInfo } from "../../../helpers"
import { useArticlesBase } from "../../../hooks/useArticlesBase"
import { useDisplayFilter } from "../../../hooks/useDisplayFilter"
import { DirectSearchArticleData } from "../../../models"
import { useDirectSearchListParamsContext } from "../ListParams"

const QUERY_KEY = "DirectSearch.useArticles"

/**
 * Get information about the current article data of the direct search module.
 * Return value is not reference stable and should therefor not be used as any dependency for other hooks.
 */
export function useArticles(erpInfos: ArticleErpInfo[], isEnabled: boolean): DirectSearchArticleData {
    const params = useDirectSearchListParamsContext()
    const {
        productGroups,
        suppliers,
        attributes,
        startParams,
        extendedAssortment,
        fittingPosition,
        sorting: { activateDefault: activateDefaultSorting },
    } = params

    const { pageSize } = useArticleListConfiguration()

    const request = useMemo<GetArticlesRequest | null>(() => {
        if (!isEnabled || !startParams.query) {
            return null
        }

        return {
            pageSize,
            query: startParams.query,
            fittingSideFilter: fittingPosition,
            searchFilter: startParams.searchFilter,
            productGroupIds: productGroups.some((pg) => pg.isSelected) ? productGroups.map((x) => x.id) : undefined,
            supplierIds: suppliers.map((x) => x.id),
            attributeFilter: attributes.map((attr) => `${attr.id}|${attr.key ?? attr.value ?? ""}`).join("|"),
            extendedAssortment,
        }
    }, [isEnabled, pageSize, startParams, productGroups, suppliers, attributes, extendedAssortment, fittingPosition])
    const [requestStatus, setRequestStatus] = useState<QueryStatus>("idle")
    const articlesBase = useArticlesBase(request, fetchDirectSearchOrVehiclePartsArticles, QUERY_KEY)
    const { isSuccess, articles, articleCount, pageCount, hasNextPage } = articlesBase

    const articleGroups = useMemo(() => getArticleGroupsWithExactMatches(articles, hasNextPage), [articles, hasNextPage])
    const articleGroupsCombined = useMemo(
        () => [...articleGroups.exactMatch, ...articleGroups.other],
        [articleGroups.exactMatch, articleGroups.other]
    )

    useEffect(() => {
        // add query to history if successfully
        if (isSuccess && articles.length && startParams.query) {
            addQueryToHistory(startParams.query)
        }
    }, [isSuccess, articles.length, startParams.query])

    useEffect(() => {
        function resetNoResult() {
            params.setNoResult(false)
        }
        resetNoResult()

        publishModuleInfo("{{389}}", startParams.query && `"${startParams.query}"`)
    }, [request])

    useEffect(
        function setNoResult() {
            if (isSuccess && !articleCount && pageCount === 1) {
                params.setNoResult(true)
            }
        },
        [isSuccess]
    )

    useEffect(() => {
        if (isSuccess) {
            activateDefaultSorting(articleCount, pageCount, articleGroupsCombined)
        }
    }, [activateDefaultSorting, articleCount, articleGroupsCombined, isSuccess, pageCount])

    useLayoutEffect(
        function waitUntilRendered() {
            if (isSuccess) {
                setRequestStatus("success")
            }
        },
        [isSuccess]
    )

    const exactMatchDisplayFilter = useDisplayFilter(articleGroups.exactMatch, params, erpInfos)
    const displayFilter = useDisplayFilter(articleGroups.other, params, erpInfos)

    return {
        ...articlesBase,
        requestStatus,
        isStalling: (exactMatchDisplayFilter.stalling || displayFilter.stalling) && pageCount === 1,
        isFrontendFiltered: displayFilter.isFrontendFiltered,
        articleGroups: displayFilter.articleGroups,
        exactMatchArticleGroups: exactMatchDisplayFilter.articleGroups,
    }
}
