import { useEffect, useRef, useState } from "react"
import { Alert, Box, Loader, Snackbar } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { managerActions, useFastServiceStore } from "../../data"
import ModuleManager from "../module-manager/component"
import Navigation from "../navigation/component"
import Steps from "../steps-navigation/component"
import { bundleChannel } from "../../helpers"
import { AlertColor, AlertMessage, AlertMessageTypes, DocumentElementExt, FullScreenType } from "../../data/models"
import { toggleFullScreen } from "../../helpers/fullscreen"

declare let document: Document & FullScreenType & { documentElement: DocumentElementExt }

type Props = {
    route: string
}

const fullscreenHandlers = ["fullscreenchange", "msfullscreenchange", "webkitfullscreenchange", "mozfullscreenchange"]

export default function Main({ route }: Props) {
    const mainRef = useRef<HTMLDivElement | null>(null)

    const { maintenancePlanLoading, historyLoading } = useFastServiceStore((state) => ({
        maintenancePlanLoading: state.maintenancePlan.maintenancePlanLoading,
        historyLoading: state.historyLoading,
    }))

    const loading = maintenancePlanLoading || historyLoading
    const { translateText } = useLocalization()
    const [saveAlert, setSaveAlert] = useState<AlertMessage>({ message: "" })

    useEffect(() => {
        fullscreenHandlers.forEach((x) => document.addEventListener(x, handleFullscreenChange))
        document.addEventListener("keydown", handleKeyPress)

        bundleChannel().subscribe("ALERT_MESSAGE", ({ messageType, message, severity }) => {
            handleAlertMessage(message, messageType, severity)
        })

        return () => {
            fullscreenHandlers.forEach((x) => document.removeEventListener(x, handleFullscreenChange))
            document.removeEventListener("keydown", handleKeyPress)
            bundleChannel().clearSubscriptions("ALERT_MESSAGE")
        }
    }, [])

    const handleAlertMessage = (message: string, messageType?: AlertMessageTypes, severity?: AlertColor) => {
        if (messageType === AlertMessageTypes.HISTORY) {
            switch (message) {
                case "savedFromButton":
                    setSaveAlert({ message: translateText(13503), severity })
                    break
                case "technician":
                    setSaveAlert({ message: translateText(13506), severity })
                    break
                case "tester":
                    setSaveAlert({ message: translateText(13505), severity })
                    break
                case "end":
                    setSaveAlert({ message: translateText(13504), severity })
                    break
                default:
                    setSaveAlert({ message: "" })
                    break
            }
        }
    }

    const handleFullScreen = () => {
        toggleFullScreen(mainRef?.current)
    }

    // don't use state vars inside these handlers!
    const handleFullscreenChange = () => {
        const isFullscreen =
            document.fullscreen ||
            document.Fullscreen ||
            document.mozFullScreen ||
            document.webkitFullscreen ||
            document.webkitIsFullScreen ||
            document.msRequestFullscreen ||
            false

        managerActions.setFullScreen(isFullscreen)
    }

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e?.key === "F11") {
            e.preventDefault()
            toggleFullScreen(mainRef?.current)
        }
    }

    const handleCloseAlert = () => {
        bundleChannel().clear("ALERT_MESSAGE")
        setSaveAlert({ message: "" })
    }

    return (
        <Box height="100%" width="100%" className="fast-service-main" ref={mainRef}>
            <ModuleManager />
            <Navigation route={route} onFullScreenChange={handleFullScreen} />
            {loading && <Loader position="absolute" top="50%" left="50%" zIndex="1" />}
            <Steps isLoading={loading} mainWrapperRef={mainRef} />
            <Snackbar
                open={saveAlert.message !== ""}
                autoHideDuration={3000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    icon={false}
                    severity={saveAlert.severity}
                    size="small"
                    title={saveAlert.message}
                    onClose={handleCloseAlert}
                    sx={{ alignItems: "center" }}
                />
            </Snackbar>
        </Box>
    )
}
