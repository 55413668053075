import { useInfiniteQuery } from "react-query"
import { useMemo } from "react"
import { notUndefinedOrNull } from "@tm/utils"
import { BookingRequest } from "../data/models"
import { getBookingResponse } from "../data/repositories"

export function useGetBookings(offset = 0, limit = 10) {
    const maxItems = 100

    const request: BookingRequest = {
        offset,
        limit,
    }

    const { data, isLoading, fetchNextPage } = useInfiniteQuery(
        [request.limit, request.offset],
        ({ pageParam }) => {
            return getBookingResponse(pageParam ?? request)
        },
        {
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage) {
                    return
                }

                if (limit * pages.length >= maxItems) {
                    return
                }

                return {
                    ...request,
                    offset: limit * pages.length,
                }
            },
            cacheTime: 5 * 60 * 1000,
            staleTime: 10 * 60 * 1000,
            refetchInterval: 10 * 60 * 1000,
            refetchOnWindowFocus: true,
        }
    )

    const bookings = useMemo(() => data?.pages.flatMap((page) => page).filter(notUndefinedOrNull), [data?.pages])

    return { bookings, isLoading, fetchNextPage }
}
