import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { PrintImage } from "../../models"

export type ImagesSlice = {
    imagesState: {
        images: Record<string, Record<string, PrintImage[]>>
    }
}

export function getInitialState(): ImagesSlice {
    return {
        imagesState: {
            images: {},
        },
    }
}

export const createImagesSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], ImagesSlice> = () => ({
    ...getInitialState(),
})
