import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { GetProductGroupTopicIdsResponse } from "@tm/models"
import { useArticlesContext } from "../Articles/ArticlesContext"
import { ensureContextAvailability } from "../../helpers"
import { useProductGroupTopicIds } from "./useProductGroupTopicIds"

export const ProductGroupTopicIdsContext = createContext<GetProductGroupTopicIdsResponse | undefined>(undefined)

export function ProductGroupTopicIdsProvider(props: PropsWithChildren<{ isEnabled: boolean }>) {
    const { productGroupIds } = useArticlesContext()
    const { productGroupTopicIds } = useProductGroupTopicIds(productGroupIds, props.isEnabled)

    return <ProductGroupTopicIdsContext.Provider value={productGroupTopicIds}>{props.children}</ProductGroupTopicIdsContext.Provider>
}

export function useProductGroupTopicIdsDataContext(): GetProductGroupTopicIdsResponse {
    const context = useContext(ProductGroupTopicIdsContext)
    return ensureContextAvailability("ProductGroupTopicIdsContext", context)
}
