import { Accordion, AccordionDetails, Icon, MuiAccordionSummary, styled } from "@tm/components"

export const CustomAccordion = styled(Accordion)(({ theme }) => ({
    marginTop: theme.margin?.xs ?? "0.1em",
    backgroundColor: "#efefef",
    boxShadow: "none",
    border: "1px solid #efefef",
    borderRadius: "3px",
    "&.general": {
        margin: 0,
        ".MuiCollapse-root": {
            display: "none",
        },
    },
    "&::before": {
        height: "0px",
        backgroundColor: "transparent",
    },
    position: "static",
}))

export const AccordionSummaryStyled = styled((props: any) => (
    <MuiAccordionSummary expandIcon={<Icon name={props.name === "general" ? "next" : "down"} />} {...props} />
))(({ theme }) => ({
    margin: theme.margin?.xs ?? "0.1em",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)",
    },
    "& .MuiAccordionSummary-content": {
        alignItems: "center",
        ".MuiTypography-root": {
            marginLeft: theme.spacing(1),
        },
        ".appendix": {
            marginLeft: theme.spacing(2),
        },
        ".icon": {
            color: theme.colors?.success,
        },
    },
    ".notCheckedInsidePoint": {
        width: "1em",
        height: "1em",
        borderRadius: "100%",
        border: "1px solid #8c8c8c",
    },
}))

export const AccordionContent = styled(AccordionDetails)({
    backgroundColor: "white",
})
