import { Card, CardActions, styled } from "@tm/components"

export const OfferCard = styled(Card)({
    width: "216px",
    minWidth: "216x",
    display: "flex",
    flexDirection: "column",
    height: "100%",
})

export const OfferCardPartActions = styled(CardActions)({
    display: "flex",
    justifyContent: "space-between",
    ".add-to-basket": {
        div: {
            ".amount-field": {
                button: {
                    minWidth: "72px",
                    width: "72px",
                },
            },
        },
    },
})
