import { Article, ErpInfoData, TraderErpInfosData } from "@tm/models"
import { notUndefinedOrNull, useErpConfig } from "@tm/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilValue } from "recoil"
import { tradeReferenceNumbersState } from "../../states"
import { ArticleErpInfoFactory, LoadErpInfoItem, useLoadErpInfos } from "../useErpInfos"
import { ErpInfosData } from "../../models"
import { TradeReferenceNumber } from "../../ContextProvider/TradeReferences/useTradeReferences"

export function useTraderErpInfos(article: Article, erpInfosData: ErpInfosData, tradeReferences?: TradeReferenceNumber[]): TraderErpInfosData {
    const { erpSystemConfigs } = useErpConfig()
    const { erpInfos } = erpInfosData
    const [enabled, setEnabled] = useState(false)
    const tradeReferenceNumbers = useRecoilValue(tradeReferenceNumbersState)
    const loadErpInfos = useLoadErpInfos(erpInfosData)
    const loadTraderErpInfos = useCallback(() => setEnabled(true), [])

    useEffect(() => {
        if (!enabled || !tradeReferences?.length || !erpSystemConfigs) {
            return
        }

        const loadErpInfoItems = erpSystemConfigs
            .map<LoadErpInfoItem | undefined>((erpSystem) => {
                const [, articleTraderReferenceNumbers] =
                    tradeReferenceNumbers.find(
                        ([key]) => key.supplierArticleNo === article.supplierArticleNo && key.supplierId === article.supplier.id
                    ) ?? []

                if (articleTraderReferenceNumbers === "NOT_FOUND") {
                    return
                }
                const { traderArticleNumber } =
                    articleTraderReferenceNumbers?.find((num) => num.tradeReferenceId === erpSystem.tradeReferenceId) ?? {}

                if (!traderArticleNumber) {
                    return
                }

                return { article: { ...article, traderArticleNo: traderArticleNumber }, erpSystem }
            })
            .filter(notUndefinedOrNull)

        loadErpInfos(loadErpInfoItems)
    }, [tradeReferences, erpSystemConfigs, article, enabled, loadErpInfos, tradeReferenceNumbers])

    const traderErpInfos = useMemo(() => {
        if (!tradeReferences?.length || !erpSystemConfigs?.length) {
            return []
        }

        const result = erpSystemConfigs
            .map<ErpInfoData | undefined>((erpSystem) => {
                const key = ArticleErpInfoFactory.createKeyFromArticle(article, erpSystem.id)
                const traderArticleNumber = tradeReferences.find((x) => x.tradeReferenceId === erpSystem.tradeReferenceId)?.traderArticleNumber
                const articleErpInfo = erpInfos.find((x) => x.key.id === key.id)

                if (!articleErpInfo || !traderArticleNumber) {
                    return
                }

                return {
                    erpSystem,
                    traderArticleNumber,
                    erpInformation: articleErpInfo.state === "success" ? articleErpInfo.response : undefined,
                    isLoading: articleErpInfo.state === "loading",
                }
            })
            .filter(notUndefinedOrNull)

        return result
    }, [tradeReferences, erpSystemConfigs, article, erpInfos])

    return useMemo(
        () => ({
            loadTraderErpInfos,
            traderErpInfos,
            isLoading: traderErpInfos.some((x) => x.isLoading),
        }),
        [loadTraderErpInfos, traderErpInfos]
    )
}
