import { AddCatalogPartListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import { useBasketModuleParameter } from "@tm/context-distribution"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { useEnableCostEstimation } from "../../../../hooks/basketState/useEnableCostEstimation"
import { useHandlePostSendOrderAction } from "../../../../hooks/useHandlePostSendOrderAction"
import { useCreateBasketPartPostMessage } from "../../../../hooks/useCreatePartPostMessage"

export function useAddCatalogPartList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { enableCostEstimation } = useEnableCostEstimation()
    const { getMessageByCatalogPart } = useCreateBasketPartPostMessage()
    const { sendArticlesPostMessage, addToNextBasket } = useBasketModuleParameter()
    const { handlePostSendOrderAction } = useHandlePostSendOrderAction()

    const { mutateAsync: addCatalogPartList } = useMutation(
        (request: { addCatalogPartListRequest: AddCatalogPartListRequest; enableCostEstimation?: boolean }) =>
            Data.addCatalogPartList(request.addCatalogPartListRequest),
        {
            onSuccess: async (response, request) => {
                if (response) {
                    if (request.enableCostEstimation) {
                        await enableCostEstimation(request.addCatalogPartListRequest.workTaskId)
                    }

                    handleBasketUpdateWorkflow(request.addCatalogPartListRequest.workTaskId, response)

                    // Save work task in the DB
                    Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
                }
            },
        }
    )
    const addCatalogParts = useCallback(
        (request: AddCatalogPartListRequest, enableCostEstimation?: boolean) => {
            if (sendArticlesPostMessage) {
                const message = getMessageByCatalogPart(request.catalogParts, request.workTaskId)
                handlePostSendOrderAction(message)
            }
            if (addToNextBasket !== false) {
                return addCatalogPartList({ addCatalogPartListRequest: request, enableCostEstimation })
            }

            return Promise.reject(new Error("No basket defined for NEXT!"))
        },
        [addToNextBasket, sendArticlesPostMessage, getMessageByCatalogPart]
    )

    return addCatalogParts
}
