import { Button, CellContentPosition, Icon, Loader, Table, TableCellData, TableColumnData, TableRowData, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { formatDate, getCurrencyFromUserContext } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { useEffect, useRef, useState } from "react"
import { useGetBookings } from "../../../hooks/useGetBookings"
import { summaryActions, useDrivemotiveStore } from "../../../data/state"
import { Booking } from "../../../data/models"
import NoResultHint from "../../_shared/NoResult"
import { mapBookingStatus } from "../../../utils"

export default function BookingsList() {
    const { translateText, currency } = useLocalization()
    const { userContext } = useUser()
    const currencyString = getCurrencyFromUserContext(userContext)
    const selectedBooking = useDrivemotiveStore((state) => state.summary.selectedBooking)
    const { bookings, isLoading, fetchNextPage } = useGetBookings()
    const boxRef = useRef<HTMLTableElement>(null)

    const [isMoreButtonVisible, setIsMoreButonVisible] = useState(true)

    useEffect(() => {
        if (boxRef.current && boxRef.current.scrollHeight !== boxRef.current.clientHeight) {
            setIsMoreButonVisible(false)
        }
    }, [bookings])

    if (isLoading) {
        return <StyledLoader />
    }

    if (!bookings) {
        return <NoResultHint />
    }

    summaryActions.setBookingsList(bookings)

    const tableColumns: TableColumnData[] = [
        {
            header: translateText(1759).toLocaleUpperCase(),
        },
        {
            header: translateText(13152).toLocaleUpperCase(),
        },
        {
            header: `${translateText(155)}`.toLocaleUpperCase(),
        },
        {
            header: translateText(99).toLocaleUpperCase(),
        },
        {
            header: translateText(1204).toLocaleUpperCase(),
        },
        {
            header: translateText(62).toLocaleUpperCase(),
            alignContent: CellContentPosition.right,
        },
        {
            alignContent: CellContentPosition.right,
        },
    ]

    const getCells = (booking: Booking) => {
        const services = booking.services?.map((service) => {
            return (
                <>
                    {service.services_id.title}
                    <br />
                </>
            )
        })

        const cellData: TableCellData[] = [
            { displayValue: mapBookingStatus(booking.status, translateText)?.label, id: "status" },
            { displayValue: formatDate(new Date(booking.date_created), "dd.MM.yyyy"), id: "date_created" },
            { displayValue: `${booking.first_name} ${booking.last_name}`, id: "name" },
            {
                displayValue:
                    booking.vehicle?.vehicle_type &&
                    `${booking.vehicle?.vehicle_type?.manufacturer} ${booking.vehicle?.vehicle_type?.model} ${booking.vehicle?.vehicle_type?.type}`,
                id: "vehicle",
            },
            { displayValue: services, id: "service" },
            { displayValue: `${currency(booking.price_range_from, currencyString)}`, id: "price" },
            {
                displayValue: (
                    <Button startIcon={<Icon name="details" />} onClick={() => onDetailsClick(booking)}>
                        {translateText(43)}
                    </Button>
                ),
                id: "details",
            },
        ]

        return cellData
    }

    const displayData: TableRowData[] = bookings.map((booking: Booking) => ({
        cells: getCells(booking),
        id: `${booking.id}`,
        customRow: false,
        active: booking.id === selectedBooking?.id,
        leftRowIndicatorColor: mapBookingStatus(booking.status, translateText)?.color,
        rowIndicatorWidth: 7,
        borderOnlyOnTheLeft: true,
    }))

    const onDetailsClick = (booking: Booking) => {
        summaryActions.setSelectedBooking(booking)
        summaryActions.setShowBookingDetails(true)
    }

    const onRowClick = (row: TableRowData) => {
        const selected = bookings.find((booking) => booking.id.toString() === row.id)
        if (selected) {
            summaryActions.setSelectedBooking(selected)
            summaryActions.setShowBookingDetails(true)
        }
    }

    return (
        <>
            <Table
                ref={boxRef}
                columns={tableColumns}
                rows={displayData}
                headerBackground="#efefef"
                fontSize={14}
                headerStyle="default"
                rowCap={1}
                overflowY="auto"
                rowStyle="uniform"
                onRowClick={onRowClick}
                onScrollBottom={fetchNextPage}
            />
            {isMoreButtonVisible && <StyledButton onClick={() => fetchNextPage()}>{translateText(869)}</StyledButton>}
        </>
    )
}

const StyledButton = styled(Button)({
    width: "fit-content",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "0.5em",
})

const StyledLoader = styled(Loader)({
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 1,
})
