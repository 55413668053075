import { PropsWithChildren } from "react"
import { ProductGroupsListParams } from "../../../models"
import { useListParams } from "./useListParams"
import { useListParamsContext, ListParamsContext } from "../../../ContextProvider/ListParams"

export function ProductGroupsListParamsProvider(props: PropsWithChildren<unknown>) {
    const params = useListParams()
    return <ListParamsContext.Provider value={params}>{props.children}</ListParamsContext.Provider>
}

export function useProductGroupsListParamsContext<TContext extends ProductGroupsListParams>(): TContext
export function useProductGroupsListParamsContext<TContext extends ProductGroupsListParams, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function useProductGroupsListParamsContext<TContext extends ProductGroupsListParams, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TReturnType | TContext {
    return useListParamsContext(selector as never)
}
