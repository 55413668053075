import { Stack, LicensePlate, styled } from "@tm/components"
import { LicensePlateComponentInitiator } from "@tm/models"
import { VehicleSelectItem } from "./VehicleSelector"

export type Props = {
    vehicleSelectItem: VehicleSelectItem
}

const OverflowWrapper = styled("span")({
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
})

export default function VehicleSelectorViewCopmonent({ vehicleSelectItem }: Props) {
    const { vehicleDescription, vehiclePlate, shortCountryCode } = vehicleSelectItem
    return (
        <Stack direction="row" spacing={0.5}>
            {vehiclePlate && (
                <LicensePlate
                    countryCode={shortCountryCode}
                    value={vehiclePlate}
                    size="extrasmall"
                    licensePlateComponentInitiator={LicensePlateComponentInitiator.VehicleSelectorView}
                />
            )}
            <OverflowWrapper>{vehicleDescription}</OverflowWrapper>
        </Stack>
    )
}
