import { Article, ArticleAttribute } from "@tm/models"
import { useCallback, useMemo } from "react"
import { useBasketQuantitiesContext, useListParamsContext } from "../../../ContextProvider"
import { useFiltersContext } from "../../../ContextProvider/Filters"
import { useShowArticleDetails } from "../../../hooks"
import { ArticleListActions } from "../../../models"
import { useWholesalerPartBasketQuantitiesContext } from "../WholeSalerBasketQuantities"

export function useArticleListActions(): ArticleListActions {
    const showArticleDetails = useShowArticleDetails()
    const params = useListParamsContext()
    const { toggleSupplier } = useFiltersContext()
    const { updateBasketQuantities } = useBasketQuantitiesContext()
    const { updateBasketQuantities: updateWholesalerBasketQuantities } = useWholesalerPartBasketQuantitiesContext()

    const toggleInlineFilter = useCallback((attribute: ArticleAttribute, article: Article) => {
        params.toggleAttribute(attribute)
    }, [])

    return useMemo<ArticleListActions>(
        () => ({
            showArticleDetails,
            toggleSupplier,
            toggleInlineFilter,
            updateBasketQuantities,
            updateWholesalerBasketQuantities,
        }),
        [showArticleDetails, toggleSupplier, toggleInlineFilter, updateBasketQuantities, updateWholesalerBasketQuantities]
    )
}
