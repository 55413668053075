import { createContext, useContextSelector } from "use-context-selector"
import { ListParams } from "../../models"
import { ensureContextAvailability } from "../../helpers"

export const ListParamsContext = createContext<ListParams | undefined>(undefined)

export function useListParamsContext<TContext extends ListParams>(): TContext
export function useListParamsContext<TContext extends ListParams, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useListParamsContext<TContext extends ListParams, TReturnType>(selector?: (value: TContext) => TReturnType): TContext | TReturnType {
    return useContextSelector(ListParamsContext, (context) => {
        const validatedContext = ensureContextAvailability("ListParamsContext", context)

        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
