import { Button, Icon, List, ListItem, Popover, Switch, buttonClasses, colors, styled, useTheme, Tooltip, Typography, Box } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useCallback, useMemo, useRef, useState } from "react"
import { useQueryClient } from "react-query"
import { ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"
import { QUERY_KEY as ARTICLES_QUERY_KEY } from "../../../hooks/useArticlesBase"
import { usePartsViewOptions } from "../../../hooks/usePartsViewOptions"
import { useListParamsContext } from "../../../ContextProvider"

const OptionsButton = styled(Button)({
    [`&.${buttonClasses.sizeMedium}`]: {
        padding: 7,
        borderColor: colors.grey[400],
    },
})

const OptionsItem = styled(ListItem)({
    padding: "0 0 0 16px",
})

type Option = {
    show: true | "extracted"
    label: string
    checked: boolean
    handler: () => void
    tooltip?: string
}

export function Options() {
    const { translateText } = useLocalization()
    const buttonRef = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)
    const { partsViewSettings, setPartsViewSettings, showPurchasePrice, setShowPurchasePrice, isLoading } = usePartsViewOptions()
    const { userSettingsUpdating } = useUser()
    const listType = useListParamsContext((x) => x.listType)
    const { viewOptions } = useArticleListConfiguration()
    const queryClient = useQueryClient()

    const theme = useTheme()
    const textColor = theme.overwrites?.components?.partListV2?.color

    const handleToggleCompactView = useCallback(() => {
        setOpen(false)
        TmaHelper.GeneralCountEvent.Call(partsViewSettings.compactView ? ECounterType.ArticleListShowDetailed : ECounterType.ArticleListShowCompact)

        setPartsViewSettings({
            ...partsViewSettings,
            compactView: !partsViewSettings.compactView,
        })
    }, [setPartsViewSettings, partsViewSettings])

    const handleToggleShowArticleImages = useCallback(() => {
        setOpen(false)
        TmaHelper.GeneralCountEvent.Call(
            partsViewSettings.showArticleImages ? ECounterType.ArticleListShowImages : ECounterType.ArticleListHideImages
        )

        setPartsViewSettings({
            ...partsViewSettings,
            showArticleImages: !partsViewSettings.showArticleImages,
        })
    }, [partsViewSettings, setPartsViewSettings])

    const handleToggleShowVehicleRecordsFilters = useCallback(() => {
        setOpen(false)
        setPartsViewSettings({
            ...partsViewSettings,
            showVehicleRecordsFilters: !partsViewSettings.showVehicleRecordsFilters,
        })
    }, [partsViewSettings, setPartsViewSettings])

    const handleToggleShowPurchasePrice = useCallback(() => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListShowInvoicePrice, !showPurchasePrice)

        setShowPurchasePrice(!showPurchasePrice)
    }, [setShowPurchasePrice, showPurchasePrice])

    const handleToggleQuantitySuggestionEnabled = useCallback(() => {
        setOpen(false)
        setPartsViewSettings({
            ...partsViewSettings,
            quantitySuggestionEnabled: !partsViewSettings.quantitySuggestionEnabled,
        })
        queryClient.resetQueries(ARTICLES_QUERY_KEY)
    }, [partsViewSettings, setPartsViewSettings, queryClient])

    const list = useMemo<Option[]>(() => {
        const options: Option[] = []

        if (viewOptions.purchasePrice) {
            options.push({
                show: viewOptions.purchasePrice,
                label: translateText(1132),
                checked: !!showPurchasePrice,
                handler: handleToggleShowPurchasePrice,
            })
        }

        if (viewOptions.compact) {
            options.push({
                show: viewOptions.compact,
                label: translateText(3009),
                checked: !!partsViewSettings.compactView,
                handler: handleToggleCompactView,
                tooltip: translateText(1804),
            })
        }

        if (viewOptions.images) {
            options.push({
                show: viewOptions.images,
                label: translateText(795),
                checked: !!partsViewSettings.showArticleImages,
                handler: handleToggleShowArticleImages,
                tooltip: translateText(1805),
            })
        }

        if (viewOptions.vehicleRecords && listType !== "direct" && listType !== "universal") {
            options.push({
                show: viewOptions.vehicleRecords,
                label: translateText(13289),
                checked: !!partsViewSettings.showVehicleRecordsFilters,
                handler: handleToggleShowVehicleRecordsFilters,
                tooltip: translateText(1807),
            })
        }

        if (viewOptions.quantitySuggestion && listType === "vehicle") {
            options.push({
                show: viewOptions.quantitySuggestion,
                label: translateText(13672),
                checked: !!partsViewSettings.quantitySuggestionEnabled,
                handler: handleToggleQuantitySuggestionEnabled,
                tooltip: translateText(13671),
            })
        }

        return options
    }, [
        translateText,
        handleToggleCompactView,
        handleToggleShowArticleImages,
        handleToggleShowPurchasePrice,
        handleToggleShowVehicleRecordsFilters,
        handleToggleQuantitySuggestionEnabled,
        partsViewSettings,
        showPurchasePrice,
        viewOptions,
        listType,
    ])

    const handleSetOpen = () => {
        setOpen(true)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListViewOptions)
    }

    return (
        <>
            <Box display="inline-block">
                <Typography variant="label" fontSize={9} textTransform="uppercase" height={12} display="block">
                    {translateText(361)}
                </Typography>
                <OptionsButton ref={buttonRef} variant="outlined" startIcon={<Icon name="settings" />} onClick={handleSetOpen} />
            </Box>
            {list
                .filter((x) => x.show === "extracted")
                .map(({ label, checked, handler, tooltip }) => (
                    <Tooltip key={label} title={tooltip}>
                        <Switch
                            typographySx={{ color: textColor, fontSize: 9, textTransform: "uppercase" }}
                            disabled={isLoading || userSettingsUpdating}
                            checked={checked}
                            size="small"
                            label={label}
                            onChange={handler}
                            formControlLabelSx={{ flexDirection: "column-reverse", alignItems: "flex-start", marginLeft: 2, marginRight: 0 }}
                        />
                    </Tooltip>
                ))}
            <Popover
                anchorEl={buttonRef.current}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <List>
                    {list
                        .filter((x) => x.show === true)
                        .map(({ label, checked, handler, tooltip }) => (
                            <Tooltip key={label} title={tooltip}>
                                <OptionsItem>
                                    <Switch disabled={isLoading} checked={checked} size="small" label={label} onChange={handler} />
                                </OptionsItem>
                            </Tooltip>
                        ))}
                </List>
            </Popover>
        </>
    )
}
