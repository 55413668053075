import { Component, Suspense } from "react"
import {
    WithUserContextProps,
    withUserContext,
    WithWorkTaskProps,
    withWorkTask,
    WithUserSettingsProps,
    withUserSettings,
    withTheme,
    WithThemeProps,
    useUser,
    useWorkTask,
    useTelesalesCustomerNumber,
} from "@tm/context-distribution"
import { Authority } from "@tm/data"
import { LocalizationProps, withLocalization } from "@tm/localization"
import {
    bindSpecialReactMethods,
    withRouter,
    encodeUniqueId,
    uniqueId,
    RouteComponentProps,
    useDefaultErpSystem,
    useDefaultOrderWarehouse,
    useWorkTaskTruckData,
    useFetchCheckHasMemo,
    isValidWorkTaskId,
} from "@tm/utils"
import {
    RegisteredModels,
    ShowManufacturerDivisionResponse,
    ErpSystemConfig,
    SearchFilters,
    ShowDefaultWarehouseResponse,
    ErpContainer,
    AddCatalogPartListRequest,
    AddOePartListRequest,
    AddWholesalerPartListRequest,
    ChangeItemsResponse,
    AddCustomPartListRequest,
    AddCustomWorkListRequest,
    AddRepairTimeListRequest,
    FastCalculation,
} from "@tm/models"
import { Container } from "@tm/nexus"
import { handlers, PostMessageEvent } from "./handler"
import { getBundleParams } from "../../utils"
import { useBasketParts } from "../../../../basket/src/hooks/basketState/useBasketParts"
import { useBasketWorks } from "../../../../basket/src/hooks/basketState/useBasketWorks"
import { useBasketImports } from "../../../../basket/src/hooks/basketState/useBasketImports"

type ExternalProps = LocalizationProps &
    WithUserContextProps &
    WithUserSettingsProps &
    WithWorkTaskProps &
    RouteComponentProps<RouteProps> &
    WithThemeProps &
    ConfigProps

type RouteProps = {
    workTaskId?: string
}

type ConfigProps = {
    oeAftermarketSearchFilter?: SearchFilters
}

const BLACKLIST = ["source", "data", "type"]

function parseIfStringifiedData<T>(data: T): T | undefined {
    if (typeof data === "string") {
        try {
            return JSON.parse(data)
        } catch (ex) {
            console.warn("Postmessage API tried to parse: ", data, ex)
            return
        }
    }

    return data
}

type Props = ExternalProps & {
    erpSystemConfig: ErpSystemConfig | undefined
    telesalesCustomerNo: string | undefined
    warehouseData: ShowDefaultWarehouseResponse | undefined
    refetchWarehouseData(): void
    workTaskTruckData: ReturnType<typeof useWorkTaskTruckData>["workTaskTruckData"]
    setWorkTaskTruckData: ReturnType<typeof useWorkTaskTruckData>["setWorkTaskTruckData"]
    getActivatableModules: ReturnType<typeof Authority.useFetchGetActivatableModules>
    getCheckHasMemo: ReturnType<typeof useFetchCheckHasMemo>
    hasTelesales: boolean
    addCustomPartList(request: AddCustomPartListRequest, isExternalCall?: boolean): Promise<ChangeItemsResponse | undefined>
    addCatalogPartList(request: AddCatalogPartListRequest): void
    addOePartList(request: AddOePartListRequest): Promise<ChangeItemsResponse | undefined>
    addWholesalerPartList(request: AddWholesalerPartListRequest): void
    addCustomWorkList(request: AddCustomWorkListRequest, isExternalCall?: boolean | undefined): void
    addRepairTimeList(request: AddRepairTimeListRequest): void
    importFastCalculation(importFastCalculationRequest: FastCalculation.ImportFastCalculationRequest): void
}

export class PostMessageControllerComponent extends Component<Props> {
    RepairTimesDivision = Container.getInstance<ShowManufacturerDivisionResponse>(RegisteredModels.RepairTimes_ShowManufacturerDivision)

    Erp: ErpContainer = Container.getInstance(RegisteredModels.ERP)

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
        window.addEventListener("message", this.handleReceiveMessage.bind(this))
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleReceiveMessage.bind(this))
    }

    shouldComponentUpdate() {
        return false
    }

    render() {
        return null
    }

    handleReceiveMessage(event: PostMessageEvent) {
        const data = parseIfStringifiedData(event.data)
        if (!data) {
            return
        }

        const dataKeys = Object.keys(data)
        if (dataKeys.some((x) => BLACKLIST.some((y) => y === x))) {
            return
        }

        const commandKeys = dataKeys.filter((x) => x !== "sourceId")
        if (commandKeys.length === 0) {
            console.warn("API Call: No Command found.")
        }

        handlers.forEach((handle) => handle.bind(this)(data, event.source as Window))
    }

    getCurrentOrNewWorkTaskId(): string {
        const { workTaskId } = this.props.match.params

        if (!workTaskId || !isValidWorkTaskId(workTaskId)) {
            return encodeUniqueId(uniqueId())
        }

        return workTaskId
    }

    checkAndCreateWorktask() {
        const { workTaskId } = this.props.match.params

        if (!workTaskId || !isValidWorkTaskId(workTaskId)) {
            this.props.history.push(`/${workTaskId}`)
        }
    }
}

function HooksWrapper(props: ExternalProps) {
    const { erpSystemConfig } = useDefaultErpSystem()
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { warehouseData, refetchWarehouseData } = useDefaultOrderWarehouse(
        { telesalesCustomerNo, distributorId: erpSystemConfig?.id },
        enableServiceCalls
    )
    const { userContext } = useUser()
    const getActivatableModules = Authority.useFetchGetActivatableModules(userContext)

    const { workTaskTruckData, setWorkTaskTruckData } = useWorkTaskTruckData(props.workTaskId)

    const vehicle = useWorkTask()?.workTask?.vehicle
    const getCheckHasMemo = useFetchCheckHasMemo(
        !!getBundleParams().memoToolEnabled,
        vehicle?.manufacturer,
        vehicle?.tecDocTypeId,
        vehicle?.engineCode
    )

    const { addCustomPartList, addCatalogPartList, addOePartList, addWholesalerPartList } = useBasketParts()
    const { addCustomWorkList, addRepairTimeList } = useBasketWorks()
    const { importFastCalculation } = useBasketImports()

    return (
        <PostMessageControllerComponent
            {...props}
            hasTelesales={!!userContext.hasTelesales}
            erpSystemConfig={erpSystemConfig}
            telesalesCustomerNo={telesalesCustomerNo}
            warehouseData={warehouseData}
            refetchWarehouseData={refetchWarehouseData}
            workTaskTruckData={workTaskTruckData}
            setWorkTaskTruckData={setWorkTaskTruckData}
            getActivatableModules={getActivatableModules}
            getCheckHasMemo={getCheckHasMemo}
            addCatalogPartList={addCatalogPartList}
            addCustomPartList={addCustomPartList}
            addOePartList={addOePartList}
            addWholesalerPartList={addWholesalerPartList}
            addCustomWorkList={addCustomWorkList}
            addRepairTimeList={addRepairTimeList}
            importFastCalculation={importFastCalculation}
        />
    )
}

function Wrapper(props: ExternalProps) {
    return (
        <Suspense fallback={null}>
            <HooksWrapper {...props} />
        </Suspense>
    )
}

export default withLocalization(withUserContext(withUserSettings(withWorkTask(withRouter(withTheme(Wrapper))))))
