import { getInitialState } from "."
import { fastServiceStore } from "../store"

export function resetTester() {
    fastServiceStore.setState(getInitialState(), false, "Reset Tester Slice")
}

export function setSignature(signature: string) {
    fastServiceStore.setState(
        (state) => ({
            tester: { ...state.tester, signature },
        }),
        false,
        "Tester set signature"
    )
}

export function setFirstName(firstName: string) {
    fastServiceStore.setState(
        (state) => ({
            tester: { ...state.tester, firstName },
        }),
        false,
        "Tester set first name"
    )
}

export function setLastName(lastName: string) {
    fastServiceStore.setState(
        (state) => ({
            tester: { ...state.tester, lastName },
        }),
        false,
        "Tester set last name"
    )
}

export function setComment(comment: string) {
    fastServiceStore.setState(
        (state) => ({
            tester: { ...state.tester, comment },
        }),
        false,
        "Tester set comment"
    )
}
