import { Button, Loader, OAuthButton, Stack, styled, Typography } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { Authority } from "@tm/data"
import { useLocalization } from "@tm/localization"
import { ConfigParams, OAuthTokenIds } from "@tm/models"
import { useState } from "react"

const Grid = styled("div")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(3, fit-content(100%))",
    alignItems: "center",
    gap: theme.spacing(1, 2),
}))

type Props = {
    configuration: ConfigParams["oAuthConfiguration"]
}

export default function OAuthTokenSettings({ configuration }: Props) {
    const { translate } = useLocalization()

    return (
        <Stack spacing={1}>
            <Typography variant="body2">{translate(13893)}</Typography>

            <Grid>
                {Object.keys(configuration).map((tokenId) => (
                    <OAuthToken key={tokenId} tokenId={tokenId as OAuthTokenIds} />
                ))}
            </Grid>
        </Stack>
    )
}

type OAuthTokenProps = {
    tokenId: OAuthTokenIds
}

function OAuthToken({ tokenId }: OAuthTokenProps) {
    const { userContext, updateExternalAuthenticationTokens } = useUser()
    const { translate } = useLocalization()

    const [loading, setLoading] = useState(false)

    const hasToken = Object.keys(userContext.externalAuthenticationTokens).some((tokenKey) => tokenKey.includes(tokenId))

    function handleAuthFinished(status: "success" | "error" | "abort") {
        // Also reload tokens on "abort" because sometimes the feedback from the OAuth popup isn't working correctly
        if (status === "success" || status === "abort") {
            updateExternalAuthenticationTokens()
        }
    }

    async function handleDisconnect() {
        setLoading(true)

        try {
            await Authority.deleteOAuthToken({ tokenId })
            await updateExternalAuthenticationTokens(true)
        } finally {
            setLoading(false)
        }
    }

    function getLabel() {
        switch (tokenId) {
            case OAuthTokenIds.BoschOs3:
                return translate(13896)
            default:
                return tokenId
        }
    }

    return (
        <>
            <Typography variant="label" htmlFor={tokenId}>
                {getLabel()}
            </Typography>
            {!hasToken ? (
                <OAuthButton id={tokenId} tokenId={tokenId} onAuthorizationFinished={handleAuthFinished} disabled={loading}>
                    {translate(13895)}
                </OAuthButton>
            ) : (
                <Button id={tokenId} onClick={handleDisconnect} disabled={loading}>
                    {translate(13894)}
                </Button>
            )}
            <Loader size="small" visibility={loading ? undefined : "hidden"} sx={{ flex: "none" }} />
        </>
    )
}
