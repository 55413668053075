import { createContext, useContext } from "react"

export type StepContextType = {
    step: string
}

export const StepContext = createContext<StepContextType | null>(null)

// This only returns the current step, if the work label(path) is needed use useWorkContext hook
export const useStepContext = (): StepContextType => {
    const location = useContext(StepContext)
    if (!location) {
        return { step: "" }
    }

    return location
}
