import { useSelector } from "react-redux"
import { MainState } from "../../main"
import { TyresList } from "../../../../../parts/src"
import { useActions } from "@tm/morpheus"
import { MainActions } from "../../main/business"
import { NextPageLoader } from "../../../../../parts/src/components/ListV2/components/NextPageLoader"
import { Box } from "@tm/components"
import { Scrollbar } from "@tm/controls"
import { getBundleParams } from "../../../utils"

export const TyresListV2 = () => {
    const request = useSelector((s: MainState) => s.list?.request)
    const selectedAvailability = useSelector((s: MainState) => s.list?.selectedFilters.availability)

    const actions = useActions(MainActions, "loadNextTyresList")
    const articles = useSelector((s: MainState) => s.list.articles)
    const { maxAutoRequest } = getBundleParams()

    if (!request) {
        return null
    }

    const handleScroll = (e: React.UIEvent<HTMLElement> | UIEvent) => {
        const el = e.target as HTMLElement
        if ((el.scrollHeight - el.scrollTop) <= (el.clientHeight + 175)) {
            actions.loadNextTyresList()
        }
    }

    const handleAutoNextPage = () => {
        if (articles.autoNextCount < maxAutoRequest && request.pageIndex > 8) {
            actions.loadNextTyresList(true)
        }
        actions.loadNextTyresList()
    }

    return (
        <Scrollbar onScroll={handleScroll} className="scrollbar__container__tyres">
            <Box display="flex" flexDirection="column">
                <TyresList request={request} selectedAvailability={selectedAvailability} handleAutoNextPage={handleAutoNextPage} />
                {articles.loadingNextItems && (
                    <NextPageLoader
                        canLoadNextArticles={true}
                        skeletonArticleCount={1}
                    />
                )}
            </Box>
        </Scrollbar>
    )
}

