import { useLocalization } from "@tm/localization"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useUser } from "@tm/context-distribution"
import { ERIKContentService } from "@tm/data"
import { useCarteligenceShare } from "./useCarteligenceShare"
import { useWhatsAppShare } from "./useWhatsAppShare"
import { useCopy } from "./useCopy"
import { useVeFilesEmailShare } from "./useVeFilesEmailShare"
import { AccessKeyExpireIn } from "../../models"

export enum FileShareOption {
    Whatsapp = 1,
    Email,
    Carteligence,
}

export function useFileShare(file: ERIKContentService.FileMeta, onShareCompleted: (success: boolean) => void) {
    const { translateText } = useLocalization()
    const { userSettings } = useUser() || {}

    const { isLoading: sharedAccessKeyLoading, getSharedAccessKey } = ERIKContentService.useGetSharedAccessKey()
    const [selectedShareOption, setSelectedShareOption] = useState<FileShareOption>()

    const [emails, setEmails] = useState([""])
    const [sendCopy, setSendCopy] = useState(false)
    const [accessKey, setAccessKey] = useState<ERIKContentService.ShareAccessKey | undefined>(undefined)
    const [isError, setIsError] = useState(false)
    const { buttonText: copyButtonText, copyToClipboard } = useCopy()

    const shareCompleted = useCallback(
        (success: boolean) => {
            setIsError(!success)
            onShareCompleted?.(success)
        },
        [onShareCompleted]
    )

    const { sendEmail, isLoading: emailSendLoading } = useVeFilesEmailShare(shareCompleted)
    const { sendToCarteligence } = useCarteligenceShare(shareCompleted)
    const { sendWhatsAppMessage } = useWhatsAppShare(shareCompleted)
    const isLoading = emailSendLoading || sharedAccessKeyLoading

    const generateLink = useCallback(async () => {
        const accessKeyResponse = await getSharedAccessKey([file.id], AccessKeyExpireIn.ThirtyDays).catch(() => setIsError(true))
        if (accessKeyResponse?.directUrl) {
            setAccessKey(accessKeyResponse)
        } else {
            setIsError(true)
        }
    }, [getSharedAccessKey, setAccessKey, file.id, setIsError])

    useEffect(() => {
        generateLink()
    }, [generateLink])

    const buttonText = useMemo(() => {
        if (accessKey?.directUrl) {
            return copyButtonText
        }

        if (isError) {
            return translateText(13297)
        }

        return translateText(13298)
    }, [accessKey, copyButtonText, isError, translateText])

    const errorMessage = useMemo(() => {
        return isError ? translateText(13301) : ""
    }, [isError, translateText])

    function send() {
        if (!accessKey) {
            return
        }

        switch (selectedShareOption) {
            case FileShareOption.Whatsapp:
                const message = `${translateText(13782)}: ${accessKey.directUrl}`
                sendWhatsAppMessage(message)
                break

            case FileShareOption.Email:
                const repairShopEmail = sendCopy ? userSettings?.repairShop?.contactInfo?.email : ""
                sendEmail(emails, accessKey.directUrl, accessKey.validTo.toLocaleDateString(), repairShopEmail ? [repairShopEmail] : undefined)
                break

            case FileShareOption.Carteligence:
                sendToCarteligence(accessKey.directUrl)
                break

            default:
                break
        }
    }

    const sendDisabled = useMemo(() => {
        if (!selectedShareOption || !accessKey) {
            return true
        }

        if (selectedShareOption === FileShareOption.Email && emails.length === 0) {
            return true
        }

        return false
    }, [emails, selectedShareOption, accessKey])

    const handleCopyLink = useCallback(() => {
        if (accessKey) {
            copyToClipboard(accessKey.directUrl)
        }
    }, [accessKey, copyToClipboard])

    return {
        fileUrl: accessKey?.directUrl,
        isLoading,
        errorMessage,
        copyLink: handleCopyLink,
        generateLink,
        buttonText,
        selectedShareOption,
        setSelectedShareOption,
        emails,
        setEmails,
        setSendCopy,
        send,
        sendDisabled,
    }
}
