import { useCallback, useEffect, useRef, useState } from "react"

import { Box, Card, Tooltip, Typography, styled } from "@tm/components"
import { useCountryCodeToLicensePlate, useUser, useWorkTask } from "@tm/context-distribution"
import { Button, Icon, LicensePlateField, SuggestionFieldButtonGroup, Table, Text, Widget, WidgetProps, WidgetSizes } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Customer, CustomerShortInfo, ECounterType, IMicros, RegistrationNoType, TitleType } from "@tm/models"
import { connectComponent, useMicro } from "@tm/morpheus"
import {
    ModulePropsMultiWidget,
    RouteComponentProps,
    TmaHelper,
    classes,
    createQueryString,
    encodeUniqueId,
    formatDate,
    renderRoute,
    uniqueId,
    useHeighAdjustment,
    useMultiWidgetState,
    withRouter,
} from "@tm/utils"

import { getBundleParams } from "../../utils"
import { Actions, IActions, WidgetState } from "./business"
import Memo from "./components/Memo"
import Editor from "./components/editor"

const EditorCard = styled(Card)<{ tinyWidget: boolean }>(({ theme, tinyWidget }) => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: tinyWidget ? theme.spacing(1) : theme.spacing(2),
}))

type WidgetComponentProps = RouteComponentProps<{ workTaskId: string }> &
    ConfigurationProps & {
        state: WidgetState
        actions: IActions
    }

type ConfigurationProps = {
    moduleProps?: ModulePropsMultiWidget
    height?: number
    size?: WidgetSizes
    listRoute: string
    detailsRoute: string
    hideCover?: boolean
    hideDiscount?: boolean
    showComponentKey?: string
    className?: string
    collapsible?: boolean
    tinyWidget?: boolean
    searchButtonIconOnly?: boolean
    hideFooterButtons?: boolean
    alternativeView?: boolean // the widget is shown differently in toolkits
    autoToggleEddit?: boolean
    fullHeight?: boolean
    hidePlaceholders?: boolean
    heightObservationName?: string
}

const TooltipPlaceholder = styled(Box)(() => ({
    whiteSpace: "inherit",
}))

const ColumnItem = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
        wordBreak: "break-all",
    },
}))

const SubtitleWithOpacity = styled("div")(() => ({
    opacity: 0.54,
}))

function WidgetComponent(props: WidgetComponentProps) {
    const { translateText, translate } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const { workTask, attachToWorkTask } = useWorkTask() ?? {}
    const { userContext } = useUser() ?? {}
    const [editing, setEditing] = useState<boolean>(false)
    const [active, setActive] = useState<boolean>(!!props.hideCover)
    const [searchQuery, setSearchQuery] = useState<string>("")
    const [navIdentifier, setNavIdentifier] = useState<string>("phone")
    const searchFieldRef = useRef<SuggestionFieldButtonGroup<CustomerShortInfo>>(null)
    const { nextLight } = getBundleParams()
    const [selectedMultiWidgetTab] = useMultiWidgetState(props.moduleProps)
    const { plateCode } = useCountryCodeToLicensePlate(workTask?.vehicle?.countryCode)

    const {
        showComponentKey,
        className,
        height,
        size,
        collapsible,
        tinyWidget,
        match: { params },
        history,
        hideDiscount,
        hideFooterButtons,
        alternativeView,
        state,
        listRoute,
        detailsRoute,
        fullHeight,
        hidePlaceholders,
    } = props
    const { requestedSuggestions } = state

    const [customer, setCustomer] = useState<Customer | undefined>(workTask?.customer)

    useEffect(() => {
        setCustomer(workTask?.customer)
    }, [workTask?.customer])

    function getListUrl() {
        return renderRoute(listRoute, { ...params, workTaskId: params.workTaskId || encodeUniqueId(uniqueId()) })
    }

    function getDetailsUrl(id?: string) {
        return renderRoute(detailsRoute, {
            ...params,
            workTaskId: params.workTaskId || encodeUniqueId(uniqueId()),
            customerId: id ? encodeUniqueId(id) : undefined,
        })
    }

    function handleOutsideClick() {
        setEditing(false)
    }

    function handleWidgetClick() {
        if (!active) {
            setActive(true)
            setTimeout(() => {
                searchFieldRef.current?.focus()
            }, 25)
        }
    }

    function handleToggleCustomerEditor(nextIdentifier?: string) {
        setNavIdentifier(nextIdentifier || (alternativeView ? "firstName" : "phone"))
        setEditing(!editing)
    }

    function handleCustomerUpdate(changedCustomer: Customer, fromSelect?: boolean) {
        const customerToSave = {
            ...changedCustomer,
            id: changedCustomer.id || uniqueId(),
        }

        if (!fromSelect) {
            handleToggleCustomerEditor()
        }

        setCustomer(customerToSave)
        attachToWorkTask?.({ customer: customerToSave })
    }

    function setWorktaskCustomerFromWidgetInModule(changedCustomer: Customer) {
        const customerToSave = {
            ...changedCustomer,
            id: changedCustomer.id || uniqueId(),
        }

        setCustomer(customerToSave)
        attachToWorkTask?.({ customer: customerToSave.id })
    }

    function handleOpenCustomerDetails(customerInfo: CustomerShortInfo) {
        const detailsUrl = getDetailsUrl(customerInfo.id)
        history.push(detailsUrl)
    }

    function handleAttachCustomer(customerInfo: CustomerShortInfo) {
        if (alternativeView) {
            setWorktaskCustomerFromWidgetInModule(customerInfo)
            return
        }

        // Remove this hack after we have an actual worktask manager :|
        // When pressing enter, the redirect to a new worktask used to only (semi-) work, because handleSearch was called too (because of changeConfirm)
        // Otherwise the worktask manager wouldn't be rendered on the page and no redirect would happen to the actually new worktask
        // I have changed the control, so that changeConfirm is only called, if there is more than one Suggestion.
        // Now a new worktask has to be opened manually
        const { vehicleCount, id, vehicleId } = customerInfo

        if (vehicleCount && vehicleCount > 1) {
            return handleOpenCustomerDetails(customerInfo)
        }

        const attachAction =
            workTask?.vehicle && (!workTask.vehicle.customerId || workTask.vehicle.customerId === id)
                ? () => attachToWorkTask?.({ customer: id }) // When the worktask already have a vehicle without customer or from exactly this customer, don't attach another one
                : () => {
                      TmaHelper.VehicleSelection.Apply(RegistrationNoType.VehicleBase)
                      attachToWorkTask?.({ customer: id, vehicle: vehicleId })
                  }

        if (!params.workTaskId) {
            history.push(`${encodeUniqueId(uniqueId())}`)
            setTimeout(attachAction, 500)
        } else {
            attachAction()
        }
    }

    function handleSearch(query?: string) {
        if (requestedSuggestions.length === 1) {
            return handleAttachCustomer(requestedSuggestions[0])
        }

        if (alternativeView) {
            return
        }
        TmaHelper.GeneralCountEvent.Call(ECounterType.CustomerSearch)
        const customerSearchUrl = renderRoute(getListUrl(), params) + createQueryString({ query })
        history.push(customerSearchUrl)
    }

    function handleSearchButtonClick() {
        handleSearch(searchQuery)
    }

    function handleQueryOnChange(query: string) {
        setSearchQuery(query)
    }

    function handleNewCustomer() {
        setCustomer({
            id: "",
            titleType: TitleType.Undefined,
        })
        props.autoToggleEddit && handleToggleCustomerEditor()
    }

    function requestSuggestionsByQuery(query: string) {
        props.actions.loadSuggestions(query)
    }

    function renderVehicleDescription(data: CustomerShortInfo) {
        return data.model && [data.manufacturer, data.modelSeries, data.model].join(" ")
    }

    function renderAutosuggestColumns() {
        return [
            <Table.Column key="no" className="no" renderItemContent={(data) => <Table.Cell>{data.customerNo}</Table.Cell>}>
                {translate(156)}
            </Table.Column>,
            <Table.Column
                key="name"
                className="name"
                renderItemContent={(data) => (
                    <Table.Cell>
                        {data.firstName || ""} {data.lastName || ""}
                    </Table.Cell>
                )}
            >
                {translate(155)}
            </Table.Column>,
            <Table.Column
                key="addr"
                className="addr"
                renderItemContent={(data) => (
                    <Table.Cell>
                        {data.zip || ""} {data.city || ""}
                    </Table.Cell>
                )}
            >
                {translate(112)} / {translate(113)}
            </Table.Column>,
            <Table.Column
                key="vehicle"
                className="vehicle"
                renderItemContent={(data) => (
                    <Table.Cell>
                        {!!data.plateId && (
                            <div className="plate">
                                <LicensePlateField readonly size="xs" shortCountryCode={plateCode} value={data.plateId} />
                            </div>
                        )}
                        {renderVehicleDescription(data)}
                    </Table.Cell>
                )}
            >
                {translate(183)}
            </Table.Column>,
            <Table.Column
                key="action"
                className="action"
                renderItemContent={(data) => (
                    <Table.Cell>
                        <Button
                            icon="search"
                            size="xs"
                            className="applyCustomerAndVehicle"
                            layout={["ghost"]}
                            onClick={(e) => {
                                e.stopPropagation()
                                return handleOpenCustomerDetails(data)
                            }}
                        />
                    </Table.Cell>
                )}
            />,
        ]
    }

    function renderSearchContent() {
        return (
            <SuggestionFieldButtonGroup<CustomerShortInfo>
                value={searchQuery}
                onChange={handleQueryOnChange}
                requestSuggestions={requestSuggestionsByQuery}
                suggestions={props.state.requestedSuggestions}
                onChangeConfirm={handleSearch}
                onSuggestionSelect={handleAttachCustomer}
                placeholder={translateText(184)}
                tooltip={translateText(572)}
                className="customer"
                renderTableColumns={renderAutosuggestColumns}
                buttonTitle={!props.searchButtonIconOnly ? translateText(135) : undefined}
                maxHeight={500}
                disabled={userContext?.parameter.catalogLight || nextLight}
                ref={searchFieldRef}
                handleSearchButtonClick={handleSearchButtonClick}
                suggestDelay={500}
            />
        )
    }

    function renderCustomerContent() {
        if (!customer) {
            return null
        }

        const customerName = `${customer.academicTitle || ""} ${customer.firstName || ""} ${customer.lastName || ""}`
        const phone = customer.phone || "_____/________"
        const mobile = customer.mobile || "_____/________"
        const email = customer.email || "______________"
        const companyName = customer.companyName || "______________"
        const street = customer.street || ""
        const city = customer.city || ""
        const zip = customer.zip || ""
        const sparePartsDiscount = customer.sparePartsDiscount ? `${customer.sparePartsDiscount}%` : hidePlaceholders ? "" : "__%"
        const repairTimesDiscount = customer.repairTimesDiscount ? `${customer.repairTimesDiscount}%` : hidePlaceholders ? "" : "__%"
        const modified =
            alternativeView && !customer.modified ? "" : `${translateText(1037)}: ${customer.modified ? formatDate(customer.modified, "d") : "-"}`

        const fullAddressRow = `${street && `${street}, `}${zip} ${city}`

        return (
            <div className="card__content">
                <div className={`content__head ${alternativeView ? "content__head--height" : ""}`}>
                    <SubtitleWithOpacity className="subtitle subtitle--s">{translate(customer.id ? 490 : 3)}</SubtitleWithOpacity>
                    <Box display="flex">
                        <Typography variant="h2" mr={0.5}>
                            {customer.customerNo}
                        </Typography>
                        <Typography variant="h2" maxLines={1} sx={{ fontWeight: "bold" }}>
                            {customerName}
                        </Typography>
                    </Box>
                    {!alternativeView && (
                        <>
                            <div className="customer__address">
                                <Typography variant="body3" maxLines={1} display="block">
                                    {fullAddressRow}
                                </Typography>
                            </div>
                        </>
                    )}
                    <SubtitleWithOpacity className="modified subtitle subtitle--xs">{modified}</SubtitleWithOpacity>
                </div>
                <Box pt={2} className="content__body">
                    <Box className="details">
                        {alternativeView && (
                            <>
                                <div className="detail details--phone">
                                    <Tooltip title={translateText(111)} placement="top-start">
                                        <div className="subtitle subtitle--xs">{translate(111)}</div>
                                    </Tooltip>
                                    <Text size="s" className="value">
                                        <div onClick={() => handleToggleCustomerEditor("street")}>{street}</div>
                                    </Text>
                                </div>
                                <div className="detail details--phone">
                                    <Tooltip title={translateText(112)} placement="top-start">
                                        <div className="subtitle subtitle--xs">{translate(112)}</div>
                                    </Tooltip>
                                    <Text size="s" className="value">
                                        <div onClick={() => handleToggleCustomerEditor("zip")}>{zip}</div>
                                    </Text>
                                </div>
                                <div className="detail details--phone">
                                    <Tooltip title={translateText(113)} placement="top-start">
                                        <div className="subtitle subtitle--xs">{translate(113)}</div>
                                    </Tooltip>
                                    <Text size="s" className="value">
                                        <div onClick={() => handleToggleCustomerEditor("city")}>{city}</div>
                                    </Text>
                                </div>
                            </>
                        )}

                        {!alternativeView && (
                            <>
                                <ColumnItem className="detail details--phone">
                                    <Tooltip title={translateText(110)} placement="top-start">
                                        <TooltipPlaceholder className="subtitle subtitle--xs">{translate(110)}</TooltipPlaceholder>
                                    </Tooltip>
                                    <Text size="s" className="value">
                                        <div onClick={() => handleToggleCustomerEditor("phone")}>{phone}</div>
                                    </Text>
                                </ColumnItem>
                                <ColumnItem className="detail details--email">
                                    <Tooltip title={translateText(109)} placement="top-start">
                                        <TooltipPlaceholder className="subtitle subtitle--xs">{translate(109)}</TooltipPlaceholder>
                                    </Tooltip>
                                    <Text size="s" className="value">
                                        <div onClick={() => handleToggleCustomerEditor("email")}>{email}</div>
                                    </Text>
                                </ColumnItem>
                                {!tinyWidget && (
                                    <ColumnItem className="detail">
                                        <Tooltip title={translateText(108)} placement="top-start">
                                            <TooltipPlaceholder className="subtitle subtitle--xs">{translate(108)}</TooltipPlaceholder>
                                        </Tooltip>
                                        <Text size="s" className="value">
                                            <div onClick={() => handleToggleCustomerEditor("companyName")}>{companyName}</div>
                                        </Text>
                                    </ColumnItem>
                                )}
                            </>
                        )}
                    </Box>
                    {!tinyWidget && (
                        <Box mt={1} className="details">
                            {!alternativeView && (
                                <ColumnItem className="detail details--mobile">
                                    <Tooltip title={translateText(1308)} placement="top-start">
                                        <TooltipPlaceholder className="subtitle subtitle--xs">{translate(1308)}</TooltipPlaceholder>
                                    </Tooltip>
                                    <Text size="s" className="value">
                                        <div onClick={() => handleToggleCustomerEditor("mobile")}>{mobile}</div>
                                    </Text>
                                </ColumnItem>
                            )}
                            {!hideDiscount && (
                                <>
                                    <ColumnItem className="detail">
                                        <Tooltip title={translateText(554)} placement="top-start">
                                            <TooltipPlaceholder className="subtitle subtitle--xs">{translate(554)}</TooltipPlaceholder>
                                        </Tooltip>
                                        <Text size="s" className="value">
                                            <div onClick={() => handleToggleCustomerEditor("sparePartsDiscount")}>{sparePartsDiscount}</div>
                                        </Text>
                                    </ColumnItem>

                                    <ColumnItem className="detail">
                                        <Tooltip title={translateText(555)} placement="top-start">
                                            <TooltipPlaceholder className="subtitle subtitle--xs">{translate(555)}</TooltipPlaceholder>
                                        </Tooltip>
                                        <Text size="s" className="value">
                                            <div onClick={() => handleToggleCustomerEditor("repairTimesDiscount")}>{repairTimesDiscount}</div>
                                        </Text>
                                    </ColumnItem>
                                </>
                            )}
                            {alternativeView && <Box flex={1} px={1} />}
                        </Box>
                    )}
                </Box>
            </div>
        )
    }

    function renderFooter() {
        if (customer) {
            return (
                <>
                    <div className="footer-wrapper">
                        {!hideFooterButtons && (
                            <>
                                <Button id="crm__widget__details-button" linkTo={getDetailsUrl(customer.id)}>
                                    {translateText(116)}
                                </Button>
                                {!nextLight && !tinyWidget && (
                                    <>
                                        <Button id="crm__widget__list-button" linkTo={getListUrl()} disabled={nextLight}>
                                            {translate(347)}
                                        </Button>
                                        <Button id="crm__widget__new-button" onClick={handleToggleCustomerEditor}>
                                            {translateText(122)}
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                        <Memo customer={customer} />
                    </div>
                    {editing && (
                        <EditorCard tinyWidget={tinyWidget ?? false}>
                            <Editor
                                customer={customer}
                                nav={navIdentifier}
                                onChange={handleCustomerUpdate}
                                onAbort={handleToggleCustomerEditor}
                                onOutsideClick={handleOutsideClick}
                                hideDiscount={hideDiscount}
                                alternativeView={alternativeView}
                                tinyWidget={tinyWidget}
                            />
                        </EditorCard>
                    )}
                </>
            )
        }

        if (alternativeView) {
            return (
                <Button id="crm__widget__new-button" onClick={handleNewCustomer} icon="plus" disabled={nextLight} layout={["iconRight"]}>
                    {translate(115)}
                </Button>
            )
        }

        return (
            <>
                <Button
                    id="crm__widget__list-button"
                    disabled={nextLight}
                    linkTo={getListUrl()}
                    onClick={() => {
                        TmaHelper.GeneralCountEvent.Call(ECounterType.CustomerList)
                    }}
                >
                    {translate(175)}
                </Button>
                <Button
                    id="crm__widget__new-button"
                    linkTo={getDetailsUrl()}
                    icon="plus"
                    disabled={nextLight}
                    layout={["iconRight"]}
                    onClick={() => {
                        TmaHelper.GeneralCountEvent.Call(ECounterType.AddCustomer)
                    }}
                >
                    {translate(115)}
                </Button>
            </>
        )
    }

    const handleRef = useCallback((ref: HTMLDivElement | null) => {
        if (ref && props.heightObservationName) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useHeighAdjustment().allowHeightObservation(ref, props.heightObservationName)
        }
    }, [])

    if (showComponentKey && selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    const id = "crm__widget"

    if (props.showComponentKey) {
        return (
            <div id={id} className={className} style={{ height: `${height}em` }}>
                <div className="widget__inner">
                    <div className="widget__content">{customer ? renderCustomerContent() : renderSearchContent()}</div>
                    <div className="widget__footer">{renderFooter()}</div>
                </div>
            </div>
        )
    }

    const propsWidget: WidgetProps = {
        size: size || "4x2",
        id,
        height,
        collapsible,
        tinyWidget,
        iconName: "user",
        title: translate(107),
        footer: renderFooter(),
        active: active || alternativeView || !!customer,
        onClick: handleWidgetClick,
        fullHeight,
        hidePlaceholders,
    }

    const widgetClassName = classes("tk-crm widget", userContext?.parameter.catalogLight && "is-disabled", className, editing && "is-editing")

    return (
        <Widget
            {...propsWidget}
            forwardedRef={handleRef}
            header={
                <Widget.Header>
                    <Widget.Header.Title>
                        <Icon name="user" size="s" className="widget__icon" />
                        <div className="text">{translate(107)}</div>
                        {customer && alternativeView && (
                            <Button
                                onClick={handleToggleCustomerEditor}
                                title={translateText(13278)}
                                className="is-clickable btn--top-right"
                                layout={["ghost"]}
                                size="xl"
                            >
                                <Icon name="pencil" />
                            </Button>
                        )}
                    </Widget.Header.Title>
                    {!alternativeView && renderMicro("notifications", "crm-button")}
                </Widget.Header>
            }
            className={widgetClassName}
        >
            {customer ? renderCustomerContent() : renderSearchContent()}
        </Widget>
    )
}

export default connectComponent(Actions, withRouter(WidgetComponent))
