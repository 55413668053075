import { VeFiles } from "@tm/data"
import { notUndefinedOrNull } from "@tm/utils"
import { useMemo } from "react"
import { QueryFunctionContext, QueryKey, useInfiniteQuery } from "react-query"
import { QueryKeys } from "."

export function useFileList(vehicleId?: string, search?: string, sortOrder?: VeFiles.FileSortOrder) {
    const pageSize = 20
    const request: VeFiles.GetFilesRequest = {
        take: pageSize,
        search,
        vehicleId: vehicleId || "",
        sortOrder: sortOrder ? [sortOrder] : undefined,
    }
    const { data, isLoading, fetchNextPage } = useInfiniteQuery(
        [QueryKeys.GetFileList, request],
        ({ pageParam }: QueryFunctionContext<QueryKey, VeFiles.GetFilesRequest>) => {
            return VeFiles.getFiles(pageParam ?? request)
        },
        {
            enabled: !!vehicleId,
            getNextPageParam: (lastPage, pages): VeFiles.GetFilesRequest | undefined => {
                if (!lastPage) {
                    return
                }

                if (pages.length * pageSize >= lastPage.total) {
                    return
                }

                return {
                    ...request,
                    skip: pages.length * pageSize,
                }
            },
            cacheTime: 0,
            staleTime: 10 * 60 * 1000,
            refetchInterval: 10 * 60 * 1000,
            refetchOnWindowFocus: true,
        }
    )

    const fileList = useMemo(() => data?.pages.flatMap((page) => page?.files).filter(notUndefinedOrNull), [data?.pages])

    return { fileList, isLoading, fetchNextPage }
}
