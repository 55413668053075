import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { SelectedItems, ActiveButtons } from "../../models"

export type MaintenanceReviewSlice = {
    maintenanceReview: {
        items: Record<string, Record<string, SelectedItems>>
        activeFilterButton: ActiveButtons
        selectedWorks: Map<string, Record<string, SelectedItems>>
        itemsForPrint: Record<string, Record<string, SelectedItems>>
    }
}

export function getInitialState(): MaintenanceReviewSlice {
    return {
        maintenanceReview: {
            items: {},
            activeFilterButton: "none",
            selectedWorks: new Map([]),
            itemsForPrint: {},
        },
    }
}

export const createMaintenanceReviewSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], MaintenanceReviewSlice> = (set) => ({
    ...getInitialState(),
    resetMaintenanceReview: () => set(getInitialState(), false, "Reset Maintenance Review Slice"),
})
