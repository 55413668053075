import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { Button, Stack, Checkbox, Loader, Icon, ConfirmationDialog } from "@tm/components"
import { ConfirmationDialogKeys } from "@tm/models"
import { Compilation } from "../../../../../data"
import { useDeleteCompilations } from "../../../../../data/hooks/useCompilations"

type Props = {
    compilation: Compilation
    isEditing: boolean
    isSelected: boolean
    onShowHideEditMode(compilation: Compilation): void
    onUpdateCompilation(compilation: Compilation): void
    onOpenCompilationDetails(id: string): void
    onSelectCompilation(id: string): void
}

export default function CompilationActions(props: Props) {
    const { compilation, isEditing, isSelected, onOpenCompilationDetails, onSelectCompilation, onShowHideEditMode, onUpdateCompilation } = props
    const { translateText } = useLocalization()
    const { deleteCompilations } = useDeleteCompilations()
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

    return (
        <Stack direction="row" justifyContent="flex-end" gap={1}>
            {isEditing ? (
                <>
                    <Button title={translateText(70)} onClick={() => onShowHideEditMode(compilation)} startIcon={<Icon name="close" />} />
                    <Button
                        title={translateText(9)}
                        onClick={() => onUpdateCompilation(compilation)}
                        startIcon={<Icon name="check" />}
                        color="success"
                    />
                </>
            ) : (
                <>
                    <Button onClick={() => onOpenCompilationDetails(compilation.id)} size="small">
                        {translateText(43)}
                    </Button>
                    <Button
                        title={translateText(122)}
                        onClick={() => onShowHideEditMode(compilation)}
                        startIcon={<Icon name="edit_change" />}
                        variant="text"
                        disabled={!compilation.isOwn}
                    />
                    {showDeleteDialog ? (
                        <Loader size="small" />
                    ) : (
                        <Button
                            title={translateText(624)}
                            onClick={() => setShowDeleteDialog(true)}
                            startIcon={<Icon name={!showDeleteDialog ? "delete" : undefined} />}
                            variant="text"
                            disabled={!compilation.isOwn}
                        />
                    )}
                    <ConfirmationDialog
                        open={showDeleteDialog}
                        confirmationButtonText={translateText(585)}
                        cancelButtonText={translateText(584)}
                        onConfirm={() => deleteCompilations([compilation.id])}
                        onCancel={() => setShowDeleteDialog(false)}
                        confirmationKey={ConfirmationDialogKeys.DeleteCompilations}
                    >
                        {translateText(1313)}
                    </ConfirmationDialog>
                    <Checkbox disabled={!compilation.isOwn} checked={isSelected} onChange={() => onSelectCompilation(compilation.id)} />
                </>
            )}
        </Stack>
    )
}
