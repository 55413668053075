import { useUser, useWorkTask } from "@tm/context-distribution"
import {
    AddRepairTimeListRequest,
    IncludedWorkRequest,
    PostSendOrderAction,
    PostSendOrderActionTargetType,
    PostSendOrderActionType,
    RepairTime,
} from "@tm/models"
import { useCallback } from "react"
import { BasketRepairTimeIncludes, BasketRepairTimePostMessage } from "../models"

const SOURCEID = "NEXT"

export function useCreateBasketRepairTimesPostMessage() {
    const { userContext } = useUser()
    const { ExternalSessionId: externalSessionId } = userContext.externalAuthenticationTokens

    const { workTask } = useWorkTask() ?? {}
    const { vehicle } = workTask ?? {}

    const mapIncludeWorks = useCallback((includedRequest?: IncludedWorkRequest[]) => {
        if (!includedRequest) {
            return []
        }
        const included: BasketRepairTimeIncludes[] = includedRequest.map((v) => ({
            workId: v.providerWorkId,
            description: v.description,
            time: v.time,
        }))
        return included
    }, [])

    const mapRepairTimes = useCallback(
        (repairTimes: RepairTime[], providerId: number) => {
            const firstTime = repairTimes.first()

            const repairTimesPostMessage: BasketRepairTimePostMessage = {
                workId: firstTime?.providerWorkId || "-1",
                displayNumber: firstTime?.displayNumber || "",
                oeReferenceNumber: firstTime?.oeReferenceNumber,
                description: firstTime?.description || "",
                note: firstTime?.note,
                time: firstTime?.time || 0,
                isMaintenanceWork: false,
                providerId,
                workCategoryId: firstTime?.categoryOfWork || 0,
                includes: mapIncludeWorks(firstTime?.includedWorks),
                vehicle: {
                    manufacturerId: vehicle?.tecDocManufacturerId,
                    modelId: vehicle?.tecDocModelId,
                    typeId: vehicle?.tecDocTypeId,
                },
            }
            return repairTimesPostMessage
        },
        [mapIncludeWorks, vehicle]
    )

    const getCreateSendRepairTimesPostMessage = useCallback(
        (repairTimesRequest: AddRepairTimeListRequest): PostSendOrderAction[] => {
            return [
                {
                    action: PostSendOrderActionType.PostMessage,
                    target: PostSendOrderActionTargetType.ParentFrame,
                    value: JSON.stringify({
                        sourceId: SOURCEID,
                        externalSessionId,
                        sendRepairTimes: {
                            workTaskId: repairTimesRequest.workTaskId,
                            repairTimeDivision: repairTimesRequest.repairTimeDivision,
                            repairTimes: mapRepairTimes(repairTimesRequest.repairTimes, repairTimesRequest.provider),
                        },
                    }),
                },
            ]
        },
        [externalSessionId, mapRepairTimes]
    )

    const getMessageForRepairTimes = useCallback(
        (repairTimesRequest: AddRepairTimeListRequest) => {
            return getCreateSendRepairTimesPostMessage(repairTimesRequest)
        },
        [getCreateSendRepairTimesPostMessage]
    )

    return { getMessageForRepairTimes }
}
