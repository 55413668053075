import { useCallback, useMemo } from "react"

import { ArticleInfoType } from "@tm/models"
import { useWorkTaskBasketData } from "../../data/hooks/workTaskBasket/queries/useWorkTaskBasketData"
import { useCostEstimationVisible } from "./useCostEstimationVisible"

export type BasketInfos = {
    quantityValue?: number
    partIds?: string[]
}
// This hooks returns the quantites off all parts in the basket not in the cost estimation. The order group is not taken into considaration.
export function useBasketQuantities(workTaskId: string) {
    const { costEstimationVisible } = useCostEstimationVisible(workTaskId)
    const { workTaskBasket } = useWorkTaskBasketData(workTaskId, costEstimationVisible ?? false, true)

    const basketParts = useMemo(() => {
        return workTaskBasket?.parts?.filter((x) => workTaskBasket.orderGroups?.some((orderGroup) => orderGroup.partIds.includes(x.id))) ?? []
    }, [workTaskBasket?.parts, workTaskBasket?.orderGroups])

    const getCatalogPartQuantity = useCallback(
        (articleNumber: string, supplierId: number, productGroupId: number): BasketInfos => {
            let quantityValue = 0
            const partIds: string[] = []
            basketParts
                .filter((part) => part.articleInformation.articleInfoType === ArticleInfoType.TecdocArticle)
                .forEach((part) => {
                    if (
                        part.articleInformation.supplierId === supplierId &&
                        part.articleInformation.articleNumber === articleNumber &&
                        part.articleInformation.productGroupId === productGroupId
                    ) {
                        quantityValue += part.quantity.value
                        partIds.push(part.id)
                    }
                })
            return { quantityValue, partIds }
        },
        [basketParts]
    )

    const getWholesalerPartQuantity = useCallback(
        (wholesalerArticleNumber: string): BasketInfos => {
            let quantityValue = 0
            const partIds: string[] = []
            basketParts
                .filter((part) => part.articleInformation.articleInfoType === ArticleInfoType.WholesalerArticle)
                .forEach((part) => {
                    if (part.articleInformation.wholesalerArticleNumber === wholesalerArticleNumber) {
                        quantityValue += part.quantity.value
                        partIds.push(part.id)
                    }
                })

            return { quantityValue, partIds }
        },
        [basketParts]
    )

    const getOePartQuantity = useCallback(
        (oeArticleNumber: string, vehicleManufacturerId?: number, productGroupId?: number, wholesalerArticleNumber?: string): BasketInfos => {
            let quantityValue = 0
            const partIds: string[] = []
            basketParts
                .filter((part) => part.articleInformation.articleInfoType === ArticleInfoType.OeArticle)
                .forEach((part) => {
                    if (
                        part.articleInformation.articleNumber === oeArticleNumber &&
                        part.articleInformation.productGroupId === productGroupId &&
                        part.articleInformation.supplierId === vehicleManufacturerId &&
                        part.articleInformation.wholesalerArticleNumber === wholesalerArticleNumber
                    ) {
                        quantityValue += part.quantity.value
                        partIds.push(part.id)
                    }
                })

            return { quantityValue, partIds }
        },
        [basketParts]
    )

    const getExternalPartQuantity = useCallback(
        (externaArticleNumber: string, productGroupName?: string, description?: string): BasketInfos => {
            let quantityValue = 0
            const partIds: string[] = []
            basketParts
                .filter((part) => part.articleInformation.articleInfoType === ArticleInfoType.ExternalArticle)
                .forEach((part) => {
                    if (
                        part.articleInformation.articleNumber === externaArticleNumber &&
                        part.articleInformation.productGroupName === productGroupName &&
                        part.articleInformation.description === description
                    ) {
                        quantityValue += part.quantity.value
                        partIds.push(part.id)
                    }
                })

            return { quantityValue, partIds }
        },
        [basketParts]
    )

    return { getCatalogPartQuantity, getWholesalerPartQuantity, getOePartQuantity, getExternalPartQuantity }
}
