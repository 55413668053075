import { useState, useRef, useEffect, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import {
    renderRoute,
    encodeUniqueId,
    uniqueId,
    useExternalCatalogUrl,
    useMultiWidgetState,
    ModulePropsMultiWidget,
    mapVoucherTypeForUrl,
    TmaHelper,
} from "@tm/utils"
import { WidgetSizes, Widget as WidgetControl, Dialog } from "@tm/controls"
import { ECounterType, VoucherType } from "@tm/models"
import { useWorkTask, useTelesalesCustomerNumber, useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { Box, Button, Divider, Stack } from "@tm/components"
import { useHistory, useParams } from "react-router"
import { useRecoilState } from "recoil"
import { useVoucherList } from "../../data/hooks/useVoucherList"
import { getBundleParams } from "../../utils"
import VoucherTypeSelector, { VoucherTypeSelectItem } from "../_shared/VoucherTypeSelector"
import VehicleSelector from "../_shared/VehicleSelector"
import { NoResultHint } from "../_shared/NoResultHint"
import { LoaderSmall, TypographyTitle } from "../_shared/StyledComponents"
import { VoucherRouteParams } from "../../business"
import { useDefaultVoucherType } from "../../hooks/useDefaultVoucherType"
import ArticleSearchFields from "../_shared/ArticleSearchFields"
import CostEstimationsList from "./lists/WidgetCostEstimationsList"
import OrdersList from "./lists/WidgetOrderList"
import ReturnList from "./lists/WidgetReturnsList"
import { voucherListFilterAtom } from "../../data/states/listFilters"

type Props = ConfigurationProps & {
    showComponentKey?: string
    moduleProps?: ModulePropsMultiWidget
    defaultVoucherType: VoucherType
}

type ConfigurationProps = {
    height?: number
    size?: WidgetSizes
    showComponentKey?: string
    className?: string
    collapsible?: boolean
    externalSystemId?: number
    hideCover?: boolean
    lightVersion?: boolean
    initialVoucherType?: VoucherType
    titleTextId?: number
    showArticleNumberSearch?: boolean // widget's size must be 4x4
}

function WidgetComponent(props: Props) {
    const {
        externalSystemId,
        showComponentKey,
        height,
        size,
        className,
        collapsible,
        lightVersion,
        moduleProps,
        hideCover,
        initialVoucherType,
        defaultVoucherType,
        titleTextId,
        showArticleNumberSearch,
    } = props
    const { translateText } = useLocalization()
    const { workTask } = useWorkTask() ?? {}
    const matchParams = useParams<VoucherRouteParams>()
    const history = useHistory()

    const [active, setActive] = useState<boolean>(!!hideCover)
    const [voucherType, setVoucherType] = useState<VoucherType>(initialVoucherType || defaultVoucherType)
    const externalOrdersDialogRef = useRef<Dialog>(null)
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { externalCatalogUrl, loadingExternalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })
    const [listFilters] = useRecoilState(voucherListFilterAtom(matchParams.workTaskId))

    const { costEstimations, costEstimationsLoading, orders, ordersLoading, returns, returnsLoading } = useVoucherList(
        voucherType,
        workTask?.customer?.id,
        listFilters.selectedVehicleId,
        1,
        50,
        active && enableServiceCalls
    )

    const [selectedMultiWidgetTab] = useMultiWidgetState(moduleProps)
    const { plateCode } = useCountryCodeToLicensePlate(workTask?.vehicle?.countryCode)
    const url = useMemo(() => {
        const params = {
            ...matchParams,
            type: mapVoucherTypeForUrl(voucherType),
            workTaskId: matchParams.workTaskId ? matchParams.workTaskId : encodeUniqueId(uniqueId()),
        }
        return renderRoute(getBundleParams().vouchersListRoute, params)
    }, [matchParams, voucherType])

    function handleWidgetClick() {
        if (!active) {
            setActive(true)
        }
    }

    function handleChangeVoucherType(entry: VoucherTypeSelectItem) {
        setVoucherType(entry.voucherType)

        if (externalSystemId && entry.voucherType === VoucherType.Order) {
            externalOrdersDialogRef.current?.show()
        }
    }

    function handleOpenVouchersList() {
        if (externalSystemId && voucherType === VoucherType.Order) {
            externalOrdersDialogRef.current?.show()
        } else {
            history.push(url)
        }
        TmaHelper.GeneralCountEvent.Call(ECounterType.VoucherList)
    }

    function renderFilters() {
        const listActive = !matchParams.type || !matchParams.id

        return (
            <Stack direction="row" spacing={1}>
                <Box minWidth="35%" pr={1}>
                    <VoucherTypeSelector
                        selectedVoucherType={voucherType}
                        disabled={!listActive}
                        size={lightVersion ? "small" : "medium"}
                        variant="filled"
                        onVoucherTypeChanged={handleChangeVoucherType}
                    />
                </Box>
                {voucherType !== VoucherType.Return && (
                    <Box maxWidth="65%">
                        <VehicleSelector active={active} fullWidth size={lightVersion ? "small" : "medium"} variant="filled" />
                    </Box>
                )}
            </Stack>
        )
    }

    function renderList() {
        if (voucherType === VoucherType.CostEstimation) {
            if (costEstimationsLoading) {
                return <LoaderSmall />
            }

            if (!costEstimations?.length) {
                return <NoResultHint />
            }

            return <CostEstimationsList costEstimations={costEstimations} shortCountryCode={plateCode} showCustomerColumn={!matchParams.workTaskId} />
        }

        if (voucherType === VoucherType.Order) {
            if (externalSystemId) {
                return null
            }

            if (ordersLoading) {
                return <LoaderSmall />
            }

            if (!orders?.length) {
                return <NoResultHint />
            }

            return <OrdersList orders={orders} shortCountryCode={plateCode} showCustomerColumn={!matchParams.workTaskId} />
        }
        if (voucherType === VoucherType.Return) {
            if (returnsLoading) {
                return <LoaderSmall />
            }

            if (!returns?.length) {
                return <NoResultHint />
            }

            return <ReturnList returns={returns} />
        }
    }

    function renderFooter() {
        return (
            <Box display="flex" justifyContent={showArticleNumberSearch ? "flex-end" : "flex-start"}>
                <Button
                    onClick={handleOpenVouchersList}
                    disabled={externalSystemId ? loadingExternalCatalogUrl : false}
                    size={lightVersion ? "small" : "medium"}
                >
                    {translateText(6)}
                </Button>
            </Box>
        )
    }

    function renderArticleNumberSearch() {
        return (
            <Box flex={1} mt={1}>
                <TypographyTitle>{translateText(389)}</TypographyTitle>
                <Box p="60px">
                    <ArticleSearchFields hideClearButton url={url} variant="widget" />
                </Box>
            </Box>
        )
    }

    function renderContent() {
        return (
            <Stack justifyContent="center" flex="1" height="100%" paddingBottom=".3rem">
                <Box display="flex" marginBottom=".3rem" position="relative" height="50px">
                    {renderFilters()}
                </Box>
                <Box sx={{ position: "relative", height: showArticleNumberSearch ? "11rem" : "100%" }}>{renderList()}</Box>
                <Dialog className="form-confirmation" ref={externalOrdersDialogRef} text={translateText(31)} iconName="voucher" layout="stretch">
                    <iframe className="PDF-IFrame" src={externalCatalogUrl} title="External vouchers" />
                </Dialog>
                {showArticleNumberSearch && (
                    <>
                        <Divider />
                        {renderArticleNumberSearch()}
                    </>
                )}
            </Stack>
        )
    }

    if (showComponentKey && selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    if (showComponentKey) {
        return (
            <Box className="widget--vouchers" display="flex" flexDirection="column" height="100%" flex={1}>
                <Box flexGrow={1}>{renderContent()}</Box>
                <Box>{renderFooter()}</Box>
            </Box>
        )
    }

    return (
        <WidgetControl
            size={size || "4x2"}
            height={height}
            collapsible={collapsible}
            iconName="voucher"
            title={translateText(titleTextId || 31)}
            footer={renderFooter()}
            active={active}
            onClick={handleWidgetClick}
            className={`tk-vouchers widget${lightVersion ? " light-version" : ""} ${className || ""}`}
        >
            {renderContent()}
        </WidgetControl>
    )
}

export function Widget(props: Omit<Props, "defaultVoucherType">) {
    const defaultVoucherType = useDefaultVoucherType()

    if (!defaultVoucherType) {
        return null
    }
    return <WidgetComponent {...props} defaultVoucherType={defaultVoucherType} />
}
