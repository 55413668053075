import { Suspense, useMemo } from "react"
import { IMicros, MemoType, SystemType } from "@tm/models"
import { sanitize } from "@tm/utils"
import { SeparatorList } from "@tm/controls"
import { useUser } from "@tm/context-distribution"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-memo"]

function ErpInfoMemoComponent(props: Props) {
    const { loading, erpInfo } = useErpInfo(props.data, "list")
    const { userContext } = useUser()

    const { isMemoHtml, memos } = useMemo(() => {
        const memosContent = erpInfo?.memos?.filter((m) => m.type === MemoType.ArticleListInformation) ?? []

        if (
            userContext?.system.systemType === SystemType.Redesign &&
            memosContent?.some((m) => m.text.includes("<div") || m.text.includes("<span"))
        ) {
            return { isMemoHtml: true, memos: memosContent.map((m) => ({ label: m.label, text: sanitize(m.text) as string })) }
        }

        return { isMemoHtml: false, memos: memosContent }
    }, [erpInfo?.memos, userContext])

    if (loading || !memos.length) {
        return null
    }

    return (
        <div className="article__cell article__cell--erp-memo">
            {!isMemoHtml && <SeparatorList items={memos.map((m) => ({ label: m.label, value: m.text }))} />}
            {isMemoHtml && memos.map((m) => <div dangerouslySetInnerHTML={{ __html: m.text }} key={m.label} />)}
        </div>
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <Suspense fallback={null}>
                <ErpInfoMemoComponent {...props} />
            </Suspense>
        </ErpWrapper>
    )
}
