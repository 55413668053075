import { useState, useCallback, useEffect, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { ConfigParams, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { Badge, CostEstimationButton, Icon, Loader } from "@tm/components"
import { useShowNewArticleList } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { PredictiveRepairTime } from "../../../data/model/predictive"

type Props = {
    repairTime: PredictiveRepairTime
    workTaskId: string
    onAdd(repairTime: PredictiveRepairTime): void
    onRemove(id: string): void
}

export default function AddToCostEstimationButton({ repairTime, onRemove, onAdd, workTaskId }: Props) {
    const { translateText, languageId } = useLocalization()
    const showNewArticleList = useShowNewArticleList()

    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const [loading, setLoading] = useState(false)
    const [basketItemId, setBasketItemId] = useState<string>()

    useEffect(() => {
        setBasketItemId(undefined)

        const subscription = Container.getInstance(RegisteredModels.Basket_HasRepairTimes).subscribe({
            workTaskId,
            repairTimesProvider: repairTime.providerId,
            repairTimeProviderWorkId: repairTime.mainWorkId,
        })

        const unsub = subscription.addListener(
            "loaded",
            (response) => {
                setBasketItemId(response?.id)
                setLoading(false)
            },
            () => {
                setBasketItemId(undefined)
                setLoading(false)
            }
        )

        subscription.loadIfRequired()

        return unsub
    }, [repairTime, workTaskId])

    const handleClick = useCallback(() => {
        if (basketItemId) {
            onRemove(basketItemId)
        } else {
            onAdd(repairTime)
        }

        setLoading(true)
    }, [basketItemId, repairTime, onRemove, onAdd])

    const icon = useMemo(() => {
        if (basketItemId) {
            return "delete"
        }

        if (!showNewArticleList) {
            return languageId === "1" ? "voucher-kva" : "voucher-kva-international"
        }
        return "add-repair-times"
    }, [showNewArticleList, languageId, basketItemId])

    const badgeContent = useMemo(() => {
        if (loading) {
            return <Loader size="extrasmall" />
        }
        if (basketItemId) {
            return <Icon name="check" size="12px" />
        }

        return null
    }, [basketItemId, loading])

    return (
        <>
            {!!badgeContent && <Badge size="medium" badgeContent={badgeContent} sx={{ top: "-0.8em", left: "2.8em" }} />}
            <CostEstimationButton
                color={basketItemId && deleteButtonColorError ? "error" : "highlight"}
                disabled={loading}
                size="medium"
                variant="bordered"
                startIcon={<Icon name={icon} />}
                title={basketItemId ? translateText(624) : translateText(916)}
                onAddToCostEstimation={handleClick}
                sx={{ width: "4em" }}
            />
        </>
    )
}
