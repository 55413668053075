import { useLocalization } from "@tm/localization"
import { Stack, Typography, ModuleHeaderSlot } from "@tm/components"
import Morpheus from "@tm/morpheus"
import { ConfigParams } from "@tm/models"
import { CostEstimationOptions } from "./CostEstimationOptions"
import { CostEstimationAreas } from "./CostEstimationAreas"
import { StackRow } from "../../../StyledComponents"
import ActionButtons from "./ActionButtons"
import ExportSlot from "../../../_shared/export-slot"
import { useWorkTaskBasketState } from "../../../../hooks/basketState/useWorkTaskBasketState"
import { OfferState } from "../../business"
import { CostEstimationMode } from "./CostEstimationMode"

type Props = {
    workTaskId: string
    costEstimationIsActive: boolean
    costEstimationUrl: string
    creatingCostEstimation: boolean
    customerEmail?: string
    detailsIsActive: boolean
    orderRoute: string
    orderButtonTextId: string
    orderButtonIcon: string
    orderRoutePreActionExternalSystemId?: number
    exportTarget?: string
    showExportVoucherButton?: boolean
    showDmsExportButton?: boolean
    state: OfferState
    onPrintCostEstimation(): void
    onSendCostEstimation(): void
    onGenerateCostEstimationLink(): void
}

export type SharingData = {
    loading: boolean
    link?: string
    pin?: string
    error?: string | true
}

export function CostEstimationNavigation(props: Props) {
    const {
        workTaskId,
        customerEmail,
        creatingCostEstimation,
        costEstimationUrl,
        costEstimationIsActive,
        detailsIsActive,
        orderButtonIcon,
        orderRoute,
        orderButtonTextId,
        orderRoutePreActionExternalSystemId,
        showDmsExportButton,
        showExportVoucherButton,
        exportTarget,
        state,
    } = props
    const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()
    const { translateText } = useLocalization()
    const { costEstimation: costEstimationState } = useWorkTaskBasketState(workTaskId)
    const { costEstimation, costEstimationLoading, hasItems } = costEstimationState.state
    const {
        toggleShowSupplierArticleNumbers,
        toggleShowManufacturers,
        toggleShowWholesalerArticleNumbers,
        toggleShowVehicleImage,
        calculateWorks,
        changePriceType,
        changePriceVat,
        changeUseNoteText,
        changeCustomerGroup,
    } = costEstimationState.actions

    return (
        <StackRow justifyContent="space-between" flex={1} px={2}>
            <StackRow spacing={1} alignItems="flex-end">
                <Stack>
                    <Typography variant="h1">{translateText(82)}</Typography>
                    <CostEstimationMode costEstimationOptions={costEstimation?.costEstimationOptions} />
                </Stack>
                {!articleDetailsInModal && (
                    <ModuleHeaderSlot title={translateText(176)} hideDivider>
                        <CostEstimationAreas
                            costEstimationUrl={costEstimationUrl}
                            costEstimationIsActive={costEstimationIsActive}
                            detailsIsActive={detailsIsActive}
                        />
                    </ModuleHeaderSlot>
                )}
                <ModuleHeaderSlot title={translateText(177)} hideDivider>
                    <CostEstimationOptions
                        costEstimation={costEstimation}
                        disabled={costEstimationLoading || creatingCostEstimation || !hasItems}
                        customerEmail={customerEmail}
                        onPrintButtonClick={props.onPrintCostEstimation}
                        onSendCostEstimationButtonClick={props.onSendCostEstimation}
                        onGenerateCostEstimationLinkButtonClick={props.onGenerateCostEstimationLink}
                        onChangeRepairTimeCalculation={calculateWorks}
                        onChangePriceTypeMode={changePriceType}
                        onChangePriceVatMode={changePriceVat}
                        onChangeUseNoteText={changeUseNoteText}
                        onChangeCustomerGroupMode={changeCustomerGroup}
                        onTogglePrintVehicleImage={toggleShowVehicleImage}
                        onToggleShowArticleNumbers={toggleShowSupplierArticleNumbers}
                        onToggleShowManufacturers={toggleShowManufacturers}
                        onToggleShowWholesalerArticleNumbers={toggleShowWholesalerArticleNumbers}
                    />
                </ModuleHeaderSlot>
                <ExportSlot exportTarget={exportTarget} showExportVoucherButton={showExportVoucherButton} />
            </StackRow>
            <ActionButtons
                showDmsExportButton={showDmsExportButton}
                orderButtonIcon={orderButtonIcon}
                orderButtonTextId={orderButtonTextId}
                orderRoute={orderRoute}
                orderRoutePreActionExternalSystemId={orderRoutePreActionExternalSystemId}
                state={state}
            />
        </StackRow>
    )
}
