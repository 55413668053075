import { PropsWithChildren } from "react"
import { OrderVoucherSupplierItem } from "@tm/models"
import { createContext, useContext } from "use-context-selector"
import { usePreviouslyOrderedArticles } from "./usePreviouslyOrderedArticles"

export const PreviouslyOrderedArticlesContext = createContext<OrderVoucherSupplierItem[] | undefined>(undefined)

export function PreviouslyOrderedArticlesProvider(props: PropsWithChildren<unknown>) {
    const data = usePreviouslyOrderedArticles()
    return <PreviouslyOrderedArticlesContext.Provider value={data}>{props.children}</PreviouslyOrderedArticlesContext.Provider>
}

export function usePreviouslyOrderedArticlesContext(): OrderVoucherSupplierItem[] | undefined {
    return useContext(PreviouslyOrderedArticlesContext)
}
