import { StateCreator } from "zustand"
import { ShowCarModelDetailsResponse, Vehicle } from "@tm/models"
import { FastServiceStore } from "../.."
import { CalcState, FastServiceSelection, Fields, Item, RecordWorks, TyreSpecification, WorkTimes } from "../../models"

export type MaintenancePlanSlice = {
    maintenancePlan: {
        vehicleFields: Fields
        maintenancePlanLoading?: boolean
        selections?: FastServiceSelection[]
        workTimes: WorkTimes
        additionalWorks?: FastServiceSelection[]
        followupWorks?: FastServiceSelection[]
        moreInfo?: FastServiceSelection[]
        selectionsToSend?: { [key: string]: { [key: string]: string[] } }
        userSelections?: { [ket: string]: { [key: string]: string[] } }
        works?: RecordWorks
        selectedService: Item[]
        stateVehicle?: Vehicle
        modelDetails?: ShowCarModelDetailsResponse
        calcState?: CalcState
        baseCalculationCalcId?: string
        tireSpecifications?: TyreSpecification[]
    }
}

export function getInitialState(): MaintenancePlanSlice {
    return {
        maintenancePlan: {
            maintenancePlanLoading: false,
            vehicleFields: {
                calculateIncludedAdditionalWorks: false,
            },
            selections: [],
            additionalWorks: [],
            followupWorks: [],
            moreInfo: [],
            selectedService: [],
            selectionsToSend: {},
            workTimes: {
                inspectionWorkTimes: [],
                additionalWorkTimes: [],
                followUpWorkTimes: [],
            },
            baseCalculationCalcId: "",
        },
    }
}

export const createMaintenancePlanSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], MaintenancePlanSlice> = (set, get) => ({
    ...getInitialState(),
})
