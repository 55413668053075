import { Article, ArticleIdentifier, OE } from "@tm/models"
import { VoucherPart } from "@tm/data/vouchers/costEstimations"
import { ExtendedOrderVoucherItem, ReturnVoucherItem } from "../model"

export function mapVoucherPartToArticleIdentifier(partItems: VoucherPart[]): ArticleIdentifier[] {
    const parts: ArticleIdentifier[] = []
    partItems.forEach((partItem) => {
        const { articleNumber, supplierId, productGroupId } = partItem.articleInformation
        if (supplierId && articleNumber && productGroupId) {
            parts.push({
                supplierId,
                supplierArticleNo: articleNumber,
                productGroupId,
                tecdocTypeNo: undefined,
            })
        }
    })
    return parts
}

export function mapOrderCatalogPartToArticleIdentifier(partItems: ExtendedOrderVoucherItem[]): ArticleIdentifier[] {
    const parts: ArticleIdentifier[] = []
    partItems.forEach((partItem) => {
        if (partItem.articleInfo.dataSupplier && partItem.articleInfo.dataSupplierArticleNumber && partItem.articleInfo.productGroup?.id) {
            parts.push({
                supplierId: partItem.articleInfo.dataSupplier.id,
                supplierArticleNo: partItem.articleInfo.dataSupplierArticleNumber,
                productGroupId: partItem.articleInfo.productGroup.id,
                tecdocTypeNo: undefined,
            })
        }
    })
    return parts
}

export function mapReturnVoucherItemToArticleIdentifier(partItems: ReturnVoucherItem[]): ArticleIdentifier[] {
    const parts: ArticleIdentifier[] = []
    partItems.forEach((partItem) => {
        if (partItem.dataSupplier && partItem.dataSupplierArticleNumber && partItem.productGroup?.id) {
            parts.push({
                supplierId: partItem.dataSupplier.id,
                supplierArticleNo: partItem.dataSupplierArticleNumber,
                productGroupId: partItem.productGroup.id,
                tecdocTypeNo: undefined,
            })
        }
    })
    return parts
}

export function mapVoucherPartToArticle(voucherPart: VoucherPart): Partial<Article> {
    return {
        id: voucherPart.partItemId,
        quantity: voucherPart.quantityValue,
        description: voucherPart.articleInformation.description,
        supplier: {
            id: voucherPart.articleInformation.supplierId ?? -1,
            name: voucherPart.articleInformation.supplierName ?? "",
            abbreviation: "",
            isTopPriority: false,
        },
        supplierArticleNo: voucherPart.articleInformation.articleNumber,
        traderArticleNo: voucherPart.articleInformation.wholesalerArticleNumber,
        productGroup: {
            id: voucherPart.articleInformation.productGroupId ?? -1,
            name: voucherPart.articleInformation.productGroupName ?? "",
            sortId: -1,
            thumbnail: "",
        },
        additionalDescription: voucherPart.articleInformation.additionalDescription,
        initialQuantity: voucherPart.quantityValue,
        showAddToBasket: true,
        requestErpInfo: !voucherPart.mainPartItemId,
    }
}

export function mapVoucherPartToOePart(voucherPart: VoucherPart, currencyCode?: string): Partial<OE.OePart> {
    return {
        number: voucherPart.articleInformation.articleNumber,
        description: voucherPart.articleInformation.description,
        additionalDescription: voucherPart.articleInformation.additionalDescription,
        manufacturerId: voucherPart.articleInformation.supplierId,
        manufacturerName: voucherPart.articleInformation.supplierName,
        quantity: voucherPart.quantityValue,
        productGroupId: voucherPart.articleInformation.productGroupId,
        prices: [
            {
                currency: currencyCode ?? "",
                value: voucherPart.calculation.offerPriceTotal ?? 0,
            },
        ],
        traderNumber: voucherPart.articleInformation.wholesalerArticleNumber,
    }
}

export function mapOrderedItemToArticle(orderedItem: ExtendedOrderVoucherItem): Partial<Article> {
    return {
        id: orderedItem.id,
        quantity: orderedItem.confirmedQuantity.value,
        description: orderedItem.articleInfo.description,
        supplier: {
            id: orderedItem.articleInfo.dataSupplier ? orderedItem.articleInfo.dataSupplier.id : -1,
            name: orderedItem.articleInfo.dataSupplier ? orderedItem.articleInfo.dataSupplier.name : "",
            abbreviation: "",
            isTopPriority: false,
        },
        supplierArticleNo: orderedItem.articleInfo.dataSupplierArticleNumber,
        traderArticleNo: orderedItem.articleInfo.wholesalerArticleNumber,
        productGroup: {
            id: orderedItem.articleInfo.productGroup ? orderedItem.articleInfo.productGroup.id : -1,
            name: orderedItem.articleInfo.productGroup ? orderedItem.articleInfo.productGroup.name : "",
            sortId: -1,
            thumbnail: "",
        },
        additionalDescription: orderedItem.articleInfo.additionalDescription,
        thumbnail: orderedItem.articleInfo.thumbnailUrl,
        initialQuantity: orderedItem.confirmedQuantity.value,
        showAddToBasket: true,
        requestErpInfo: !orderedItem.isLinkedItem,
    }
}

export function mapOrderedItemToPartToOePart(orderedItem: ExtendedOrderVoucherItem): Partial<OE.OePart> {
    return {
        description: orderedItem.articleInfo.description,
        additionalDescription: orderedItem.articleInfo.additionalDescription,
        number: orderedItem.articleInfo.oeArticleNumber,
        manufacturerId: orderedItem.articleInfo.vehicleManufacturer?.id,
        manufacturerName: orderedItem.articleInfo.vehicleManufacturer?.name,
        quantity: orderedItem.confirmedQuantity.value,
        productGroupId: orderedItem.articleInfo.productGroupId,
        prices: [
            {
                currency: orderedItem.articleInfo.oePrice?.currencyCode ?? "",
                value: orderedItem.articleInfo.oePrice?.amount ?? 0,
            },
        ],
        traderNumber: orderedItem.articleInfo.wholesalerArticleNumber,
    }
}
