import { useMemo, MouseEvent, useState, useCallback } from "react"
import { useHistory, useParams } from "react-router"
import { WorkTaskInfo, useWorkTask, useUser, getModuleFromUserContext } from "@tm/context-distribution"
import { Box, ButtonProps, MenuItemWidget, Select, SelectProps, Typography, Button, styled, SxProps, Theme } from "@tm/components"
import { Widget, WidgetSizes } from "@tm/controls"
import { Authority } from "@tm/data"
import { useLocalization } from "@tm/localization"
import { ActiveVehicleDataProviders, ModuleOptionType, UserModuleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { renderRoute, Dictionary, encodeUniqueId, TmaHelper, uniqueId, ConfigFunctionType, ConfigFunctions, getModuleCompareRoute } from "@tm/utils"
import { NavigationItemConfigProps, Props as ItemProps, NavigationItemStatus, ItemContent } from "./components/navigationItem"
import { getDemoModuleOptions } from "./helpers"

type Props = {
    title?: string
    highlight?: boolean
    icon?: string
    className?: string
    disabledFunction?: string
    disabledMsgId?: string
    disabledIcon?: string
    disabled?: string | boolean
    items: Dictionary<NavigationItemConfigProps>
    hideCover?: boolean
    id?: string
    moduleRoute?: string
    hideNotAvailableMsg?: boolean
    size?: WidgetSizes
    height?: number
    dropDownVariant?: SelectProps["variant"]
    buttonVariant?: ButtonProps["variant"]
    alwaysDisplay?: boolean
    sx?: SxProps<Theme>
}

const translationRegex = /\{\{(.*?)\}\}/

const DropDownWrapper = styled("div")(() => ({
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "flex-end",
    flexDirection: "column",
}))

const SxWidget = styled(Widget)({})

export function WidgetNavigationDropdown(props: Props) {
    const history = useHistory()
    const matchParams = useParams<Record<string, string>>()
    const { translateText } = useLocalization()
    const { workTask } = useWorkTask() || {}
    const { userContext, userSettings, setUserSetting } = useUser() || {}

    const [hideCover, setHideCover] = useState(props.hideCover || false)
    const [selectedItemState, setSelectedItem] = useState<ItemProps>()
    const activatableModulesLoadable = Authority.useGetActivatableModules(userContext)
    const [hasActivatableModules, setHasActivatableModules] = useState<boolean>()

    const getDisabled = useCallback(
        (item: { disabledFunction?: string; disabled?: string | boolean }): boolean => {
            if (typeof item.disabled === "boolean") {
                return item.disabled
            }

            let disabledFunction: ConfigFunctionType | undefined = item.disabledFunction
                ? ConfigFunctions[item.disabledFunction as keyof typeof ConfigFunctions]
                : undefined

            if (!disabledFunction) {
                if (!item.disabled) {
                    return false
                }

                try {
                    disabledFunction = eval(`[${item.disabled}][0]`)
                } catch {
                    return false
                }

                if (typeof disabledFunction !== "function") {
                    return false
                }
            }

            const result = disabledFunction(userContext, workTask?.vehicle, workTask?.customer)
            if (typeof result !== "boolean") {
                return false
            }
            return result
        },
        [userContext, workTask?.vehicle, workTask?.customer]
    )

    const getStatus = useCallback(
        (item: NavigationItemConfigProps, disabled: boolean): NavigationItemStatus => {
            const dataProvider = item.setAsActiveVehicleDataProviderOnSelect
            if (!getModuleFromUserContext(userContext, UserModuleType.DemoActivation) || !dataProvider) {
                return "none"
            }

            if (!activatableModulesLoadable.response) {
                return "none"
            }

            const groupProvider = Object.keys(dataProvider)?.first()

            if (!groupProvider) {
                return "none"
            }

            const providerName = dataProvider[groupProvider]

            const moduleGroup = activatableModulesLoadable.response.moduleGroups.find((group) => group.id === groupProvider)

            if (!moduleGroup || !providerName) {
                return "none"
            }

            setHasActivatableModules(true)

            const module = moduleGroup.modules.find((m) => m.id === providerName && m.vehicleType === workTask?.vehicle?.vehicleType)

            if (!module) {
                return "none"
            }

            const { activeModuleOption, activatableModuleOption, fullActivatableModuleOption } = getDemoModuleOptions(module)

            if (activeModuleOption?.type === ModuleOptionType.Demo) {
                return "demo"
            }

            if (activatableModuleOption && disabled) {
                return "locked"
            }

            if (activeModuleOption?.type === ModuleOptionType.Full || (!fullActivatableModuleOption && item.active)) {
                return "none"
            }

            return "locked"
        },
        [userContext, activatableModulesLoadable.response, workTask?.vehicle]
    )

    const options: Array<ItemProps> = useMemo(() => {
        if (!props.items || !userContext) {
            return []
        }

        return Object.values(props.items)
            .map((item) => {
                const disabled = getDisabled(item)
                const status = getStatus(item, disabled)
                return {
                    ...item,
                    disabled: status === "none" && disabled,
                    disabledTooltip: translateText(216),
                    name: item.name?.replace(translationRegex, (_, num) => translateText(num)),
                    status,
                }
            })
            .filter((x) => !!x && !!x.name && (x.active || (x.activatable && x.status !== "none")))
            .orderBy((x) => x.sort || 0)
    }, [translateText, props.items, getDisabled, getStatus, userContext])

    const defaultItem = useMemo(() => {
        if (userSettings?.activeVehicleDataProviders) {
            const selectedProviders = userSettings.activeVehicleDataProviders
            const item = options.find((item) => {
                if (!item.disabled && item.status !== "locked" && item.setAsActiveVehicleDataProviderOnSelect) {
                    // Assuming each item only tries to set one provider
                    const firstKey = Object.keys(item.setAsActiveVehicleDataProviderOnSelect)[0] as keyof ActiveVehicleDataProviders
                    if (firstKey && selectedProviders[firstKey] === item.setAsActiveVehicleDataProviderOnSelect[firstKey]) {
                        return true
                    }
                }
            })

            if (item) {
                return item
            }
        }
        return options.find((x) => !x.disabled && x.status !== "locked") || options.first()
    }, [options, userSettings?.activeVehicleDataProviders])

    const selectedItem = selectedItemState || defaultItem

    const navigateToModuleGroup = (item: ItemProps, params: Record<string, string>) => {
        if (!props.moduleRoute) {
            history.push("/usersettings/module?fastScroll=1")
            return
        }

        const moduleGroupId = Object.keys(item.setAsActiveVehicleDataProviderOnSelect!).first()
        const module = getModuleCompareRoute(moduleGroupId, workTask?.vehicle?.vehicleType)

        const url = renderRoute(props.moduleRoute, { ...params, module })
        Morpheus.showView("1", url)
    }

    const navigateTo = (item: ItemProps, newWorkTask?: WorkTaskInfo) => {
        const params = newWorkTask ? { workTaskId: encodeUniqueId(newWorkTask?.id) } : matchParams
        if (item.status === "locked") {
            navigateToModuleGroup(item, params)
            return
        }

        const url = getUrl(item, params)
        if (url) {
            TmaHelper.ModuleCalled({ route: url, vehicleId: workTask?.vehicle?.id })
            history.push(url)
            TmaHelper.GeneralCountEvent.CallModule(url)
        }
    }

    const handleDirectClick = () => {
        if (!selectedItem) {
            return
        }

        const newWorkTask = workTask ? undefined : ({ id: uniqueId() } as WorkTaskInfo)

        navigateTo(selectedItem, newWorkTask)
    }

    const handleClick = (e: MouseEvent<HTMLElement>) => {
        function wasDropdownClicked(element: HTMLElement | null): boolean {
            if (!element) {
                return false
            }

            const classes = Array.from(element.classList)
            if (classes.some((x) => x.includes("dropdown"))) {
                return true
            }
            if (classes.some((x) => x.includes("widget"))) {
                return false
            }

            return wasDropdownClicked(element.parentElement)
        }

        if (!hideCover) {
            setHideCover(true)
        } else if (
            selectedItem &&
            (selectedItem.disabled !== true || selectedItem.status === "locked") &&
            !wasDropdownClicked(e.target as HTMLElement)
        ) {
            navigateTo(selectedItem)
        }
    }

    const handleChangeItem = (item: ItemProps) => {
        if (item.disabled) {
            return
        }

        if (item.status !== "locked" && userSettings && setUserSetting && item.setAsActiveVehicleDataProviderOnSelect) {
            setUserSetting("ACTIVE_VEHICLE_DATA_PROVIDERS", {
                ...userSettings.activeVehicleDataProviders,
                ...item.setAsActiveVehicleDataProviderOnSelect,
            })
        }
        navigateTo(item)
    }

    const getUrl = (item: ItemProps, params: any): string | undefined => {
        if (!item.route) {
            return
        }

        try {
            const createUrl: Function = eval(`[${item.route}][0]`)

            if (typeof createUrl !== "function") {
                return renderRoute(item.route, params)
            }

            const route = createUrl(userContext, workTask?.vehicle, workTask?.customer)

            return renderRoute(route, params)
        } catch {
            return renderRoute(item.route, params)
        }
    }

    if (!options.length && !activatableModulesLoadable.response?.moduleGroups.length && props.alwaysDisplay !== true) {
        return null
    }

    const enabledOptionsCount = options.filter((item) => !item.disabled).length
    const allDemoOptionsDisabled = options.every((item) => item.status === "none" && item.disabled)
    const hasMultipleOptions = enabledOptionsCount > 1
    const disabledFromProps = getDisabled(props)

    const disabled =
        disabledFromProps ||
        !options.length ||
        (hasActivatableModules ? !!options.length && allDemoOptionsDisabled : enabledOptionsCount === 0) ||
        activatableModulesLoadable.isLoading

    const locked = !!options.length && options.every((option) => option.status === "locked")
    let className = `bundle-misc widget-navigation-dropdown ${hasActivatableModules ? "has-activatable-modules " : ""}`

    let notAvailableMsg
    let notAvailableIcon

    if (locked && !props.hideNotAvailableMsg) {
        notAvailableMsg = translateText(12594)
        notAvailableIcon = "lock"
    } else if (disabled) {
        className += "is-disabled "
        if (disabledFromProps && !props.hideNotAvailableMsg) {
            notAvailableMsg = props.disabledMsgId ? translateText(props.disabledMsgId) : translateText(12652)
            notAvailableIcon = props.disabledIcon || "car-front"
        }
    }

    if (props.className) {
        className += props.className
    }

    const title = props.title?.replace(translationRegex, (s, num) => translateText(num))

    return (
        <SxWidget
            sx={props.sx}
            highlight={props.highlight}
            iconName={props.icon}
            size={props.size || "1x1"}
            height={props.height && props.height}
            active={hideCover}
            blockedByCondition={disabled}
            className={className}
            title={title}
            onClick={!disabled || locked ? (!hasMultipleOptions ? handleDirectClick : handleClick) : undefined}
            id={props.id}
            notAvailableMsg={notAvailableMsg}
            notAvailableIcon={notAvailableIcon}
            displayDemoBadge={!hasMultipleOptions && !!options.length && options.every((x) => x.status === "demo")}
        >
            {hasMultipleOptions ? (
                <DropDownWrapper>
                    <Select
                        variant={props.dropDownVariant || "filled"}
                        disableUnderline
                        disabled={disabled}
                        value={selectedItem}
                        onChange={(e) => setSelectedItem(e.target.value as ItemProps)}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                        sx={{ "&& .MuiSelect-select.MuiSelect-outlined": { paddingRight: "5px" } }}
                    >
                        {options.map((option) => {
                            return (
                                <MenuItemWidget
                                    onClick={() => handleChangeItem(option)}
                                    key={`key-${option.name}`}
                                    value={option}
                                    disabled={option.disabled}
                                >
                                    <ItemContent {...option} />
                                </MenuItemWidget>
                            )
                        })}
                    </Select>
                </DropDownWrapper>
            ) : (
                <Box sx={{ position: "absolute", bottom: 0, left: "0.2em", right: 0 }}>
                    <Button variant={props.buttonVariant || "contained"} sx={{ width: "100%" }} disabled={disabled || !selectedItem?.name}>
                        <Typography variant="inherit" noWrap>
                            {selectedItem?.name || translateText(956)}
                        </Typography>
                    </Button>
                </Box>
            )}
        </SxWidget>
    )
}
