import { Genart, PanelState, RepairTime, SpecificationsGenart } from "."

export enum StepNames {
    Start = "start",
    MaintenancePlan = "maintenancePlan",
    Technician = "technician",
    EngineCompartment = "engineCompartment",
    UnderVehicle = "underVehicle",
    Interior = "interior",
    Exterior = "exterior",
    General = "general",
    MaintenanceReview = "maintenanceReview",
    MaintenanceComplete = "maintenanceComplete",
    Tester = "tester",
    Inspection = "inspection",
    CheckWork = "checkWork",
    TestComplete = "testComplete",
    TestDrive = "testDrive",
    TiresWheels = "tiresWheels",
    End = "end",
}

export type Work = {
    id: string
    genArts: Genart[]
    consumables: SpecificationsGenart[]
    label: string
    isSelected: boolean
    repairTimes: RepairTime[]
    location: string
    providerId: string
    locationId: string
    workType: number
    infoTexts: string[]
    haynesProSmartLinks: SmartLink[]
}

export type SmartLink = {
    filter: string
    id1: number
    id2: string
    operation: string
    text: string[]
    label: string
}

export type RecordWorks = Record<string, Work[]>

export type RecordCustomWorks = Record<string, CustomWork[]>

export enum WorkId {
    FirstAidKit = "1045",
}

export type CustomWork = {
    id: number
    providerId?: string
    title: number[]
    initiallyClosed: boolean
    isCompleted: boolean
    panelState: PanelState
    location: string
}

export type WorkTimes = {
    inspectionWorkTimes: RepairTime[]
    additionalWorkTimes: RepairTime[]
    followUpWorkTimes: RepairTime[]
}

export enum SelectionKeys {
    Selections = "selections",
    AdditionalWorks = "additionalWorks",
    FollowUps = "followupWorks",
}
