import { ActivatableModule, ModuleOption } from "@tm/models"

export function getDemoModuleOptions(module: ActivatableModule) {
    let activeModuleOption = Object.values(module.moduleOptions!).find((x) => x.active)

    let fullActivatableModuleOption = module.moduleOptions?.full?.activatable ? module.moduleOptions.full : undefined
    let activatableModuleOption: ModuleOption | undefined

    if (module.moduleOptions?.full?.active && module.modulePackages?.higherPackage) {
        const moduleOptionsArr = Object.values(module.modulePackages.higherPackage.moduleOptions)

        activeModuleOption = moduleOptionsArr.find((x) => x.active) ?? activeModuleOption
        activatableModuleOption = moduleOptionsArr.find((x) => x.activatable)

        fullActivatableModuleOption = activatableModuleOption ?? fullActivatableModuleOption
    }

    return {
        activeModuleOption,
        activatableModuleOption,
        fullActivatableModuleOption,
    }
}
