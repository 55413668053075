import { useContextSelector } from "use-context-selector"
import { CompilationShowViewModelItem } from "../../../../../../compilations/src/data"
import { ArticlesContext, ArticlesProviderProps } from "../../../ListV2/ContextProvider/Articles"
import { ensureContextAvailability, useAfterPaintEffect } from "../../../ListV2/helpers"
import { ArticleData } from "../../../ListV2/models"
import { useArticles } from "./useArticles"

type CompilationArticlesProviderProps = ArticlesProviderProps & {
    parts: CompilationShowViewModelItem[]
}

export function CompilationArticlesProvider({ setHasRendered, children, parts }: CompilationArticlesProviderProps) {
    const articlesData = useArticles(parts)

    useAfterPaintEffect(() => {
        if (articlesData.requestStatus === "success") {
            setHasRendered(true)
        }
    }, [articlesData.requestStatus])

    return <ArticlesContext.Provider value={articlesData as ArticleData}>{children}</ArticlesContext.Provider>
}

export function useCompilationArticlesContext<TContext extends ArticleData>(): TContext
export function useCompilationArticlesContext<TContext extends ArticleData, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useCompilationArticlesContext<TContext extends ArticleData, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(ArticlesContext, (context) => {
        const validatedContext = ensureContextAvailability("CompilationArticlesContext", context)

        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}
