import { useWorkTaskId } from "@tm/context-distribution"
import { ActiveVehicleDataProviders } from "@tm/models"
import { useActiveVehicleDataProviders } from "@tm/utils"
import { PropsWithChildren } from "react"
import { createContext, useContext } from "use-context-selector"
import { ensureContextAvailability } from "../../helpers"

type ActiveVehicleDataProvidersData = {
    isLoading: boolean
    activeProviders: ActiveVehicleDataProviders | undefined
}

export const ActiveVehicleDataProvidersContext = createContext<ActiveVehicleDataProvidersData | undefined>(undefined)

export function ActiveVehicleDataProvidersProvider(props: PropsWithChildren<unknown>) {
    const workTaskId = useWorkTaskId()
    const activeVehicleDataProvidersData = useActiveVehicleDataProviders(workTaskId)

    return (
        <ActiveVehicleDataProvidersContext.Provider value={activeVehicleDataProvidersData}>
            {props.children}
        </ActiveVehicleDataProvidersContext.Provider>
    )
}

export function useActiveVehicleDataProvidersContext(): ActiveVehicleDataProvidersData {
    const context = useContext(ActiveVehicleDataProvidersContext)
    return ensureContextAvailability("ActiveVehicleDataProvidersContext", context)
}
