import { PropsWithChildren } from "react"
import { FiltersContext, useFiltersContext } from "../../../ContextProvider/Filters"
import { useVehicle } from "../../../hooks"
import { useVehiclePartsListParamsContext } from "../ListParams"
import { useFilters } from "./useFilters"

export function VehiclePartsFiltersProvider(props: PropsWithChildren<unknown>) {
    const params = useVehiclePartsListParamsContext()
    const vehicle = useVehicle()
    const filters = useFilters(vehicle, params, true)
    return <FiltersContext.Provider value={filters}>{props.children}</FiltersContext.Provider>
}

export const useVehiclePartsFiltersContext = useFiltersContext
