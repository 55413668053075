import { StepNavigationCategory, StepsNavigation, CalculationHistoryStatus, StepNames } from "../../models"

export const navigationData: StepsNavigation[] = [
    { icon: "start", indexNumber: 0, stepName: StepNames.Start, available: true, badge: undefined, completed: true },
    { icon: "maintenance-plan", indexNumber: 1, stepName: StepNames.MaintenancePlan, available: true, badge: undefined, completed: false },
    {
        icon: "technician",
        indexNumber: 2,
        stepName: StepNames.Technician,
        available: false,
        badge: "technician",
        completed: false,
        calculationStatus: CalculationHistoryStatus.ReadyToStart,
    },
    {
        icon: "engine-compartment",
        indexNumber: 3,
        stepName: StepNames.EngineCompartment,
        available: false,
        badge: "technician",
        completed: false,
        specialStep: true,
        calculationStatus: CalculationHistoryStatus.InProgress,
        category: StepNavigationCategory.Inspection,
    },
    {
        icon: "under_vehicle",
        indexNumber: 4,
        stepName: StepNames.UnderVehicle,
        available: false,
        badge: "technician",
        completed: false,
        specialStep: true,
        category: StepNavigationCategory.Inspection,
    },
    {
        icon: "wheel-and-car",
        indexNumber: 5,
        stepName: StepNames.TiresWheels,
        available: false,
        badge: undefined,
        completed: false,
        category: StepNavigationCategory.Inspection,
    },
    {
        icon: "interior",
        indexNumber: 6,
        stepName: StepNames.Interior,
        available: false,
        badge: "technician",
        completed: false,
        specialStep: true,
        category: StepNavigationCategory.Inspection,
    },
    {
        icon: "exterior",
        indexNumber: 7,
        stepName: StepNames.Exterior,
        available: false,
        badge: "technician",
        completed: false,
        specialStep: true,
        category: StepNavigationCategory.Inspection,
    },
    {
        icon: "general-vehicle",
        indexNumber: 8,
        stepName: StepNames.General,
        available: false,
        badge: "technician",
        completed: false,
        specialStep: true,
        category: StepNavigationCategory.Inspection,
    },
    {
        icon: "maintenance-overview",
        indexNumber: 9,
        stepName: StepNames.MaintenanceReview,
        available: false,
        badge: "technician",
        completed: false,
        category: StepNavigationCategory.Inspection,
    },
    {
        icon: "maintenance-complete",
        indexNumber: 10,
        stepName: StepNames.MaintenanceComplete,
        available: false,
        badge: "technician",
        completed: false,
        category: StepNavigationCategory.Inspection,
    },
    {
        icon: "inspector",
        indexNumber: 11,
        stepName: StepNames.Tester,
        available: false,
        badge: "inspector",
        completed: false,
        calculationStatus: CalculationHistoryStatus.ReadyForExam,
    },
    {
        icon: "inspection",
        indexNumber: 12,
        stepName: StepNames.Inspection,
        available: false,
        badge: "inspector",
        completed: false,
        calculationStatus: CalculationHistoryStatus.InExamination,
    },
    {
        icon: "test-drive",
        indexNumber: 13,
        stepName: StepNames.TestDrive,
        available: false,
        badge: "inspector",
        completed: false,
        category: StepNavigationCategory.Test,
    },
    {
        icon: "list-of-defects",
        indexNumber: 14,
        stepName: StepNames.CheckWork,
        available: false,
        badge: "inspector",
        completed: false,
        category: StepNavigationCategory.Test,
    },
    {
        icon: "maintenance-complete",
        indexNumber: 15,
        stepName: StepNames.TestComplete,
        available: false,
        badge: "inspector",
        completed: false,
        category: StepNavigationCategory.Test,
    },
    {
        icon: "end",
        indexNumber: 16,
        stepName: StepNames.End,
        available: false,
        badge: "inspector",
        completed: false,
        calculationStatus: CalculationHistoryStatus.Finished,
    },
]
