import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { formatDate, Dictionary } from "@tm/utils"
import {
    CellContentPosition,
    Table,
    TableCellData,
    TableColumnData,
    Loader,
    Button,
    TextField,
    Typography,
    Icon,
    Stack,
    Switch,
} from "@tm/components"
import { useParams } from "react-router"
import CompilationActions from "./actions"
import CompilationCreator from "./creator"
import { useUpdateCompilation } from "../../../../../data/hooks/useCompilations"
import { Compilation } from "../../../../../data"
import { isWM } from "../../../../../utils"
import { CompilationDeleteNavigation } from "../../navigation/delete/delete"

type RouteProps = {
    compilationId?: string
}

type Props = {
    compilations: Compilation[]
    selectedCompilationIds: string[]
    compact: boolean
    showCreator: boolean
    onOpenCompilationDetails(id: string): void
    onSelectCompilation(id: string): void
    openCompilationList(): void
    onShowCreator(): void
}

export default function CompilationsList(props: Props) {
    const { compilations, selectedCompilationIds, compact, showCreator } = props
    const { translateText } = useLocalization()
    const matchParams = useParams<RouteProps>()
    const { updateCompilation } = useUpdateCompilation()

    const [editingCompilations, setEditingCompilations] = useState<Dictionary<Compilation>>({})
    const [updatingCompilations, setUpdatingCompilations] = useState<Dictionary<boolean>>({})

    const handleShowHideEditMode = (compilation: Compilation) => {
        if (!editingCompilations[compilation.id]) {
            setEditingCompilations({
                ...editingCompilations,
                [compilation.id]: compilation,
            })
        } else {
            const temp = { ...editingCompilations }
            delete temp[compilation.id]
            setEditingCompilations(temp)
        }
    }

    const handleUpdateCompilation = (compilation: Compilation) => {
        const editedCompilation = editingCompilations[compilation.id]
        if (editedCompilation) {
            setUpdatingCompilations((prev) => ({ ...prev, [compilation.id]: true }))
            updateCompilation({
                compilationId: editedCompilation.id,
                name: editedCompilation.name,
                description: editedCompilation.description,
                isPublic: editedCompilation.isPublic,
            }).then(
                () => {
                    setUpdatingCompilations((prev) => ({ ...prev, [compilation.id]: false }))
                    handleShowHideEditMode(compilation)
                },
                () => {
                    setUpdatingCompilations((prev) => ({ ...prev, [compilation.id]: false }))
                }
            )
        }
    }

    const handleNameChange = (name: string | null, compilation: Compilation) => {
        setEditingCompilations({
            ...editingCompilations,
            [compilation.id]: {
                ...compilation,
                name: name || "",
            },
        })
    }

    const handleDescriptionChange = (description: string | null, compilation: Compilation) => {
        setEditingCompilations({
            ...editingCompilations,
            [compilation.id]: {
                ...compilation,
                description: description || "",
            },
        })
    }

    const handlePrivacyChange = (compilation: Compilation) => {
        setEditingCompilations({
            ...editingCompilations,
            [compilation.id]: {
                ...compilation,
                isPublic: !compilation.isPublic,
            },
        })
    }

    const renderNameCell = (compilation: Compilation) => {
        const compilationBeingEdited = editingCompilations[compilation.id]
        if (compilationBeingEdited) {
            return (
                <TextField
                    inputProps={{ maxLength: 50 }}
                    variant="filled"
                    size="large"
                    label={translateText(155)}
                    inputCounter
                    value={compilationBeingEdited.name}
                    fullWidth
                    onChange={(e) => {
                        handleNameChange(e.target.value, editingCompilations[compilation.id])
                    }}
                />
            )
        }

        return <>{compilation.name}</>
    }

    const renderDescriptionCell = (compilation: Compilation) => {
        const compilationBeingEdited = editingCompilations[compilation.id]
        if (compilationBeingEdited) {
            return (
                <TextField
                    inputProps={{ maxLength: 200 }}
                    label={translateText(617)}
                    variant="filled"
                    size="large"
                    inputCounter
                    value={compilationBeingEdited.description}
                    fullWidth
                    onChange={(e) => {
                        handleDescriptionChange(e.target.value, editingCompilations[compilation.id])
                    }}
                />
            )
        }

        return <>{compilation.description}</>
    }

    const renderPrivacyCell = (compilation: Compilation) => {
        const compilationBeingEdited = editingCompilations[compilation.id]
        if (compilationBeingEdited) {
            return (
                <Switch
                    label={translateText(1254)}
                    labelPlacement="start"
                    onChange={(e) => handlePrivacyChange(editingCompilations[compilation.id])}
                    checked={compilationBeingEdited.isPublic}
                    size="small"
                />
            )
        }
        return <>{translateText(compilation.isPublic ? 1254 : 1253)}</>
    }

    const renderModificationCell = (compilation: Compilation) => {
        if (editingCompilations[compilation.id] || !compilation.updateDate) {
            return
        }

        return <>{formatDate(new Date(compilation.updateDate), "d")}</>
    }

    function getColumns() {
        const columns: TableColumnData[] = [{ header: translateText(155), overflowCell: true }] // name
        if (compact) {
            columns.push({ header: translateText(1252) }) // modification date
        } else {
            columns.push({ header: translateText(617), overflowCell: true }) // description
            if (isWM()) {
                columns.push({ header: translateText(1243) }) // privacy
            }
            columns.push(
                { header: translateText(258) }, // creation date
                { header: translateText(1252) } // modification date
            )
        }
        columns.push({ alignContent: CellContentPosition.right }) // Actions
        return columns
    }

    function getCells(compilation: Compilation): TableCellData[] {
        const updating = updatingCompilations[compilation.id]
        const cells: TableCellData[] = [{ displayValue: updating ? <Loader size="extrasmall" /> : renderNameCell(compilation), id: "1" }]

        if (compact) {
            cells.push(
                { displayValue: updating ? <Loader size="extrasmall" /> : renderModificationCell(compilation), id: "2" },
                {
                    displayValue: (
                        <Stack direction="row" gap={0.5}>
                            <CompilationDeleteNavigation
                                compilation={compilation}
                                openCompilations={() => {
                                    /* do nothing */
                                }}
                            />
                            <Button size="small" startIcon={<Icon name="details" />} onClick={() => props.onOpenCompilationDetails(compilation.id)} />
                        </Stack>
                    ),
                    id: "3",
                }
            )
        } else {
            cells.push({ displayValue: updating ? <Loader size="extrasmall" /> : renderDescriptionCell(compilation), id: "2" })
            if (isWM()) {
                cells.push({ displayValue: updating ? <Loader size="extrasmall" /> : renderPrivacyCell(compilation), id: "3" })
            }
            cells.push(
                {
                    displayValue:
                        !editingCompilations[compilation.id] && !!compilation.createDate ? formatDate(new Date(compilation.createDate), "d") : "",
                    id: "4",
                },
                { displayValue: renderModificationCell(compilation), id: "5" },
                {
                    displayValue: (
                        <CompilationActions
                            compilation={compilation}
                            isEditing={!!editingCompilations[compilation.id]}
                            isSelected={selectedCompilationIds?.includes(compilation.id)}
                            onShowHideEditMode={handleShowHideEditMode}
                            onUpdateCompilation={handleUpdateCompilation}
                            onOpenCompilationDetails={props.onOpenCompilationDetails}
                            onSelectCompilation={props.onSelectCompilation}
                        />
                    ),
                    id: "6",
                }
            )
        }
        return cells
    }

    const displayData = compilations.map((compilation) => ({
        cells: getCells(compilation),
        id: `${compilation.id}`,
        customRow: false,
        active: compilation.id === matchParams.compilationId,
    }))

    return (
        <>
            {!isWM() && compact && (
                <Stack direction="row" justifyContent="space-between" alignItems="center" pb={1}>
                    <Typography variant="h3">{translateText(12791)}</Typography>
                    <Button size="small" onClick={props.openCompilationList} startIcon={<Icon name="arrow-right" />} />
                </Stack>
            )}
            {showCreator && compact && <CompilationCreator onClose={props.onShowCreator} short />}
            <Table
                overflowY="auto"
                headerBackground="transparent"
                columns={getColumns()}
                rows={displayData}
                rowCap={4}
                onRowDoubleClick={(compilation) => props.onOpenCompilationDetails(compilation.id)}
                onRowClick={compact ? (compilation) => props.onOpenCompilationDetails(compilation.id) : undefined}
            />
        </>
    )
}
