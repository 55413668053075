import { useWorkTask } from "@tm/context-distribution"
import { ModuleGroupId, ModuleId, ModuleMode, channel } from "@tm/models"
import { FC, useEffect } from "react"
import { DemoBanner } from "./components/DemoBanner"

type Props = {
    activationRoute?: string
    moduleGroupId: ModuleGroupId
    moduleId: ModuleId
    moduleName: string
    moduleMode: ModuleMode
}

const DemoAlert: FC<Props> = ({ moduleGroupId, moduleId, moduleName, moduleMode, activationRoute }) => {
    const { workTaskId, workTask } = useWorkTask() || {}

    const vehicleType = workTask?.vehicle?.vehicleType

    useEffect(() => {
        if (moduleId && workTaskId && vehicleType && ["dat", "eurotax", "tecrmi", "autodata", "haynespro"].includes(moduleId)) {
            channel("GLOBAL").publish("MVC/RESIZE", { moduleMode: moduleMode as string })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workTaskId, vehicleType])

    if (!workTaskId || !vehicleType || !moduleGroupId) {
        return null
    }

    if (!moduleId || !moduleName || !moduleGroupId || !vehicleType || !["TD", "SD", "RT", "NONE"].includes(moduleMode?.toString())) {
        return null
    }

    return (
        <DemoBanner
            moduleGroupId={moduleGroupId}
            moduleId={moduleId}
            vehicleType={vehicleType}
            moduleName={moduleName}
            activationRoute={activationRoute}
        />
    )
}

export default DemoAlert
