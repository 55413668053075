import { ajaxAuth } from "@tm/utils"
import { getBasketServiceUrl } from "../.."
import { ExportToExternalBasketRequest, ExportToExternalBasketResponse, ImportExternalBasketResponse } from "../../model/ThirdPartyBasket"

function getServiceUrl() {
    return `${getBasketServiceUrl()}.External/ThirdPartyBasket`
}

export function importFromExternalBasket(workTaskId: string) {
    const url = `${getServiceUrl()}/ImportExternalBasket`
    const body = { workTaskId }

    return ajaxAuth<ImportExternalBasketResponse>({ url, body, method: "POST" })
}

export function exportToExternalBasket(request: ExportToExternalBasketRequest) {
    const url = `${getServiceUrl()}/ExportToExternalBasket`

    return ajaxAuth<ExportToExternalBasketResponse>({ url, body: request, method: "POST" })
}
