import { StateCreator } from "zustand"
import _ from "lodash"
import { FastServiceStore } from "../.."
import { tabItems } from "./staticData"
import { DriveRightTiresRespone, TyresCritsResponse } from "../../repositories"
import { TireBrands } from "../../repositories/tires-tiresBrands/model"
import { TireType, TyreAxles, WheelsAndTyresTab } from "../../models"

export type TyresWheelsSlice = {
    tyresWheels: {
        tabs: WheelsAndTyresTab[]
        selectedTab: WheelsAndTyresTab
        tiresData: DriveRightTiresRespone & { loading: boolean }
        tecDocUsed: number
        tireBrands: TireBrands
        loadingCrits: boolean
        tyresCrits: Partial<TyresCritsResponse>
        spareWheel?: boolean
        showBonus?: boolean
        selectedTire?: TireType
        selectedTyreSpecification?: TyreAxles
    }
}

export function getInitialState(): TyresWheelsSlice {
    const clonedTabItems = _.cloneDeep(tabItems)
    return {
        tyresWheels: {
            tabs: clonedTabItems,
            selectedTab: clonedTabItems[0],
            tiresData: {
                frontTires: [],
                rearTires: [],
                loading: false,
            },
            tecDocUsed: 0,
            tireBrands: { suppliers: [] },
            loadingCrits: false,
            spareWheel: undefined,
            selectedTire: undefined,
            tyresCrits: {},
        },
    }
}

export const createTyresWheelsSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], TyresWheelsSlice> = () => ({
    ...getInitialState(),
})
