import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { Button, ConfirmationDialog, Icon, SelectorPaper, Stack, Typography } from "@tm/components"
import { ConfirmationDialogKeys } from "@tm/models"
import { useDeleteCompilations } from "../../../../../data/hooks/useCompilations"

type Props = {
    allSelected: boolean
    selectedCompilationIds: Array<string>
    onSelectAll(): void
    onUnselectAll(): void
}

export default function CompilationsSelection(props: Props) {
    const { allSelected, selectedCompilationIds, onSelectAll, onUnselectAll } = props
    const { translateText } = useLocalization()
    const { deleteCompilations } = useDeleteCompilations()
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

    const handleWarningConfirm = () => {
        deleteCompilations(selectedCompilationIds)
    }

    return (
        <SelectorPaper>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="label">
                    {translateText(701)}: {selectedCompilationIds.length} {translateText(6)}
                </Typography>
                <Button onClick={allSelected ? onUnselectAll : onSelectAll} size="small">
                    {allSelected ? translateText(703) : translateText(702)}
                </Button>
                <Button onClick={() => setShowDeleteDialog(true)} startIcon={<Icon name="delete" />} variant="text" />
                <ConfirmationDialog
                    open={showDeleteDialog}
                    confirmationButtonText={translateText(585)}
                    cancelButtonText={translateText(584)}
                    onConfirm={handleWarningConfirm}
                    onCancel={() => setShowDeleteDialog(false)}
                    confirmationKey={ConfirmationDialogKeys.DeleteCompilations}
                >
                    {translateText(1313)}
                </ConfirmationDialog>
                <Button color="primary" startIcon={<Icon name="cancel-x" />} size="large" title={translateText(317)} onClick={onUnselectAll} />
            </Stack>
        </SelectorPaper>
    )
}
