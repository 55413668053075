import { Button, Loader, Scrollbar, Toolbar } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import { useActions } from '@tm/morpheus'
import { classes, clone, getValue, setValue } from '@tm/utils'
import { batch, useSelector } from 'react-redux'
import { RimFilter } from '../../../data/model'
import { MainState } from '../../main'
import { AttributeSelectionCheckboxes, DropDownWrapper, FilterComponent, ResetButtonFromState, SelectionCheckbox } from '../../_shared'
import { Actions } from '../business'
import { addOrRemoveFilter, addOrRemoveOneFilter } from '../business/helper'
import { FiltersBySelectionOrder, SelectedFilters } from '../business/model'
import { FilterType } from '../../../data/helpers'
import { AvailabilityFilterType } from '../../../business'
import { getBundleParams } from '../../../utils'
import { useUser, useAvailabilityStatus } from '@tm/context-distribution'
import { FilterCriterias } from '../../../data/enums'

type Props = {}

const selector = (s: MainState) => ({
    selectedFilters: s.wheelsList.base.selectedFilters,
    filtersBySelectionOrder: s.wheelsList.base.filtersBySelectionOrder,
    filters: s.wheelsList.base.filters,
    articlesLoading: s.wheelsList.base.articles.loading,
    articlesError: s.wheelsList.base.articles.error,
    displayStateReset: s.wheelsList.base.displayStateReset,
    availabilityFilter: s.wheelsList.base.availabilityFilter
})

const RimFilters: React.FC<Props> = ({ }) => {
    const { translateText } = useLocalization()
    const isHostettler = getBundleParams()?.isHostettler
    const actions = useActions(Actions, "updateSelectedFilters", "loadRimFilterAndArticles", "changeAvailabilityFilter", "loadRimFilters")
    const { selectedFilters, filtersBySelectionOrder, filters, articlesLoading, articlesError, displayStateReset, availabilityFilter } = useSelector(selector)
    const { manufacturers, widths, offsets, inches, colors, designs, winterprofed, loading, snowChainsCompatible, noModification, ece, OE, VSA, type } = filters
    const [isOpen, setIsOpen] = React.useState<boolean>(true)
    const displayAvailabilityFilter = getBundleParams().priceAvailabilityInformation ? !useUser()?.userSettings?.hideWheelsAvailability : false
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()

    const handleDeselectFilter = (path: string, updatedSelectedFilters?: SelectedFilters) => {
        const newSelectedFilters = updatedSelectedFilters ?? setValue({ ...selectedFilters }, [path], "")
        const newFiltersOrder: FiltersBySelectionOrder[] = filtersBySelectionOrder.filter(x => x.attributeKey !== path)
        
        batch(() => {
            actions.updateSelectedFilters(newSelectedFilters, newFiltersOrder)
            actions.loadRimFilterAndArticles(false)
            if (!isHostettler) {
                actions.loadRimFilters()
            }
        })
    }

    function handleSelectFilter(path: string, filter: string): void
    function handleSelectFilter(path: string, filter: RimFilter): void
    function handleSelectFilter(path: string, filter: RimFilter | string): void {
        if (typeof (filter) !== "string" && filter.attributeId == -1) //reset when we click on inch/width/offset
            filter.attributeValue = ""

        const filterValue = typeof (filter) === "string" ? filter : filter.attributeValue

        if ([FilterCriterias.rimOffset.toString(), FilterCriterias.rimWidth.toString(), FilterCriterias.rimDiameter.toString()].includes(path)) {
            const currentFilterValue = getValue({ ...selectedFilters }, [path])
            if (currentFilterValue === filterValue) {
                return
            }
        }

        let newSelectedFilters = clone(selectedFilters)
        if (path === FilterCriterias.rimType.toString()) {
            // In this case, we don't want to add another filter, we just want to switch between filters
            const lastType: string = getValue({ ...selectedFilters }, [path]);
            newSelectedFilters = setValue({ ...selectedFilters }, [path], addOrRemoveOneFilter(filterValue, lastType));
        }
        else if ([FilterCriterias.rimSupplier.toString(), FilterCriterias.rimColor.toString(), FilterCriterias.rimSort.toString()].includes(path)) {
            const lastManufacurers: string = getValue({ ...selectedFilters }, [path])
            newSelectedFilters = setValue({ ...selectedFilters }, [path], addOrRemoveFilter(filterValue, lastManufacurers))
        }
        else
            newSelectedFilters = setValue({ ...selectedFilters }, [path], filterValue)
        
        let newFiltersOrder: FiltersBySelectionOrder[] = filtersBySelectionOrder
        const activeFilter = filtersBySelectionOrder.find(x => x.attributeKey == path && (x.attributeValue == filterValue || filterValue == ""))
        
        if (activeFilter) {
            const index = filtersBySelectionOrder.indexOf(activeFilter)
            newFiltersOrder.splice(index, 1)
        } else {
            newFiltersOrder = filtersBySelectionOrder.concat({ attributeKey: path, attributeValue: filterValue })
        }

        batch(() => {
            actions.updateSelectedFilters(newSelectedFilters, newFiltersOrder)
            if (!isHostettler) {
                actions.loadRimFilters()
            }
            actions.loadRimFilterAndArticles(false)
        })
    }

    const handleResetButton = (e: React.MouseEvent<HTMLElement>, filter: string) => {
        e.stopPropagation()
        handleDeselectFilter(filter)
        actions.loadRimFilters()
    }

    const handleDimensionsReset = React.useCallback(() => {
        let filters = { ...selectedFilters };
        [FilterCriterias.rimWidth.toString(), FilterCriterias.rimOffset.toString(), FilterCriterias.rimDiameter.toString()].forEach(x => setValue(filters, [x], ""))
 
        let newFiltersOrder = filtersBySelectionOrder.filter(
            (filter) =>
                ![FilterCriterias.rimWidth.toString(), FilterCriterias.rimOffset.toString(), FilterCriterias.rimDiameter.toString()].includes(filter.attributeKey)
        )

        batch(() => {
            actions.updateSelectedFilters(filters, newFiltersOrder)
            actions.loadRimFilterAndArticles(false)
            if (!isHostettler) {
                actions.loadRimFilters()
            }
        })
    }, [selectedFilters])

    const handleDropDownSelectionReset = (path: string) => {
        handleDeselectFilter(path)
    }

    const handleCollapsibleChange = () => {
        setIsOpen(!isOpen)
    }

    const renderAttributeSelectionCheckboxes = () => {
        const manufacturerFilter =
            <AttributeSelectionCheckboxes
                collapsibleText={translateText(71)}
                filters={manufacturers}
                displaySearchButton
                onChange={handleSelectFilter}
                onReset={handleResetButton}
                selectedFilters={selectedFilters}
            />

        const designFilter =
            <AttributeSelectionCheckboxes
                initiallyClosed
                collapsibleText={translateText(994)}
                filters={designs}
                displaySearchButton
                onChange={handleSelectFilter}
                onReset={handleResetButton}
                selectedFilters={selectedFilters}
            />

        const colorFilter =
            <AttributeSelectionCheckboxes
                initiallyClosed
                collapsibleText={translateText(944)}
                filters={colors}
                displaySearchButton
                onChange={handleSelectFilter}
                onReset={handleResetButton}
                selectedFilters={selectedFilters}
            />

        const VSAOEFilter =
            <AttributeSelectionCheckboxes
                collapsibleText={translateText(397)}
                filters={[...winterprofed, ...snowChainsCompatible, ...noModification, ...ece, ...(OE || []), ...(VSA || [])].filter((thing, i, arr) => {
                    return arr.indexOf(arr.find(t => t.attributeId === thing.attributeId && t.attributeValue != "0") || arr[0]) === i
                })}
                onChange={handleSelectFilter}
                selectedFilters={selectedFilters}
                disabledCollapsible={!manufacturers.length}
            />

        const typeFilter =
            <AttributeSelectionCheckboxes
                collapsibleText={translateText(13084)}
                filters={type}
                onChange={handleSelectFilter}
                onReset={handleResetButton}
                selectedFilters={selectedFilters}
            />

        const renderAvailabilityFilter = () => {
            if (!availabilityStatusIdsToShow?.length && !availabilityStatusIdsToShowSecondary?.length) {
                return null
            }

            const primarySelected = availabilityFilter === AvailabilityFilterType.Primary
            const secondarySelected = availabilityFilter === AvailabilityFilterType.Secondary

            return (
                <FilterComponent
                    filterId={FilterType.Availability}
                    title={translateText(412)}
                    onCollapsibleChange={handleCollapsibleChange}
                    active={isOpen}
                    onReset={() => actions.changeAvailabilityFilter(AvailabilityFilterType.None)}
                    loading={filters.loading}
                >
                    {
                        !!availabilityStatusIdsToShow?.length &&
                        <SelectionCheckbox
                            label={translateText(1623)?.toUpperCase()}
                            selected={primarySelected}
                            onChange={() => actions.changeAvailabilityFilter(primarySelected ? AvailabilityFilterType.None : AvailabilityFilterType.Primary)}
                            blockModifier
                            compactStyle
                        />
                    }
                    {
                        !!availabilityStatusIdsToShowSecondary?.length &&
                        <SelectionCheckbox
                            label={translateText(12860)?.toUpperCase()}
                            selected={secondarySelected}
                            onChange={() => actions.changeAvailabilityFilter(secondarySelected ? AvailabilityFilterType.None : AvailabilityFilterType.Secondary)}
                            blockModifier
                            compactStyle
                        />
                    }
                </FilterComponent>
            )
        }

        if (isHostettler) {
            //TODO we should add a check to display filters depending on the response from V4.
            const filteredType: RimFilter[] = type.filter((filter) => filter.info)
            return <> {filteredType.length > 0 && typeFilter} {VSAOEFilter} {colorFilter} {designFilter} {manufacturerFilter} {displayAvailabilityFilter && renderAvailabilityFilter()}</>
        } else {
            return <> {typeFilter} {manufacturerFilter} {colorFilter} {designFilter} {VSAOEFilter} {displayAvailabilityFilter && renderAvailabilityFilter()}</>
        }
    }

    if (articlesError) {
        return null
    }

    if (loading) {
        return (
            <div className="wheels-list_filters">
                <div className="article-list__panel article-list__status">
                    <Loader />
                </div >
            </div>
        )
    }

    return (
        <div className={classes(`wheels-list_filters`, articlesLoading && "loading_cover")}>
            {displayStateReset && <ResetButtonFromState onReset={handleDimensionsReset} />}
            <Scrollbar>
                <Toolbar className="diameter-filters">
                    <DropDownWrapper
                        onReset={handleDropDownSelectionReset}
                        filterType={FilterCriterias.rimDiameter.toString()}
                        items={[{ attributeValue: translateText(715), attributeId: -1, info: FilterCriterias.rimDiameter.toString() }, ...inches]}
                        selectedValue={inches.find(x => x.attributeValue == selectedFilters[FilterCriterias.rimDiameter])}
                        onChange={handleSelectFilter}
                    />
                    <DropDownWrapper
                        onReset={handleDropDownSelectionReset}
                        filterType={FilterCriterias.rimWidth.toString()}
                        items={[{ attributeValue: translateText(713), attributeId: -1, info: FilterCriterias.rimWidth.toString() }, ...widths]}
                        selectedValue={widths.find(x => x.attributeValue == selectedFilters[FilterCriterias.rimWidth])}
                        onChange={handleSelectFilter}
                    />
                    <DropDownWrapper
                        onReset={handleDropDownSelectionReset}
                        filterType={FilterCriterias.rimOffset.toString()}
                        items={[{ attributeValue: translateText(982), attributeId: -1, info: FilterCriterias.rimOffset.toString() }, ...offsets]}
                        onChange={handleSelectFilter}
                        selectedValue={offsets.find(x => x.attributeValue == selectedFilters[FilterCriterias.rimOffset])}
                    />
                    <Button layout={['ghost']} size="s" disabled={!selectedFilters[FilterCriterias.rimWidth] && !selectedFilters[FilterCriterias.rimDiameter] && !selectedFilters[FilterCriterias.rimOffset]} onClick={handleDimensionsReset} icon="synchronize" />
                </Toolbar>
                {renderAttributeSelectionCheckboxes()}
            </Scrollbar>
        </div>
    )
}

export default RimFilters
