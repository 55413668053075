import { LocalizationProps, useLocalization } from "@tm/localization"
import {
    useBasketModuleParameter,
    useCostEstimationModuleParameter,
    usePureArticleLists,
    useUser,
    useWorkTask,
    WithUserContextProps,
    WithUserSettingsProps,
    WithWorkTaskProps,
} from "@tm/context-distribution"
import { ChangeItemsResponse, Article, channel, IMicros, UserModuleType } from "@tm/models"
import { connectComponent, WithMicroProps } from "@tm/morpheus"
import { openDetailsState, selectedOrderOptionState, RouteComponentProps, selectedExternalArticleDetailsState } from "@tm/utils"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import { useEffect } from "react"
import { BaseDetailsRouteProps } from "../../business"
import { DetailsHeadState } from "./business"
import { DetailsHeadComponent } from "./component"
import { Actions, IActions } from "./business/actions"
import { createListRequests } from "./business/createListRequests"

type DetailsHeadBaseProps = {
    state: DetailsHeadState
    actions: IActions
    showAdditionalPrices?: boolean
    showArticleWarehouseSelector?: boolean
    infoAvailabilityOverviewData?: { status: number; text: string }[]
    hideWholesalerArticleNumber?: boolean
}

export type DetailsHeadProps = WithMicroProps<IMicros> &
    LocalizationProps &
    RouteComponentProps<BaseDetailsRouteProps> &
    WithUserContextProps &
    WithUserSettingsProps &
    WithWorkTaskProps &
    DetailsHeadBaseProps

const DetailsHeadComponentWrapper = (props: DetailsHeadBaseProps) => {
    const setOpenDetailsState = useSetRecoilState(openDetailsState)
    const [externalArticleDetailsState, setExternalArticleDetailsState] = useRecoilState(selectedExternalArticleDetailsState)
    const selectedOrderOption = useRecoilValue(selectedOrderOptionState)
    const history = useHistory()
    const location = useLocation()
    const routeMatch = useRouteMatch<BaseDetailsRouteProps>()
    const localization = useLocalization()
    const user = useUser()
    const workTask = useWorkTask()!
    const { setRequest } = usePureArticleLists()
    const { showBasketButton } = useBasketModuleParameter()
    const { hasCostEstimation } = useCostEstimationModuleParameter()

    const { detailsRequest, detailsResponse, foundBySearchTerm } = props.state

    const catalogModule = user.userContext.modules?.find((x) => x.type === UserModuleType.Catalog)
    const hasExternalBasket = catalogModule?.hasExternalBasket ?? false
    const hideWholesalerArticleNumber = hasExternalBasket && !!externalArticleDetailsState.externalBasketItemId

    useEffect(
        function handleDetailsLoaded() {
            if (detailsResponse && detailsRequest) {
                createListRequests(detailsRequest, detailsResponse, foundBySearchTerm).forEach(([targetId, request]) => {
                    setRequest(targetId, request)
                })
            }
        },
        [detailsResponse]
    )

    useEffect(() => {
        return function cleanup() {
            setExternalArticleDetailsState({ externalBasketItemId: undefined })
        }
    }, [])

    const onFinished = (data: ChangeItemsResponse) => {
        channel("GLOBAL").publish("ERP/TECCOM_ARTICLE_ADDED", data)
    }

    const updateArticle = (article?: Article) => {
        setOpenDetailsState({ article })
    }

    return (
        <DetailsHeadComponent
            {...props}
            {...user}
            {...workTask}
            hasCostestimation={hasCostEstimation}
            showBasketButton={showBasketButton}
            history={history}
            localization={localization}
            location={location}
            match={routeMatch}
            updateArticle={updateArticle}
            selectedOrderOption={selectedOrderOption}
            onFinished={onFinished}
            hideWholesalerArticleNumber={hideWholesalerArticleNumber}
        />
    )
}

export default connectComponent(Actions, DetailsHeadComponentWrapper)
