import { Article, TradeReferenceNumber } from "@tm/models"
import { useMemo } from "react"
import { useTradeReferenceContext } from "../../ContextProvider/TradeReferences"

export function useArticleTradeReferences(article: Article) {
    const { tradeReferences } = useTradeReferenceContext()
    return useMemo(() => {
        const articleTradeReferences = tradeReferences.find(
            ([key]) => key.supplierId === article.supplier.id && key.supplierArticleNo === article.supplierArticleNo
        )?.[1]

        return (articleTradeReferences ?? []) as TradeReferenceNumber[]
    }, [article, tradeReferences])
}
