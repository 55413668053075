import { memo, useEffect, useMemo, useRef, useState, MouseEvent, useCallback, PropsWithChildren, ReactNode } from "react"
import { Collapse, List, Stack, TextField, Typography } from "@tm/components"
import { TyreFilter } from "@tm/models"
import { AccordionDetailsSmall, AccordionHeadline, AccordionSimple } from "../../../../../parts/src/components/ListV2/components/Filters/FiltersStyledComponents"
import IconWithTooltip from "../../../../../parts/src/components/_shared/icon-with-tooltip/IconWithTooltip"
import { FilterItem } from "./FilterItem"
import { AvailabilityFilterType, IListFilters } from "../business"
import { getEnumByPath } from "../../../data/helpers"
import { isInfoAvailable } from "../../../data/helpers"
import { InfoDialog } from "../../_shared"

export type AvailabilityFilter = {
    name: string
    value: AvailabilityFilterType
}

type FiltersAreaProps = PropsWithChildren<{
    path?: IListFilters
    headline: string
    data?: TyreFilter[]
    onChange?: (filter: TyreFilter | AvailabilityFilter | null, path?: IListFilters) => void
    onResetFilters?: (path: IListFilters | undefined) => void
    hideFilterSearch?: boolean
    hideActions?: boolean
    isExpanded?: boolean
    expandWhenChildIsSelected?: boolean
    selectedFilters?: TyreFilter[]
    seasons?: TyreFilter[]
    hasInlineInfo?: boolean
    slider?: boolean
    onResetEuFilters?: () => void
    children?: ReactNode
    onAccordionClick?: (key: string, value: boolean) => void
    dropdownPath?: "availability" | "tyreSize"
}>

export const FiltersArea = memo(function FiltersAreaComponent(props: FiltersAreaProps) {
    const {
        data,
        headline,
        onResetFilters,
        hideFilterSearch,
        hideActions,
        path,
        onChange,
        selectedFilters,
        hasInlineInfo,
        onResetEuFilters,
        isExpanded,
        children,
        onAccordionClick,
        dropdownPath
    } = props

    const [showInput, setShowInput] = useState(false)
    const [groupFilter, setGroupFilter] = useState("")
    const textFieldRef = useRef<HTMLInputElement>(null)
    const [displayDialog, setDisplayDialog] = useState(false)
    const [infoInline, setInfoInline] = useState("")

    useEffect(() => {
        if (textFieldRef.current && showInput) {
            textFieldRef.current.focus()
        }
    }, [showInput])

    const handleResetFilters = (event: MouseEvent) => {
        event.stopPropagation()
        onResetFilters?.(path)
    }

    const handleFilterCategory = (event: MouseEvent) => {
        event.stopPropagation()
        setShowInput(!showInput)
    }

    const handleFilterChange = useCallback(
        (filter: TyreFilter | AvailabilityFilter) => {
            onChange?.(filter, path)
        },
        [onChange, path]
    )

    const filteredData = useMemo(() => {
        return data?.filter(filter =>
            filter.value.toLowerCase().includes(groupFilter.toLowerCase())
        ) || []
    }, [data, groupFilter])

    const filterId = getEnumByPath(path)

    const onInfoClick = (e: MouseEvent) => {
        e.stopPropagation()
        setDisplayDialog(true)
    }

    const handleDialogCLose = () => {
        setDisplayDialog(false)
    }

    const onInfoInlineClick = useCallback(
        (filterValue?: string) => {
            if (filterValue) {
                setInfoInline(filterValue)
                setDisplayDialog(true)
            }
        },
        [onChange, path]
    )

    const handleEuFiltersReset = (event: React.MouseEvent) => {
        event.stopPropagation()
        onResetEuFilters?.()
    }

    const handleAccordionClick = () => {
        onAccordionClick?.(path || dropdownPath!, !isExpanded)
    }

    return (
        <>
            <AccordionSimple expanded={isExpanded} onChange={handleAccordionClick}>
                <AccordionHeadline sx={{ display: "flex" }} >
                    <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
                        <Typography ml={1}>{headline}</Typography>
                        <Stack direction="row" spacing={1} sx={{ mr: ".8em" }}>
                            {isInfoAvailable(filterId) && <IconWithTooltip variant="info" onClick={onInfoClick} />}
                            {!(hideFilterSearch || hideActions) && <IconWithTooltip variant="search" onClick={handleFilterCategory} />}
                            {!hideActions && !!onResetFilters && <IconWithTooltip variant="remove-filter" onClick={path === "wetGripClass" ? handleEuFiltersReset : handleResetFilters} />}
                        </Stack>
                    </Stack>
                </AccordionHeadline>
                <AccordionDetailsSmall sx={path === "wetGripClass" ? { padding: 0 } : undefined}>
                    <Collapse in={showInput} unmountOnExit>
                        <TextField
                            inputRef={textFieldRef}
                            size="small"
                            onChange={(e) => setGroupFilter(e.target.value)}
                            sx={{ margin: "4px 8px" }}
                            clearButton
                            variant="filled"
                            value={groupFilter}
                        />
                    </Collapse>
                    {children ? children :
                        <List sx={{ p: 0 }}>
                            {filteredData.map((filter, index) => {
                                const isChecked = selectedFilters ? selectedFilters.some(selected => selected.value === filter.value) : false
                                return (
                                    <FilterItem
                                        key={index}
                                        filter={filter}
                                        checked={isChecked}
                                        onChange={handleFilterChange}
                                        onInfoInlineClick={hasInlineInfo ? onInfoInlineClick : undefined}
                                    />
                                )
                            })}
                        </List>}
                </AccordionDetailsSmall>
            </AccordionSimple>
            {displayDialog && <InfoDialog filterId={path === "extras" ? infoInline : filterId} onDialogCLose={handleDialogCLose} />}
        </>
    )
})