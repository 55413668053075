import { createSelector } from "reselect"
import { MainState } from ".."

// summary
export const fieldsSelector = createSelector(
    (s: MainState) => s.summary.fields,
    (x) => x
)
export const isGermanOrAustrianVehicleSelector = createSelector(
    (s: MainState) => ({ isGermanOrAustrianVehicle: s.summary.isGermanOrAustrianVehicle }),
    (x) => x
)

// extern
export const documentSelector = createSelector(
    (s: MainState) => s.extern.document,
    (x) => x
)
export const successfullStartSelector = createSelector(
    (s: MainState) => s.extern.successfullStart,
    (x) => x
)

// calculation
export const worksSelector = createSelector(
    (s: MainState) => s.calculation.works,
    (x) => x
)
export const itemsSelector = createSelector(
    (s: MainState) => s.calculation.items,
    (x) => x
)
export const erpArticlesSelector = createSelector(
    (s: MainState) => s.calculation.erpArticles,
    (x) => x
)
export const totalsSelector = createSelector(
    (s: MainState) => s.calculation.totals,
    (x) => x
)

// manage
export const vehicleSelector = createSelector(
    (s: MainState) => s.manager.vehicle,
    (x) => x
)
export const customerSelector = createSelector(
    (s: MainState) => s.manager.customer,
    (x) => x
)
