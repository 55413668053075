import { SelectedFilters, WheelsAndTyresTabNames, TyreSpecification } from "../data/models"
import { extractSpecificationValues } from "./extractSpecificationValues"
import { removeDuplicatesFromArray } from "./removeDuplicatesFromArray"

export function getFilteredTyreSpecifications(
    selectedTireSpecification: TyreSpecification | undefined,
    selectedTabName: string,
    selectedFilters: SelectedFilters,
    mainSizesSelected: boolean
) {
    const values: string[] = []

    if (mainSizesSelected && selectedTireSpecification) {
        let filterValues

        selectedTireSpecification.specifications?.forEach((specification) => {
            if (
                (selectedTabName === WheelsAndTyresTabNames.FrontLeft || selectedTabName === WheelsAndTyresTabNames.FrontRight) &&
                specification.frontAxle?.size
            ) {
                filterValues = extractSpecificationValues(selectedTireSpecification, selectedFilters, specification.frontAxle?.size)

                if (
                    selectedFilters.width === filterValues.width &&
                    selectedFilters.height === filterValues.height &&
                    selectedFilters.inch === filterValues.inch &&
                    selectedFilters.loadIndex === filterValues.loadIndex &&
                    selectedFilters.speedIndex === filterValues.speedIndex
                ) {
                    values.push(`${specification.frontAxle?.size} ${specification.frontAxle?.wheelSize}`)
                }
            } else if (
                (selectedTabName === WheelsAndTyresTabNames.RearLeft || selectedTabName === WheelsAndTyresTabNames.RearRight) &&
                specification.rearAxle?.size
            ) {
                filterValues = extractSpecificationValues(selectedTireSpecification, selectedFilters, specification.rearAxle?.size)

                if (
                    selectedFilters.width === filterValues.width &&
                    selectedFilters.height === filterValues.height &&
                    selectedFilters.inch === filterValues.inch &&
                    selectedFilters.loadIndex === filterValues.loadIndex &&
                    selectedFilters.speedIndex === filterValues.speedIndex
                ) {
                    values.push(`${specification.rearAxle?.size} ${specification.rearAxle?.wheelSize}`)
                }
            }
        })

        return removeDuplicatesFromArray(values)
    }

    if (selectedTireSpecification) {
        selectedTireSpecification.specifications?.forEach((specification) => {
            if (
                (specification.frontAxle?.size && selectedTabName === WheelsAndTyresTabNames.FrontLeft) ||
                selectedTabName === WheelsAndTyresTabNames.FrontRight
            ) {
                values.push(`${specification.frontAxle?.size} ${specification.frontAxle?.wheelSize}`)
            } else if (
                (specification.rearAxle?.size && selectedTabName === WheelsAndTyresTabNames.RearLeft) ||
                selectedTabName === WheelsAndTyresTabNames.RearRight
            ) {
                values.push(`${specification.rearAxle?.size} ${specification.rearAxle?.wheelSize}`)
            }
        })
    }

    return removeDuplicatesFromArray(values)
}
