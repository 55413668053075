import { atom, useRecoilState, useRecoilValue } from "recoil"
import { Article, RecommendedArticles, Vehicle } from "@tm/models"
import { useCallback, useMemo, useState } from "react"
import { usePureArticleLists, useWorkTask, useWorkTaskId } from "@tm/context-distribution"
import { Articles } from "@tm/data"
import { useQueryClient } from "react-query"
import { createRecommendedArticlesRequest } from "./createRecommendedArticlesRequest"
import { getCachedWorkTaskBasketData } from "../../../../../../../basket/src/data/hooks/workTaskBasket/queries/useWorkTaskBasketData"
import { PartsViewSettingsState } from "../../../states"
import { PureList } from "../../../../PureList/component"
import { useArticleListConfiguration } from "../../../ArticleListConfiguration"
import { useVehicle } from "../.."
import { useListParamsContext } from "../../../ContextProvider"

const RecommendedArticleState = atom<Article | undefined>({
    key: "parts_recommendedArticle",
    default: undefined,
})

export function useRecommendedArticles(article: Article): RecommendedArticles {
    const listId = "recommended-articles"
    const queryClient = useQueryClient()

    const vehicle = useVehicle()
    const workTaskId = useWorkTaskId()!

    const { enableRecommendedArticles } = useArticleListConfiguration()
    const { listType } = useListParamsContext()
    const isVehicleDependent = listType === "vehicle"

    const partsViewSettings = useRecoilValue(PartsViewSettingsState)
    const quantitySuggestionEnabled = useMemo(() => partsViewSettings.quantitySuggestionEnabled, [partsViewSettings?.quantitySuggestionEnabled])

    const { reset, setRequest } = usePureArticleLists()
    const [referenceArticle, setReferenceArticle] = useRecoilState(RecommendedArticleState)

    const [isLoading, setIsLoading] = useState(false)

    const isEnabled = useMemo(
        () => enableRecommendedArticles && isVehicleDependent && referenceArticle === article,
        [enableRecommendedArticles, isVehicleDependent, referenceArticle, article.id]
    )
    const loadArticles = useCallback(
        async (newArticle: Article) => {
            // get the data directly async without mutating the state and do rerender hell
            const workTaskBasket = await getCachedWorkTaskBasketData(queryClient, workTaskId, true)

            const request = createRecommendedArticlesRequest(workTaskBasket?.parts, newArticle, vehicle!)

            if (!request) {
                return undefined
            }

            const recommendedArticlesOut = await queryClient.fetchQuery({
                queryKey: [listId, request],
                queryFn: () => Articles.getRecommendedArticles(request),
            })

            if (!quantitySuggestionEnabled || !recommendedArticlesOut?.length) {
                return recommendedArticlesOut
            }

            return recommendedArticlesOut.map((recommendedArticle) => {
                if (!recommendedArticle.suggestedQuantity) {
                    return recommendedArticle
                }

                return {
                    ...recommendedArticle,
                    quantity: recommendedArticle.suggestedQuantity,
                    initialQuantity: recommendedArticle.suggestedQuantity,
                }
            })
        },
        [queryClient, vehicle, workTaskId, quantitySuggestionEnabled]
    )

    const loadRecommendedArticles = useCallback(async () => {
        if (!enableRecommendedArticles || !isVehicleDependent || !vehicle) {
            return
        }

        setReferenceArticle(undefined)

        const recommendedArticlesOut = await loadArticles(article)

        if (recommendedArticlesOut?.length) {
            setRequest(listId, { articles: recommendedArticlesOut })
            setReferenceArticle(article)
        }
    }, [enableRecommendedArticles, isVehicleDependent, vehicle, setReferenceArticle, loadArticles, setRequest])

    const loadNextRecommendedArticle = useCallback(
        async (newArticle: Article) => {
            if (!enableRecommendedArticles || !isVehicleDependent || !vehicle) {
                return
            }

            setIsLoading(true)

            const recommendedArticlesOut = await loadArticles(newArticle)

            if (recommendedArticlesOut?.length) {
                setRequest(listId, { articles: recommendedArticlesOut })
            }

            setIsLoading(false)
        },
        [enableRecommendedArticles, isVehicleDependent, loadArticles, setRequest, vehicle, setIsLoading]
    )

    const onClose = useCallback(() => {
        setReferenceArticle(undefined)
        reset(listId)
    }, [reset, setReferenceArticle])

    return useMemo(
        () => ({ isEnabled, isLoading, loadRecommendedArticles, loadNextRecommendedArticle, onClose, ListComponent: PureList }),
        [isEnabled, isLoading, loadRecommendedArticles, loadNextRecommendedArticle, onClose, PureList]
    )
}
