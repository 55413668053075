import { useLocalization } from "@tm/localization"
import { Article, AvailabilityComponentProps, channel, DefaultArticleItemState, ErpInformation, SupplierArticleDto } from "@tm/models"
import { copyToClipboard } from "@tm/utils"
import { isEqual } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDmsArticleInfo } from "../../../../../../dms/src/hooks/useDmsArticleInfo"
import AvailabilityComponent from "../../../../../../erp/src/_shared/availability-wrapper"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import {
    useActiveVehicleDataProvidersContext,
    useBasketQuantitiesContext,
    useErpInfosContext,
    useListOptionsContext,
    useListParamsContext,
    usePreviouslyOrderedArticlesContext,
    useProductGroupRepairTimesDataContext,
    useSupplierLogosContext,
} from "../../ContextProvider"
import { useArticleListActionsContext } from "../../ContextProvider/ArticleListActions"
import { useFiltersContext } from "../../ContextProvider/Filters"
import { useVehicleRecordsContext } from "../../VehicleParts/ContextProvider"
import { isAddButtonDisabled } from "../../helpers"
import { useArticleActions } from "../useArticleActions"
import { useArticleImages } from "../useArticleImages"
import { useArticleSelection } from "../useArticleSelection"
import { useLoadErpInfos } from "../useErpInfos"
import { useGetRepairTimesUrl } from "../useGetRepairTimesUrl"
import { useHandleClickAttribute } from "../useHandleClickAttribute"
import { useHandleClickReference } from "../useHandleClickReference"
import { useVehicle } from "../useVehicle"
import { useArticleExtendedDeliveryInfo } from "./useArticleExtendedDeliveryInfo"
import { useArticleFoundBy } from "./useArticleFoundBy"
import { useArticleNotes } from "./useArticleNotes"
import { useArticleNotifications } from "./useArticleNotifications"
import { useArticleOptions } from "./useArticleOptions"
import { useArticleTradeReferences } from "./useArticleTradeReferences"
import { useArticleErpInfos } from "./useArticlesErpInfos"
import { useDisplayArticle } from "./useDisplayArticle"
import { useHandleAddToBasket } from "./useHandleAddToBasket"
import { useHandleAddToCostEstimation } from "./useHandleAddToCostEstimation"
import { useHandleClickAvailability } from "./useHandleClickAvailability"
import { useHandleClickDetails } from "./useHandleClickDetails"
import { useHandleClickImage } from "./useHandleClickImage"
import { useHandleRemoveFromBasket } from "./useHandleRemoveFromBasket"
import { useRecommendedArticles } from "./useRecommendedArticles"
import { useTraderErpInfos } from "./useTraderErpInfos"
import { useWatchlist } from "./useWatchlist"

export function useArticleItem(article: Article): DefaultArticleItemState {
    const { toggleInlineFilter, updateBasketQuantities, toggleSupplier: toggleArticleNotes, showArticleDetails } = useArticleListActionsContext()
    const {
        listType,
        attributes,
        startParams: { replaceButtonBundle },
    } = useListParamsContext()
    const previouslyOrderedArticles = usePreviouslyOrderedArticlesContext()

    const { translateText } = useLocalization()

    const options = useArticleOptions(article)

    const [expanded, setExpanded] = useState(!options.compactView)
    const [traderSelection, setTraderSelection] = useState(false)

    useEffect(() => {
        setExpanded(!options.compactView)
    }, [options.compactView])

    const toggleExpand = useCallback(() => {
        setExpanded((prev) => !prev)
    }, [])

    const toggleTraderSelection = useCallback(() => {
        setTraderSelection((prev) => !prev)
    }, [])

    const positionChangeEArtNrHArtNr = useArticleListConfiguration((x) => x.positionChangeEArtNrHArtNr)
    const imsIcon = useArticleListConfiguration((x) => x.imsIcon)
    const memoTooltipIcon = useArticleListConfiguration((x) => x.memoTooltipIcon)
    const isVehicleDependent = listType === "vehicle"
    const erpInfosData = useErpInfosContext()
    const { basketQuantities } = useBasketQuantitiesContext()
    const vehicleRecords = useVehicleRecordsContext(false)
    const vehicle = useVehicle()
    const [articleQuantity, setArticleQuantity] = useState(article.quantity || 1)
    const [originalQuantity, setOriginalQuantity] = useState(article.quantity || 1)
    const articleErpInfos = useArticleErpInfos(article, articleQuantity, erpInfosData)
    const defaultArticleErpInfoResponse = articleErpInfos.default?.state === "success" ? articleErpInfos.default.response : undefined
    const handleClickDetails = useHandleClickDetails(article, articleQuantity)
    const handleClickAvailability = useHandleClickAvailability(article, articleQuantity, erpInfosData)
    const { handleClickImage, handleCloseImage, showImageViewer } = useHandleClickImage(article)
    const handleClickAttribute = useHandleClickAttribute(article, handleClickDetails, toggleInlineFilter)
    const handleClickReference = useHandleClickReference(article, articleQuantity, showArticleDetails)
    const addToBasket = useHandleAddToBasket(article)
    const removeFromBasket = useHandleRemoveFromBasket()
    const articleImages = useArticleImages(article)
    const tradeReferences = useArticleTradeReferences(article)
    const loadErpInfos = useLoadErpInfos(erpInfosData, tradeReferences)
    const traderErpInfos = useTraderErpInfos(article, erpInfosData, tradeReferences)
    const notifications = useArticleNotifications(articleErpInfos.default)
    const watchList = useWatchlist(article)
    const foundBy = useArticleFoundBy(article)
    const selection = useArticleSelection()

    const { hideExtendedDeliveryComponent, feedbackConfiguration, enableArticleNotes } = useArticleListConfiguration()
    const extendedDeliveryInfo = useArticleExtendedDeliveryInfo(!hideExtendedDeliveryComponent, articleErpInfos.default)

    const basketQuantity = useMemo(
        () =>
            basketQuantities.find((q) =>
                isEqual(q.article, {
                    dataSupplierArticleNumber: article.supplierArticleNo,
                    dataSupplierId: article.supplier.id,
                    productGroupId: article.productGroup.id,
                } as SupplierArticleDto)
            ),
        [basketQuantities, article]
    )

    // Notes --------------------------------
    const notes = useArticleNotes(article)
    // --------------------------------------

    const alreadyOrdered = useMemo(() => {
        return (
            previouslyOrderedArticles?.some((x) => x.supplierArticleNumber === article.supplierArticleNo && x.supplierId === article.supplier.id) ??
            false
        )
    }, [previouslyOrderedArticles, article.supplierArticleNo, article.supplier.id])

    // CostEstimation -----------------------
    const { activeProviders } = useActiveVehicleDataProvidersContext()
    const { externalCatalogUrl } = useListOptionsContext()
    const productGroupRepairTimes = useProductGroupRepairTimesDataContext()
    const repairTimeProviders = productGroupRepairTimes.productGroupRepairTimes?.productGroups[article.productGroup.id]

    const addButtonsDisabled = useMemo(() => isAddButtonDisabled(articleErpInfos), [articleErpInfos])

    const addToKva = useHandleAddToCostEstimation(
        article,
        addToBasket,
        activeProviders,
        externalCatalogUrl,
        repairTimeProviders,
        basketQuantity?.isInOrder
    )
    // --------------------------------------

    const getRepairTimesUrl = useGetRepairTimesUrl()
    const repairTimesUrl = useMemo(() => {
        if (!!repairTimeProviders?.length && repairTimeProviders) {
            return getRepairTimesUrl(article, repairTimeProviders)
        }
    }, [repairTimeProviders, getRepairTimesUrl, article])

    const { showDocumentsInline } = useListOptionsContext()

    const dmsArticleInfo = useDmsArticleInfo(article)

    // Recommended Articles ------------------
    const recommendedArticles = useRecommendedArticles(article)

    const articleActions = useArticleActions({
        article,
        articleErpInfo: articleErpInfos.default,
        feedbackConfiguration,
        isNotesEnabled: enableArticleNotes,
        listType,
        notes: notes.notes,
        repairTimesUrl,
        showDocumentsInline,
        onClickDetails: handleClickDetails,
        toggleExpand,
        toggleNotes: notes.toggle,
    })

    // Supplier data ----------------------
    const { dataSupplierFilters } = useFiltersContext()
    const isSupplierSelected = useMemo(
        () => dataSupplierFilters.find((x) => x[0].id === article.supplier.id)?.[1] ?? false,
        [article.supplier.id, dataSupplierFilters]
    )
    const { supplierLogos } = useSupplierLogosContext()
    const supplierLogo = useMemo(() => supplierLogos?.[article.supplier.id], [supplierLogos, article.supplier.id])
    // ------------------------------------

    const displayArticle = useDisplayArticle(article, attributes, supplierLogo, isSupplierSelected)

    const postAddToBasket = useCallback(async () => {
        updateBasketQuantities?.([article])
        await vehicleRecords?.handleArticleAddedToBasket(article)
        vehicleRecords?.refresh()
    }, [article, vehicleRecords, updateBasketQuantities])

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setArticleQuantity(value)

            if (loadErpInfo) {
                setOriginalQuantity(value)
                if (articleErpInfos.default) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.default.erpSystem })
                }
                if (articleErpInfos.alternative) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.alternative.erpSystem })
                }
            }
        },
        [article, loadErpInfos, articleErpInfos]
    )

    const handleAddToBasket = useCallback(
        async (quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number) => {
            if (!erpSystemId && tradeReferences.length > 1) {
                toggleTraderSelection()
                return
            }

            recommendedArticles.loadRecommendedArticles()
            await addToBasket(
                quantity ?? articleQuantity,
                erpInformation ?? defaultArticleErpInfoResponse,
                erpSystemId ?? tradeReferences[0]?.erpSystemId
            )
            postAddToBasket()
            handleChangeQuantity(article.quantity)
        },
        [
            addToBasket,
            article.quantity,
            articleQuantity,
            defaultArticleErpInfoResponse,
            handleChangeQuantity,
            postAddToBasket,
            toggleTraderSelection,
            tradeReferences,
            recommendedArticles.loadRecommendedArticles,
        ]
    )

    const handleReplaceArticle = useCallback(() => {
        if (!replaceButtonBundle) {
            return
        }
        channel("GLOBAL").publish("PARTS/REPLACE_PART", { part: article, isNewList: true, bundle: replaceButtonBundle })
    }, [article, replaceButtonBundle])

    const handleAddToCostEstimation = useCallback(
        async (quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number) => {
            if (!erpSystemId && (tradeReferences.length ?? 0) > 1) {
                toggleTraderSelection()
                return
            }
            await addToKva(quantity ?? articleQuantity, erpInformation ?? defaultArticleErpInfoResponse, erpSystemId)
            postAddToBasket()
            handleChangeQuantity(article.quantity)
        },
        [
            addToKva,
            article.quantity,
            articleQuantity,
            defaultArticleErpInfoResponse,
            handleChangeQuantity,
            postAddToBasket,
            toggleTraderSelection,
            tradeReferences.length,
        ]
    )

    const handleRemoveFromBasket = useCallback(
        async (itemIds?: string[]) => {
            const ids = itemIds ?? basketQuantity?.articleQuantities?.allPartItemIds
            if (ids?.length) {
                await removeFromBasket(ids)
                updateBasketQuantities?.([article])
            }
        },
        [basketQuantity, removeFromBasket, updateBasketQuantities, article]
    )

    const handleCopyArticleNumber = useCallback(
        (number: string) => {
            copyToClipboard(number)
            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1920) })
        },
        [translateText]
    )

    const toggleSupplierFilter = useCallback(
        (exclusive?: boolean) => {
            toggleArticleNotes?.(article.supplier.id, exclusive)
        },
        [toggleArticleNotes, article.supplier.id]
    )

    const AvailabilityComponentIntern = useCallback(
        (props: AvailabilityComponentProps) => (
            <AvailabilityComponent {...props} erpSystem={erpInfosData.erpConfig?.useOrderByDistributor ? props.erpSystem : undefined} />
        ),
        [erpInfosData.erpConfig?.useOrderByDistributor]
    )

    const showExtendedAvailabilityContainer =
        !!erpInfosData.erpConfig?.erpSystemConfigs && erpInfosData.erpConfig?.erpSystemConfigs.filter((x) => x.useForGetErpInformation).length > 1

    return {
        type: "default",
        isVehicleDependent,
        options,
        article,
        displayArticle,
        quantity: articleQuantity,
        originalQuantity,
        articleActions,
        articleErpInfos,
        basketQuantity,
        notes,
        recommendedArticles,
        tradeReferences,
        traderErpInfos,
        notifications,
        expanded,
        traderSelection,
        watchList,
        showReplaceButton: !!replaceButtonBundle,
        positionChangeEArtNrHArtNr,
        foundBy,
        selection,
        extendedDeliveryInfo,
        vehicle,
        showImageViewer,
        articleImages,
        imsIcon,
        memoTooltipIcon,
        dmsArticleInfo,
        alreadyOrdered,
        showExtendedAvailabilityContainer,
        addToCostEstimationButtonDisabled: addButtonsDisabled,
        addToBasketButtonDisabled: addButtonsDisabled,

        handleClickImage,
        handleCloseImage,
        handleClickDetails,
        handleChangeQuantity,
        handleClickReference,
        handleClickAttribute: listType !== "direct" ? handleClickAttribute : undefined,
        handleAddToBasket,
        handleAddToCostEstimation,
        handleRemoveFromBasket,
        handleReplaceArticle,
        handleCopyArticleNumber,
        handleClickAvailability,
        toggleExpand,
        toggleTraderSelection,
        toggleSupplierFilter,

        AvailabilityComponent: AvailabilityComponentIntern,
    }
}
