import { CatalogPart, ErpInformation } from "@tm/models"
import { clone } from "@tm/utils"
import { Part } from "../../../../data/models"
import { BasicErpInfo, UpdateTotalPricesRequest } from "../../../../data/repositories/updateTotalPrices"
import { CalculationState } from "../model"

function mapBasicErpInfo(erpInfo: ErpInformation): BasicErpInfo {
    const { dataSupplierArticleNumber, prices } = erpInfo

    return {
        dataSupplierArticleNumber,
        prices,
    }
}

function mapPartCatalogPart(part: Part, quantity: number): CatalogPart {
    return {
        dataSupplierArticleNumber: part.dataSupplierArticleNumber,
        dataSupplierId: part.dataSupplierId,
        dataSupplierName: part.dataSupplierName,
        productGroupId: part.productGroupId,
        productGroupName: part.productGroupName,
        quantityValue: quantity,
    }
}

export function createUpdateTotalsRequest(state: CalculationState, erpInfo?: ErpInformation[]): UpdateTotalPricesRequest {
    const alternatives: CatalogPart[] = []
    const items = clone(state.items)

    // TODO: alternative articles don't have support for quantity
    items?.forEach((x) => x.isSelected && !!x.selectedPart && alternatives.push(mapPartCatalogPart(x.selectedPart, x.oeArticle.quantityValue)))

    const selectedItems = items.filter((x) => x.isSelected && !x.selectedPart)

    return {
        oeArticles: selectedItems.map((x) => x.oeArticle),
        alternatives,
        works: state.works?.filter((x) => x.isSelected),
        basicErpInfo: erpInfo?.map(mapBasicErpInfo),
    }
}
