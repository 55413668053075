import { useCallback, useEffect } from "react"
import { useRecoilState } from "recoil"
import { GetProductGroupTopicIdsRequest, RepairTimeProvider } from "@tm/models"
import { getProductGroupTopicIds } from "../../../../../../repair-times/src/data/repositories/technical-data"
import { ProductGroupTopicIdsState } from "../../states"
import { useVehicle } from "../../hooks/useVehicle"
import { getBundleParams } from "../../../../utils"

export function useProductGroupTopicIds(productGroupIds: number[], isEnabled: boolean) {
    const vehicle = useVehicle()
    const { haynesProTdGenartsRoute, hasToolRental } = getBundleParams()
    const [productGroupTopicIds, setProductGroupTopicIds] = useRecoilState(ProductGroupTopicIdsState(vehicle?.id))

    const loadTopicIds = useCallback(
        async (ids: number[]) => {
            if (!ids.length || !vehicle || (!haynesProTdGenartsRoute && !hasToolRental)) {
                return
            }
            const request: GetProductGroupTopicIdsRequest = {
                modelId: vehicle.tecDocTypeId,
                vehicleType: vehicle.vehicleType,
                repairTimeProvider: [
                    ...(haynesProTdGenartsRoute ? [RepairTimeProvider.HaynesProCar] : []),
                    ...(hasToolRental ? [RepairTimeProvider.MobilityMarketToolRental] : []),
                ],
                productGroupIds: ids,
            }
            const response = await getProductGroupTopicIds(request)
            setProductGroupTopicIds((state) => ({ ...state, ...response }))
        },
        [hasToolRental, haynesProTdGenartsRoute, setProductGroupTopicIds, vehicle]
    )

    useEffect(() => {
        if (!isEnabled) {
            return
        }
        const idsToLoad = productGroupIds.filter((id) => !productGroupTopicIds[id])
        if (idsToLoad.length) {
            loadTopicIds(idsToLoad)
        }
    }, [productGroupIds, loadTopicIds, productGroupTopicIds, isEnabled])

    return { productGroupTopicIds }
}
