import { Box, Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { TyreFilter } from "@tm/models"
import { getIconByGroup, getTitleByGroup } from "@tm/utils"
import { IListFilters } from "../business"
import { Button } from "@tm/controls"
import { AvailabilityFilter } from "."

type Props = {
    seasons?: TyreFilter[]
    handleFilterChange: (filter: TyreFilter | AvailabilityFilter | null, path?: IListFilters) => void
    selectedFilters: TyreFilter[] | undefined
}

export const SeasonComponent = ({ seasons, handleFilterChange, selectedFilters }: Props) => {
    const { translateText } = useLocalization()

    return (
        <Box display="flex" flexWrap="wrap">
            {seasons?.map((season) => (
                <Box margin=".2em" key={season.query}>
                    <Tooltip title={translateText(getTitleByGroup(season.query))}>
                        <Button
                            icon={getIconByGroup(season.query)}
                            key={season.query}
                            fakeButton
                            isActive={!!selectedFilters?.find(x => x.query == season?.query)}
                            onClick={() => handleFilterChange(season, "season")}
                            className="filter"
                        />
                    </Tooltip>
                </Box>
            ))}
        </Box>
    )
}