import { VoucherType } from "@tm/models"
import { BasketButton } from "@tm/components"
import { VoucherPart } from "@tm/data/vouchers/costEstimations"
import { isArray } from "lodash"
import { ExtendedOrderVoucherItem } from "../../../../../../../data/model"
import { useBasketButtonActions } from "../../../../../business/hooks/useBasketButtonActions"

type Props = {
    workTaskId: string
    voucherType: VoucherType
    disabled: boolean
    voucherParts?: VoucherPart[] | VoucherPart
    orderedItems?: ExtendedOrderVoucherItem[] | ExtendedOrderVoucherItem
    currencyCode?: string
    costEstimationVehicleId?: string
}

export default function BasketButtonComponent(props: Props) {
    const { workTaskId, disabled, voucherType, voucherParts, orderedItems, currencyCode, costEstimationVehicleId } = props
    const { basketInfos, addToBasket, removeFromBasket } = useBasketButtonActions(
        workTaskId,
        voucherType,
        voucherParts,
        orderedItems,
        currencyCode,
        costEstimationVehicleId
    )
    return (
        <BasketButton
            size="small"
            onAddToBasket={addToBasket}
            onRemoveFromBasket={removeFromBasket}
            basketQuantity={!isArray(orderedItems) || !isArray(voucherParts) ? basketInfos?.quantityValue : undefined}
            disabled={disabled}
        />
    )
}
