import { Article, CustomArticle, CustomPart } from "@tm/models"
import { fastServiceStore } from "../.."
import { addOrRemoveItem } from "../../../helpers/addOrRemoveItem"
import { CustomWork, DefectGenart, Genart, PanelState, RecordCustomWorks, RecordWorks, RepairTime, Work } from "../../models"
import { convertCustomWorks } from "../../../helpers/convertCustomWorks"
import { genartComparatorWithoutQuantity } from "../../../helpers/genartComparatorWithoutQuantity"
import { getInitialState } from "."

export function resetWorks() {
    fastServiceStore.setState(getInitialState(), false, "Reset Works Slice")
}

export function updateAllWorks(works: RecordWorks) {
    const state = fastServiceStore.getState()

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                works,
            },
        },
        false,
        "Update all works"
    )
}
export function updateCustomWorks(customWorks: RecordCustomWorks) {
    fastServiceStore.setState(
        (state) => ({
            worksState: {
                ...state.worksState,
                customWorks,
            },
        }),
        false,
        "Update custom works"
    )
}

export function updateCustomWorkField(customWorks: CustomWork[], customWorkId: number, customWorkState: PanelState) {
    const newCustomWorks = [...customWorks]
    newCustomWorks[customWorkId] = { ...newCustomWorks[customWorkId], isCompleted: customWorkState !== PanelState.None, panelState: customWorkState }
    const newCustomWorksRecord = convertCustomWorks(newCustomWorks)

    fastServiceStore.setState(
        (state) => ({
            worksState: {
                ...state.worksState,
                customWorks: newCustomWorksRecord,
            },
        }),
        false,
        "Update custom work field"
    )
}

export function updateSelectedGenarts(item: Genart, path: string, stepName: string) {
    const state = fastServiceStore.getState()
    const { selectedItems } = state.worksState
    const { selectedGenArts } = selectedItems?.[stepName]?.[path] || {}
    let newValue: Genart[] = []

    const isInArray = [...(selectedGenArts || [])].some((x) => genartComparatorWithoutQuantity(x, item))

    if (isInArray) {
        newValue = [...(selectedGenArts || [])].filter((x) => !genartComparatorWithoutQuantity(x, item))
    }
    if (!newValue.length) {
        newValue.push(item)
    }

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                selectedItems: {
                    ...selectedItems,
                    [stepName]: {
                        ...selectedItems[stepName],
                        [path]: {
                            ...selectedItems[stepName]?.[path],
                            selectedGenArts: newValue,
                        },
                    },
                },
            },
        },
        false,
        "Update selected genarts"
    )
}

export function updateSelectedRepairtimes(item: RepairTime, path: string, stepName: string) {
    const state = fastServiceStore.getState()
    const { selectedItems } = state.worksState
    const { selectedRepairTimes } = selectedItems?.[stepName]?.[path] || {}
    const newValue = addOrRemoveItem([...(selectedRepairTimes || [])], item)

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                selectedItems: {
                    ...selectedItems,
                    [stepName]: {
                        ...selectedItems[stepName],
                        [path]: {
                            ...selectedItems[stepName]?.[path],
                            selectedRepairTimes: newValue,
                        },
                    },
                },
            },
        },
        false,
        "Update selected repair times"
    )
}

export function updateSelectedLubricant(item: string, path: string, stepName: string) {
    const state = fastServiceStore.getState()
    const { selectedItems } = state.worksState

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                selectedItems: {
                    ...selectedItems,
                    [stepName]: {
                        ...selectedItems[stepName],
                        [path]: {
                            ...selectedItems[stepName]?.[path],
                            selectedLubricant: item,
                        },
                    },
                },
            },
        },
        false,
        "Update selected lubricants"
    )
}

export function updateSavedLubricant(item: string, path: string, stepName: string) {
    const state = fastServiceStore.getState()
    const { savedItemLubricants } = state.worksState

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                savedItemLubricants: {
                    [stepName]: {
                        ...savedItemLubricants[stepName],
                        [path]: {
                            ...savedItemLubricants[stepName]?.[path],
                            selectedLubricant: item,
                        },
                    },
                },
            },
        },
        false,
        "Update saved lubricants"
    )
}

export function setDefaultNextPanel(stepName: string) {
    const state = fastServiceStore.getState()
    const { works, selectedItems } = state.worksState
    if (!works || !works[stepName]) {
        return
    }

    const firstWorkSelectionStatus = selectedItems[stepName]?.[works[stepName][0]?.label]?.selectedStatusButton

    if (firstWorkSelectionStatus && firstWorkSelectionStatus !== "none") {
        return
    }

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                nextPanel: works[stepName][0],
            },
        },
        false,
        "Set default next panel"
    )
}

export function updateWorkField(workField: { key: string; value: string | number; isLastWork?: boolean }, path: string, stepName: string) {
    const state = fastServiceStore.getState()
    const { selectedItems, works } = state.worksState
    const { key, value, isLastWork } = workField
    const selectedKeyItems = Object.keys(selectedItems[stepName] || {}).filter((x) => selectedItems[stepName][x].selectedStatusButton !== "none")
    const nextPanel =
        value === "defectButton" || value === "none" || key === "safetyStatus" || key === "comment"
            ? works[stepName]?.find((x) => x.label === path)
            : getNextPanel(
                  works[stepName]?.findIndex((x) => x.label === path),
                  selectedKeyItems,
                  works[stepName]
              )

    if (isLastWork) {
        fastServiceStore.setState(
            {
                worksState: {
                    ...state.worksState,
                    nextPanel,
                },
            },
            false,
            "Update work field"
        )
    }

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                nextPanel,
                selectedItems: {
                    ...selectedItems,
                    [stepName]: {
                        ...selectedItems[stepName],
                        [path]: {
                            ...selectedItems[stepName]?.[path],
                            [key]: value,
                        },
                    },
                },
            },
        },
        false,
        "Update work field"
    )
}

export function updateArticleQuantity(path: string, stepName: string, quantity: number, id: string) {
    const state = fastServiceStore.getState()
    const { works, selectedItems } = state.worksState
    const newWorks: Work[] = works[stepName].map((x) => ({
        ...x,
        genArts: x.genArts.map((y) => ({ ...y, ...(y.article && { ...(y.article.id === id && { article: { ...y.article, quantity } }) }) })),
    }))

    const updatedSelectedItems = {
        ...selectedItems,
        [stepName]: {
            ...selectedItems[stepName],
            [path]: {
                ...selectedItems[stepName]?.[path],
                selectedGenArts: (selectedItems[stepName]?.[path]?.selectedGenArts || []).map((genArt) =>
                    genArt.article?.id === id ? { ...genArt, article: { ...genArt.article, quantity } } : genArt
                ),
            },
        },
    }

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                works: {
                    ...works,
                    [stepName]: [...(newWorks || [])],
                },
                selectedItems: updatedSelectedItems,
            },
        },
        false,
        "Update article quantity"
    )
}

export function updateOeArticle(path: string, stepName: string, quantity: number, price: number, designation: string, id: string, oeNumber: string) {
    const state = fastServiceStore.getState()
    const { works, selectedItems } = state.worksState
    const newWorks: Work[] = works[stepName]?.map((x) => ({
        ...x,
        genArts: x.genArts?.map((y) => ({
            ...y,
            ...(y.fsArticle && {
                ...(y.fsArticle.id === id && {
                    fsArticle: { ...y.fsArticle, quantity, price, oeLabel: designation, oeNrs: [oeNumber] },
                }),
            }),
        })),
    }))

    const updatedSelectedItems = {
        ...selectedItems,
        [stepName]: {
            ...selectedItems[stepName],
            [path]: {
                ...selectedItems[stepName]?.[path],

                selectedGenArts: (selectedItems[stepName]?.[path]?.selectedGenArts || []).map((genArt) =>
                    genArt.fsArticle?.id === id
                        ? { ...genArt, fsArticle: { ...genArt.fsArticle, quantity, price, oeLabel: designation, oeNrs: [oeNumber] } }
                        : genArt
                ),
            },
        },
    }

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                works: {
                    ...works,
                    [stepName]: [...(newWorks || [])],
                },
                selectedItems: updatedSelectedItems,
            },
        },
        false,
        "Update OE article"
    )
}

export function replaceConsumable(stepName: string, consumableId: string, selectedConsumableArticle: CustomArticle) {
    const state = fastServiceStore.getState()
    const { works } = state.worksState

    const newWorks = works[stepName].map((work) => {
        if (work.consumables.find((c) => c.id === consumableId)) {
            const consumables = work.consumables.map((consumable) => {
                const consumableArticle = consumable?.article
                if (consumableArticle && consumable.id === consumableId) {
                    if (consumableArticle) {
                        consumableArticle.genArtLabel = selectedConsumableArticle.description
                        consumableArticle.id = selectedConsumableArticle.id
                        consumableArticle.hArtNr = selectedConsumableArticle.articleNumber
                        consumableArticle.supplierArtNr = selectedConsumableArticle.articleNumber
                        consumableArticle.price = selectedConsumableArticle.retailPrice
                        if (consumable.quantity && selectedConsumableArticle.retailPrice) {
                            consumableArticle.totalPrice = consumable.quantity * selectedConsumableArticle.retailPrice
                        } else {
                            consumableArticle.totalPrice = 0
                        }

                        return {
                            ...consumable,
                            article: consumableArticle,
                        }
                        // consumable.article = consumableArticle
                    }
                }

                return consumable
            })

            return {
                ...work,
                consumables,
            }
        }

        return { ...work }
    })

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                works: {
                    ...works,
                    [stepName]: [...(newWorks || [])],
                },
            },
        },
        false,
        "Replace consumabable"
    )
}

export function replaceArticle(path: string, stepName: string, id: string, newArticle: Article, oeReplacement?: boolean, genArt?: Genart) {
    const state = fastServiceStore.getState()
    const { selectedItems, works } = state.worksState
    const { selectedArticles } = selectedItems[stepName]?.[path] ?? {}

    const articleIdToBeFiltered: Array<string> = []

    const newWorks = works[stepName].map((work) => {
        if (work.label === path) {
            let newGenarts: Genart[] = []
            if (work.genArts?.length > 0) {
                newGenarts = work.genArts.map((gA) => {
                    if (gA.fsArticle && gA.fsArticle?.id === id) {
                        if (gA.article) {
                            // eslint-disable-next-line no-param-reassign
                            newArticle.quantity = gA.article.quantity
                            articleIdToBeFiltered.push(gA.article.id)
                        }

                        if (oeReplacement) {
                            gA.oeReplace = true
                        }
                        // eslint-disable-next-line no-param-reassign
                        gA.article = newArticle

                        return gA
                    }

                    if (genArt) {
                        if (gA.article) {
                            newArticle.quantity = gA.article.quantity
                            articleIdToBeFiltered.push(gA.article.id)
                            return { genArtNr: genArt.genArtNr, label: genArt.label, article: newArticle, oeReplace: oeReplacement }
                        }

                        return { genArtNr: genArt.genArtNr, label: genArt.label, article: newArticle, oeReplace: oeReplacement }
                    }

                    return gA
                })
            } else if (genArt) {
                newGenarts = [{ genArtNr: genArt.genArtNr, label: genArt.label, article: newArticle, oeReplace: oeReplacement }]
            }

            return {
                ...work,
                genArts: newGenarts,
            }
        }

        return work
    })

    const filteredArticles = selectedArticles ? selectedArticles.filter((a) => !articleIdToBeFiltered.includes(a.id)) : []
    const newArticleList = addOrRemoveItem([...filteredArticles], newArticle)

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                selectedItems: {
                    ...selectedItems,
                    [stepName]: {
                        ...selectedItems[stepName],
                        [path]: {
                            ...selectedItems[stepName]?.[path],
                            selectedArticles: newArticleList,
                        },
                    },
                },
                works: {
                    ...works,
                    [stepName]: [...(newWorks || [])],
                },
            },
        },
        false,
        "Replace article"
    )
}

export function setSelectedArticles(stepName: string, path: string, articles: Article[]) {
    const state = fastServiceStore.getState()
    const { selectedItems } = state.worksState

    fastServiceStore.setState({
        worksState: {
            ...state.worksState,
            selectedItems: {
                ...selectedItems,
                [stepName]: {
                    ...selectedItems[stepName],
                    [path]: {
                        ...selectedItems[stepName]?.[path],
                        selectedArticles: articles,
                    },
                },
            },
        },
    })
}

export function removeCustomArticle(stepName: string, path: string, internalIdentifier: string) {
    const state = fastServiceStore.getState()
    const { selectedItems } = state.worksState
    const { selectedCustomParts } = selectedItems[stepName]?.[path] ?? {}

    if (!selectedCustomParts) {
        return
    }

    const selectedCustomArticleList = [...selectedCustomParts]

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                selectedItems: {
                    ...selectedItems,
                    [stepName]: {
                        ...selectedItems[stepName],
                        [path]: {
                            ...selectedItems[stepName]?.[path],
                            selectedCustomParts: selectedCustomArticleList.filter((a) => a.internalIdentifier === internalIdentifier),
                        },
                    },
                },
            },
        },
        false,
        "Set update selected custom part quantity"
    )
}

export function setUpdateSelectedArticles(stepName: string, path: string, article: Article) {
    const state = fastServiceStore.getState()
    const { selectedItems } = state.worksState
    const { selectedArticles } = selectedItems[stepName]?.[path] ?? {}

    if (!selectedArticles) {
        return
    }

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                selectedItems: {
                    ...selectedItems,
                    [stepName]: {
                        ...selectedItems[stepName],
                        [path]: {
                            ...selectedItems[stepName]?.[path],
                            selectedArticles: addOrRemoveItem([...selectedArticles], article),
                        },
                    },
                },
            },
        },
        false,
        "Set update selected articles"
    )
}

export function setUpdateArticleQuantity(stepName: string, path: string, article: Article, quanity: number) {
    const state = fastServiceStore.getState()
    const { selectedItems } = state.worksState
    const { selectedArticles } = selectedItems[stepName]?.[path] ?? {}

    if (!selectedArticles) {
        return
    }

    const selectedArticleList = [...selectedArticles]
    const changedArticle = selectedArticleList.find((a) => a.id === article.id)
    if (changedArticle) {
        changedArticle.quantity = quanity
    }

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                selectedItems: {
                    ...selectedItems,
                    [stepName]: {
                        ...selectedItems[stepName],
                        [path]: {
                            ...selectedItems[stepName]?.[path],
                            selectedArticles: selectedArticleList,
                        },
                    },
                },
            },
        },
        false,
        "Set update selected article quantity"
    )
}

export function setUpdateSelectedCustomPart(
    stepName: string,
    path: string,
    customArticle: CustomPart,
    internalIdentifier: string,
    isChecked: boolean
) {
    const state = fastServiceStore.getState()
    const { selectedItems } = state.worksState
    const { selectedCustomParts } = selectedItems[stepName]?.[path] ?? {}

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                selectedItems: {
                    ...selectedItems,
                    [stepName]: {
                        ...selectedItems[stepName],
                        [path]: {
                            ...selectedItems[stepName]?.[path],
                            selectedCustomParts: addOrRemoveItem([...(selectedCustomParts ?? [])], {
                                ...customArticle,
                                internalIdentifier,
                                isChecked,
                            }),
                        },
                    },
                },
            },
        },
        false,
        "Set update selected articles"
    )
}

export function setUpdateCustomPart(
    stepName: string,
    path: string,
    internalIdentifier: string,
    articleNumber?: string,
    quanity?: number,
    price?: number,
    description?: string,
    isChecked?: boolean
) {
    const state = fastServiceStore.getState()
    const { selectedItems } = state.worksState
    const { selectedCustomParts } = selectedItems[stepName]?.[path] ?? {}

    if (!selectedCustomParts) {
        setUpdateSelectedCustomPart(
            stepName,
            path,
            { description: description ?? "", articleNumber, quantityValue: quanity ?? 1, retailPrice: price },
            internalIdentifier,
            isChecked ?? false
        )
        return
    }

    const selectedCustomArticleList = [...selectedCustomParts]
    const changedArticle = selectedCustomArticleList.find((a) => a.internalIdentifier === internalIdentifier)
    if (changedArticle) {
        if (articleNumber !== undefined) {
            changedArticle.articleNumber = articleNumber
        }

        if (quanity !== undefined) {
            changedArticle.quantityValue = quanity
        }

        if (price !== undefined) {
            changedArticle.retailPrice = price
        }

        if (description !== undefined) {
            changedArticle.description = description
        }

        if (isChecked !== undefined) {
            changedArticle.isChecked = isChecked
        }

        fastServiceStore.setState(
            {
                worksState: {
                    ...state.worksState,
                    selectedItems: {
                        ...selectedItems,
                        [stepName]: {
                            ...selectedItems[stepName],
                            [path]: {
                                ...selectedItems[stepName]?.[path],
                                selectedCustomParts: selectedCustomArticleList,
                            },
                        },
                    },
                },
            },
            false,
            "Set update selected custom part quantity"
        )
    }
}

export function setUpdateStatusGenart(stepName: string, path: string, status: number, id: string) {
    const state = fastServiceStore.getState()
    const { selectedItems } = state.worksState
    const { defectGenarts } = selectedItems[stepName]?.[path] ?? {}

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                selectedItems: {
                    ...selectedItems,
                    [stepName]: {
                        ...selectedItems[stepName],
                        [path]: {
                            ...selectedItems[stepName]?.[path],
                            defectGenarts: defectGenarts?.map((defect) => ({ ...defect, ...(defect.id === id && { status, id }) })),
                        },
                    },
                },
            },
        },
        false,
        "Set update status genart"
    )
}

export function setUpdateEndPageDefects(stepName: string, path: string, genArt: Genart, id: string) {
    const state = fastServiceStore.getState()
    const { selectedItems } = state.worksState
    const { defectGenarts } = selectedItems[stepName]?.[path] ?? {}

    const item = { ...genArt, status: 0, id }

    const comparator = (x: DefectGenart, y: DefectGenart) => x.id === y.id && x.genArtNr === y.genArtNr && x.binKrit100 === y.binKrit100
    const newItems = addOrRemoveItem([...(defectGenarts ?? [])], item, comparator)

    fastServiceStore.setState(
        {
            worksState: {
                ...state.worksState,
                selectedItems: {
                    ...selectedItems,
                    [stepName]: {
                        ...selectedItems[stepName],
                        [path]: {
                            ...selectedItems[stepName]?.[path],
                            defectGenarts: newItems,
                        },
                    },
                },
            },
        },
        false,
        "Set update end page defects"
    )
}

const getNextPanel = (currentIndex: number, selectedItems: string[], works: Work[]) => {
    for (let i = currentIndex + 1; i <= works.length; i++) {
        if (works[i] && !selectedItems.includes(works[i]?.label)) {
            return works[i]
        }
    }

    for (let y = 0; y <= currentIndex; y++) {
        if (!selectedItems.includes(works[y].label)) {
            return works[y]
        }
    }

    return works[0]
}
