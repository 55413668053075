import { useUser, useWorkTask } from "@tm/context-distribution"
import {
    CatalogPart,
    CustomPart,
    ExternalPart,
    OePart,
    PostSendOrderAction,
    PostSendOrderActionTargetType,
    PostSendOrderActionType,
    WholesalerPart,
} from "@tm/models"
import { useCallback } from "react"
import { BasketPartPostMessage } from "../models"
import {
    mapCatalogpartToPostMessagePart,
    mapCustomPartToPostMessagePart,
    mapExternalPartToPostMessagePart,
    mapOePartToPostMessagePart,
    mapWholsalerPartToPostMessagePart,
} from "./mapper"

const SOURCEID = "NEXT"

export function useCreateBasketPartPostMessage() {
    const { userContext } = useUser()
    const { ExternalSessionId: externalSessionId } = userContext.externalAuthenticationTokens

    const { workTask } = useWorkTask() ?? {}
    const { vehicle } = workTask ?? {}

    const getCreateSendArticlesPostMessage = useCallback(
        (articles: BasketPartPostMessage[], workTaskId: string): PostSendOrderAction[] => {
            return [
                {
                    action: PostSendOrderActionType.PostMessage,
                    target: PostSendOrderActionTargetType.ParentFrame,
                    value: JSON.stringify({
                        sourceId: SOURCEID,
                        externalSessionId,
                        sendArticles: {
                            workTaskId,
                            articles,
                        },
                    }),
                },
            ]
        },
        [externalSessionId]
    )

    const getMessageByWholsalerPart = useCallback(
        (parts: WholesalerPart[], workTaskId: string) => {
            const basketParts = parts.map((part) => mapWholsalerPartToPostMessagePart(part, vehicle))
            return getCreateSendArticlesPostMessage(basketParts, workTaskId)
        },
        [getCreateSendArticlesPostMessage, vehicle]
    )

    const getMessageByCatalogPart = useCallback(
        (parts: CatalogPart[], workTaskId: string) => {
            const basketParts = parts.map((part) => mapCatalogpartToPostMessagePart(part, vehicle))
            return getCreateSendArticlesPostMessage(basketParts, workTaskId)
        },
        [getCreateSendArticlesPostMessage, vehicle]
    )

    const getMessageByOePart = useCallback(
        (parts: OePart[], workTaskId: string) => {
            const basketParts = parts.map((part) => mapOePartToPostMessagePart(part))
            return getCreateSendArticlesPostMessage(basketParts, workTaskId)
        },
        [getCreateSendArticlesPostMessage]
    )

    const getMessageByCustomPart = useCallback(
        (parts: CustomPart[], workTaskId: string) => {
            const basketParts = parts.map((part) => mapCustomPartToPostMessagePart(part))
            return getCreateSendArticlesPostMessage(basketParts, workTaskId)
        },
        [getCreateSendArticlesPostMessage]
    )

    const getMessageByExternalPart = useCallback(
        (parts: ExternalPart[], workTaskId: string) => {
            const basketParts = parts.map((part) => mapExternalPartToPostMessagePart(part))
            return getCreateSendArticlesPostMessage(basketParts, workTaskId)
        },
        [getCreateSendArticlesPostMessage]
    )

    return { getMessageByCatalogPart, getMessageByCustomPart, getMessageByOePart, getMessageByWholsalerPart, getMessageByExternalPart }
}
