import { Article, ArticleErpInfo, AvailabilityStatus, TyreArticle } from "@tm/models"
import { ArticleErpInfoFactory } from "../../ListV2/hooks"

export function filterArticlesByAvailability(
    articles: TyreArticle[],
    erpData: ArticleErpInfo[],
    erpSystemId: number,
    showAvailable: boolean,
    showAvailableSecondary: boolean,
    availabilityStatusIdsToShow: AvailabilityStatus[],
    availabilityStatusIdsToShowSecondary: AvailabilityStatus[] | undefined
): TyreArticle[] {
    return articles.filter((article) => {
        const erpInfo = getArticleErpInfo(article, erpData, erpSystemId)

        if (erpInfo?.state !== "success") {
            return false
        }

        const availabilityType = erpInfo.response.availability.type
        if (showAvailable && availabilityStatusIdsToShow) {
            return availabilityType && availabilityStatusIdsToShow.includes(availabilityType)
        }
        if (showAvailableSecondary && availabilityStatusIdsToShowSecondary) {
            return availabilityType && availabilityStatusIdsToShowSecondary.includes(availabilityType)
        }

        return true
    })
}

function getArticleErpInfo(article: Article, erpData: ArticleErpInfo[], erpSystemId: number): ArticleErpInfo | undefined {
    return erpData.find((info) => info.key.id === ArticleErpInfoFactory.createKeyFromArticle(article, erpSystemId).id)
}
