import { useState, Suspense, useEffect, useCallback, FC } from "react"
import { useUser, useCostEstimationModuleParameter } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { AddWholesalerPartListRequest, ChangeItemsResponse, channel, ConfigParams, ConfirmationDialogKeys, ECounterType } from "@tm/models"
import { Box, Icon, Button, Typography, LinkButton, ConfirmationDialog } from "@tm/components"
import Morpheus from "@tm/morpheus"
import { TmaHelper } from "@tm/utils"
import PurchasePriceSwitch from "../../../../../../settings/src/components/user-settings/components/PurchasePriceSwitch"
import ImportFileComponent from "./ImportFileButton"
import { getBundleParams } from "../../../../utils"
import { BoxWithAlignContent } from "../../../StyledComponents"

type Props = {
    costEstimationRoute: string
    detailsIsActive: boolean
    hasParts: boolean
    basketLoading: boolean
    partsIsActive: boolean
    partsUrl: string
    showCopyToClipboardButton?: boolean
    loadingWorkTaskOrderPdf?: boolean
    onCostEstimationButtonClick(): void
    onCopyPartsNumberAndQuantityToClipboard(): void
    onPartsWarningConfirm(): void
    onPrintButtonClick(): void
    onAddWholesalerArticlesToBasket(request: AddWholesalerPartListRequest, skipResponseHandling?: boolean): Promise<ChangeItemsResponse | undefined>
}

const BasketNavigation: FC<Props> = (props: Props) => {
    const {
        hasParts,
        partsUrl,
        detailsIsActive,
        partsIsActive,
        costEstimationRoute,
        basketLoading,
        loadingWorkTaskOrderPdf,
        showCopyToClipboardButton,
    } = props

    const { translateText, languageId } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { deleteButtonColorError, articleDetailsInModal } = Morpheus.getParams<ConfigParams>()

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [fromShortcut, setFromShortcut] = useState(false)
    const { hasCostEstimation } = useCostEstimationModuleParameter()
    const { generalTermsAndconditionsUrl, nextLight, hideCostEstimationButton, hideGeneralTermsAndconditionsButton } = getBundleParams()

    const showCostEstimationButton = hasCostEstimation && !hideCostEstimationButton

    function handleDeleteDialogOpenStateChange() {
        setShowDeleteDialog((prev) => !prev)
        TmaHelper.GeneralCountEvent.Call(ECounterType.DeleteBasket)
    }

    useEffect(() => {
        channel("APP").subscribe("SHORTCUTS", (rez) => {
            if (rez.key === "delete") {
                handleDeleteDialogOpenStateChange()
                setFromShortcut(true)
            }
        })
    }, [hasParts])

    function deleteCurrentPosition() {
        props.onPartsWarningConfirm()
        handleDeleteDialogOpenStateChange()
    }

    function closeDeleteDialog() {
        setFromShortcut(false)
        handleDeleteDialogOpenStateChange()
    }

    const warningListener = useCallback(
        (e: KeyboardEvent) => {
            if (showDeleteDialog) {
                e.preventDefault()
                e.stopPropagation()
                if (e.key === "n" || e.key === "N") {
                    setShowDeleteDialog(false)
                    setFromShortcut(false)
                } else if (e.key === "y" || e.key === "Y") {
                    deleteCurrentPosition()
                    setFromShortcut(false)
                }
            }
        },
        [showDeleteDialog]
    )

    useEffect(() => {
        document.addEventListener("keydown", warningListener)
        return () => {
            document.removeEventListener("keydown", warningListener)
        }
    }, [warningListener])

    function handleOpenGeneralTermsAndconditions() {
        window.open(generalTermsAndconditionsUrl ?? translateText(832), "_blank")
    }

    return (
        <BoxWithAlignContent>
            <Typography variant="h1" mr={2}>
                {translateText(291)}
            </Typography>
            {!articleDetailsInModal && (
                <Toolbar title={translateText(176)}>
                    <LinkButton to={partsUrl} color={partsIsActive ? "primary" : undefined} startIcon={<Icon name="menu" />}>
                        {translateText(6)}
                    </LinkButton>
                    <Button disabled={!detailsIsActive} color={detailsIsActive ? "primary" : undefined} startIcon={<Icon name="details" />}>
                        {translateText(43)}
                    </Button>
                </Toolbar>
            )}
            <Toolbar title={translateText(177)}>
                <>
                    <Button
                        disabled={!hasParts || basketLoading}
                        onClick={handleDeleteDialogOpenStateChange}
                        startIcon={<Icon name="delete" />}
                        color={deleteButtonColorError ? "error" : undefined}
                        title={translateText(69)}
                    />
                    <ConfirmationDialog
                        open={showDeleteDialog}
                        confirmationButtonText={translateText(585) + (fromShortcut ? " (y)" : "")}
                        cancelButtonText={translateText(584) + (fromShortcut ? " (n)" : "")}
                        onConfirm={deleteCurrentPosition}
                        onCancel={closeDeleteDialog}
                        confirmationKey={ConfirmationDialogKeys.DeleteAllBasketItems}
                    >
                        <Typography>{translateText(835)}</Typography>
                    </ConfirmationDialog>
                </>
                {showCopyToClipboardButton && (
                    <Button
                        startIcon={<Icon name="copy" />}
                        onClick={props.onCopyPartsNumberAndQuantityToClipboard}
                        title={translateText(13435)}
                        disabled={!hasParts}
                    />
                )}
                <Button
                    startIcon={<Icon name="print" />}
                    disabled={!hasParts || basketLoading || loadingWorkTaskOrderPdf}
                    onClick={() => {
                        props.onPrintButtonClick()
                        TmaHelper.GeneralCountEvent.Call(ECounterType.PrintBasket)
                    }}
                    title={translateText(49)}
                />
                <ImportFileComponent disabled={basketLoading} onAddWholesalerArticlesToBasket={props.onAddWholesalerArticlesToBasket} />
            </Toolbar>
            <PurchasePriceSwitch showLabelOnTop toolbarSx={{ paddingLeft: 1 }} />
            {costEstimationRoute && !nextLight && showCostEstimationButton && (
                <Toolbar title={translateText(31)}>
                    <Button
                        id="tk-basket__navigation--add-button"
                        startIcon={<Icon name={languageId === "1" ? "voucher-add-kva" : "voucher-add-kva-international"} />}
                        onClick={() => {
                            props.onCostEstimationButtonClick()
                            TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimate)
                        }}
                        disabled={userContext?.parameter.catalogLight}
                    >
                        {translateText(1607)}
                    </Button>
                </Toolbar>
            )}
            {!hideGeneralTermsAndconditionsButton && (
                <Box display="flex" alignItems="flex-end" ml={1} mb={0.25}>
                    <Button onClick={handleOpenGeneralTermsAndconditions}>{translateText(831)}</Button>
                </Box>
            )}
        </BoxWithAlignContent>
    )
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <BasketNavigation {...props} />
        </Suspense>
    )
}
