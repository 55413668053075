import { useUser, useCostEstimationModuleParameter } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useParams } from "react-router"
import { TmaHelper, renderRoute } from "@tm/utils"
import { ReactNode, useState } from "react"
import { Icon, LinkButton, Stack, Typography } from "@tm/components"
import { CostEstimationOptions, ECounterType } from "@tm/models"
import { CostEstimationCalculation } from "../../../../data/model"
import CostEstimationTotalsTable from "./CostEstimationTotalsTable"
import { SummaryRouteProps } from "../../component"
import { LoaderSmall, StyledBox, SummaryPopover } from "../../../StyledComponents"
import ErrorButton from "../../../_shared/ErrorButton"

import { useSummaryTheme } from "../../hooks/useSummaryTheme"

type Props = {
    workTaskId: string
    costEstimationCalculation?: CostEstimationCalculation
    costEstimationOptions?: CostEstimationOptions
    repairTimeDivision: number
    currencyCode: string
    costEstimationRoute: string
    showRepairTimesInHours: boolean
    hasErrors: boolean
    isLoading: boolean
    hideHours?: boolean
    swapTotals?: boolean
    additionalErrorTextId?: number
}

export default function CostEstimationTotals(props: Props) {
    const {
        currencyCode,
        repairTimeDivision,
        costEstimationCalculation,
        costEstimationRoute,
        hasErrors,
        isLoading,
        swapTotals,
        hideHours,
        showRepairTimesInHours,
        costEstimationOptions,
        additionalErrorTextId,
        workTaskId,
    } = props

    const { translateText, translate, currency, languageId, number } = useLocalization()
    const { totalsB2B, totalsB2C } = costEstimationCalculation || {}
    const { priceVatMode, customerGroupMode } = costEstimationOptions || {}
    const { userContext } = useUser() ?? {}
    const [costEstimationPopoverEl, setCostEstimationPopoverEl] = useState<HTMLDivElement>()
    const { hasCostEstimation } = useCostEstimationModuleParameter()
    const matchParams = useParams<SummaryRouteProps>()
    const { linkButtonFontWeight, linkButtonFontFamily, contrastColor, linkButtonSize, linkButtonBorderRadius, linkButtonVariant } = useSummaryTheme()

    if (!hasCostEstimation) {
        return null
    }

    const repairTimeTotal = totalsB2B?.repairTimeTotal ?? totalsB2C?.repairTimeTotal
    const offerPrice = totalsB2C ? totalsB2C?.offerPriceVatIncluded : totalsB2B?.offerPrice

    function getCostEstimationUrl(): string {
        return renderRoute(costEstimationRoute, { ...matchParams })
    }

    const logo = languageId === "1" ? "voucher-kva-summary" : "voucher-kva-international-summary"
    const repairTimesUnits = showRepairTimesInHours || repairTimeDivision === 1 ? ` ${translateText(60)}` : ""
    const disabled = userContext?.parameter.catalogLight
    let repairTimesButtonContent: ReactNode = number(0, 2) + repairTimesUnits
    let costEstimationButtonContent: ReactNode = currency(0, currencyCode)

    if (hasErrors) {
        repairTimesButtonContent = ""
        costEstimationButtonContent = (
            <ErrorButton costEstimationWithErrors workTaskIds={[workTaskId]} additionalErrorTextId={additionalErrorTextId} />
        )
    } else if (isLoading) {
        repairTimesButtonContent = <LoaderSmall />
        costEstimationButtonContent = <LoaderSmall />
    } else {
        repairTimesButtonContent = number(repairTimeTotal ?? 0, 2) + repairTimesUnits
        costEstimationButtonContent = `${currency(offerPrice ?? 0, currencyCode)}*`
    }

    return (
        <Stack direction={swapTotals ? "row-reverse" : "row"} paddingRight={1} alignItems="center">
            {!hideHours && (
                <LinkButton
                    sx={{
                        borderRadius: 0,
                        border: 0,
                        fontWeight: linkButtonFontWeight,
                        fontFamily: linkButtonFontFamily,
                    }}
                    variant={linkButtonVariant}
                    startIcon={<Icon name="repairtimes-summary" color={contrastColor} />}
                    title={hasErrors ? "" : translateText(83)}
                    to={getCostEstimationUrl()}
                    onClick={() => {
                        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimate)
                    }}
                    disabled={disabled}
                    size={linkButtonSize || "extralarge"}
                >
                    {repairTimesButtonContent}
                </LinkButton>
            )}

            <div
                onMouseEnter={(event) => {
                    setCostEstimationPopoverEl(event.currentTarget)
                }}
                onMouseLeave={() => {
                    setCostEstimationPopoverEl(undefined)
                }}
            >
                <LinkButton
                    sx={{
                        borderRadius: linkButtonBorderRadius || 0,
                        fontWeight: linkButtonFontWeight,
                        fontFamily: linkButtonFontFamily,
                    }}
                    variant={linkButtonVariant}
                    startIcon={<Icon name={logo} color={contrastColor} />}
                    to={getCostEstimationUrl()}
                    onClick={() => {
                        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimate)
                    }}
                    disabled={disabled}
                    size={linkButtonSize || "extralarge"}
                >
                    <Stack gap={0.5} direction="row" alignItems="center">
                        {costEstimationButtonContent}
                        {!hasErrors && !isLoading && (
                            <Typography variant="label" textTransform="none" display="inline-block" color={contrastColor}>
                                ({translate(customerGroupMode && !priceVatMode ? 694 : 13668)})
                            </Typography>
                        )}
                    </Stack>
                </LinkButton>

                {costEstimationCalculation && !hasErrors && (
                    <SummaryPopover open={!!costEstimationPopoverEl} anchorEl={costEstimationPopoverEl}>
                        <StyledBox>
                            <CostEstimationTotalsTable
                                costEstimationCalculation={costEstimationCalculation}
                                costEstimationOptions={costEstimationOptions}
                                currencyCode={currencyCode}
                                showRepairTimesInHours={showRepairTimesInHours}
                            />
                        </StyledBox>
                    </SummaryPopover>
                )}
            </div>
        </Stack>
    )
}
