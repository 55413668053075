import { useUser, useWorkTask, useBasketModuleParameter, useCostEstimationModuleParameter } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { channel, getCurrentWorkTaskId } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, barcodeToastState, RouteComponentProps, uniqueId, withRouter } from "@tm/utils"
import { useSetRecoilState } from "recoil"
import { Button, Dialog, Icon, Stack, styled, Typography } from "@tm/components"
import { FC, useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router"
import { getBundleParams } from "../../utils"

type Shortcut = {
    text: number | string
    usable: boolean
}

const StyledShortcutsDialog = styled(Dialog)(({ theme }) => ({
    [`& .MuiBox-root:first-child`]: {
        padding: theme.spacing(1.5),
    },
}))

type Props = RouteComponentProps<void>

const Shortcuts: FC<Props> = ({ history }) => {
    const [dialogActive, setDialogActive] = useState(false)
    const location = useLocation()

    const user = useUser()
    const hasVehicle = !!useWorkTask()?.workTask?.vehicle?.id
    const { translateText } = useLocalization()
    const { hasCommercialVehicles } = Morpheus.getParams("vehicle").moduleAvailabilities
    const setBarcodeToast = useSetRecoilState(barcodeToastState)
    const { hasWorkTaskOrder } = useBasketModuleParameter()
    const { disabledCostEstimation } = useCostEstimationModuleParameter()

    const renderShortcutText = (item: string | number): string => (typeof item === "string" ? item : translateText(item))

    const getDmsName = (): string => {
        // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
        const isDMSConnected = (window as any)?.__DMS_MESSAGEBUS__?.channel("CONNECTION").last(1, "CONNECTION_STATUS_RECEIVED")[0]
            ?.content?.isConnected
        if (!isDMSConnected) {
            return "DMS"
        }
        // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
        return (window as any).__dmsInfo?.name ?? JSON.parse(localStorage.getItem("DMS_INFO") ?? "{}")?.name ?? translateText(1875)
    }

    const includesPath = (currentPath: string): boolean => {
        return location.pathname.includes(currentPath)
    }

    const dmsText = translateText(12922).replace("DMS", getDmsName())

    const infos: Shortcut[] = [
        { text: 12574, usable: true },
        { text: dmsText, usable: true },
        { text: 12576, usable: true },
        { text: 12577, usable: !hasVehicle && hasCommercialVehicles },
        { text: 12578, usable: true },
        { text: 12579, usable: true },
    ]

    if (hasWorkTaskOrder) {
        infos.push({ text: 12580, usable: true })
    }

    if (!disabledCostEstimation) {
        infos.push({ text: 12581, usable: true })
    }

    if (hasWorkTaskOrder) {
        infos.push({ text: 12582, usable: true })
    }

    if (!(!getCurrentWorkTaskId() || typeof tmJSEvents === "undefined" || !tmJSEvents || !tmJSEvents.onExportShoppingBasket)) {
        infos.push({ text: 13171, usable: true })
    }

    if (
        includesPath("/parts/universal") ||
        includesPath("/parts/direct") ||
        includesPath("/parts/vehicles") ||
        includesPath("/basket") ||
        includesPath("/usersettings") ||
        includesPath("/tyres/list") ||
        includesPath("/wheels")
    ) {
        infos.unshift({ text: 13756, usable: true })
    }

    const redirectTo = useCallback(
        (url?: string) => {
            let urlToPush = `/${encodeUniqueId(getCurrentWorkTaskId() || uniqueId())}/`
            if (url) {
                urlToPush += url
            }
            history.push(urlToPush)
        },
        [history]
    )

    const closeOrOpenDialog = () => {
        setDialogActive((prev) => !prev)
    }

    useEffect(() => {
        const handleKey = (key: string) => {
            // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
            const isDMSConnected = (window as any)?.__DMS_MESSAGEBUS__?.channel("CONNECTION").last(1, "CONNECTION_STATUS_RECEIVED")[0]
                ?.content?.isConnected

            switch (key) {
                case "i": // info
                    closeOrOpenDialog()
                    break
                case "0": // Alt + 0 this shortcut is also used in parts for purchase-price-switch
                    user.setUserSetting("SHOW_PURCHASE_PRICE", !user.userSettings?.showPurchasePrice)
                    break
                case "1": // new tab
                    history.push(`/${encodeUniqueId(uniqueId())}`) // includes new tab
                    break
                case "2": // open dms(StakisProfi) if available
                    if (isDMSConnected) {
                        redirectTo("dms")
                    } else {
                        history.push(`/usersettings/dms`)
                    }
                    break
                case "3": // dashboard
                    redirectTo()
                    channel("APP").publish("SHORTCUTS", { key: "PKW" })
                    break
                case "4": // dashboard focus nkw
                    if (hasCommercialVehicles && !hasVehicle) {
                        redirectTo()
                        channel("APP").publish("SHORTCUTS", { key: "NKW" })
                    }
                    break
                case "5": // dashboard focus universal
                    redirectTo()
                    channel("APP").publish("SHORTCUTS", { key: "UNI" })
                    break
                case "6": // dashboard focus article search
                    redirectTo()
                    channel("APP").publish("SHORTCUTS", { key: "DIRECT" })
                    break
                case "7": // basket
                    if (hasWorkTaskOrder) {
                        redirectTo("basket")
                    }
                    break
                case "8": // kva
                    if (!disabledCostEstimation) {
                        !getBundleParams().nextLight && redirectTo("cost-estimation")
                    }
                    break
                case "9": // delete basket
                    if (hasWorkTaskOrder) {
                        if (!window.location.href.includes("basket")) {
                            redirectTo("basket")
                        }
                        channel("APP").publish("SHORTCUTS", { key: "delete" })
                    }
                    break
                case "x":
                    channel("APP").publish("SHORTCUTS", { key: "dms-voucher" })
                    break
                default:
                    break
            }
        }

        const onkeydown = (e: KeyboardEvent) => {
            if (e.altKey) {
                e.preventDefault()
                handleKey(e.key)
            }

            if (e.ctrlKey && e.key === "b") {
                e.preventDefault()
                setBarcodeToast((prev) => ({ show: !prev.show }))
            }
        }

        const disableEmoticons = (event: KeyboardEvent) => {
            // this symbols should not be displayed
            if ((event.code === "AltLeft" || event.code === "AltRight") && ["☺", "☻", "♥", "♦", "♣", "♠", "•", "◘", "○"].includes(event.key)) {
                event.preventDefault()
                event.stopPropagation()
            }
        }

        const handleMvcShortcuts = (event: MessageEvent) => {
            const {
                data: { sendShortCutKeys },
            } = event
            if (sendShortCutKeys) {
                handleKey(sendShortCutKeys)
            }
        }

        document.addEventListener("keydown", onkeydown)
        document.addEventListener("keypress", disableEmoticons)
        window.addEventListener("message", handleMvcShortcuts) // get shortcuts from mvc as well
        return () => {
            document.removeEventListener("keydown", onkeydown)
            document.removeEventListener("keypress", disableEmoticons)
            window.removeEventListener("message", handleMvcShortcuts)
        }
    }, [hasCommercialVehicles, hasVehicle, history, redirectTo, setBarcodeToast, user])

    return (
        <StyledShortcutsDialog open={dialogActive} onOutsideClick={closeOrOpenDialog}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
                <Stack direction="row" alignItems="center" columnGap={1}>
                    <Icon name="info" size="25px" color="primary" />
                    <Typography variant="h2">{translateText(12601)}</Typography>
                </Stack>
                <Button onClick={closeOrOpenDialog} startIcon={<Icon name="close" />} />
            </Stack>
            {infos.map((info: Shortcut) => {
                return (
                    <div className="shortcut" key={info.text}>
                        <Typography padding={1.5} borderTop="1px solid #d3d3d3">
                            {renderShortcutText(info.text)}
                        </Typography>
                    </div>
                )
            })}
        </StyledShortcutsDialog>
    )
}
export default withRouter(Shortcuts)
