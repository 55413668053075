import { createContext, useContext } from "use-context-selector"
import { useMemo } from "react"
import { useTradeReferenceContext } from "../../../ContextProvider"
import { ErpInfosProviderProps } from "../../../ContextProvider/ErpInfos"
import { ensureContextAvailability, mapWholesalerPartToArticle } from "../../../helpers"
import { ErpInfosData } from "../../../models"
import { useFiltersAndResultsContext } from "../FiltersAndResults"
import { useWholeSalerArticleNumberListParamsContext } from "../ListParams"
import { useErpInfos } from "../../../ContextProvider/ErpInfos/useErpInfos"

export const ErpInfosContext = createContext<ErpInfosData | undefined>(undefined)

export function WholesalerErpInfosProvider({ isEnabled, erpInfos, setErpInfos, children }: ErpInfosProviderProps) {
    const params = useWholeSalerArticleNumberListParamsContext()
    const { results: articlesData, wholesalerNosArticles } = useFiltersAndResultsContext()
    const { tradeReferences, tradeReferenceNumbersLoaded } = useTradeReferenceContext()

    const erpArticles = useMemo(() => {
        const wholesalerToArticle = wholesalerNosArticles.map((x) => mapWholesalerPartToArticle(x))
        return [...articlesData.articles, ...wholesalerToArticle]
    }, [articlesData.articles, wholesalerNosArticles])

    const erpInfosData = useErpInfos({
        articles: erpArticles,
        isEnabled: isEnabled && tradeReferenceNumbersLoaded,
        startParams: params.startParams,
        erpInfos,
        setErpInfos,
        tradeReferences,
    })

    return <ErpInfosContext.Provider value={erpInfosData}>{children}</ErpInfosContext.Provider>
}

export function useWholesalerErpInfosContext(): ErpInfosData {
    const context = useContext(ErpInfosContext)
    return ensureContextAvailability("ErpInfosContext", context)
}
