import { useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { ErpPriceDictionary, PriceType } from "@tm/models"
import { useLocalization } from "@tm/localization"

export function useBasketErpTotalInOrder(erpTotalsInOrder?: ErpPriceDictionary) {
    const { translate, currency } = useLocalization()

    const { userSettings } = useUser() || {}
    const showPurchasePrice = userSettings?.showPurchasePrice ?? true

    const [taxText, totalPriceAsCurrency] = useMemo(() => {
        const calculatedPrices = erpTotalsInOrder ? Object.values(erpTotalsInOrder) : []
        const purchasePrice = calculatedPrices.find((price) => price?.type === PriceType.Purchase)
        const retailPrice =
            calculatedPrices.find((price) => price?.type === PriceType.Retail) ??
            calculatedPrices.find((price) => price?.type === PriceType.RecommendedRetail)

        const totalPrice = showPurchasePrice ? purchasePrice : retailPrice
        const priceAsCurrency = totalPrice ? currency(totalPrice.value, totalPrice.currencyCode_Iso_4217 || totalPrice.currencySymbol) : "-"
        const priceTaxText = totalPrice ? translate(totalPrice?.taxIncluded ? 13668 : 694) : ""

        return [priceTaxText, priceAsCurrency]
    }, [currency, erpTotalsInOrder, showPurchasePrice, translate])

    return { taxText, totalPriceAsCurrency }
}
