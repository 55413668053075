/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { getValue } from "@tm/utils"
import { ShowCarModelDetailsResponse, Vehicle } from "@tm/models"
import { addOrRemoveItem } from "../../../helpers/addOrRemoveItem"
import { getInitialState } from "."
import { CalcState, FastServiceSelection, MaintenanceData, SelectionKeys } from "../../models"
import { fastServiceStore } from "../.."
import { mapWorkTimes } from "./helpers"
import { resetStore } from "../manager/actions"

export function resetMaintenancePlan() {
    fastServiceStore.setState(getInitialState(), false, "Reset maintenance plan slice")
}

export function deleteSelectionKey(selectionKey: SelectionKeys) {
    const state = fastServiceStore.getState()
    const temp = { ...state.maintenancePlan.selectionsToSend }
    delete temp?.[selectionKey]

    fastServiceStore.setState(
        {
            maintenancePlan: {
                ...state.maintenancePlan,
                selectionsToSend: temp,
            },
        },
        false,
        "Delete selection key"
    )
}

export function setUpdateSelections(selectionKey: SelectionKeys, path: string, value: string) {
    const state = fastServiceStore.getState()
    const previousValues = getValue(state.maintenancePlan.selectionsToSend?.[selectionKey]!, [path])
    const newValue = addOrRemoveItem(previousValues, value)

    const previousUserSelections = getValue(state.maintenancePlan.userSelections?.[selectionKey]!, [path])
    const newUserSelections = addOrRemoveItem(previousUserSelections, value)

    fastServiceStore.setState(
        {
            maintenancePlan: {
                ...state.maintenancePlan,
                selectionsToSend: {
                    ...state.maintenancePlan.selectionsToSend,
                    [selectionKey]: {
                        ...state.maintenancePlan.selectionsToSend?.[selectionKey],
                        [path]: newValue,
                    },
                },
                userSelections: {
                    ...state.maintenancePlan.userSelections,
                    [selectionKey]: {
                        ...state.maintenancePlan.userSelections?.[selectionKey],
                        [path]: newUserSelections,
                    },
                },
            },
        },
        false,
        "Update selection key"
    )
}

export function setMaintenanceData(maintenanceData: MaintenanceData) {
    const { selections, additionalWorkSelections, followupWorkSelections, recordWorks, results, tireSpecifications } = maintenanceData

    const workTimes = mapWorkTimes(results)

    const selectedService = maintenanceData.selections.find((x) => x.id === "haynesProMaintenancePeriod")?.items.filter((x) => x.isSelected) || []

    const selectionS = getSelectedSelectionsFromWS(selections, SelectionKeys.Selections)
    const selectionAdditionalWorks = additionalWorkSelections
        ? getSelectedSelectionsFromWS(additionalWorkSelections, SelectionKeys.AdditionalWorks)
        : {}
    const selectionFollowUps = followupWorkSelections ? getSelectedSelectionsFromWS(followupWorkSelections, SelectionKeys.FollowUps) : {}

    fastServiceStore.setState(
        (state) => ({
            maintenancePlan: {
                ...state.maintenancePlan,
                maintenancePlanLoading: false,
                selections,
                additionalWorks: additionalWorkSelections,
                followupWorks: followupWorkSelections,
                moreInfo: undefined,
                works: recordWorks,
                workTimes,
                selectedService,
                tireSpecifications,
                userSelections: { ...selectionS, ...selectionAdditionalWorks, ...selectionFollowUps },
            },
        }),
        false,
        "Set maintenance data"
    )
}

export function setMaintenanceDataLoading() {
    fastServiceStore.setState(
        (state) => ({
            maintenancePlan: {
                ...state.maintenancePlan,
                maintenancePlanLoading: true,
            },
        }),
        false,
        "Set maintenance data loading"
    )
}

export function resetSelections() {
    fastServiceStore.setState(
        (state) => ({
            maintenancePlan: {
                ...state.maintenancePlan,
                selections: [],
                additionalWorks: [],
                followupWorks: [],
            },
        }),
        false,
        "Reset selections"
    )
}

export function setMaintenanceDataError() {
    fastServiceStore.setState(
        (state) => ({
            maintenancePlan: {
                ...state.maintenancePlan,
                maintenancePlanLoading: false,
            },
        }),
        false,
        "Set maintenance data error"
    )
}

export function updateVehicleField(path: string, value: any) {
    fastServiceStore.setState(
        (state) => ({
            maintenancePlan: {
                ...state.maintenancePlan,
                vehicleFields: {
                    ...state.maintenancePlan.vehicleFields,
                    [path]: value,
                },
            },
        }),
        false,
        "Update vehicle field"
    )
}

export function setVehicle(vehicle: Vehicle, modelDetailsResponse: ShowCarModelDetailsResponse, shouldReset?: boolean) {
    // When we set the vehicle ot modify data we should reset the store, as all the process is vehicle dependent!
    const { initialRegistration, plateId, vin, mileAge, engineCode, longlife } = vehicle
    const { engineCodes } = modelDetailsResponse.modelDetails || {}
    const { modelDetails } = modelDetailsResponse
    const minDate = modelDetails?.constructionYearFrom && new Date(modelDetails.constructionYearFrom.year, modelDetails.constructionYearFrom.month, 0)
    shouldReset && resetStore()

    fastServiceStore.setState(
        (state) => ({
            maintenancePlan: {
                ...state.maintenancePlan,
                stateVehicle: vehicle,
                modelDetails: modelDetailsResponse,
                vehicleFields: {
                    ...state.maintenancePlan.vehicleFields,
                    initialRegistration,
                    vin,
                    engineCode,
                    plateNumber: plateId,
                    mileage: mileAge,
                    engineCodes,
                    longLife: longlife,
                    minDate,
                },
            },
        }),
        false,
        "Set vehicle"
    )
}

export function setCalcState(calcState: CalcState) {
    fastServiceStore.setState(
        (state) => ({
            maintenancePlan: {
                ...state.maintenancePlan,
                calcState,
            },
        }),
        false,
        "Set calc state"
    )
}

export function setBaseCalculationId(baseCalculationCalcId: string) {
    fastServiceStore.setState(
        (state) => ({
            maintenancePlan: {
                ...state.maintenancePlan,
                baseCalculationCalcId,
            },
        }),
        false,
        "Set base calculation id"
    )
}

function getSelectedSelectionsFromWS(selections: FastServiceSelection[], selectionKey: SelectionKeys) {
    const multiSelections = selections.filter((s) => s.multiSelect)
    let selectedSelections: { [key: string]: { [key: string]: string[] } } = {}

    multiSelections.map((s) => {
        if (s.multiSelect) {
            s.items.map((i) => {
                if (i.isSelected) {
                    const previousValues = getValue(selectedSelections?.[selectionKey]!, [s.selectionId])
                    const newValue = addOrRemoveItem(previousValues, i.id)

                    selectedSelections = {
                        ...selectedSelections,
                        [selectionKey]: {
                            ...selectedSelections[selectionKey],
                            [s.selectionId]: newValue,
                        },
                    }
                }
            })
        }
    })

    return selectedSelections
}
