import { ArticleIdentifier, IMicros, OE } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { Box, NumberSelect, styled } from "@tm/components"
import { ReplaceButton } from "@tm/controls"
import { eOEArtSubType } from "../../../data/enums"
import { ExtendedOePart, Part } from "../../../data/models"
import { vehicleSelector } from "../../main/business"

type Props = {
    oeArticle: ExtendedOePart
    showAlternativeBtn?: boolean
    selectedPart?: Part
    isSelected?: boolean
    onQuantityChange(value: number): void
    onDetailsClick(part: Part): void
    onSelect(): void
    onAlternativeClick(): void
}

export default function OeArticleItem({
    oeArticle,
    showAlternativeBtn,
    onAlternativeClick,
    onDetailsClick,
    onQuantityChange,
    onSelect,
    isSelected,
    selectedPart,
}: Props) {
    const { renderMicro } = useMicro<IMicros>()
    const vehicle = useSelector(vehicleSelector)
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"

    const renderBasketButton = () => {
        let content = (
            <Box display="flex" gap="3%">
                <NumberSelect minAmount={0} value={oeArticle.quantityValue} onValueChange={onQuantityChange} />
                <ReplaceButton disabled={!showAlternativeBtn} onClick={onAlternativeClick} isWm={isWm} />
            </Box>
        )

        if (oeArticle.isExtraArticle) {
            content = <StyledAdditionalCostActions />
        }

        return <Box margin={`.1725em ${!isWm ? "auto" : ".1725em"} .1725em 0`}>{content}</Box>
    }

    const renderDefaultHeader = () => {
        const oePart: OE.OePart = {
            description: oeArticle.description,
            ...(!oeArticle.isExtraArticle && {
                manufacturerName: vehicle?.manufacturer,
            }),
            ...(oeArticle.isExtraArticle && {
                manufacturerName: "DAT",
                thumbnailUrl: "/styles/base/images/dat-logo.png",
            }),
            manufacturerId: vehicle?.tecDocManufacturerId,
            number: oeArticle.oeArticleNumber,
            prices: [
                {
                    value: oeArticle.oePriceValue ?? 0,
                    currency: "EUR",
                },
            ],
            quantity: oeArticle.quantityValue ?? 1,
        }

        const enableSelector =
            oeArticle.datProcessId !== eOEArtSubType.SurchargeOrDiscount ||
            (oeArticle.datProcessId === eOEArtSubType.SurchargeOrDiscount && !!oeArticle.oePriceValue && oeArticle.oePriceValue > 0)

        return (
            <Box sx={{ ".checkbox": { marginLeft: !isWm ? "1em" : "none" } }}>
                {renderMicro!("parts", "oe-part-item", {
                    oePart,
                    renderBasketButton,
                    hiddenFakeActions: {
                        costEstimation: true,
                        selector: enableSelector,
                        moreMenu: true,
                        details: true,
                    },
                    onArticleSelect: enableSelector ? onSelect : undefined,
                    isSelected,
                })}
            </Box>
        )
    }

    const renderAlternativeHeader = (selectedAlternativePart: Part) => {
        const art: ArticleIdentifier = {
            productGroupId: selectedAlternativePart.article?.productGroup.id ?? selectedAlternativePart.productGroupId,
            supplierArticleNo: selectedAlternativePart.article?.supplierArticleNo ?? selectedAlternativePart.dataSupplierArticleNumber,
            supplierId: selectedAlternativePart.article?.supplier.id ?? selectedAlternativePart.dataSupplierId,
            tecdocTypeNo: vehicle?.tecDocTypeId,
        }

        return (
            <>
                {renderMicro!("parts", "part-item", {
                    partToLoad: art,
                    renderBuyActions: renderBasketButton,
                    onRequestArticleDetails: () => onDetailsClick(selectedAlternativePart),
                    // hiddenFakeActions: { costEstimation: true, selector: true, moreMenu: true, details: true },
                    onArticleSelect: onSelect,
                    isSelected,
                    showActions: true,
                } as any)}
            </>
        )
    }

    if (selectedPart) {
        return renderAlternativeHeader(selectedPart)
    }

    return renderDefaultHeader()
}

const StyledAdditionalCostActions = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    width: "11.5em",
}))
