import { useUser } from "@tm/context-distribution"
import { ConfigParams, RegisteredModels } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { RouteComponentProps, createQueryString, encodeUniqueId, uniqueId, withRouter } from "@tm/utils"
import { useCallback, useEffect, useRef, useState } from "react"
import { AdTileType } from "./business/model"
import { useAdTiles } from "./business/useAdTiles"
import { className } from "./styles"

type RouteParams = {
    workTaskId: string
}

type Props = RouteComponentProps<RouteParams> & {
    prodUrl: string
    devUrl: string
}

const AdTileComponent = (props: Props) => {
    const { userContext } = useUser()

    const [token, setToken] = useState("")
    useEffect(() => {
        Container.getInstance(RegisteredModels.Authority)
            .action("getExternalToken")("Wm")
            .then((responses) => setToken(responses.token))
    }, [])

    const { env } = Morpheus.getParams<ConfigParams>()
    const serviceUrl = env === "dev" || env === "test" ? props.devUrl : props.prodUrl
    const { adTileItems } = useAdTiles(serviceUrl)
    const [tileIndex, setTileIndex] = useState(0)

    const hoverRef = useRef(false)

    useEffect(() => {
        setTileIndex(0)
    }, [adTileItems])

    const changeTileContent = useCallback(
        (change: number) => {
            setTileIndex((prev) => {
                let newTileIndex = prev + change
                if (newTileIndex >= adTileItems.length) {
                    newTileIndex = 0
                } else if (newTileIndex < 0) {
                    newTileIndex = adTileItems.length - 1
                }
                return newTileIndex
            })
        },
        [adTileItems.length]
    )

    useEffect(() => {
        const interval = setInterval(() => {
            if (!hoverRef.current) {
                changeTileContent(1)
            }
        }, 10000)

        return () => {
            clearInterval(interval)
        }
    }, [changeTileContent])

    function readParamVal(cplist: { paramname: string; paramvalue: string }[], param: string): string {
        return cplist.find((e) => e.paramname.toLowerCase() === param.toLowerCase())?.paramvalue || ""
    }

    function openWindowInNewTab(url: string) {
        window.open(url, "_blank")
    }

    function openUniSeachTree(nodeId: number) {
        // TODO: don't use postMessage!!!
        window.parent.postMessage(
            {
                openInternalModule: { module: `parts/universal/list/uninode?treeId=87&nodeId=${nodeId}`, newWorkTask: true },
            },
            "*"
        )
    }

    function openUniProductGroups(nodeId: number) {
        window.parent.postMessage(
            {
                openInternalModule: { module: `parts/universal/list/uniproductgroups?productGroupIds=${nodeId}`, newWorkTask: true },
            },
            "*"
        )
    }

    function searchParts(searchText: string) {
        // TODO: don't use postMessage!!!
        window.parent.postMessage({ searchOe: { searchOE: true, searchText } }, "*")
    }

    function doTileAction(contenttype: number, tile: AdTileType) {
        switch (contenttype) {
            case 30:
                openUniSeachTree(Number(readParamVal(tile.params, "GenArt")))
                break
            case 31:
                openUniProductGroups(Number(readParamVal(tile.params, "GenArt")))
                break
            case 35:
                searchParts("oc47")
                break
            case 40:
                openWindowInNewTab(readParamVal(tile.params, "URL"))
                break
            case 50:
                openWindowInNewTab("http://wmkat.de")
                break
            case 60:
                window.top?.postMessage({ openPromoPartsList: true }, "*")
                break
            case 70: {
                // Blätterkataloge
                if (userContext && userContext.account) {
                    const { customerNo } = userContext.account
                    const url = readParamVal(tile.params, "URL").replace("{0}", customerNo)
                    openWindowInNewTab(url)
                }

                break
            }
            case 75: {
                let client = ""
                if (userContext) {
                    const trader = userContext.externalAuthenticationTokens.WMPortalToken?.split("¬")[0]
                    if (trader === "trost") {
                        client = "trost"
                    } else if (userContext.system.traderDescription === "WM-NL") {
                        client = "wmnl"
                    } else if (userContext.system.traderDescription === "WM-AT") {
                        client = "wmat"
                    } else {
                        client = "wm"
                    }
                }

                const workTaskId = encodeUniqueId(uniqueId())
                const url = readParamVal(tile.params, "URL").replace("{0}", token).replace("{3}", client)
                const queryParams = new URLSearchParams(createQueryString({ url }))
                queryParams.append("title", tile.tilecontentdescription)
                props.history.push(`/${workTaskId}/ext/?${queryParams}`)

                break
            }
            default:
                break
        }
    }

    function renderButtonWrapper(contenttype: number, tile: AdTileType) {
        return (
            <table
                className={`${className}__button-wrapper`}
                onMouseEnter={() => {
                    hoverRef.current = true
                }}
                onMouseLeave={() => {
                    hoverRef.current = false
                }}
            >
                <tbody>
                    <tr>
                        <td>
                            <button
                                type="button"
                                className={`${className}__slideButton`}
                                onClick={() => {
                                    changeTileContent(-1)
                                }}
                                style={{ float: "left" }}
                            >
                                &#10094;
                            </button>
                        </td>
                        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                        <td
                            className={`${className}__adTileClickable`}
                            onClick={() => {
                                doTileAction(contenttype, tile)
                            }}
                        />
                        <td>
                            <button
                                type="button"
                                className={`${className}__slideButton`}
                                onClick={() => {
                                    changeTileContent(1)
                                }}
                                style={{ float: "right" }}
                            >
                                &#10095;
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    const activeTile = adTileItems[tileIndex]

    return (
        <div className={className}>
            {activeTile && (
                <div
                    className={`${className}__tile`}
                    style={{
                        backgroundImage: `url(${readParamVal(activeTile.params, "Image")})`,
                    }}
                >
                    {renderButtonWrapper(activeTile.contenttype, activeTile)}
                </div>
            )}
        </div>
    )
}

export default withRouter(AdTileComponent)
