import { clone, equals } from "@tm/utils"
import { Box } from "@tm/components"
import { ICalculationItem, Part } from "../../../data/models"
import { OeArticleItem } from "../../_shared"

type Props = {
    item: ICalculationItem
    onOeArticleSelect(): void
    onOeArticleQuantity(quantity: number): void
    onDetailsClick?(article: Part): void
    onAlternativeClick(item: ICalculationItem): void
}

export default function CalculationItemComponent({ item, onDetailsClick, onOeArticleSelect, onOeArticleQuantity, onAlternativeClick }: Props) {
    let alternatives = clone(item.alternativeParts)
    const index = alternatives?.findIndex((x) => equals(x, item.selectedPart))

    if (item.selectedPart && index > 0) {
        alternatives = alternatives.filter((x) => !equals(x, item.selectedPart))
        alternatives.unshift(item.selectedPart)
    }

    return (
        <Box className="calculation-item">
            <OeArticleItem
                oeArticle={item.oeArticle}
                showAlternativeBtn={alternatives && !!alternatives.length}
                onQuantityChange={onOeArticleQuantity}
                isSelected={item.isSelected}
                onSelect={onOeArticleSelect}
                onDetailsClick={(part) => onDetailsClick?.(part)}
                onAlternativeClick={() => onAlternativeClick(item)}
                selectedPart={item.selectedPart}
            />
        </Box>
    )
}
