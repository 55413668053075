import { Article, DefaultArticleItemState, ECounterType, Note } from "@tm/models"
import { useCallback, useEffect, useState } from "react"
import { isEqual } from "lodash"
import { TmaHelper } from "@tm/utils"
import { useVehicle } from "../useVehicle"
import { getArticleNoteIdentifier } from "../../../_shared/article-item/note/helper/mapper"
import { useListParamsContext, useNotesContext } from "../../ContextProvider"

export function useArticleNotes(article: Article): DefaultArticleItemState["notes"] {
    const vehicle = useVehicle()
    const notesParams = useNotesContext()
    const { notes: allNotes, deleteNote, saveNote } = notesParams

    const [notes, setNotes] = useState<Note[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const { listType } = useListParamsContext()

    useEffect(() => {
        setNotes((prev) => {
            const queryIds = getArticleNoteIdentifier(article, vehicle)
            const filtered = allNotes.filter((x) => queryIds.includes(x.noteId))
            if (!isEqual(filtered, prev)) {
                return filtered
            }
            return prev
        })
    }, [allNotes, article, listType, vehicle?.id])

    const handleSave = useCallback(async (note: Note) => {
        setIsLoading(true)
        await saveNote(note)
        setIsLoading(false)
    }, [])

    const handleDelete = useCallback(async (noteId: string) => {
        setIsLoading(true)
        await deleteNote(noteId)
        setIsLoading(false)
    }, [])

    const toggle = useCallback(() => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleNotes, !visible)
        setVisible((prev) => !prev)
    }, [visible])

    const hide = useCallback(() => {
        setVisible(false)
    }, [])

    return { notes, handleSave, handleDelete, isLoading, visible, toggle, hide }
}
