import { ErpInformation, getCurrentWorkTaskId, ImportRepairEstimationRequest, ImportResponse, TmaEModule } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { clone, equals } from "@tm/utils"
import { batch } from "react-redux"
import { BundleActions, BundleActionTypes } from "../../../business"
import { ICalculationItem, Part, UpdatedTotals, Work } from "../../../data/models"
import { GetCalculationDataResponse, useContainer } from "../../../data/repositories"
import { MainState } from "../../main"
import { createCostEstimationRequest, createUpdateTotalsRequest } from "./helpers"
import { CalculationState } from "./model"
// import DEFAULT_STATE from "./mockdata"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "CALCULATION_DATA_LOADING" }
    | { type: "CALCULATION_DATA_LOADED"; payload: GetCalculationDataResponse }
    | { type: "CALCULATION_DATA_ERROR" }
    | { type: "ADD_TO_SHOPPING_BASKET_SUCCESS" }
    | { type: "ADD_TO_SHOPPING_BASKET_ERROR" }
    | { type: "REPLACE_ARTICLE"; payload: { oeArticle: ICalculationItem; part: Part } }
    | { type: "REPLACE_TO_OE_ARTICLE"; payload: { oeArticle: ICalculationItem } }
    | { type: "SELECT_ARTICLE"; payload: { oeArticle: ICalculationItem } }
    | { type: "SELECT_WORK"; payload: { work: Work } }
    | { type: "CHANGE_OE_ARTICLE_QUANTITY"; payload: { item: ICalculationItem; quantity: number } }
    | { type: "SET_ERP_ARTICLES"; payload: ErpInformation[] }
    | { type: "SET_UPDATED_TOTALS"; payload: UpdatedTotals }
    | { type: "SET_UPDATED_TOTALS_ERROR" }

export const DEFAULT_STATE: CalculationState = {
    items: [],
    works: [],
    externToolUpdate: true,
    erpArticles: [],
    totals: {
        totalSparePartsPrice: 0,
        additionalCosts: 0,
        totalWorksPrice: 0,
        totalNetPrice: 0,
        totalGrossPrice: 0,
        totalAdditionalWorksCostsPrice: 0,
    },
    initialSparePartsPrice: 0,
}

export function reduce(state = { ...(DEFAULT_STATE as CalculationState) }, action: ComponentActionType): CalculationState {
    switch (action.type) {
        case "CALCULATION_DATA_LOADING": {
            return {
                ...state,
                calculationLoading: true,
                calculationError: false,
                works: [],
                items: [],
            }
        }
        case "CALCULATION_DATA_ERROR": {
            return {
                ...state,
                calculationLoading: false,
                calculationError: true,
            }
        }
        case "CALCULATION_DATA_LOADED": {
            const { items, works, totals, initialSparePartsPrice } = action.payload

            return {
                ...state,
                calculationLoading: false,
                calculationError: false,
                works: works ?? [],
                items: items ?? [],
                externToolUpdate: false,
                totals,
                initialSparePartsPrice,
            }
        }
        case "REPLACE_ARTICLE": {
            const { oeArticle, part } = action.payload
            const { items } = state

            return {
                ...state,
                items: items.map((x) => ({
                    ...x,
                    ...(equals(x, oeArticle) && { selectedPart: part }),
                })),
                updateTotalsInProgress: true,
            }
        }
        case "REPLACE_TO_OE_ARTICLE": {
            const { oeArticle } = action.payload
            const { items } = state

            return {
                ...state,
                items: items.map((x) => ({
                    ...x,
                    ...(equals(x, oeArticle) && { selectedPart: undefined }),
                })),
                updateTotalsInProgress: true,
            }
        }
        case "SELECT_ARTICLE": {
            const { oeArticle } = action.payload
            const { items } = state

            return {
                ...state,
                items: items.map((x) => ({ ...x, ...(equals(x, oeArticle) && { isSelected: !x.isSelected }) })),
                updateTotalsInProgress: true,
            }
        }
        case "SET_ERP_ARTICLES": {
            return {
                ...state,
                erpArticles: action.payload,
            }
        }
        case "SELECT_WORK": {
            const { work } = action.payload
            return {
                ...state,
                works: state.works.map((x) => ({ ...x, ...(equals(x, work) && { isSelected: !x.isSelected }) })),
                addToShoppingBasketDone: false,
                updateTotalsInProgress: true,
            }
        }
        case "CHANGE_OE_ARTICLE_QUANTITY": {
            const { item, quantity } = action.payload
            return {
                ...state,
                items: state.items.map((x) => ({ ...x, ...(equals(x, item) && { oeArticle: { ...x.oeArticle, quantityValue: quantity } }) })),
                addToShoppingBasketDone: false,
                updateTotalsInProgress: true,
            }
        }
        case "SET_UPDATED_TOTALS": {
            const { updatedOeArticles } = action.payload
            const items = clone(state.items)

            if (updatedOeArticles?.length) {
                updatedOeArticles.forEach((updatedArticle) => {
                    const match = items?.find(
                        (x) =>
                            x.oeArticle.oeArticleNumber === updatedArticle.oeArticleNumber &&
                            x.oeArticle.type === updatedArticle.type &&
                            x.oeArticle.datProcessId === updatedArticle.datProcessId
                    )

                    if (match) {
                        match.oeArticle = updatedArticle
                    }
                })
            }

            return {
                ...state,
                items,
                totals: action.payload,
                updateTotalsInProgress: false,
            }
        }
        case "SET_UPDATED_TOTALS_ERROR": {
            return {
                ...state,
                updateTotalsInProgress: false,
                updateTotalsFailed: true,
            }
        }
        default: {
            return state
        }
    }
}

const addToShoppingBasket =
    (
        importToCostEstimation: (importRequest: ImportRepairEstimationRequest, tmaModule: TmaEModule) => Promise<ImportResponse | undefined>,
        memo?: string
    ): AsyncAction<ComponentActionType, MainState> =>
    (dispatch, getState) => {
        const workTaskId = getCurrentWorkTaskId()
        const request = createCostEstimationRequest(getState(), workTaskId!, memo)

        importToCostEstimation(request, TmaEModule.GLASS_REPESTIMATE_DAT).then(
            (_) => {
                dispatch({ type: "ADD_TO_SHOPPING_BASKET_SUCCESS" })
            },
            (_) => dispatch({ type: "ADD_TO_SHOPPING_BASKET_ERROR" })
        )
    }

const updateTotals =
    (erpInfo?: ErpInformation[]): AsyncAction<ComponentActionType, MainState> =>
    (dispatch, getState) => {
        const state = getState()
        const container = useContainer()

        if (erpInfo) {
            dispatch(setErpArticles(erpInfo))
        }

        const request = createUpdateTotalsRequest(state.calculation, erpInfo || state.calculation.erpArticles)

        container
            .action("updateTotalsPrices")(request)
            .then(
                (res) => dispatch({ type: "SET_UPDATED_TOTALS", payload: res }),
                () => dispatch({ type: "SET_UPDATED_TOTALS_ERROR" })
            )
    }

const changeOeArticleQuantity =
    (item: ICalculationItem, quantity: number): AsyncAction<ComponentActionType, MainState> =>
    (dispatch) => {
        batch(() => {
            dispatch({ type: "CHANGE_OE_ARTICLE_QUANTITY", payload: { item, quantity } })

            dispatch(updateTotals())
        })
    }

const replaceArticle = (oeArticle: ICalculationItem, part: Part): ComponentActionType => ({ type: "REPLACE_ARTICLE", payload: { oeArticle, part } })

const replaceOeArticle = (oeArticle: ICalculationItem): ComponentActionType => ({ type: "REPLACE_TO_OE_ARTICLE", payload: { oeArticle } })

const selectArticle =
    (oeArticle: ICalculationItem): AsyncAction<ComponentActionType, MainState> =>
    (dispatch) => {
        batch(() => {
            dispatch({ type: "SELECT_ARTICLE", payload: { oeArticle } })

            dispatch(updateTotals())
        })
    }

const selectWork =
    (work: Work): AsyncAction<ComponentActionType, MainState> =>
    (dispatch) => {
        batch(() => {
            dispatch({ type: "SELECT_WORK", payload: { work } })

            dispatch(updateTotals())
        })
    }

const setErpArticles = (articles: ErpInformation[]): ComponentActionType => ({ type: "SET_ERP_ARTICLES", payload: articles })

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    selectArticle,
    replaceArticle,
    replaceOeArticle,
    selectWork,
    changeOeArticleQuantity,
    setErpArticles,
    addToShoppingBasket,
    updateTotals,
}
