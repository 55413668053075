import { WholesalerArticleQuantitiesRequest } from "@tm/models"
import { ajaxAuth, notUndefinedOrNull } from "@tm/utils"
import { getServiceUrl } from "../../.."
import { ShowQuantitiesByWholesalerArticleRequest, ShowQuantitiesByWholesalerArticleResponse } from "../../../model"

// TODO: Remove when the artilce list V2 doesn't use it anymore
export function getShowWholesalerArticleQuantities(requests: WholesalerArticleQuantitiesRequest[]) {
    const url = `${getServiceUrl()}/ShowQuantitiesByWholesalerArticle`
    const body: ShowQuantitiesByWholesalerArticleRequest = {
        workTaskId: requests[requests.length - 1].workTaskId,
        articles: requests.map((r) => r.article),
    }

    return ajaxAuth<ShowQuantitiesByWholesalerArticleResponse>({ url, body, method: "POST" }).then(
        (data) => data?.wholesalerArticleQuantities?.filter(notUndefinedOrNull) || []
    )
}
